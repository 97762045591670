import { Box, CssBaseline, ThemeProvider } from '@mui/material';
import { PropertyContactModal } from 'components/modals/PropertyContactModal';
import { PropertyUploadModal } from 'components/modals/PropertyUploadModal';
import { CapabilityType, CompanyFeatureFlag, UserRole } from 'shared/api/clients';
import { ChatDrawer } from 'components/Chat/Chat';
import theme from 'shared/theme';
import { WhenCapable } from 'components';
import { WithReqChildren } from 'shared/utils';
import { MainBoxStyle, Toolbar } from './shared';
import { Sidebar } from './Sidebar';
import { RooToolbar } from './Toolbar';
import { InviteUserModal } from 'components/modals';
import { useCurrentUser, useCurrentVendor, useHasFeatureFlag, useIsGeneralContractor } from '../shared/store';
import { AddVendorMemberModal } from '../components/modals/AddVendorMemberModal';
import { GoToIssueModal } from '../components/modals/GoToIssueModal';
import { ImpersonateModal } from '../components/modals/ImpersonateModal';
import { PriceGuideModal } from '../components/modals/PriceGuideModal';
import { MakeReadyModal } from 'components/modals/MakeReadyModal';
import { VendorUploadModal } from '../components/modals/VendorUploadModal';

export function Layout({ children }: WithReqChildren) {
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <RooToolbar />
        <Sidebar />
        <Box component="main" sx={MainBoxStyle} id={'main-anchor'}>
          {/* this is only here to push everything else down... */}
          <Toolbar />
          {children}
        </Box>
      </Box>
      <GlobalModals />
    </ThemeProvider>
  );
}

const GlobalModals = () => {
  const currentVendor = useCurrentVendor();
  const isGeneralContractor = useIsGeneralContractor();
  const hasPriceGuide = useHasFeatureFlag(CompanyFeatureFlag.PriceGuide);
  const currentUser = useCurrentUser();
  return (
    <>
      {currentVendor != null && <AddVendorMemberModal />}
      {isGeneralContractor && hasPriceGuide && <PriceGuideModal />}
      {currentVendor != null && <MakeReadyModal />}
      <WhenCapable capability={CapabilityType.ManagementCompanyOwner}>
        <InviteUserModal />
      </WhenCapable>
      {currentUser.accountRole === UserRole.Admin && <GoToIssueModal />}
      {currentUser.accountRole === UserRole.Admin && <ImpersonateModal />}
      <PropertyContactModal />
      <PropertyUploadModal />
      <VendorUploadModal />
      <ChatDrawer />
    </>
  );
};
