import { useCallback, useState } from "react";

export const useToggle = (initial: boolean = false) => {
  const [visible, setVisible] = useState(initial);
  const toggle = useCallback(() => {
    setVisible((x) => !x);
  }, []);

  return [visible, toggle] as const;
};
