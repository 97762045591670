import type { OutlinedTextFieldProps } from '@mui/material/TextField/TextField';
import { FieldValues, useController } from 'react-hook-form';
import { getMuiHelperText } from 'components/form/ErrorMessage';
import { ControlProps } from 'components/form/types';
import { DatePicker } from '@mui/x-date-pickers';

type BaseProps = Omit<OutlinedTextFieldProps, 'id' | 'type' | 'name' | 'onChange' | 'value' | 'onBlur' | 'variant'>;
type FinalProps<TForm extends FieldValues> = ControlProps<TForm> & BaseProps;
export type FieldMuiDatePickerProps<TForm extends FieldValues> = FinalProps<TForm>;

export const FieldMuiDatePicker = <T extends FieldValues>({
  name,
  control,
  helperText,
  label,
  ...rest
}: FinalProps<T>) => {
  const {
    field,
    fieldState: { error }
  } = useController({
    control: control,
    name: name
  });

  const finalHelperText = getMuiHelperText(helperText, error);

  return (
    <DatePicker
      label={label}
      name={name}
      slotProps={{
        textField: {
          fullWidth: true,
          error: error != null,
          helperText: finalHelperText
        }
      }}
      {...field}
    />
  );
};
