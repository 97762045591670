import { ConfirmationModal, RooButton } from 'components';
import { useCurrentIssue } from 'pages/Workorders/shared/CurrentIssueContext';
import React, { useState } from 'react';
import { apiProvider } from 'shared/api/apiProvider';
import { DeclineBidPayload } from 'shared/api/clients';
import { redirect, Routes } from 'shared/routing';

export const DeclineBidButton = () => {
  const { issue, onIssueUpdate, latestBidInvitation } = useCurrentIssue();
  const [showConfirm, setShowConfirm] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  if (latestBidInvitation == null) {
    return null;
  }
  const save = async () => {
    setIsSaving(true);
    try {
      const newIssue = await apiProvider.issues.bids.declineBid(
        new DeclineBidPayload({
          bidId: latestBidInvitation.id,
          issueId: issue.id
        })
      );
      onIssueUpdate(newIssue);
      setIsSaving(false);
      redirect(Routes.Dashboard);
    } catch (e) {
      setIsSaving(false);
    }
  };
  return (
    <>
      <RooButton onClick={() => setShowConfirm(true)} size={'sm'} variant={'danger'}>
        Decline
      </RooButton>
      <ConfirmationModal
        visible={showConfirm}
        question={'Are you sure you want to decline this Bid Request?'}
        onClose={() => setShowConfirm(false)}
        onSave={() => void save()}
        running={isSaving}
      />
    </>
  );
};
