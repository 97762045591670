import { alpha, Box, CircularProgress, FormControlLabel, styled, Switch } from '@mui/material';
import { ChangeEvent, useState } from 'react';
import { useCurrentIssue } from '../../../shared/CurrentIssueContext';
import { showError, showSuccess } from '../../../../../shared/utils';
import { apiProvider } from '../../../../../shared/api/apiProvider';
import { ColorSwitch } from 'components/ColorSwitch';

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  // border: `1px solid ${theme.palette.divider}`,
  paddingRight: theme.spacing(2),
  cursor: 'pointer',
  backgroundColor: theme.palette.grey.A200,
  '&:hover': {
    backgroundColor: alpha(theme.palette.action.hover, theme.palette.action.hoverOpacity)
  }
}));

export const EstimateSentToggle = () => {
  const { issue, onIssueUpdate } = useCurrentIssue();
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = async () => {
    setIsLoading(true);
    try {
      const fn = issue.markedEstimateSent
        ? () => apiProvider.issues.estimates.markEstimateNotSent(issue.id)
        : () => apiProvider.issues.estimates.markEstimateSent(issue.id);
      const res = await fn();
      onIssueUpdate(res);
      showSuccess();
    } catch (e) {
      console.error(e);
      showError();
    }
    setIsLoading(false);
  };

  return (
    <StyledFormControlLabel
      disabled={isLoading}
      sx={{ marginBottom: 0 }}
      control={
        <Box sx={{ minWidth: 58, minHeight: 48, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          {isLoading ? (
            <CircularProgress size={20} />
          ) : (
            <ColorSwitch checked={issue.markedEstimateSent} onChange={handleChange} />
          )}
        </Box>
      }
      label="Estimate Sent"
    />
  );
};
