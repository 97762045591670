import { format } from 'date-fns';
import React, { InputHTMLAttributes } from 'react';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { FieldValues, useController } from 'react-hook-form';
import { fieldClassNames } from 'components/form/utils';
import { ErrorMessage } from 'components/form/ErrorMessage';
import { ControlProps } from 'components/form/types';
import { isMobile } from '../../shared/utils/device';
import { FormGroup } from 'react-bootstrap';
import { FormGroupProps } from 'react-bootstrap/FormGroup';
import { RooFormLabel } from './RooFormLabel';

type BaseProps = Omit<
  ReactDatePickerProps & InputHTMLAttributes<HTMLInputElement>,
  'id' | 'type' | 'name' | 'onChange' | 'value' | 'onBlur'
>;
type FinalProps<TForm extends FieldValues> = ControlProps<TForm> &
  BaseProps & {
    groupProps?: FormGroupProps;
  };
export type FieldDatePickerProps<TForm extends FieldValues> = FinalProps<TForm>;

function inputDateValue(value: any) {
  if (!value) return '';

  const date = new Date(value);

  const isValidDate = !isNaN(date.getTime());
  return isValidDate ? format(date, 'yyyy-MM-dd') : '';
}

export const FieldDatePicker = <TForm extends {}>({
  name,
  control,
  minDate,
  maxDate,
  label,
  className,
  groupProps,
  required,
  ...rest
}: FinalProps<TForm>) => {
  const mobile = isMobile();

  const {
    field,
    fieldState: { error }
  } = useController({
    control: control,
    name: name
  });

  let input: JSX.Element;
  if (mobile) {
    input = (
      <input
        type={'date'}
        className={fieldClassNames(className, error)}
        name={name}
        id={name}
        value={inputDateValue(field.value)}
        min={inputDateValue(minDate)}
        max={inputDateValue(maxDate)}
        onBlur={field.onBlur}
        onChange={(e) => {
          const dateParts = e.target.value.split('-');
          // @ts-ignore
          field.onChange(new Date(+dateParts[0], +dateParts[1] - 1, +dateParts[2]));
        }}
        {...rest}
      />
    );
  } else {
    input = (
      <ReactDatePicker
        className={fieldClassNames(className, error)}
        wrapperClassName={'in-form'}
        selected={field.value}
        minDate={minDate}
        maxDate={maxDate}
        onChange={(d, ev) => {
          // @ts-ignore
          field.onChange(d, ev);
          field.onBlur();
        }}
        onBlur={field.onBlur}
        {...rest}
      />
    );
  }

  return (
    <FormGroup {...(groupProps ?? {})}>
      <RooFormLabel label={label} name={name} required={required} />
      {input}
      <ErrorMessage error={error} />
    </FormGroup>
  );
};
