import { useRef, useState } from 'react';
import { Button, Col, Modal, ModalFooter, Row } from 'react-bootstrap';
import { CSVReader } from 'react-papaparse';
import { apiProvider } from 'shared/api/apiProvider';
import { BulkCreateVendorsPayload, BulkCreateVendorStatus, IBulkCreateVendorForm } from 'shared/api/clients';
import DownloadLink from 'shared/external/react-download-link';
import { RooIcon } from 'shared/icons';
import { redirect, Routes } from 'shared/routing';
import { useCurrentUser } from 'shared/store';
import { rooGeo, showSuccess, toSnakeCase } from 'shared/utils';
import { useWatchGlobalModal } from './modalStore';
import { CenteredLoader } from '../CenteredLoader';

export const VendorUploadModal = () => {
  const { isVisible, toggle } = useWatchGlobalModal('vendorBulkImport');

  const user = useCurrentUser();

  const [state, setState] = useState<'pending' | 'disabled'>('disabled');

  const buttonRef = useRef(null);

  const handleOpenDialog = (e: any) => {
    if (buttonRef.current) {
      buttonRef.current.open(e);
    }
  };

  const handleOnFileLoad = async (data: any) => {
    setState('pending');

    let headers: { data: any[] }[] = [];
    let rowsData: { data: any[] }[] = [];
    let headerRow = true;

    data?.filter((row: { data: any[] }, index: number) => {
      if (
        row.data.every((column: string, index: number) => {
          if (index === 0) {
            return true;
          } else {
            return column === '';
          }
        }) ||
        row.data.length <= 2
      ) {
        //Non header/data rows
      } else {
        if (headerRow) {
          headers.push(row);
          headerRow = false;
        } else {
          rowsData.push(row);
        }
      }
    });

    const entries: IBulkCreateVendorForm[] = [];

    for (var i = 0; i < rowsData.length; i++) {
      let parsedObject: any = {};

      //eslint-disable-next-line
      rowsData[i].data.filter((columnData, columnIndex) => {
        parsedObject[toSnakeCase(headers[0].data[columnIndex])] = columnData;
      });

      let address1 = parsedObject['address']
        ? parsedObject['address']
        : parsedObject['address_1']
          ? parsedObject['address_1']
          : '';

      let address2 = parsedObject['address_cont.']
        ? parsedObject['address_cont.']
        : parsedObject['address_2']
          ? parsedObject['address_2']
          : '';

      if (!address2) {
        const address = address1.split(' ');
        address1 = '';
        address2 = '';
        let address2Part = false;
        let address1FirstIndex = true;
        let address2FirstIndex = true;
        for (const splittedAddress of address) {
          if (splittedAddress === 'Apt' || splittedAddress === 'Apt.' || address2Part) {
            address2Part = true;
            if (address2FirstIndex) {
              address2FirstIndex = false;
              address2 += splittedAddress;
            } else {
              address2 += ' ' + splittedAddress;
              address2 = address2.trim();
            }
          } else {
            if (address1FirstIndex) {
              address1FirstIndex = false;
              address1 += splittedAddress;
            } else {
              address1 += ' ' + splittedAddress;
              address1 = address1.trim();
            }
          }
        }
      }

      let state = parsedObject['state'] ? parsedObject['state'] : null;
      const zip = parsedObject['zip'] ? parsedObject['zip'] : '';
      const city = parsedObject['city'] ? parsedObject['city'] : '';

      if (!state && zip) {
        state = rooGeo.getStateByZipCode(zip.substring(0, 5));
      }

      const companyName: string = parsedObject['company_name'];
      const phone: string = parsedObject['phone'];
      const email: string = parsedObject['email'];
      let firstName: string = parsedObject['first_name'];
      let lastName: string = parsedObject['last_name'];

      if (!firstName && !lastName) {
        const name = parsedObject['full_name'] ? parsedObject['full_name'].split(' ') : [];
        firstName = name[0];
        lastName = '';
        for (var k = 1; k < name.length; k++) {
          lastName += name[k];
          lastName += k === name.length - 1 ? '' : ' ';
        }
      }

      entries.push({
        index: i,
        companyName: companyName,
        phoneNumber: phone,
        email: email,
        firstName: firstName,
        lastName: lastName,
        address1: address1,
        address2: address2,
        city: city,
        state: state,
        zip: zip
      });
    }

    try {
      const results = await apiProvider.vendorClient.bulkCreate(
        new BulkCreateVendorsPayload({
          managementCompanyId: user.managementCompany.id,
          candidates: entries
        })
      );

      const total = results.length;
      const skipped = results.filter((x) => x.status === BulkCreateVendorStatus.Skipped).length;
      const errors = results.filter((x) => x.status === BulkCreateVendorStatus.Error).length;

      showSuccess(`Import completed. Total: ${total}, skipped ${skipped}, errors ${errors}`);
      toggle();
    } catch (err) {
      console.error('err: ', err);
    }

    redirect({ pathname: Routes.VendorList });
    showSuccess();
    setState('disabled');
  };

  const handleOnError = (err: any, file: any, inputElem: any, reason: any) => {
    console.log('-------handleOnError-------');
    console.log('---------------------------');
    console.log(err);
    console.log('---------------------------');
  };

  const handleOnRemoveFile = (data: any) => {
    console.log('----handleOnRemoveFile-----');
    console.log('---------------------------');
    console.log(data);
    console.log('---------------------------');
  };

  const csvTemplate = 'Company Name,Phone,Email,First Name,Last Name,Address1,Address2,City,State,Zip';

  return (
    <Modal show={isVisible} onHide={toggle} className={'modal-dialog-centered'}>
      <Modal.Header closeButton>
        <>Upload Your File</>
      </Modal.Header>
      <Modal.Body>
        {state === 'pending' && <CenteredLoader />}
        {state === 'disabled' && (
          <Row>
            <Col xl={12}>
              <p>Instructions to upload</p>
            </Col>
            <Col xl={12}>
              <p>
                1. Your file must be uploaded in the following format: comma delimited (.csv)
                <ol>
                  <li>Locate and open the file</li>
                  <li>
                    Select “File” ={'>'} “Export” ={'>'} “Change File Type”
                  </li>
                  <li>Choose the correct file format: Comma delimited (CSV)</li>
                  <li>Save and upload the newly exported file.</li>
                </ol>
              </p>
            </Col>
            <Col xl={12}>
              <p>
                2. Check your file for the correct column headers. If your file's headers don't match the system's, your
                data will not be uploaded. Click either of the links to view or download our file templates with correct
                headers: (
                <DownloadLink label=".csv" filename="vendors_template.csv" exportFile={() => csvTemplate} />)
              </p>
            </Col>
            <Col xl={12}>
              <p>3. Click the "Upload Vendors File" button below to select and upload your file.</p>
            </Col>
            <Col xl={6}>
              <div style={{ display: 'inline-block' }}>
                <CSVReader
                  ref={buttonRef}
                  onFileLoad={handleOnFileLoad}
                  onError={handleOnError}
                  noClick
                  noDrag
                  onRemoveFile={handleOnRemoveFile}
                  accept={'text/csv, .csv'}
                >
                  {({ file }: { file: any }) => (
                    <>
                      <Button className="btn-custom" onClick={handleOpenDialog}>
                        <RooIcon icon={['fas', 'building']} /> <b>Upload Vendors File</b>
                      </Button>
                    </>
                  )}
                </CSVReader>
              </div>
            </Col>
          </Row>
        )}
      </Modal.Body>
      <ModalFooter>
        <Button variant={'secondary'} onClick={toggle}>
          <b>Cancel</b>
        </Button>
      </ModalFooter>
    </Modal>
  );
};
