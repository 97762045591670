import { RooDialog } from '../../RooDialog';
import React from 'react';
import { AllowedFileType, FileUploaderContext, FileUploadMeta, useFileArea } from './FilePicker.store';
import { AttachmentFile } from '../../../shared/api/clients';
import { ViewFileList } from './ViewFileList';
import { ViewSelector } from './ViewSelector';
import { DragArea } from './DragArea';
import { ViewGallery } from './ViewGallery';
import { ViewCollage } from './ViewCollage';
import { Disclosure } from '@roo/lib';

type FilePickerProps = {
  allowedFileTypes?: AllowedFileType[];
  maxFiles?: number;
  meta: FileUploadMeta;
  onAppend?: (...items: AttachmentFile[]) => void;
  requestClose?: () => void;
};

type FilePickerModalProps = { modal: Disclosure } & Omit<FilePickerProps, 'requestClose'>;

export const FilePickerModal = ({ modal, ...rest }: FilePickerModalProps) => {
  return (
    <RooDialog fullWidth maxWidth={'md'} open={modal.isOpen} onClose={modal.close}>
      <RooDialog.Title onClose={modal.close}>Upload Files</RooDialog.Title>
      <FilePicker requestClose={modal.close} {...rest} />
    </RooDialog>
  );
};

export const FilePicker = ({ allowedFileTypes, meta, maxFiles, onAppend, requestClose }: FilePickerProps) => {
  return (
    <FileUploaderContext
      allowedFileTypes={allowedFileTypes}
      maxFiles={maxFiles}
      requestClose={requestClose}
      meta={meta}
      onAppend={onAppend}
    >
      <DragArea>
        <FilePickerBody />
      </DragArea>
    </FileUploaderContext>
  );
};

const FilePickerBody = () => {
  const view = useFileArea((x) => x.view);
  switch (view) {
    case 'file-list':
      return <ViewFileList />;
    case 'source-selector':
      return <ViewSelector />;
    case 'gallery':
      return <ViewGallery />;
    case 'collage':
      return <ViewCollage />;
  }
};
