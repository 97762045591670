import { Priority } from 'shared/api/clients';
import { ColoredEnumDisplay, createMap, EnumMap } from 'shared/store/enums/shared';

const InternalMapPriority: EnumMap<typeof Priority, ColoredEnumDisplay> = {
  Critical: {
    display: 'Critical',
    color: 'danger'
  },
  High: {
    display: 'High',
    color: 'danger'
  },
  Medium: {
    display: 'Medium',
    color: 'warning'
  },
  Low: {
    display: 'Low',
    color: 'primary'
  },
  Trivial: {
    display: 'Trivial',
    color: 'primary'
  },
  Unknown: {
    display: 'Unknown',
    color: 'primary'
  }
};

export const EnumMapPriority = createMap<Priority, ColoredEnumDisplay>(Priority, InternalMapPriority);
