import { ReactNode } from 'react';
import { classNames } from 'shared/utils';
import { Control, FieldValues, useFormState } from 'react-hook-form';
import { Button, ButtonProps } from '@mui/material';

type SaveButtonProps<TForm extends FieldValues> = Omit<
  JSX.IntrinsicElements['button'],
  'disabled' | 'type' | 'children'
> & {
  control: Control<TForm>;
  children: ReactNode;
};

export const SaveButton = <TForm extends FieldValues>({
  control,
  className,
  children,
  ...rest
}: SaveButtonProps<TForm>) => {
  const { errors, isSubmitting, isValidating } = useFormState({
    control: control
  });

  const hasErrors = Object.keys(errors).length > 0;
  return (
    <button
      disabled={isSubmitting || isValidating}
      type="submit"
      className={classNames('btn btn-primary', className)}
      {...rest}
    >
      {isSubmitting && (
        <span
          className="spinner-border spinner-border-sm"
          style={{ marginRight: '8px' }}
          role="status"
          aria-hidden="true"
        />
      )}
      {children}
    </button>
  );
};

type SaveButtonMuiProps<TForm extends FieldValues> = Omit<ButtonProps, 'disabled' | 'type' | 'children' | 'color'> & {
  control: Control<TForm>;
  children: ReactNode;
};

export const SaveButtonMui = <TForm extends FieldValues>({
  control,
  className,
  children,
  ...rest
}: SaveButtonMuiProps<TForm>) => {
  const { errors, isSubmitting, isValidating } = useFormState({
    control: control
  });

  const hasErrors = Object.keys(errors).length > 0;

  return (
    <Button disabled={isSubmitting || isValidating} type="submit" color="primary" {...rest}>
      {isSubmitting && (
        <span
          className="spinner-border spinner-border-sm"
          style={{ marginRight: '8px' }}
          role="status"
          aria-hidden="true"
        />
      )}
      {children}
    </Button>
  );
};
