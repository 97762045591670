// https://github.com/mtblc/image-collage

import { BinaryHeap } from './binaryHeap';

interface QueueNode {
  id: string;
  weight: number;
}

type GraphFunction = (nodeId: string) => Record<string, number>;

const buildPrecedentsMap = (graph: GraphFunction, startNode: string, endNode: string): Record<string, string> => {
  const precedentsMap: Record<string, string> = {};
  const visited: Record<string, boolean> = {};
  const storedShortestPaths: Record<string, number> = {};
  storedShortestPaths[startNode] = 0;

  const pQueue = new BinaryHeap<QueueNode>((n) => n.weight);
  pQueue.push({ id: startNode, weight: 0 });

  while (pQueue.size()) {
    const shortestNode = pQueue.pop();
    if (!shortestNode) continue;

    const shortestNodeId = shortestNode.id;

    if (visited[shortestNodeId]) continue;

    const neighboringNodes = graph(shortestNodeId) || {};
    visited[shortestNodeId] = true;

    for (const neighbor in neighboringNodes) {
      const newTotalWeight = shortestNode.weight + neighboringNodes[neighbor];

      if (typeof storedShortestPaths[neighbor] === 'undefined' || storedShortestPaths[neighbor] > newTotalWeight) {
        storedShortestPaths[neighbor] = newTotalWeight;
        pQueue.push({ id: neighbor, weight: newTotalWeight });
        precedentsMap[neighbor] = shortestNodeId;
      }
    }
  }

  if (typeof storedShortestPaths[endNode] === 'undefined') {
    throw new Error(`There is no path from ${startNode} to ${endNode}`);
  }

  return precedentsMap;
};

const getPathFromPrecedentsMap = (precedentsMap: Record<string, string>, endNode: string): string[] => {
  const nodes: string[] = [];
  let n = endNode;
  let precedent;
  while (n) {
    nodes.push(n);
    precedent = precedentsMap[n];
    n = precedentsMap[n];
  }
  return nodes.reverse();
};

export const findShortestPath = (graph: GraphFunction, startNode: string, endNode: string): string[] => {
  const precedentsMap = buildPrecedentsMap(graph, startNode, endNode);
  return getPathFromPrecedentsMap(precedentsMap, endNode);
};
