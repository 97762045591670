import { IInvoice, Invoice, ReviewInvoiceAction, ReviewInvoicePayload } from '../../../../../shared/api/clients';
import { showSuccess } from '../../../../../shared/utils';
import { useCurrentIssue } from '../../../shared/CurrentIssueContext';
import React, { useState } from 'react';
import { apiProvider } from '../../../../../shared/api/apiProvider';
import { ConfirmationModal } from '../../../../../components';
import { InvoiceInfo } from './InvoiceInfoButton';
import { Disclosure } from '@roo/lib';

export const ReviewInvoiceModal = ({
  invoice,
  action,
  modal
}: {
  action: ReviewInvoiceAction;
  invoice: IInvoice;
  modal: Disclosure;
}) => {
  const { issue, onIssueUpdate } = useCurrentIssue();
  const [saving, setSaving] = useState(false);
  const [notes, setNotes] = useState<string>('');

  const save = async () => {
    try {
      setSaving(true);
      const updated = await apiProvider.issues.invoices.reviewInvoice(
        new ReviewInvoicePayload({
          action: action,
          invoiceId: invoice.id,
          issueId: issue.id,
          notes: notes.trim() === '' ? null : notes
        })
      );

      onIssueUpdate(updated);
      showSuccess();
      modal.close();
      setSaving(false);
    } catch (e) {
      setSaving(false);
    }
  };

  return (
    <>
      <ConfirmationModal
        size={'xl'}
        question={
          <>
            <p className={'mb-4'}>
              {action === ReviewInvoiceAction.Accept
                ? 'Do you want to accept this invoice?'
                : 'Do you want to reject this invoice?'}
            </p>
            <div className={'mt-4 form-group'}>
              <label htmlFor={'review-notes'}>Notes</label>
              <textarea
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                className={'form-control'}
                id={'review-notes'}
                rows={3}
                placeholder={'Your comments'}
              />
            </div>

            <InvoiceInfo invoice={invoice} />
          </>
        }
        visible={modal.isOpen}
        onClose={modal.close}
        onSave={() => void save()}
        running={saving}
      />
    </>
  );
};
