import { UpdateVendorPreferencesPayload, Vendor } from '../../shared/api/clients';
import { Button, Grid, IconButton, List, ListItem, ListItemText, ListSubheader, Stack } from '@mui/material';
import { MuiIcon } from '../../shared/icons';
import { RooDialog } from '../../components';
import { z } from 'zod';
import { FieldMuiSwitch, useRooForm } from '../../components/form';
import { useVendorMutation } from './shared';
import { apiProvider } from '../../shared/api/apiProvider';
import React from 'react';
import { YesNoIcon } from '../../components/YesNoIcon';
import { Disclosure, useDisclosure } from '@roo/lib';

export const PreferencesWidget = ({ vendor, canEdit }: { vendor: Vendor; canEdit: boolean }) => {
  const modal = useDisclosure(false);
  return (
    <>
      <List
        subheader={
          <Stack direction={'row'}>
            <ListSubheader>
              <MuiIcon.Construction /> Preferences
            </ListSubheader>
            {canEdit && (
              <IconButton color={'primary'} onClick={modal.open}>
                <MuiIcon.Edit />
              </IconButton>
            )}
          </Stack>
        }
      >
        <ListItem>
          <ListItemText
            primary={
              <Stack direction={'row'} spacing={2}>
                {<YesNoIcon value={vendor.showInPool} />}
                {vendor.showInPool ? 'Yes' : 'No'}
              </Stack>
            }
            secondary={'Show in Pool'}
          />
        </ListItem>
      </List>
      <UpdatePreferencesModal disclosure={modal} vendor={vendor} />
    </>
  );
};

const UpdatePreferencesModal = ({ disclosure, vendor }: { disclosure: Disclosure; vendor: Vendor }) => {
  return (
    <RooDialog onClose={disclosure.close} fullWidth maxWidth={'sm'} open={disclosure.isOpen}>
      <RooDialog.Title onClose={disclosure.close}>Update Preferences</RooDialog.Title>
      <UpdatePreferencesForm disclosure={disclosure} vendor={vendor} />
    </RooDialog>
  );
};

const ContactSchema = z.object({
  showInPool: z.boolean()
});

type ContactForm = z.infer<typeof ContactSchema>;

const UpdatePreferencesForm = ({ disclosure, vendor }: { disclosure: Disclosure; vendor: Vendor }) => {
  const { control, handleSubmit } = useRooForm(ContactSchema, {
    defaultValues: {
      showInPool: vendor.showInPool
    }
  });

  const { isLoading, mutateAsync } = useVendorMutation(vendor.id, {
    mutationFn: (values: ContactForm) =>
      apiProvider.vendorClient.updatePreferences(
        new UpdateVendorPreferencesPayload({
          vendorId: vendor.id,
          showInPool: values.showInPool
        })
      ),
    onSuccess: () => {
      disclosure.close();
    }
  });

  return (
    <>
      <form noValidate onSubmit={handleSubmit((vals) => mutateAsync(vals))}>
        <RooDialog.Content>
          <Grid item xs={12} md={12}>
            <h6>Show in Vendor Pool</h6>
            <FieldMuiSwitch control={control} name={'showInPool'} color="primary" />
          </Grid>
        </RooDialog.Content>
        <RooDialog.Actions>
          <Stack direction={'row'} spacing={2}>
            <Button type="submit" disabled={isLoading}>
              Update
            </Button>
            <Button color={'muted'} disabled={isLoading} onClick={disclosure.close}>
              Close
            </Button>
          </Stack>
        </RooDialog.Actions>
      </form>
    </>
  );
};
