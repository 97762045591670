import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useState } from 'react';
import { Card } from 'react-bootstrap';
import { IssueUserView } from 'shared/api/clients';
import { RooIcon } from 'shared/icons';
import { redirect, Routes } from 'shared/routing';
import { IconLink } from 'components';
import { AddEditIssueModal } from 'pages/Workorders/AddEditIssueModal';
import { useCurrentIssue } from 'pages/Workorders/shared/CurrentIssueContext';
import { rooEnum } from 'shared/store';

export const SubissueList = () => {
  const { subissues, workorder, issue } = useCurrentIssue();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const closeModal = () => setIsModalVisible(false);
  const openModal = () => setIsModalVisible(true);
  return (
    <Card>
      <Card.Header>
        Subissues{' '}
        <button onClick={openModal} className={'btn btn-primary btn-sm float-end slim'}>
          <RooIcon icon={['fas', 'plus']} /> Add
        </button>
      </Card.Header>
      <Card.Body>
        {subissues.length === 0 && (
          <p style={{ textAlign: 'center' }}>
            You can hire subcontractors for this issue. Add a subissue to get started.
          </p>
        )}
        {subissues.length > 0 && (
          <DataTable value={subissues}>
            <Column
              field={'issue.friendlyId'}
              header={'Issue ID'}
              body={(row: IssueUserView) => (
                <IconLink
                  style={{ whiteSpace: 'nowrap' }}
                  to={{
                    pathname: Routes.IssueView,
                    params: { issueId: row.issue.id, workorderId: row.issue.workorderId }
                  }}
                  text={row.issue.friendlyId}
                />
              )}
            />
            <Column field={'issue.title'} header={'Title'} />
            <Column
              field={'cosmeticStatus'}
              header={'Status'}
              body={(row: IssueUserView) => rooEnum.issueStatus.display(row.cosmeticStatus)}
            />
          </DataTable>
        )}
      </Card.Body>
      <AddEditIssueModal
        visible={isModalVisible}
        workorderId={workorder.id}
        managementCompanyId={issue.managementCompanyId}
        propertyId={issue.propertyId}
        parentIssue={issue}
        onCloseRequest={closeModal}
        title={'New Subissue'}
        onIssueSaved={(result) => {
          redirect({ pathname: Routes.IssueView, params: { workorderId: workorder.id, issueId: result.issue.id } });
        }}
      />
    </Card>
  );
};
