import { useState } from 'react';
import { apiProvider } from 'shared/api/apiProvider';
import { ChangeRequest, DeleteChangeRequestPayload } from 'shared/api/clients';
import { ConfirmationModal } from 'components';
import { useCurrentIssue } from 'pages/Workorders/shared/CurrentIssueContext';

export const DeleteChangeRequestModal = ({
  changeRequest,
  visible,
  onClose
}: {
  changeRequest: ChangeRequest;
  visible: boolean;
  onClose: () => void;
}) => {
  const { issue, onIssueUpdate } = useCurrentIssue();
  const [isSaving, setIsSaving] = useState(false);

  const save = async () => {
    setIsSaving(true);
    try {
      const result = await apiProvider.issues.changeRequests.deleteChangeRequest(
        new DeleteChangeRequestPayload({
          changeRequestId: changeRequest.id,
          issueId: issue.id
        })
      );
      onIssueUpdate(result);
      onClose();
    } catch (e) {}
    setIsSaving(false);
  };

  return (
    <ConfirmationModal
      question={'Are you sure you want to delete the current change request?'}
      visible={visible}
      onClose={() => onClose()}
      onSave={() => void save()}
      running={isSaving}
    />
  );
};
