import { TextField } from '@mui/material';
import type { OutlinedTextFieldProps } from '@mui/material/TextField/TextField';
import { FieldValues, useController } from 'react-hook-form';
import { getMuiHelperText } from 'components/form/ErrorMessage';
import { ControlProps } from 'components/form/types';
import { stringTransform } from 'components/form/utils';

type BaseProps = Omit<OutlinedTextFieldProps, 'id' | 'type' | 'name' | 'onChange' | 'value' | 'onBlur' | 'variant'>;
type FinalProps<TForm extends FieldValues> = ControlProps<TForm> & BaseProps & { type?: 'text' | 'password' | 'email' };
export type FieldMuiTextProps<TForm extends FieldValues> = FinalProps<TForm>;

export const FieldMuiText = <T extends FieldValues>({
  name,
  control,
  helperText,
  label,
  type = 'text',
  ...rest
}: FinalProps<T>) => {
  const {
    field,
    fieldState: { error }
  } = useController({
    control: control,
    name: name
  });
  const finalHelperText = getMuiHelperText(helperText, error);
  return (
    <TextField
      label={label}
      name={name}
      type={type}
      error={error != null}
      helperText={finalHelperText}
      value={stringTransform.input(field.value)}
      onBlur={field.onBlur}
      onChange={(e) => field.onChange(stringTransform.output(e))}
      {...rest}
    />
  );
};
