import { useMutation, UseMutationOptions, useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query';
import { apiProvider } from './apiProvider';
import { AddNotePayload, EntityNote, GetNotePayload, ManagementCompany, NoteEntityType, Vendor } from './clients';
import { QueryBatcher } from './queryBatcher';
import { sortBy } from 'lodash';
import { DropdownOption, useCurrentUser } from '../store';

const notesQueryBatcher = new QueryBatcher<GetNotePayload, EntityNote[]>(
  50,
  (args) => apiProvider.notesClient.getForEntities(args),
  (arg, results) => results.filter((x) => x.entityId === arg.entityId && x.entityType === arg.entityType)
);

export const useNotesQuery = ({ entityType, entityId }: { entityType: NoteEntityType; entityId: string }) => {
  return useQuery(
    ['notes', entityType, entityId],
    () =>
      notesQueryBatcher.batchQuery(
        new GetNotePayload({
          entityId: entityId,
          entityType: entityType
        })
      ),
    {
      staleTime: 5 * 60 * 1000,
      retry: false
    }
  );
};

export const useAddNote = (onSuccess?: () => void) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (payload: AddNotePayload) => apiProvider.notesClient.add(payload),
    onSuccess: async (_, variables) => {
      await queryClient.invalidateQueries(['notes', variables.entityType, variables.entityId]);
      onSuccess?.();
    }
  });
};

export const useGetPropertyQuery = (propertyId: string) => {
  return useQuery(['properties', propertyId], () => apiProvider.propertiesClient.getById(propertyId));
};

export const useAdminAllCompanies = () => {
  return useQuery(['companies', 'admin-list-all'], () => apiProvider.adminClient.listCompanies());
};

export const useMyVendors = <TSelected = Vendor[],>(options?: UseQueryOptions<Vendor[], unknown, TSelected>) => {
  const currentUser = useCurrentUser();
  return useQuery(
    ['vendors', 'for-current-user', currentUser.id],
    () => apiProvider.vendorClient.vendorsForUser(currentUser.id),
    options
  );
};

export const useMyVendorsForDropdown = () => {
  return useMyVendors({
    select: (data) =>
      sortBy(
        data.map((x) => ({ label: x.companyName, value: x.id })),
        (x) => x.label
      )
  });
};

const companiesToDropdownPrime = (companies: ManagementCompany[]) => {
  const mapped = companies.map((x) => ({
    label: x.name,
    value: x.id
  }));
  return sortBy(mapped, (x) => x.label);
};

const companiesToDropdownMaterial = (companies: ManagementCompany[]): DropdownOption<string>[] => {
  const mapped = companies.map((x) => ({
    label: x.name,
    value: x.id
  }));
  return sortBy(mapped, (x) => x.label);
};

//TODO: do something smarter
export const useMyCompaniesForDropdownPrime = () => {
  return useQuery(['companies', 'for-current-user'], () => apiProvider.usersClient.getManagementCompanies(), {
    select: companiesToDropdownPrime
  });
};

export const useMyCompaniesForDropdownMaterial = () => {
  return useQuery(['companies', 'for-current-user'], () => apiProvider.usersClient.getManagementCompanies(), {
    select: companiesToDropdownMaterial
  });
};

export const useMyCompanies = () => {
  return useQuery(['companies', 'for-current-user'], () => apiProvider.usersClient.getManagementCompanies());
};

export const useCompanyById = (companyId: string) => {
  return useQuery(['companies', 'by-id', companyId], () => apiProvider.companiesClient.get(companyId), {
    enabled: companyId != null
  });
};

export const useCompanyManagers = (companyId: string) => {
  return useQuery(['companies', 'get-managers', companyId], () => apiProvider.companiesClient.getManagers(companyId), {
    enabled: companyId != null
  });
};

export const useInvalidateCompanies = () => {
  const qc = useQueryClient();
  return () => qc.invalidateQueries(['companies']);
};

export const useCompanyMutation = <TData = unknown, TError = unknown, TVariables = void, TContext = unknown>({
  onSuccess,
  ...rest
}: UseMutationOptions<TData, TError, TVariables, TContext>) => {
  const invalidate = useInvalidateCompanies();
  return useMutation({
    onSuccess: async (data, variables, ctx) => {
      await invalidate();
      await onSuccess?.(data, variables, ctx);
    },
    ...rest
  });
};
