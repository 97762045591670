import { Card, Col, Form, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { apiProvider } from 'shared/api/apiProvider';
import { CreateWorkorderPayload, IProperty, Priority } from 'shared/api/clients';
import { useRooForm } from 'components/form/utils';
import { redirect, Routes } from 'shared/routing';
import { useAppStore, useIsGeneralContractor } from 'shared/store';
import { NavSlim, PageContentSection, SaveButton, UserCard } from 'components';
import { showSuccess, useSearchParams } from 'shared/utils';
import { WorkorderDefinition, WorkorderForm, WorkorderSchema } from './shared/WorkorderForm';
import { Stack, Alert, CircularProgress } from '@mui/material';
import { UseFormWatch } from 'react-hook-form/dist/types/form';
import { useCompanyById, useCompanyManagers } from '../../shared/api/queries';

export const CreateWorkorder = () => {
  const { userDetails } = useAppStore();

  const { searchParams } = useSearchParams<{ companyId: string; propertyId: string }>();
  const presetCompanyId = !Boolean(searchParams?.companyId) ? null : searchParams.companyId;
  const presetPropertyId = !Boolean(searchParams?.propertyId) ? null : searchParams.propertyId;

  const isGc = useIsGeneralContractor();

  const { handleSubmit, watch, control, setValue } = useRooForm(WorkorderSchema, {
    defaultValues: {
      priority: Priority.Medium,
      isEditing: false,
      managementCompanyId: isGc ? presetCompanyId : userDetails.managementCompany.id,
      propertyId: presetPropertyId
    }
  });

  const submit = async (values: WorkorderDefinition) => {
    try {
      const newOrder = await apiProvider.workOrdersClient.create(
        new CreateWorkorderPayload({
          propertyId: values.propertyId,
          notes: values.notes,
          gateCode: values.gateCode,
          lockBox: values.lockBox,
          externalReference: values.externalReference,
          priority: values.priority,
          managementCompanyId: values.managementCompanyId,
          inspectionId: null
        })
      );

      redirect({ pathname: Routes.WorkorderView, params: { id: newOrder.id } });
      showSuccess();
    } catch (err) {}
  };

  return (
    <>
      <Helmet>
        <title>Create Workorder - Walkthroo</title>
      </Helmet>
      <NavSlim breadcrumbs={[{ text: 'New Workorder' }]} />
      <PageContentSection>
        <Row>
          <Col xl={3}>
            <Card>
              <Card.Body>
                <h4>New Workorder</h4>

                <CompanyArea watch={watch} />
              </Card.Body>
            </Card>
          </Col>
          <Col xl={9}>
            <Row>
              <Col xl={12}>
                <Card>
                  <Card.Body>
                    <Form noValidate onSubmit={handleSubmit(submit)}>
                      <Stack spacing={2}>
                        <WorkorderForm watch={watch} control={control} isEditing={false} setValue={setValue} />
                        <Stack alignItems={'flex-end'}>
                          <SaveButton control={control}>Save</SaveButton>
                        </Stack>
                      </Stack>
                    </Form>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </PageContentSection>
    </>
  );
};

const CompanyArea = ({ watch }: { watch: UseFormWatch<WorkorderDefinition> }) => {
  const companyId = watch('managementCompanyId');
  const { data: company, status: companyStatus } = useCompanyById(companyId);
  const { data: managers, status: managersStatus } = useCompanyManagers(companyId);

  if (companyId == null) {
    return <Alert severity={'info'}>Select a client to view more details</Alert>;
  }

  if (companyStatus === 'loading' || managersStatus === 'loading') {
    return <CircularProgress size={32} />;
  }

  if (companyStatus === 'error' || managersStatus === 'error' || company == null) {
    return <Alert severity={'error'}>Couldn't load info about this client.</Alert>;
  }

  return (
    <>
      <dl>
        <dt>Company</dt>
        <dd>{company.name}</dd>
      </dl>

      {managers != null && managers.length > 0 && (
        <>
          <strong>Key Contacts</strong>
          {managers.map((contact) => {
            return <UserCard style={{ marginTop: '10px' }} key={contact.id} user={contact} role={null} />;
          })}
        </>
      )}
    </>
  );
};
