import { useIsGeneralContractor } from '../../../shared/store';
import { useQuery } from '@tanstack/react-query';
import { apiProvider } from '../../../shared/api/apiProvider';
import { Alert } from 'react-bootstrap';
import { WithPlaceholder } from '../../../components';

export const ClientIssueNotice = ({ companyId }: { companyId: string }) => {
  const isGc = useIsGeneralContractor();
  const { isLoading, data } = useQuery(
    ['gc', 'issueNotice', companyId],
    () => apiProvider.generalContractorClient.getClientIssueNotice(companyId),
    {
      enabled: isGc
    }
  );

  if (!isGc || isLoading || data == null || data.trim() === '') {
    return null;
  }

  return (
    <Alert variant={'warning'}>
      <Alert.Heading>PM Details</Alert.Heading>
      <p>
        <WithPlaceholder value={data} />
      </p>
    </Alert>
  );
};
