import React from 'react';
import { Button, Grid, Stack } from '@mui/material';
import { OptionalAddressForm, OptionalAddressSchema } from '../../../shared/utils';
import { ManagementCompany, ModifyAddressPayload, UpdateCompanyAddressPayload } from '../../../shared/api/clients';
import { useStateOptions } from '../../../shared/store';
import { FieldMuiText, FieldSingleSelect, useRooForm } from '../../../components/form';
import { useCompanyMutation } from '../../../shared/api/queries';
import { apiProvider } from '../../../shared/api/apiProvider';
import { RooDialog } from 'components';
import { ReactSelectCommonStyles } from '../../../components/RooSelect';
import { Disclosure } from '@roo/lib';

export const UpdateCompanyAddressModal = ({
  disclosure,
  company
}: {
  disclosure: Disclosure;
  company: ManagementCompany;
}) => {
  return (
    <RooDialog onClose={disclosure.close} fullWidth maxWidth={'sm'} open={disclosure.isOpen}>
      <RooDialog.Title onClose={disclosure.close}>Update Address</RooDialog.Title>
      <UpdateCompanyAddressForm disclosure={disclosure} company={company} />
    </RooDialog>
  );
};

const UpdateCompanyAddressForm = ({ disclosure, company }: { disclosure: Disclosure; company: ManagementCompany }) => {
  const states = useStateOptions();
  const { control, handleSubmit } = useRooForm(OptionalAddressSchema, {
    defaultValues: {
      address1: company.address.address1,
      address2: company.address.address2,
      city: company.address.city,
      zipCode: company.address.zipCode,
      state: company.address.state
    }
  });

  const { isLoading, mutateAsync } = useCompanyMutation({
    mutationFn: (values: OptionalAddressForm) =>
      apiProvider.companiesClient.updateAddress(
        new UpdateCompanyAddressPayload({
          companyId: company.id,
          addressPayload: new ModifyAddressPayload({
            address1: values.address1,
            address2: values.address2,
            city: values.city,
            zipCode: values.zipCode,
            state: values.state
          })
        })
      ),
    onSuccess: () => {
      disclosure.close();
    }
  });

  return (
    <>
      <form noValidate onSubmit={handleSubmit((vals) => mutateAsync(vals))}>
        <RooDialog.Content>
          <Grid item xs={12}>
            <FieldMuiText control={control} name={'address1'} label="Address 1" fullWidth margin="normal" />
            <FieldMuiText control={control} name={'address2'} label="Address 2" fullWidth margin="normal" />
            <FieldMuiText control={control} name={'city'} label="City" fullWidth margin="normal" />
            <Grid container spacing={2}>
              <Grid item xs={12} md={8} sx={{ marginTop: 2 }}>
                <FieldSingleSelect
                  control={control}
                  name={'state'}
                  className="Input"
                  options={states}
                  placeholder={'State'}
                  styles={ReactSelectCommonStyles.MuiLookAlike}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FieldMuiText control={control} name={'zipCode'} label="Zip" fullWidth margin="normal" />
              </Grid>
            </Grid>
          </Grid>
        </RooDialog.Content>
        <RooDialog.Actions>
          <Stack direction={'row'} spacing={2}>
            <Button type="submit" disabled={isLoading}>
              Update
            </Button>
            <Button color={'muted'} disabled={isLoading} onClick={disclosure.close}>
              Close
            </Button>
          </Stack>
        </RooDialog.Actions>
      </form>
    </>
  );
};
