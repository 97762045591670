import { IInvoice, Invoice, IssueUserView, PayInvoicePayload } from '../../../../../shared/api/clients';
import { showSuccess } from '../../../../../shared/utils';
import { useCurrentIssue } from '../../../shared/CurrentIssueContext';
import React, { useEffect, useState } from 'react';
import { apiProvider } from '../../../../../shared/api/apiProvider';
import { ConfirmationModal } from '../../../../../components';
import { InvoiceInfo } from './InvoiceInfoButton';
import { useAppStore, useCurrentUser, useCurrentVendor, useIsGeneralContractor } from 'shared/store';
import { PasswordModal } from 'components/PasswordModal';
import { FormControlLabel, FormGroup, Switch } from '@mui/material';
import { Disclosure, useDisclosure } from '@roo/lib';

export const PayInvoiceModal = ({ invoice, modal }: { invoice: IInvoice; modal: Disclosure }) => {
  const { issue, onIssueUpdate } = useCurrentIssue();
  const [saving, setSaving] = useState(false);

  const currentVendor = useCurrentVendor();
  const currentUser = useCurrentUser();
  const isTestUser = currentUser?.id === '2b2e8ad6-3772-4e19-a864-55db16a5eec5' ?? false;
  const isGeneralContractor = useIsGeneralContractor();

  const [canPayWithDwolla, setCanPayWithDwolla] = useState(isTestUser || isGeneralContractor);

  const [additionalAuth, onAdditionalAuth] = useAppStore((x) => {
    return [x.additionalAuth, x.actions.onAdditionalAuth];
  });

  const pwdModal = useDisclosure(false, {
    onClose: () => {}
  });

  useEffect(() => {
    const getInfo = async () => {
      if (canPayWithDwolla) {
        let result = await apiProvider.paymentsClient.canVendorAccpetDwollaPayment(invoice?.vendor?.id);
        setCanPayWithDwolla(canPayWithDwolla && result);
      }
    };
    void getInfo();
  }, [currentVendor, invoice]);

  const doSave = async () => {
    try {
      setSaving(true);

      let updated: IssueUserView;

      if (canPayWithDwolla && useDwolla) {
        updated = await apiProvider.issues.invoices.payInvoiceWithDwolla(
          new PayInvoicePayload({
            invoiceId: invoice.id,
            issueId: issue.id,
            notes: null
          })
        );
      } else {
        updated = await apiProvider.issues.invoices.payInvoice(
          new PayInvoicePayload({
            invoiceId: invoice.id,
            issueId: issue.id,
            notes: null
          })
        );
      }

      onIssueUpdate(updated);
      showSuccess();
      modal.close();
      setSaving(false);
    } catch (e) {
      setSaving(false);
    }
  };

  const [useDwolla, setUseDwolla] = useState(false);

  const save = () => {
    if (canPayWithDwolla && useDwolla && !additionalAuth) {
      pwdModal.open();
    } else {
      doSave();
    }
  };

  return (
    <>
      <ConfirmationModal
        size={'xl'}
        question={
          <>
            {canPayWithDwolla ? (
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={useDwolla}
                      onChange={(ev, checked) => {
                        setUseDwolla(checked);
                      }}
                    />
                  }
                  label="Pay with Dwolla"
                />
              </FormGroup>
            ) : (
              // <p className={'mb-4'}><Switch label="Pay with Dwolla"></Switch> Do you want to pay this Invoice with dwolla?</p>
              <p className={'mb-4'}>Do you want to mark this Invoice as Paid?</p>
            )}
            <InvoiceInfo invoice={invoice} showPaidOutstanding={true} />
          </>
        }
        visible={modal.isOpen}
        onClose={modal.close}
        onSave={save}
        running={saving}
      />
      {!additionalAuth && (
        <PasswordModal
          modal={pwdModal}
          onSuccess={() => {
            onAdditionalAuth();
            doSave();
          }}
        />
      )}
    </>
  );
};
