import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Checkbox,
  Chip,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  Typography
} from '@mui/material';
import { sortBy } from 'lodash';
import { Column } from 'primereact/column';
import { DataTable, DataTableSelectionChangeParams } from 'primereact/datatable';
import { MultiSelect } from 'primereact/multiselect';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IAddress, IVendor, Vendor, VendorSource } from 'shared/api/clients';
import stockTools from 'images/stock-tools.jpg';
import { Routes } from 'shared/routing';
import {
  AuthManager,
  DropdownOption,
  useCurrentUser,
  useFocusOptions,
  useIsGeneralContractor,
  useIsSpecialUser
} from 'shared/store';
import { RooAvatar, RouterLink } from 'components';
import { RooRating } from 'components/RooRating';
import { makeBasicFilter, rooFmt, rooGeo } from 'shared/utils';
import { apiProvider } from '../../shared/api/apiProvider';
import { MuiIcon } from 'shared/icons';

export type LockedVendor = {
  id: string;
  reason: string;
  color: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | 'default';
};

type SelectionMode = 'none' | 'single' | 'multiple';

type VendorSelectorConfig = {
  referenceAddress: IAddress;
  lockedVendors: LockedVendor[];
  selectionMode: SelectionMode;
  excludedVendors: string[];
};

type VendorSelectorInternals = VendorSelectorConfig &
  (
    | {
        selectionMode: 'none';
      }
    | {
        selectionMode: 'single' | 'multiple';
        handleSelectAll: (checked: boolean, allIds: string[]) => void;
        handleClearAll: () => void;
        handleToggleItem: (id: string) => void;
        selectedIds: string[];
      }
  );

export const vendorTableIcons = [
  <MuiIcon.Build />,
  <MuiIcon.Construction />,
  <MuiIcon.Handyman />,
  <MuiIcon.Brush />,
  <MuiIcon.BuildCircle />,
  <MuiIcon.HomeRepairService />,
  <MuiIcon.SquareFoot />,
  <MuiIcon.Architecture />,
  <MuiIcon.Hardware />,
  <MuiIcon.Plumbing />,
  <MuiIcon.Carpenter />
];

export const useVendorSelector = ({
  lockedVendors,
  selectionMode,
  referenceAddress,
  excludedVendors
}: VendorSelectorConfig & { selectionMode: 'single' | 'multiple' }): VendorSelectorInternals & {
  selectionMode: 'single' | 'multiple';
} => {
  const [selectedIds, setSelectedIds] = useState([]);
  const handleSelectAll = useCallback((checked: any, allIds: any) => setSelectedIds(checked ? [...allIds] : []), []);
  const handleToggleItem = useCallback(
    (id: string) => {
      const isLocked = lockedVendors.find((x) => x.id === id);
      if (isLocked) {
        return;
      }

      const isSelected = selectedIds.indexOf(id) > -1;
      const toKeep = selectionMode === 'multiple' ? selectedIds : [];
      const newSelected = isSelected ? [...selectedIds.filter((x) => x !== id)] : [...toKeep, id];
      setSelectedIds(newSelected);
    },
    [selectedIds, selectionMode, lockedVendors]
  );
  const handleClearAll = useCallback(() => setSelectedIds([]), []);

  return {
    selectedIds,
    selectionMode,
    lockedVendors,
    handleSelectAll,
    handleToggleItem,
    handleClearAll,
    excludedVendors,
    referenceAddress
  };
};

export const VendorSelector = ({ excludedVendors, ...rest }: VendorSelectorInternals) => {
  const currentUser = useCurrentUser();
  const [includePool, setIncludePool] = useState(false);
  const isSpecial = useIsSpecialUser();
  const [{ knownVendors, poolVendors, isLoading }, setState] = useState<{
    isLoading: boolean;
    knownVendors: Vendor[];
    poolVendors: Vendor[];
  }>({ isLoading: true, knownVendors: null, poolVendors: null });
  useEffect(() => {
    setState({ isLoading: true, knownVendors: null, poolVendors: null });
    const load = async () => {
      try {
        const [knownVendors, poolVendors] = await Promise.all([
          apiProvider.vendorClient.vendorsForUser(currentUser.id),
          apiProvider.vendorClient.vendorPoolForUser(currentUser.id)
        ]);
        setState({
          isLoading: false,
          poolVendors,
          knownVendors
        });
      } catch (e) {}
    };
    void load();
  }, [currentUser.id]);

  if (isLoading) {
    return (
      <>
        <Stack p={4} justifyContent={'center'} alignItems={'center'}>
          <CircularProgress size={64} />
        </Stack>
      </>
    );
  }

  let displayVendors = includePool ? [...knownVendors, ...poolVendors] : knownVendors;
  displayVendors = displayVendors.filter((x) => excludedVendors.find((y) => y === x.id) == null);

  return (
    <>
      {isSpecial && (
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
          <Card sx={{ display: 'flex', position: 'relative', flexDirection: { xs: 'column-reverse', md: 'row' } }}>
            <CardContent>
              <Typography variant="h5" component="div" gutterBottom>
                Not finding what you're looking for?
              </Typography>
              <Typography gutterBottom>
                Use the <strong>Vendor Pool</strong> to access our hand-picked list of Vendors.
              </Typography>
              <Typography gutterBottom>
                There are <strong>{poolVendors.length}</strong> vendors in your area waiting to get started.
              </Typography>

              <Box>
                <FormControlLabel
                  control={<Switch checked={includePool} onChange={(e, val) => setIncludePool(val)} />}
                  label="Use Vendor Pool"
                />
              </Box>

              <Typography sx={{ position: 'absolute', bottom: '4px' }} variant={'caption'}>
                May incur additional charges.
              </Typography>
            </CardContent>
            <CardMedia component="img" sx={{ width: { md: 151 }, height: { xs: '220px' } }} image={stockTools} alt="" />
          </Card>
        </Box>
      )}

      <VendorTable vendors={displayVendors} excludedVendors={excludedVendors} {...rest} />
    </>
  );
};

export type TableVendor = IVendor & {
  index: number;
  distance: number;
  serviceIds: number[];
  ownerFirstName: string;
};

export const useTableVendors = ({
  vendors,
  referenceAddress,
  searchRadius
}: {
  vendors: IVendor[];
  referenceAddress: IAddress;
  searchRadius: number;
}) => {
  const tableVendors: TableVendor[] = useMemo(() => {
    const withDistance: Omit<TableVendor, 'index'>[] = vendors.map((x) => {
      const distance = rooGeo.calculateDistanceInMiles(referenceAddress, x.address);

      return {
        ...x,
        ownerFirstName: x.users.length === 0 ? null : x.users[0].firstName,
        distance,
        serviceIds: x.focuses.map((y) => y.id)
      };
    });
    const sorted = sortBy(withDistance, (x) => {
      const coefficient = Math.max(5 - (x.score ?? 2.5), 0);
      const normalizedDistance = x.isPinned ? 0 : x.distance ?? 100;
      return normalizedDistance * coefficient;
    });
    const withIdx = sorted.map((x, idx) => ({ ...x, index: idx }));
    return withIdx.filter(
      (x) => searchRadius == null || x.isPinned || (x.distance != null && x.distance <= searchRadius)
    );
  }, [vendors, referenceAddress, searchRadius]);

  return tableVendors;
};

export const makeServicesColumn = (services: DropdownOption<number>[]) => {
  return (
    <Column
      header={'Services'}
      filter
      filterField="serviceIds"
      showFilterMatchModes={false}
      filterMenuStyle={{ width: '14rem' }}
      showFilterOperator={false}
      showAddButton={false}
      filterElement={(options) => {
        return (
          <MultiSelect
            value={options.value}
            options={services}
            onChange={(e) => options.filterCallback(e.value)}
            showSelectAll={false}
            filter
            optionLabel="label"
            placeholder="Any"
            className="p-column-filter"
          />
        );
      }}
      body={(data: TableVendor) => (
        <Stack direction={'row'} spacing={1} flexWrap={'wrap'}>
          {data.focuses.map((x, idx) => (
            <Chip key={idx} label={x.name} />
          ))}
        </Stack>
      )}
    />
  );
};

export const makeLocationColumn = () => {
  return (
    <Column
      field={'distance'}
      sortable
      header={'Location'}
      body={(data: TableVendor) => (
        <>
          {data.address.city && data.address.state && (
            <>
              <Typography>
                {data.address.city}, {data.address.state}
              </Typography>
              {data.distance != null && (
                <Typography variant={'caption'}>{rooFmt.number(data.distance, 0)} miles away</Typography>
              )}
              {data.distance == null && <Typography variant={'caption'}>Unknown distance</Typography>}
            </>
          )}
          {(!data.address.city || !data.address.state) && (
            <>
              <MuiIcon.NotListedLocation fontSize={'large'} /> <Typography variant={'caption'}>Unknown</Typography>
            </>
          )}
        </>
      )}
    />
  );
};

export const makeReviewsColumn = () => {
  return (
    <Column
      field={'completedJobs'}
      sortable
      header={'Reviews'}
      body={(data: TableVendor) => (
        <>
          {data.completedJobs > 0 && (
            <Box sx={{ display: 'inline-block' }}>
              <RooRating size={'small'} name="read-only" value={data.score} readOnly /> <br />
              <Typography variant={'caption'} sx={{ textAlign: 'center', width: '100%', display: 'block' }}>
                {data.completedJobs} completed jobs
              </Typography>
            </Box>
          )}
          {data.completedJobs <= 0 && (
            <Box sx={{ display: 'inline-block' }}>
              <RooRating size={'small'} name="read-only" value={0} disabled readOnly /> <br />
              <Typography variant={'caption'} sx={{ textAlign: 'center', width: '100%', display: 'block' }}>
                No jobs yet
              </Typography>
            </Box>
          )}
        </>
      )}
    />
  );
};

export const useVendorRadius = ({
  defaultRadius
}: {
  defaultRadius: VendorRadiusOptions;
}): { radius: number; radiusState: VendorRadiusState } => {
  const [radius, setRadius] = useState<VendorRadiusOptions>(defaultRadius);
  const value = radius === '' ? null : parseInt(radius, 10);
  return {
    radius: value,
    radiusState: {
      selectedRadius: radius,
      setRadius: setRadius
    }
  };
};

type VendorRadiusState = {
  selectedRadius: VendorRadiusOptions;
  setRadius: (radius: VendorRadiusOptions) => void;
};

type VendorRadiusOptions = '25' | '50' | '100' | '';

export const VendorRadiusSelector = ({ state }: { state: VendorRadiusState }) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    state.setRadius((event.target as HTMLInputElement).value as VendorRadiusOptions);
  };
  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">Search Radius</FormLabel>
      <RadioGroup
        value={state.selectedRadius}
        onChange={handleChange}
        row
        aria-label="radius"
        name="row-radio-buttons-group"
      >
        <FormControlLabel value="25" control={<Radio />} label="25 mi" />
        <FormControlLabel value="50" control={<Radio />} label="50 mi" />
        <FormControlLabel value="100" control={<Radio />} label="100 mi" />
        <FormControlLabel value="" control={<Radio />} label="Any" />
      </RadioGroup>
    </FormControl>
  );
};

const VendorTable = (props: { vendors: Vendor[] } & VendorSelectorInternals) => {
  const { lockedVendors, selectionMode, referenceAddress, vendors } = props;
  const services = useFocusOptions();
  const { radius, radiusState } = useVendorRadius({ defaultRadius: '25' });

  const tableVendors = useTableVendors({ vendors, referenceAddress, searchRadius: radius });

  const [filters] = useState({
    companyName: { operator: 'AND', constraints: [{ value: '', matchMode: 'contains' }] },
    ownerFirstName: { operator: 'AND', constraints: [{ value: '', matchMode: 'contains' }] },
    serviceIds: { value: null, matchMode: 'arrayIntersect' }
  });

  const isDisabled = (id: string) => lockedVendors.find((x) => x.id === id) != null;
  let isSelected = (id: string) => false;
  let onSelectionChange = (e: DataTableSelectionChangeParams) => {};
  let onItemClick = (id: string) => {};
  let selection: TableVendor[] = [];

  if (selectionMode !== 'none') {
    const { selectedIds, handleToggleItem } = props;
    isSelected = (id: string) => lockedVendors.find((x) => x.id === id) != null || selectedIds.indexOf(id) !== -1;
    selection = tableVendors.filter((x) => isSelected(x.id));
    onItemClick = (id) => handleToggleItem(id);
    onSelectionChange = (e) => {
      let id: string = null;
      if (Array.isArray(e.value)) {
        id = e.value[0].id;
      } else {
        id = e.value.id;
      }

      handleToggleItem(id);
    };
  }

  return (
    <>
      <Box>
        <VendorRadiusSelector state={radiusState} />
      </Box>
      <Box sx={{ width: '100%' }}>
        <DataTable
          responsiveLayout={'scroll'}
          dataKey={'id'}
          filters={filters as any}
          value={tableVendors}
          paginator
          rows={5}
          selection={selection}
          onSelectionChange={onSelectionChange}
          selectionMode={selectionMode === 'none' ? undefined : selectionMode}
        >
          {selectionMode !== 'none' && (
            <Column
              style={{ width: '50px' }}
              body={(data: TableVendor) => {
                const isItemDisabled = isDisabled(data.id);
                const isItemSelected = isSelected(data.id);
                return (
                  <>
                    {selectionMode === 'single' && (
                      <Radio
                        color="primary"
                        disabled={isItemDisabled}
                        checked={isItemSelected}
                        onClick={() => onItemClick(data.id)}
                      />
                    )}
                    {selectionMode === 'multiple' && (
                      <Checkbox
                        color="primary"
                        disabled={isItemDisabled}
                        checked={isItemSelected}
                        onClick={() => onItemClick(data.id)}
                      />
                    )}
                  </>
                );
              }}
            />
          )}

          <Column
            header={'Company'}
            field={'companyName'}
            sortable
            {...makeBasicFilter()}
            body={(data: TableVendor) => {
              const lockReason = lockedVendors.find((x) => x.id === data.id);
              return (
                <Box sx={{ display: 'flex' }}>
                  <RooAvatar size={'l'} name={data.companyName} avatarUrl={data.avatarUrl}>
                    {vendorTableIcons[data.index % vendorTableIcons.length]}
                  </RooAvatar>
                  <Box sx={{ ml: 2 }}>
                    <Typography gutterBottom sx={{ fontWeight: 'bold' }}>
                      <RouterLink to={{ pathname: Routes.VendorProfile, params: { vendorId: data.id } }}>
                        {data.companyName}
                      </RouterLink>
                    </Typography>
                    <Stack direction="row" spacing={1}>
                      {lockReason && <Chip label={lockReason.reason} size={'small'} color={lockReason.color} />}
                      {data.vendorSource === VendorSource.Contact && (
                        <Chip label="Contact" size={'small'} color={'success'} />
                      )}
                      {data.vendorSource === VendorSource.VendorPool && (
                        <Chip label="Vendor Pool" size={'small'} color={'error'} />
                      )}

                      {!data.users[0].isActive && <Chip label="Inactive" size={'small'} />}
                    </Stack>
                  </Box>
                </Box>
              );
            }}
          />
          <Column header={'First Name'} field={'ownerFirstName'} sortable {...makeBasicFilter()} />
          {makeServicesColumn(services)}
          {makeLocationColumn()}
          {makeReviewsColumn()}
        </DataTable>
      </Box>
    </>
  );
};
