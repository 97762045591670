import { CapabilityType, DescribedDashboardStat, Membership } from 'shared/api/clients';
import { createContext } from 'react';

export const roleFromMembership = (memberships: Membership[]) => {
  const capabilities = memberships.flatMap((x) => x.capabilities);
  let role = CapabilityType.Unknown;
  if (
    capabilities.find((x) => x === CapabilityType.ManagementCompanyOwner || x === CapabilityType.PropertyManager) !=
    null
  ) {
    role = CapabilityType.PropertyManager;
  } else if (capabilities.find((x) => x === CapabilityType.PropertyOwner)) {
    role = CapabilityType.PropertyOwner;
  } else if (capabilities.find((x) => x === CapabilityType.Vendor)) {
    role = CapabilityType.Vendor;
  } else if (capabilities.find((x) => x === CapabilityType.Tenant)) {
    role = CapabilityType.Tenant;
  }

  return role;
};

export const DashboardContext = createContext<{
  setStat: (stat: DescribedDashboardStat, isLoading: boolean) => void;
  setIsLoading: (isLoading: boolean) => void;
  statIsLoading: boolean;
  currentStat: DescribedDashboardStat;
}>(null);
