import { Disclosure, useDisclosure } from '@roo/lib';
import { RooDialog } from '../../../../../../components';
import { useMutation, useQuery } from '@tanstack/react-query';
import { apiProvider } from '../../../../../../shared/api/apiProvider';
import { CenteredLoader } from '../../../../../../components/CenteredLoader';
import { Alert, Button, Divider, Stack, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { useCurrentIssue } from '../../../../shared/CurrentIssueContext';
import React, { useState } from 'react';
import { sumBy } from 'lodash';
import { rooFmt, showSuccess } from '../../../../../../shared/utils';
import { LoadingButton } from '@mui/lab';
import { BidAction, BidSplitLineWriteModel, GetDataForBidSplitResponse, UpdateBidSplitPayload } from '@roo/api';
import { bidActionList, bidModalComponents } from './actions';
import { MuiIcon } from 'shared/icons';
import { createModalStore } from '../../../../../../shared/store';

const split = bidActionList.register({
  key: 'split',
  actionType: BidAction.Split,
  color: 'primary',
  label: 'Split',
  Icon: MuiIcon.CallSplit,
  onClick: (bidView) =>
    useBidSplitModalStore.getState().actions.show({
      bidId: bidView.bid.id
    })
});

const updateSplit = bidActionList.register({
  key: 'split',
  actionType: BidAction.UpdateSplit,
  color: 'primary',
  label: 'Update Split',
  Icon: MuiIcon.CallSplit,
  onClick: (bidView) =>
    useBidSplitModalStore.getState().actions.show({
      bidId: bidView.bid.id
    })
});

export const bidSplitActionList = [split, updateSplit];

type ModalPayload = {
  bidId: string;
};
const useBidSplitModalStore = createModalStore<ModalPayload>();
const StoreSplitBidModal = () => {
  const { payload, visible, actions } = useBidSplitModalStore();
  const open = () => {
    throw new Error('attempt to open global modal directly');
  };

  return (
    <SplitBidModal
      bidId={payload?.bidId}
      modal={{
        toggle: () => (visible ? open() : actions.hide()),
        close: actions.hide,
        open: open,
        isOpen: visible
      }}
    />
  );
};

bidModalComponents.push(<StoreSplitBidModal />);

export const SplitBidModal = ({ bidId, modal }: { bidId: string; modal: Disclosure }) => {
  return (
    <RooDialog scroll={'paper'} maxWidth={'sm'} fullWidth open={modal.isOpen} onClose={modal.close}>
      <RooDialog.Title onClose={modal.close}>Split Bid</RooDialog.Title>
      <SplitModalBody bidId={bidId} modal={modal} />
    </RooDialog>
  );
};

const SplitModalBody = ({ bidId, modal }: { bidId: string; modal: Disclosure }) => {
  const { data, status } = useQuery(['bid', bidId, 'split'], () => apiProvider.issues.bids.getBidSplitData(bidId), {
    enabled: bidId != null
  });

  if (status === 'loading') {
    return (
      <RooDialog.Content>
        <CenteredLoader />
      </RooDialog.Content>
    );
  }

  if (status === 'error') {
    return (
      <RooDialog.Content>
        <Alert color={'error'}>Failed loading data.</Alert>
      </RooDialog.Content>
    );
  }

  return <SplitModalForm bidId={bidId} data={data} modal={modal} />;
};

type Flag = 'tenant' | 'owner';
type FormState = Record<string, string[]>;
const SplitModalForm = ({
  bidId,
  data,
  modal
}: {
  bidId: string;
  data: GetDataForBidSplitResponse;
  modal: Disclosure;
}) => {
  const { onIssueUpdate } = useCurrentIssue();
  const def = data.lines.reduce(
    (prev, curr) => ({
      ...prev,
      [curr.lineId]: [curr.isOwner ? 'owner' : null, curr.isTenant ? 'tenant' : null].filter((x) => x != null) as Flag[]
    }),
    {} as FormState
  );

  const [state, setState] = useState<FormState>(def);
  const canSave = Object.entries(state).filter((x) => x[1].length === 0).length === 0;
  const perPartyShare = data.lines.map((x) => {
    const stateMatch = state[x.lineId];
    const ownerMul = stateMatch.includes('owner') ? 1.0 / stateMatch.length : 0;
    const tenantMul = stateMatch.includes('tenant') ? 1.0 / stateMatch.length : 0;

    return { owner: x.amount * ownerMul, tenant: x.amount * tenantMul };
  });

  const ownerShare = sumBy(perPartyShare, (x) => x.owner);
  const tenantShare = sumBy(perPartyShare, (x) => x.tenant);
  const totalShare = ownerShare + tenantShare;
  const ownerPerc = totalShare === 0 ? 0 : (ownerShare / totalShare) * 100;
  const tenantPerc = totalShare === 0 ? 0 : (tenantShare / totalShare) * 100;

  const { mutate, isLoading } = useMutation({
    mutationFn: () =>
      apiProvider.issues.bids.updateBidSplit(
        new UpdateBidSplitPayload({
          bidId: bidId,
          existingSplitId: data.existingSplitId,
          lines: data.lines.map(
            (x) =>
              new BidSplitLineWriteModel({
                lineId: x.lineId,
                lineDescription: x.description,
                amount: x.amount,
                isOwner: state[x.lineId].includes('owner'),
                isTenant: state[x.lineId].includes('tenant')
              })
          )
        })
      ),
    onSuccess: (data) => {
      onIssueUpdate(data);
      showSuccess();
      modal.close();
    }
  });

  return (
    <>
      <RooDialog.Content>
        <Stack gap={2}>
          <Stack direction={'column'} gap={1} alignItems={'center'}>
            <Typography>
              Owner share: {rooFmt.money(ownerShare)} ({rooFmt.percent(ownerPerc)})
            </Typography>
            <Typography>
              Tenant share: {rooFmt.money(tenantShare)} ({rooFmt.percent(tenantPerc)})
            </Typography>
          </Stack>

          {data.lines.map((line, i) => (
            <Stack gap={1}>
              <Stack key={line.lineId} direction={'row'} justifyContent={'space-between'} alignItems={'center'} gap={1}>
                <Stack direction={'row'} gap={2} alignItems={'center'}>
                  <ToggleButtonGroup
                    color={'primary'}
                    sx={{ lineHeight: 1 }}
                    value={state[line.lineId]}
                    onChange={(ev, val) => {
                      setState((curr) => ({ ...curr, [line.lineId]: val }));
                    }}
                  >
                    <ToggleButton sx={{ lineHeight: 1 }} value="tenant" aria-label="left aligned">
                      <Stack spacing={1} direction={'row'}>
                        <span>T</span>
                      </Stack>
                    </ToggleButton>
                    <ToggleButton sx={{ lineHeight: 1 }} value="owner" aria-label="centered">
                      <Stack spacing={1} direction={'row'}>
                        <span>O</span>
                      </Stack>
                    </ToggleButton>
                  </ToggleButtonGroup>
                  <Typography>{line.description}</Typography>
                </Stack>
                <Typography>{rooFmt.money(line.amount)}</Typography>
              </Stack>
              {i < data.lines.length - 1 && <Divider />}
            </Stack>
          ))}
        </Stack>
      </RooDialog.Content>
      <RooDialog.Actions>
        <Stack sx={{ width: '100%' }}>
          <Typography fontSize={12}>Tip: you can select both T and O for a 50%/50% split</Typography>
          {!canSave && (
            <Typography fontSize={12} color={'warning.main'}>
              Split for all lines before saving!
            </Typography>
          )}
          <Stack direction={'row'} justifyContent={'flex-end'}>
            <LoadingButton loading={isLoading} disabled={!canSave} onClick={() => mutate()}>
              Save
            </LoadingButton>
            <Button color={'secondary'} onClick={modal.close}>
              Cancel
            </Button>
          </Stack>
        </Stack>
      </RooDialog.Actions>
    </>
  );
};
