import { Box, Chip, CircularProgress, Stack, styled, Typography } from '@mui/material';
import { Button as ButtonUnstyled } from '@mui/base';
import { UiColor } from '../shared/api/clients';
import { rooFmt } from '../shared/utils';
import * as React from 'react';
import { MuiIcon } from 'shared/icons';
import { assertNever } from '@roo/lib';

const StatCardContainer = styled(ButtonUnstyled, { shouldForwardProp: (prop) => prop !== 'emphasized' })<{
  emphasized: boolean;
}>(({ theme, emphasized }) => ({
  backgroundColor: emphasized ? theme.palette.error.main : 'white',
  border: 'none',
  padding: '16px',
  color: emphasized ? 'white' : '#3a3f63'
}));

export const StatCard = ({
  stat,
  isLoading,
  isSelected,
  onClick
}: {
  stat: {
    hasDetails: boolean;
    value: number;
    emphasized: boolean;
    displayDecimals: number;
    displayName: string;
    delayedCount: number;
    assigneeBadge: { text: string; color: UiColor };
  };
  isLoading: boolean;
  isSelected: boolean;
  onClick: () => void;
}) => {
  const clickable = stat.hasDetails && stat.value !== 0 && !isLoading && !isSelected;

  return (
    <StatCardContainer emphasized={stat.emphasized} disabled={!clickable} onClick={onClick}>
      <Stack spacing={1} justifyContent={'center'} sx={{ height: '100%' }} alignItems={'center'} direction={'column'}>
        <Box
          sx={{
            fontSize: '48px',
            lineHeight: '48px'
          }}
        >
          {isLoading && <CircularProgress />}
          {!isLoading && <>{rooFmt.number(stat.value, stat.displayDecimals)}</>}
        </Box>

        <Box sx={{ textAlign: 'center', fontSize: '14px' }}>{stat.displayName}</Box>
        <Stack direction={'row'} alignItems={'center'} spacing={1}>
          {Boolean(stat.delayedCount) && (
            <SmallChip
              color={'error'}
              label={
                <Stack sx={{ fontSize: '14px' }} direction={'row'} alignItems={'center'}>
                  <Typography sx={{ color: 'white' }} fontSize={'inherit'}>
                    {stat.delayedCount}
                  </Typography>
                  <MuiIcon.AccessAlarm fontSize={'inherit'} sx={{ color: 'white' }} />
                </Stack>
              }
            />
          )}
          {stat.assigneeBadge && (
            <SmallChip
              color={stat.emphasized ? 'secondary' : enumToColor(stat.assigneeBadge.color)}
              label={
                <Stack sx={{ fontSize: '14px' }} direction={'row'} alignItems={'center'}>
                  <MuiIcon.TransferWithinAStation
                    fontSize={'inherit'}
                    sx={{ color: stat.emphasized ? 'black' : 'white' }}
                  />
                  <Typography sx={{ color: stat.emphasized ? 'black' : 'white' }} fontSize={'inherit'}>
                    {stat.assigneeBadge.text}
                  </Typography>
                </Stack>
              }
            />
          )}
        </Stack>
      </Stack>
    </StatCardContainer>
  );
};

const enumToColor = (enumVal: UiColor) => {
  switch (enumVal) {
    case UiColor.Default:
      return 'default';
    case UiColor.Success:
      return 'success';
    case UiColor.Error:
      return 'error';
    case UiColor.Warning:
      return 'warning';
    case UiColor.Primary:
      return 'primary';
    case UiColor.Secondary:
      return 'secondary';
    default:
      assertNever(enumVal);
  }

  return 'default';
};

const SmallChip = styled(Chip)((theme) => ({
  fontSize: '12px',
  height: '24px',
  cursor: 'inherit'
}));
