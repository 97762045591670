import { Box, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Stack, Typography } from '@mui/material';
import { ConfirmationModal, RooButton } from 'components';
import { RooRating } from 'components/RooRating';
import { useCurrentIssue } from 'pages/Workorders/shared/CurrentIssueContext';
import { useState } from 'react';
import { apiProvider } from 'shared/api/apiProvider';
import { CancelIssuePayload, IssueStatus } from 'shared/api/clients';
import { showSuccess } from 'shared/utils';

type ReasonValue = 'owner-declined' | 'lost-bid' | 'other';

export const CancelIssueButton = () => {
  const { issue, onIssueUpdate } = useCurrentIssue();
  const [isConfirming, setIsConfirming] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [notes, setNotes] = useState<string>('');
  const [rating, setRating] = useState<number>(null);
  const [reason, setReason] = useState<ReasonValue>(null);
  const reasonMap: Record<ReasonValue, IssueStatus> = {
    other: IssueStatus.CanceledOther,
    'lost-bid': IssueStatus.CanceledLostBid,
    'owner-declined': IssueStatus.CanceledOwnerDeclined
  };
  const canSubmit = reason != null;

  const save = async () => {
    setIsSaving(true);
    try {
      const result = await apiProvider.issues.workflow.cancelIssue(
        new CancelIssuePayload({
          issueId: issue.id,
          oldStatus: issue.status,
          newStatus: reasonMap[reason],
          notes: notes?.trim() === '' ? null : notes,
          rating: rating
        })
      );

      onIssueUpdate(result);
      showSuccess();
    } catch (e) {}

    setIsSaving(false);
    setIsConfirming(false);
  };
  return (
    <>
      <RooButton onClick={() => setIsConfirming(true)} variant={'danger'} icon={'stop-circle'}>
        Cancel
      </RooButton>
      <ConfirmationModal
        visible={isConfirming}
        running={isSaving}
        title={'Do you want to cancel this issue?'}
        question={
          <>
            <Stack spacing={2} direction={'column'} justifyContent={'center'} alignItems={'center'}>
              <FormControl>
                <FormLabel id="reason-group-label">Reason</FormLabel>
                <RadioGroup
                  value={reason}
                  onChange={(_, val) => setReason(val as ReasonValue)}
                  aria-labelledby="reason-group-label"
                  name="reason-buttons-group"
                >
                  <FormControlLabel value="owner-declined" control={<Radio />} label="Owner Declined" />
                  <FormControlLabel value="lost-bid" control={<Radio />} label="Lost Bid" />
                  <FormControlLabel value="other" control={<Radio />} label="Other" />
                </RadioGroup>
              </FormControl>
              {issue.vendor != null && (
                <>
                  <Typography>
                    How would you rate <strong>{issue.vendor.companyName}</strong>? (optional)
                  </Typography>
                  <RooRating size={'large'} value={rating} onChange={(val) => setRating(val)} />
                </>
              )}
              <Box sx={{ width: '100%' }}>
                <label htmlFor={'notes-input'}>Notes</label>
                <textarea
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                  className={'form-control'}
                  id={'notes-input'}
                  rows={3}
                  placeholder={'Your comments'}
                />
              </Box>
            </Stack>
          </>
        }
        confirmDisabled={!canSubmit}
        onClose={() => setIsConfirming(false)}
        onSave={() => {
          void save();
        }}
      />
    </>
  );
};
