import { useCurrentIssue } from '../../../shared/CurrentIssueContext';
import { RooDialog } from '../../../../../components';
import { Stack } from '@mui/material';
import { InvoiceForm } from '../Invoices/InvoiceForm';

export const CompleteWorkStepInvoiceEdit = ({
  onClose,
  onContinue,
  invoiceId
}: {
  onClose: () => void;
  onContinue: () => void;
  invoiceId: string;
}) => {
  const { visibleInvoices } = useCurrentIssue();
  const invoice = visibleInvoices.find((x) => x.id === invoiceId);
  if (invoice == null) {
    throw new Error(`Invoice ${invoiceId} not found`);
  }
  return (
    <>
      <RooDialog.Title onClose={onClose}>Edit Invoice</RooDialog.Title>
      <RooDialog.Content>
        <Stack spacing={2}>
          <InvoiceForm
            onComplete={onContinue}
            invoice={invoice}
            allowImplicitLines={true}
            preferMatchDraft={false}
            isPartial={false}
          />
        </Stack>
      </RooDialog.Content>
    </>
  );
};
