import { Alert, AlertTitle } from '@mui/material';
import { Center } from './Center';
import { ReactNode } from 'react';

export const InlineError = ({ title, children }: { title?: ReactNode; children?: ReactNode }) => {
  const finalTitle = title ?? 'An error occurred';
  const finalChildren = children ?? 'Please try again later';
  return (
    <Center p={'xl'}>
      <Alert severity={'error'}>
        <AlertTitle>{finalTitle}</AlertTitle>
        {finalChildren}
      </Alert>
    </Center>
  );
};
