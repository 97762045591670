import { useState } from 'react';
import { RooIcon } from 'shared/icons';
import { RooDialog } from 'components/RooDialog';
import { Button, TextField } from '@mui/material';
import { apiProvider } from 'shared/api/apiProvider';
import { Disclosure } from '@roo/lib';

export const PasswordModal = ({ modal, onSuccess }: { modal: Disclosure; onSuccess: () => void }) => {
  const finalTitle = 'To continue, please enter your password.';

  const [running, setRunning] = useState(false);
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const onAuth = async () => {
    try {
      setRunning(true);
      let result = await apiProvider.authClient.checkPassword(password);
      if (result) {
        setError('');
        onSuccess();
      } else {
        setError('The password is invalid!');
      }
    } finally {
      setRunning(false);
    }
  };

  return (
    <RooDialog fullWidth open={modal.isOpen} onClose={modal.close}>
      <RooDialog.Title onClose={modal.close}>{finalTitle}</RooDialog.Title>
      <RooDialog.Content>
        <TextField
          type={'password'}
          margin="normal"
          name={'enterPassword'}
          autoComplete="new-password"
          label={'Password'}
          fullWidth
          required
          error={!!error}
          helperText={error}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
        />
      </RooDialog.Content>
      <RooDialog.Actions>
        <Button color="primary" onClick={onAuth} disabled={running} sx={{ mr: 1 }}>
          {running ? <RooIcon icon={['fas', 'circle-notch']} spin={running} style={{ fontSize: 23 }} /> : 'Ok'}
        </Button>
        <Button color="muted" onClick={modal.close} disabled={running}>
          Cancel
        </Button>
      </RooDialog.Actions>
    </RooDialog>
  );
};
