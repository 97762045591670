import { RooIcon } from 'shared/icons';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { apiProvider } from 'shared/api/apiProvider';
import { LogInTwoFactorPayload } from 'shared/api/clients';
import { redirect, RouteDestination, Routes } from 'shared/routing';
import { AuthManager } from 'shared/store/authManager';
import { RouterLink } from 'components/Links';
import { LoaderBalls } from 'components/LoaderBalls';
import { useQueryParams } from 'shared/utils';

export const TwoFactor = () => {
  const { token, userId } = useQueryParams<{ token: string; userId: string }>();
  if (token != null) {
    return <ConfirmPending token={token} userId={userId} />;
  }

  return <EmailSentInfo />;
};

const ConfirmPending = ({ token, userId }: { token: string; userId: string }) => {
  const [state, setState] = useState<'pending' | 'bad-token' | 'error'>('pending');
  useEffect(() => {
    const run = async () => {
      try {
        const result = await apiProvider.authClient.logInTwoFactor(
          new LogInTwoFactorPayload({
            userId: userId,
            token: token
          })
        );
        if (result && result.success) {
          AuthManager.instance.logIn(result.token, true);
          redirect('/' as RouteDestination);
        } else {
          setState('bad-token');
        }
      } catch (e) {
        setState('error');
      }
    };
    void run();
    //eslint-disable-next-line
  }, []);
  return (
    <>
      <Helmet>
        <title>Confirming your login - Walkthroo</title>
      </Helmet>
      <div className="container confirmation-page">
        <div>
          <div className={'text-center mb-2'}>
            <RooIcon
              size={'6x'}
              style={{ opacity: '0.7' }}
              className={state === 'pending' ? 'text-primary' : 'text-danger'}
              icon={'envelope-open-text'}
            />
          </div>
          {state === 'pending' && <h1 className={'text-center text-muted mb-4'}>Confirming your login...</h1>}
          {state === 'bad-token' && <h1 className={'text-center text-muted mb-4'}>Invalid confirmation link</h1>}
          {state === 'error' && <h1 className={'text-center text-muted mb-4'}>Failed confirmation</h1>}

          {state === 'pending' && (
            <div className={'text-center'}>
              <LoaderBalls />
            </div>
          )}
          {state === 'error' && (
            <div className={'text-center'}>Something happened and we couldn't log you in. Please try again later.</div>
          )}
          {state === 'bad-token' && (
            <div className={'text-center'}>
              The confirmation link you used was not valid or has expired.{' '}
              <RouterLink to={Routes.LogIn}>Log in</RouterLink> again to create a new one.
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const EmailSentInfo = () => {
  return (
    <>
      <Helmet>
        <title>Confirm your e-mail - Walkthroo</title>
      </Helmet>
      <div className="container confirmation-page">
        <div>
          <div className={'text-center mb-2'}>
            <RooIcon size={'6x'} style={{ opacity: '0.7' }} className={'text-primary'} icon={'envelope-open-text'} />
          </div>
          <h1 className={'text-center text-muted mb-4'}>Confirm your e-mail</h1>
          <p className={'mb-2 text-center'}>
            We're almost done, we just need you to click the link we sent to your e-mail address.
          </p>
          <p className={'mb-4 text-center'}>
            Don't see it? Wait a few minutes and make sure to check your spam folder.
          </p>
        </div>
      </div>
    </>
  );
};
