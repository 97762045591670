import React, { ReactElement, ReactNode } from 'react';
import { ErrorScreen } from './ErrorScreen';
import * as Sentry from '@sentry/react';
import { FallbackRender } from '@sentry/react/types/errorboundary';

export const ErrorBoundary = ({
  children,
  fallback
}: {
  children: ReactNode;
  fallback: ReactElement | FallbackRender;
}) => {
  return <Sentry.ErrorBoundary fallback={ErrorScreen}>{children}</Sentry.ErrorBoundary>;
};
