import { CapabilityType, CompanyFeatureFlag } from '../../shared/api/clients';
import { useMyCompaniesForDropdownMaterial, useMyVendorsForDropdown } from '../../shared/api/queries';
import {
  useAccountManagersOptions,
  useHasCapability,
  useHasFeatureFlag,
  useIsGeneralContractor,
  useStateOptions
} from '../../shared/store';
import { Box, Button, TextField } from '@mui/material';
import { RouterLink } from '../../components';
import { MuiIcon } from '../../shared/icons';
import { Routes } from '../../shared/routing';
import { RooMuiMultipleSelect } from '../../components/RooMuiMultipleSelect';
import { RooMuiSingleSelect } from '../../components/RooMuiSingleSelect';
import { Dispatch, SetStateAction, useState } from 'react';
import { debounce } from 'lodash';

export type DashboardFilters = {
  companyId: string;
  vendorIds: string[];
  stateCode: string;
  handlerId: string;
  city: string;
};

export const ActionsHeader = ({
  filters,
  setFilters
}: {
  filters: DashboardFilters;
  setFilters: Dispatch<SetStateAction<DashboardFilters>>;
}) => {
  const isGc = useIsGeneralContractor();
  const isManager = useHasCapability(CapabilityType.PropertyManager);
  const isManagerType = isGc || isManager;
  const isVendor = useHasCapability(CapabilityType.Vendor);
  const hasDashFilters = useHasFeatureFlag(CompanyFeatureFlag.DashboardFilters);
  const isGcWithFilters = isGc && hasDashFilters;

  return (
    <Box
      sx={{ display: 'flex', justifyContent: { xs: 'center', md: 'flex-end', alignItems: 'flex-end' } }}
      flexWrap={'wrap'}
    >
      {(isVendor || isGcWithFilters) && (
        <CompanyFilter value={filters.companyId} onChange={(val) => setFilters({ ...filters, companyId: val })} />
      )}
      {(isGcWithFilters || isManager) && (
        <VendorsFilter value={filters.vendorIds} onChange={(val) => setFilters({ ...filters, vendorIds: val })} />
      )}
      {isGcWithFilters && (
        <>
          <StateFilter value={filters.stateCode} onChange={(val) => setFilters({ ...filters, stateCode: val })} />
          <CityFilter value={filters.city} onChange={(val) => setFilters((flt) => ({ ...flt, city: val }))} />
          <AccountManagerFilter
            value={filters.handlerId}
            onChange={(val) => setFilters({ ...filters, handlerId: val })}
          />
        </>
      )}
      {isManagerType && (
        <Button
          sx={{ marginLeft: '10px' }}
          size={'large'}
          color="primary"
          startIcon={<MuiIcon.Add />}
          component={RouterLink}
          to={{ pathname: Routes.WorkorderCreate }}
        >
          Add Workorder
        </Button>
      )}
    </Box>
  );
};

type FilterProps<T> = {
  value: T;
  onChange: (val: T) => void;
};

const CompanyFilter = ({ value, onChange }: FilterProps<string>) => {
  const { isLoading, data: companies } = useMyCompaniesForDropdownMaterial();

  return (
    <RooMuiSingleSelect
      loading={isLoading}
      sx={{ minWidth: '300px', marginLeft: '10px' }}
      placeholder={'Choose a company'}
      options={companies}
      value={value}
      onChange={onChange}
    />
  );
};

const VendorsFilter = ({ value, onChange }: FilterProps<string[]>) => {
  const { isLoading, data: vendors } = useMyVendorsForDropdown();

  return (
    <RooMuiMultipleSelect
      loading={isLoading}
      sx={{ minWidth: '300px', marginLeft: '10px' }}
      options={vendors}
      value={value}
      placeholder={'Choose Vendors'}
      onChange={(val) => onChange(val)}
    />
  );
};

const StateFilter = ({ value, onChange }: FilterProps<string>) => {
  const states = useStateOptions();
  return (
    <RooMuiSingleSelect
      sx={{ width: '300px', marginLeft: '10px' }}
      options={states}
      value={value}
      placeholder={'Choose state'}
      onChange={onChange}
    />
  );
};

const CityFilter = ({ value, onChange }: FilterProps<string>) => {
  const [debouncedOnChange] = useState(() => debounce(onChange, 300));
  return (
    <TextField
      sx={{ width: '300px', marginLeft: '10px' }}
      variant={'standard'}
      size={'small'}
      label="City"
      defaultValue={value}
      onChange={(e) => {
        debouncedOnChange(e.target.value);
      }}
    />
  );
};

const AccountManagerFilter = ({ value, onChange }: FilterProps<string>) => {
  const accountManagerOpts = useAccountManagersOptions();
  if (accountManagerOpts == null || accountManagerOpts.length === 0) {
    return null;
  }

  return (
    <RooMuiSingleSelect
      sx={{ width: '300px', marginLeft: '10px' }}
      placeholder={'Choose acct manager'}
      options={accountManagerOpts}
      value={value}
      onChange={onChange}
    />
  );
};
