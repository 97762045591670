import { Stack, Tab, Tabs } from '@mui/material';
import { prepareRouterHrefAttrs, Routes } from '../../shared/routing';
import React from 'react';

export const PaymentsNav = ({ value }: { value: number }) => {
  return (
    <Stack sx={{ backgroundColor: 'white' }}>
      <Tabs value={value}>
        <Tab component={'a'} label="Accounts Receivable" {...prepareRouterHrefAttrs(Routes.AccountsReceivable)} />
        <Tab component={'a'} label="Accounts Payable" {...prepareRouterHrefAttrs(Routes.AccountsPayable)} />
        <Tab component={'a'} label="Payees" {...prepareRouterHrefAttrs(Routes.Payees)} />
        <Tab component={'a'} label="P&L" {...prepareRouterHrefAttrs(Routes.ProfitLoss)} />
      </Tabs>
    </Stack>
  );
};
