import {
  Box,
  Button,
  Card as MuiCard,
  CardContent,
  FormControlLabel,
  IconButton,
  Stack,
  Switch,
  Typography
} from '@mui/material';
import { RooButton, RouterLink, UserLink } from 'components';
import { useCurrentIssue } from 'pages/Workorders/shared/CurrentIssueContext';
import { ActionsContainer } from 'pages/Workorders/ViewIssue/Documents/ActionsContainer';
import { ActionsMenu, ActionsMenuItem } from 'components/ActionsMenu';
import { BidInfoButton } from 'pages/Workorders/ViewIssue/Documents/Bids/BidList/BidInfoButton';
import { bidReviewActionList, ReviewBidModal } from 'pages/Workorders/ViewIssue/Documents/Bids/BidList/BidReview';
import { uninviteBidAction } from 'pages/Workorders/ViewIssue/Documents/Bids/BidList/UninviteBidderModal';
import { DeclineBidButton } from 'pages/Workorders/ViewIssue/Documents/Bids/DeclineBidButton';
import { RequestBidsModal } from 'pages/Workorders/ViewIssue/Documents/Bids/RequestBids';
import React, { useState } from 'react';
import { Badge, Card } from 'react-bootstrap';
import { apiProvider } from 'shared/api/apiProvider';
import {
  BidSubmissionType,
  IBid,
  IBidSplitProjection,
  IBidUserView,
  IssueAction,
  IssueRole,
  SetRequireScheduleBeforeBidPayload,
  SplitDocumentParty
} from 'shared/api/clients';
import { MuiIcon, RooIcon } from 'shared/icons';
import { rooEnum } from 'shared/store';
import { rooFmt, showSuccess } from 'shared/utils';
import { vendorSkipBiddingAction } from '../../../Workflow/SkipBiddingModal';
import { bidActionList } from './actions';
import { bidEditActionList } from '../BidFormModal';
import { produce } from 'immer';
import { orderBy } from 'lodash';
import { useDisclosure } from '@roo/lib';
import { bidSplitActionList, SplitBidModal } from './SplitBidModal';
import { EnumMapBidStatus } from '../../../../../../shared/store/enums/bidStatus';
import { Routes } from '../../../../../../shared/routing';

export const BidList = ({ requestEdit }: { requestEdit: (bid: IBid, submissionType: BidSubmissionType) => void }) => {
  const ctx = useCurrentIssue();
  const { visibleBids, latestBidInvitation, canAct, userRole } = ctx;
  const [showModal, setShowModal] = useState(false);
  const canSkip = vendorSkipBiddingAction.isVisible(ctx);
  const sorted = orderBy(visibleBids, (x) => x.bid.createdDate, 'desc');
  const splitBidModal = useDisclosure(false);
  const canCompare =
    ctx.userRole === IssueRole.MainPropertyManager ||
    ctx.userRole === IssueRole.AltPropertyManager ||
    ctx.userRole === IssueRole.PropertyOwner;

  return (
    <Card>
      <Card.Header>
        <div className={'d-flex justify-content-between'}>
          <div>Bids</div>
          <Stack spacing={2} direction={'row'}>
            {canSkip && (
              <button onClick={vendorSkipBiddingAction.onClick} className={'btn btn-warning btn-sm slim'}>
                <RooIcon icon={['fas', 'fast-forward']} /> Under Limit
              </button>
            )}
            {canAct(IssueAction.RequestBid) && (
              <button onClick={() => setShowModal(true)} className={'btn btn-primary btn-sm slim'}>
                <RooIcon icon={['fas', 'plus']} /> Request
              </button>
            )}
            <DeclineBidButton />
            {canAct(IssueAction.CreateBid) && (
              <button
                onClick={() => requestEdit(latestBidInvitation, BidSubmissionType.Creation)}
                className={'btn btn-primary btn-sm slim'}
              >
                <RooIcon icon={['fas', 'plus']} /> Create
              </button>
            )}
            {canCompare && (
              <RouterLink
                to={{
                  pathname: Routes.IssueBidCompareReadonly,
                  params: { issueId: ctx.issue.id, workorderId: ctx.issue.workorderId }
                }}
                className={'btn btn-primary btn-sm slim float-end me-1'}
              >
                <RooIcon icon={['fas', 'eye']} /> Compare
              </RouterLink>
            )}
          </Stack>
        </div>
      </Card.Header>
      <Card.Body>
        {canAct(IssueAction.ChangeBidSchedulingReq) && <ScheduleBeforeBidSetter />}
        <Stack gap={3}>
          {sorted.map((x) => (
            <MuiCard variant={'outlined'} sx={{ width: '100%' }} key={x.bid.id}>
              <CardContent sx={{ width: '100%' }}>
                <Stack
                  sx={{ width: '100%' }}
                  direction={'row'}
                  gap={2}
                  justifyContent={'space-between'}
                  flexWrap={'wrap'}
                >
                  <Stack
                    sx={{ width: '100%' }}
                    direction={'row'}
                    gap={3}
                    flex={1}
                    alignItems={'center'}
                    flexWrap={'wrap'}
                  >
                    <Stack sx={{ minWidth: 220 }}>
                      <Stack direction={'row'} alignItems={'center'}>
                        {x.bid.pdfFile !== null && x.bid.isSubmitted && (
                          <a
                            href={apiProvider.fileUrls.download(x.bid.pdfFile.id)}
                            rel="noreferrer"
                            style={{ padding: '0.5rem' }}
                          >
                            <RooIcon size={'lg'} icon={'download'} />
                          </a>
                        )}
                        <UserLink user={x.bid.vendor.users[0]} role={'Vendor'} text={x.bid.vendor.companyName} />
                      </Stack>
                      <Badge bg={EnumMapBidStatus.get(x.bid.status).color}>
                        {rooEnum.bidStatus.display(x.bid.status)}
                      </Badge>
                    </Stack>

                    <Stack sx={{ minWidth: 80 }}>
                      <Typography variant={'caption'}>Subtotal</Typography>
                      <Typography>{rooFmt.moneyMaybe(x.bid.totals?.subtotal, 'N/A')}</Typography>
                    </Stack>
                    <Stack sx={{ minWidth: 80 }}>
                      <Typography variant={'caption'}>Requested</Typography>
                      <Typography>{rooFmt.date(x.bid.createdDate)}</Typography>
                    </Stack>
                  </Stack>

                  <Box>
                    <ActionsContainer>
                      <BidActionsMenu bidView={x} />
                      <BidInfoButton bidView={x} />
                    </ActionsContainer>
                  </Box>
                </Stack>
                {(userRole === IssueRole.MainPropertyManager || userRole === IssueRole.AltPropertyManager) &&
                  x.bid.split != null && (
                    <Stack p={2} gap={1}>
                      <Stack direction={'row'} alignItems={'center'} gap={1}>
                        <Typography fontWeight={600} fontSize={'large'}>
                          Split
                        </Typography>
                        <IconButton onClick={splitBidModal.open} size={'small'} color={'primary'}>
                          <MuiIcon.Edit fontSize={'small'} />
                        </IconButton>
                      </Stack>
                      <Stack direction={'row'} gap={2}>
                        {x.bid.split.ownerProjection != null && (
                          <SplitBidCard projection={x.bid.split.ownerProjection} />
                        )}
                        {x.bid.split.tenantProjection != null && (
                          <SplitBidCard projection={x.bid.split.tenantProjection} />
                        )}
                      </Stack>
                      <SplitBidModal bidId={x.bid.id} modal={splitBidModal} />
                    </Stack>
                  )}
              </CardContent>
            </MuiCard>
          ))}
        </Stack>
      </Card.Body>
      <RequestBidsModal setIsModalVisible={setShowModal} isVisible={showModal} />
      <ReviewBidModal />
    </Card>
  );
};

const ScheduleBeforeBidSetter = () => {
  const { issue, onIssueUpdate } = useCurrentIssue();
  const [enabled, setEnabled] = useState(issue.requireScheduleBeforeBid ?? false);

  const updateSettings = async (value: boolean) => {
    try {
      setEnabled(value);
      await apiProvider.issues.bids.setRequireScheduleBeforeBid(
        new SetRequireScheduleBeforeBidPayload({
          enabled: value,
          issueId: issue.id
        })
      );
      const newIssue = produce(issue, (old) => {
        old.requireScheduleBeforeBid = value;
      });
      onIssueUpdate({ issue: newIssue });
      showSuccess();
    } catch (err) {
      setEnabled(enabled);
    }
  };

  return (
    <FormControlLabel
      value="top"
      control={<Switch color="primary" checked={enabled} onChange={(e, checked) => void updateSettings(checked)} />}
      label="Require Schedule before Bid"
    />
  );
};

const bidActions = [...bidReviewActionList, ...bidEditActionList, uninviteBidAction, ...bidSplitActionList];

const BidActionsMenu = ({ bidView }: { bidView: IBidUserView }) => {
  const availableActions = bidActions.filter((act) => bidActionList.isAvailable(act, bidView));
  if (availableActions.length === 0) {
    return null;
  }

  return (
    <ActionsMenu moreButton={(onClick) => <RooButton icon={'ellipsis-h'} onClick={onClick} />}>
      {availableActions.map((act) => (
        <ActionsMenuItem
          key={act.key}
          onClick={() => {
            act.onClick(bidView);
          }}
          Icon={act.Icon}
          color={act.color}
        >
          {act.label}
        </ActionsMenuItem>
      ))}
    </ActionsMenu>
  );
};

const SplitBidCard = ({ projection }: { projection: IBidSplitProjection }) => {
  return (
    <MuiCard variant={'outlined'} sx={{ maxWidth: 300, flex: 1 }}>
      <CardContent>
        <Stack direction={'column'} gap={2}>
          <Stack gap={1}>
            <Typography fontSize={'large'} fontWeight={600}>
              {projection.friendlyId}
            </Typography>
            <Stack direction={'row'} gap={2}>
              <Stack>
                <Typography variant={'caption'}>Party</Typography>
                <Typography>{projection.party === SplitDocumentParty.Tenant ? 'Tenant' : 'Owner'}</Typography>
              </Stack>
              <Stack>
                <Typography variant={'caption'}>Amount</Typography>
                <Typography>{rooFmt.money(projection.amount)}</Typography>
              </Stack>
            </Stack>
          </Stack>

          <Stack gap={1}>
            <Typography variant={'caption'}>PDF</Typography>
            <Stack direction={'row'} gap={1}>
              <Button
                size={'small'}
                href={`/api/issues/bids/render-split-pdf?splitId=${projection.splitId}&party=${projection.party}`}
                target={'_blank'}
                startIcon={<MuiIcon.PictureAsPdf />}
              >
                View
              </Button>
              <Button
                size={'small'}
                href={`/api/issues/bids/download-split-pdf?splitId=${projection.splitId}&party=${projection.party}`}
                target={'_blank'}
                startIcon={<MuiIcon.Download />}
              >
                Download
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </CardContent>
    </MuiCard>
  );
};
