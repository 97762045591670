import { Divider, Paper, Stack } from '@mui/material';
import { Col, FormGroup, Row } from 'react-bootstrap';
import { Control, FormState } from 'react-hook-form';
import { FieldMultiSelect, FieldSingleSelect, FieldText, Required } from 'components/form';
import { useFocusOptions, useStateOptions } from 'shared/store';
import { SaveButton } from 'components/SaveButton';
import { FormDefinition } from 'pages/UserLists/Vendors/forms';
import { ReactSelectCommonStyles } from '../../../components/RooSelect';
import Grid2 from '@mui/material/Unstable_Grid2';

type WithControl = { control: Control<FormDefinition>; isEditing: boolean };
type WithFormState = { formState: FormState<FormDefinition> };

export const VendorForm = ({ control, formState }: WithControl & WithFormState) => {
  const focusOptions = useFocusOptions();
  const stateOptions = useStateOptions();

  return (
    <>
      <Paper elevation={0} sx={{ p: { xs: 2, md: 3 } }}>
        <Stack spacing={4}>
          <Stack>
            <h6 className="text-black">
              <strong>Vendor Information</strong>
            </h6>
            <Divider sx={{ mb: 2 }} />
            <Grid2 container spacing={2}>
              <Grid2 xl={6} xs={12}>
                <FieldText
                  control={control}
                  name={'company'}
                  label={'Company Name'}
                  placeholder={'Company Name'}
                  className="Input-field"
                />
              </Grid2>
              <Grid2 xl={6} xs={12}>
                <FieldMultiSelect
                  control={control}
                  options={focusOptions}
                  name={'vendorFocuses'}
                  label={'Services'}
                  styles={ReactSelectCommonStyles.MuiLookAlike}
                />
              </Grid2>
              <Grid2 xl={6} xs={12}>
                <FieldText
                  control={control}
                  name={'contactFirstName'}
                  required
                  label={'First Name'}
                  placeholder={'First Name'}
                  className="Input-field"
                />
              </Grid2>
              <Grid2 xl={6} xs={12}>
                <FieldText
                  control={control}
                  name={'contactLastName'}
                  label={'Last Name'}
                  placeholder={'Last Name'}
                  className="Input-field"
                />
              </Grid2>
            </Grid2>
          </Stack>
          <Stack>
            <h6 className="text-black mt-2">
              <strong>Contact</strong>
            </h6>
            <Divider sx={{ mb: 2 }} />
            <Grid2 container spacing={2}>
              <Grid2 xl={4} xs={12}>
                <FieldText
                  control={control}
                  name={'contactCellPhone'}
                  label={'Cell Phone'}
                  placeholder={'Cell Phone'}
                  className="Input-field"
                />
              </Grid2>
              <Grid2 xl={4} xs={12}>
                <FieldText
                  control={control}
                  name={'contactWorkPhone'}
                  label={'Work Phone'}
                  placeholder={'Work Phone'}
                  className="Input-field"
                />
              </Grid2>
              <Grid2 xl={4} xs={12}>
                <FieldText
                  control={control}
                  name={'contactEmail'}
                  required
                  label={'Email'}
                  placeholder={'Email'}
                  className="Input-field"
                />
              </Grid2>
              <Grid2 xl={6} xs={12}>
                <FieldText
                  control={control}
                  name={'address.address1'}
                  label={'Address 1'}
                  placeholder={'Address 1'}
                  className="Input-field"
                />
              </Grid2>
              <Grid2 xl={6} xs={12}>
                <FieldText
                  control={control}
                  name={'address.address2'}
                  label={'Address 2'}
                  placeholder={'Address 2'}
                  className="Input-field"
                />
              </Grid2>
              <Grid2 xl={4} xs={12}>
                <FieldText
                  control={control}
                  name={'address.city'}
                  label={'City'}
                  placeholder={'City'}
                  className="Input-field"
                />
              </Grid2>
              <Grid2 xl={4} xs={12}>
                <FieldSingleSelect
                  control={control}
                  options={stateOptions}
                  name={'address.state'}
                  label={'State'}
                  styles={ReactSelectCommonStyles.MuiLookAlike}
                />
              </Grid2>
              <Grid2 xl={4} xs={12}>
                <FieldText
                  control={control}
                  name={'address.zipCode'}
                  label={'Zip Code'}
                  placeholder={'Zip Code'}
                  className="Input-field"
                />
              </Grid2>
            </Grid2>
          </Stack>
        </Stack>
      </Paper>
      <div className="mt-3" style={{ textAlign: 'right' }}>
        <SaveButton control={control} className={'btn-lg'}>
          Save
        </SaveButton>
      </div>
    </>
  );
};
