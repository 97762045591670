import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import MuiToolbar from '@mui/material/Toolbar';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { WithReqChildren } from '../shared/utils/typeUtils';
import { SvgIconComponent } from '@mui/icons-material';
import { isRouteMatch, RouteDestination } from '../shared/routing';
import { Avatar, ListItem, ListItemAvatar, ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { RouterLink } from 'components/Links';
import React from 'react';
import { useLocation } from 'react-router';

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}
export const drawerWidth: number = 285;

export const AppBar = styled(MuiAppBar)<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    [theme.breakpoints.down('lg')]: {
      width: '100%',
      marginLeft: 0
    }
  })
}));

export const Drawer = styled(MuiDrawer)(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    overflowY: 'visible',
    backgroundColor: '#303030',
    color: 'white',
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9)
      }
    })
  }
}));

export const DrawerPermanent = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      overflowY: 'visible',
      backgroundColor: '#303030',
      color: 'white',
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      zIndex: 1300,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9)
        },
        zIndex: 1000
      })
    }
  })
);

export const MenuPaperStyles = {
  elevation: 0,
  sx: {
    width: 200,
    overflow: 'visible',
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    mt: 1.5,
    '& .MuiAvatar-root': {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1
    },
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      right: 24,
      width: 10,
      height: 10,
      bgcolor: 'background.paper',
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: 0
    }
  }
};

export const Toolbar = styled(MuiToolbar)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingLeft: theme.spacing(3),
  backgroundColor: '#212121'
}));

export const MainBoxStyle = {
  backgroundColor: '#F7F8FC',
  flexGrow: 1,
  height: '100vh',
  overflow: 'auto'
};

type MenuItemProps = {
  icon: SvgIconComponent;
} & WithReqChildren;

export const RouteListItem = ({ children, icon, to }: MenuItemProps & { to: RouteDestination }) => {
  const location = useLocation();
  const isActive = isRouteMatch(to, location);
  return (
    <ListItem button component={RouterLink} to={to} selected={isActive}>
      <ListItemAvatar>
        <Avatar>{React.createElement(icon, {})}</Avatar>
      </ListItemAvatar>
      <ListItemText primary={children} />
    </ListItem>
  );
};

export const RouteMenuItem = ({ children, icon, to }: MenuItemProps & { to: RouteDestination }) => {
  return (
    <MenuItem component={RouterLink} to={to}>
      <ListItemIcon>{React.createElement(icon, {})}</ListItemIcon>
      {children}
    </MenuItem>
  );
};

export const ToolbarMenuItem = ({
  children,
  onClick,
  icon
}: WithReqChildren & { onClick: () => void; icon: SvgIconComponent }) => {
  return (
    <MenuItem onClick={onClick}>
      <ListItemIcon>{React.createElement(icon, {})}</ListItemIcon>
      {children}
    </MenuItem>
  );
};
