import { RooDialog } from '../../../../../components';
import { Button, Stack, Typography } from '@mui/material';
import { useCurrentIssue } from '../../../shared/CurrentIssueContext';
import { InvoiceInfo } from '../Invoices/InvoiceInfoButton';
import React from 'react';

export const CompleteWorkStepInvoiceReview = ({
  onClose,
  requestEdit,
  invoiceId
}: {
  onClose: () => void;
  requestEdit: () => void;
  invoiceId: string;
}) => {
  const { visibleInvoices } = useCurrentIssue();
  const invoice = visibleInvoices.find((x) => x.id === invoiceId);
  if (invoice == null) {
    throw new Error(`Invoice ${invoiceId} not found`);
  }
  return (
    <>
      <RooDialog.Title onClose={onClose}>Review Invoice</RooDialog.Title>
      <RooDialog.Content>
        <Stack spacing={2}>
          <Typography variant={'h6'}>Your invoice has been created. Review it below.</Typography>
          <InvoiceInfo invoice={invoice} />
        </Stack>
      </RooDialog.Content>
      <RooDialog.Actions>
        <Stack direction={'row'} spacing={2}>
          <Button color="muted" onClick={requestEdit}>
            Edit
          </Button>
          <Button color="primary" onClick={onClose}>
            Close
          </Button>
        </Stack>
      </RooDialog.Actions>
    </>
  );
};
