import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography
} from '@mui/material';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { BidColumnSelection, schema } from './CompareBidsContent';
import { Control, useWatch } from 'react-hook-form';
import { z } from 'zod';
import EditIcon from '@mui/icons-material/Edit';
import NoteTooltip from './NoteTooltip';
import { DocumentLineItem } from '../../../../../shared/api/clients';

type TasksListProps = {
  tasks: string[];
  lines: BidColumnSelection[];
  onChangeEstimate?: (index: number, lineId: string, value: number, originalValue: number, task: string) => void;
  control: Control<z.infer<typeof schema>>;
  onSkip: (index: string, skip: boolean) => void;
};

export function TasksList({ tasks, lines, control, onChangeEstimate, onSkip }: TasksListProps) {
  const total = lines.reduce((acc, line) => acc + line.line.value, 0);
  const [estimates, setEstimates] = useState<{ [key: string | number]: number }>({});

  const markup = useWatch({ control, name: 'markup', defaultValue: 15 }) ?? 0;

  const changeEstimate = useCallback(
    (index: number, lineId: string, value: number, originalValue: number, task: string) => {
      onChangeEstimate?.(index, lineId, value, originalValue, task);
      setEstimates((prev) => ({ ...prev, [index]: value }));
    },
    [onChangeEstimate]
  );

  const estimate = useMemo(
    () =>
      Object.keys(estimates).reduce((acc, key: string) => {
        return acc + estimates[key];
      }, 0),
    [estimates]
  );

  return (
    <Grid item container direction="column">
      <Grid
        item
        container
        height={'calc(50px + 1rem)'}
        justifyContent={'flex-end'}
        alignItems={'center'}
        sx={{
          borderBottomLeftRadius: '10px',
          borderBottomRightRadius: '10px'
        }}
      >
        <Grid item container sm={6}>
          <Grid item sm={6}>
            <Typography>Bid amount</Typography>
          </Grid>
          <Grid item sm={6}>
            <Typography
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
              }}
            >
              Estimate amount
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {tasks.map((task, index) => {
        const line = lines.find((line) => line.line.description === task)?.line;

        return (
          <TaskItem
            onSkip={onSkip}
            key={task}
            line={line}
            markup={markup}
            task={task}
            amount={line?.value ?? 0}
            index={index}
            onChange={changeEstimate}
          />
        );
      })}
      <Grid
        item
        container
        height={'calc(50px + 1rem)'}
        justifyContent={'flex-end'}
        alignItems={'center'}
        sx={{
          backgroundColor: '#303030',
          color: 'white',
          borderBottomLeftRadius: '10px',
          borderBottomRightRadius: '10px'
        }}
      >
        <Grid item container sm={6}>
          <Grid item sm={6}>
            <Typography>Total bid</Typography>
            <Typography fontWeight={600}>${total}</Typography>
          </Grid>
          <Grid item sm={6}>
            <Typography
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
              }}
            >
              Total estimate
            </Typography>
            <Typography fontWeight={600}>${estimate}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

const InfoButton = function InfoButton({ onClick, disabled }: { onClick: () => void; disabled: boolean }) {
  return (
    <IconButton
      disabled={disabled}
      onClick={onClick}
      sx={{
        color: '#0ab7b7'
      }}
      edge="end"
    >
      <EditIcon />
    </IconButton>
  );
};

type TaskItemProps = {
  index: number;
  task: string;
  amount: number;
  line?: DocumentLineItem;
  onChange: (index: number, lineId: string, value: number, originalValue: number, task: string) => void;
  markup: number;
  onSkip: (index: string, skip: boolean) => void;
};

function TaskItem({ index, task, amount, onChange, markup, line, onSkip }: TaskItemProps) {
  const [estimate, setEstimate] = useState<number | null>(amount);
  const [editable, setEditable] = useState(false);

  useEffect(() => {
    const total = amount + (amount * markup) / 100;
    setEstimate(total);
    onChange(index, line?.id, total, amount, task);

    if (line?.id) {
      onSkip(task, false);
    }
  }, [amount, onChange, index, line?.id, markup, task, onSkip]);

  const onChangeEstimate = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const value = +e.currentTarget.value;
    setEstimate(value);
    onChange(index, line.id, value, line.value, task);
  };

  const accept = () => {
    setEditable(false);
  };

  const edit = () => {
    setEditable(true);
    document.getElementById(`estimate-${index}`).focus();
  };

  return (
    <Grid
      id={`task-${index}`}
      key={task}
      item
      container
      minHeight={'calc(100px + 1rem)'}
      alignItems={'center'}
      p={'1rem'}
      sx={{ backgroundColor: index % 2 === 0 ? '#f5f5f5' : 'white' }}
    >
      <Grid item container alignItems={'center'} sm={6}>
        <Typography px={'1rem'}>
          <Typography component={'span'}>{index + 1}. </Typography>
          {task}
          <NoteTooltip notes={line?.notes} />
        </Typography>
      </Grid>
      <Grid item container sm={6}>
        <Grid item sm={6}>
          {!line && (
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e) => {
                      onSkip(task, e.target.checked);
                    }}
                  />
                }
                label="Skip line"
              />
            </FormGroup>
          )}
          {line && <Typography py="0.5rem">${amount}</Typography>}
        </Grid>
        <Grid item sm={6} position={'relative'}>
          {line && (
            <TextField
              id={`estimate-${index}`}
              onBlur={accept}
              onKeyDown={(e) => e.key === 'Enter' && accept()}
              value={estimate}
              type="number"
              onChange={onChangeEstimate}
              variant="standard"
              InputProps={{
                disableUnderline: true,
                readOnly: !editable || !line,
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                endAdornment: <InfoButton disabled={editable || !line} onClick={edit} />
              }}
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
