import { Box, Button, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Card } from 'react-bootstrap';
import { apiProvider } from 'shared/api/apiProvider';
import { CreateBoardPostPayload, IssueRole } from 'shared/api/clients';
import { RooIcon } from 'shared/icons';
import { RooDialog } from 'components';
import { classNames, cls, rooFmt } from 'shared/utils';
import { useCurrentIssue } from 'pages/Workorders/shared/CurrentIssueContext';
import { DeleteMessageButton } from 'pages/Workorders/ViewIssue/BulletinBoard/DeleteMessageButton';
import css from './BulletinBoardView.module.scss';

export const BulletinBoardView = () => {
  const { boardPosts, userRole } = useCurrentIssue();
  const allowedDeleteRoles = [IssueRole.MainPropertyManager, IssueRole.AltPropertyManager, IssueRole.AdminViewer];
  const allowDelete = allowedDeleteRoles.includes(userRole);
  const allowCreate = userRole !== IssueRole.AdminViewer;

  return (
    <Card>
      <Card.Header>
        <Box display="flex" justifyContent="space-between">
          Bulletin Board
          {allowCreate && <NewPostButton />}
        </Box>
      </Card.Header>
      <Card.Body>
        {boardPosts.length === 0 && (
          <p style={{ textAlign: 'center', marginBottom: 0 }}>
            There are no posts yet. Everyone can create and read posts.
          </p>
        )}
        {boardPosts.length > 0 && (
          <div className={css.chat}>
            <div className={cls(css.chatHistory)}>
              <ul>
                {boardPosts.map((msg, i) => (
                  <li key={i}>
                    <div>
                      <span>
                        {msg.createdBy.firstName} {msg.createdBy.lastName}
                      </span>
                      <span className={css.messageDataTime}>{rooFmt.chatDate(msg.createdAt)}</span>
                      {allowDelete && (
                        <Box sx={{ ml: 2, display: 'inline' }}>
                          <DeleteMessageButton postId={msg.id} />
                        </Box>
                      )}
                    </div>
                    <div style={{ marginTop: '16px' }} className={classNames(css.message, [css.otherMessage])}>
                      {msg.message}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

const NewPostButton = () => {
  const [visible, setVisible] = useState(false);
  return (
    <>
      <button className={'btn btn-primary btn-sm float-end slim'} onClick={() => setVisible(true)}>
        <RooIcon icon={['fas', 'plus']} /> New Post
      </button>
      <NewPostModal visible={visible} onCloseRequest={() => setVisible(false)} />
    </>
  );
};

const NewPostModal = ({ visible, onCloseRequest }: { visible: boolean; onCloseRequest: () => void }) => {
  const { issue, onIssueUpdate } = useCurrentIssue();
  const [isSaving, setIsSaving] = useState(false);
  const [message, setMessage] = useState('');
  const canSave = message !== '';

  const onSave = async () => {
    setIsSaving(true);
    try {
      const result = await apiProvider.issues.main.createBoardPost(
        new CreateBoardPostPayload({
          message: message,
          issueId: issue.id
        })
      );
      onIssueUpdate(result);
      onCloseRequest();
      setMessage('');
    } catch (e) {}
    setIsSaving(false);
  };
  return (
    <RooDialog open={visible} onClose={onCloseRequest} maxWidth={'sm'} fullWidth>
      <RooDialog.Title onClose={onCloseRequest}>New Post</RooDialog.Title>
      <RooDialog.Content>
        <Typography variant={'body2'} sx={{ mb: 4 }}>
          Create a new Bulletin Board post. Everyone else can see and reply to it.
        </Typography>
        <Box sx={{ width: '100%' }}>
          <textarea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            className={'form-control'}
            id={'notes-input'}
            rows={5}
            placeholder={"Let everyone know what's going on"}
          />
        </Box>
      </RooDialog.Content>
      <RooDialog.Actions>
        <Button color="primary" onClick={onSave} disabled={isSaving || !canSave} sx={{ mr: 1 }}>
          {isSaving ? <RooIcon icon={['fas', 'circle-notch']} spin={isSaving} style={{ fontSize: 23 }} /> : 'Create'}
        </Button>
        <Button color="muted" onClick={onCloseRequest} disabled={isSaving}>
          Close
        </Button>
      </RooDialog.Actions>
    </RooDialog>
  );
};
