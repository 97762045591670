import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Cell, Pie, PieChart } from 'recharts';
import { CapabilityType, Vendor } from 'shared/api/clients';
import { useChatStore } from 'components/Chat/chatStore';
import { useRequestGlobalModal } from '../../components/modals';

export const NewMessagesCard = ({
  companyId,
  vendors,
  stateCode,
  city
}: {
  companyId: string;
  vendors: Vendor[];
  stateCode: string;
  city: string;
}) => {
  const allMessages = useChatStore((x) => x.messages);
  const participants = useChatStore((x) => x.participants);
  const filterVendorUserIds = (vendors ?? []).flatMap((x) => x.users.map((y) => y.id));
  const allUnread = allMessages.filter(
    (x) =>
      (companyId == null || x.issue?.managementCompanyId === companyId) &&
      (filterVendorUserIds.length === 0 ||
        filterVendorUserIds.some((userId) => userId === x.from.id || userId === x.to.id)) &&
      (stateCode == null || x.property.address.state === stateCode) &&
      (city == null || city === '' || x.property.address.city === city) &&
      !x.isRead &&
      !x.sentByMe
  );

  const COLORS = ['#FF56A9', '#00D0B5', '#FFB243', '#4458FE', '#800080'];
  const byRole = new Map<CapabilityType, { count: number; ids: string[] }>();
  const countByRole = (capability: CapabilityType) => {
    const ids: string[] = [];
    let count = 0;
    for (const message of allUnread) {
      const otherId = message.sentByMe ? message.to?.id : message.from?.id;
      if (otherId == null) {
        continue;
      }

      const participant = participants.find((x) => x.userSummary.id === otherId);
      if (participant == null) {
        continue;
      }

      if (participant.mainCapability !== capability) {
        continue;
      }

      ids.push(participant.userSummary.id);
      count += 1;
    }

    byRole.set(capability, { count: count, ids: ids });
  };

  countByRole(CapabilityType.PropertyManager);
  countByRole(CapabilityType.PropertyOwner);
  countByRole(CapabilityType.Vendor);
  countByRole(CapabilityType.Tenant);

  let pieData = [
    { name: 'Internal', values: byRole.get(CapabilityType.PropertyManager).count },
    { name: 'Vendor', values: byRole.get(CapabilityType.Vendor).count },
    { name: 'Tenant', values: byRole.get(CapabilityType.Tenant).count },
    { name: 'PropertyOwner', values: byRole.get(CapabilityType.PropertyOwner).count }
  ];
  const pieSum = pieData.reduce((total, currentValue) => total + currentValue.values, 0);
  const nullData = [{ name: 'Internal', values: 10 }];

  return (
    <div className="bg-white">
      <Row className="messages-col">
        <Col>
          <PieChart width={170} height={174}>
            <Pie
              data={pieData}
              dataKey="values"
              stroke={'transperent'}
              outerRadius={80}
              innerRadius={60}
              fill="#00D0B5"
              paddingAngle={0}
            >
              {pieData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            {pieSum === 0 && (
              <Pie
                data={nullData}
                dataKey="values"
                stroke={'transperent'}
                outerRadius={80}
                innerRadius={60}
                fill="#eeeeee"
                paddingAngle={0}
              >
                {pieData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill="#eeeeee" />
                ))}
              </Pie>
            )}
          </PieChart>
        </Col>
        <Col className="messaging-count">
          <MessageCountRow
            data={byRole.get(CapabilityType.PropertyManager)}
            color={COLORS[0]}
            title={'Property Managers'}
          />
          <MessageCountRow data={byRole.get(CapabilityType.Vendor)} color={COLORS[1]} title={'Vendors'} />
          <MessageCountRow data={byRole.get(CapabilityType.Tenant)} color={COLORS[2]} title={'Tenants'} />
          <MessageCountRow
            data={byRole.get(CapabilityType.PropertyOwner)}
            color={COLORS[3]}
            title={'Property Owners'}
          />
        </Col>
      </Row>
    </div>
  );
};

const MessageCountRow = ({
  data,
  color,
  title
}: {
  color: string;
  title: string;
  data: { count: number; ids: string[] };
}) => {
  const [, toggleChat] = useRequestGlobalModal('chatDrawer', {
    props: {
      conversationIds: data.ids
    }
  });

  return (
    <Row onClick={toggleChat}>
      <Col>
        <li className="messaging-count-txt">
          <span style={{ background: color }} />
          {title}
        </li>
      </Col>
      <Col>
        <li className="messaging-count-number">{data.count}</li>
      </Col>
    </Row>
  );
};
