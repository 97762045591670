import { IInvoice, Invoice, PublishInvoicePayload } from '../../../../../shared/api/clients';
import { showSuccess } from '../../../../../shared/utils';
import React, { useState } from 'react';
import { useCurrentIssue } from '../../../shared/CurrentIssueContext';
import { apiProvider } from '../../../../../shared/api/apiProvider';
import { ConfirmationModal } from '../../../../../components';
import { Disclosure } from '@roo/lib';

export const PublishInvoiceModal = ({ invoice, modal }: { invoice: IInvoice; modal: Disclosure }) => {
  const [saving, setSaving] = useState(false);
  const { issue, onIssueUpdate } = useCurrentIssue();

  const save = async () => {
    try {
      setSaving(true);
      const updated = await apiProvider.issues.invoices.publishInvoice(
        new PublishInvoicePayload({
          invoiceId: invoice.id,
          issueId: issue.id
        })
      );

      onIssueUpdate(updated);
      showSuccess();
      modal.close();
      setSaving(false);
    } catch (e) {
      setSaving(false);
    }
  };

  return (
    <>
      <ConfirmationModal
        question={'Do you want to send this invoice to the client? This action is final.'}
        visible={modal.isOpen}
        onClose={modal.close}
        onSave={() => void save()}
        running={saving}
      />
    </>
  );
};
