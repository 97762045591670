import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { apiProvider } from 'shared/api/apiProvider';
import { BidAction, BidUserView, IBidUserView, UninviteBidderPayload } from 'shared/api/clients';
import { ConfirmationModal } from 'components';
import { showSuccess } from 'shared/utils';
import { useCurrentIssue } from 'pages/Workorders/shared/CurrentIssueContext';
import { bidActionList, bidModalComponents } from './actions';
import { MuiIcon } from '../../../../../../shared/icons';
import { createModalStore } from '../../../../../../shared/store';

const useUninviteBidderStore = createModalStore<IBidUserView>();
export const uninviteBidAction = bidActionList.register({
  key: 'uninvite',
  actionType: BidAction.Uninvite,
  color: 'error',
  label: 'Uninvite',
  Icon: MuiIcon.Cancel,
  onClick: (bidView) => useUninviteBidderStore.getState().actions.show(bidView)
});

export const UninviteBidderModal = () => {
  const {
    visible,
    actions: { hide },
    payload
  } = useUninviteBidderStore();
  const { onIssueUpdate } = useCurrentIssue();
  const [isSaving, setIsSaving] = useState(false);
  const [notes, setNotes] = useState<string>('');
  const bid = payload?.bid;
  if (bid == null) {
    return null;
  }

  const save = async () => {
    try {
      setIsSaving(true);
      const newIssue = await apiProvider.issues.bids.uninviteBidder(
        new UninviteBidderPayload({
          issueId: bid.issueId,
          bidId: bid.id,
          notes: notes.trim() === '' ? null : notes
        })
      );

      showSuccess();
      onIssueUpdate(newIssue);
      hide();
    } catch (e) {}
    setIsSaving(false);
  };
  const question = (
    <Row>
      <Col xl={12} className={'mb-3'}>
        Do you want to uninvite this vendor from your bid?
      </Col>
      <Col xl={12} className={'mb-3'}>
        <div className={'mt-4 form-group'}>
          <label htmlFor={'review-notes'}>Notes</label>
          <textarea
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            className={'form-control'}
            id={'review-notes'}
            rows={3}
            placeholder={'Your comments'}
          />
        </div>
      </Col>
    </Row>
  );

  return (
    <ConfirmationModal
      size={'md'}
      visible={visible}
      onClose={hide}
      onSave={() => void save()}
      running={isSaving}
      question={question}
    />
  );
};

bidModalComponents.push(<UninviteBidderModal />);
