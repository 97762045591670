import { orderBy, uniq, uniqBy } from 'lodash';
import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { Badge, Button } from 'react-bootstrap';
import { AttachmentEntityType, AttachmentFile, IAttachmentFile, IIssue, UserSummary } from 'shared/api/clients';
import { RooIcon } from 'shared/icons';
import { Routes } from 'shared/routing';
import { useAppStore, useCurrentUser } from 'shared/store';
import { ErrorScreen, RooAvatar, RooButton, RooDialog, RooDrawer, RouterLink, UserLink } from 'components';
import { classNames, cls, rooFmt } from 'shared/utils';
import { useCurrentIssue } from 'pages/Workorders/shared/CurrentIssueContext';
import css from 'components/Chat/Chat.module.scss';
import { AppMessage, useChatStore } from 'components/Chat/chatStore';
import { Grid, Stack } from '@mui/material';
import { FileThumbnail } from 'components/Files/FileThumbnail';
import { useWatchGlobalModal } from '../modals';
import { ErrorBoundary } from '../ErrorBoundary';
import { useQuery } from '@tanstack/react-query';
import { apiProvider } from '../../shared/api/apiProvider';
import { FilePickerModal } from '../Files/FilePicker/FilePicker';
import { useDisclosure, useListState } from '@roo/lib';

type Conversation = {
  user: UserSummary;
  role: string;
  unreadCount: number;
  lastMessage: AppMessage;
  messages: AppMessage[];
};

const useGlobalConversations = () => {
  //TODO: do something about this
  const currentUser = useCurrentUser();
  const messages = useChatStore((x) => x.messages);
  const participants = useChatStore((x) => x.participants);
  const orderedMessages = orderBy(messages, (x) => x.sentAt);
  const reversedMessages = [...orderedMessages].reverse();
  const recipientsByMessage = orderedMessages
    .map((x) => x.to)
    .concat(orderedMessages.map((x) => x.from))
    .filter((x) => x.id !== currentUser.id);
  const uniqueRecipients = uniqBy(recipientsByMessage, (x) => x.id);
  const unreadByUserId = (userId: string) => {
    return orderedMessages.filter((x) => x.from.id === userId && !x.isRead).length;
  };

  const lastMessageByUserId = (userId: string) => {
    return reversedMessages.find((x) => x.from.id === userId || x.to.id === userId);
  };

  const messagesByUserId = (userId: string) => {
    return orderedMessages.filter((x) => x.from.id === userId || x.to.id === userId);
  };

  const conversations: Conversation[] = [];
  const makeConversation = (user: UserSummary, role: string) => {
    conversations.push({
      user,
      role,
      lastMessage: lastMessageByUserId(user.id),
      unreadCount: unreadByUserId(user.id),
      messages: messagesByUserId(user.id)
    });
  };

  for (const recipient of uniqueRecipients) {
    const participant = participants.find((x) => x.userSummary.id === recipient.id);
    makeConversation(recipient, participant?.category ?? 'Unknown');
  }

  return conversations;
};

export const useIssueConversations = () => {
  const chatStoreLoaded = useChatStore((x) => x.dataLoaded);
  const messages = useChatStore((x) => x.messages);
  const { issue } = useCurrentIssue();
  const { isLoading, data: participants } = useQuery(
    ['issue-chat-participants', issue.id],
    () => apiProvider.messagesClient.getIssueConversations(issue.id),
    {
      enabled: chatStoreLoaded
    }
  );

  return useMemo(() => {
    const messagesForIssues = messages.filter((x) => x.issue.id === issue.id);

    if (isLoading) {
      return { isLoading, conversations: null };
    }

    const conversations: Conversation[] = [];
    for (const participant of participants.filter((x) => x.showInChat)) {
      const userId = participant.user.id;
      const userMessages = messagesForIssues.filter((x) => x.from.id === userId || x.to.id === userId);
      const unreadCount = userMessages.filter((x) => x.from.id === userId && !x.isRead).length;
      const orderedMessages = orderBy(userMessages, (x) => x.sentAt);
      const lastMessage = orderedMessages.length === 0 ? null : orderedMessages[0];
      conversations.push({
        user: participant.user,
        role: participant.group,
        lastMessage,
        unreadCount,
        messages: orderedMessages
      });
    }

    return { isLoading: isLoading, conversations };
  }, [isLoading, participants, issue.id, messages]);
};

//TODO: this belongs under Workorder
export const IssueChat = () => {
  const { issue } = useCurrentIssue();
  const { isLoading, conversations } = useIssueConversations();

  const [state, setState] = useState<{ mode: 'list' | 'single'; selectedPerson: string }>({
    mode: 'list',
    selectedPerson: null
  });

  if (isLoading) {
    return (
      <div className={'col col-xl-12'}>
        <div className={css.blankState}>
          <div className={'text-center'}>
            <p className={css.blankStateText}>Loading conversations...</p>
            <RooIcon icon={'comments'} size={'6x'} className={css.blankStateIcon} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {state.mode === 'list' && (
        <ConversationList
          conversations={conversations}
          issue={issue}
          onSelect={(personId) => setState({ ...state, mode: 'single', selectedPerson: personId })}
          standalone={false}
        />
      )}
      {state.mode === 'single' && (
        <MessageList
          issueId={issue.id}
          conversations={conversations}
          onBack={() => setState({ mode: 'list', selectedPerson: null })}
          personId={state.selectedPerson}
          standalone={false}
        />
      )}
    </>
  );
};

const ConversationList = ({
  onSelect,
  issue,
  conversations,
  standalone
}: {
  conversations: Conversation[];
  onSelect: (id: string) => void;
  issue: IIssue;
  standalone: boolean;
}) => {
  const currentUser = useAppStore((x) => x.userDetails);
  const others = conversations.filter((x) => x.user.id !== currentUser.id);
  const roles = uniq(others.map((x) => x.role));
  return (
    <div>
      <div className={cls('row', css.conversations)}>
        <div className={cls('col col-xl-6 col-md-12', standalone ? 'col-xl-12' : 'col-xl-6 col-md-12')}>
          {roles.map((role) => {
            const peopleInRole = orderBy(
              others.filter((x) => x.role === role),
              (x) => x.user.firstName
            );
            const hasMultiple = peopleInRole.length > 1;
            return (
              <Fragment key={role}>
                <hr className={'hr-text'} style={hasMultiple ? { marginBottom: '5px' } : null} data-content={role} />
                {hasMultiple && issue && (
                  <BroadcastMessageButton issueId={issue?.id} role={role} conversations={peopleInRole} />
                )}
                {peopleInRole.map((person, idx) => {
                  return (
                    <div
                      key={person.user.id}
                      onClick={() => onSelect(person.user.id)}
                      className={classNames(css.conversation, 'shadow p-3 bg-white rounded', {
                        'mb-3': idx !== others.length - 1
                      })}
                    >
                      <RooAvatar name={person.user.fullName} avatarUrl={person.user.avatarUrl} size={'l'} />
                      <div className={cls(css.personAbout)}>
                        <div>
                          <strong>
                            {person.user.firstName} {person.user.lastName}
                          </strong>
                        </div>
                        {person.messages.length === 0 && (
                          <div className={'text-muted font-italic'}>
                            Start a conversation with {person.user.firstName}
                          </div>
                        )}
                        {person.messages.length > 0 && (
                          <div
                            className={classNames(css.truncateText, {
                              'text-muted': person.unreadCount < 1,
                              'fw-bold': person.unreadCount > 0
                            })}
                          >
                            {person.lastMessage?.textContent ? person.lastMessage.textContent : 'Picture'}
                          </div>
                        )}

                        {person.unreadCount > 0 && (
                          <Badge pill bg={'warning'} className={cls(css.unreadBadge, 'text-dark')}>
                            {person.unreadCount}
                          </Badge>
                        )}
                      </div>
                    </div>
                  );
                })}
              </Fragment>
            );
          })}
        </div>
        {!standalone && (
          <div className={'col col-xl-6 d-none d-xl-block'}>
            <div className={css.blankState}>
              <div className={'text-center'}>
                <p className={css.blankStateText}>Select a conversation to get started</p>
                <RooIcon icon={'comments'} size={'6x'} className={css.blankStateIcon} />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const MAX_FILES = 1;

const MessageList = ({
  personId,
  onBack,
  conversations,
  issueId,
  standalone
}: {
  personId: string;
  issueId: string;
  conversations: Conversation[];
  onBack: () => void;
  standalone: boolean;
}) => {
  const addMessage = useChatStore((x) => x.addMessage);
  const markAsRead = useChatStore((x) => x.markAsRead);
  const [files, { append, remove }] = useListState<IAttachmentFile>([]);
  const onChangeRef = useRef<(files: IAttachmentFile[]) => void>();
  const hasFiles = files.length > 0;
  const canUpload = files.length < MAX_FILES;
  const currentConversation = conversations.find((x) => x.user.id === personId);
  const lastMessageSeqId = useChatStore((x) => x.lastMessageSeqId);
  const initialMessageSeqId = useRef(lastMessageSeqId);
  const { messages, user, role } = currentConversation;
  const firstUnread = useMemo(
    () => messages.find((x) => !x.isRead && !x.sentByMe),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [lastMessageSeqId]
  );
  useEffect(() => {
    onChangeRef.current?.(files);
  }, [files]);
  const bottomRef = useRef(null);
  const inputRef = useRef(null);
  const firstUnreadRef = useRef(null);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [message, setMessage] = useState<string>('');
  const scrollToBottom = () => {
    setTimeout(() => bottomRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' }), 10);
  };
  useEffect(() => {
    inputRef.current?.focus();
    let refToScroll = bottomRef;
    if (firstUnreadRef.current) {
      refToScroll = firstUnreadRef;
    }

    let offset = refToScroll.current.offsetTop - 15;
    offset = offset < 0 ? 0 : offset;

    scrollContainerRef.current.scroll({
      behavior: 'smooth',
      top: offset
    });
  }, []);

  useEffect(() => {
    const unread = messages.filter((x) => !x.isRead && !x.sentByMe).map((x) => x.id);
    if (unread.length > 0) {
      void markAsRead(unread);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastMessageSeqId]);

  useEffect(() => {
    if (lastMessageSeqId === initialMessageSeqId.current) {
      return;
    }

    scrollToBottom();
  }, [lastMessageSeqId]);

  const submit = () => {
    if (message?.trim() === '' && files.length === 0) {
      return;
    }
    addMessage(message, [currentConversation.user], issueId, files[0]);
    remove(0);
    setMessage('');
    scrollToBottom();
  };

  return (
    <div className={cls(css.chat)}>
      <div className={classNames(css.chatHeader, css.clearfix)}>
        <RooAvatar name={user.fullName} avatarUrl={user.avatarUrl} size={'l'} style={{ float: 'left' }} />

        <div className={css.chatAbout}>
          <div className={css.chatWith}>
            <UserLink user={user} role={role} hideAvatar={true} />
          </div>
          <div className={css.chatRole}>{role}</div>
        </div>
        <RooButton onClick={() => onBack()} className={css.backBtn} variant={'link'} icon={'reply'}>
          Conversations
        </RooButton>
      </div>

      <div ref={scrollContainerRef} className={cls(css.chatHistory, standalone && css.fullHeight)}>
        <ul>
          {messages.map((msg) => {
            return (
              <Fragment key={msg.clientId}>
                {msg.id === firstUnread?.id && (
                  <li
                    className={css.newMessages}
                    ref={firstUnreadRef}
                    style={{ position: 'relative', marginBottom: '10px' }}
                  >
                    <Badge style={{ position: 'absolute', right: '-2px', top: '-10px' }} bg={'danger'}>
                      New
                    </Badge>
                  </li>
                )}

                <li className={classNames(msg.sentByMe ? css.clearfix : null)}>
                  <div className={classNames(css.messageData, msg.sentByMe ? css.alignRight : null)}>
                    {msg.state === 'sending' && <RooIcon icon={'spinner'} spin />}
                    {msg.sentByMe && (
                      <>
                        <span className={css.messageDataTime}>{rooFmt.chatDate(msg.sentAt)}</span> &nbsp; &nbsp;
                        <span className={css.messageDataName}>Me</span>
                      </>
                    )}
                    {!msg.sentByMe && (
                      <>
                        <span className={css.messageDataName}>{msg.from.firstName}</span>
                        <span className={css.messageDataTime}>{rooFmt.chatDate(msg.sentAt)}</span>
                      </>
                    )}
                  </div>
                  <div
                    className={classNames(
                      css.message,
                      msg.sentByMe ? [css.myMessage, css.floatRight] : [css.otherMessage]
                    )}
                  >
                    {msg.files.length > 0 && (
                      <Stack direction={'row'} spacing={2}>
                        {msg.files.map((file) => (
                          <FileThumbnail key={file.id} file={file} size={128} />
                        ))}
                      </Stack>
                    )}
                    {msg.textContent}
                  </div>
                  {standalone && msg.issue && msg.workorder && msg.property && (
                    <div
                      className={classNames(
                        msg.sentByMe ? [css.breadcrumbs, css.floatRight, css.textRight] : [css.breadcrumbs]
                      )}
                    >
                      <RouterLink
                        className={css.breadcrumbLink}
                        to={{
                          pathname: Routes.PropertyView,
                          params: { id: msg.property.id }
                        }}
                      >
                        {msg.property.displayAddress}
                      </RouterLink>
                      &nbsp;&gt;&nbsp;
                      <RouterLink
                        className={css.breadcrumbLink}
                        to={{
                          pathname: Routes.WorkorderView,
                          params: { id: msg.workorder.id }
                        }}
                      >
                        {msg.workorder.friendlyId}
                      </RouterLink>
                      &nbsp;&gt;&nbsp;
                      <RouterLink
                        className={css.breadcrumbLink}
                        to={{
                          pathname: Routes.IssueView,
                          params: { issueId: msg.issue.id, workorderId: msg.workorder.id }
                        }}
                      >
                        {msg.issue.friendlyId}
                      </RouterLink>
                    </div>
                  )}
                </li>
              </Fragment>
            );
          })}
          <li ref={bottomRef} />
        </ul>
      </div>

      {hasFiles && (
        <Stack className={classNames(css.attachment, css.clearfix)} direction={hasFiles ? 'column-reverse' : 'column'}>
          <FileGrid files={files} remove={remove} />
        </Stack>
      )}

      {!standalone && (
        <div className={classNames(css.chatMessage, css.clearfix)}>
          <textarea
            ref={inputRef}
            value={message}
            onKeyPress={(e) => {
              if (e.ctrlKey && (e.key === 'Enter' || e.key === '\n')) {
                submit();
              }
            }}
            onChange={(e) => setMessage(e.target.value)}
            className={'form-control'}
            name="message-to-send"
            placeholder="Type your message"
            rows={3}
          />
          <i className={cls('text-muted')} style={{ fontSize: '12px' }}>
            Press <kbd style={{ opacity: 0.6, fontSize: '12px' }}>CTRL + &#9166;</kbd> to send
          </i>
          <button onClick={submit}>Send</button>
          {canUpload && <FileUploadTextButton className="" entityType={AttachmentEntityType.Message} append={append} />}
        </div>
      )}
    </div>
  );
};

type DrawerState = {
  mode: 'list' | 'single';
  userId: string;
};

export const ChatDrawer = () => {
  const { isVisible, toggle, props } = useWatchGlobalModal('chatDrawer');
  const [drawerState, setDrawerState] = useState<DrawerState>({
    mode: 'list',
    userId: null
  });

  const hasFilter = props?.conversationIds != null && props.conversationIds.length > 0;

  return (
    <RooDrawer
      visible={isVisible}
      onClose={toggle}
      noPadding={drawerState.mode === 'single'}
      title={drawerState.mode === 'list' && `All Conversations${hasFilter ? ' (filtered)' : ''}`}
    >
      <ErrorBoundary fallback={ErrorScreen}>
        <ChatDrawerContent
          drawerState={drawerState}
          setDrawerState={setDrawerState}
          conversationIds={props?.conversationIds}
        />
      </ErrorBoundary>
    </RooDrawer>
  );
};

const ChatDrawerContent = ({
  drawerState,
  setDrawerState,
  conversationIds
}: {
  drawerState: DrawerState;
  setDrawerState: (state: DrawerState) => void;
  conversationIds: string[];
}) => {
  const allConversations = useGlobalConversations();
  let conversations = allConversations;
  const hasFilter = conversationIds != null && conversationIds.length > 0;
  if (hasFilter) {
    conversations = conversations.filter((x) => conversationIds.includes(x.user.id));
  }

  return (
    <>
      {drawerState.mode === 'list' && (
        <ConversationList
          conversations={conversations}
          onSelect={(userId) =>
            setDrawerState({
              mode: 'single',
              userId: userId
            })
          }
          issue={null}
          standalone={true}
        />
      )}
      {drawerState.mode === 'single' && (
        <MessageList
          personId={drawerState.userId}
          issueId={null}
          conversations={conversations}
          onBack={() =>
            setDrawerState({
              mode: 'list',
              userId: null
            })
          }
          standalone={true}
        />
      )}
    </>
  );
};

const BroadcastMessageButton = ({
  role,
  conversations,
  issueId
}: {
  role: string;
  conversations: Conversation[];
  issueId: string;
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [message, setMessage] = useState('');
  const [files, { append, remove }] = useListState<IAttachmentFile>([]);
  const addMessage = useChatStore((x) => x.addMessage);

  const save = async () => {
    try {
      setIsSaving(true);
      await addMessage(
        message,
        conversations.map((x) => x.user),
        issueId,
        files[0]
      );
      setIsVisible(false);
      setMessage('');
      remove(0);
      setIsSaving(false);
    } catch (e) {
      setIsSaving(false);
    }
  };

  return (
    <>
      <div className={'text-center mb-2'}>
        <RooButton onClick={() => setIsVisible(true)} icon={'bullhorn'} size={'sm'}>
          Message all
        </RooButton>
      </div>
      <RooDialog maxWidth={'sm'} fullWidth open={isVisible} onClose={() => setIsVisible(false)}>
        <RooDialog.Title>Message</RooDialog.Title>
        <RooDialog.Content>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div>
              <p>Ask a question or leave a message</p>
            </div>
          </div>
          <textarea
            placeholder={'Type your message'}
            onChange={(e) => setMessage(e.target.value)}
            className={'form-control'}
          />
          <div style={{ display: 'flex', justifyContent: 'center', margin: '10px 0 0 0' }}>
            <FileArea append={append} remove={remove} files={files} entityType={AttachmentEntityType.Message} />
          </div>
          <div className={'mt-4'}>
            It will be sent to the following people:{' '}
            <ul>
              {conversations.map((x) => (
                <li key={x.user.id}>
                  <UserLink key={x.user.id} user={x.user} role={role} />
                </li>
              ))}
            </ul>
          </div>
        </RooDialog.Content>
        <RooDialog.Actions>
          <RooButton
            disabled={isSaving || message == null || message.trim().length === 0}
            loading={isSaving}
            variant={'primary'}
            onClick={() => void save()}
          >
            Send
          </RooButton>
          <Button disabled={isSaving} variant={'secondary'} onClick={() => setIsVisible(false)}>
            Cancel
          </Button>
        </RooDialog.Actions>
      </RooDialog>
    </>
  );
};

const FileArea = ({
  append,
  remove,
  files,
  entityType
}: {
  append: (...items: AttachmentFile[]) => void;
  remove: (...indices: number[]) => void;
  files: IAttachmentFile[];
  entityType: AttachmentEntityType;
}) => {
  const hasFiles = files.length > 0;
  const canUpload = files.length < MAX_FILES;
  return (
    <Stack direction={hasFiles ? 'column-reverse' : 'column'}>
      {canUpload && (
        <div style={{ flexGrow: 0, paddingTop: hasFiles ? '12px' : null }}>
          <FileUploadTextButton className="btn btn-primary" entityType={entityType} append={append} />
        </div>
      )}

      {files.length > 0 && <FileGrid files={files} remove={remove} />}
    </Stack>
  );
};

const FileGrid = ({ files, remove }: { files: IAttachmentFile[]; remove: (...indices: number[]) => void }) => {
  return (
    <Stack spacing={1}>
      <Grid container spacing={2}>
        {files.map((file, idx) => (
          <Grid item key={file.id}>
            <div style={{ position: 'relative' }}>
              <FileThumbnail size={128} file={file} />
              <Button
                style={{ top: 6, right: 6, position: 'absolute' }}
                size={'sm'}
                variant={'danger'}
                onClick={() => {
                  remove(idx);
                }}
              >
                <RooIcon icon={['fas', 'trash']} />
              </Button>
            </div>
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
};

const FileUploadTextButton = ({
  entityType,
  append,
  className
}: {
  append: (...items: AttachmentFile[]) => void;
  entityType: AttachmentEntityType;
  className: string;
}) => {
  const { issue } = useCurrentIssue();
  const modal = useDisclosure(false);
  return (
    <>
      <button className={className} onClick={modal.open}>
        {className !== '' && <RooIcon icon={['fas', 'upload']} />}
        &nbsp;Add Picture
      </button>
      <FilePickerModal
        modal={modal}
        onAppend={append}
        allowedFileTypes={['image']}
        meta={{
          entityType: entityType,
          galleryEntityId: issue?.id,
          galleryEntityType: AttachmentEntityType.Issue
        }}
      />
    </>
  );
};
