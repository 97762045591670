import { issueActionList } from '../shared/types';
import { MuiIcon } from '../../../shared/icons';

export const scrollToChatAction = issueActionList.register({
  Icon: MuiIcon.Chat,
  color: 'info',
  isDisabled: () => false,
  key: 'scroll-to-chat',
  label: 'Messages',
  isVisible: (ctx) => true,
  onClick: () => {
    // something is interfering with the scroll, possibly the popup closing
    setTimeout(() => {
      const mainAnchor = document.querySelector('#main-anchor') as any;
      const chatAnchor = document.querySelector('#chat-anchor') as any;
      const headerAnchor = document.querySelector('#header-anchor') as any;
      if (mainAnchor == null || chatAnchor == null) {
        return;
      }

      const headerHeight = headerAnchor?.getBoundingClientRect().height ?? 0;

      const offset = chatAnchor.getBoundingClientRect().y + mainAnchor.scrollTop - headerHeight;

      mainAnchor.scroll({ top: offset });
    }, 1);
  }
});
