import { BasicEnumDisplay, createMap, EnumMap } from './shared';
import { PaymentMethod } from '../../api/clients';

const InternalMapPaymentMethod: EnumMap<typeof PaymentMethod, BasicEnumDisplay> = {
  Internal: {
    display: 'Internal'
  },
  Dwolla: {
    display: 'Dwolla'
  },
  Stripe: {
    display: 'Stripe'
  }
};

export const EnumMapPaymentMethod = createMap<PaymentMethod, BasicEnumDisplay>(PaymentMethod, InternalMapPaymentMethod);
