import { Grid, ListItemText, MenuItem, MenuList } from '@mui/material';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { User } from 'shared/api/clients';
import { useAppStore, useCurrentUser } from 'shared/store';
import { NavSlim } from 'components';
import { showSuccess } from 'shared/utils';
import { BasicsSection } from 'pages/UserProfile/EditProfile/BasicsSection';
import { ContactInformationSection } from 'pages/UserProfile/EditProfile/ContactInformationSection';
import { PasswordSection } from 'pages/UserProfile/EditProfile/PasswordSection';
import { PreferencesSection } from 'pages/UserProfile/EditProfile/PreferencesSection';
import { WhiteBox } from '../../../components/WhiteBox';

type StateMenuItemSelected = 'basics' | 'password' | 'contact' | 'preferences';

export const EditProfile = () => {
  const onUserUpdated = useAppStore((x) => x.actions.onUserUpdated);
  const currentUser = useCurrentUser();
  const [menuItemSelected, setMenuItemSelected] = useState<StateMenuItemSelected>('basics');

  const afterUpdate = (updated: User) => {
    onUserUpdated(updated);
    showSuccess();
  };

  return (
    <>
      <Helmet>
        <title>User Profile - Walkthroo</title>
      </Helmet>
      <NavSlim breadcrumbs={[{ text: 'User Profile' }]} />
      <Grid container p={1} spacing={1}>
        <Grid item xs={12} md={4} lg={3}>
          <WhiteBox>
            <MenuList sx={{ pr: 1 }} className="menu-user-profile">
              <MenuItem
                sx={{ pt: 1, pb: 1 }}
                selected={menuItemSelected === 'basics'}
                onClick={() => setMenuItemSelected('basics')}
              >
                <ListItemText>My Profile</ListItemText>
              </MenuItem>
              {Boolean(currentUser.contactInfo.email) && (
                <MenuItem
                  sx={{ pt: 1, pb: 1 }}
                  selected={menuItemSelected === 'password'}
                  onClick={() => setMenuItemSelected('password')}
                >
                  <ListItemText>Password</ListItemText>
                </MenuItem>
              )}
              <MenuItem
                sx={{ pt: 1, pb: 1 }}
                selected={menuItemSelected === 'contact'}
                onClick={() => setMenuItemSelected('contact')}
              >
                <ListItemText>Contact Information</ListItemText>
              </MenuItem>
              <MenuItem
                sx={{ pt: 1, pb: 1 }}
                selected={menuItemSelected === 'preferences'}
                onClick={() => setMenuItemSelected('preferences')}
              >
                <ListItemText>Preferences</ListItemText>
              </MenuItem>
            </MenuList>
          </WhiteBox>
        </Grid>
        <Grid item xs={12} md={8} lg={9}>
          <WhiteBox>
            {menuItemSelected === 'basics' && <BasicsSection onUpdate={afterUpdate} />}
            {menuItemSelected === 'password' && Boolean(currentUser.contactInfo.email) && (
              <PasswordSection onUpdate={afterUpdate} />
            )}
            {menuItemSelected === 'contact' && <ContactInformationSection onUpdate={afterUpdate} />}
            {menuItemSelected === 'preferences' && <PreferencesSection onUpdate={afterUpdate} />}
          </WhiteBox>
        </Grid>
      </Grid>
    </>
  );
};
