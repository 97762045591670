import { Calendar } from 'primereact/calendar';
import { ColumnFilterElementTemplateOptions } from 'primereact/column';
import {
  DataTableFilterMeta,
  DataTableFilterMetaData,
  DataTableFilterParams,
  DataTablePageParams,
  DataTableRowToggleParams,
  DataTableSortOrderType,
  DataTableSortParams
} from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Path } from 'react-hook-form';
import { Instant, SortDirection } from 'shared/api/clients';
import { rooDate } from 'shared/utils';
import { useMyCompaniesForDropdownPrime } from '../../shared/api/queries';

export const dateFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
  return (
    <Calendar
      value={options.value}
      onChange={(e) => options.filterCallback(e.value, options.index)}
      dateFormat="mm/dd/yy"
      placeholder="mm/dd/yyyy"
      mask="99/99/9999"
    />
  );
};

export const boolFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
  return (
    <>
      <div className="p-field-checkbox p-m-0">
        <TriStateCheckbox value={options.value} onChange={(e) => options.filterCallback(e.value)} />
        <label style={{ marginLeft: '0.5rem', lineHeight: 1, marginBottom: 0 }}>Is Occupied</label>
      </div>
    </>
  );
};

export const extractFilterVal = <T extends {}>(filters: DataTableFilterMeta, key: string): T => {
  const filter = filters[key];
  if (filter == null) {
    return null;
  }

  if ('operator' in filter) {
    return filter.constraints[0]?.value as T;
  } else {
    return filter.value as T;
  }
};

export const CompanyDropdown = ({ options }: { options: ColumnFilterElementTemplateOptions }) => {
  const { isLoading, data } = useMyCompaniesForDropdownPrime();

  return (
    <Dropdown
      value={options.value}
      options={data ?? []}
      disabled={isLoading}
      onChange={(e) => options.filterCallback(e.value, options.index)}
      placeholder="Select a Company"
      className="p-column-filter"
      showClear
    />
  );
};

type ServerState<TItem> = {
  isLoading: boolean;
  data: TItem[];
  totalItems: number;
};

type TableState = {
  expandedRows: Record<string, boolean>;
  first: number;
  rows: number;
  page: number;
  sortField: string;
  sortOrder: DataTableSortOrderType;
  filters: DataTableFilterMeta;
};

export type ServerGridState<TItem, TExternalFilters> = {
  expandAllRows: () => void;
  externalFilters: TExternalFilters;
  tableHandlers: {
    first: number;
    rows: number;
    totalRecords: number;
    onPage: (event: DataTablePageParams) => void;
    onSort: (event: DataTableSortParams) => void;
    onFilter: (event: DataTableFilterParams) => void;
    onRowToggle: (event: DataTableRowToggleParams) => void;
    sortField: string;
    sortOrder: DataTableSortOrderType;
    filters: DataTableFilterMeta;
    loading: boolean;
    expandedRows: Record<string, boolean>;
    value: TItem[];
  };
  refresh: () => Promise<void>;
};

class FilterExtractor {
  constructor(private filters: DataTableFilterMeta) {}

  getString = (key: string): string => extractFilterVal<string>(this.filters, key);
  getDate = (key: string): Date => extractFilterVal<Date>(this.filters, key);
  getInstant = (key: string): Instant => rooDate.makeInstant(extractFilterVal<Date>(this.filters, key));
  getBoolean = (key: string): boolean => extractFilterVal<boolean>(this.filters, key);
  getNumber = (key: string): number => extractFilterVal<number>(this.filters, key);

  makeTyped = <T,>() => new TypedFilterExtractor<T>(this);
}

class TypedFilterExtractor<T> {
  constructor(private extractor: FilterExtractor) {}

  getString = (key: Path<T>): string => this.extractor.getString(key);
  getDate = (key: Path<T>): Date => this.extractor.getDate(key);
  getInstant = (key: Path<T>): Instant => this.extractor.getInstant(key);
  getBoolean = (key: Path<T>): boolean => this.extractor.getBoolean(key);
  getNumber = (key: Path<T>): number => this.extractor.getNumber(key);
}

export class FilterBuilder<T> {
  filters: DataTableFilterMeta = {};
  addString = (key: Path<T>, value: string = '') => {
    this.filters[key] = { operator: 'AND', constraints: [{ value: value, matchMode: 'contains' }] };
  };
  addId = (key: Path<T>, value: string = '') => {
    this.filters[key] = { value: null, matchMode: 'equals' };
  };
  addDate = (key: Path<T>, value: Date = null) => {
    this.filters[key] = { value: value, matchMode: 'dateIs' };
  };
  addBoolean = (key: Path<T>, value: boolean = null) => {
    this.filters[key] = { value: value, matchMode: 'equals' };
  };

  get = () => this.filters;
}

export type ServerGridParams<TItem, TExternalFilters> = {
  forceExpanded?: boolean;
  dataGetter: (meta: {
    externalFilters: TExternalFilters;
    sortField: string;
    sortDirection: SortDirection;
    filterExtractor: FilterExtractor;
    take: number;
    skip: number;
  }) => Promise<{ items: TItem[]; totalItems: number }>;
  externalFilters: TExternalFilters;
  idGetter: (item: TItem) => string;
  initialFilters: DataTableFilterMeta;
  initialSortField: string;
  initialSortDirection: SortDirection;
  onInitialLoad?: () => void;
  onLoad?: () => void;
};

export const useServerGrid = <TItem, TExternalFilters>({
  forceExpanded,
  dataGetter,
  idGetter,
  externalFilters,
  initialFilters,
  initialSortDirection,
  initialSortField,
  onInitialLoad,
  onLoad
}: ServerGridParams<TItem, TExternalFilters>): ServerGridState<TItem, TExternalFilters> => {
  const [serverState, setServerState] = useState<ServerState<TItem>>({ isLoading: true, data: [], totalItems: 0 });

  const idGetterRef = useRef(idGetter);
  idGetterRef.current = idGetter;

  const dataGetterRef = useRef(dataGetter);
  dataGetterRef.current = dataGetter;

  const forceExpandedRef = useRef(forceExpanded);
  forceExpandedRef.current = forceExpanded;

  const externalFiltersRef = useRef(externalFilters);
  externalFiltersRef.current = externalFilters;

  const onInitialLoadRef = useRef(onInitialLoad);
  onInitialLoadRef.current = onInitialLoad;

  const onLoadRef = useRef(onLoad);
  onLoadRef.current = onLoad;

  const isInitialLoadRef = useRef(true);

  const [tableState, setTableState] = useState<TableState>({
    first: 0,
    rows: 10,
    page: 1,
    sortField: initialSortField,
    sortOrder: initialSortDirection === SortDirection.Descending ? -1 : 1,
    expandedRows: {},
    filters: initialFilters
  });

  const loadRef = useRef<() => Promise<void>>(null);
  loadRef.current = async () => {
    setServerState((x) => ({ ...x, isLoading: true }));
    try {
      let finalSortDirection = SortDirection.Default;
      switch (tableState.sortOrder) {
        case 1:
          finalSortDirection = SortDirection.Ascending;
          break;
        case -1:
          finalSortDirection = SortDirection.Descending;
          break;
      }

      const result = await dataGetterRef.current({
        externalFilters: externalFiltersRef.current,
        filterExtractor: new FilterExtractor(tableState.filters),
        sortDirection: finalSortDirection,
        sortField: tableState.sortField,
        skip: tableState.first,
        take: tableState.rows
      });

      if (isInitialLoadRef.current) {
        onInitialLoadRef.current?.();
        isInitialLoadRef.current = false;
      }

      onLoadRef.current?.();

      setServerState({ isLoading: false, data: result.items, totalItems: result.totalItems });
      if (forceExpandedRef.current) {
        const newExpanded = {} as Record<string, boolean>;
        for (const id of result.items.map((x) => idGetterRef.current(x))) {
          newExpanded[id] = true;
        }

        setTableState((x) => ({ ...x, expandedRows: newExpanded }));
      }
    } catch (e) {
      setServerState((x) => ({ ...x, isLoading: false }));
    }
  };

  useEffect(() => {
    void loadRef.current();
  }, [tableState.rows, tableState.first, tableState.sortField, tableState.sortOrder, tableState.filters]);

  const onPage = useCallback((event: DataTablePageParams) => {
    setTableState((x) => ({ ...x, first: event.first, rows: event.rows, page: event.page }));
  }, []);

  const onSort = useCallback((event: DataTableSortParams) => {
    setTableState((x) => ({ ...x, sortField: event.sortField, sortOrder: event.sortOrder }));
  }, []);

  const onFilter = useCallback((event: DataTableFilterParams) => {
    setTableState((x) => ({ ...x, filters: event.filters as Record<string, DataTableFilterMetaData>, first: 0 }));
  }, []);

  const onRowToggle = useCallback((event: DataTableRowToggleParams) => {
    setTableState((x) => ({ ...x, expandedRows: event.data as any }));
  }, []);

  const refresh = useCallback(() => {
    return loadRef.current();
  }, []);

  const expandAllRows = useCallback(() => {
    const newExpanded = {} as Record<string, boolean>;
    for (const id of serverState.data.map((x) => idGetterRef.current(x))) {
      newExpanded[id] = true;
    }

    setTableState((x) => ({ ...x, expandedRows: newExpanded }));
  }, [serverState.data]);

  return {
    tableHandlers: {
      first: tableState.first,
      rows: tableState.rows,
      expandedRows: tableState.expandedRows,
      filters: tableState.filters,
      loading: serverState.isLoading,
      onFilter,
      onPage,
      onSort,
      onRowToggle,
      sortField: tableState.sortField,
      sortOrder: tableState.sortOrder,
      value: serverState.data,
      totalRecords: serverState.totalItems
    },
    externalFilters: externalFilters,
    refresh: refresh,
    expandAllRows
  };
};
