import { RouterLink, UserLink } from 'components';
import { ViewMessageButton } from 'pages/Workorders/ViewIssue/ViewMessageModal';
import {
  IIssue,
  IssueActivityLogEntry,
  IssueActivityType,
  IssueClonedDestinationMeta,
  IssueClonedSourceMeta,
  IssueStatus
} from 'shared/api/clients';
import { EnumMapIssueStatus } from 'shared/store/enums/issueStatus';
import { BasicEnumDisplay, createMap, EnumMap } from 'shared/store/enums/shared';
import { rooFmt } from 'shared/utils';
import { Routes } from '../../../shared/routing';
import { SvgIconComponent } from '@mui/icons-material';
import { MuiIcon } from '../../../shared/icons';

type ActivityPayload = {
  color: 'primary' | 'secondary' | 'success' | 'warning' | 'error';
  Icon: SvgIconComponent;
  textFormatter: (log: IssueActivityLogEntry, issue: IIssue) => JSX.Element;
};

type IssueActivityEnumDisplay = BasicEnumDisplay & ActivityPayload;

const UserCreated = ({ log }: { log: IssueActivityLogEntry }) => {
  if (log.modifiedBy == null) {
    return <>Walkthroo</>;
  }

  return <UserLink user={log.modifiedBy} role={null} />;
};

type EnumKeys = keyof typeof IssueActivityType;

function createActivityGroup<T extends { [key in EnumKeys]?: IssueActivityEnumDisplay }>(group: T) {
  return group;
}

const genericActivities = createActivityGroup({
  Created: {
    display: 'Created',
    color: 'primary',
    Icon: MuiIcon.Add,
    textFormatter: (log, issue) => (
      <>
        <UserCreated log={log} /> created Issue <strong>{issue.friendlyId}</strong>
      </>
    )
  },
  Updated: {
    display: 'Updated',
    color: 'primary',
    Icon: MuiIcon.Edit,
    textFormatter: (log, _) => (
      <>
        <UserCreated log={log} /> updated the issue
      </>
    )
  },
  Closed: {
    display: 'Closed',
    color: 'error',
    Icon: MuiIcon.StopCircle,
    textFormatter: (log, _) => (
      <>
        <UserCreated log={log} /> closed the issue
      </>
    )
  },
  MarkedIssuePaid: {
    display: 'Paid',
    color: 'success',
    Icon: MuiIcon.StopCircle,
    textFormatter: (log, _) => (
      <>
        <UserCreated log={log} /> marked the issue as paid
      </>
    )
  },
  Canceled: {
    display: 'Canceled',
    color: 'error',
    Icon: MuiIcon.StopCircle,
    textFormatter: (log, _) => {
      const reasonDict: Partial<Record<IssueStatus, string>> = {
        [IssueStatus.CanceledOther]: 'Other',
        [IssueStatus.CanceledOwnerDeclined]: 'Owner Declined',
        [IssueStatus.CanceledLostBid]: 'Lost Bid',
        [IssueStatus.CanceledUnassigned]: 'Unassigned'
      };
      const reason = reasonDict[log.newStatus];
      return (
        <>
          <UserCreated log={log} /> canceled the issue. {reason && <>Reason: {reason}</>}
        </>
      );
    }
  },
  CompletedWork: {
    display: 'Completed Work',
    color: 'success',
    Icon: MuiIcon.Check,
    textFormatter: (log, _) => (
      <>
        <UserCreated log={log} /> completed the job
      </>
    )
  },
  ChangedStatus: {
    display: 'Changed Status',
    color: 'primary',
    Icon: MuiIcon.CompareArrows,
    textFormatter: (log, _) => (
      <>
        <UserCreated log={log} /> changed the status from{' '}
        <strong>{EnumMapIssueStatus.get(log.oldStatus).display}</strong> to{' '}
        <strong>{EnumMapIssueStatus.get(log.newStatus).display}</strong>
      </>
    )
  },
  AddedMessage: {
    display: 'Added Message',
    color: 'primary',
    Icon: MuiIcon.Chat,
    textFormatter: (log, issue) => (
      <>
        <UserCreated log={log} /> sent a message. <ViewMessageButton activityId={log.id} />
      </>
    )
  },
  TenantCancelIssue: {
    display: 'Tenant Requested Cancellation',
    color: 'error',
    Icon: MuiIcon.Cancel,
    textFormatter: (log) => (
      <>
        <UserCreated log={log} /> <span className={'text-danger'}> requested the cancellation of this issue</span>
      </>
    )
  },
  VendorCancelIssue: {
    display: 'Vendor Canceled Issue',
    color: 'error',
    Icon: MuiIcon.Cancel,
    textFormatter: (log) => (
      <>
        <UserCreated log={log} /> <span className={'text-danger'}> canceled this issue</span>
      </>
    )
  },
  CreatedBoardPost: {
    display: 'Created Board Post',
    color: 'primary',
    Icon: MuiIcon.ModeComment,
    textFormatter: (log) => (
      <>
        <UserCreated log={log} /> created a new Bulletin Board post.
      </>
    )
  },
  DeletedBoardPost: {
    display: 'Deleted Board Post',
    color: 'primary',
    Icon: MuiIcon.ModeComment,
    textFormatter: (log) => (
      <>
        <UserCreated log={log} /> deleted a Bulletin Board post.
      </>
    )
  }
});

const assignmentActivities = createActivityGroup({
  AssignedVendor: {
    display: 'Assigned Vendor',
    color: 'primary',
    Icon: MuiIcon.PersonAdd,
    textFormatter: (log, _) => (
      <>
        <UserCreated log={log} /> assigned the issue to <strong>{log.vendor?.companyName}</strong>
      </>
    )
  },
  UnassignedVendor: {
    display: 'Unassigned Vendor',
    color: 'error',
    Icon: MuiIcon.PersonRemove,
    textFormatter: (log, _) => (
      <>
        <UserCreated log={log} /> unassigned the issue from <strong>{log.vendor?.companyName}</strong>
      </>
    )
  },
  AssignedTech: {
    display: 'Assigned Tech',
    color: 'primary',
    Icon: MuiIcon.PersonAdd,
    textFormatter: (log, _) => (
      <>
        <UserCreated log={log} /> assigned the issue to a tech.
      </>
    )
  },
  UnassignedTech: {
    display: 'Unassigned Tech',
    color: 'error',
    Icon: MuiIcon.PersonRemove,
    textFormatter: (log, _) => (
      <>
        <UserCreated log={log} /> unassigned the issue from a tech.
      </>
    )
  },
  AcceptedAssignment: {
    display: 'Accepted the job',
    color: 'success',
    Icon: MuiIcon.Check,
    textFormatter: (log) => (
      <>
        <UserCreated log={log} /> accepted this job.
      </>
    )
  },
  DeclinedAssignment: {
    display: 'Refused the job',
    Icon: MuiIcon.Clear,
    color: 'error',
    textFormatter: (log) => (
      <>
        <UserCreated log={log} /> <span className={'text-danger'}> refused this job</span>
      </>
    )
  },
  MarkVendorUnresponsive: {
    display: 'Vendor Unresponsive',
    color: 'warning',
    Icon: MuiIcon.Snooze,
    textFormatter: (log) => (
      <>
        <UserCreated log={log} /> marked issue as <strong>Vendor Unresponsive</strong>
      </>
    )
  },
  MarkVendorDeclined: {
    display: 'Marked Vendor Declined',
    color: 'error',
    Icon: MuiIcon.Cancel,
    textFormatter: (log) => (
      <>
        <UserCreated log={log} /> marked issue as <strong>All Vendors Declined</strong>
      </>
    )
  },
  ClonedSource: {
    display: 'Created a copy of this issue',
    color: 'warning',
    Icon: MuiIcon.ContentCopy,
    textFormatter: (log) => {
      const meta = log.issueActivityMeta as IssueClonedSourceMeta;
      return (
        <>
          <UserCreated log={log} /> copied this issue into{' '}
          <RouterLink
            to={{ pathname: Routes.IssueView, params: { workorderId: meta.workorderId, issueId: meta.destinationId } }}
          >
            {meta.destinationFriendlyId}
          </RouterLink>
          .
        </>
      );
    }
  },
  ClonedDestination: {
    display: 'Copied this issue from another one',
    color: 'warning',
    Icon: MuiIcon.ContentCopy,
    textFormatter: (log) => {
      const meta = log.issueActivityMeta as IssueClonedDestinationMeta;
      return (
        <>
          <UserCreated log={log} /> copied this issue from{' '}
          <RouterLink
            to={{ pathname: Routes.IssueView, params: { workorderId: meta.workorderId, issueId: meta.sourceId } }}
          >
            {meta.sourceFriendlyId}
          </RouterLink>
          .
        </>
      );
    }
  }
});

const invitationActivities = createActivityGroup({
  InvitationAccepted: {
    display: 'Accepted Issue',
    color: 'success',
    Icon: MuiIcon.PersonAdd,
    textFormatter: (log, _) => (
      <>
        <UserCreated log={log} /> accepted the issue assignment
      </>
    )
  },
  InvitationExpired: {
    display: 'Issue Invitation Expired',
    color: 'warning',
    Icon: MuiIcon.Snooze,
    textFormatter: (_, __) => <>An issue invitation expired.</>
  },
  InvitationNoLongerAvailable: {
    display: 'Issue Invitation No Longer Available',
    color: 'error',
    Icon: MuiIcon.Snooze,
    textFormatter: (_, __) => <>An issue invitation is no longer available.</>
  },
  InvitationRejected: {
    display: 'Rejected Invitation',
    color: 'error',
    Icon: MuiIcon.Cancel,
    textFormatter: (log) => (
      <>
        <UserCreated log={log} /> declined the issue assignment
      </>
    )
  },
  InvitedVendors: {
    display: 'Invited Vendors',
    color: 'primary',
    Icon: MuiIcon.PersonAdd,
    textFormatter: (log) => (
      <>
        <UserCreated log={log} /> assigned the issue to multiple vendors
      </>
    )
  }
});

const scheduleActivities = createActivityGroup({
  Scheduled: {
    display: 'Scheduled',
    color: 'primary',
    Icon: MuiIcon.Event,
    textFormatter: (log, _) => (
      <>
        <UserCreated log={log} /> scheduled the job for{' '}
        <strong>{rooFmt.instantDate(log.scheduleProposal.scheduledInstant)}</strong>{' '}
        <strong>{rooFmt.timeBlock(log.scheduleProposal.timeBlocks?.[0])}</strong>
        <br />
        {log.scheduleProposal.notes && (
          <>
            <br />
            Notes:
            <p>{log.scheduleProposal.notes}</p>
          </>
        )}
      </>
    )
  },
  ProposedSchedule: {
    display: 'Proposed Schedule',
    color: 'primary',
    Icon: MuiIcon.CalendarMonth,
    textFormatter: (log, _) => (
      <>
        <UserCreated log={log} /> proposed a schedule for{' '}
        <strong>{rooFmt.instantDate(log.scheduleProposal.scheduledInstant)}</strong>{' '}
        <strong>{rooFmt.timeBlock(log.scheduleProposal.timeBlocks?.[0])}</strong>
        <br />
        {log.scheduleProposal.notes && (
          <>
            <br />
            Notes:
            <p>{log.scheduleProposal.notes}</p>
          </>
        )}
      </>
    )
  },
  VendorCancelSchedule: {
    display: 'Vendor Rescheduled',
    color: 'warning',
    Icon: MuiIcon.EventBusy,
    textFormatter: (log) => (
      <>
        <UserCreated log={log} /> will reschedule the visit
      </>
    )
  },
  AcceptedIssueSchedule: {
    display: 'Accepted schedule',
    color: 'success',
    Icon: MuiIcon.Check,
    textFormatter: (log) => (
      <>
        <UserCreated log={log} /> accepted the proposed schedule.
      </>
    )
  },
  RejectedIssueSchedule: {
    display: 'Rejected schedule',
    color: 'error',
    Icon: MuiIcon.Cancel,
    textFormatter: (log) => (
      <>
        <UserCreated log={log} /> rejected the proposed schedule.
      </>
    )
  },
  TenantCancelSchedule: {
    display: 'Tenant Rescheduled',
    color: 'warning',
    Icon: MuiIcon.EventBusy,
    textFormatter: (log) => (
      <>
        <UserCreated log={log} /> requested a reschedule of the visit
      </>
    )
  },
  SkippedScheduling: {
    display: 'Skipped providing a Schedule',
    color: 'warning',
    Icon: MuiIcon.Cancel,
    textFormatter: (log) => (
      <>
        <UserCreated log={log} /> skipped providing a schedule
      </>
    )
  }
});

const changeRequestActivities = createActivityGroup({
  AcceptedChangeRequest: {
    display: 'Accepted Change Order Request',
    color: 'success',
    Icon: MuiIcon.Check,
    textFormatter: (log, _) => (
      <>
        <UserCreated log={log} /> accepted a Change Order Request.
      </>
    )
  },
  AddedChangeRequest: {
    display: 'Added Change Order Request',
    color: 'primary',
    Icon: MuiIcon.Add,
    textFormatter: (log, _) => (
      <>
        <UserCreated log={log} /> submitted a Change Order Request.
      </>
    )
  },
  RejectedChangeRequest: {
    display: 'Rejected Change Order Request',
    color: 'error',
    Icon: MuiIcon.PersonRemove,
    textFormatter: (log, _) => (
      <>
        <UserCreated log={log} /> rejected a Change Order Request.
      </>
    )
  },
  ElevatedChangeRequest: {
    display: 'Sent Change Order Request to Owner',
    color: 'primary',
    Icon: MuiIcon.Elevator,
    textFormatter: (log, _) => (
      <>
        <UserCreated log={log} /> sent a Change Order Request to owner.
      </>
    )
  },
  UpdatedChangeRequest: {
    display: 'Updated Change Request',
    color: 'primary',
    Icon: MuiIcon.Edit,
    textFormatter: (log) => (
      <>
        <UserCreated log={log} /> updated a Change Order Request.
      </>
    )
  },
  DeletedChangeRequest: {
    display: 'Delete Change Request',
    color: 'error',
    Icon: MuiIcon.Cancel,
    textFormatter: (log) => (
      <>
        <UserCreated log={log} /> deleted a Change Order Request.
      </>
    )
  },
  ForceAcceptedChangeRequest: {
    display: 'Force Accepted a Change Request',
    color: 'warning',
    Icon: MuiIcon.Cancel,
    textFormatter: (log) => (
      <>
        <UserCreated log={log} /> force accepted a Change Order Request
      </>
    )
  }
});

const bidActivities = createActivityGroup({
  RequestedBids: {
    display: 'Requested Bids',
    color: 'primary',
    Icon: MuiIcon.Gavel,
    textFormatter: (log, _) => (
      <>
        <UserCreated log={log} /> requested bids from vendors.
      </>
    )
  },
  AcceptBid: {
    display: 'Accepted Bid',
    color: 'success',
    Icon: MuiIcon.Check,
    textFormatter: (log, _) => (
      <>
        <UserCreated log={log} /> accepted a bid.
      </>
    )
  },
  RejectBid: {
    display: 'Reject Bid',
    color: 'error',
    Icon: MuiIcon.Cancel,
    textFormatter: (log, _) => (
      <>
        <UserCreated log={log} /> rejected a bid.
      </>
    )
  },
  SubmitBid: {
    display: 'Submit Bid',
    color: 'primary',
    Icon: MuiIcon.RequestQuote,
    textFormatter: (log, _) => (
      <>
        <UserCreated log={log} /> submitted a bid.
      </>
    )
  },
  UpdateBid: {
    display: 'Updated Bid',
    color: 'primary',
    Icon: MuiIcon.RequestQuote,
    textFormatter: (log, _) => (
      <>
        <UserCreated log={log} /> updated a bid.
      </>
    )
  },
  ElevatedBid: {
    display: 'Sent Bid to Owner',
    color: 'primary',
    Icon: MuiIcon.Elevator,
    textFormatter: (log, _) => (
      <>
        <UserCreated log={log} /> sent bid to owner.
      </>
    )
  },
  BidProposedSchedule: {
    display: 'Proposed Bid Schedule',
    color: 'primary',
    Icon: MuiIcon.CalendarMonth,
    textFormatter: (log) => (
      <>
        <UserCreated log={log} /> submitted a schedule proposal for a bid.
      </>
    )
  },
  BidScheduled: {
    display: 'Bid Visit Scheduled',
    color: 'primary',
    Icon: MuiIcon.CalendarMonth,
    textFormatter: (log) => (
      <>
        <UserCreated log={log} /> submitted a schedule for a bid.
      </>
    )
  },
  DeclineBid: {
    display: 'Declined Bid',
    color: 'error',
    Icon: MuiIcon.Cancel,
    textFormatter: (log) => (
      <>
        <UserCreated log={log} /> declined to bid on this issue.
      </>
    )
  },
  UninvitedBidder: {
    display: 'Uninvited Bidder',
    color: 'error',
    Icon: MuiIcon.Cancel,
    textFormatter: (log) => (
      <>
        <UserCreated log={log} /> uninvited a bidder on this issue.
      </>
    )
  },
  RevisedBid: {
    display: 'Revised a Bid',
    color: 'primary',
    Icon: MuiIcon.RequestQuote,
    textFormatter: (log, _) => (
      <>
        <UserCreated log={log} /> updated a bid.
      </>
    )
  },
  CreatedBid: {
    display: 'Created a Bid',
    color: 'primary',
    Icon: MuiIcon.RequestQuote,
    textFormatter: (log, _) => (
      <>
        <UserCreated log={log} /> created a bid.
      </>
    )
  },
  AcceptedRevisedBid: {
    display: 'Accepted changes a Bid',
    color: 'primary',
    Icon: MuiIcon.RequestQuote,
    textFormatter: (log, _) => (
      <>
        <UserCreated log={log} /> accepted changes to a bid.
      </>
    )
  },
  ForceAcceptedRevisedBid: {
    display: 'Force accepted changes a Bid',
    color: 'warning',
    Icon: MuiIcon.Cancel,
    textFormatter: (log, _) => (
      <>
        <UserCreated log={log} /> force accepted changes to a bid.
      </>
    )
  },
  RequestedChangesBid: {
    display: 'Requested changes to a Bid',
    color: 'primary',
    Icon: MuiIcon.RequestQuote,
    textFormatter: (log, _) => (
      <>
        <UserCreated log={log} /> requested changes to an accepted bid.
      </>
    )
  },
  AcceptedChangedBid: {
    display: 'Accepted changes to a Bid',
    color: 'primary',
    Icon: MuiIcon.RequestQuote,
    textFormatter: (log, _) => (
      <>
        <UserCreated log={log} /> accepted changes to a bid.
      </>
    )
  },
  ForceAcceptedChangedBid: {
    display: 'Force accepted changes to a Bid',
    color: 'warning',
    Icon: MuiIcon.Cancel,
    textFormatter: (log, _) => (
      <>
        <UserCreated log={log} /> force accepted changes to a bid.
      </>
    )
  },
  DeclinedChangedBid: {
    display: 'Declined changes to a Bid',
    color: 'error',
    Icon: MuiIcon.RequestQuote,
    textFormatter: (log, _) => (
      <>
        <UserCreated log={log} /> declined changes to a bid.
      </>
    )
  },
  SkippedBidding: {
    display: 'Skipped bidding: Under Limit',
    color: 'warning',
    Icon: MuiIcon.Cancel,
    textFormatter: (log) => (
      <>
        <UserCreated log={log} /> Skipped bidding: Under Limit
      </>
    )
  },
  SplitBid: {
    display: 'Split Bid',
    color: 'primary',
    Icon: MuiIcon.VerticalSplit,
    textFormatter: (log, _) => (
      <>
        <UserCreated log={log} /> split a bid
      </>
    )
  }
});

const invoiceActivities = createActivityGroup({
  ModifiedPublishedInvoice: {
    display: 'Updated an Invoice',
    color: 'primary',
    Icon: MuiIcon.Receipt,
    textFormatter: (log) => (
      <>
        <UserCreated log={log} /> updated an invoice
      </>
    )
  },
  PublishedInvoice: {
    display: 'Added an Invoice',
    color: 'primary',
    Icon: MuiIcon.Receipt,
    textFormatter: (log) => (
      <>
        <UserCreated log={log} /> added an invoice
      </>
    )
  },
  AcceptedInvoice: {
    display: 'Accepted an Invoice',
    color: 'success',
    Icon: MuiIcon.Check,
    textFormatter: (log) => (
      <>
        <UserCreated log={log} /> accepted an invoice
      </>
    )
  },
  RejectedInvoice: {
    display: 'Rejected an Invoice',
    color: 'error',
    Icon: MuiIcon.Cancel,
    textFormatter: (log) => (
      <>
        <UserCreated log={log} /> rejected an invoice
      </>
    )
  },
  PaidInvoice: {
    display: 'Paid an Invoice',
    color: 'primary',
    Icon: MuiIcon.PriceCheck,
    textFormatter: (log) => (
      <>
        <UserCreated log={log} /> paid an invoice
      </>
    )
  },
  AddedPayment: {
    display: 'Added a payment',
    color: 'primary',
    Icon: MuiIcon.PriceCheck,
    textFormatter: (log) => (
      <>
        <UserCreated log={log} /> added a payment
      </>
    )
  },
  EditedPayment: {
    display: 'Edited a payment',
    color: 'warning',
    Icon: MuiIcon.PriceCheck,
    textFormatter: (log) => (
      <>
        <UserCreated log={log} /> edited a payment
      </>
    )
  },
  DeletedPayment: {
    display: 'Deleted a payment',
    color: 'error',
    Icon: MuiIcon.PriceCheck,
    textFormatter: (log) => (
      <>
        <UserCreated log={log} /> deleted a payment
      </>
    )
  },
  SystemMarkedPaid: {
    display: 'Paid',
    color: 'success',
    Icon: MuiIcon.StopCircle,
    textFormatter: (log, _) => (
      <>
        <UserCreated log={log} /> marked the issue as paid
      </>
    )
  },
  SystemMarkedNotPaid: {
    display: 'Not Paid',
    color: 'success',
    Icon: MuiIcon.StopCircle,
    textFormatter: (log, _) => (
      <>
        <UserCreated log={log} /> marked the issue as not paid
      </>
    )
  },
  SplitInvoice: {
    display: 'Split Invoice',
    color: 'primary',
    Icon: MuiIcon.VerticalSplit,
    textFormatter: (log, _) => (
      <>
        <UserCreated log={log} /> split an invoice
      </>
    )
  },
  SplitEstimate: {
    display: 'Split Estimate',
    color: 'primary',
    Icon: MuiIcon.VerticalSplit,
    textFormatter: (log, _) => (
      <>
        <UserCreated log={log} /> split an estimate
      </>
    )
  }
});

const estimateActivities = createActivityGroup({
  ModifiedPublishedEstimate: {
    display: 'Updated an Estimate',
    color: 'primary',
    Icon: MuiIcon.Receipt,
    textFormatter: (log) => (
      <>
        <UserCreated log={log} /> updated an estimate
      </>
    )
  },
  PublishedEstimate: {
    display: 'Added an Estimate',
    color: 'primary',
    Icon: MuiIcon.Receipt,
    textFormatter: (log) => (
      <>
        <UserCreated log={log} /> added an estimate
      </>
    )
  },
  AcceptedEstimate: {
    display: 'Accepted an Estimate',
    color: 'success',
    Icon: MuiIcon.Check,
    textFormatter: (log) => (
      <>
        <UserCreated log={log} /> accepted an estimate
      </>
    )
  },
  RejectedEstimate: {
    display: 'Rejected an Estimate',
    color: 'error',
    Icon: MuiIcon.Cancel,
    textFormatter: (log) => (
      <>
        <UserCreated log={log} /> rejected an estimate
      </>
    )
  },
  SkippedEstimation: {
    display: 'Skipped providing an Estimate',
    color: 'warning',
    Icon: MuiIcon.Cancel,
    textFormatter: (log) => (
      <>
        <UserCreated log={log} /> skipped providing an estimate
      </>
    )
  },
  ForceAcceptedEstimate: {
    display: 'Force Accepted an Estimate',
    color: 'warning',
    Icon: MuiIcon.Cancel,
    textFormatter: (log) => (
      <>
        <UserCreated log={log} /> force accepted an estimate
      </>
    )
  },
  MarkedEstimateSent: {
    display: 'Marked Estimate Sent',
    color: 'success',
    Icon: MuiIcon.Outbound,
    textFormatter: (log) => (
      <>
        <UserCreated log={log} /> sent an Estimate through an external system
      </>
    )
  },
  MarkedEstimateNotSent: {
    display: 'Marked Estimate Not Sent',
    color: 'error',
    Icon: MuiIcon.Outbound,
    textFormatter: (log) => (
      <>
        <UserCreated log={log} /> did not send an Estimate through an external system
      </>
    )
  }
});

const InternalMapIssueActivityType: EnumMap<typeof IssueActivityType, IssueActivityEnumDisplay> = {
  Unknown: {
    display: 'Unknown',
    color: 'primary',
    Icon: MuiIcon.QuestionMark,
    textFormatter: () => <>Unknown event</>
  },
  ...genericActivities,
  ...assignmentActivities,
  ...invitationActivities,
  ...scheduleActivities,
  ...changeRequestActivities,
  ...bidActivities,
  ...invoiceActivities,
  ...estimateActivities
};

export const EnumMapIssueActivityType = createMap<IssueActivityType, IssueActivityEnumDisplay>(
  IssueActivityType,
  InternalMapIssueActivityType
);
