import React, { InputHTMLAttributes } from 'react';
import { FieldValues, useController } from 'react-hook-form';
import { DropdownOption } from 'shared/store';
import { ControlProps } from 'components/form/types';
import { FormGroup } from 'react-bootstrap';
import { FormGroupProps } from 'react-bootstrap/FormGroup';
import { RooFormLabel } from './RooFormLabel';

type BaseProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'id' | 'type' | 'name' | 'onChange' | 'value' | 'onBlur'>;
type FinalProps<TForm extends FieldValues> = ControlProps<TForm> &
  BaseProps & {
    options: DropdownOption<string | number>[];
    groupProps?: FormGroupProps;
  };
export type FieldRadioProps<TForm extends FieldValues> = FinalProps<TForm>;

export const FieldRadio = <T extends Record<string, string | number>>({
  control,
  name,
  options,
  groupProps,
  required,
  ...rest
}: FinalProps<T>) => {
  const { field } = useController({
    control: control,
    name: name
  });

  return (
    <FormGroup {...(groupProps ?? {})}>
      {options.map((x) => {
        const optionId = `name-${x.value}`;
        return (
          <>
            <div className="form-check form-check-inline">
              <input
                {...rest}
                key={optionId}
                type="radio"
                name={name}
                id={optionId}
                className="form-check-input"
                value={x.value}
                checked={field.value === x.value}
                onChange={() => {
                  // @ts-ignore
                  field.onChange(x.value);
                }}
              />
              <RooFormLabel className={'form-check-label'} label={x.label} name={optionId} required={required} />
            </div>
          </>
        );
      })}
    </FormGroup>
  );
};
