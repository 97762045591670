import React, { useMemo } from 'react';
import { PriceGuideEntry } from '../../shared/api/clients';
import { useWatchGlobalModal } from './modalStore';
import { apiProvider } from '../../shared/api/apiProvider';
import { RooDialog } from '../RooDialog';
import { RooButton } from '../RooButton';
import MaterialReactTable, { MRT_ColumnDef } from 'material-react-table';
import { useQuery } from '@tanstack/react-query';
import { rooFmt } from '../../shared/utils';
import { Alert, Stack, styled, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

export const PriceGuideModal = () => {
  const { isVisible, toggle } = useWatchGlobalModal('priceGuide');

  return (
    <>
      <RooDialog maxWidth={'xl'} fullWidth open={isVisible} onClose={toggle}>
        <RooDialog.Title>Prices</RooDialog.Title>
        <RooDialog.Content>
          <WalkthrooPriceList />
        </RooDialog.Content>
        <RooDialog.Actions>
          <RooButton variant={'secondary'} onClick={toggle}>
            Close
          </RooButton>
        </RooDialog.Actions>
      </RooDialog>
    </>
  );
};
const StyledTableCell = styled(TableCell)({
  padding: '0.6em 0.5em !important'
});

const WalkthrooPriceList = () => {
  return (
    <Stack>
      <Alert color={'warning'}>
        TIME AND MATERIALS IS THE BEST WAY TO CHECK A PRICE (PLUS GOOGLE :). TIME/LABOR RATES USUALLY AROUND $50-$60
        HOUR
      </Alert>
      <Table size={'small'}>
        <TableHead>
          <TableRow>
            <StyledTableCell>Service</StyledTableCell>
            <StyledTableCell width={'180px'}>WT Pays</StyledTableCell>
            <StyledTableCell width={'180px'}>WT Bills</StyledTableCell>
            <StyledTableCell width={'250px'}>Typical Material Cost</StyledTableCell>
            <StyledTableCell>Notes</StyledTableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          <TitleRow name={'Flooring'} />

          <EntryRow cells={['Carpet', '1-1.50/sq-ft', '1.75-2.50/sq-ft', '1', 'should include demo/haul off']} />
          <EntryRow cells={['LVP', '2-2.50/sq-ft', '3-5.50/sq-ft', '3-4', 'should include demo/haul off']} />
          <EmptyRow />

          <SubTitleRow name={'Paint'} />
          <EntryRow emphasizeFirst cells={['Interior', '', '', 'NA', 'labor/materials']} />
          <EntryRow cells={['Walls Only/Touch Up', '1.00-1.50-/sq-ft', '1.75-2.00/sq-ft', 'NA', 'labor/materials']} />
          <EntryRow cells={['Walls, Doors,Trim', '1.75 - 2.75/sqft', '2.00-3.25 sq/ft', 'NA', 'labor/materials']} />
          <EntryRow cells={['Ceilings Only', '50-.75-/sq-ft', '', 'NA', 'labor/materials']} />
          <EntryRow cells={['Cabinets', 'Lump', '', 'NA', 'labor/materials']} />
          <EmptyRow />
          <EntryRow emphasizeFirst cells={['Exterior', '2-3/sq ft', '2.25-4 sq ft', 'NA', 'labor/materials']} />
          <EmptyRow />

          <TitleRow name={'Plumbing'} />
          <EntryRow
            cells={['Toilet', '225-275', '275-325', '100-125', 'should include demo/haul off; billl extra 50 ok']}
          />
          <EntryRow cells={['Disposal', '200-225', '250-300', '135', '']} />
          <EntryRow cells={['Angle Stop/CutOff Valve Replacement', '125-175', '150-200', '25', '']} />
          <EntryRow cells={['Shower Stem', '100-200', '100-200', '25', 'depends on how accessible the stem is']} />
          <EmptyRow />

          <TitleRow name={'Electrical'} />
          <EntryRow cells={['Light Fixture', '35-50', '55-125', '30-100', '']} />
          <EntryRow cells={['Overhead Fan', '200-300', '200-350', '75-125', 'does it have lights']} />
          <EntryRow cells={['Exhaust Fan', '35-100', '50-150', '30-50', '']} />
          <EmptyRow />

          <TitleRow name={'Clean'} />
          <EntryRow cells={['Deep Clean', '250-400', '375-500', '', 'make ready clean']} />
          <EntryRow
            cells={['Carpet Clean', '$60/room', '$80/room', '', 'ask vendor what type of machine they are using']}
          />
          <EmptyRow />

          <TitleRow name={'Handyman'} />
          <EntryRow cells={['Blinds - Vinyl', '15-25', '35-50', '15-50', '']} />
          <EntryRow cells={['Blinds - 2in Faux Wood', '$50-100 ea', '$60-$120 ea', '40-80', '']} />
          <EntryRow cells={['Bulbs', '2 or lump', '3 or lump', '3-5', 'keep this low! takes 30 seconds']} />
          <EntryRow cells={['Globes', '25-75', '35-100', '20-30', '']} />
          <EntryRow
            cells={[
              'Bifold doors',
              '200-500',
              '200-600',
              '99',
              '6 panel at HD is 99, usually need 2. include haul off'
            ]}
          />
          <EntryRow cells={['Interior doors', '100-250', '150-350', '115', 'include haul off']} />
          <EntryRow cells={['Entrance Doors', '300-1500', '450-2000', '', 'really depends on existing situation']} />
          <EmptyRow />

          <TitleRow name={'Deck/Fencing'} />
          <EntryRow cells={['Stain', '15-25/ln ft', '20-35/ln ft', '', '']} />
          <EntryRow
            cells={[
              'Replace Fence',
              '2 or lump',
              '3 or lump',
              '3-5',
              'ASK IF NEED TO STAIN. indicate if portion of\n' +
                'fence has neighbor; how many ln ft; how many\n' +
                'panels/pickets/posts'
            ]}
          />
          <EntryRow cells={['Replace Gate', '5', '10', '20-30', '']} />
          <EntryRow cells={['Replace Deck', '', '', '', '']} />
        </TableBody>
      </Table>
    </Stack>
  );
};

const EntryRow = ({
  cells,
  emphasizeFirst
}: {
  cells: [string, string, string, string, string];
  emphasizeFirst?: boolean;
}) => {
  return (
    <TableRow>
      <StyledTableCell sx={{ fontStyle: emphasizeFirst ? 'italic' : undefined }}>{cells[0]}</StyledTableCell>
      <StyledTableCell>{cells[1]}</StyledTableCell>
      <StyledTableCell>{cells[2]}</StyledTableCell>
      <StyledTableCell>{cells[3]}</StyledTableCell>
      <StyledTableCell>{cells[4]}</StyledTableCell>
    </TableRow>
  );
};

const TitleRow = ({ name }: { name: string }) => {
  return (
    <TableRow>
      <StyledTableCell
        sx={(theme) => ({
          backgroundColor: '#b7e1cd',
          fontWeight: 'bold',
          textTransform: 'uppercase'
        })}
        colSpan={5}
      >
        {name}
      </StyledTableCell>
    </TableRow>
  );
};

const SubTitleRow = ({ name }: { name: string }) => {
  return (
    <TableRow>
      <StyledTableCell
        sx={(theme) => ({
          fontWeight: 'bold',
          textTransform: 'uppercase'
        })}
        colSpan={5}
      >
        {name}
      </StyledTableCell>
    </TableRow>
  );
};

const EmptyRow = () => {
  return (
    <TableRow>
      <StyledTableCell colSpan={5}>&nbsp;</StyledTableCell>
    </TableRow>
  );
};

const PriceList = () => {
  const { isLoading, data } = useQuery(['price-guide'], () => apiProvider.vendorClient.getPriceGuide());

  const columns = useMemo<MRT_ColumnDef<PriceGuideEntry>[]>(
    () => [
      {
        header: 'Description',
        accessorKey: 'description'
      },
      { header: 'Price', Cell: ({ cell }) => rooFmt.money(cell.getValue<number>()), size: 150 }
    ],
    []
  );

  return (
    <MaterialReactTable
      columns={columns}
      enablePagination={false}
      data={data ?? []}
      state={{ isLoading, density: 'compact' }}
      enableGlobalFilter={false}
      enableHiding={false}
      enableColumnDragging={false}
      enableFullScreenToggle={false}
      enableDensityToggle={false}
      enableTopToolbar={false}
    />
  );
};
