import { Grid, Typography } from '@mui/material';
import { BidColumnSelection } from './CompareBidsContent';
import NoteTooltip from './NoteTooltip';
import { DocumentLineItem } from '../../../../../shared/api/clients';

type TasksListProps = {
  tasks: string[];
  lines: BidColumnSelection[];
};

export function TasksList({ tasks, lines }: TasksListProps) {
  return (
    <Grid item container direction="column">
      <Grid
        item
        container
        height={'calc(50px + 1rem)'}
        alignItems={'center'}
        sx={{
          borderBottomLeftRadius: '10px',
          borderBottomRightRadius: '10px'
        }}
      >
        <Grid item container sm={12}>
          <Grid item sm={12} pl={4}>
            <Typography fontWeight={'600'}>Line</Typography>
          </Grid>
        </Grid>
      </Grid>
      {tasks.map((task, index) => {
        const line = lines.find((line) => line.line.description === task)?.line;

        return <TaskItem key={task} line={line} task={task} amount={line?.value ?? 0} index={index} />;
      })}
      <Grid
        item
        container
        height={'calc(50px + 1rem)'}
        justifyContent={'flex-end'}
        alignItems={'center'}
        sx={{
          backgroundColor: '#303030',
          color: 'white',
          borderBottomLeftRadius: '10px',
          borderBottomRightRadius: '10px'
        }}
      ></Grid>
    </Grid>
  );
}

type TaskItemProps = {
  index: number;
  task: string;
  amount: number;
  line?: DocumentLineItem;
};

function TaskItem({ index, task, amount, line }: TaskItemProps) {
  return (
    <Grid
      id={`task-${index}`}
      key={task}
      item
      container
      minHeight={'calc(100px + 1rem)'}
      alignItems={'center'}
      p={'1rem'}
      sx={{ backgroundColor: index % 2 === 0 ? '#f5f5f5' : 'white' }}
    >
      <Grid item container alignItems={'center'} sm={6}>
        <Typography px={'1rem'}>
          <Typography component={'span'}>{index + 1}. </Typography>
          {task}
          <NoteTooltip notes={line?.notes} />
        </Typography>
      </Grid>
    </Grid>
  );
}
