import React, { HTMLAttributes, ReactNode } from 'react';
import { ReqStar } from './ReqStar';
import { cls } from '../../shared/utils';

export const RooFormLabel = ({
  label,
  name,
  required,
  className,
  ...rest
}: { label: ReactNode; name: string; required: boolean } & HTMLAttributes<HTMLLabelElement>) => {
  if (label == null) {
    return null;
  }

  const content = required ? (
    <>
      {label} <ReqStar />
    </>
  ) : (
    label
  );

  return (
    <label htmlFor={name} className={cls('form-label', className)} {...rest}>
      {content}
    </label>
  );
};
