import { useState } from 'react';
import { apiProvider } from 'shared/api/apiProvider';
import { DeleteBoardPostPayload } from 'shared/api/clients';
import { ConfirmationModal, RooButton } from 'components';
import { showSuccess } from 'shared/utils';
import { useCurrentIssue } from 'pages/Workorders/shared/CurrentIssueContext';

export const DeleteMessageButton = ({ postId }: { postId: string }) => {
  const { onIssueUpdate } = useCurrentIssue();
  const [isSaving, setIsSaving] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);

  const save = async () => {
    setIsSaving(true);
    try {
      const result = await apiProvider.issues.main.deleteBoardPost(
        new DeleteBoardPostPayload({
          postId: postId
        })
      );
      onIssueUpdate(result);
      showSuccess();
    } catch (e) {}

    setIsSaving(false);
    setIsConfirming(false);
  };

  return (
    <>
      <RooButton onClick={() => setIsConfirming(true)} size={'sm'} variant={'danger'} icon={'trash'} />
      <ConfirmationModal
        visible={isConfirming}
        running={isSaving}
        title={'Confirm Post Deletion'}
        question={<>Are you sure you want to delete this post?</>}
        onClose={() => setIsConfirming(false)}
        onSave={() => {
          void save();
        }}
      />
    </>
  );
};
