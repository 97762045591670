import { Box, Button, CircularProgress, ThemeProvider, Typography } from '@mui/material';
import React, { useState } from 'react';
import { apiProvider } from 'shared/api/apiProvider';
import { ResetPasswordPayload } from 'shared/api/clients';
import { FieldMuiText, useRooForm } from 'components/form';
import { Routes } from 'shared/routing';
import theme, { darkTheme } from 'shared/theme';
import { RouterLink } from 'components';
import { useQueryParams } from 'shared/utils';
import { z } from 'zod';
import { AuthPage } from 'pages/Auth/AuthPage';

const FormSchema = z
  .object({
    email: z.string().email(),
    password: z.string(),
    confirmPassword: z.string()
  })
  .superRefine((val, ctx) => {
    if (val.password == null) {
      ctx.addIssue({
        message: 'Required',
        code: 'custom',
        path: ['changePassword', 'newPassword']
      });
    }

    if (val.confirmPassword == null) {
      ctx.addIssue({
        message: 'Required',
        code: 'custom',
        path: ['changePassword', 'confirmPassword']
      });
    }

    if (val.password == null && val.confirmPassword == null) {
      return;
    }

    if (val.password === val.confirmPassword) {
      return;
    }

    ctx.addIssue({
      message: "Passwords don't match",
      code: 'custom',
      path: ['confirmPassword']
    });
  });

type FormDefinition = z.infer<typeof FormSchema>;

export const ResetPassword = () => {
  const [state, setState] = useState<'form' | 'confirmation'>('form');
  const showConfirm = () => setState('confirmation');
  return (
    <AuthPage title={'Reset Password - Walkthroo'}>
      <Typography variant="h5" component="div" gutterBottom sx={{ fontWeight: 700 }}>
        Reset Password
      </Typography>
      {state === 'form' && <FormView onConfirmation={showConfirm} />}
      {state === 'confirmation' && <ConfirmationView />}
    </AuthPage>
  );
};

const FormView = ({ onConfirmation }: { onConfirmation: () => void }) => {
  const [state, setState] = useState<'idle' | 'loading' | 'error'>('idle');
  const { userId, token } = useQueryParams<{ userId: string; token: string }>();
  const { control, handleSubmit } = useRooForm(FormSchema);

  const resetPassword = async (values: FormDefinition) => {
    setState('loading');
    try {
      await apiProvider.authClient.resetPassword(
        new ResetPasswordPayload({
          email: values.email,
          newPassword: values.password,
          token: token,
          userId: userId
        })
      );
      onConfirmation();
    } catch (e) {
      setState('error');
    }
  };

  return (
    <form noValidate onSubmit={handleSubmit(resetPassword)}>
      <ThemeProvider theme={darkTheme}>
        <FieldMuiText
          control={control}
          name={'email'}
          label={'E-mail'}
          className={'Input-dark'}
          margin={'normal'}
          fullWidth
          required
        />
        <FieldMuiText
          control={control}
          name={'password'}
          className={'Input-dark'}
          label={'New Password'}
          margin={'normal'}
          fullWidth
          required
        />
        <FieldMuiText
          control={control}
          name={'confirmPassword'}
          className={'Input-dark'}
          label={'Confirm Password'}
          margin={'normal'}
          fullWidth
          required
        />
        <Button size={'largest'} type="submit" fullWidth sx={{ mt: 2, mb: 2 }} onClick={handleSubmit(resetPassword)}>
          {state === 'loading' ? <CircularProgress style={{ width: 31, height: 31 }} color="inherit" /> : 'Log In'}
        </Button>
        {state === 'error' && (
          <div className="alert alert-danger mt-2" role="alert">
            Could not reset your password.
          </div>
        )}
      </ThemeProvider>
    </form>
  );
};
const ConfirmationView = () => {
  return (
    <>
      <Box sx={{ textAlign: 'center' }}>
        <Typography variant="subtitle1" gutterBottom>
          Your password has been changed.{' '}
          <RouterLink to={Routes.LogIn} style={{ color: theme.palette.primary.main }}>
            Log In
          </RouterLink>
        </Typography>
      </Box>
    </>
  );
};
