import { ConfirmationModal, RooButton } from 'components';
import { useCurrentIssue } from 'pages/Workorders/shared/CurrentIssueContext';
import { useState } from 'react';
import { Badge, Card } from 'react-bootstrap';
import { apiProvider } from 'shared/api/apiProvider';
import { IssueRole, Payment, PaymentStatus } from 'shared/api/clients';
import { PaymentModal } from './PaymentModal';
import { DataTable } from 'primereact/datatable';
import { rooFmt } from 'shared/utils';
import { Column } from 'primereact/column';
import { Box, Stack, Typography } from '@mui/material';
import { rooEnum, useIsGeneralContractor } from 'shared/store';
import { sumBy } from 'lodash';

export const Payments = () => {
  const { onIssueUpdate, payments, userRole } = useCurrentIssue();
  const [state, setState] = useState<{ isLoading?: boolean; isDeleting?: boolean }>({
    isLoading: false,
    isDeleting: false
  });
  const [addModalVisible, setAddModalVisible] = useState<boolean>(false);
  const [selectedPayment, setSelectedPayment] = useState<Payment>();
  const [isConfirming, setIsConfirming] = useState<boolean>(false);
  const isGeneralContractor = useIsGeneralContractor();
  // normally only managers should handle payments,
  // but in our case GCs can mark invoices as paid through the reporting section,
  // so they should be able to change them as well
  const isManager =
    userRole === IssueRole.MainPropertyManager ||
    userRole === IssueRole.AltPropertyManager ||
    (userRole === IssueRole.Vendor && isGeneralContractor);

  const onDelete = async () => {
    setState({ ...state, isDeleting: true });
    var result = await apiProvider.paymentsClient.deletePayment(selectedPayment.id);
    setState({ ...state, isDeleting: false });
    setIsConfirming(false);
    onIssueUpdate(result);
    setSelectedPayment(null);
  };

  const processedPayments = payments.filter(
    (x) => x.status === PaymentStatus.Paid || x.status === PaymentStatus.PaymentSent
  );

  return (
    <Card>
      <Card.Header>
        Payments
        {isManager && (
          <RooButton
            color={'primary'}
            size={'sm'}
            className={'float-end slim'}
            icon={'plus'}
            onClick={() => {
              setSelectedPayment(null);
              setAddModalVisible(true);
            }}
          >
            Add
          </RooButton>
        )}
        <PaymentModal
          visible={addModalVisible}
          onCloseRequest={() => setAddModalVisible(false)}
          payment={selectedPayment}
          title={selectedPayment?.id ? 'Edit Payment' : 'Add Payment'}
        />
      </Card.Header>
      <Card.Body>
        <DataTable value={payments} loading={state.isLoading}>
          <Column field={'date'} header={'Date'} body={(row: Payment) => rooFmt.instantDateMaybe(row.date, '-')} />
          <Column field={'amount'} header={'Amount'} body={(row: Payment) => rooFmt.moneyMaybe(row.amount, '-')} />
          <Column header={'Unallocated'} body={(row: Payment) => rooFmt.moneyMaybe(row.amount - row.allocated, '-')} />
          <Column field={'notes'} header={'Notes'} body={(row: Payment) => row.notes} />
          <Column
            field={'status'}
            header={'Status'}
            body={(row: Payment) => (
              <Badge bg={rooEnum.paymentStatus.color(row.status)}>{rooEnum.paymentStatus.display(row.status)}</Badge>
            )}
          />
          <Column
            hidden={!isManager}
            body={(payment: Payment) => {
              return (
                <Box display="flex" justifyContent="flex-end" gap={2}>
                  {payment.paymentSource !== 6 && (
                    <RooButton
                      variant={'success'}
                      icon={'pencil-alt'}
                      onClick={() => {
                        setSelectedPayment(payment);
                        setAddModalVisible(true);
                      }}
                    ></RooButton>
                  )}
                  {payment.paymentSource !== 4 && payment.paymentSource !== 6 && (
                    <RooButton
                      variant={'danger'}
                      icon={'times'}
                      onClick={() => {
                        setSelectedPayment(payment);
                        setIsConfirming(true);
                      }}
                    ></RooButton>
                  )}
                </Box>
              );
            }}
          />
        </DataTable>
        {processedPayments.length > 0 &&
          (() => {
            const totalAmt = sumBy(processedPayments, (x) => x.amount);
            const totalUnallocated = sumBy(processedPayments, (x) => x.amount - x.allocated);
            return (
              <Stack sx={{ mt: 2 }} direction={'row'} justifyContent={'flex-end'}>
                <Stack>
                  <Typography>Total Paid: {rooFmt.moneyMaybe(totalAmt, '-')}</Typography>
                  <Typography>Unallocated: {rooFmt.moneyMaybe(totalUnallocated, '-')}</Typography>
                </Stack>
              </Stack>
            );
          })()}

        <ConfirmationModal
          visible={isConfirming}
          onClose={() => {
            setIsConfirming(false);
          }}
          onSave={() => {
            onDelete();
          }}
          question={`Are you sure you want to delete this payment?`}
          running={state.isDeleting}
        />
      </Card.Body>
    </Card>
  );
};
