import { apiProvider } from 'shared/api/apiProvider';
import {
  AddIssueMessagePayload,
  AddMessageRecipient,
  ChatParticipant,
  FindLatestMessagesPayload,
  IAttachmentFile,
  IssueSummary,
  MarkMessagesReadPayload,
  Membership,
  MessageList,
  PropertySummary,
  Receipt,
  UserDetails,
  UserSummary,
  WorkorderSummary
} from 'shared/api/clients';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { makeUuid } from '@roo/lib';

export type AppMessage = {
  id: string;
  seqId: number;
  clientId: string;
  textContent: string;
  sentByMe: boolean;
  isRead: boolean;
  state: 'sending' | 'idle';
  from: UserSummary;
  to: UserSummary;
  issue: Partial<IssueSummary>;
  workorder: WorkorderSummary;
  property: PropertySummary;
  sentAt: Date;
  files: IAttachmentFile[];
};

const mapClientMessages = (currentUserId: string, messageList: MessageList) => {
  const messages = messageList.messages.map((msg) => {
    const from = messageList.participants.find((x) => x.userSummary.id === msg.fromId)?.userSummary;
    const to = messageList.participants.find((x) => x.userSummary.id === msg.toId)?.userSummary;
    const issue = messageList.issues.find((x) => x.id === msg.issueId);
    const workorder = messageList.workorders.find((x) => x.id === msg.workorderId);
    const property = messageList.properties.find((x) => x.id === msg.propertyId);

    return {
      id: msg.id,
      clientId: msg.clientTempId ?? makeUuid(),
      from,
      to,
      issue,
      workorder,
      property,
      isRead: msg.read,
      sentByMe: from.id === currentUserId,
      seqId: msg.seqId,
      state: 'idle',
      textContent: msg.body,
      sentAt: msg.sentAt,
      files: msg.files
    } as AppMessage;
  });

  return messages;
};

const updateCollection = <T>(
  colSource: Array<T>,
  colDest: Array<T>,
  predicateBuilder: (source: T) => (dest: T) => boolean
) => {
  for (const src of colSource) {
    const existingIdx = colDest.findIndex(predicateBuilder(src));
    if (existingIdx > -1) {
      colDest[existingIdx] = src;
    } else {
      colDest.push(src);
    }
  }
};

type ChatState = {
  dataLoaded: boolean;
  isLoading: boolean;
  isRefreshing: boolean;
  isActing: boolean;
  currentUser: UserSummary;
  lastMessageSeqId: number;
  lastReceiptSeqId: number;
  messages: AppMessage[];
  participants: ChatParticipant[];
  issues: IssueSummary[];
  workorders: WorkorderSummary[];
  properties: PropertySummary[];
  unreadCount: number;
};

const applyReceipts = (curr: ChatState, receipts: Receipt[]) => {
  if (receipts == null) {
    return;
  }

  for (const receipt of receipts) {
    const message = curr.messages.find((x) => x.id === receipt.messageId);
    if (message != null) {
      message.isRead = true;
    }

    curr.lastReceiptSeqId = Math.max(receipt.seqId, curr.lastReceiptSeqId);
  }
};

const applyStateDiff = (curr: ChatState, diff: MessageList) => {
  const messages = mapClientMessages(curr.currentUser.id, diff);
  updateCollection(
    messages,
    curr.messages,
    (src) => (dest) =>
      (dest.id == null && src.clientId != null && src.clientId === dest.clientId) ||
      (dest.id != null && src.id === dest.id)
  );
  updateCollection(diff.issues, curr.issues, (src) => (dest) => dest.id === src.id);
  updateCollection(diff.workorders, curr.workorders, (src) => (dest) => dest.id === src.id);
  updateCollection(diff.properties, curr.properties, (src) => (dest) => dest.id === src.id);
  updateCollection(diff.participants, curr.participants, (src) => (dest) => dest.userSummary.id === src.userSummary.id);
  curr.lastMessageSeqId = curr.messages
    .filter((x) => x.id != null)
    .map((x) => x.seqId)
    .reduce((a, b) => Math.max(a, b), 0);
  applyReceipts(curr, diff.receipts);
  countUnread(curr);
};

const countUnread = (curr: ChatState) => {
  curr.unreadCount = curr.messages.filter((x) => !x.isRead && !x.sentByMe).length;
};

const refreshStore = async (get: () => ChatState, set: (fn: (draft: ChatState) => void) => void) => {
  const current = get();
  if (current.isRefreshing || current.isActing) {
    return;
  }

  set((curr) => {
    curr.isRefreshing = true;
  });
  try {
    const messageResponse = await apiProvider.messagesClient.getMessages(
      new FindLatestMessagesPayload({
        lastMessageSeqId: current.lastMessageSeqId,
        messageIds: null,
        lastReceiptSeqId: current.lastReceiptSeqId
      })
    );

    set((curr) => {
      applyStateDiff(curr, messageResponse);
      curr.isRefreshing = false;
    });
  } catch (e) {
    set((curr) => {
      curr.isRefreshing = false;
    });
    console.error(e);
  }
};

type ChatActions = {
  initialize: (currentUser: UserDetails) => Promise<void>;
  refreshStore: () => Promise<void>;
  addMessage: (
    textContent: string,
    recipients: UserSummary[],
    issueId: string,
    file: IAttachmentFile | null
  ) => Promise<void>;
  markAsRead: (messageIds: string[]) => Promise<void>;
};

export const useChatStore = create<ChatState & ChatActions>()(
  immer((set, get) => ({
    dataLoaded: false,
    isLoading: true,
    isActing: false,
    isRefreshing: false,
    currentUser: null,
    participants: [],
    lastMessageSeqId: 0,
    lastReceiptSeqId: null,
    messages: [],
    issues: [],
    workorders: [],
    properties: [],
    unreadCount: 0,
    initialize: async (currentUser: UserDetails) => {
      const current = get();
      if (current.dataLoaded) {
        return;
      }

      if (currentUser == null) {
        return;
      }

      try {
        const messageResponse = await apiProvider.messagesClient.getMessages(
          new FindLatestMessagesPayload({
            lastMessageSeqId: null,
            messageIds: null,
            lastReceiptSeqId: null
          })
        );

        const messages = mapClientMessages(currentUser.user.id, messageResponse);
        set((curr) => {
          curr.dataLoaded = true;
          curr.isLoading = false;
          curr.messages = messages;
          curr.participants = messageResponse.participants;
          curr.issues = messageResponse.issues;
          curr.properties = messageResponse.properties;
          curr.workorders = messageResponse.workorders;
          curr.currentUser = currentUser.user;
          curr.lastMessageSeqId = messageResponse.lastMessageSeqId ?? 0;
          curr.lastReceiptSeqId = messageResponse.lastReceiptSeqId;

          countUnread(curr);
        });

        setInterval(async () => refreshStore(get, set), 30 * 1000);
      } catch (e) {
        console.error(e);
      }
    },
    refreshStore: async () => {
      await refreshStore(get, set);
    },
    addMessage: async (
      textContent: string,
      recipients: UserSummary[],
      issueId: string,
      file: IAttachmentFile | null
    ) => {
      try {
        const recipientLinks: AddMessageRecipient[] = [];
        const beforeSend = get();
        let maxSeq = beforeSend.lastMessageSeqId;
        const tempMessages: AppMessage[] = [];
        for (const recipient of recipients) {
          const tempMessage: AppMessage = {
            id: null,
            seqId: ++maxSeq,
            clientId: makeUuid(),
            isRead: true,
            textContent: textContent,
            state: 'sending',
            issue: { id: issueId },
            workorder: null,
            property: null,
            sentByMe: true,
            from: beforeSend.currentUser,
            to: recipient,
            sentAt: new Date(),
            files: file == null ? [] : [file]
          };

          tempMessages.push(tempMessage);
          recipientLinks.push(
            new AddMessageRecipient({
              userId: recipient.id,
              clientMessageId: tempMessage.clientId
            })
          );
        }

        set((curr) => {
          curr.isActing = true;
          tempMessages.forEach((x) => curr.messages.push(x));
        });

        const addResults = await apiProvider.messagesClient.addMessage(
          new AddIssueMessagePayload({
            messageBody: textContent,
            issueId: issueId,
            recipients: recipientLinks,
            fileIds: file == null ? [] : [file.id]
          })
        );

        set((curr) => {
          applyStateDiff(curr, addResults);
          curr.isActing = false;
        });
      } catch (e) {
        set((curr) => {
          curr.isActing = false;
        });
      }
    },
    markAsRead: async (messageIds: string[]) => {
      try {
        set((curr) => {
          curr.isActing = true;
        });
        const current = get();
        const response = await apiProvider.messagesClient.markAsRead(
          new MarkMessagesReadPayload({
            messageIds: messageIds,
            lastReceiptId: current.lastReceiptSeqId
          })
        );
        set((curr) => {
          applyReceipts(curr, response);
          countUnread(curr);
          curr.isActing = false;
        });
      } catch (e) {
        set((curr) => {
          curr.isActing = false;
        });
      }
    }
  }))
);
