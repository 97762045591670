import { useState } from 'react';
import { apiProvider } from 'shared/api/apiProvider';
import { BidStatus, RequestBidsPayload } from 'shared/api/clients';
import { LockedVendor } from 'components/VendorSelector/VendorSelector';
import { VendorSelectorModal } from 'components/VendorSelector/VendorSelectorModal';
import { RooButton } from 'components';
import { rooFmt } from 'shared/utils';
import { useCurrentIssue } from 'pages/Workorders/shared/CurrentIssueContext';

export const RequestBidsButton = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  return (
    <>
      <RooButton onClick={() => setIsModalVisible(true)} variant={'primary'} icon={'gavel'}>
        Request Vendor Bids
      </RooButton>
      <RequestBidsModal setIsModalVisible={setIsModalVisible} isVisible={isModalVisible} />
    </>
  );
};

export const RequestBidsModal = ({
  isVisible,
  setIsModalVisible
}: {
  isVisible: boolean;
  setIsModalVisible: (requesting: boolean) => void;
}) => {
  const { issue, visibleBids, onIssueUpdate, property } = useCurrentIssue();
  const locked: LockedVendor[] =
    visibleBids
      .map((x) => x.bid)
      .filter((x) => x.status !== BidStatus.Uninvited)
      .map((x) => ({
        color: x.status === BidStatus.Declined ? 'error' : 'success',
        reason: x.status === BidStatus.Declined ? 'Declined' : 'Invited',
        id: x.vendor.id
      })) ?? [];

  const save = async (selectedIds: string[], notes: string) => {
    if (selectedIds.length === 0) {
      return;
    }

    const updated = await apiProvider.issues.bids.requestBids(
      new RequestBidsPayload({
        issueId: issue.id,
        vendorIds: selectedIds,
        notes
      })
    );

    onIssueUpdate(updated);
  };

  return (
    <VendorSelectorModal
      title={'Request Bids'}
      saveButtonFormatter={(ids, isSaving) => {
        if (isSaving) {
          return 'Sending requests...';
        }
        if (ids.length === 0) {
          return 'No vendors selected';
        }

        return `Request ${rooFmt.counted(ids.length, 'bid', 'bids')}`;
      }}
      visible={isVisible}
      onCloseRequest={() => setIsModalVisible(false)}
      selectionMode={'multiple'}
      lockedVendors={locked}
      referenceAddress={property.address}
      save={save}
    />
  );
};
