const fallbackCopyTextToClipboard = (text: string) => {
  const textArea = document.createElement('textarea');
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    const successful = document.execCommand('copy');
    if (!successful) {
      console.error('Failed copying through fallback');
    }
  } catch (err) {
    console.error('Error while copying through fallback', err);
  }

  document.body.removeChild(textArea);
};

const copyText = async (text: string) => {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }

  try {
    await navigator.clipboard.writeText(text);
  } catch (err) {
    fallbackCopyTextToClipboard(text);
    console.error('Failed native copy', err);
  }
};

export const AppClipboard = {
  copyText: copyText
};
