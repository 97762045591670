import { Box } from '@mui/material';
import React, { useState } from 'react';
import { Card } from 'react-bootstrap';
import { NoteEntityType } from 'shared/api/clients';
import { LoaderSmall, RooButton } from 'components';
import { AddNoteModal } from './AddNoteModal';
import { useNotesQuery } from 'shared/api/queries';
import { InternalNoteList } from '../../../components/Notes/InternalNote';

export const InternalNotesCard = ({ entityType, entityId }: { entityType: NoteEntityType; entityId: string }) => {
  const { isLoading, data } = useNotesQuery({
    entityId: entityId,
    entityType: entityType
  });
  const [openNoteModal, setOpenNoteModal] = useState(false);

  return (
    <>
      <Card>
        <Card.Header>
          <Box display="flex" justifyContent="space-between">
            Internal Notes
            <RooButton size={'sm'} onClick={() => setOpenNoteModal(true)} className={'slim'} icon={'pencil-alt'}>
              Add
            </RooButton>
          </Box>
        </Card.Header>
        <Card.Body>
          {isLoading && <LoaderSmall />}
          {!isLoading && data.length === 0 && (
            <p style={{ textAlign: 'center', marginBottom: 0 }}>
              There are no notes yet. Internal Notes are only visible to you and your coworkers.
            </p>
          )}
          {!isLoading && data.length > 0 && <InternalNoteList notes={data} />}
        </Card.Body>
      </Card>
      <AddNoteModal
        entityId={entityId}
        entityType={entityType}
        visible={openNoteModal}
        onCloseRequest={() => setOpenNoteModal(false)}
      />
    </>
  );
};
