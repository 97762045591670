import { Stack } from '@mui/material';
import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Estimate, IEstimate } from 'shared/api/clients';
import { downloadFiles } from 'components/Files/utils';
import { RooIcon } from 'shared/icons';
import { RooButton, RooDialog } from 'components';
import { rooFmt } from 'shared/utils';
import { LineTable } from 'pages/Workorders/ViewIssue/Documents/LineTable';
import { flatMap } from 'lodash';
import { useCurrentVendor } from '../../../../../shared/store';
import { DocumentViewTotals } from '../DocumentViewTotals';
import { useToggle } from '@roo/lib';

export const EstimateInfoButton = ({ estimate }: { estimate: IEstimate }) => {
  const [show, toggleShow] = useToggle();
  const allFiles = flatMap(estimate.lines, (x) => x.beforeFiles);
  return (
    <>
      <RooButton icon={'eye'} variant={'primary'} onClick={toggleShow} />
      <RooDialog open={show} onClose={toggleShow} maxWidth={'md'} fullWidth>
        <RooDialog.Title onClose={toggleShow}>Estimate Info</RooDialog.Title>
        <RooDialog.Content>
          <Row>
            <Col xl={12}>
              <EstimateInfo estimate={estimate} />
            </Col>
          </Row>
        </RooDialog.Content>
        <RooDialog.Actions>
          <Stack direction={'row'} spacing={2}>
            {estimate.pdfUrls != null && (
              <a href={estimate.pdfUrls.downloadUrl} className={'btn btn-primary'} rel="noreferrer">
                <RooIcon icon={'file-pdf'} /> Download PDF
              </a>
            )}
            {allFiles.length > 0 && (
              <Button onClick={() => downloadFiles(allFiles)}>
                Download {rooFmt.counted(allFiles.length, 'picture', 'pictures')}
              </Button>
            )}
            <Button variant={'outline-primary'} onClick={toggleShow}>
              Close
            </Button>
          </Stack>
        </RooDialog.Actions>
      </RooDialog>
    </>
  );
};

export const EstimateInfo = ({ estimate }: { estimate: IEstimate }) => {
  const currVendor = useCurrentVendor();
  return (
    <>
      {!!estimate.reviewerNotes && (
        <Row>
          <Col>
            <dl>
              <dt>Reviewer Notes</dt>
              <dd>{estimate.reviewerNotes}</dd>
            </dl>
          </Col>
        </Row>
      )}
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 1, md: 4 }}>
        <dl style={{ marginBottom: 0 }}>
          <dt>Number</dt>
          <dd>{estimate.friendlyId ?? '-'}</dd>
        </dl>
        <dl style={{ marginBottom: 0 }}>
          <dt>Submitted</dt>
          <dd>{rooFmt.instantDateMaybe(estimate.referenceDate, '-')}</dd>
        </dl>
        <dl style={{ marginBottom: 0 }}>
          <dt>Vendor</dt>
          <dd>{estimate.vendor.companyName}</dd>
        </dl>
        <dl>
          <dt>Start Date</dt>
          <dd>{rooFmt.instantDateMaybe(estimate.startDate, '-')}</dd>
        </dl>
        <dl>
          <dt>End Date</dt>
          <dd>{rooFmt.instantDateMaybe(estimate.endDate, '-')}</dd>
        </dl>
      </Stack>
      <Row>
        <Col>
          <LineTable lines={estimate.lines} />
        </Col>
      </Row>

      <div
        style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', flexGrow: 0, marginTop: '30px' }}
      >
        <DocumentViewTotals totals={estimate.totals} isAuthor={currVendor?.id === estimate.vendor.id} />
      </div>
    </>
  );
};
