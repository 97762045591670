import { ContentWrapper, LoaderSmall, NavSlim, RooButton } from 'components';
import React from 'react';
import { Helmet } from 'react-helmet';
import { apiProvider } from 'shared/api/apiProvider';
import { PaymentsTable } from './PaymentsTable';
import { PaymentsNav } from './PaymentsNav';
import { CompanyType, ExportAccountsReceivablePayload, PaymentGroupEntry } from '../../shared/api/clients';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useAppStore } from '../../shared/store';
import { downloadFiles } from '../../components/Files/utils';
import { Stack } from '@mui/material';

export const AccountsReceivable = () => {
  const { isLoading, data } = useQuery(['reporting', 'accountsReceivable'], () =>
    apiProvider.issues.reports.listForAccountsReceivable()
  );
  const hideReports = useAppStore((x) => x.hideReports);

  return (
    <>
      <Helmet>
        <title>Accounts Receivable - Walkthroo</title>
      </Helmet>
      <NavSlim breadcrumbs={[{ text: 'Accounts Receivable' }]} />
      <ContentWrapper className="container-property-list no-transparent">
        <PaymentsNav value={0} />
        {isLoading && (
          <div className={'text-center p-4'}>
            <LoaderSmall />
          </div>
        )}
        {!isLoading && !hideReports && (
          <PaymentsTable
            data={data}
            CompanyNameExtras={DownloadPdfButton}
            companyType={CompanyType.ManagementCompany}
          />
        )}
      </ContentWrapper>
    </>
  );
};

const DownloadPdfButton = ({ groupEntry }: { groupEntry: PaymentGroupEntry }) => {
  const { isLoading, mutate } = useMutation({
    mutationFn: (companyId: string) =>
      apiProvider.issues.reports.exportAccountsReceivable(
        new ExportAccountsReceivablePayload({ companyId: companyId })
      ),
    onSuccess: (data) => {
      downloadFiles([data]);
    }
  });

  return (
    <Stack alignItems={'center'} justifyContent={'center'} style={{ minWidth: '80px', width: '80px' }}>
      <RooButton
        size={'sm'}
        loading={isLoading}
        disabled={isLoading}
        onClick={() => mutate(groupEntry.companyId)}
        icon={'download'}
      >
        PDF
      </RooButton>
    </Stack>
  );
};
