import { RooButton } from 'components';
import { useRequestGlobalModal } from './modals';

export const ImpersonateButton = ({
  account,
  sendToLanding
}: {
  account: { userId: string; firstName: string; lastName: string };
  sendToLanding: boolean;
}) => {
  const [, toggle] = useRequestGlobalModal('impersonateModal', {
    props: {
      account,
      sendToLanding: sendToLanding
    }
  });
  return (
    <>
      <RooButton size={'sm'} onClick={() => toggle()}>
        Impersonate
      </RooButton>
    </>
  );
};
