import { Avatar, Box, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import oneEightLogoSidebar from 'images/1836/1836_small.png';
import rooLogoSidebar from 'images/roo-logo-sidebar.png';
import { CapabilityType, CompanyFeatureFlag, RooPermission } from 'shared/api/clients';
import { Routes } from 'shared/routing';
import {
  useAppStore,
  useCurrentUser,
  useCurrentVendor,
  useHasCapability,
  useHasFeatureFlag,
  useHasInspectionsAccess,
  useHasPermission,
  useIsAdmin,
  useIsGeneralContractor,
  useThemeName
} from 'shared/store';
import { RouterLink, WhenCapable } from 'components';
import { isDevelopment, useIsSmallScreen } from 'shared/utils';
import { Drawer, DrawerPermanent, drawerWidth, RouteListItem, Toolbar } from './shared';
import { MuiIcon } from '../shared/icons';
import { useRequestGlobalModal, useWatchGlobalModal } from '../components/modals';
import React from 'react';

export const Sidebar = () => {
  const isAdmin = useIsAdmin();
  const currentVendor = useCurrentVendor();
  const { isVisible, toggle } = useWatchGlobalModal('appSidebar');
  const [, togglePriceGuide] = useRequestGlobalModal('priceGuide');
  const [, toggleMakeReady] = useRequestGlobalModal('makeReady');
  const isMobile = useIsSmallScreen();
  const themeName = useThemeName();
  const currentUser = useCurrentUser();
  const hideReports = useAppStore((x) => x.hideReports);
  const showCommissions = useAppStore((x) => x.showCommissions);
  const hideClients = useAppStore((x) => x.hideClients);
  const isGeneralContractor = useIsGeneralContractor();
  const hasPriceGuide = useHasFeatureFlag(CompanyFeatureFlag.PriceGuide);
  const canViewProperties =
    useHasPermission(RooPermission.ViewPropertyList, currentUser.managementCompany?.id) || isGeneralContractor;
  const isManager = useHasCapability(CapabilityType.PropertyManager);
  const isDev = isDevelopment();
  const hasInspectionsAccess = useHasInspectionsAccess();

  const drawer = (
    <>
      <Toolbar>
        {isVisible && (
          <>
            {themeName === 'default' && (
              <RouterLink to={Routes.Dashboard}>
                <img src={rooLogoSidebar} height={60} alt="" />
              </RouterLink>
            )}
            {themeName === '1836' && (
              <RouterLink className={'no-underline'} to={Routes.Dashboard}>
                <Box
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingTop: '8px',
                    paddingBottom: '8px',
                    fontSize: '17px'
                  }}
                >
                  <img style={{ backgroundColor: 'white' }} src={oneEightLogoSidebar} height={60} alt="" />
                  <div style={{ marginLeft: '12px', color: 'white', textTransform: 'uppercase' }}>
                    <span style={{ fontWeight: 700 }}>Property</span> <br />
                    <span>Management</span>
                  </div>
                </Box>
              </RouterLink>
            )}
          </>
        )}
        {isVisible && <SidebarToggleButton />}
      </Toolbar>
      <Divider />
      <List sx={{ pt: 0 }}>
        <RouteListItem to={Routes.Dashboard} icon={MuiIcon.PieChart}>
          Overview
        </RouteListItem>
        {hasInspectionsAccess && (
          <RouteListItem to={Routes.InspectionsDashboard} icon={MuiIcon.ContentPasteSearch}>
            Inspections
          </RouteListItem>
        )}

        {isGeneralContractor && !hideReports && (
          <RouteListItem to={Routes.AccountsReceivable} icon={MuiIcon.AccountBalance}>
            Reports
          </RouteListItem>
        )}
        {isGeneralContractor && showCommissions && (
          <RouteListItem to={Routes.CommissionsReport} icon={MuiIcon.PointOfSale}>
            Commissions
          </RouteListItem>
        )}
        {canViewProperties && (
          <RouteListItem icon={MuiIcon.Home} to={Routes.PropertyList}>
            Properties
          </RouteListItem>
        )}

        {isGeneralContractor && !hideClients && (
          <RouteListItem icon={MuiIcon.Contacts} to={Routes.VendorClients}>
            Clients
          </RouteListItem>
        )}

        <WhenCapable capability={CapabilityType.ManagementCompanyOwner}>
          <RouteListItem icon={MuiIcon.AssignmentInd} to={Routes.ManagerList}>
            Team Members
          </RouteListItem>
        </WhenCapable>

        {(isGeneralContractor || isManager) && (
          <RouteListItem icon={MuiIcon.Handyman} to={Routes.VendorList}>
            Vendors
          </RouteListItem>
        )}
        <WhenCapable capability={CapabilityType.PropertyManager}>
          <RouteListItem icon={MuiIcon.FamilyRestroom} to={Routes.TenantList}>
            Tenants
          </RouteListItem>
        </WhenCapable>
        <WhenCapable capability={CapabilityType.PropertyManager}>
          <RouteListItem icon={MuiIcon.AssuredWorkload} to={Routes.OwnerList}>
            Owners
          </RouteListItem>
        </WhenCapable>
        {isGeneralContractor && hasPriceGuide && (
          <ListItem button onClick={togglePriceGuide}>
            <ListItemAvatar>
              <Avatar>
                <MuiIcon.AttachMoney />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={'Prices'} />
          </ListItem>
        )}
        {currentVendor != null && (
          <ListItem button onClick={toggleMakeReady}>
            <ListItemAvatar>
              <Avatar>
                <MuiIcon.PlaylistAddCheck />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={'Make Ready Checklist'} />
          </ListItem>
        )}
        {isAdmin && (
          <RouteListItem icon={MuiIcon.Handyman} to={Routes.AdminSoftwareClientList}>
            Software Clients
          </RouteListItem>
        )}
        {isDev && (
          <RouteListItem icon={MuiIcon.Handyman} to={Routes.DevLanding}>
            Dev Area
          </RouteListItem>
        )}
      </List>
    </>
  );
  return (
    <Box component="nav" aria-label="mailbox folders">
      {isMobile && (
        <Drawer
          container={() => window.document.body}
          variant="temporary"
          open={isVisible}
          onClose={toggle}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', lg: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth }
          }}
        >
          {drawer}
        </Drawer>
      )}
      {!isMobile && (
        <DrawerPermanent
          variant="permanent"
          sx={{
            height: '100vh',
            display: { xs: 'none', lg: 'block' }
          }}
          open={isVisible}
        >
          {drawer}
        </DrawerPermanent>
      )}
    </Box>
  );
};

const SidebarToggleButton = () => {
  const [, toggle] = useRequestGlobalModal('appSidebar');
  return (
    <IconButton
      onClick={toggle}
      sx={{
        position: 'absolute',
        right: '-20px',
        backgroundColor: 'white',
        boxShadow: '0 1px 1px 1px #ccc'
      }}
    >
      <MuiIcon.ChevronLeft sx={{ fontSize: 20 }} />
    </IconButton>
  );
};
