import { IAddress } from '../shared/api/clients';
import { useCurrentUser } from '../shared/store';
import { rooFmt, rooGeo } from '../shared/utils';

export const MapButton = ({ propAddress }: { propAddress: IAddress }) => {
  const user = useCurrentUser();
  const distance = rooGeo.calculateDistanceInMiles(user.address, propAddress);
  return (
    <a target={'_blank'} rel={'noreferrer'} href={rooGeo.generateGoogleMapsDirUrl(propAddress)}>
      Map it {distance != null && <span>({rooFmt.number(distance, 0)} mi.)</span>}
    </a>
  );
};
