export const LoaderSmall = () => {
  return (
    <div className={'d-flex justify-content-center'}>
      <div className="d-flex justify-content-center">
        <div className="spinner-grow text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
        <div className="spinner-grow text-secondary" role="status" />
        <div className="spinner-grow text-success" role="status" />
        <div className="spinner-grow text-danger" role="status" />
        <div className="spinner-grow text-warning" role="status" />
        <div className="spinner-grow text-info" role="status" />
        <div className="spinner-grow text-light" role="status" />
        <div className="spinner-grow text-dark" role="status" />
      </div>
    </div>
  );
};
