import { differenceInDays, format as fmt, formatDistanceToNow } from 'date-fns';
import { addMinutes, setHours, setMilliseconds, setMinutes, setSeconds } from 'date-fns/fp';
import { flow } from 'lodash';
import { ReactNode } from 'react';
import { IInstant, TimeBlock } from 'shared/api/clients';

export const keys = Object.keys as <T>(o: T) => Extract<keyof T, string>[];
const usdFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
});
const formatterCache: Record<string, Intl.NumberFormat> = {
  USD: usdFormatter
};

const formatPercent = (value: number, decimalPoints: number = 2) => {
  return `${formatNumber(value, decimalPoints)}%`;
};

const formatPercentMaybe = (value: number, placeholder: ReactNode, decimalPoints: number = 2) => {
  if (value == null || isNaN(value)) {
    return placeholder;
  }

  return formatPercent(value, decimalPoints);
};

const formatNumber = (value: number, decimalPoints: number = 2) => {
  return value.toFixed(decimalPoints);
};
const formatNumberMaybe = (value: number, placeholder: ReactNode, decimalPoints: number = 2) => {
  if (value == null || isNaN(value)) {
    return placeholder;
  }
  return value.toFixed(decimalPoints) ?? placeholder;
};

const formatMoney = (amt: number, currencyCode: string = 'USD') => {
  if (!formatterCache.hasOwnProperty(currencyCode)) {
    formatterCache[currencyCode] = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currencyCode
    });
  }

  return formatterCache[currencyCode].format(amt);
};

const formatMoneyMaybe = (amt: number, placeholder: ReactNode, currencyCode: string = 'USD') => {
  if (amt == null || !isFinite(amt)) {
    return placeholder;
  }

  return formatMoney(amt, currencyCode);
};

const adjustForUTCOffset = (date: Date, offset: number) => {
  if (date == null) {
    return null;
  }
  if (offset == null) {
    offset = 0;
  }

  const utcDate = new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );
  return addMinutes(-offset, utcDate);
};

const dateFromInstant = (instant: IInstant) => {
  if (instant == null) {
    return null;
  }

  return adjustForUTCOffset(instant.dateTime, instant.utcOffset);
};

const formatDate = (date: Date) => {
  if (date == null) {
    return null;
  }

  return fmt(date, 'M/d/yyyy');
};

const textMaybe = (text: string, placeholder: ReactNode) => {
  return !text ? placeholder : text;
};

const formatDateMaybe = (date: Date, placeholder: ReactNode) => {
  if (date == null) {
    return placeholder;
  }

  return fmt(date, 'M/d/yyyy');
};

const formatDateTime = (date: Date) => {
  if (date == null) {
    return null;
  }

  return fmt(date, 'M/d/yyyy h:mm a');
};

const formatElapsed = (date: Date) => {
  if (date == null) {
    return null;
  }

  if (differenceInDays(new Date(), date) > 1) {
    return formatDateTime(date);
  }

  return (
    formatDistanceToNow(date, { addSuffix: true })
      .replace('about ', '')
      // dumb, but this is the only case
      .replace('less than a minute ago', '1 minute ago')
      .replace('in less than a minute', '1 minute ago')
  );
};

const formatInstantDate = (instant: IInstant) => {
  if (instant == null) {
    return null;
  }

  return formatDate(adjustForUTCOffset(instant.dateTime, instant.utcOffset));
};

const formatInstantDateMaybe = (instant: IInstant, placeholder: ReactNode) => {
  const date = instant == null ? null : adjustForUTCOffset(instant.dateTime, instant.utcOffset);
  return formatDateMaybe(date, placeholder);
};

const formatInstantDateTime = (instant: IInstant) => {
  if (instant == null) {
    return null;
  }

  return formatDateTime(adjustForUTCOffset(instant.dateTime, instant.utcOffset));
};

const formatTime = (date: Date) => {
  if (date == null) {
    return null;
  }

  return fmt(date, 'h:mm a');
};

const formatTimeBlock = (timeBlock: TimeBlock) => {
  if (timeBlock == null) {
    return null;
  }

  const blankDate = flow(setHours(0), setMinutes(0), setSeconds(0), setMilliseconds(0))(new Date());
  const initial = flow(setHours(timeBlock.startHour), setMinutes(timeBlock.startMinutes))(blankDate);
  const final = addMinutes(timeBlock.durationMinutes, initial);

  return `${formatTime(initial)} to ${formatTime(final)}`;
};

const formatChatDate = (date: Date) => {
  if (date == null) {
    return null;
  }

  const fullTemplate = 'h:mm a, M/d/yyyy';
  const todayTemplate = "h:mm a,' Today'";
  const yesterdayTemplate = "h:mm a,' Yesterday'";
  const thisWeekTemplate = 'h:mm a, EEEE';
  let templateToUse = fullTemplate;

  const onlyDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
  const now = new Date();
  const todayDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  const diff = differenceInDays(todayDate, onlyDate);

  if (diff === 0) {
    templateToUse = todayTemplate;
  } else if (diff === 1) {
    templateToUse = yesterdayTemplate;
  } else if (diff < 0) {
    templateToUse = fullTemplate;
  } else if (diff < 7) {
    templateToUse = thisWeekTemplate;
  }

  return fmt(date, templateToUse);
};

const formatFullName = (person: { firstName?: string; lastName?: string }) => {
  return [person?.firstName ?? '', person?.lastName ?? ''].join(' ');
};

const formatAbbrevName = (user: { firstName?: string; lastName?: string }) => {
  const lastClean = user.lastName ?? '';
  let lastInitial = null;
  if (lastClean.length >= 1) {
    lastInitial = lastClean[0];
  }

  const firstTokens = user.firstName.split(' ').filter((x) => x.length > 0)[0];
  const normalizedFirst = normalizeName(firstTokens);
  const normalizedLast = normalizeName(lastInitial);

  return formatFullName({
    firstName: normalizedFirst,
    lastName: normalizedLast == null ? null : normalizedLast + '.'
  });
};

const normalizeName = (name: string) => {
  if (name == null) {
    return null;
  }

  const finalTokens = [];
  const tokens = name.split(' ').filter((x) => x.length > 0);
  for (const token of tokens) {
    const cleaned = token.toLowerCase();
    finalTokens.push(cleaned[0].toUpperCase() + cleaned.substring(1));
  }

  return finalTokens.join(' ');
};

const counted = (count: number, singular: string, plural: string, hideNumber = false) => {
  if (count == null) {
    count = 0;
  }

  if (hideNumber) {
    if (count === 1) {
      return singular;
    }

    return plural;
  }

  if (count === 1) {
    return `${count} ${singular}`;
  }

  return `${count} ${plural}`;
};

const chunkArray = <T>(arr: T[], n: number) => {
  const chunkLength = Math.max(arr.length / n, 1);
  const chunks = [];
  for (let i = 0; i < n; i++) {
    if (chunkLength * (i + 1) <= arr.length) {
      chunks.push(arr.slice(chunkLength * i, chunkLength * (i + 1)));
    }
  }
  return chunks;
};

export const rooFmt = {
  money: formatMoney,
  moneyMaybe: formatMoneyMaybe,
  percent: formatPercent,
  percentMaybe: formatPercentMaybe,
  instantDate: formatInstantDate,
  instantDateMaybe: formatInstantDateMaybe,
  instantDateTime: formatInstantDateTime,
  time: formatTime,
  timeBlock: formatTimeBlock,
  date: formatDate,
  dateMaybe: formatDateMaybe,
  dateTime: formatDateTime,
  elapsed: formatElapsed,
  dateFromInstant: dateFromInstant,
  chatDate: formatChatDate,
  number: formatNumber,
  numberMaybe: formatNumberMaybe,
  fullName: formatFullName,
  abbrevName: formatAbbrevName,
  chunkArray: chunkArray,
  counted,
  textMaybe
};
