import {
  Alert,
  alpha,
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  Fab,
  Grid,
  styled,
  SvgIcon,
  ThemeProvider,
  Typography
} from '@mui/material';
import { SyntheticEvent, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import html2canvas from 'html2canvas';
import { FieldMuiText, useRooForm } from 'components/form';
import { SaveButtonMui } from 'components/SaveButton';
import { apiProvider } from 'shared/api/apiProvider';
import { CreateFeedbackPayload, FeedbackMetadata, FeedbackSize } from 'shared/api/clients';
import theme from 'shared/theme';
import { ReactComponent as RooLogo } from 'images/roo-logo.svg';
import { RooDialog } from 'components/RooDialog';
import { z } from 'zod';
import { useCurrentUser } from 'shared/store';
import { rooFmt } from 'shared/utils';

export const Feedback = () => {
  const [showModal, setShowModal] = useState(false);

  return (
    <ThemeProvider theme={theme}>
      <FeedbackModal visible={showModal} onHide={() => setShowModal(false)} />
      {!showModal && <FeedbackButton onClick={() => setShowModal(true)} />}
    </ThemeProvider>
  );
};

const FeedbackButton = ({ onClick }: { onClick: (e: SyntheticEvent) => void }) => {
  return createPortal(
    <Box
      className={'exclude-screenshot'}
      position="fixed"
      zIndex="1000"
      sx={{ right: { xs: '18px', md: '32px' } }}
      bottom="14px"
    >
      <Box sx={{ position: 'relative' }}>
        <TooltipLike>Feedback</TooltipLike>
        <Fab color="primary" onClick={onClick} size="medium" title="Send feedback">
          <SvgIcon sx={{ color: 'white', marginLeft: '-6px', width: '32px', height: '32px' }}>
            <RooLogo />
          </SvgIcon>
        </Fab>
      </Box>
    </Box>,
    document.body
  );
};

const TooltipLike = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: '-28px',
  left: '-8px',
  backgroundColor: alpha(theme.palette.grey[700], 0.92),
  borderRadius: theme.shape.borderRadius,
  color: theme.palette.common.white,
  fontFamily: theme.typography.fontFamily,
  padding: '4px 6px',
  fontSize: theme.typography.pxToRem(10),
  maxWidth: 300,
  wordWrap: 'break-word',
  fontWeight: theme.typography.fontWeightMedium
}));

type ModalState = 'form' | 'success';

const FeedbackModal = ({ visible, onHide }: { visible: boolean; onHide: () => void }) => {
  const [state, setState] = useState<ModalState>('form');
  const hideHandler = () => {
    setState('form');
    onHide();
  };

  return (
    <RooDialog
      className={'exclude-screenshot'}
      BackdropProps={{ className: 'exclude-screenshot' }}
      onClose={hideHandler}
      fullWidth
      maxWidth={'sm'}
      open={visible}
    >
      <RooDialog.Title onClose={hideHandler}>We want your feedback!</RooDialog.Title>
      <RooDialog.Content>
        {visible && (
          <>
            {state === 'form' && <FeedbackForm onSuccess={() => setState('success')} />}
            {state === 'success' && <FeedbackSuccess onHide={hideHandler} />}
          </>
        )}
      </RooDialog.Content>
    </RooDialog>
  );
};

const FormSchema = z.object({
  email: z.string().email().nullish(),
  phone: z.string().nullish(),
  fullName: z.string().nullish(),
  feedback: z.string()
});

type FormDefinition = z.infer<typeof FormSchema>;

const FeedbackForm = ({ onSuccess }: { onSuccess: () => void }) => {
  const currentUser = useCurrentUser();
  const [image, setImage] = useState<string>(null);
  const [meta, setMeta] = useState<FeedbackMetadata>(null);
  const [isGathering, setIsGathering] = useState(true);

  const { handleSubmit, control } = useRooForm(FormSchema, {
    defaultValues: {
      fullName: rooFmt.fullName(currentUser),
      email: currentUser?.contactInfo?.email,
      phone: currentUser?.contactInfo?.phoneNumber
    }
  });

  useEffect(() => {
    setTimeout(() => {
      setIsGathering(true);
      html2canvas(document.body, {
        ignoreElements: (element) => element.className?.includes?.('exclude-screenshot')
      })
        .then((canvas) => setImage(canvas.toDataURL()))
        .finally(() => {
          setMeta(
            new FeedbackMetadata({
              url: window.location.href,
              referrer: window.document.referrer,
              userAgent: navigator.userAgent,
              isTouchDevice: window.matchMedia('(pointer: coarse)')?.matches ?? false,
              clientSizes: [
                new FeedbackSize({
                  key: 'screen',
                  width: window.screen.width,
                  height: window.screen.height
                }),
                new FeedbackSize({
                  key: 'scroll',
                  width: document.body.scrollWidth,
                  height: document.body.scrollHeight
                }),
                new FeedbackSize({
                  key: 'inner',
                  width: window.innerWidth,
                  height: window.innerHeight
                })
              ]
            })
          );
          setIsGathering(false);
        });
    }, 1);
  }, []);

  const onSubmit = async (data: FormDefinition) => {
    try {
      await apiProvider.feedbackClient.createFeedback(
        new CreateFeedbackPayload({
          feedbackText: data.feedback,
          fullName: data.fullName,
          contactEmail: data.email,
          contactPhone: data.phone,
          image: image,
          metadata: meta,
          isImpersonating: false
        })
      );
      onSuccess();
    } catch (e) {}
  };

  return (
    <>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Grid container spacing={[0, 0, 2]}>
            <Grid item xs={12}>
              <FieldMuiText
                control={control}
                name={'fullName'}
                label="Full Name"
                placeholder="Your First Name"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FieldMuiText
                control={control}
                name={'phone'}
                label="Phone Number"
                placeholder="Your Phone Number"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FieldMuiText control={control} name={'email'} label="Email" placeholder="Your Email" fullWidth />
            </Grid>
            <Grid item xs={12} md={12}>
              <FieldMuiText
                control={control}
                name={'feedback'}
                multiline
                fullWidth
                autoFocus
                label={'Your feedback'}
                required
                placeholder={'Tell us what you think.'}
                minRows={3}
              />
            </Grid>
          </Grid>

          {isGathering && (
            <Box display="flex" justifyContent="center" paddingY={'1rem'}>
              <Alert severity="warning" icon={<CircularProgress size={24} />}>
                <Typography>We're gathering some data</Typography>
              </Alert>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <SaveButtonMui control={control}>Save</SaveButtonMui>
        </DialogActions>
      </form>
    </>
  );
};

const FeedbackSuccess = ({ onHide }: { onHide: () => void }) => {
  return (
    <>
      <DialogContent>
        <Typography variant={'h5'} sx={{ textAlign: 'center' }}>
          Thank you!
        </Typography>
        <Typography sx={{ textAlign: 'center' }}>
          If you left your contact details, someone will reach out to you soon.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onHide}>Back to App</Button>
      </DialogActions>
    </>
  );
};
