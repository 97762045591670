import React, { InputHTMLAttributes, useEffect, useState } from 'react';
import { FieldValues, useController } from 'react-hook-form';
import { fieldClassNames } from 'components/form/utils';
import { ErrorMessage } from 'components/form/ErrorMessage';
import { ControlProps } from 'components/form/types';
import { FormGroup } from 'react-bootstrap';
import { FormGroupProps } from 'react-bootstrap/FormGroup';
import { RooFormLabel } from './RooFormLabel';

type BaseProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'id' | 'type' | 'name' | 'onChange' | 'value' | 'onBlur'>;
type FinalProps<TForm extends FieldValues> = ControlProps<TForm> &
  BaseProps & { onAfterChange?: () => void; groupProps?: FormGroupProps };
export type FieldNumberProps<TForm extends FieldValues> = FinalProps<TForm>;

const transform = {
  input: (value: number) => {
    return isNaN(value) || value === null ? '' : value.toString();
  },
  output: (str: string) => {
    const output = parseFloat(str);
    return isNaN(output) ? null : output;
  }
};

export const FieldNumber = <T extends FieldValues>({
  name,
  control,
  label,
  className,
  onAfterChange,
  groupProps,
  required,
  ...rest
}: FinalProps<T>) => {
  const {
    field,
    fieldState: { error }
  } = useController({
    control: control,
    name: name
  });

  const [userInput, setUserInput] = useState('');

  useEffect(() => {
    const parsedUserVal = transform.output(userInput);
    const isBadState = isNaN(parsedUserVal);
    if (parsedUserVal !== field.value && !isBadState) {
      setUserInput(transform.input(field.value));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.value]);

  return (
    <FormGroup {...(groupProps ?? {})}>
      <RooFormLabel label={label} name={name} required={required} />
      <input
        type="number"
        className={fieldClassNames(className, error)}
        step="0.01"
        value={userInput}
        onChange={(e) => {
          const textValue = e.target.value;
          setUserInput(textValue);
          // @ts-ignore
          field.onChange(transform.output(textValue));
          onAfterChange?.();
        }}
        name={name}
        id={name}
        {...rest}
      />
      <ErrorMessage error={error} />
    </FormGroup>
  );
};
