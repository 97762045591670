import { Button, Divider, Grid } from '@mui/material';
import React from 'react';
import { apiProvider } from 'shared/api/apiProvider';
import { ModifyAddressPayload, UpdateProfileContactInfoPayload, User } from 'shared/api/clients';
import { FieldMuiText, FieldSingleSelect, Required, useRooForm } from 'components/form';
import { useAppStore, useStateOptions } from 'shared/store';
import { AddressSchema } from 'shared/utils';
import { z } from 'zod';
import { ReactSelectCommonStyles } from '../../../components/RooSelect';

const ContactUserSchema = z.object({
  phone: z.string(),
  workPhone: z.string().nullish(),
  email: z.string().nullish(),
  address: AddressSchema
});
type ContactUserDefinition = z.infer<typeof ContactUserSchema>;

export const ContactInformationSection = ({ onUpdate }: { onUpdate: (updated: User) => void }) => {
  const currentUser = useAppStore((x) => x.userDetails);
  const states = useStateOptions();
  const { control, handleSubmit } = useRooForm(ContactUserSchema, {
    defaultValues: {
      phone: currentUser.contactInfo.phoneNumber,
      workPhone: currentUser.contactInfo.workPhone,
      email: currentUser.contactInfo.email,
      address: {
        address1: currentUser.address?.address1,
        address2: currentUser.address?.address2,
        city: currentUser.address?.city,
        zipCode: currentUser.address?.zipCode,
        state: currentUser.address?.state
      }
    }
  });

  const handleSaveProfile = async (values: ContactUserDefinition) => {
    try {
      const updated = await apiProvider.profileClient.updateContact(
        new UpdateProfileContactInfoPayload({
          phone: values.phone,
          workPhone: values.workPhone,
          address: new ModifyAddressPayload({
            address1: values.address.address1,
            address2: values.address.address2,
            city: values.address.city,
            zipCode: values.address.zipCode,
            state: values.address.state
          })
        })
      );
      onUpdate(updated);
    } catch (e) {}
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="flex-start"
      sx={{ mt: 0, p: 3, mb: 4 }}
      spacing={4}
    >
      <Grid item xs={12} md={6}>
        <h5 style={{ color: 'black ' }}>
          <strong>Contact</strong>
        </h5>
        <Divider />
        <FieldMuiText
          control={control}
          name={'phone'}
          label="Phone"
          placeholder={'Enter your phone'}
          fullWidth
          margin="normal"
          required
        />
        <FieldMuiText
          control={control}
          name={'workPhone'}
          label="Work Phone"
          placeholder={'Enter your Work Phone'}
          fullWidth
          margin="normal"
        />
        {Boolean(currentUser.contactInfo.email) && (
          <FieldMuiText
            control={control}
            name={'email'}
            label="Email"
            placeholder={'Enter your Email'}
            fullWidth
            margin="normal"
            required
            disabled
          />
        )}
      </Grid>
      <Grid item xs={12} md={6}>
        <h5 style={{ color: 'black ' }}>
          <strong>Address</strong>
        </h5>
        <Divider />
        <FieldMuiText
          control={control}
          name={'address.address1'}
          label="Address 1"
          fullWidth
          margin="normal"
          required
        />
        <FieldMuiText control={control} name={'address.address2'} label="Address 2" fullWidth margin="normal" />
        <FieldMuiText control={control} name={'address.city'} label="City" fullWidth margin="normal" required />
        <Grid container spacing={2}>
          <Grid item xs={12} md={8} sx={{ marginTop: 2 }}>
            {/* TODO: replace with mui */}
            <FieldSingleSelect
              control={control}
              name={'address.state'}
              className="Input"
              options={states}
              required
              placeholder={'State'}
              styles={ReactSelectCommonStyles.MuiLookAlike}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FieldMuiText control={control} name={'address.zipCode'} label="Zip" fullWidth margin="normal" required />
          </Grid>
        </Grid>
      </Grid>
      <Grid container direction="row" justifyContent="flex-end" alignItems="center">
        <Button
          type="submit"
          variant="contained"
          size={'large'}
          sx={{ mt: 2, mb: 2, width: 200 }}
          onClick={handleSubmit(handleSaveProfile)}
        >
          Save
        </Button>
      </Grid>
    </Grid>
  );
};
