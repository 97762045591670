import { RooIcon } from 'shared/icons';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { apiProvider } from 'shared/api/apiProvider';
import { redirect, Routes } from 'shared/routing';
import { useAppStore } from 'shared/store';
import { LoaderBalls, RooButton, RouterLink } from 'components';
import { useQueryParams } from 'shared/utils';
import 'pages/Auth/emailConfirmation.scss';

export const EmailConfirmation = () => {
  const user = useAppStore((x) => x.userDetails);
  const query = useQueryParams<{ token: string }>();
  if (user.emailConfirmed) {
    redirect(Routes.Dashboard);
  }

  if (query?.token != null) {
    return <ConfirmPending token={query.token} />;
  }

  return <RequestConfirmation />;
};

const ConfirmPending = ({ token }: { token: string }) => {
  const onEmailConfirmed = useAppStore((x) => x.actions.onEmailConfirmed);
  const [state, setState] = useState<'pending' | 'bad-token' | 'error'>('pending');
  useEffect(() => {
    const run = async () => {
      try {
        const result = await apiProvider.usersClient.confirmEmail(token);
        if (result) {
          onEmailConfirmed();
          redirect(Routes.Dashboard);
        } else {
          setState('bad-token');
        }
      } catch (e) {
        setState('error');
      }
    };
    void run();
    //eslint-disable-next-line
  }, []);
  return (
    <>
      <Helmet>
        <title>Confirm your e-mail - Walkthroo</title>
      </Helmet>
      <div className="container confirmation-page">
        <div>
          <div className={'text-center mb-2'}>
            <RooIcon
              size={'6x'}
              style={{ opacity: '0.7' }}
              className={state === 'pending' ? 'text-primary' : 'text-danger'}
              icon={'envelope-open-text'}
            />
          </div>
          {state === 'pending' && <h1 className={'text-center text-muted mb-4'}>Confirming your e-mail...</h1>}
          {state === 'bad-token' && <h1 className={'text-center text-muted mb-4'}>Invalid confirmation link</h1>}
          {state === 'error' && <h1 className={'text-center text-muted mb-4'}>Failed confirmation</h1>}

          {state === 'pending' && (
            <div className={'text-center'}>
              <LoaderBalls />
            </div>
          )}
          {state === 'error' && (
            <div className={'text-center'}>
              Something happened and we couldn't confirm your e-mail. Please try again later.
            </div>
          )}
          {state === 'bad-token' && (
            <div className={'text-center'}>
              The confirmation link you used was not valid or has expired. Click{' '}
              <RouterLink to={Routes.ConfirmEmail}>here</RouterLink> to create a new one.
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const RequestConfirmation = () => {
  const user = useAppStore((x) => x.userDetails);
  const [state, setState] = useState<'idle' | 'pending' | 'success'>('idle');
  const requestConfirmation = async () => {
    try {
      setState('pending');
      await apiProvider.usersClient.sendEmailConfirmationToken();
      setState('success');
    } catch (e) {
      setState('idle');
    }
  };
  return (
    <>
      <Helmet>
        <title>Confirm your e-mail - Walkthroo</title>
      </Helmet>
      <div className="container confirmation-page">
        <div>
          <div className={'text-center mb-2'}>
            <RooIcon size={'6x'} style={{ opacity: '0.7' }} className={'text-primary'} icon={'envelope-open-text'} />
          </div>
          <h1 className={'text-center text-muted mb-4'}>Confirm your e-mail</h1>
          <p className={'mb-2 text-center'}>
            We're almost done, we just need you to click the link we sent at{' '}
            <a href={`mailto:${user.contactInfo.email}`}>{user.contactInfo.email}</a>.
          </p>
          <p className={'mb-4 text-center'}>
            Don't see it? Wait a few minutes and make sure to check your spam folder.
          </p>
          <div className={'text-center'}>
            <RooButton
              onClick={() => void requestConfirmation()}
              disabled={state !== 'idle'}
              icon={state === 'success' ? 'check' : 'redo'}
              loading={state === 'pending'}
              variant={state === 'success' ? 'success' : 'primary'}
              size={'lg'}
            >
              {state === 'success' ? 'Sent' : state === 'pending' ? 'Sending...' : 'Request another e-mail'}
            </RooButton>
            {state === 'idle' && (
              <p className={'text-muted mt-2'}>
                If it's still not there, click the button above and we'll send you a new one
              </p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
