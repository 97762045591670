import { z } from 'zod';
import { AddressSchema, PersonSchema } from 'shared/utils';

const TenantSchema = PersonSchema.omit({
  email: true
}).extend({
  email: z.string().email().nullish(),
  moveInDate: z.date().nullish(),
  moveOutDate: z.date().nullish()
});

export const FormSchema = z
  .object({
    maintenanceLimit: z.number().positive(),
    name: z.string().nullish(),
    isOccupied: z.boolean(),

    propertyArea: z.number().positive().nullish(),
    lotSize: z.number().positive().nullish(),
    bathroomNo: z.number().int().positive().nullish(),
    bedroomNo: z.number().int().positive().nullish(),
    hasPool: z.boolean(),
    gateCode: z.string().nullish(),
    managementCompanyId: z.string().nullish(),
    managerId: z.string().nullish(),
    owner: PersonSchema.nullish(),
    address: AddressSchema,
    tenants: z.array(TenantSchema)
  })
  .superRefine((val, ctx) => {
    const emailOccurrences: Record<string, number[]> = {};

    val.tenants
      .filter((x) => Boolean(x.email))
      .forEach((tenant, index) => {
        if (emailOccurrences[tenant.email]) {
          emailOccurrences[tenant.email].push(index);
        } else {
          emailOccurrences[tenant.email] = [index];
        }
      });

    console.log(emailOccurrences);

    for (const key of Object.keys(emailOccurrences)) {
      const indices = emailOccurrences[key];
      if (indices.length === 1) {
        continue;
      }

      indices.forEach((index) => {
        ctx.addIssue({
          code: 'custom',
          path: ['tenants', index, 'email'],
          message: `Tenant e-mails must be unique`
        });
      });
    }
  });

export type FormDefinition = z.infer<typeof FormSchema>;
