import { useState } from 'react';
import { apiProvider } from 'shared/api/apiProvider';
import { ChangeRequest, ReviewChangeRequestAction, ReviewChangeRequestPayload } from 'shared/api/clients';
import { ConfirmationModal } from 'components';
import { showSuccess } from 'shared/utils';
import { useCurrentIssue } from 'pages/Workorders/shared/CurrentIssueContext';
import { ChangeRequestDetails } from 'pages/Workorders/ViewIssue/Documents/ChangeRequests/ChangeRequestDetails';

export const ReviewChangeRequestModal = ({
  actionType,
  visible,
  onClose,
  changeRequest
}: {
  changeRequest: ChangeRequest;
  actionType: ReviewChangeRequestAction;
  visible: boolean;
  onClose: () => void;
}) => {
  const { issue, onIssueUpdate } = useCurrentIssue();
  const [isSaving, setIsSaving] = useState(false);
  const [notes, setNotes] = useState<string>('');

  const save = async () => {
    setIsSaving(true);
    try {
      const updatedIssue = await apiProvider.issues.changeRequests.reviewChangeRequest(
        new ReviewChangeRequestPayload({
          changeRequestId: changeRequest.id,
          action: actionType,
          issueId: issue.id,
          notes: notes === '' ? null : notes
        })
      );
      onIssueUpdate(updatedIssue);
      showSuccess();
      onClose();
    } catch (err) {}
    setIsSaving(false);
  };

  const actionText =
    actionType === ReviewChangeRequestAction.Accept ? (
      <>
        Do you want to <strong>accept</strong> this Change Order Request?
      </>
    ) : actionType === ReviewChangeRequestAction.ForceAccept ? (
      'Do you want to accept this estimate without waiting for PM approval?'
    ) : actionType === ReviewChangeRequestAction.Reject ? (
      <>
        Do you want to <strong>reject</strong> this Change Order Request?
      </>
    ) : (
      <>Do you want to send this Change Order Request to the Property Owner?</>
    );

  return (
    <ConfirmationModal
      visible={visible}
      onClose={onClose}
      onSave={() => void save()}
      running={isSaving}
      question={
        <>
          <p>{actionText}</p>

          {(actionType === ReviewChangeRequestAction.Accept || actionType === ReviewChangeRequestAction.Reject) && (
            <div className={'mt-4 form-group'}>
              <label htmlFor={'review-notes'}>Notes</label>
              <textarea
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                className={'form-control'}
                id={'review-notes'}
                rows={3}
                placeholder={'Your comments'}
              />
            </div>
          )}

          <ChangeRequestDetails changeRequest={changeRequest} />
        </>
      }
    />
  );
};
