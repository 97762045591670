import {
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Stack,
  Unstable_Grid2 as Grid
} from '@mui/material';
import React from 'react';
import { ManagementCompany, UpdateCompanyTenantCopyPayload } from '../../../shared/api/clients';
import { rooFmt } from '../../../shared/utils';
import { MuiIcon } from '../../../shared/icons';
import { z } from 'zod';
import { FieldMuiText, useRooForm } from '../../../components/form';
import { apiProvider } from '../../../shared/api/apiProvider';
import { RooDialog } from '../../../components';
import { useCompanyMutation } from '../../../shared/api/queries';
import { Disclosure, useDisclosure } from '@roo/lib';

export const TenantCarbonConfigurationWidget = ({
  company,
  canEdit
}: {
  company: ManagementCompany;
  canEdit: boolean;
}) => {
  const disclosure = useDisclosure(false);
  return (
    <List
      className={'profile'}
      subheader={
        <Stack direction={'row'}>
          <ListSubheader>
            <MuiIcon.ContentCopy /> Copy Tenant Notifications
          </ListSubheader>
          {canEdit && (
            <IconButton color={'primary'} onClick={disclosure.open}>
              <MuiIcon.Edit />
            </IconButton>
          )}
        </Stack>
      }
    >
      <ListItem>
        <ListItemText primary={rooFmt.textMaybe(company.copyTenantEmailsTo, '-')} secondary={'Email'} />
      </ListItem>
      <ListItem>
        <ListItemText primary={rooFmt.textMaybe(company.copyTenantTextsTo, '-')} secondary={'SMS'} />
      </ListItem>
      <UpdateTenantCopyModal company={company} disclosure={disclosure} />
    </List>
  );
};

const UpdateTenantCopyModal = ({ disclosure, company }: { disclosure: Disclosure; company: ManagementCompany }) => {
  return (
    <RooDialog onClose={disclosure.close} fullWidth maxWidth={'sm'} open={disclosure.isOpen}>
      <RooDialog.Title onClose={disclosure.close}>Update Client Settings</RooDialog.Title>
      <UpdateTenantCopyForm disclosure={disclosure} company={company} />
    </RooDialog>
  );
};

const ConfigurationSchema = z.object({
  copyTenantEmailsTo: z.string().email().nullish(),
  copyTenantTextsTo: z.string().nullish()
});

type ConfigurationForm = z.infer<typeof ConfigurationSchema>;

const UpdateTenantCopyForm = ({ disclosure, company }: { disclosure: Disclosure; company: ManagementCompany }) => {
  const { control, handleSubmit } = useRooForm(ConfigurationSchema, {
    defaultValues: {
      copyTenantEmailsTo: company.copyTenantEmailsTo,
      copyTenantTextsTo: company.copyTenantTextsTo
    }
  });

  const { isLoading, mutateAsync } = useCompanyMutation({
    mutationFn: (values: ConfigurationForm) =>
      apiProvider.companiesClient.updateTenantCopySettings(
        new UpdateCompanyTenantCopyPayload({
          companyId: company.id,
          copyTenantTextsTo: values.copyTenantTextsTo,
          copyTenantEmailsTo: values.copyTenantEmailsTo
        })
      ),
    onSuccess: async () => {
      disclosure.close();
    }
  });

  return (
    <>
      <form noValidate onSubmit={handleSubmit((vals) => mutateAsync(vals))}>
        <RooDialog.Content>
          <Grid xs={12}>
            <FieldMuiText control={control} name={'copyTenantEmailsTo'} label="Email" fullWidth margin="normal" />
            <FieldMuiText control={control} name={'copyTenantTextsTo'} label="SMS" fullWidth margin="normal" />
          </Grid>
        </RooDialog.Content>
        <RooDialog.Actions>
          <Stack direction={'row'} spacing={2}>
            <Button type="submit" disabled={isLoading}>
              Update
            </Button>
            <Button color={'muted'} disabled={isLoading} variant={'contained'} onClick={disclosure.close}>
              Close
            </Button>
          </Stack>
        </RooDialog.Actions>
      </form>
    </>
  );
};
