import React from 'react';
import { InvoiceForm } from './InvoiceForm';
import { useMediaQuery, useTheme } from '@mui/material';
import { RooDialog } from '../../../../../components';
import { IInvoice, Invoice } from '../../../../../shared/api/clients';
import { Disclosure } from '@roo/lib';

export const EditInvoiceModal = ({ modal, invoice }: { modal: Disclosure; invoice: IInvoice }) => {
  const theme = useTheme();
  const isLarge = useMediaQuery(theme.breakpoints.up('md'));
  return (
    <RooDialog
      scroll={'paper'}
      fullScreen={!isLarge}
      maxWidth={isLarge ? 'md' : undefined}
      fullWidth
      open={modal.isOpen}
      onClose={modal.close}
    >
      <RooDialog.Title onClose={modal.close}>Edit Invoice</RooDialog.Title>
      <RooDialog.Content>
        <InvoiceForm
          onComplete={modal.close}
          allowImplicitLines={false}
          invoice={invoice}
          preferMatchDraft={true}
          isPartial={invoice.isPartial}
        />
      </RooDialog.Content>
      <RooDialog.Actions></RooDialog.Actions>
    </RooDialog>
  );
};
