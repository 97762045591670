import React from 'react';
import { apiProvider } from '../../shared/api/apiProvider';
import { Helmet } from 'react-helmet';
import { ContentWrapper, LoaderSmall, NavSlim } from '../../components';
import { PaymentsNav } from './PaymentsNav';
import { PaymentsTable } from './PaymentsTable';
import { CompanyType } from '../../shared/api/clients';
import { useQuery } from '@tanstack/react-query';
import { useAppStore } from '../../shared/store';

export const AccountsPayable = () => {
  const { isLoading, data } = useQuery(['reporting', 'accountsPayable'], () =>
    apiProvider.issues.reports.listForAccountsPayable()
  );
  const hideReports = useAppStore((x) => x.hideReports);

  return (
    <>
      <Helmet>
        <title>Accounts Payable - Walkthroo</title>
      </Helmet>
      <NavSlim breadcrumbs={[{ text: 'Accounts Payable' }]} />
      <ContentWrapper className="container-property-list no-transparent">
        <PaymentsNav value={1} />
        {isLoading && (
          <div className={'text-center p-4'}>
            <LoaderSmall />
          </div>
        )}
        {!isLoading && !hideReports && (
          <PaymentsTable data={data} CompanyNameExtras={null} companyType={CompanyType.Vendor} />
        )}
      </ContentWrapper>
    </>
  );
};
