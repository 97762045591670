import { FieldValues, useController, useFormState } from 'react-hook-form';
import { ControlProps } from './types';
import { Stack, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { MuiIcon } from '../../shared/icons';
import React from 'react';

export const FieldYesNoButtonGroup = <TForm extends FieldValues>({
  name,
  control,
  onChange
}: Omit<ControlProps<TForm>, 'label'> & { onChange?: () => void }) => {
  const {
    field,
    fieldState: { error }
  } = useController({
    control: control,
    name: name
  });

  return (
    <Stack>
      <ToggleButtonGroup
        color={field.value == null ? undefined : field.value === 'yes' ? 'success' : 'error'}
        value={field.value}
        exclusive
        onChange={(ev, val) => {
          field.onChange(val);
          onChange?.();
        }}
      >
        <ToggleButton value="yes" aria-label="left aligned">
          <Stack spacing={1} direction={'row'}>
            <MuiIcon.Check color={'success'} /> <span>Yes</span>
          </Stack>
        </ToggleButton>
        <ToggleButton value="no" aria-label="centered">
          <Stack spacing={1} direction={'row'}>
            <MuiIcon.Close color={'error'} /> <span>No</span>
          </Stack>
        </ToggleButton>
      </ToggleButtonGroup>
      {error && (
        <Typography align={'center'} variant={'caption'} sx={(theme) => ({ color: theme.palette.error.main })}>
          {error.message}
        </Typography>
      )}
    </Stack>
  );
};
