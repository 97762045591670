import {
  IAttachmentFile,
  IInsDefLineAction,
  IInsDefLineCondition,
  IInsDocument,
  IInsNodeInstance,
  InsConditionType,
  InsFieldType,
  InsNodeType,
} from "@roo/api";
import { simpleHash } from "../utils";
import { orderBy } from "lodash";

const KNOWN_FIELDS = {
  notes: "notes",
  cost: "cost",
};

export type InsNodeViewType = "section" | "line" | "bulk-section";

export type InsBaseView = {
  id: string;
  parentId: string;
  childrenIds: string[];
  descendantIds: string[];
  name: string;
  parentName: string;
  parentPath: string;
  allFieldKeys: string[];
  visibleFieldKeys: string[];
};

export type InsFieldView = {
  nodeId: string;
  type: InsFieldType;
  fieldKey: string;
  value: string;
  isVisible: boolean;
};

export type InsBulkLineGroup = {
  name: string;
  hash: string;
  lineIds: string[];
};

export type InsNodeView = InsBaseView &
  (
    | {
        type: "section";
        sectionIds: string[];
        descendantSectionIds: string[];
        lineIds: string[];
        createableSectionKeys: string[];
        createableLineKeys: string[];

        completedLines: number;
        issueLines: number;
        totalLines: number;
      }
    | {
        type: "line";
        nextLineId: string;
        prevLineId: string;
        directEditTime: number;

        selectedAction: IInsDefLineAction;
        selectedCondition: IInsDefLineCondition;

        availableActions: IInsDefLineAction[];
        availableConditions: IInsDefLineCondition[];

        price: number;
        notes: string;
        pictures: IAttachmentFile[];
        isComplete: boolean;
        isAcceptable: boolean;
        isIssue: boolean;
      }
    | {
        type: "bulk-section";
        lineGroups: InsBulkLineGroup[];
      }
  );

export type InsLineView = InsNodeView & { type: "line" };
export type InsSectionView = InsNodeView & { type: "section" };
export type InsBulkSectionView = InsNodeView & { type: "bulk-section" };

type InsNodeMetaView = {
  id: string;
  type: InsNodeViewType;
};

export type InspectionViews = {
  sections: Record<string, InsSectionView>;
  lines: Record<string, InsLineView>;
  bulkSections: Record<string, InsBulkSectionView>;
  nodeInfo: Record<string, InsNodeMetaView>;
  fields: Record<string, InsFieldView>;
  problemLineIds: string[];
  defaultAcceptableCondition: IInsDefLineCondition;
};

const getLineViewHash = (line: InsLineView) => {
  let key = line.name;
  key += line.availableConditions.map((x) => x.key).join("|");
  key += line.availableActions.map((x) => x.key).join("|");
  key += line.allFieldKeys.join("|");
  return simpleHash(key);
};

export const deriveInsViews = (doc: IInsDocument): InspectionViews => {
  const acceptableCondition = Object.values(
    doc.sharedStore.lineConditions,
  ).find((x) => x.type === InsConditionType.Acceptable);

  const res: InspectionViews = {
    sections: {},
    nodeInfo: {},
    bulkSections: {},
    lines: {},
    fields: {},
    problemLineIds: [],
    defaultAcceptableCondition: {
      ...acceptableCondition,
    },
  };

  const orderedLineIds: string[] = [];

  const visit = (
    node: IInsNodeInstance,
    parentView: InsNodeView,
  ): InsNodeView => {
    const currBase = {
      id: node.id,
      name: node.name,
      parentId: parentView?.id,
      parentPath: [parentView?.parentPath, parentView?.name]
        .filter((x) => x != null && x.trim() !== "")
        .join(" > "),
      visibleFieldKeys: [] as string[],
      allFieldKeys: [] as string[],
      childrenIds: [] as string[],
      descendantIds: [] as string[],
      parentName: parentView?.name,
    } satisfies InsBaseView;

    const nodeValues = doc.nodeValues[node.id];

    const fieldMarkers = {
      notes: null as string,
      pictures: [] as IAttachmentFile[],
      price: null as number,
    };

    if (node.fieldKeys != null) {
      for (const fieldKey of node.fieldKeys) {
        currBase.allFieldKeys.push(fieldKey);

        const fieldDef = doc.sharedStore.fields[fieldKey];
        const fieldVal = nodeValues.fieldValues.find((x) => x.key === fieldKey);
        if (fieldVal.isVisible) {
          currBase.visibleFieldKeys.push(fieldKey);
        }

        if (fieldVal.key === KNOWN_FIELDS.cost) {
          if (fieldVal.isVisible && fieldVal.value != null) {
            const parsed = parseFloat(fieldVal.value);
            fieldMarkers.price = isNaN(parsed) ? null : parsed;
          }
        }

        if (fieldVal.key === KNOWN_FIELDS.notes) {
          fieldMarkers.notes = fieldVal.value;
        }

        if (
          fieldDef.info.type === InsFieldType.Pictures &&
          fieldVal.isVisible &&
          fieldVal.value != null
        ) {
          const pics = JSON.parse(fieldVal.value ?? "[]") as IAttachmentFile[];
          if (pics.length > 0) {
            fieldMarkers.pictures = pics;
          }
        }

        res.fields[`${node.id}:${fieldKey}`] = {
          nodeId: node.id,
          type: fieldDef.info.type,
          value: fieldVal.value,
          fieldKey,
          isVisible: fieldVal.isVisible,
        };
      }
    }

    if (node.info.type === InsNodeType.Line) {
      const conditionMatch =
        nodeValues.selectedCondition == null
          ? null
          : { ...doc.sharedStore.lineConditions[nodeValues.selectedCondition] };
      const actionMatch =
        nodeValues.selectedAction == null
          ? null
          : { ...doc.sharedStore.lineActions[nodeValues.selectedAction] };
      const isComplete =
        conditionMatch?.type === InsConditionType.Acceptable ||
        (conditionMatch != null && actionMatch != null);
      const isAcceptable = conditionMatch?.type === InsConditionType.Acceptable;
      const isIssue = actionMatch != null;

      const availableActions = node.info.forLine.availableActionKeys
        .map((x) => doc.sharedStore.lineActions[x])
        .map((x) => ({ ...x }));
      const availableConditions = node.info.forLine.availableConditionKeys
        .map((x) => doc.sharedStore.lineConditions[x])
        .map((x) => ({ ...x }));

      const line: InsLineView = {
        ...currBase,
        ...fieldMarkers,
        type: "line",
        selectedCondition: conditionMatch,
        selectedAction: actionMatch,
        availableActions,
        availableConditions,
        isAcceptable,
        isComplete,
        isIssue,
        directEditTime: nodeValues.directEditTime,
        prevLineId: null,
        nextLineId: null,
      };

      if (line.isIssue) {
        res.problemLineIds.push(line.id);
      }

      res.lines[line.id] = line;
      res.nodeInfo[line.id] = { id: line.id, type: "line" };
      orderedLineIds.push(line.id);
      return line;
    }

    if (node.info.type === InsNodeType.Section) {
      const section: InsSectionView = {
        ...currBase,
        type: "section",
        sectionIds: [],
        descendantSectionIds: [],
        lineIds: [],
        createableLineKeys: [],
        createableSectionKeys: [],
        completedLines: 0,
        totalLines: 0,
        issueLines: 0,
      };

      const creatableKeys = node.creatableNodeKeys ?? [];
      for (const key of creatableKeys) {
        const node = doc.sharedStore.nodes[key];
        if (node.info.type === InsNodeType.Line) {
          section.createableLineKeys.push(key);
        } else if (node.info.type === InsNodeType.Section) {
          section.createableSectionKeys.push(key);
        }
      }

      const children = node.children ?? [];
      for (const child of children) {
        const visitedChild = visit(child, section);
        if (visitedChild == null) {
          continue;
        }

        section.childrenIds.push(visitedChild.id);
        section.descendantIds.push(
          visitedChild.id,
          ...visitedChild.descendantIds,
        );

        if (visitedChild.type === "section") {
          section.sectionIds.push(visitedChild.id);
          section.descendantSectionIds.push(
            visitedChild.id,
            ...visitedChild.descendantSectionIds,
          );
          section.totalLines += visitedChild.totalLines;
          section.completedLines += visitedChild.completedLines;
          section.issueLines += visitedChild.issueLines;
        } else if (visitedChild.type === "line") {
          section.lineIds.push(visitedChild.id);

          section.totalLines += 1;
          section.completedLines += visitedChild.isComplete ? 1 : 0;
          section.issueLines += visitedChild.isIssue ? 1 : 0;
        }
      }

      res.sections[section.id] = section;
      res.nodeInfo[section.id] = { id: section.id, type: "section" };
      return section;
    }

    if (node.info.type === InsNodeType.BulkEditSection) {
      const bulkSection = {
        ...currBase,
        ...fieldMarkers,
        type: "bulk-section",
        lineGroups: [] as InsBulkLineGroup[],
      } satisfies InsBulkSectionView;
      res.nodeInfo[bulkSection.id] = {
        id: bulkSection.id,
        type: "bulk-section",
      };
      res.bulkSections[bulkSection.id] = bulkSection;

      return bulkSection;
    }

    return null;
  };

  visit(doc.layout.root, null);

  for (let i = 0; i < orderedLineIds.length; i++) {
    const curr = res.lines[orderedLineIds[i]];

    if (i > 1) {
      curr.prevLineId = orderedLineIds[i - 1];
    }

    if (i < orderedLineIds.length - 1) {
      curr.nextLineId = orderedLineIds[i + 1];
    }
  }

  for (const bulkSection of Object.values(res.bulkSections)) {
    if (bulkSection.parentId == null) {
      continue;
    }

    const groups: Record<string, InsBulkLineGroup> = {};
    const visit = (sectionId: string) => {
      const section = res.sections[sectionId];

      for (const lineId of section.lineIds) {
        const line = res.lines[lineId];
        if (line.directEditTime != null) {
          continue;
        }

        const hash = getLineViewHash(line);
        if (groups[hash] == null) {
          groups[hash] = {
            hash: hash.toString(),
            name: line.name,
            lineIds: [],
          };
        }

        groups[hash].lineIds.push(lineId);
      }

      for (const childId of section.sectionIds) {
        visit(childId);
      }
    };

    visit(bulkSection.parentId);
    bulkSection.lineGroups = orderBy(
      Object.values(groups).filter((x) => x.lineIds.length > 1),
      (x) => x.lineIds.length,
      "desc",
    );
  }

  return res;
};
