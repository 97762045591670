import { Stack } from '@mui/material';
import { RooIcon } from 'shared/icons';
import { Button, Col, Row } from 'react-bootstrap';
import { IInvoice, Invoice } from 'shared/api/clients';
import { RooButton, RooDialog } from 'components';
import { LineTable } from 'pages/Workorders/ViewIssue/Documents/LineTable';
import { rooFmt } from 'shared/utils';
import { downloadFiles } from '../../../../../components/Files/utils';
import React from 'react';
import { flatMap } from 'lodash';
import { useCurrentVendor } from '../../../../../shared/store';
import { DocumentViewTotals } from '../DocumentViewTotals';
import { apiProvider } from '../../../../../shared/api/apiProvider';
import { useToggle } from '@roo/lib';

export const InvoiceInfoButton = ({ invoice }: { invoice: IInvoice }) => {
  const [show, toggleShow] = useToggle();

  return (
    <>
      <RooButton icon={'eye'} variant={'primary'} onClick={toggleShow} />
      <InvoiceInfoModal invoice={invoice} show={show} toggleShow={toggleShow} />
    </>
  );
};

export const InvoiceInfoModal = ({
  invoice,
  show,
  toggleShow
}: {
  invoice: IInvoice;
  show: boolean;
  toggleShow: () => void;
}) => {
  const allFiles = [...flatMap(invoice.lines, (x) => x.beforeFiles), ...flatMap(invoice.lines, (x) => x.afterFiles)];
  return (
    <RooDialog open={show} onClose={toggleShow} maxWidth={'md'} fullWidth>
      <RooDialog.Title onClose={toggleShow}>Invoice Info</RooDialog.Title>
      <RooDialog.Content>
        <Row>
          <Col xl={12}>
            <InvoiceInfo invoice={invoice} />
          </Col>
        </Row>
      </RooDialog.Content>
      <RooDialog.Actions>
        <Stack direction={'row'} spacing={2}>
          {allFiles.length > 0 && (
            <Button onClick={() => downloadFiles(allFiles)}>
              Download {rooFmt.counted(allFiles.length, 'picture', 'pictures')}
            </Button>
          )}
          {invoice.pdfFile != null && (
            <a href={apiProvider.fileUrls.download(invoice.pdfFile.id)} className={'btn btn-primary'} rel="noreferrer">
              <RooIcon icon={'file-pdf'} /> Download PDF
            </a>
          )}
          <Button variant={'outline-primary'} onClick={toggleShow}>
            Close
          </Button>
        </Stack>
      </RooDialog.Actions>
    </RooDialog>
  );
};

export const InvoiceInfo = ({ invoice, showPaidOutstanding }: { invoice: IInvoice; showPaidOutstanding?: boolean }) => {
  const currVendor = useCurrentVendor();
  return (
    <>
      {!!invoice.reviewerNotes && (
        <Row>
          <Col>
            <dl>
              <dt>Reviewer Notes</dt>
              <dd>{invoice.reviewerNotes}</dd>
            </dl>
          </Col>
        </Row>
      )}
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 1, md: 4 }}>
        <dl style={{ marginBottom: 0 }}>
          <dt>Number</dt>
          <dd>{invoice.friendlyId ?? '-'}</dd>
        </dl>
        <dl style={{ marginBottom: 0 }}>
          <dt>Date</dt>
          <dd>{rooFmt.instantDateMaybe(invoice.referenceDate, '-')}</dd>
        </dl>
        <dl style={{ marginBottom: 0 }}>
          <dt>Vendor</dt>
          <dd>{invoice.vendor.companyName}</dd>
        </dl>
      </Stack>
      <Row>
        <Col>
          <LineTable lines={invoice.lines} />
        </Col>
      </Row>

      <div
        style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', flexGrow: 0, marginTop: '30px' }}
      >
        <DocumentViewTotals
          totals={invoice.totals}
          outstanding={showPaidOutstanding ? invoice.outstanding : undefined}
          isAuthor={currVendor?.id === invoice.vendor.id}
        />
      </div>
    </>
  );
};
