import { cloneDeep, keyBy } from "lodash";
import { logTime, makeUuid, mergeDeepTyped, platformUtils } from "../utils";
import { produce } from "immer";
import { assertNever } from "../types";
import { create } from "zustand";
import { createStoreContext } from "../hooks";
import {
  IInsDefNode,
  IInsDocument,
  IInsDocumentProperties,
  IInsNodeInstance,
  IInsNodeValue,
  IInsSharedStore,
  IInsTemplateEnvelope,
  IInspectionDetails,
  InsConditionType,
  InsFieldVisibilityRuleType,
  InsNodeInstance,
  InsNodeType,
  InsTemplateEnvelope,
  UpdateInspectionDocumentPayload,
} from "@roo/api";
import { InsSectionView, InspectionViews, deriveInsViews } from "./view";
import { insService } from "./insService";

export const createInspectionDocument = (
  templateEnvelope: IInsTemplateEnvelope,
  startingProperties: IInsDocumentProperties,
): IInsDocument => {
  const instanceData = instantiateNode(
    templateEnvelope.template.root,
    templateEnvelope.template.sharedStore,
  );
  const nodeValues = keyBy(instanceData.nodeValues, (x) => x.nodeId);

  return {
    layout: {
      root: instanceData.instance,
    },
    templateInfo: {
      hash: templateEnvelope.templateHash,
      toolingVersion: templateEnvelope.toolingVersion,
      type: templateEnvelope.templateType,
    },
    properties: startingProperties,
    nodeValues: nodeValues,
    sharedStore: templateEnvelope.template.sharedStore,
  };
};

const instantiateNode = (
  node: IInsDefNode,
  sharedStore: IInsSharedStore,
): { instance: IInsNodeInstance; nodeValues: IInsNodeValue[] } => {
  const nodeValues: IInsNodeValue[] = [];
  const walk = (templateNode: IInsDefNode) => {
    const instance: IInsNodeInstance = {
      id: makeUuid(),
      ...templateNode,
      children: null,
    };

    const isLine = instance.info.type === InsNodeType.Line;
    const hasValue = isLine || (instance.fieldKeys?.length ?? 0) > 0;
    if (hasValue) {
      const val: IInsNodeValue = {
        nodeId: instance.id,
        selectedAction: null,
        selectedCondition: null,
        fieldValues: null,
        directEditTime: null,
        bulkEditTime: null,
      };

      if ((instance.fieldKeys?.length ?? 0) > 0) {
        val.fieldValues = instance.fieldKeys.map((x) => ({
          key: x,
          value: null,
          isVisible: isFieldVisible(
            x,
            { selectedAction: null, selectedCondition: null },
            sharedStore,
          ),
        }));
      }

      nodeValues.push(val);
    }

    if (templateNode.children != null) {
      instance.children = [];
      for (const child of templateNode.children) {
        instance.children.push(
          walk(child) satisfies IInsNodeInstance as InsNodeInstance,
        );
      }
    }

    return instance;
  };

  const root = walk(node);

  return {
    instance: root,
    nodeValues: nodeValues,
  };
};

type InspectionStoreType = {
  document: IInsDocument;
  views: InspectionViews;
  rootNodeId: string;
  inspectionDetails: IInspectionDetails;
  userId: string;
  pendingSaveTimeout?: number;
  isReadonly: boolean;
};

type StoreSetter = (
  cb: (store: InspectionStoreType) => InspectionStoreType,
) => void;

const storeActionBuilder = (
  set: StoreSetter,
  get: () => InspectionStoreType,
  isReadonly: boolean,
) => ({
  addNode: (
    parentId: string,
    nodeKey: string,
    name: string,
  ): { nodeId: string } => {
    if (isReadonly) {
      return null;
    }

    let createdId: string = null;
    set((curr) => {
      const doc = logTime("[add] produce doc", () => {
        const sharedStore = curr.document.sharedStore;
        const template = sharedStore.nodes[nodeKey];

        return produce(curr.document, (draft) => {
          const visit = (node: IInsNodeInstance) => {
            if (node.id === parentId) {
              const clone = cloneDeep(template);
              clone.name = name;

              const { instance, nodeValues } = instantiateNode(
                clone,
                draft.sharedStore,
              );
              if (node.children == null) {
                node.children = [];
              }
              createdId = instance.id;
              node.children.push(
                instance satisfies IInsNodeInstance as InsNodeInstance,
              );

              for (const nodeVal of nodeValues) {
                draft.nodeValues[nodeVal.nodeId] = nodeVal;
              }

              return;
            }
            if (node.children != null) {
              for (const child of node.children) {
                visit(child);
              }
            }
          };

          visit(draft.layout.root);
        });
      });

      const newViews = logTime(`[delete] add node`, () => deriveInsViews(doc));
      const views = logTime(`[delete]: add node`, () =>
        mergeDeepTyped(curr.views, newViews),
      );

      return {
        ...curr,
        views,
        document: doc,
      };
    });

    triggerSave(get);

    return {
      nodeId: createdId,
    };
  },
  deleteNode: (nodeId: string): void => {
    if (isReadonly) {
      return;
    }
    set((curr) => {
      const doc = logTime("[delete] produce doc", () => {
        return produce(curr.document, (draft) => {
          const visit = (node: IInsNodeInstance) => {
            if (node.children != null) {
              const idx = node.children.findIndex((x) => x.id === nodeId);
              if (idx > -1) {
                node.children.splice(idx, 1);
                return;
              }

              for (const child of node.children) {
                visit(child);
              }
            }
          };
          visit(draft.layout.root);
        });
      });
      const newViews = logTime(`[delete] derive views`, () =>
        deriveInsViews(doc),
      );
      const views = logTime(`[delete]: merge views`, () =>
        mergeDeepTyped(curr.views, newViews),
      );

      return {
        ...curr,
        views,
        document: doc,
      };
    });
    triggerSave(get);
  },
  renameNode: (nodeId: string, newName: string): void => {
    if (isReadonly) {
      return;
    }
    set((curr) => {
      const doc = logTime("[rename] produce doc", () => {
        return produce(curr.document, (draft) => {
          const visit = (node: IInsNodeInstance) => {
            if (node.id === nodeId) {
              node.name = newName;
              return;
            }

            if (node.children != null) {
              for (const child of node.children) {
                visit(child);
              }
            }
          };
          visit(draft.layout.root);
        });
      });
      const newViews = logTime(`[rename] derive views`, () =>
        deriveInsViews(doc),
      );
      const views = logTime(`[rename]: merge views`, () =>
        mergeDeepTyped(curr.views, newViews),
      );

      return {
        ...curr,
        views,
        document: doc,
      };
    });
    triggerSave(get);
  },
  setLinesAction: (
    lineIds: string[],
    actionKey: string,
    isBulk: boolean,
  ): void => {
    if (isReadonly) {
      return;
    }
    updateNodeValue("set action", set, lineIds, isBulk, (nodeVal) => {
      nodeVal.selectedAction = actionKey;
    });
    triggerSave(get);
  },
  setLinesCondition: (
    lineIds: string[],
    conditionKey: string,
    isBulk: boolean,
  ): void => {
    if (isReadonly) {
      return;
    }
    updateNodeValue("set condition", set, lineIds, isBulk, (nodeVal) => {
      nodeVal.selectedCondition = conditionKey;
    });
    triggerSave(get);
  },
  setNodesField: (
    nodeIds: string[],
    fieldKey: string,
    value: string,
    isBulk: boolean,
  ): void => {
    if (isReadonly) {
      return;
    }
    updateNodeValue("set field", set, nodeIds, isBulk, (nodeVal) => {
      const field = nodeVal.fieldValues.find((x) => x.key === fieldKey);
      field.value = value;
    });
    triggerSave(get);
  },
  markSectionAcceptable: (sectionId: string): string => {
    if (isReadonly) {
      return;
    }
    const beforeUpdate = get();
    const acceptableCondition = beforeUpdate.views.defaultAcceptableCondition;
    const targetLineIds: string[] = [];

    const section = beforeUpdate.views.sections[sectionId];
    const visit = (node: InsSectionView) => {
      for (const lineId of node.lineIds) {
        const lineView = beforeUpdate.views.lines[lineId];
        if (
          lineView.selectedCondition != null &&
          lineView.selectedCondition.type !== InsConditionType.Acceptable
        ) {
          continue;
        }

        targetLineIds.push(lineId);
      }

      for (const childId of node.sectionIds) {
        const child = beforeUpdate.views.sections[childId];
        visit(child);
      }
    };

    visit(section);

    updateNodeValue("all good", set, targetLineIds, true, (nodeVal) => {
      nodeVal.selectedCondition = acceptableCondition.key;
    });

    triggerSave(get);

    return null;
  },
  getDehydratedDocument: (): string => {
    const state = get();
    return insService.dehydrate(
      state.inspectionDetails.id,
      state.userId,
      Date.now(),
      state.document,
    );
  },
});

const updateNodeValue = (
  name: string,
  storeSet: StoreSetter,
  nodeIds: string[],
  isBulk: boolean,
  updater: (val: IInsNodeValue) => void,
) => {
  storeSet((curr) => {
    const doc = logTime(`[${name}] doc changes`, () =>
      produce(curr.document, (draft) => {
        for (const nodeId of nodeIds) {
          const nodeVal = draft.nodeValues[nodeId];
          updater(nodeVal);

          consolidateValues(nodeVal, curr.document.sharedStore);

          if (isBulk) {
            nodeVal.bulkEditTime = Date.now();
          } else {
            nodeVal.directEditTime = Date.now();
          }
        }
      }),
    );

    const newViews = logTime(`[${name}] derive views`, () =>
      deriveInsViews(doc),
    );
    const views = logTime(`[${name}]: merge views`, () =>
      mergeDeepTyped(curr.views, newViews),
    );

    return {
      ...curr,
      views,
      document: doc,
    };
  });
};

const consolidateValues = (
  val: IInsNodeValue,
  sharedStore: IInsSharedStore,
) => {
  if (val.selectedAction) {
    if (val.selectedCondition == null) {
      val.selectedAction = null;
    } else {
      const conditionMatch = sharedStore.lineConditions[val.selectedCondition];
      if (conditionMatch == null) {
        throw new Error(`Missing condition: ${val.selectedCondition}`);
      }

      if (conditionMatch.type === InsConditionType.Acceptable) {
        val.selectedAction = null;
      }
    }
  }

  for (const field of val.fieldValues) {
    field.isVisible = isFieldVisible(field.key, val, sharedStore);
    field.value = field.isVisible ? field.value : null;
  }
};

const isFieldVisible = (
  fieldKey: string,
  nodeVal: { selectedCondition: string; selectedAction: string },
  sharedStore: IInsSharedStore,
) => {
  const template = sharedStore.fields[fieldKey];

  if (template == null) {
    throw new Error(`Missing field: ${fieldKey}`);
  }

  switch (template.visibility.type) {
    case InsFieldVisibilityRuleType.Always:
      return true;
    case InsFieldVisibilityRuleType.IfConditionType: {
      const rule = template.visibility.forIfConditionType;
      const conditionMatch =
        nodeVal.selectedCondition == null
          ? null
          : sharedStore.lineConditions[nodeVal.selectedCondition];
      return conditionMatch != null && conditionMatch.type === rule.type;
    }
    case InsFieldVisibilityRuleType.IfActionKey: {
      const rule = template.visibility.forIfActionKey;
      return (
        nodeVal.selectedAction != null &&
        rule.actionKeys.includes(nodeVal.selectedAction)
      );
    }
    case InsFieldVisibilityRuleType.IfActionType: {
      const rule = template.visibility.forIfActionType;
      const actionMatch =
        nodeVal.selectedAction == null
          ? null
          : sharedStore.lineActions[nodeVal.selectedAction];
      return actionMatch != null && actionMatch.type === rule.type;
    }
    default:
      assertNever(template.visibility.type);
  }
};

export const createInspectionEditorStore = ({
  template,
  inspection,
  existingDocument,
  userId,
  isReadonly,
}: {
  template: InsTemplateEnvelope;
  inspection: IInspectionDetails;
  existingDocument: IInsDocument;
  userId: string;
  isReadonly: boolean;
}) => {
  const doc =
    existingDocument != null
      ? existingDocument
      : logTime("doc", () =>
          createInspectionDocument(template, {} as IInsDocumentProperties),
        );
  const view = logTime("derive", () => deriveInsViews(doc));
  return create<
    InspectionStoreType & { actions: ReturnType<typeof storeActionBuilder> }
  >()((set, get) => ({
    actions: storeActionBuilder(set, get, isReadonly),
    document: doc,
    views: view,
    rootNodeId: doc.layout.root.id,
    inspectionDetails: inspection,
    userId: userId,
    isReadonly,
  }));
};

type StoreApiType = ReturnType<typeof createInspectionEditorStore>;
export const { Provider: InsEditorStoreProvider, useStore: useInsEditor } =
  createStoreContext<StoreApiType>();

const triggerSave = (getter: () => InspectionStoreType) => {
  const store = getter();
  if (store.isReadonly) {
    return;
  }

  if (store.pendingSaveTimeout != null) {
    clearTimeout(store.pendingSaveTimeout);
  }

  const timestamp = Date.now();
  const serialized = insService.dehydrate(
    store.inspectionDetails.id,
    store.userId,
    timestamp,
    store.document,
  );
  const inspectionId = store.inspectionDetails.id;
  const userId = store.userId;

  store.pendingSaveTimeout = setTimeout(() => {
    void save({
      inspectionId,
      userId,
      timestamp,
      serializedPayload: serialized,
    });
  }, 3000) as unknown as number;
};

const save = async ({
  inspectionId,
  userId,
  timestamp,
  serializedPayload,
}: {
  inspectionId: string;
  userId: string;
  timestamp: number;
  serializedPayload: string;
}) => {
  try {
    await platformUtils.get().api.inspectionClient.updateInspectionDocument(
      new UpdateInspectionDocumentPayload({
        inspectionId: inspectionId,
        clientTimestamp: timestamp,
        contentJson: serializedPayload,
      }),
    );
    await insService.save(inspectionId, userId, serializedPayload);
  } catch (e) {
    platformUtils.get().logException(e);
  }
};
