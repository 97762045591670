import './polyfills';
import {} from './mui';

import React, { ReactNode } from 'react';

import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.css';

import 'rc-drawer/assets/index.css';
import 'primereact/resources/themes/md-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import './index.scss';
import './primereact.scss';

import { FullPageApp } from 'App/FullPageApp';
import { Router } from 'react-router';
import { browserHistory } from 'shared/routing';
import { enableMapSet } from 'immer';
import { customizeErrors } from 'shared/utils';
import { ToastContainer } from 'react-toastify';
import { loadIcons } from 'shared/icons';
import * as Sentry from '@sentry/react';
import { ErrorScreen } from 'components';
import { FilterService } from 'primereact/api';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from './shared/queryClient';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ErrorBoundary } from './components/ErrorBoundary';
import { createRoot } from 'react-dom/client';
import { setupPlatform } from './platformSetup';

enableMapSet();
customizeErrors();
loadIcons();
setupPlatform();

if (!process.env.NODE_ENV || process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://1e9883df0f134dc5a7d2f163b538eb37@o1000694.ingest.sentry.io/5960235',
    tunnel: '/api/forward/sentry'
  });
}
const enableProfiling = localStorage.getItem('enableProfiling');

if (enableProfiling) {
  fetch('/api/diagnostics/get-profiling-include', {
    headers: {
      'X-ENABLE-PROFILING': '1'
    }
  })
    .then((resp) => (resp.status !== 200 ? null : resp.text()))
    .then((resp) => {
      if (resp == null) {
        return;
      }
      const frag = window.document.createRange().createContextualFragment(resp);
      window.document.body.appendChild(frag);
    });
}

FilterService.register('arrayIntersect', (theirs: unknown[], ours: unknown[]) => {
  if (ours == null) {
    return true;
  }

  for (const selection of ours) {
    if (theirs.find((x) => x === selection) != null) {
      return true;
    }
  }
  return false;
});

const AppWrapper = ({ children }: { children?: ReactNode }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <React.StrictMode>
        <QueryClientProvider client={queryClient}>
          <Router history={browserHistory}>
            <ErrorBoundary fallback={ErrorScreen}>{children}</ErrorBoundary>
          </Router>
          <ToastContainer />
          <div id={'drawerContainer'} />
        </QueryClientProvider>
      </React.StrictMode>
    </LocalizationProvider>
  );
};

const fullApp = document.getElementById('react-full-mount');
const root = createRoot(fullApp);
root.render(
  <AppWrapper>
    <FullPageApp />
  </AppWrapper>
);
