import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { IAttachmentFile } from 'shared/api/clients';
import { apiProvider } from '../../shared/api/apiProvider';

export const mimeToIcon = (mimeType: string): IconProp => {
  if (mimeType == null) {
    return 'file';
  }
  if (mimeType.startsWith('image')) {
    return 'file-image';
  }

  if (mimeType.startsWith('audio')) {
    return 'file-audio';
  }

  if (mimeType.startsWith('video')) {
    return 'file-video';
  }

  switch (mimeType) {
    case 'application/pdf':
      return 'file-pdf';
    case 'application/msword':
    case 'application/vnd.ms-word':
    case 'application/vnd.oasis.opendocument.text':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingm':
      return 'file-word';
    case 'application/vnd.ms-excel':
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml':
    case 'application/vnd.oasis.opendocument.spreadsheet':
      return 'file-excel';
    case 'application/vnd.ms-powerpoint':
    case 'application/vnd.openxmlformats-officedocument.presentationml':
    case 'application/vnd.oasis.opendocument.presentation':
      return 'file-powerpoint';
    case 'text/plain':
      return 'file-alt';
    case 'application/gzip':
    case 'application/zip':
      return 'file-archive';
  }

  return 'file';
};

// https://stackoverflow.com/a/29606450
export const downloadFiles = (files: IAttachmentFile[]) => {
  const downloadNext = (idx: number) => {
    if (idx >= files.length) {
      return;
    }

    const currFile = files[idx];
    const downloadUrl = apiProvider.fileUrls.download(currFile.id);
    if (downloadUrl == null) {
      return;
    }

    const a = document.createElement('a');
    a.href = downloadUrl;
    a.target = '_parent';
    // Use a.download if available, it prevents plugins from opening.
    if ('download' in a) {
      a.download = currFile.fileName;
    }
    // Add a to the doc for click to work.
    (document.body || document.documentElement).appendChild(a);
    if (a.click) {
      a.click(); // The click method is supported by most browsers.
    }
    // Delete the temporary link.
    a.parentNode.removeChild(a);
    // Download the next file with a small timeout. The timeout is necessary
    // for IE, which will otherwise only download the first file.
    setTimeout(function () {
      downloadNext(idx + 1);
    }, 200);
  };

  downloadNext(0);
};
