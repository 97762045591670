import { Button, Stack, Tooltip } from '@mui/material';
import { Helmet } from 'react-helmet';
import { ContentWrapper, MuiRouterLink, NavSlim, RooButton, RooDialog } from '../../components';
import { apiProvider } from '../../shared/api/apiProvider';
import { useQuery } from '@tanstack/react-query';
import React, { MutableRefObject, useEffect, useMemo, useRef, useState } from 'react';
import MaterialReactTable, { MRT_ColumnDef, MRT_TableInstance } from 'material-react-table';
import {
  GetClientsFilters,
  ManagementCompany,
  NoteEntityType,
  NoteSource,
  VendorClientModel
} from '../../shared/api/clients';
import { rooFmt } from '../../shared/utils';
import { useContainerHeight } from '../../shared/utils/useContainerHeight';
import { DateRangeChips, SelectedDateRange } from '../../components/DateRangeChips';
import { NotesButton } from '../../components/Notes/NotesButton';
import { Routes } from '../../shared/routing';
import { ContactsTable } from '../../components/Clients/ContactsTable';
import { useAppStore } from '../../shared/store';
import { unparse } from 'papaparse';
import { AppClipboard } from '../../shared/utils/clipboard';
import { CenteredLoader } from '../../components/CenteredLoader';
import { useDisclosure } from '@roo/lib';
import { CreateClientButton } from './CreateClientButton';
import { AddIssueNoticeButton } from './AddIssueNoticeButton';

export const VendorClientsPage = () => {
  const [dateSelection, setDateSelection] = useState<SelectedDateRange>({
    id: 'all-time',
    endDate: null,
    startDate: null
  });
  const hideClients = useAppStore((x) => x.hideClients);

  const tableRef = useRef<MRT_TableInstance<VendorClientModel>>();
  const { isLoading, data } = useQuery(
    ['vendor', 'clients', dateSelection.startDate, dateSelection.endDate],
    () =>
      apiProvider.generalContractorClient.getClients(
        new GetClientsFilters({
          startDate: dateSelection.startDate,
          endDate: dateSelection.endDate
        })
      ),
    {
      staleTime: 1 * 60 * 1000
    }
  );

  // if we have a lot of items, navigation will take too long waiting for table to render
  const [fakeLoading, setFakeLoading] = useState(true);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setFakeLoading(false);
    }, 1);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  if (hideClients) {
    return null;
  }

  const actualLoading = fakeLoading || isLoading;

  return (
    <>
      <Helmet>
        <title>Clients - Walkthroo</title>
      </Helmet>
      <NavSlim breadcrumbs={[{ text: 'Client List' }]} />
      <ContentWrapper className="container-property-list">
        <Stack spacing={3}>
          <Stack bgcolor={'white'} p={2} flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'}>
            <h5 className="m-0">Clients</h5>
            <Stack flexDirection={'row'} gap={2}>
              <CreateClientButton />
              {!isLoading && <CopyRowsButton tableRef={tableRef} />}
              <DateRangeChips value={dateSelection} setValue={setDateSelection} />
            </Stack>
          </Stack>
          {actualLoading && <CenteredLoader />}
          {!actualLoading && <ClientsTable data={data} tableRef={tableRef} />}
        </Stack>
      </ContentWrapper>
    </>
  );
};

const ClientsTable = ({
  data,
  tableRef
}: {
  data: VendorClientModel[];
  tableRef: MutableRefObject<MRT_TableInstance<VendorClientModel>>;
}) => {
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const height = useContainerHeight({ itemRef: tableContainerRef, defaultHeight: 500 });

  const columns = useMemo<MRT_ColumnDef<VendorClientModel>[]>(
    () => [
      {
        header: 'Company',
        id: 'company.name',
        accessorFn: (x) => x.company?.name,
        Cell: ({ row }) => (
          <MuiRouterLink to={{ pathname: Routes.CompanyProfile, params: { companyId: row.original.company.id } }}>
            {row.original.company.name}
          </MuiRouterLink>
        )
      },
      {
        id: 'contacts',
        header: 'Contacts',
        columnDefType: 'display',
        Cell: ({ row }) => <ContactsButton company={row.original.company} />
      },
      {
        header: 'City',
        id: 'company.address.city',
        accessorFn: (x) => x.company?.address?.city
      },
      {
        header: 'State',
        id: 'company.address.state',
        accessorFn: (x) => x.company?.address?.state
      },
      {
        header: 'Issues',
        accessorKey: 'issueCount',
        enableColumnFilter: false
      },
      {
        header: 'Turns Estimated',
        accessorKey: 'turnsEstimated',
        enableColumnFilter: false
      },
      {
        header: 'Turns Approved',
        accessorKey: 'turnsApproved',
        enableColumnFilter: false
      },
      {
        header: 'Last Order',
        accessorKey: 'lastAssignmentDate',
        Cell: ({ cell }) => rooFmt.dateMaybe(cell.getValue<Date>(), 'N/A'),
        enableColumnFilter: false
      },
      {
        header: 'Total Invoiced',
        accessorKey: 'totalInvoiced',
        Cell: ({ cell }) => rooFmt.moneyMaybe(cell.getValue<number>(), 'N/A'),
        enableColumnFilter: false
      },
      {
        header: 'Account Manager',
        id: 'clientConfiguration.accountManager.fullName',
        accessorFn: (x) => x.clientConfiguration?.accountManager?.fullName,
        enableColumnFilter: true
      },
      {
        id: 'notes',
        header: 'Notes',
        columnDefType: 'display',
        Cell: ({ row }) => (
          <NotesButton
            entityId={row.original.company.id}
            entityType={NoteEntityType.ManagementCompany}
            noteSource={NoteSource.ClientsList}
            title={'Client Notes'}
          />
        )
      },
      {
        id: 'issue-notice',
        header: 'Notice',
        columnDefType: 'display',
        Cell: ({ row }) => (
          <Stack direction={'row'} justifyContent={'center'} alignItems={'center'} spacing={2}>
            <AddIssueNoticeButton
              key={`${row.id}-notice`}
              companyId={row.original.company.id}
              currentNotice={row.original.clientConfiguration.issueNotice}
            />
          </Stack>
        )
      }
    ],
    []
  );

  return (
    <MaterialReactTable
      columns={columns}
      enablePagination={false}
      data={data ?? []}
      state={{ density: 'compact' }}
      tableInstanceRef={tableRef}
      initialState={{
        sorting: [
          {
            id: 'totalInvoiced',
            desc: true
          }
        ]
      }}
      muiTableContainerProps={{
        ref: tableContainerRef,
        sx: { maxHeight: `${height}px` }
      }}
      enableGlobalFilter={false}
      enableStickyHeader={true}
      enableHiding={false}
      enableColumnDragging={false}
      enableFullScreenToggle={false}
      enableDensityToggle={false}
      enableTopToolbar={false}
    />
  );
};

const CopyRowsButton = ({ tableRef }: { tableRef: MutableRefObject<MRT_TableInstance<VendorClientModel>> }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const handleCopyClick = async () => {
    const text = unparse(
      tableRef.current.getPrePaginationRowModel().rows.map((x) => ({
        Company: x.original.company.name,
        City: x.original.company.address.city,
        State: x.original.company.address.state,
        Issues: x.original.issueCount,
        LastOrder: rooFmt.dateMaybe(x.original.lastAssignmentDate, 'N/A'),
        TotalInvoiced: x.original.totalInvoiced,
        AccountManager: x.original.clientConfiguration.accountManager?.fullName
      })),
      {
        delimiter: '\t',
        header: true,
        newline: '\r\n'
      }
    );

    try {
      await AppClipboard.copyText(text);
      setTooltipOpen(true);
      setTimeout(() => setTooltipOpen(false), 2000);
    } catch (error) {
      console.error('Copy failed', error);
    }
  };

  return (
    <Tooltip open={tooltipOpen} title="Copied!" leaveDelay={200}>
      <Button color={'info'} onClick={handleCopyClick}>
        Copy Rows
      </Button>
    </Tooltip>
  );
};

const ContactsButton = ({ company }: { company: ManagementCompany }) => {
  const disclosure = useDisclosure(false);
  return (
    <>
      <RooButton size={'sm'} onClick={disclosure.open} icon={'people-arrows'}>
        View
      </RooButton>
      <RooDialog maxWidth={'xl'} fullWidth open={disclosure.isOpen} onClose={disclosure.close}>
        <RooDialog.Title onClose={disclosure.close}>Contacts</RooDialog.Title>
        <RooDialog.Content>
          <ContactsTable companyId={company.id} noteSource={NoteSource.ClientsList} />
        </RooDialog.Content>
        <RooDialog.Actions>
          <RooButton variant={'primary'} onClick={disclosure.close}>
            Close
          </RooButton>
        </RooDialog.Actions>
      </RooDialog>
    </>
  );
};
