import { useCallback, useMemo, useState } from "react";

export type Disclosure = {
  isOpen: boolean;
  open: () => void;
  close: () => void;
  toggle: () => void;
};

export const useDisclosure = (
  initialState = false,
  callbacks?: { onOpen?(): void; onClose?(): void },
): Disclosure => {
  const { onOpen, onClose } = callbacks || {};
  const [opened, setOpened] = useState(initialState);

  const open = useCallback(() => {
    setOpened((isOpened) => {
      if (!isOpened) {
        onOpen?.();
        return true;
      }
      return isOpened;
    });
  }, [onOpen]);

  const close = useCallback(() => {
    setOpened((isOpened) => {
      if (isOpened) {
        onClose?.();
        return false;
      }
      return isOpened;
    });
  }, [onClose]);

  const toggle = useCallback(() => {
    const fn = opened ? close : open;
    fn();
  }, [close, open, opened]);

  return useMemo(
    () => ({
      isOpen: opened,
      open,
      close,
      toggle,
    }),
    [opened, open, close, toggle],
  );
};
