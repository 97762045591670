import { Form } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { apiProvider } from 'shared/api/apiProvider';
import {
  CreatePersonModel,
  CreateTenantModel,
  ModifyAddressPayload,
  Property,
  UpdatePropertyPayload
} from 'shared/api/clients';
import { useRooForm } from 'components/form/utils';
import { redirect, Routes } from 'shared/routing';
import { useAppStore, useCurrentUser } from 'shared/store';
import { ContentWrapper, MessageBig, NavSlim } from 'components';
import { rooDate, rooFmt, showSuccess } from 'shared/utils';
import { FormDefinition, FormSchema } from 'pages/Properties/forms';
import { PropertyForm, PropertyFormDefaultButtons } from 'pages/Properties/PropertyForm';
import { useParams } from 'react-router';
import { useGetPropertyQuery } from '../../shared/api/queries';
import { CenteredLoader } from '../../components/CenteredLoader';
import { useQueryClient } from '@tanstack/react-query';
import React from 'react';

export const PropertyEdit = () => {
  const { id } = useParams<{ id: string }>();
  const { isLoading, data } = useGetPropertyQuery(id);

  return (
    <>
      {!isLoading && data != null && <PropertyEditInner property={data} />}
      {!isLoading && data == null && (
        <MessageBig message={"This property does not exist or you're not allowed to view it"} />
      )}
      {isLoading && <CenteredLoader />}
    </>
  );
};

const PropertyEditInner = ({ property }: { property: Property }) => {
  const { handleSubmit, formState, control, setValue, watch } = useRooForm(FormSchema, {
    defaultValues: {
      name: property.name,
      maintenanceLimit: property.maintenanceLimit,
      managerId: property.manager?.id,
      managementCompanyId: property.managementCompany?.id,

      hasPool: property.hasPool,
      gateCode: property.gateCode,
      isOccupied: property.isOccupied,
      bathroomNo: property.bathroomNumber,
      bedroomNo: property.bedroomNumber,

      lotSize: property.lotSize,
      propertyArea: property.propertyArea,

      tenants: property.tenants.map((x) => ({
        userId: x.user.id,
        lastName: x.user.lastName,
        firstName: x.user.firstName,
        phone: x.user.contactInfo.phoneNumber,
        email: x.user.contactInfo.email,
        moveInDate: rooFmt.dateFromInstant(x.moveInDate),
        moveOutDate: rooFmt.dateFromInstant(x.moveOutDate)
      })),
      address: property.address,
      owner:
        property.owner == null
          ? null
          : {
              email: property.owner.contactInfo.email,
              phone: property.owner.contactInfo.phoneNumber,
              firstName: property.owner.firstName,
              lastName: property.owner.lastName,
              userId: property.owner.id
            }
    }
  });
  const user = useCurrentUser();
  const qc = useQueryClient();

  const save = async (values: FormDefinition) => {
    try {
      const updated = await apiProvider.propertiesClient.update(
        new UpdatePropertyPayload({
          id: property.id,
          name: values.name,
          maintenanceLimit: values.maintenanceLimit,
          isOccupied: values.isOccupied,
          address: new ModifyAddressPayload({
            address1: values.address.address1,
            address2: values.address.address2,
            city: values.address.city,
            state: values.address.state,
            zipCode: values.address.zipCode
          }),
          bathroomNumber: values.bathroomNo,
          bedroomNumber: values.bedroomNo,
          gateCode: values.gateCode,
          hasPool: values.hasPool,
          lotSize: values.lotSize,
          propertyArea: values.propertyArea,
          managementCompanyId: user.managementCompany.id,
          managerId: values.managerId,
          owner:
            values.owner == null
              ? null
              : new CreatePersonModel({
                  email: values.owner.email,
                  phone: values.owner.phone,
                  firstName: values.owner.firstName,
                  lastName: values.owner.lastName,
                  userId: values.owner.userId
                }),
          tenants: values.tenants.map(
            (x) =>
              new CreateTenantModel({
                firstName: x.firstName,
                lastName: x.lastName,
                phone: x.phone,
                email: x.email,
                userId: x.userId,
                moveInDate: rooDate.makeInstant(x.moveInDate),
                moveOutDate: rooDate.makeInstant(x.moveOutDate)
              })
          )
        })
      );
      await qc.invalidateQueries();
      redirect({ pathname: Routes.PropertyView, params: { id: property.id } });
      showSuccess();
    } catch (ex) {}
  };

  return (
    <>
      <Helmet>
        <title>{`${property.name} - Properties - Walkthroo`}</title>
      </Helmet>

      <NavSlim
        breadcrumbs={[
          { text: 'Property List', destination: Routes.PropertyList },
          { text: property.name, destination: { pathname: Routes.PropertyView, params: { id: property.id } } },
          { text: 'Edit' }
        ]}
      />

      <ContentWrapper>
        <Form onSubmit={handleSubmit(save)}>
          <PropertyForm
            renderButtons={({ control }) => (
              <PropertyFormDefaultButtons
                control={control}
                returnUrl={{ pathname: Routes.PropertyView, params: { id: property.id } }}
              />
            )}
            control={control}
            formState={formState}
            isEditing={true}
            setValue={setValue}
            watch={watch}
          />
        </Form>
      </ContentWrapper>
    </>
  );
};
