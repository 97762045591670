import React, { useState } from 'react';
import { apiProvider } from 'shared/api/apiProvider';
import { IssueAction, SkipEstimationPayload } from 'shared/api/clients';
import { ConfirmationModal } from 'components';
import { showSuccess } from 'shared/utils';
import { useCurrentIssue } from 'pages/Workorders/shared/CurrentIssueContext';
import { createModalStore } from '../../../../../shared/store';
import { issueActionList } from '../../../shared/types';
import { MuiIcon } from '../../../../../shared/icons';

const useSkipEstimationStore = createModalStore();
export const SkipEstimationModal = () => {
  const { issue, onIssueUpdate } = useCurrentIssue();
  const [comments, setComments] = useState<string>('');
  const [isSaving, setIsSaving] = useState(false);
  const isVisible = useSkipEstimationStore((x) => x.visible);
  const hide = useSkipEstimationStore((x) => x.actions.hide);

  const save = async () => {
    setIsSaving(true);
    try {
      const result = await apiProvider.issues.estimates.skipEstimation(
        new SkipEstimationPayload({
          issueId: issue.id,
          notes: comments?.trim() === '' ? null : comments
        })
      );
      onIssueUpdate(result);
      showSuccess();
    } catch (e) {}

    setIsSaving(false);
    hide();
  };

  return (
    <>
      <ConfirmationModal
        visible={isVisible}
        running={isSaving}
        title={'Confirm Skip Estimation'}
        question={
          <>
            Are you sure you want to skip providing an estimate?
            <div className={'mt-4 form-group'}>
              <label htmlFor={'notes-input'}>Comments</label>
              <textarea
                value={comments}
                onChange={(e) => setComments(e.target.value)}
                className={'form-control'}
                id={'notes-input'}
                rows={3}
                placeholder={'Your comments'}
              />
            </div>
          </>
        }
        onClose={hide}
        onSave={() => {
          void save();
        }}
      />
    </>
  );
};

export const skipEstimationAction = issueActionList.register({
  Icon: MuiIcon.FastForward,
  color: 'warning',
  isDisabled: () => false,
  key: 'skip-estimation',
  label: 'Skip Estimation',
  isVisible: (ctx) => ctx.canAct(IssueAction.SkipEstimation),
  onClick: () => {
    useSkipEstimationStore.getState().actions.show();
  },
  modalToRender: <SkipEstimationModal />
});
