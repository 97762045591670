import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, styled } from '@mui/material';
import { ReactNode } from 'react';
import { MuiIcon } from '../shared/icons';

const Main = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

const Content = styled(DialogContent)(({ theme }) => ({}));
const Actions = styled(DialogActions)(({ theme }) => ({}));

export interface DialogTitleProps {
  id?: string;
  children?: ReactNode;
  onClose?: () => void;
}

const Title = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <MuiIcon.Close />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export const RooDialog = Object.assign(Main, {
  Content: Content,
  Actions: Actions,
  Title: Title
});
