import React, { ReactNode } from 'react';
import { Button } from 'react-bootstrap';
import { UseFieldArrayRemove } from 'react-hook-form';
import { RooIcon } from 'shared/icons';

export const LineContainer = ({
  children,
  line,
  index
}: {
  children: ReactNode;
  index: number;
  line: { id: string };
}) => {
  return (
    <div
      key={line.id}
      style={{
        backgroundColor: index % 2 === 0 ? 'rgba(0,0,0,.05)' : null,
        padding: '10px'
      }}
      className={'row pb-xs-4 pb-md-0 flex-md-nowrap'}
    >
      {children}
    </div>
  );
};

export const LineItemHeader = ({
  index,
  remove,
  canEdit
}: {
  index: number;
  remove: UseFieldArrayRemove;
  canEdit: boolean;
}) => {
  return (
    <>
      <div
        className={'d-none d-md-block col-auto'}
        style={{ borderRight: '1px solid #b2b2b2', marginRight: '20px', marginBottom: '12px' }}
      >
        <p style={{ marginTop: '35px', paddingRight: '15px', paddingLeft: '5px' }}>{index + 1}</p>
      </div>

      <div className={'d-block d-md-none col-12 '}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '18px',
            marginTop: '12px'
          }}
        >
          <strong>Line #{index + 1}</strong>
          <Button
            disabled={!canEdit}
            size={'sm'}
            variant={'danger'}
            onClick={() => {
              remove(index);
            }}
          >
            <RooIcon icon={['fas', 'trash']} /> Delete
          </Button>
        </div>
      </div>
    </>
  );
};

export const LineRemoveButton = ({
  index,
  remove,
  canEdit
}: {
  index: number;
  remove: UseFieldArrayRemove;
  canEdit: boolean;
}) => {
  return (
    <div className={'d-none d-md-block col-auto'} style={{ paddingLeft: '30px' }}>
      <Button
        disabled={!canEdit}
        style={{ marginTop: '35px' }}
        size={'sm'}
        variant={'danger'}
        onClick={() => {
          remove(index);
        }}
      >
        <RooIcon icon={['fas', 'trash']} />
      </Button>
    </div>
  );
};
