import { Box } from '@mui/material';

export interface ContentWrapperProps {
  className?: string;
  children?: React.ReactNode;
}

export const ContentWrapper = (props: ContentWrapperProps) => {
  const { children, className, ...other } = props;

  return (
    // leave enough padding at the bottom to make sure the feedback widget doesn't interfere with buttons
    <Box className={className} sx={{ py: { xs: 0, lg: 4 }, px: { xs: 1, lg: 3 }, pb: { xs: '90px' } }} {...other}>
      {children}
    </Box>
  );
};
