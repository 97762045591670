import { PayableInvoiceInfo, PayInvoicePayload } from '../../shared/api/clients';
import { showSuccess } from '../../shared/utils';
import React, { useState } from 'react';
import { apiProvider } from '../../shared/api/apiProvider';
import { ConfirmationModal, RooButton } from '../../components';
import { InvoiceInfo } from '../Workorders/ViewIssue/Documents/Invoices/InvoiceInfoButton';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Disclosure, useDisclosure } from '@roo/lib';

export const ReportingPayInvoiceButton = ({ row }: { row: PayableInvoiceInfo }) => {
  const disclosure = useDisclosure(false);
  return (
    <>
      <RooButton onClick={disclosure.open} variant={'success'}>
        Paid
      </RooButton>
      <ReportingPayInvoiceModal row={row} modal={disclosure} />
    </>
  );
};

export const ReportingPayInvoiceModal = ({ row, modal }: { row: PayableInvoiceInfo; modal: Disclosure }) => {
  const [comments, setComments] = useState<string>('');
  const queryClient = useQueryClient();
  const payMutation = useMutation({
    mutationFn: () =>
      apiProvider.issues.invoices.payInvoiceReporting(
        new PayInvoicePayload({
          invoiceId: row.invoice.id,
          issueId: row.issue.id,
          notes: comments
        })
      ),
    onSuccess: async () => {
      await queryClient.invalidateQueries(['reporting']);
      modal.close();
      showSuccess();
    }
  });

  return (
    <>
      <ConfirmationModal
        size={'xl'}
        question={
          <>
            <p className={'mb-4'}>Do you want to mark this Invoice as Paid?</p>
            <InvoiceInfo invoice={row.invoice} />
            <div className={'mt-4 form-group'}>
              <label htmlFor={'notes-input'}>Notes (optional)</label>
              <textarea
                value={comments}
                onChange={(e) => setComments(e.target.value)}
                className={'form-control'}
                id={'notes-input'}
                rows={3}
                placeholder={'Payment notes'}
              />
            </div>
          </>
        }
        visible={modal.isOpen}
        onClose={modal.close}
        onSave={() => payMutation.mutate()}
        running={payMutation.isLoading}
      />
    </>
  );
};
