import { Box, Stack } from '@mui/material';
import { LoaderSmall } from 'components';
import { StatCard } from 'components/StatCard';
import { sortBy, uniq } from 'lodash';
import * as React from 'react';
import { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import { DashboardSection, DescribedDashboardStat } from 'shared/api/clients';
import { rooFmt } from 'shared/utils';
import { DashboardContext } from './utils';

export const GlobalSummaryCard = ({ stats, loading }: { stats: DescribedDashboardStat[]; loading: boolean }) => {
  const { setStat, statIsLoading, currentStat } = useContext(DashboardContext);
  const globalStats = sortBy(
    stats.filter((x) => x.section === DashboardSection.Global),
    (x) => x.order
  );
  return (
    <Stack>
      <h3 style={{ fontWeight: 400 }}>
        Work Order Status Summary as of{' '}
        <span style={{ fontSize: '16px', color: '#676A84' }}>{rooFmt.date(new Date())}</span>
      </h3>
      {loading && <LoaderSmall />}
      {!loading && (
        <Box
          sx={{
            display: 'grid',
            gridAutoRows: '1fr',
            gridTemplateColumns: 'repeat(auto-fit, minmax(180px, 1fr));',
            gap: '20px',
            p: '8px',
            pt: 0
          }}
        >
          {globalStats.map((stat) => (
            <StatCard
              key={stat.category}
              stat={stat}
              onClick={() => setStat(stat, true)}
              isLoading={statIsLoading && currentStat === stat}
              isSelected={currentStat === stat}
            />
          ))}
        </Box>
      )}
    </Stack>
  );
};

const getSectionName = (current: DashboardSection, all: DashboardSection[]) => {
  if (current === DashboardSection.ContractorManager) {
    return 'WPS - Subissue Status';
  }
  if (current === DashboardSection.ContractorVendor) {
    return 'WPS - Issue Status';
  }
  if (all.length === 1) {
    return 'Issue Status';
  }

  if (current === DashboardSection.Owner) {
    return 'Issue Status - Owner';
  }
  if (current === DashboardSection.Vendor) {
    return 'Issue Status - Vendor';
  }
  if (current === DashboardSection.Manager) {
    return 'Issue Status - Manager';
  }
  if (current === DashboardSection.Tenant) {
    return 'Issue Status - Tenant';
  }

  return '';
};

export const NewStatsArea = ({ stats, loading }: { stats: DescribedDashboardStat[]; loading: boolean }) => {
  if (loading) {
    return (
      <Row className={'p-4'}>
        <Col>
          <LoaderSmall />
        </Col>
      </Row>
    );
  }

  const sections = uniq(
    stats.filter((x) => x.section !== DashboardSection.Global && x.value !== 0).map((x) => x.section)
  );

  return (
    <>
      {sections.map((section) => {
        return (
          <Box key={section.toString()}>
            <h3 style={{ fontWeight: 400, marginTop: '24px', marginBottom: '10px' }}>
              {getSectionName(section, sections)}
            </h3>
            <Box sx={{ backgroundColor: 'white' }}>
              <StatList section={section} issueStats={stats} />
            </Box>
          </Box>
        );
      })}
    </>
  );
};

export const StatList = ({
  section,
  issueStats
}: {
  section: DashboardSection;
  issueStats: DescribedDashboardStat[];
}) => {
  const { setStat, statIsLoading, currentStat } = useContext(DashboardContext);
  const myStats = sortBy(
    issueStats.filter((x) => x.section === section && x.value !== 0),
    (x) => x.order
  );

  if (myStats.length === 0) {
    return (
      <Box sx={{ fontSize: '1.5em', textAlign: 'center' }} p={3}>
        No action needed! Go check your Instagram.
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: 'grid',
        gridAutoRows: '1fr',
        gridTemplateColumns: 'repeat(auto-fit, minmax(180px, 1fr));'
      }}
    >
      {myStats.map((stat) => (
        <StatCard
          key={stat.category}
          stat={stat}
          onClick={() => setStat(stat, true)}
          isLoading={statIsLoading && currentStat === stat}
          isSelected={currentStat === stat}
        />
      ))}
    </Box>
  );
};
