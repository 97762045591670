import { ChangeRequest } from '../../../../../shared/api/clients';
import React, { useState } from 'react';
import { RooButton, RooDialog } from '../../../../../components';
import { Button, Col, Row } from 'react-bootstrap';
import { ChangeRequestDetails } from './ChangeRequestDetails';

export const ChangeRequestInfoButton = ({ changeRequest }: { changeRequest: ChangeRequest }) => {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <RooButton icon={'eye'} variant={'primary'} onClick={() => setShowModal(true)} />
      <RooDialog open={showModal} onClose={() => setShowModal(false)}>
        <RooDialog.Title>Change Request Order</RooDialog.Title>
        <RooDialog.Content>
          <Row>
            <Col xl={12}>
              <ChangeRequestDetails changeRequest={changeRequest} />
            </Col>
          </Row>
        </RooDialog.Content>
        <RooDialog.Actions>
          <Button variant={'outline-primary'} onClick={() => setShowModal(false)}>
            Close
          </Button>
        </RooDialog.Actions>
      </RooDialog>
    </>
  );
};
