import { LoaderBig, MessageBig, NavSlim } from 'components';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router';
import { apiProvider } from 'shared/api/apiProvider';
import { IssueRole } from 'shared/api/clients';
import { Routes } from 'shared/routing';
import { CompareBidsContent } from './CompareBidsContent';
import { useQuery } from '@tanstack/react-query';

export const BidCompare = () => {
  const { workorderId, issueId } = useParams<{ workorderId: string; issueId: string }>();
  const defaultsQuery = useQuery(['issue', issueId, 'comparison-defaults'], () =>
    apiProvider.issues.bids.getBidComparison(issueId, false)
  );
  const issueQuery = useQuery(['issue', issueId, 'user-view'], () => apiProvider.issues.main.getForUser(issueId));
  const issueView = issueQuery.data;

  if (issueQuery.isLoading || defaultsQuery.isLoading) {
    return <LoaderBig />;
  }

  if (issueView.issue == null) {
    return <MessageBig message={"This issue does not exist or you're not allowed to view it"} />;
  }

  return (
    <>
      <Helmet>
        <title>{issueView.issue.friendlyId} - Issue Details - Walkthroo</title>
      </Helmet>
      <NavSlim
        breadcrumbs={[
          {
            text: issueView.workorder.friendlyId,
            destination: { pathname: Routes.WorkorderView, params: { id: issueView.workorder.id } }
          },
          issueView.parentIssue != null &&
          (issueView.userRole === IssueRole.MainPropertyManager ||
            issueView.userRole === IssueRole.AltPropertyManager ||
            issueView.userRole === IssueRole.AdminViewer)
            ? {
                text: issueView.parentIssue.friendlyId,
                destination: {
                  pathname: Routes.IssueView,
                  params: { workorderId: issueView.workorder.id, issueId: issueView.parentIssue.id }
                }
              }
            : null,
          {
            text: issueView.issue.friendlyId,
            destination: {
              pathname: Routes.IssueView,
              params: { workorderId: issueView.workorder.id, issueId: issueView.issue.id }
            }
          },
          {
            text: 'Bid Compare',
            destination: {
              pathname: Routes.IssueBidCompare,
              params: { workorderId: issueView.workorder.id, issueId: issueView.issue.id }
            }
          }
        ]}
      />

      <CompareBidsContent comparisonData={defaultsQuery.data} workorderId={workorderId} issueId={issueId} />
    </>
  );
};
