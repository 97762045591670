import { Box, ClickAwayListener, IconButton, Grid } from '@mui/material';
import { ReactComponent as PropertyIcon } from 'images/static/property.svg';
import { ReactComponent as Search } from 'images/static/search-icon.svg';
import { ReactComponent as TenantIcon } from 'images/static/tenant.svg';
import { ReactComponent as VendorIcon } from 'images/static/vendor.svg';
import { escapeRegExp } from 'lodash';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
// @ts-ignore
import AsyncSelect, { Props as SelectProps } from 'react-select/async';
import { apiProvider } from 'shared/api/apiProvider';
import { buildPath, Routes } from 'shared/routing';
import { cls } from 'shared/utils';

import { useWindowDimensions } from 'shared/utils/hooks';
import { debouncePromise } from 'shared/utils';
import styles from 'components/SearchBar.module.scss';
import { MuiIcon } from '../shared/icons';
import { isMobile } from '../shared/utils/device';
import { usePreparedTimeout } from '@roo/lib';

const DropdownIndicator = () => {
  return (
    <Box sx={{ padding: '0 0.5rem' }}>
      <Search />
    </Box>
  );
};

const HighLightableText = ({
  children,
  highlight,
  className
}: {
  children: string;
  highlight: string;
  className?: string;
}) => {
  const escapedHighlight = escapeRegExp(highlight);
  const parts = children.split(new RegExp(`(${escapedHighlight})`, 'gi'));
  return (
    <p className={cls(styles.listText, className)}>
      {parts.map((part, index) => (
        <span key={index}>
          {part.toLowerCase() === highlight.toLowerCase() ? <span className={styles.highlight}>{part}</span> : part}
        </span>
      ))}
    </p>
  );
};

const GroupHeading = (props: any) => {
  return (
    <Grid container alignItems="center" marginY={'2px'}>
      <Grid item xs={2} display="flex" justifyContent="center" alignItems="center">
        {props.children}
      </Grid>
      <Grid item xs={7} display="flex" justifyContent="center" alignItems="center">
        <div style={{ height: '1px', backgroundColor: 'gray', width: '100%' }} />
      </Grid>
      <Grid
        item
        xs={3}
        display="flex"
        component={'p'}
        className={styles.resultsCount}
        justifyContent="center"
        alignItems="center"
      >
        {props.data.options.length} RESULTS
      </Grid>
    </Grid>
  );
};

const search = async (inputValue: string) => {
  try {
    if (inputValue == null || inputValue.trim() === '') {
      return [];
    }

    const result = await apiProvider.searchClient.generalSearch(inputValue);

    return [
      {
        label: <PropertyIcon title="Properties" />,
        options:
          result?.properties?.map?.((property) => ({
            label: <HighLightableText highlight={inputValue}>{property.name}</HighLightableText>,
            value: buildPath(Routes.PropertyView, { id: property.id })
          })) ?? []
      },
      {
        label: <TenantIcon title="Tenants" />,
        options:
          result?.tenants?.map?.((tenant) => {
            return {
              label: <HighLightableText highlight={inputValue}>{tenant.fullName}</HighLightableText>,
              value: buildPath(Routes.UserProfile, { id: tenant.id })
            };
          }) ?? []
      },
      {
        label: <VendorIcon title="Vendors" />,
        options:
          result?.vendors?.map?.((vendor) => ({
            label: <HighLightableText highlight={inputValue}>{vendor.companyName}</HighLightableText>,
            value: buildPath(Routes.UserProfile, { id: vendor.users[0].id })
          })) ?? []
      },
      {
        label: <MuiIcon.LibraryBooks titleAccess="Workorders" />,
        options:
          result?.workorders?.map?.((workorder) => {
            const issueNames = workorder.issues
              .filter((issue) => issue.title)
              .map((issue) => (
                <HighLightableText className={styles.indent} highlight={inputValue}>
                  {issue.title}
                </HighLightableText>
              ));

            return {
              label: (
                <>
                  <HighLightableText highlight={inputValue}>
                    {`${workorder.friendlyId} ${workorder.property.displayAddress}`}
                  </HighLightableText>
                  {issueNames}
                </>
              ),
              value: buildPath(Routes.WorkorderView, { id: workorder.id })
            };
          }) ?? []
      }
    ];
  } catch (e) {}
};
const debouncedSearch = debouncePromise(async (query) => await search(query), 300);

export function SearchBar() {
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [mobile, setMobile] = useState(false);
  const [, setExiting] = useState(false);
  const windowDimensions = useWindowDimensions();
  const isMobileDevice = isMobile();

  useEffect(() => {
    if (windowDimensions.width >= 600) {
      setMobile(false);
    } else if (windowDimensions.width < 600) {
      setMobile(true);
    }
  }, [windowDimensions]);

  const onExit = usePreparedTimeout(() => {
    setShow(false);
    setExiting(false);
  }, 400);

  const onClickAway = () => {
    setExiting(true);
    onExit();
  };

  function onSelect(data: SelectProps<any, false>) {
    history.push(data.value);
  }

  const searchBar = (
    <Box sx={{ minWidth: '300px', maxWidth: '300px', px: 1 }} position="relative">
      <AsyncSelect<SelectProps<any, false>>
        placeholder="Type to search"
        loadOptions={debouncedSearch as any}
        name="search"
        autoFocus={!isMobileDevice}
        value={null}
        menuPortalTarget={document.body}
        onChange={onSelect}
        styles={{
          indicatorSeparator: (styles: any) => ({ ...styles, width: '0' }),
          control: (styles: any) => ({ ...styles, borderRadius: '0px' }),
          menuPortal: (styles) => ({ ...styles, zIndex: 9999 })
        }}
        className={styles.searchBarIn}
        components={{ GroupHeading, DropdownIndicator }}
      />
    </Box>
  );

  if (!mobile) return searchBar;
  return show ? (
    <ClickAwayListener onClickAway={onClickAway}>{searchBar}</ClickAwayListener>
  ) : (
    <Box display="flex" justifyContent="flex-end">
      <IconButton onClick={() => setShow(true)} style={{ marginRight: '1px' }}>
        <Search />
      </IconButton>
    </Box>
  );
}
