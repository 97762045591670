import { useRequestGlobalModal, useWatchGlobalModal } from './modalStore';
import React, { useState } from 'react';
import { apiProvider } from '../../shared/api/apiProvider';
import { RooDialog } from '../RooDialog';
import { GetIssueIdFromNumberResult } from '../../shared/api/clients';
import { Alert, Box, Button, TextField, Typography } from '@mui/material';
import { redirect, Routes } from '../../shared/routing';

export const GoToIssueModal = () => {
  const [identifier, setIdentifier] = useState<string>();
  const [{ isLoading, result, isError }, setState] = useState<{
    isLoading: boolean;
    result: GetIssueIdFromNumberResult;
    isError: boolean;
  }>({
    isLoading: false,
    result: null,
    isError: false
  });
  const { isVisible, toggle } = useWatchGlobalModal('goToIssueModal');
  const isNotFound = !isLoading && isError && result == null;
  const [, toggleGoToIssueModal] = useRequestGlobalModal('goToIssueModal');

  const onSetIdentifier = (val: string) => {
    setIdentifier(val);
    setState((s) => ({ ...s, isError: false }));
  };

  const onNavigate = async () => {
    setState({
      isError: false,
      result: null,
      isLoading: true
    });
    try {
      const result = await apiProvider.adminClient.getIssueIdFromNumber(identifier);
      if (result != null) {
        redirect({ pathname: Routes.IssueView, params: { workorderId: result.workorderId, issueId: result.issueId } });
        toggleGoToIssueModal();
      }
      setState({
        isLoading: false,
        isError: result == null,
        result: result
      });
      setIdentifier(null);
    } catch (e) {
      setState({
        isLoading: false,
        isError: true,
        result: null
      });
    }
  };

  return (
    <>
      <RooDialog maxWidth={'sm'} fullWidth open={isVisible} onClose={toggle}>
        <RooDialog.Title>Go to Issue</RooDialog.Title>
        <RooDialog.Content>
          <form
            noValidate
            onSubmit={(e) => {
              e.preventDefault();
              void onNavigate();
            }}
          >
            <Typography variant={'h6'} mb={2}>
              Use an issue number to navigate to any <code>Issue</code> in Walkthroo.
            </Typography>
            <Typography>If the target issue is not in your company, you will be shown a read-only version.</Typography>
            <TextField
              autoFocus
              disabled={isLoading}
              sx={{ mt: 2 }}
              margin={'normal'}
              className={'Input-field'}
              value={identifier}
              onChange={(e) => onSetIdentifier(e.target.value)}
              helperText={'e.g. ISS12345-01'}
              fullWidth
              label={'Issue Number'}
            />
            {isNotFound && (
              <Box sx={{ textAlign: 'center', mt: 2 }}>
                <Alert severity={'error'}>We couldn't find an issue matching that number.</Alert>
              </Box>
            )}
          </form>
        </RooDialog.Content>
        <RooDialog.Actions>
          <Button disabled={isLoading} onClick={() => onNavigate()}>
            Go
          </Button>
        </RooDialog.Actions>
      </RooDialog>
    </>
  );
};
