import { Alert, Box, Stack, Typography } from '@mui/material';
import { ConfirmationModal, RooButton } from 'components';
import { RooRating } from 'components/RooRating';
import { useCurrentIssue } from 'pages/Workorders/shared/CurrentIssueContext';
import { useState } from 'react';
import { apiProvider } from 'shared/api/apiProvider';
import { MarkIssuePaidPayload } from 'shared/api/clients';
import { showSuccess } from 'shared/utils';

export const MarkPaidButton = () => {
  const { issue, onIssueUpdate, visibleInvoices } = useCurrentIssue();
  const [isConfirming, setIsConfirming] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [notes, setNotes] = useState<string>('');
  const [rating, setRating] = useState<number>(null);
  const canSubmit = issue.vendor == null || rating != null;

  const save = async () => {
    setIsSaving(true);
    try {
      const result = await apiProvider.issues.workflow.markPaid(
        new MarkIssuePaidPayload({
          issueId: issue.id,
          oldStatus: issue.status,
          notes: notes?.trim() === '' ? null : notes,
          rating: rating
        })
      );
      onIssueUpdate(result);
      showSuccess();
    } catch (e) {}

    setIsSaving(false);
    setIsConfirming(false);
  };

  var outstanding = 0;
  visibleInvoices.forEach((val) => {
    outstanding += val.outstanding;
  });

  return (
    <>
      <RooButton onClick={() => setIsConfirming(true)} variant={'success'} icon={'money-bill'}>
        Paid
      </RooButton>
      <ConfirmationModal
        visible={isConfirming}
        running={isSaving}
        title={'Do you want to mark this issue as paid?'}
        question={
          <>
            <Stack spacing={2} direction={'column'} justifyContent={'center'} alignItems={'center'}>
              {outstanding > 0 && (
                <Alert severity="warning">There are outstanding invoices. These will be marked as paid.</Alert>
              )}
              {issue.vendor != null && (
                <>
                  <Typography>
                    Before you do, how would you rate <strong>{issue.vendor.companyName}</strong>?
                  </Typography>
                  <RooRating size={'large'} value={rating} onChange={(val) => setRating(val)} />
                </>
              )}
              <Box sx={{ width: '100%' }}>
                <label htmlFor={'notes-input'}>Notes</label>
                <textarea
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                  className={'form-control'}
                  id={'notes-input'}
                  rows={3}
                  placeholder={'Your comments'}
                />
              </Box>
            </Stack>
          </>
        }
        confirmDisabled={!canSubmit}
        onClose={() => setIsConfirming(false)}
        onSave={() => {
          void save();
        }}
      />
    </>
  );
};
