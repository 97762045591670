import { IconName } from '@fortawesome/free-regular-svg-icons';
import { Button, ButtonProps, IconButton, Link, LinkProps } from '@mui/material';
import { RooIcon } from 'shared/icons';
import { ComponentProps, forwardRef, ReactNode } from 'react';
import { prepareRouterHrefAttrs, RouteDestination } from 'shared/routing';

export type RouterLinkProps = Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'href'> & {
  to: RouteDestination;
  after?: () => void;
};

type RouterButtonProps = { to: RouteDestination } & ComponentProps<typeof Button> & RouterLinkProps;

export const RouterButton = ({ to, ...props }: RouterButtonProps) => {
  // TODO: fix later
  // @ts-ignore
  return <Button component={RouterLink} to={to} {...props} />;
};

type RouterIconButtonProps = { to: RouteDestination } & ComponentProps<typeof IconButton> & RouterLinkProps;

export const RouterIconButton = ({ to, ...props }: RouterIconButtonProps) => {
  // TODO: fix later
  // @ts-ignore
  return <IconButton component={RouterLink} to={to} {...props} />;
};

// Used for links to other react pages. Needed as otherwise regular links will cause a full page refresh.
export const RouterLink = forwardRef(({ to, after = null, ...props }: RouterLinkProps, ref) => {
  // it's the caller's responsibility
  // eslint-disable-next-line jsx-a11y/anchor-has-content
  return <a {...props} {...prepareRouterHrefAttrs(to, after)} />;
});

export const MuiRouterLink = forwardRef(
  (
    {
      to,
      after = null,
      ...props
    }: LinkProps & {
      to: RouteDestination;
      after?: () => void;
    },
    ref
  ) => {
    // it's the caller's responsibility
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    return <Link {...props} {...prepareRouterHrefAttrs(to, after)} />;
  }
);

export const MuiRouterButton = ({
  to,
  after,
  ...props
}: Omit<ButtonProps<'a', { component: 'a' }>, 'href' | 'component'> & { to: RouteDestination; after?: () => void }) => {
  return <Button component={'a'} {...props} {...prepareRouterHrefAttrs(to, after)} />;
};

export type IconLinkProps = Omit<RouterLinkProps, 'children'> & {
  text: ReactNode;
  icon?: IconName;
};

export const IconLink = ({ to, text, icon = 'link', ...props }: IconLinkProps) => {
  return (
    <RouterLink to={to} {...props}>
      <RooIcon icon={['fas', icon]} /> {text}
    </RouterLink>
  );
};
