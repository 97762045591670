import { Alert } from 'react-bootstrap';
import { CandidacyStatus, IssueRole, IssueStatus, ScheduleStatus } from 'shared/api/clients';
import { useCurrentUser } from 'shared/store';
import { useCurrentIssue } from 'pages/Workorders/shared/CurrentIssueContext';

export const IssueCallToAction = () => {
  const { userRole } = useCurrentIssue();
  return (
    <div className={'callToAction'}>
      {userRole === IssueRole.VendorCandidate && <CandidateCallToAction />}
      {userRole === IssueRole.Vendor && <VendorCallToAction />}
      {userRole === IssueRole.Tenant && <TenantCallToAction />}
      {userRole === IssueRole.AdminViewer && <AdminViewerAction />}
    </div>
  );
};

const VendorCallToAction = () => {
  const { issue } = useCurrentIssue();
  return (
    <>
      {issue.status === IssueStatus.VendorScheduling &&
        issue.schedule != null &&
        issue.schedule.status === ScheduleStatus.Rejected && (
          <Alert variant={'danger'}>
            <h5>A tenant has declined your schedule proposal</h5>
            {issue.schedule.reviewerNotes && (
              <>
                <p>
                  Check the tenant's notes below, and if the suggested dates work for you, hit the “Schedule” button and
                  confirm the date and time.
                </p>
                <hr />
                <p className="mb-0">{issue.schedule.reviewerNotes}</p>
              </>
            )}
          </Alert>
        )}
    </>
  );
};

const CandidateCallToAction = () => {
  const currentUser = useCurrentUser();
  const { issue } = useCurrentIssue();
  const myCandidacy = issue.vendorCandidates.find((x) => x.vendor.userId === currentUser.id);
  if (myCandidacy == null || myCandidacy.status !== CandidacyStatus.Invited) {
    return null;
  }

  return (
    <Alert variant={'warning'}>
      Multiple vendors are assigned to this issue. Be the first to accept it to guarantee that you get it.
    </Alert>
  );
};

const TenantCallToAction = () => {
  const { issue } = useCurrentIssue();
  return (
    <>
      {issue.status === IssueStatus.VendorScheduling &&
        issue.schedule != null &&
        issue.schedule.status === ScheduleStatus.PendingReview && (
          <Alert variant={'warning'}>
            The vendor proposed a schedule for his upcoming visit. Review it to indicate your availability.
          </Alert>
        )}
    </>
  );
};

const AdminViewerAction = () => {
  return (
    <>
      <Alert variant={'warning'}>
        This issue is outside of your company. You are able to view it because you are a Walkthroo admin.
      </Alert>
    </>
  );
};
