import { apiProvider } from 'shared/api/apiProvider';
import { IssueRole } from 'shared/api/clients';
import { RooButton, RooDialog, UserLink } from 'components';
import { useCurrentIssue } from 'pages/Workorders/shared/CurrentIssueContext';
import { useQuery } from '@tanstack/react-query';
import { Alert, Button, CircularProgress, Stack, Typography } from '@mui/material';
import { FileThumbnail } from '../../../components/Files/FileThumbnail';
import React from 'react';
import { useToggle } from '@roo/lib';

export const ViewMessageButton = ({ activityId }: { activityId: string }) => {
  const [isVisible, toggleIsVisible] = useToggle();
  const { userRole } = useCurrentIssue();

  if (
    userRole !== IssueRole.MainPropertyManager &&
    userRole !== IssueRole.AltPropertyManager &&
    userRole !== IssueRole.AdminViewer
  ) {
    return null;
  }

  if (activityId == null) {
    return null;
  }
  return (
    <>
      <RooButton variant={'link'} onClick={toggleIsVisible}>
        View Message
      </RooButton>
      <RooDialog onClose={toggleIsVisible} fullWidth maxWidth={'sm'} open={isVisible}>
        <RooDialog.Title onClose={toggleIsVisible}>Message Details</RooDialog.Title>
        <RooDialog.Content>
          <ViewMessageBody activityId={activityId} />
        </RooDialog.Content>
        <RooDialog.Actions>
          <Button
            variant={'contained'}
            className={'Button Button-default'}
            color={'secondary'}
            onClick={toggleIsVisible}
          >
            Close
          </Button>
        </RooDialog.Actions>
      </RooDialog>
    </>
  );
};

const ViewMessageBody = ({ activityId }: { activityId: string }) => {
  const { status, data: message } = useQuery(['message-from-activity', activityId], () =>
    apiProvider.messagesClient.getFromActivityLog(activityId)
  );

  return (
    <>
      {status === 'loading' && <CircularProgress />}
      {status === 'success' && message != null && (
        <Stack spacing={2}>
          <Stack direction={'row'} spacing={2}>
            <Stack>
              <Typography fontWeight={'bold'}>From</Typography>
              <UserLink user={message.from} role={null} />
            </Stack>
            <Stack>
              <Typography fontWeight={'bold'}>To</Typography>
              <UserLink user={message.to} role={null} />
            </Stack>
          </Stack>
          {message.files.length > 0 && (
            <Stack spacing={1}>
              <Typography fontWeight={'bold'}>Attachments</Typography>
              <Stack direction={'row'} spacing={2}>
                {message.files.map((x) => (
                  <FileThumbnail key={x.id} size={64} file={x} />
                ))}
              </Stack>
            </Stack>
          )}
          {message.body && (
            <Stack spacing={1}>
              <Typography fontWeight={'bold'}>Message</Typography>
              <pre style={{ marginBottom: 0, whiteSpace: 'pre-wrap' }}>{message.body}</pre>
            </Stack>
          )}
        </Stack>
      )}
      {(status === 'error' || message == null) && (
        <Alert severity={'warning'}>Something went wrong. We can't show you this message right now.</Alert>
      )}
    </>
  );
};
