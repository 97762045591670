import { Button, Card, CardActions, CardContent, Grid, Link, Typography } from '@mui/material';
import React from 'react';
import { Helmet } from 'react-helmet';
import { LegalLinks } from 'shared/routing';
import { ContentWrapper, NavSlim } from 'components';
import { MuiIcon } from '../../shared/icons';

export const Legal = () => {
  return (
    <>
      <Helmet>
        <title>Legal Agreements - Walkthroo</title>
      </Helmet>
      <NavSlim breadcrumbs={[{ text: 'Legal Agreements' }]} />
      <ContentWrapper>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} xl={4}>
            <LegalCard
              title={'Terms and Conditions'}
              description={'Our Terms and Conditions Agreement'}
              url={LegalLinks.TermsAndConditions}
            />
          </Grid>
          <Grid item xs={12} md={6} xl={4}>
            <LegalCard title={'Terms of Use'} description={'Our Terms of Use Agreement'} url={LegalLinks.TermsOfUse} />
          </Grid>
          <Grid item xs={12} md={6} xl={4}>
            <LegalCard title={'Privacy Policy'} description={'Our Privacy Policy'} url={LegalLinks.Privacy} />
          </Grid>
          <Grid item xs={12} md={6} xl={4}>
            <LegalCard title={'Cookies Policy'} description={'Our Cookies Policy'} url={LegalLinks.Cookies} />
          </Grid>
          <Grid item xs={12} md={6} xl={4}>
            <LegalCard
              title={'Website Accesibility Statement'}
              description={'Our Website Accesibility Statement'}
              url={LegalLinks.Accessibility}
            />
          </Grid>
        </Grid>
      </ContentWrapper>
    </>
  );
};

const LegalCard = ({ title, description, url }: { title: string; description: string; url: string }) => {
  return (
    <Card sx={{ p: 3 }}>
      <CardContent>
        <Typography gutterBottom variant={'h4'}>
          {title}
        </Typography>
        <Typography variant={'body2'} color={'text.secondary'}>
          {description}
        </Typography>
      </CardContent>
      <CardActions>
        <Button color="primary" startIcon={<MuiIcon.Outbound />} component={Link} target={'_blank'} href={url}>
          Read
        </Button>
      </CardActions>
    </Card>
  );
};
