import { CandidacyStatus } from 'shared/api/clients';
import { BasicEnumDisplay, createMap, EnumMap } from 'shared/store/enums/shared';

const InternalMapCandidacyStatus: EnumMap<typeof CandidacyStatus, BasicEnumDisplay> = {
  Invited: {
    display: 'Invited'
  },
  Declined: {
    display: 'Declined'
  },
  NoLongerAvailable: {
    display: 'No Longer Available'
  },
  Expired: {
    display: 'Expired'
  },
  Accepted: {
    display: 'Accepted'
  }
};

export const EnumMapCandidacyStatus = createMap<CandidacyStatus, BasicEnumDisplay>(
  CandidacyStatus,
  InternalMapCandidacyStatus
);
