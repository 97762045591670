import { RooIcon } from 'shared/icons';
import { useState } from 'react';
import { Card } from 'react-bootstrap';
import { IssueStatus, VendorCandidate } from 'shared/api/clients';
import { rooEnum } from 'shared/store';
import { useCurrentIssue } from 'pages/Workorders/shared/CurrentIssueContext';
import { InviteVendorsModal } from 'pages/Workorders/ViewIssue/InviteVendors/InviteVendorsModal';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

export const InvitationList = () => {
  const { issue } = useCurrentIssue();
  const [showModal, setShowModal] = useState(false);
  return (
    <Card>
      <Card.Header>
        Multiple Vendor Assignments{' '}
        {issue.status === IssueStatus.AssignedToMultipleVendors && (
          <button onClick={() => setShowModal(true)} className={'btn btn-primary btn-sm float-end slim'}>
            <RooIcon icon={['fas', 'plus']} /> Add
          </button>
        )}
      </Card.Header>
      <Card.Body>
        <DataTable value={issue.vendorCandidates}>
          <Column sortable field={'vendor.companyName'} header={'Vendor'} />
          <Column
            sortable
            field={'status'}
            header={'Status'}
            body={(row: VendorCandidate) => rooEnum.candidacyStatus.display(row.status)}
          />
        </DataTable>
      </Card.Body>
      <InviteVendorsModal isVisible={showModal} setIsModalVisible={setShowModal} />
    </Card>
  );
};
