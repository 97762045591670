export const makeFileUrls = (baseUrl: string) => {
  const base = baseUrl == null ?? baseUrl.trim() === "" ? "" : baseUrl;

  const build = (
    fileId: string,
    builder: (base: string, fileId: string) => string,
  ) => {
    if (fileId == null || fileId.trim() === "") {
      return null;
    }

    return builder(base, fileId);
  };

  return {
    thumbnail: (fileId: string) =>
      build(fileId, (base, id) => `${base}/files/thumbnail?id=${id}`),
    serve: (fileId: string) =>
      build(fileId, (base, id) => `${base}/files/serve?id=${id}`),
    download: (fileId: string) =>
      build(fileId, (base, id) => `${base}/files/download?id=${id}`),
  };
};
