import { CapabilityType } from 'shared/api/clients';
import { create } from 'zustand';
import { useCallback, useRef } from 'react';
import { immer } from 'zustand/middleware/immer';

const defaults = {
  visible: false,
  onSuccess: () => {}
};

const defaultModalState = {
  inviteUser: {
    ...defaults,
    props: {
      capability: CapabilityType.Unknown
    }
  },
  propertyHelp: {
    ...defaults
  },
  vendorBulkImport: {
    ...defaults
  },
  propertyBulkImport: {
    ...defaults
  },
  buildiumImport: {
    ...defaults
  },
  addVendorMember: {
    ...defaults
  },
  priceGuide: {
    ...defaults
  },
  makeReady: {
    ...defaults
  },
  chatDrawer: {
    ...defaults,
    props: {
      conversationIds: null as string[]
    }
  },
  appSidebar: {
    ...defaults
  },
  goToIssueModal: {
    ...defaults
  },
  impersonateModal: {
    ...defaults,
    props: {
      account: { userId: null as string, firstName: null as string, lastName: null as string },
      sendToLanding: false
    }
  }
};

type MapType = typeof defaultModalState;

export type GlobalModalCallbacks = { onSuccess?: () => void };
export type GlobalModalKey = keyof typeof defaultModalState;
export type GlobalModalProps = {
  [key in GlobalModalKey as MapType[key] extends { props: any } ? key : never]: MapType[key] extends { props: any }
    ? MapType[key]['props']
    : never;
};

//TODO: do something about this
const isMobile = () => window.innerWidth <= 1000;
defaultModalState.appSidebar.visible = !isMobile();

type StoreType = {
  modals: MapType;
  toggleGlobalModal: (key: GlobalModalKey, props?: unknown) => void;
  setModalCallbacks: (key: GlobalModalKey, callbacks: GlobalModalCallbacks) => void;
  afterNavigation: () => void;
};

export const useModalStore = create<StoreType>()(
  immer((set, get) => ({
    modals: defaultModalState,
    toggleGlobalModal: (key: GlobalModalKey, props?: unknown) => {
      set((curr) => {
        curr.modals[key].visible = !curr.modals[key].visible;
        (curr.modals[key] as any).props = props;
      });
    },
    setModalCallbacks: (key: GlobalModalKey, callbacks: GlobalModalCallbacks) => {
      set((curr) => {
        curr.modals[key].onSuccess = callbacks.onSuccess;
      });
    },
    afterNavigation: () => {
      if (!isMobile()) {
        return;
      }

      set((curr) => {
        curr.modals['appSidebar'].visible = false;
      });
    }
  }))
);

type RequestModalRequest<T extends GlobalModalKey> = {
  props: GlobalModalProps extends { [K in T]: any } ? GlobalModalProps[T] : never;
  onSuccess?: () => void;
};

export const useRequestGlobalModal = <T extends GlobalModalKey>(key: T, settings?: RequestModalRequest<T>) => {
  const isVisible = useModalStore((x) => x.modals[key].visible);
  const toggleStore = useModalStore((x) => x.toggleGlobalModal);
  const setCallbacks = useModalStore((x) => x.setModalCallbacks);

  const propsRef = useRef(settings?.props);
  propsRef.current = settings?.props;

  const successRef = useRef(settings?.onSuccess);
  successRef.current = settings?.onSuccess;

  const toggle = useCallback(() => {
    setCallbacks(key, {
      onSuccess: successRef.current
    });
    toggleStore(key, propsRef.current);
  }, [key, toggleStore, setCallbacks]);

  return [isVisible, toggle] as const;
};

type WatchModalResult<T extends GlobalModalKey> = {
  isVisible: boolean;
  toggle: () => void;
  props: GlobalModalProps extends { [K in T]: any } ? GlobalModalProps[T] : never;
  callbacks: GlobalModalCallbacks;
};

export const useWatchGlobalModal = <T extends GlobalModalKey>(key: T): WatchModalResult<T> => {
  const isVisible = useModalStore((x) => x.modals[key].visible);
  const props = useModalStore((x) => (x.modals[key] as any).props);
  const toggleStore = useModalStore((x) => x.toggleGlobalModal);
  const onSuccess = useModalStore((x) => x.modals[key].onSuccess);

  const toggle = useCallback(() => {
    toggleStore(key, props);
  }, [key, toggleStore, props]);

  return {
    isVisible,
    toggle,
    props,
    callbacks: {
      onSuccess
    }
  };
};
