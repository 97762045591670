import { useCompanyContext } from '../../shared/store';
import { NoteEntityType, NoteSource } from '../../shared/api/clients';
import { LoaderSmall, RooButton, RooDialog } from '../index';
import { useNotesQuery } from '../../shared/api/queries';
import React from 'react';
import { Alert, Badge, CircularProgress, IconButton, Stack } from '@mui/material';
import { Center } from '../Center';
import { InternalNoteList } from './InternalNote';
import { AddNoteForm } from './AddNoteForm';
import { MuiIcon } from '../../shared/icons';
import { Disclosure, useDisclosure } from '@roo/lib';

export const NotesButton = ({
  entityType,
  entityId,
  title,
  noteSource
}: {
  entityType: NoteEntityType;
  entityId: string;
  title: string;
  noteSource: NoteSource;
}) => {
  const companyCtx = useCompanyContext();
  if (companyCtx == null) {
    return null;
  }

  return <NotesButtonInternal entityId={entityId} entityType={entityType} title={title} noteSource={noteSource} />;
};

const NotesButtonInternal = ({
  entityType,
  entityId,
  title,
  noteSource
}: {
  entityType: NoteEntityType;
  entityId: string;
  title: string;
  noteSource: NoteSource;
}) => {
  const disclosure = useDisclosure(false);
  const { isLoading, data } = useNotesQuery({
    entityId: entityId,
    entityType: entityType
  });

  return (
    <>
      <IconButton color={'primary'} disabled={isLoading} onClick={disclosure.open}>
        {isLoading && <CircularProgress size={24} />}
        {!isLoading && data.length > 0 && (
          <Badge badgeContent={data.length} color={'info'}>
            <MuiIcon.Comment />
          </Badge>
        )}
        {!isLoading && data.length === 0 && <MuiIcon.Comment />}
      </IconButton>
      <NotesViewerModal
        disclosure={disclosure}
        entityId={entityId}
        entityType={entityType}
        title={title}
        noteSource={noteSource}
      />
    </>
  );
};

const NotesViewerModal = ({
  disclosure,
  entityType,
  entityId,
  title,
  noteSource
}: {
  disclosure: Disclosure;
  entityType: NoteEntityType;
  entityId: string;
  title: string;
  noteSource: NoteSource;
}) => {
  const { isLoading, data } = useNotesQuery({
    entityId: entityId,
    entityType: entityType
  });

  return (
    <RooDialog maxWidth={'sm'} fullWidth open={disclosure.isOpen} onClose={disclosure.close}>
      <RooDialog.Title onClose={disclosure.close}>{title}</RooDialog.Title>
      <RooDialog.Content>
        {isLoading && <LoaderSmall />}
        {!isLoading && (
          <Stack spacing={2}>
            <AddNoteForm entityId={entityId} entityType={entityType} noteSource={noteSource} />
            {data.length === 0 && (
              <Center>
                <Alert color={'info'}>No existing notes yet. Add your own!</Alert>
              </Center>
            )}
            <InternalNoteList notes={data} />
          </Stack>
        )}
      </RooDialog.Content>
      <RooDialog.Actions>
        <RooDialog.Actions>
          <RooButton variant={'primary'} onClick={disclosure.close}>
            Close
          </RooButton>
        </RooDialog.Actions>
      </RooDialog.Actions>
    </RooDialog>
  );
};
