import { concat, round } from 'lodash';
import { IAttachmentFile } from 'shared/api/clients';

type FileType = Omit<IAttachmentFile, 'urls'>;
type LineType = { beforeFiles: FileType[]; afterFiles?: FileType[] };

export const lineFiles = (line: LineType) => {
  return concat(line.beforeFiles, line.afterFiles ?? []);
};

export const DEFAULT_MARKUP_PERCENT = 20;
export const calculateMarkup = (original: number, markup: number) => {
  if (original == null || markup == null) {
    return null;
  }

  return round(original + original * (markup / 100), 2);
};
