import { Card, CardContent, CardHeader } from '@mui/material';
import { RooButton } from 'components';
import { IssuesServerGrid, useIssuesGrid } from 'components/Issues/IssuesServerGrid';
import * as React from 'react';
import { useCallback, useContext, useEffect, useRef } from 'react';
import { DescribedDashboardStat } from 'shared/api/clients';
import { DashboardContext } from './utils';

export const ConditionalIssuesArea = ({
  companyId,
  stat,
  vendorIds,
  stateCode,
  city,
  accountManagerId
}: {
  companyId: string;
  stat: DescribedDashboardStat;
  vendorIds: string[];
  stateCode: string;
  city: string;
  accountManagerId: string;
}) => {
  return (
    <>
      {stat == null && (
        <IssuesArea
          companyId={companyId}
          vendorIds={vendorIds}
          stateCode={stateCode}
          city={city}
          accountManagerId={accountManagerId}
        />
      )}
      {stat != null && (
        <FilteredIssuesArea
          companyId={companyId}
          stat={stat}
          vendorIds={vendorIds}
          stateCode={stateCode}
          city={city}
          accountManagerId={accountManagerId}
        />
      )}
    </>
  );
};

export const IssuesArea = ({
  companyId,
  vendorIds,
  stateCode,
  city,
  accountManagerId
}: {
  companyId: string;
  vendorIds: string[];
  stateCode: string;
  city: string;
  accountManagerId: string;
}) => {
  const activeIssGrid = useIssuesGrid({
    forceExpanded: false,
    externalFilters: {
      isActive: true,
      companyIds: companyId == null ? null : [companyId],
      vendorIds: vendorIds,
      city: city,
      stateCodes: stateCode == null ? null : [stateCode],
      accountManagerId: accountManagerId
    }
  });
  const inactiveIssGrid = useIssuesGrid({
    forceExpanded: false,
    externalFilters: {
      isActive: false,
      companyIds: companyId == null ? null : [companyId],
      vendorIds: vendorIds,
      city: city,
      stateCodes: stateCode == null ? null : [stateCode],
      accountManagerId: accountManagerId
    }
  });

  const activeRefresh = activeIssGrid.refresh;
  const inactiveRefresh = inactiveIssGrid.refresh;

  useEffect(() => {
    void activeRefresh();
    void inactiveRefresh();
  }, [activeRefresh, inactiveRefresh, companyId, vendorIds, stateCode, city, accountManagerId]);

  return (
    <>
      <Card>
        <CardHeader title={'Active Issues'} />
        <CardContent>
          <IssuesServerGrid
            state={activeIssGrid}
            forceLinksNewWindow={false}
            showStatAmount={false}
            showSubtotal={false}
            showCompany={false}
          />
        </CardContent>
      </Card>
      <Card>
        <CardHeader title={'Closed Issues (last 30d)'} />
        <CardContent>
          <IssuesServerGrid
            state={inactiveIssGrid}
            forceLinksNewWindow={false}
            showStatAmount={false}
            showSubtotal={false}
            showCompany={false}
          />
        </CardContent>
      </Card>
    </>
  );
};

export const FilteredIssuesArea = ({
  stat,
  companyId,
  vendorIds,
  stateCode,
  city,
  accountManagerId
}: {
  stat: DescribedDashboardStat;
  companyId: string;
  vendorIds: string[];
  stateCode: string;
  city: string;
  accountManagerId: string;
}) => {
  const { setStat, setIsLoading } = useContext(DashboardContext);
  const containerRef = useRef<any>();
  const scrollToGrid = useCallback(() => {
    if (containerRef?.current) {
      containerRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, []);

  const grid = useIssuesGrid({
    forceExpanded: true,
    externalFilters: {
      forCategory: stat.category,
      companyIds: companyId == null ? null : [companyId],
      vendorIds: vendorIds,
      city: city,
      stateCodes: stateCode == null ? null : [stateCode],
      accountManagerId: accountManagerId
    },
    onInitialLoad: () => scrollToGrid(),
    onLoad: () => setIsLoading(false)
  });
  const refresh = grid.refresh;

  useEffect(() => {
    const run = async () => {
      await refresh();
      scrollToGrid();
    };

    void run();
  }, [stat, refresh, scrollToGrid]);

  useEffect(() => {
    void refresh();
  }, [refresh, companyId, vendorIds, stateCode, city, accountManagerId]);

  return (
    <Card ref={containerRef}>
      <CardHeader
        title={`Showing only: ${stat.displayName}`}
        action={
          <RooButton onClick={() => setStat(null, false)} variant={'link'} icon={'undo'}>
            View All
          </RooButton>
        }
      />
      <CardContent>
        <IssuesServerGrid
          state={grid}
          forceLinksNewWindow={false}
          showStatAmount={false}
          showSubtotal={false}
          showCompany={false}
        />
      </CardContent>
    </Card>
  );
};
