import { Button, Divider, Grid } from '@mui/material';
import { apiProvider } from 'shared/api/apiProvider';
import { UpdateProfilePreferencesPayload, User } from 'shared/api/clients';
import { FieldMuiSwitch, useRooForm } from 'components/form';
import { useAppStore } from 'shared/store';
import { z } from 'zod';

export const PreferencesUserSchema = z.object({
  receiveEmail: z.boolean(),
  receiveText: z.boolean()
});
type FormDefinition = z.infer<typeof PreferencesUserSchema>;

export const PreferencesSection = ({ onUpdate }: { onUpdate: (updated: User) => void }) => {
  const currentUser = useAppStore((x) => x.userDetails);
  const { control, handleSubmit } = useRooForm(PreferencesUserSchema, {
    defaultValues: {
      receiveEmail: currentUser.receiveEmail,
      receiveText: currentUser.receiveText
    }
  });

  const handleSaveProfile = async (values: FormDefinition) => {
    try {
      const updated = await apiProvider.profileClient.updatePreferences(
        new UpdateProfilePreferencesPayload({
          receiveEmail: values.receiveEmail,
          receiveText: values.receiveText
        })
      );
      onUpdate(updated);
    } catch (e) {}
  };

  return (
    <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ mt: 0, p: 3, mb: 4 }} spacing={4}>
      <Grid item xs={12}>
        <h5 style={{ color: 'black' }}>
          <strong>Communication</strong>
        </h5>
        <Divider />
      </Grid>
      <Grid item xs={12} md={6}>
        <h6>Receive Emails Regarding Walkthroo?</h6>
        <FieldMuiSwitch control={control} name={'receiveEmail'} color="primary" />
      </Grid>
      <Grid item xs={12} md={6}>
        <h6>Receive Texts Regarding Walkthroo?</h6>
        <FieldMuiSwitch control={control} name={'receiveText'} color="primary" />
      </Grid>
      <Grid container direction="row" justifyContent="flex-end" alignItems="center">
        <Button
          size={'large'}
          type="submit"
          sx={{ mt: 2, mb: 2, width: 200 }}
          onClick={handleSubmit(handleSaveProfile)}
        >
          Save
        </Button>
      </Grid>
    </Grid>
  );
};
