import { Helmet } from 'react-helmet';
import { ContentWrapper, LoaderSmall, NavSlim } from '../../components';
import { PaymentsNav } from './PaymentsNav';
import {
  DashboardStatCategory,
  GetProfitLossPayload,
  Instant,
  ManagementCompany,
  ProfitLossStatement,
  Vendor
} from '../../shared/api/clients';
import React, { useState } from 'react';
import { apiProvider } from '../../shared/api/apiProvider';
import { rooDate, rooFmt } from '../../shared/utils';
import { Autocomplete, Card, CardContent, createFilterOptions, Stack, TextField, Typography } from '@mui/material';
import { IssueListButton } from './IssueListButton';
import { DateRangeChips, makeDefaultChipInterval, SelectedDateRange } from '../../components/DateRangeChips';
import { useQuery } from '@tanstack/react-query';
import {
  DropdownOption,
  useAccountManagersOptions,
  useAppStore,
  useCurrentUser,
  useCurrentVendor,
  useStateOptions
} from '../../shared/store';
import { orderBy } from 'lodash';
import { useMyCompaniesForDropdownMaterial } from '../../shared/api/queries';
import { StateCompanyFilter } from './CompanyFilter';

const filterOptions = createFilterOptions<DropdownOption<string>>({
  stringify: (val) => val.label + '|' + val.value
});

export const ProfitLoss = () => {
  const hideReports = useAppStore((x) => x.hideReports);
  const [dateSelection, setDateSelection] = useState<SelectedDateRange>(makeDefaultChipInterval('mtd'));
  const [states, setStates] = useState<DropdownOption<string>[]>([]);
  const [vendors, setVendors] = useState<Vendor[]>([]);
  const [companies, setCompanies] = useState<ManagementCompany[]>([]);
  const [acctManager, setAcctManager] = useState<DropdownOption<string>>(null);

  const stateCodes = states?.map((x) => x.value) ?? [];
  const vendorIds = vendors?.map((x) => x.id) ?? [];
  const companyIds = companies?.map((x) => x.id) ?? [];
  const acctManagerId = acctManager?.value;

  const { isLoading, data } = useQuery(
    [
      'reporting',
      'p&l',
      dateSelection.startDate,
      dateSelection.endDate,
      stateCodes,
      vendorIds,
      companyIds,
      acctManagerId
    ],
    () =>
      apiProvider.issues.reports.getProfitLoss(
        new GetProfitLossPayload({
          startDate: rooDate.makeInstant(dateSelection.startDate),
          endDate: rooDate.makeInstant(dateSelection.endDate),
          stateCodes,
          vendorIds,
          companyIds,
          accountManagerId: acctManagerId
        })
      ),
    {
      staleTime: 1 * 60 * 1000
    }
  );

  return (
    <>
      <Helmet>
        <title>P&L - Walkthroo</title>
      </Helmet>
      <NavSlim breadcrumbs={[{ text: 'Profit & Loss' }]} />
      <ContentWrapper className="container-property-list no-transparent">
        <PaymentsNav value={3} />
        <Stack mt={3} gap={3}>
          <Stack gap={2} direction={'row'} justifyContent={'center'} alignItems={'center'} flexWrap={'wrap'}>
            <StateFilter value={states} setValue={setStates} />
            <StateCompanyFilter value={companies} setValue={setCompanies} />
            <VendorFilter value={vendors} setValue={setVendors} />
            <AccountManagerFilter value={acctManager} setValue={setAcctManager} />
            <DateRangeChips value={dateSelection} setValue={setDateSelection} />
          </Stack>

          {isLoading && (
            <div className={'text-center p-4'}>
              <LoaderSmall />
            </div>
          )}
          {!isLoading && !hideReports && (
            <Stack spacing={2} alignItems={'center'} justifyContent={'center'}>
              <ProfitLossStatementDisplay statement={data} />
              <ProjectionsDisplay statement={data} />
            </Stack>
          )}
        </Stack>
      </ContentWrapper>
    </>
  );
};

const VendorFilter = ({ value, setValue }: { value: Vendor[]; setValue: (val: Vendor[]) => void }) => {
  const currentUser = useCurrentUser();
  const { isLoading: vendorsIsLoading, data: vendorsData } = useQuery(
    ['vendors'],
    () => apiProvider.vendorClient.vendorsForUser(currentUser.id),
    {
      select: (data) => orderBy(data, (x) => x.companyName)
    }
  );
  return (
    <Autocomplete
      loading={vendorsIsLoading}
      multiple={true}
      getOptionLabel={(option) => (option as any).companyName}
      value={value}
      onChange={(_, val) => setValue(val as Vendor[])}
      sx={{ minWidth: '300px', marginLeft: '10px' }}
      renderInput={(params) => <TextField variant={'standard'} {...params} size={'small'} label="Choose vendors" />}
      options={vendorsData ?? []}
    />
  );
};

const AccountManagerFilter = ({
  value,
  setValue
}: {
  value: DropdownOption<string>;
  setValue: (val: DropdownOption<string>) => void;
}) => {
  const accountManagerOpts = useAccountManagersOptions();

  return (
    <Autocomplete
      multiple={false}
      value={value}
      onChange={(_, val) => setValue(val as DropdownOption<string>)}
      sx={{ minWidth: '300px', marginLeft: '10px' }}
      renderInput={(params) => (
        <TextField variant={'standard'} {...params} size={'small'} label="Choose acct manager" />
      )}
      options={accountManagerOpts ?? []}
    />
  );
};

const StateFilter = ({
  value,
  setValue
}: {
  value: DropdownOption<string>[];
  setValue: (val: DropdownOption<string>[]) => void;
}) => {
  const states = useStateOptions();
  return (
    <Autocomplete
      multiple={true}
      filterOptions={filterOptions}
      value={value}
      onChange={(ev, val) => setValue(val as DropdownOption<string>[])}
      sx={{ width: '200px', marginLeft: '10px' }}
      renderInput={(params) => <TextField variant={'standard'} {...params} size={'small'} label="Choose state" />}
      options={states ?? []}
    />
  );
};

const ProfitLossStatementDisplay = ({ statement }: { statement: ProfitLossStatement }) => {
  return (
    <Card>
      <CardContent>
        <Typography sx={{ textAlign: 'center' }} variant="h5" component="div">
          Profit & Loss
        </Typography>
        <DateRangeIndicator startDate={statement.startDate} endDate={statement.endDate} />
        <table style={{ minWidth: '400px' }}>
          <tr>
            <td>
              <Typography variant={'subtitle1'}>Income</Typography>
            </td>
            <td style={{ textAlign: 'right' }}>
              <IssueListButton
                modalTitle={'P&L: Income'}
                filters={{
                  forCategory: DashboardStatCategory.IssProfitLossIncome,
                  startDate: statement.startDate,
                  endDate: statement.endDate,
                  vendorIds: null,
                  subVendorIds: statement.vendorIds,
                  companyIds: statement.companyIds,
                  accountManagerId: statement.accountManagerId
                }}
              >
                {rooFmt.money(statement.assets)}
              </IssueListButton>
            </td>
          </tr>
          <tr>
            <td>
              <Typography variant={'subtitle1'}>Cost of Goods Sold</Typography>
            </td>
            <td style={{ textAlign: 'right' }}>
              <IssueListButton
                modalTitle={'P&L: Cost of Goods Sold'}
                filters={{
                  forCategory: DashboardStatCategory.IssProfitLossCost,
                  startDate: statement.startDate,
                  endDate: statement.endDate,
                  stateCodes: statement.stateCodes,
                  vendorIds: statement.vendorIds,
                  subVendorIds: null,
                  companyIds: statement.companyIds
                }}
              >
                {rooFmt.money(statement.liabilities)}
              </IssueListButton>
            </td>
          </tr>
          <tr style={{ borderTop: '1px solid lightgray' }}>
            <td style={{ paddingTop: '10px' }}>
              <Typography variant={'subtitle1'}>Net Income</Typography>
            </td>
            <td style={{ textAlign: 'right', paddingTop: '10px' }}>{rooFmt.money(statement.grandTotal)}</td>
          </tr>
        </table>
      </CardContent>
    </Card>
  );
};

const ProjectionsDisplay = ({ statement }: { statement: ProfitLossStatement }) => {
  return (
    <Card>
      <CardContent>
        <Typography sx={{ textAlign: 'center' }} variant="h5" component="div">
          Pending Owed Invoices
        </Typography>
        <DateRangeIndicator startDate={statement.startDate} endDate={statement.endDate} />
        <table style={{ minWidth: '400px' }}>
          <tr>
            <td>
              <Typography variant={'subtitle1'}>Invoices that need to be sent</Typography>
            </td>
            <td style={{ textAlign: 'right' }}>
              <IssueListButton
                modalTitle={'P&L: Invoices that need to be sent'}
                filters={{
                  forCategory: DashboardStatCategory.IssProfitLossPendingIncome,
                  startDate: statement.startDate,
                  endDate: statement.endDate,
                  stateCodes: statement.stateCodes,
                  vendorIds: null,
                  subVendorIds: statement.vendorIds,
                  companyIds: statement.companyIds
                }}
              >
                {rooFmt.money(statement.potentialAssets)}
              </IssueListButton>
            </td>
          </tr>
          <tr>
            <td>
              <Typography variant={'subtitle1'}>Outstanding owed needed invoices</Typography>
            </td>
            <td style={{ textAlign: 'right' }}>
              <IssueListButton
                modalTitle={'P&L: Outstanding owed needed invoices'}
                filters={{
                  forCategory: DashboardStatCategory.IssProfitLossPendingCost,
                  startDate: statement.startDate,
                  endDate: statement.endDate,
                  stateCodes: statement.stateCodes,
                  vendorIds: statement.vendorIds,
                  subVendorIds: null,
                  companyIds: statement.companyIds
                }}
              >
                {rooFmt.money(statement.potentialLiabilities)}
              </IssueListButton>
            </td>
          </tr>
        </table>
      </CardContent>
    </Card>
  );
};

const DateRangeIndicator = ({ startDate, endDate }: { startDate: Instant; endDate: Instant }) => {
  if (startDate == null && endDate == null) {
    return (
      <Typography sx={{ textAlign: 'right' }} gutterBottom variant="caption" component="div">
        All Time
      </Typography>
    );
  }
  return (
    <Typography sx={{ textAlign: 'right' }} gutterBottom variant="caption" component="div">
      {rooFmt.instantDate(startDate)} - {rooFmt.instantDate(endDate)}
    </Typography>
  );
};

// const CompaniesByStateFilter = ({}: { values: Company }) => {
//   const { isLoading, data } = useMyCompanies();
// };
