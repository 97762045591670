import { Box, ClickAwayListener, Grow, Paper, Popper } from '@mui/material';
import React, { ReactNode } from 'react';
import { IUserSummary } from 'shared/api/clients';
import { rooFmt } from 'shared/utils';
import { RooAvatar } from 'components/RooAvatar';
import { UserCard } from 'components/UserCard';

export const UserLink = ({
  user,
  role,
  text = null,
  short = false,
  hideAvatar = false
}: {
  user: IUserSummary;
  role: string;
  text?: ReactNode;
  short?: boolean;
  hideAvatar?: boolean;
}) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  if (text == null) {
    text = short ? rooFmt.abbrevName(user) : user.fullName;
  }

  const button = (
    <button ref={anchorRef} type="button" onClick={handleToggle} className={'btn btn-link inline'}>
      {!hideAvatar && <RooAvatar name={user.fullName} avatarUrl={user.avatarUrl} size={'xs'} />}
      <span style={{ marginLeft: hideAvatar ? null : '5px' }}>{text}</span>
    </button>
  );

  return (
    <Box component={'span'}>
      {button}
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} placement="bottom-start" transition>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom'
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <Box sx={{ maxWidth: '300px' }}>
                  <UserCard user={user} role={role} />
                </Box>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
};
