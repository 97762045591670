import { CancelIssueSchedulePayload, CapabilityType } from 'shared/api/clients';
import { useState } from 'react';
import { apiProvider } from 'shared/api/apiProvider';
import { showSuccess } from 'shared/utils';
import { RooButton } from 'components';
import { ConfirmationModal } from 'components';
import { useCurrentIssue } from 'pages/Workorders/shared/CurrentIssueContext';

export const TenantRescheduleButton = () => {
  const { issue, onIssueUpdate } = useCurrentIssue();
  const [comments, setComments] = useState<string>('');
  const [isSaving, setIsSaving] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);

  const save = async () => {
    setIsSaving(true);
    try {
      const result = await apiProvider.issues.scheduling.cancelSchedule(
        new CancelIssueSchedulePayload({
          issueId: issue.id,
          comments: comments?.trim() === '' ? null : comments,
          capabilityType: CapabilityType.Tenant
        })
      );
      onIssueUpdate(result);
      showSuccess();
    } catch (e) {}

    setIsSaving(false);
    setIsConfirming(false);
  };

  return (
    <>
      <RooButton onClick={() => setIsConfirming(true)} variant={'warning'} icon={'calendar-times'}>
        Reschedule
      </RooButton>
      <ConfirmationModal
        visible={isConfirming}
        running={isSaving}
        title={'Confirm Reschedule Request'}
        question={
          <>
            The Vendor will send you additional times/dates. <br />
            You can also message the Vendor directly via Walkthroo or call the Vendor.
            <div className={'mt-4 form-group'}>
              <label htmlFor={'notes-input'}>Comments</label>
              <textarea
                value={comments}
                onChange={(e) => setComments(e.target.value)}
                className={'form-control'}
                id={'notes-input'}
                rows={3}
                placeholder={'Your comments'}
              />
            </div>
          </>
        }
        onClose={() => setIsConfirming(false)}
        onSave={() => {
          void save();
        }}
      />
    </>
  );
};
