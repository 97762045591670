import { platformUtils } from '@roo/lib';
import { apiProvider } from './shared/api/apiProvider';

const hex: string[] = [];
for (let i = 0; i < 256; i++) {
  hex[i] = i.toString(16).padStart(2, '0');
}

export const setupPlatform = () => {
  platformUtils.set({
    generateUuid: () => {
      const r = crypto.getRandomValues(new Uint8Array(16));

      r[6] = (r[6] & 0x0f) | 0x40;
      r[8] = (r[8] & 0x3f) | 0x80;

      return (
        hex[r[0]] +
        hex[r[1]] +
        hex[r[2]] +
        hex[r[3]] +
        '-' +
        hex[r[4]] +
        hex[r[5]] +
        '-' +
        hex[r[6]] +
        hex[r[7]] +
        '-' +
        hex[r[8]] +
        hex[r[9]] +
        '-' +
        hex[r[10]] +
        hex[r[11]] +
        hex[r[12]] +
        hex[r[13]] +
        hex[r[14]] +
        hex[r[15]]
      );
    },
    logException: (e) => {},
    storage: {
      set: async () => {},
      get: async () => null
    },
    api: apiProvider
  });
};
