import React from 'react';
import Select, { Props as SelectProps } from 'react-select';
import { DropdownOption } from 'shared/store';

export const ReactSelectCommonStyles = {
  MuiLookAlike: {
    control: (styles: any) => ({
      ...styles,
      backgroundColor: '#F7F8FC',
      borderRadius: 0,
      minHeight: 56,
      borderColor: '#EAECF6'
    })
  }
};

export type RooSelectProps<TVal> = SelectProps<DropdownOption<TVal>> & {
  invalid?: boolean;
};

export const RooSelect = <TVal extends any>({ styles, invalid = false, ...rest }: RooSelectProps<TVal>) => {
  return (
    <Select
      menuPortalTarget={document.body}
      styles={{
        ...(styles ?? {}),
        control: (ctrlStyles, props) => {
          const myStyles = { ...ctrlStyles, borderColor: invalid ? 'red' : ctrlStyles.borderColor };
          return styles?.control == null ? myStyles : styles.control(myStyles, props);
        },
        menuPortal: (menuStyles, props) => {
          const myStyles = { ...menuStyles, zIndex: 9999 };
          return styles?.menuPortal == null ? myStyles : styles.menuPortal(myStyles, props);
        }
      }}
      {...rest}
    />
  );
};
