import { ChangeRequest, DocumentTotals } from 'shared/api/clients';
import { rooFmt } from 'shared/utils';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Stack } from '@mui/material';
import { LineTable } from '../LineTable';
import { DocumentViewTotals } from '../DocumentViewTotals';

export const ChangeRequestDetails = ({ changeRequest }: { changeRequest: ChangeRequest }) => {
  return (
    <>
      {!!changeRequest.reviewerNotes && (
        <Row>
          <Col>
            <dl>
              <dt>Reviewer Notes</dt>
              <dd>{changeRequest.reviewerNotes}</dd>
            </dl>
          </Col>
        </Row>
      )}
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 1, md: 4 }}>
        <dl style={{ marginBottom: 0 }}>
          <dt>Number</dt>
          <dd>{changeRequest.friendlyId ?? '-'}</dd>
        </dl>
        <dl style={{ marginBottom: 0 }}>
          <dt>Estimated Completion Date</dt>
          <dd>{rooFmt.instantDateMaybe(changeRequest.completionDate, '-')}</dd>
        </dl>
      </Stack>
      <Row>
        <Col>
          <LineTable lines={changeRequest.lines.map((x) => ({ ...x, notes: null, sourceEntityId: null, files: [] }))} />
        </Col>
      </Row>

      <div
        style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', flexGrow: 0, marginTop: '30px' }}
      >
        <DocumentViewTotals totals={changeRequest.totals} isAuthor={false} />
      </div>
    </>
  );
};
