import { zodResolver } from '@hookform/resolvers/zod';
import { ChangeEvent, Ref, RefObject } from 'react';
import { FieldError, useForm, UseFormProps, UseFormReturn } from 'react-hook-form';
import { classNames, DeepRequired } from 'shared/utils';
import { z } from 'zod';
import * as Sentry from '@sentry/react';

export const stringTransform = {
  input: (value: string) => {
    return value == null ? '' : value;
  },
  output: <T extends HTMLElement>(e: ChangeEvent<T>) => {
    const output = (e.target as any).value;
    return output === '' ? null : output;
  }
};

export const fieldClassNames = (className: string, error: FieldError) =>
  classNames('form-control', className, { 'is-invalid': error != null });

type RooFormProps<T> = Omit<UseFormProps<T>, 'resolver' | 'mode'> & {
  disableScrollOnError?: boolean;
  scrollContainer?: RefObject<HTMLElement>;
};

export const useRooForm = <T extends z.Schema<any, any>>(
  schema: T,
  opts?: RooFormProps<z.infer<T>>
): UseFormReturn<z.infer<T>> => {
  const formReturn = useForm<z.infer<T>>({
    resolver: zodResolver(schema),
    mode: 'all',
    ...(opts ?? {})
  });

  return {
    ...formReturn,
    handleSubmit: (onValid, onInvalid) =>
      formReturn.handleSubmit(onValid, (err, ev) => {
        setTimeout(() => {
          if (opts?.disableScrollOnError) {
            return;
          }

          try {
            const form = ev.target as HTMLElement;
            if (form == null || !form['querySelectorAll']) {
              return;
            }

            const err = form.querySelector('.form-control.is-invalid,.invalid-feedback,.Mui-error');
            if (err != null) {
              // Determine the scroll container
              let container: HTMLElement | null = opts?.scrollContainer?.current;
              if (!container) {
                container = err.closest('.MuiDialogContent-root') as HTMLElement;
              }
              if (!container) {
                // @ts-ignore
                container = document.querySelector('#main-anchor');
              }

              const errBounds = err.getBoundingClientRect();
              const containerBounds = container.getBoundingClientRect();
              const yOffset = container.scrollTop + errBounds.top - containerBounds.top - container.clientHeight / 2;

              container.scrollTop = yOffset;
            }
          } catch (e) {
            Sentry.captureException(e);
          }
        }, 50);

        return onInvalid?.(err, ev);
      })
  };
};

export const useRequiredRooForm = <T extends z.Schema<any, any>>(
  schema: T,
  opts?: RooFormProps<DeepRequired<z.infer<T>>>
): UseFormReturn<DeepRequired<z.infer<T>>> => {
  return useRooForm(schema, opts);
};
