import { z } from 'zod';
import { FieldMuiText, useRooForm } from '../../components/form';
import { apiProvider } from '../../shared/api/apiProvider';
import { Alert, Box, Button, CircularProgress, ThemeProvider, Typography } from '@mui/material';
import rooBlue from '../../images/roo-logo-blue.png';
import { AuthPage } from './AuthPage';
import { darkTheme } from '../../shared/theme';
import { useMutation } from '@tanstack/react-query';
import { ReactNode, useEffect } from 'react';
import { CenteredLoader } from '../../components/CenteredLoader';
import { RequestMagicLinkPayload, RequestMagicLinkResult } from '@roo/api';
import { useSearchParams } from '../../shared/utils';
import { Routes } from '../../shared/routing';

const FormSchemaMagicLink = z.object({
  email: z.string().email()
});

const redirect = (url: string) => {
  window.location.href = url;
};

type FormDefinitionMagicLink = z.infer<typeof FormSchemaMagicLink>;

export const LoginMagicLink = () => {
  const {
    searchParams: { returnUrl, email }
  } = useSearchParams<{ email: string; returnUrl: string }>();
  const { control, handleSubmit } = useRooForm(FormSchemaMagicLink, {
    defaultValues: {
      email: email
    }
  });

  const { data, status, mutate } = useMutation({
    mutationFn: (data: RequestMagicLinkPayload) => apiProvider.authClient.requestMagicLink(data)
  });

  return (
    <AuthPage
      title={'Log In - Walkthroo'}
      logo={
        <Box className={'brand'}>
          <img src={rooBlue} alt="" />
        </Box>
      }
    >
      <WithSuccess success={status === 'success' && data === RequestMagicLinkResult.Success}>
        <CurrentUserChecker returnUrl={null}>
          <form
            noValidate
            onSubmit={handleSubmit((data: FormDefinitionMagicLink) =>
              mutate(
                new RequestMagicLinkPayload({
                  email: data.email,
                  returnUrl: returnUrl
                })
              )
            )}
          >
            <Box className="d-flex flex-column align-items-center">
              <Typography variant="h5" component="div" gutterBottom sx={{ fontWeight: 700 }}>
                Login
              </Typography>
              <Typography variant="subtitle1" component="div" gutterBottom>
                Use your e-mail to log in to Walkthroo
              </Typography>
            </Box>
            <ThemeProvider theme={darkTheme}>
              <FieldMuiText
                control={control}
                className={'Input-dark'}
                name={'email'}
                label={'Email'}
                type={'email'}
                autoComplete={'email'}
                placeholder={'Enter your Email'}
                fullWidth
                required
              />
            </ThemeProvider>

            <Button type="submit" size={'largest'} fullWidth sx={{ mt: 2, mb: 2 }}>
              {status === 'loading' ? <CircularProgress style={{ width: 31, height: 31 }} color="inherit" /> : 'Log In'}
            </Button>
            <Box sx={{ mt: 3 }}>
              {status === 'success' && (
                <>
                  {data === RequestMagicLinkResult.AccountDisabled && (
                    <Alert severity="error">This account is disabled.</Alert>
                  )}
                  {data === RequestMagicLinkResult.EmailNotFound && (
                    <Alert severity="error">This e-mail is not a registered account.</Alert>
                  )}
                  {data === RequestMagicLinkResult.InvalidEmail && (
                    <Alert severity="error">The e-mail address you entered is not valid.</Alert>
                  )}
                </>
              )}
            </Box>
          </form>
        </CurrentUserChecker>
      </WithSuccess>
    </AuthPage>
  );
};

const CurrentUserChecker = ({ returnUrl, children }: { returnUrl: string; children: ReactNode }) => {
  const { isLoading, mutate } = useMutation({
    mutationFn: () => apiProvider.authClient.getCurrentUser(),
    onSuccess: (data) => {
      if (data != null) {
        redirect(returnUrl ?? Routes.Dashboard);
      }
    }
  });

  useEffect(() => {
    mutate();
  }, []);

  if (isLoading) {
    return <CenteredLoader />;
  }

  return children;
};

const WithSuccess = ({ success, children }: { success: boolean; children: ReactNode }) => {
  if (!success) {
    return children;
  }

  return (
    <Alert severity={'info'}>An e-mail has been sent to the address you entered. Use the link inside to log in.</Alert>
  );
};
