import { ContentWrapper } from 'components';
import { Card, CardActionArea, CardContent, CardMedia, Container, Stack, Typography } from '@mui/material';
import { redirect, RouteDestination, Routes } from '../../shared/routing';
import { ReactNode } from 'react';
import { SvgIconComponent } from '@mui/icons-material';
import { MuiIcon } from '../../shared/icons';

import { Grid, Box } from '@mui/material';

export const DevLandingPage = () => {
  const pages: { route: RouteDestination; title: ReactNode; Icon: SvgIconComponent }[] = [
    {
      route: Routes.DevFormTest,
      title: 'Forms',
      Icon: MuiIcon.TextFields
    },
    {
      route: Routes.DevButtonsDemo,
      title: 'Buttons',
      Icon: MuiIcon.SmartButton
    }
  ];
  return (
    <ContentWrapper>
      <Container maxWidth={'lg'}>
        <Grid container spacing={4} justifyContent="center">
          {pages.map((x) => (
            <Grid item xs={12} sm={6} md={4}>
              <Card sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <CardActionArea onClick={() => redirect(x.route)}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '75px',
                      width: '100%'
                    }}
                  >
                    <CardMedia component={x.Icon} sx={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                  </Box>
                  <CardContent>
                    <Typography variant={'h5'} align="center">
                      {x.title}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </ContentWrapper>
  );
};
