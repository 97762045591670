import { AttachmentFile } from '../../shared/api/clients';
import { ThumbnailImage } from './FileThumbnail';
import { Box, Button, CircularProgress, Stack } from '@mui/material';
import { MuiIcon } from '../../shared/icons';
import { RooDialog } from '../RooDialog';
import { showError } from '../../shared/utils';
import { FilePicker } from './FilePicker/FilePicker';
import { FileUploadMeta } from './FilePicker/FilePicker.store';
import React, { useState } from 'react';
import { apiProvider } from '../../shared/api/apiProvider';
import { useDisclosure } from '@roo/lib';

export const EditableImageThumbnail = ({
  currentFile,
  size = 128,
  disableEditing,
  uploadMeta,
  saveFn
}: {
  currentFile: AttachmentFile;
  disableEditing?: boolean;
  size?: number;
  uploadMeta?: FileUploadMeta;
  saveFn: (file: AttachmentFile) => Promise<void>;
}) => {
  const modal = useDisclosure(false);
  const icoSize = size / 3;
  const content =
    currentFile == null ? (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: size,
          height: size,
          backgroundColor: '#cdd0d5',
          cursor: disableEditing ? undefined : 'pointer'
        }}
        onClick={disableEditing ? undefined : modal.open}
      >
        <MuiIcon.Image sx={{ color: 'white', width: icoSize, height: icoSize }} />
      </Box>
    ) : (
      <ThumbnailImage previewUrl={apiProvider.fileUrls.thumbnail(currentFile.id)} size={size} onClick={modal.open} />
    );

  if (disableEditing) {
    return content;
  }

  return (
    <>
      <Box
        sx={{
          position: 'relative',
          display: 'inline-flex'
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#3f3e3e',
            opacity: 0,
            zIndex: 1,
            cursor: 'pointer',
            '&:hover': {
              opacity: 0.8
            }
          }}
          onClick={modal.open}
        >
          {currentFile == null ? (
            <Button variant={'text'} size={'small'} startIcon={<MuiIcon.Add />}>
              Add
            </Button>
          ) : (
            <Button variant={'text'} size={'small'} startIcon={<MuiIcon.Edit />}>
              Edit
            </Button>
          )}
        </Box>

        {content}
      </Box>
      <SingleFileEditModal
        visible={modal.isOpen}
        close={modal.close}
        uploadMeta={uploadMeta}
        initialImage={currentFile}
        saveFn={saveFn}
      />
    </>
  );
};

const SingleFileEditModal = ({
  visible,
  close,
  uploadMeta,
  initialImage,
  saveFn
}: {
  visible: boolean;
  close: () => void;
  uploadMeta: FileUploadMeta;
  initialImage: AttachmentFile;
  saveFn: (file: AttachmentFile) => Promise<void>;
}) => {
  return (
    <RooDialog maxWidth={'md'} fullWidth open={visible} onClose={close}>
      <RooDialog.Title onClose={close}>Edit Image</RooDialog.Title>
      <ModalBody uploadMeta={uploadMeta} initialImage={initialImage} close={close} saveFn={saveFn} />
    </RooDialog>
  );
};

const ModalBody = ({
  uploadMeta,
  initialImage,
  close,
  saveFn
}: {
  uploadMeta: FileUploadMeta;
  initialImage: AttachmentFile;
  close: () => void;
  saveFn: (file: AttachmentFile) => Promise<void>;
}) => {
  const [mode, setMode] = useState<'view' | 'upload'>(initialImage == null ? 'upload' : 'view');
  const [currentImage, setCurrentImage] = useState<AttachmentFile | null>(initialImage);
  const [isSaving, setIsSaving] = useState(false);

  const onSave = async () => {
    setIsSaving(true);
    try {
      await saveFn(currentImage!);
      close();
    } catch (e) {
      showError();
    }
    setIsSaving(false);
  };

  return (
    <>
      <RooDialog.Content>
        {mode === 'view' && <ImageView image={currentImage} />}
        {mode === 'upload' && (
          <FilePicker
            meta={uploadMeta}
            maxFiles={1}
            allowedFileTypes={['image']}
            onAppend={(...items) => {
              if (items.length > 0) {
                setCurrentImage(items[0]);
                setMode('view');
              }
            }}
          />
        )}
      </RooDialog.Content>
      <RooDialog.Actions>
        <Stack direction={'row'} gap={2}>
          <Button onClick={close} variant={'text'}>
            Cancel
          </Button>
          {currentImage != null && (
            <Button
              variant={'text'}
              onClick={() => {
                setCurrentImage(null);
                setMode('upload');
              }}
              startIcon={<MuiIcon.Edit />}
            >
              Change
            </Button>
          )}

          {currentImage == null && initialImage != null && (
            <Button onClick={onSave} disabled={isSaving} color={'error'} variant={'contained'}>
              Save Empty
            </Button>
          )}
          {currentImage != null && (
            <Button onClick={onSave} disabled={isSaving} variant={'contained'}>
              Save
            </Button>
          )}
        </Stack>
      </RooDialog.Actions>
    </>
  );
};

const ImageView = ({ image }: { image: AttachmentFile }) => {
  const [isLoading, setIsLoading] = useState(true);
  return (
    <div
      style={{
        height: 'auto',
        width: 'auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      {isLoading && <CircularProgress sx={{ position: 'absolute', margin: 'auto' }} />}
      <img
        onLoad={() => setIsLoading(false)}
        src={apiProvider.fileUrls.serve(image.id)}
        alt="file"
        style={{ width: 'auto', height: 'auto', maxWidth: '100%', maxHeight: '85vh', minHeight: '400px' }}
      />
    </div>
  );
};
