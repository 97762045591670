import { Grid, Paper, Stack, Typography } from '@mui/material';
import { FileThumbnail } from 'components/Files/FileThumbnail';
import { useCurrentIssue } from 'pages/Workorders/shared/CurrentIssueContext';
import React, { ReactNode } from 'react';
import { Button, Card } from 'react-bootstrap';
import { IAttachmentFile, IChecklistLine } from 'shared/api/clients';
import { rooFmt } from 'shared/utils';
import { flatMap } from 'lodash';
import { downloadFiles } from '../../../../../components/Files/utils';
import { MuiIcon } from '../../../../../shared/icons';

export const ChecklistCard = () => {
  const { checklist } = useCurrentIssue();
  const lines = checklist.length;
  const allFiles = [...flatMap(checklist, (x) => x.beforeFiles), ...flatMap(checklist, (x) => x.afterFiles)];
  return (
    <Card>
      <Card.Header>
        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
          <span>Checklist</span>
          {allFiles.length > 0 && (
            <Button size={'sm'} onClick={() => downloadFiles(allFiles)}>
              Download {rooFmt.counted(allFiles.length, 'picture', 'pictures')}
            </Button>
          )}
        </Stack>
      </Card.Header>
      <Card.Body>
        <Stack spacing={2} sx={{ pt: 2 }}>
          {checklist.map((line, i) => (
            <ChecklistLineView key={i} checklistLine={line} index={i} totalLines={lines} />
          ))}
        </Stack>
      </Card.Body>
    </Card>
  );
};

const ChecklistLineView = ({
  checklistLine,
  index,
  totalLines
}: {
  checklistLine: IChecklistLine;
  index: number;
  totalLines: number;
}) => {
  return (
    <Paper sx={{ p: 2 }} elevation={1}>
      <Stack spacing={3}>
        <Stack direction={'row'} alignItems={'center'} spacing={2}>
          {checklistLine.isComplete && <MuiIcon.CheckCircle color={'success'} />}
          {!checklistLine.isComplete && <MuiIcon.Cancel color={'error'} />}
          <Stack sx={{ width: '100%' }} direction={'row'} justifyContent={'space-between'} spacing={2}>
            <Stack>
              <Typography variant={'caption'} color={'grey.500'}>
                Line {index + 1}/{totalLines}
              </Typography>
              <Typography>{checklistLine.description}</Typography>
            </Stack>
            <Stack direction={'row'} spacing={4}>
              <Stack justifyContent={'center'} alignItems={'center'}>
                <Typography variant={'caption'}>Estimated</Typography>
                <Typography>{rooFmt.moneyMaybe(checklistLine.initialValue, 'N/A')}</Typography>
              </Stack>
              <Stack justifyContent={'center'} alignItems={'center'}>
                <Typography variant={'caption'}>Actual</Typography>
                <Typography>{rooFmt.moneyMaybe(checklistLine.value, 'N/A')}</Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        {checklistLine.notes && (
          <Stack justifyContent={'center'}>
            <Typography variant={'caption'}>Notes</Typography>
            <Typography>{checklistLine.notes}</Typography>
          </Stack>
        )}
        {(checklistLine.beforeFiles.length > 0 || checklistLine.afterFiles.length > 0) && (
          <Stack direction={'row'} spacing={2}>
            <FileView label={'Before'} list={checklistLine.beforeFiles} />
            <FileView label={'After'} list={checklistLine.afterFiles} />
          </Stack>
        )}
      </Stack>
    </Paper>
  );
};

const FileView = ({ label, list }: { label: ReactNode; list: IAttachmentFile[] }) => {
  if (list == null || list.length === 0) {
    return null;
  }
  return (
    <Stack>
      <Typography variant={'caption'}>{label}</Typography>
      <Grid container spacing={2}>
        {list.map((file) => (
          <Grid item key={file.id}>
            <FileThumbnail size={64} file={file} />
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
};
