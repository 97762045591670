import { Stack } from '@mui/material';
import { UserCard } from 'components';
import { useCurrentIssue } from 'pages/Workorders/shared/CurrentIssueContext';

export const IssueContacts = () => {
  const { participants } = useCurrentIssue();

  return (
    <>
      <Stack direction={'column'} spacing={2}>
        {participants.map((x) => (
          <UserCard key={x.user.id} user={x.user} role={x.group} />
        ))}
      </Stack>
    </>
  );
};
