import { mergeDeep } from '@roo/lib';
import { QueryClient } from '@tanstack/react-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      structuralSharing: (oldData, newData) => {
        return mergeDeep(oldData, newData);
      },
      retry: false,
      staleTime: 1 * 60 * 1000,
      refetchOnMount: 'always'
    }
  }
});

export { queryClient };
