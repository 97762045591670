// pulled in from @tanstack/query replaceEqualDeep, modified to support our custom API classes

import { isEqual } from 'date-fns';

export const mergeDeepTyped = <T extends {}>(oldVal: T, newVal: T): T => {
  return mergeDeep(oldVal, newVal);
};

export const mergeDeep = (oldVal: any, newVal: any): any => {
  if (oldVal === newVal) {
    return oldVal;
  }

  if (isPlainArray(oldVal) && isPlainArray(newVal)) {
    return mergeDeepArray(oldVal, newVal);
  } else if (isDateTime(oldVal) && isDateTime(newVal)) {
    return isEqual(oldVal, newVal) ? oldVal : newVal;
  } else if (isApiClass(oldVal) && isApiClass(newVal)) {
    return mergeDeepApiClass(oldVal, newVal);
  } else if (isPlainObject(oldVal) && isPlainObject(newVal)) {
    return mergeDeepObject(oldVal, newVal);
  } else {
    return newVal;
  }
};

const mergeDeepArray = (oldVal: any[], newVal: any[]): any[] => {
  const copy: any[] = [];
  let equalItems = 0;
  for (let i = 0; i < newVal.length; i++) {
    copy[i] = mergeDeep(oldVal[i], newVal[i]);
    if (copy[i] === oldVal[i]) {
      equalItems += 1;
    }
  }

  if (oldVal.length === newVal.length && equalItems === oldVal.length) {
    return oldVal;
  }

  return copy;
};

const mergeDeepObject = (oldVal: any, newVal: any) => {
  const copy: any = {};
  const oldKeys = Object.keys(oldVal);
  const newKeys = Object.keys(newVal);
  let equalItems = 0;
  for (let i = 0; i < newKeys.length; i++) {
    const key = newKeys[i];
    copy[key] = mergeDeep(oldVal[key], newVal[key]);
    if (copy[key] === oldVal[key]) {
      equalItems += 1;
    }
  }

  if (oldKeys.length === newKeys.length && equalItems === oldKeys.length) {
    return oldVal;
  }

  return copy;
};

const mergeDeepApiClass = (oldVal: any, newVal: any) => {
  const copy: any = {};

  const oldKeys = Object.keys(oldVal);
  const oldCtor = oldVal.constructor;

  const newKeys = Object.keys(newVal);
  const newCtor = newVal.constructor;

  let equalItems = 0;
  for (let i = 0; i < newKeys.length; i++) {
    const key = newKeys[i];
    copy[key] = mergeDeep(oldVal[key], newVal[key]);
    if (copy[key] === oldVal[key]) {
      equalItems += 1;
    }
  }

  if (oldCtor === newCtor && oldKeys.length === newKeys.length && equalItems === oldKeys.length) {
    return oldVal;
  }

  return new newCtor(copy);
};

const isApiClass = (value: any) => {
  if (value == null) {
    return false;
  }

  const ctor = value.constructor;
  if (ctor == null) {
    return false;
  }

  const proto = ctor.prototype;
  return proto.hasOwnProperty('init') && proto.hasOwnProperty('toJSON');
};

export const isPlainArray = (value: unknown) => {
  return Array.isArray(value) && value.length === Object.keys(value).length;
};

export const isDateTime = (value: unknown): value is Date => {
  return value instanceof Date;
};

// Copied from: https://github.com/jonschlinkert/is-plain-object
// eslint-disable-next-line @typescript-eslint/ban-types
export const isPlainObject = (o: any): o is Object => {
  if (!hasObjectPrototype(o)) {
    return false;
  }

  // If it has a modified constructor
  const ctor = o.constructor;
  if (typeof ctor === 'undefined') {
    return true;
  }

  // If it has a modified prototype
  const prot = ctor.prototype;
  if (!hasObjectPrototype(prot)) {
    return false;
  }

  // If the constructor does not have an Object-specific method
  if (!prot.hasOwnProperty('isPrototypeOf')) {
    return false;
  }

  // Most likely a plain Object
  return true;
};

const hasObjectPrototype = (o: any): boolean => {
  return Object.prototype.toString.call(o) === '[object Object]';
};
