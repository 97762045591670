import React from 'react';
import { FieldValues, useController } from 'react-hook-form';
import { ErrorMessage } from 'components/form/ErrorMessage';
import { DropdownOption } from 'shared/store';
import { RooSelect, RooSelectProps } from 'components/RooSelect';
import { ControlProps } from 'components/form/types';
import { MultiValue } from 'react-select';
import { SingleValue } from 'react-select/dist/declarations/src/types';
import { FormGroup } from 'react-bootstrap';
import { FormGroupProps } from 'react-bootstrap/FormGroup';
import { RooFormLabel } from './RooFormLabel';

type BaseProps<TVal> = Omit<RooSelectProps<TVal>, 'id' | 'type' | 'name' | 'onChange' | 'value' | 'onBlur' | 'isMulti'>;
type FinalProps<TForm extends FieldValues, TVal> = ControlProps<TForm> &
  BaseProps<TVal> & { groupProps?: FormGroupProps };
export type FieldMultiSelectProps<TForm extends FieldValues, TVal> = FinalProps<TForm, TVal>;

export const FieldMultiSelect = <TForm, TVal>({
  name,
  options,
  control,
  label,
  groupProps,
  required,
  ...rest
}: FinalProps<TForm, TVal>) => {
  const {
    field,
    fieldState: { error }
  } = useController({
    control: control,
    name: name
  });

  const val = options?.filter((x: any) => field.value != null && (field.value as TVal[]).indexOf(x.value) > -1) as
    | MultiValue<DropdownOption<TVal>>
    | SingleValue<DropdownOption<TVal>>;

  return (
    <FormGroup {...(groupProps ?? {})}>
      <RooFormLabel label={label} name={name} required={required} />
      <RooSelect
        name={field.name}
        id={name}
        isMulti={true}
        options={options}
        value={val}
        invalid={error != null}
        onBlur={field.onBlur}
        onChange={(value) => {
          const myVal = value as MultiValue<DropdownOption<TVal>>;
          // @ts-ignore
          field.onChange(myVal?.map((x) => x.value));
        }}
        {...rest}
      />
      <ErrorMessage error={error} />
    </FormGroup>
  );
};
