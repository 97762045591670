import { IconButton, List, ListItem, ListItemText, ListSubheader, Stack } from '@mui/material';
import { ContactInfo } from 'shared/api/clients';
import { rooFmt } from 'shared/utils';
import { MuiIcon } from '../../shared/icons';

export const ContactInfoWidget = ({
  contactInfo,
  name,
  nameLabel,
  canEdit,
  onEdit
}: {
  name: string;
  nameLabel: string;
  contactInfo: ContactInfo;
  canEdit: boolean;
  onEdit?: () => void;
}) => {
  if (contactInfo == null) {
    return null;
  }

  return (
    <List
      className={'profile'}
      subheader={
        <Stack direction={'row'}>
          <ListSubheader>
            <MuiIcon.Call /> Contact Info
          </ListSubheader>
          {canEdit && (
            <IconButton color={'primary'} onClick={() => onEdit?.()}>
              <MuiIcon.Edit />
            </IconButton>
          )}
        </Stack>
      }
    >
      {name != null && nameLabel != null && (
        <ListItem>
          <ListItemText primary={rooFmt.textMaybe(name, '-')} secondary={nameLabel} />
        </ListItem>
      )}

      <ListItem>
        <ListItemText primary={rooFmt.textMaybe(contactInfo.phoneNumber, '-')} secondary={'Cell Phone'} />
      </ListItem>
      {contactInfo.workPhone && (
        <ListItem>
          <ListItemText primary={rooFmt.textMaybe(contactInfo.workPhone, '-')} secondary={'Work Phone'} />
        </ListItem>
      )}
      <ListItem>
        <ListItemText primary={rooFmt.textMaybe(contactInfo.email, '-')} secondary={'E-mail'} />
      </ListItem>
    </List>
  );
};
