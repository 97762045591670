import { Paper, styled } from '@mui/material';

export const WhiteBox = styled(Paper, { shouldForwardProp: (prop) => prop !== 'elevation' })(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: 'left',
  color: theme.palette.text.secondary
}));

WhiteBox.defaultProps = {
  elevation: 0
};
