import { alpha } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';

type ColorKey = 'primary' | 'secondary' | 'error' | 'warning' | 'info' | 'success';

type ColorSwitchProps = {
  checkedColor?: ColorKey;
  uncheckedColor?: ColorKey;
};

export const ColorSwitch = styled(Switch, {
  shouldForwardProp: (prop: string) => !['checkedColor', 'uncheckedColor'].includes(prop)
})<ColorSwitchProps>(({ theme, checkedColor = 'success', uncheckedColor = 'error' }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: theme.palette[checkedColor].main,
    '&:hover': {
      backgroundColor: alpha(theme.palette[checkedColor].main, theme.palette.action.hoverOpacity)
    }
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: theme.palette[checkedColor].main
  },
  '& .MuiSwitch-switchBase:not(.Mui-checked)': {
    color: theme.palette[uncheckedColor].main,
    '&:hover': {
      backgroundColor: alpha(theme.palette[uncheckedColor].main, theme.palette.action.hoverOpacity)
    }
  },
  '& .MuiSwitch-switchBase:not(.Mui-checked) + .MuiSwitch-track': {
    backgroundColor: theme.palette[uncheckedColor].main
  }
}));
