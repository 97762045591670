import { SaveButton } from 'components';
import { FieldDatePicker, FieldSingleSelect, FieldTextArea, Required, useRooForm } from 'components/form';
import { useCurrentIssue } from 'pages/Workorders/shared/CurrentIssueContext';
import React from 'react';
import { Alert, Col, Row } from 'react-bootstrap';
import { apiProvider } from 'shared/api/apiProvider';
import { IBid, Instant, ProposalType, SetBidSchedulePayload, TimeBlock } from 'shared/api/clients';
import { useTimeBlockOptions } from 'shared/store';
import { rooFmt, showSuccess } from 'shared/utils';
import { z } from 'zod';

const TimeBlockSchema = z.object({
  startHour: z.number(),
  startMinutes: z.number(),
  durationMinutes: z.number()
});

const ScheduleSchema = z.object({
  date: z.date(),
  timeBlocks: TimeBlockSchema,
  notes: z.string().nullish()
});

type ScheduleDefinition = z.infer<typeof ScheduleSchema>;

export const ScheduleProposalForm = ({ proposalType, myBid }: { myBid: IBid; proposalType: ProposalType }) => {
  const { issue, onIssueUpdate, property } = useCurrentIssue();
  const blockOptions = useTimeBlockOptions();
  const selectedBlock = blockOptions.find(
    (x) => x.value.startHour === myBid.schedule?.timeBlocks?.[0]?.startHour
  )?.value;
  const { control, handleSubmit } = useRooForm(ScheduleSchema, {
    defaultValues: {
      timeBlocks: selectedBlock,
      date: rooFmt.dateFromInstant(myBid.schedule?.scheduledInstant),
      // if he already had some notes in the proposal, no point in having them in the final schedule again
      notes: null
    }
  });

  const save = async (values: ScheduleDefinition) => {
    try {
      const result = await apiProvider.issues.bids.setBidSchedule(
        new SetBidSchedulePayload({
          issueId: issue.id,
          bidId: myBid.id,
          scheduledInstant: new Instant({
            dateTime: values.date,
            utcOffset: values.date.getTimezoneOffset()
          }),
          timeBlocks: [
            new TimeBlock({
              durationMinutes: values.timeBlocks.durationMinutes,
              startHour: values.timeBlocks.startHour,
              startMinutes: values.timeBlocks.startMinutes,
              display: null
            })
          ],
          proposalType: proposalType,
          comments: values.notes
        })
      );
      onIssueUpdate(result);
      showSuccess();
    } catch (e) {}
  };
  return (
    <form noValidate onSubmit={handleSubmit(save)}>
      <Alert variant={'info'} className={'text-center'}>
        All bidders are required to visit the property before submitting a bid. <br /> Schedule your visit below.
      </Alert>

      {proposalType === ProposalType.Tentative && (
        <Alert variant={'warning'} className={'text-center'}>
          This property is occupied. Your proposal will be sent to the tenants for further discussion.
        </Alert>
      )}

      {proposalType === ProposalType.Final && property.isOccupied && (
        <Alert variant={'warning'} className={'text-center'}>
          This property is occupied. Make sure the tenants have agreed to your proposed visit date and time.
        </Alert>
      )}

      <Row>
        <Col xs={12} className={'mb-2'}>
          <FieldDatePicker
            control={control}
            name={'date'}
            minDate={new Date()}
            dateFormat={'MMMM d, yyyy'}
            placeholderText={'Date'}
            required
            label={'Date'}
          />
        </Col>
        <Col xs={12} className={'mb-2'}>
          <FieldSingleSelect
            equalityFn={(a: TimeBlock, b: TimeBlock) => a.startHour === b.startHour}
            control={control}
            name={'timeBlocks'}
            options={blockOptions}
            required
            label={'Time'}
          />
        </Col>
        <Col xl={12}>
          <FieldTextArea control={control} name={'notes'} label={'Notes'} />
        </Col>
      </Row>
      <hr className="hr-text" />
      <div style={{ textAlign: 'center' }}>
        <SaveButton control={control}>{proposalType === ProposalType.Tentative ? 'Propose' : 'Schedule'}</SaveButton>
      </div>
    </form>
  );
};
