import { IInsDocument, IInsDocumentEnvelope } from "@roo/api";
import { platformUtils } from "../utils";

const hydrate = (serialized: string) => {
  if (serialized == null) {
    return null;
  }
  return JSON.parse(serialized) as IInsDocumentEnvelope;
};

const dehydrate = (
  inspectionId: string,
  userId: string,
  timestamp: number,
  doc: IInsDocument,
) => {
  const payload: IInsDocumentEnvelope = {
    inspectionId: inspectionId,
    userId: userId,
    document: doc,
    version: doc.templateInfo.toolingVersion,
    timestamp: timestamp,
  };
  return JSON.stringify(payload);
};

export const insService = {
  save: async (inspectionId: string, userId: string, payload: string) => {
    await platformUtils
      .get()
      .storage.set(`${userId}_inspection_v2_${inspectionId}`, payload);
  },
  load: async (inspectionId: string, userId: string) => {
    const serialize = await platformUtils
      .get()
      .storage.get(`${userId}_inspection_v2_${inspectionId}`);
    if (serialize == null) {
      return null;
    }

    return hydrate(serialize);
  },
  dehydrate,
  hydrate,
};
