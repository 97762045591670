import { Stack } from '@mui/material';
import { SaveButton } from 'components';
import { FieldDatePicker, FieldNumber, FieldTextArea } from 'components/form';
import { useRequiredRooForm } from 'components/form/utils';
import { round } from 'lodash';
import { useCurrentIssue } from 'pages/Workorders/shared/CurrentIssueContext';
import { FormLineItem } from 'pages/Workorders/ViewIssue/Documents/FormLineItem';
import { DocumentFormTotals } from 'pages/Workorders/ViewIssue/Documents/DocumentFormTotals';
import { TaxableLinesSchema, WithLineCollection } from 'pages/Workorders/ViewIssue/Documents/types';
import { DEFAULT_MARKUP_PERCENT } from 'pages/Workorders/ViewIssue/Documents/utils';
import { Button, Col, FormGroup, Row } from 'react-bootstrap';
import { Control, useFieldArray } from 'react-hook-form';
import { UseFormGetValues, UseFormSetValue } from 'react-hook-form/dist/types/form';
import { apiProvider } from 'shared/api/apiProvider';
import {
  AttachmentEntityType,
  BidDefaultsResponse,
  BidStatus,
  BidSubmissionType,
  FormSubmissionLineItem,
  GetBidDefaultsPayload,
  IBid,
  LineSource,
  SubmitBidPayload
} from 'shared/api/clients';
import { RooIcon } from 'shared/icons';
import { classNames, DeepRequired, rooDate, rooFmt, showSuccess } from 'shared/utils';
import { z } from 'zod';
import { useQuery } from '@tanstack/react-query';
import { CenteredLoader } from '../../../../../components/CenteredLoader';
import { InlineError } from '../../../../../components/InlineError';
import { useUniqueId } from '@roo/lib';

const BidSchema = z.object({
  startDate: z.date(),
  endDate: z.date(),
  revisionNotes: z.string().nullish(),
  lineCollection: TaxableLinesSchema
});

type FormDefinition = DeepRequired<z.infer<typeof BidSchema>>;

export const BidForm = (props: { myBid: IBid; submissionType: BidSubmissionType; onCancel?: () => void }) => {
  const { issue } = useCurrentIssue();
  const uid = useUniqueId();
  const { data, status } = useQuery(['issues', issue.id, 'bids', props.myBid.id, 'form-defaults', uid], {
    queryFn: () =>
      apiProvider.issues.bids.getFormDefaults(
        new GetBidDefaultsPayload({
          issueId: issue.id,
          submissionType: props.submissionType,
          existingBidId: props.myBid.id
        })
      ),
    cacheTime: Infinity,
    staleTime: Infinity
  });

  if (status === 'loading') {
    return <CenteredLoader />;
  }

  if (status === 'error') {
    return <InlineError />;
  }

  return <BidFormInner {...props} defaults={data} />;
};

const BidFormInner = ({
  myBid,
  onCancel,
  submissionType,
  defaults
}: {
  myBid: IBid;
  submissionType: BidSubmissionType;
  defaults: BidDefaultsResponse;
  onCancel?: () => void;
}) => {
  const { issue, onIssueUpdate } = useCurrentIssue();
  const { handleSubmit, control, getValues, setValue } = useRequiredRooForm(BidSchema, {
    defaultValues: {
      startDate: rooFmt.dateFromInstant(defaults.startDate),
      endDate: rooFmt.dateFromInstant(defaults.endDate),
      lineCollection: {
        taxPercent: defaults.lineCollection.taxPercent,
        resaleTaxPercent: defaults.lineCollection.resaleTaxPercent,
        discountPercent: defaults.lineCollection.discountPercent,
        lines: defaults.lineCollection.lineItems.map((x) => ({
          value: x.value,
          initialValue: x.initialValue,
          resaleValue: x.resaleValue,
          notes: x.notes,
          type: x.sourceEntityType,
          sourceEntityId: x.sourceEntityId,
          beforeFiles: x.beforeFiles,
          afterFiles: x.afterFiles,
          description: x.description,
          canEditDescription: x.canEditDescription,
          canEditValue: x.canEditValue
        }))
      }
    }
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'lineCollection.lines'
  });

  const addLine = () => {
    append(
      {
        value: null,
        description: null,
        type: LineSource.Custom,
        sourceEntityId: null,
        notes: null,
        initialValue: null,
        beforeFiles: [],
        afterFiles: [],
        canEditDescription: true,
        canEditValue: true,
        resaleValue: null
      },
      {
        shouldFocus: false
      }
    );
  };

  const save = async (values: FormDefinition) => {
    try {
      const updated = await apiProvider.issues.bids.submitBid(
        new SubmitBidPayload({
          bidId: myBid.id,
          issueId: issue.id,
          startDate: rooDate.makeInstant(values.startDate),
          endDate: rooDate.makeInstant(values.endDate),
          taxPercent: values.lineCollection.taxPercent,
          resaleTaxPercent: values.lineCollection.resaleTaxPercent,
          discountPercent: values.lineCollection.discountPercent,
          submissionType: submissionType,
          revisionNotes: values.revisionNotes,
          lineItems: values.lineCollection.lines.map(
            (x, idx) =>
              new FormSubmissionLineItem({
                description: x.description,
                notes: x.notes,
                value: x.value,
                initialValue: x.initialValue,
                sourceEntityId: x.sourceEntityId,
                sourceEntityType: x.type,
                order: idx + 1,
                beforeFileIds: x.beforeFiles.map((x) => x.id),
                resaleValue: x.resaleValue,
                afterFileIds: []
              })
          )
        })
      );

      onIssueUpdate(updated);
      showSuccess();
      onCancel?.();
    } catch (e) {}
  };

  return (
    <form noValidate onSubmit={handleSubmit(save)}>
      <Row>
        <Col xs={12}>
          <div className={classNames('alert alert-warning', { 'text-center': true })} role="alert">
            <p className={'mb-0'}>**ALL BID ITEMS REQUIRE PICTURES**</p>
            <p className={'mb-0'}>**INCLUDE LABOR + MATERIALS**</p>
          </div>
        </Col>
      </Row>
      {myBid.status === BidStatus.Rejected && (
        <Row>
          <Col xs={12}>
            <div
              className={classNames('alert alert-danger', { 'text-center': !Boolean(myBid.reviewerNotes) })}
              role="alert"
            >
              <h5 className={'alert-heading'}>Your bid has been rejected</h5>
              {myBid.reviewerNotes && <p>Reviewer notes: {myBid.reviewerNotes}</p>}
            </div>
          </Col>
        </Row>
      )}
      {submissionType !== BidSubmissionType.Revision &&
        submissionType !== BidSubmissionType.ChangeRequest &&
        myBid.revisionNotes && (
          <Row>
            <Col xs={12}>
              <div className={classNames('alert alert-warning', 'text-center')} role="alert">
                <h5 className={'alert-heading'}>Reviewer Notes</h5>
                {myBid.revisionNotes && <p>{myBid.revisionNotes}</p>}
              </div>
            </Col>
          </Row>
        )}
      {defaults.meta.withResale && (
        <Row>
          <Col xs={12}>
            <div className={classNames('alert alert-warning', 'text-center')} role="alert">
              <h5 className={'alert-heading'}>Resale notice</h5>
              Fields marked with "Resale" are only visible to you. They will be used to automatically create an estimate
              in the main issue when the vendors accepts your revision.
            </div>
          </Col>
        </Row>
      )}

      <Row>
        <Col xs={6} className={'mb-2'}>
          <FieldDatePicker
            control={control}
            name={'startDate'}
            minDate={new Date()}
            dateFormat={'MMMM d, yyyy'}
            placeholderText={'Start Date'}
            required
            label={'Start Date'}
          />
        </Col>
        <Col xs={6} className={'mb-2'}>
          <FieldDatePicker
            control={control}
            name={'endDate'}
            minDate={new Date()}
            dateFormat={'MMMM d, yyyy'}
            placeholderText={'End Date'}
            required
            label={'End Date'}
          />
        </Col>
      </Row>

      <Stack direction={'row'} alignItems={'center'} justifyContent={'center'}>
        {defaults.meta.withResale && <MarkupField setValue={setValue} getValues={getValues} />}
        {defaults.meta.withResale && (
          <FieldNumber
            control={control}
            name={`lineCollection.resaleTaxPercent`}
            label={'Resale Tax Rate %'}
            placeholder={'Resale Tax Rate %'}
          />
        )}
        {defaults.meta.withTax && (
          <FieldNumber
            control={control}
            name={`lineCollection.taxPercent`}
            required
            label={'Tax Rate %'}
            placeholder={'Tax Rate %'}
          />
        )}
        {defaults.meta.withDiscount && (
          <FieldNumber
            control={control}
            name={`lineCollection.discountPercent`}
            required
            label={'Discount %'}
            placeholder={'Discount %'}
          />
        )}
      </Stack>

      <hr className="hr-text" data-content="Items" />
      {fields.map((line, index) => {
        return (
          <FormLineItem
            key={index}
            line={line}
            index={index}
            remove={remove}
            control={control as unknown as Control<WithLineCollection>}
            getValues={getValues as unknown as UseFormGetValues<WithLineCollection>}
            entityType={AttachmentEntityType.BidLineItem}
            canEditDescription={line.canEditDescription}
            canEditValue={line.canEditValue}
            showInitial={false}
            hasResale={defaults.meta.withResale}
            hasNotes={true}
            hasBeforeFiles={true}
            hasAfterFiles={false}
          />
        );
      })}

      <div style={{ textAlign: 'center', marginTop: '15px' }}>
        <Button color={'primary'} onClick={() => addLine()}>
          <RooIcon icon={['fas', 'plus']} /> Add
        </Button>
      </div>

      <div style={{ marginTop: '30px' }}>
        <DocumentFormTotals
          control={control as unknown as Control<WithLineCollection>}
          withResale={defaults.meta.withResale}
          withDiscount={defaults.meta.withDiscount}
          previousDiscount={null}
          withTax={defaults.meta.withTax}
          withPartial={false}
        />
      </div>

      {(submissionType === BidSubmissionType.Revision || submissionType === BidSubmissionType.ChangeRequest) && (
        <Row>
          <Col className={'mb-2'}>
            <FieldTextArea control={control} name={'revisionNotes'} label={'Leave a note for the vendor'} />
          </Col>
        </Row>
      )}

      <hr className="hr-text" />
      <Stack spacing={2} direction={'row'} justifyContent={'center'}>
        <SaveButton className={'btn'} control={control}>
          Submit
        </SaveButton>
        {onCancel && (
          <button type={'button'} className={'btn btn-secondary'} onClick={onCancel}>
            Cancel
          </button>
        )}
      </Stack>
    </form>
  );
};

const MarkupField = ({
  getValues,
  setValue
}: {
  getValues: UseFormGetValues<FormDefinition>;
  setValue: UseFormSetValue<FormDefinition>;
}) => {
  return (
    <FormGroup style={{ marginRight: '16px' }}>
      <label htmlFor={'markup'}>Markup %</label>
      <input
        type="number"
        className={'form-control'}
        step="0.01"
        defaultValue={DEFAULT_MARKUP_PERCENT}
        onChange={(e) => {
          const textValue = e.target.value;
          const number = parseFloat(textValue);
          if (isNaN(number)) {
            return;
          }

          const lines = getValues('lineCollection.lines');
          let idx = 0;
          for (const line of lines) {
            if (line.value != null) {
              const newVal = round(line.value + line.value * (number / 100), 2);
              setValue(`lineCollection.lines.${idx}.resaleValue`, newVal);
            }
            idx += 1;
          }
        }}
        id={'markup'}
      />
    </FormGroup>
  );
};
