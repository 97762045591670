import { Box, Button, Grid } from '@mui/material';
import { apiProvider } from 'shared/api/apiProvider';
import { UpdateProfileBasicsPayload, User } from 'shared/api/clients';
import { FieldMuiText, useRooForm } from 'components/form';
import { useAppStore } from 'shared/store';
import { RooAvatar } from 'components';
import { rooFmt } from 'shared/utils';
import { z } from 'zod';

const BasicsSchema = z.object({
  title: z.string().nullish(),
  firstName: z.string(),
  lastName: z.string()
});
type FormDefinition = z.infer<typeof BasicsSchema>;

export const BasicsSection = ({ onUpdate }: { onUpdate: (updated: User) => void }) => {
  const currentUser = useAppStore((x) => x.userDetails);

  const { control, handleSubmit } = useRooForm(BasicsSchema, {
    defaultValues: {
      firstName: currentUser.firstName,
      lastName: currentUser.lastName,
      title: currentUser.title
    }
  });

  const handleSaveProfile = async (values: FormDefinition) => {
    try {
      const updated = await apiProvider.profileClient.updateBasics(
        new UpdateProfileBasicsPayload({
          title: values.title,
          lastName: values.lastName,
          firstName: values.firstName
        })
      );
      onUpdate(updated);
    } catch (e) {}
  };

  return (
    <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ mt: 0, p: 3, mb: 4 }}>
      <Box component="form" noValidate sx={{ p: 3, mb: 4 }}>
        <div className="d-flex justify-content-start align-items-center">
          <RooAvatar name={rooFmt.fullName(currentUser)} avatarUrl={currentUser.avatarUrl} size={'xxl'} />
          <Box sx={{ ml: 3 }}>
            <h5 style={{ margin: 0 }}>Your avatar</h5>
            <Button disabled type="submit" variant="contained" size={'large'} sx={{ mt: 2, mb: 2, width: 120 }}>
              Update
            </Button>
          </Box>
        </div>
        <br />
        <FieldMuiText
          control={control}
          name={'firstName'}
          label="First Name"
          placeholder={'John'}
          required
          fullWidth
          margin="normal"
        />
        <FieldMuiText
          control={control}
          name={'lastName'}
          label="Last Name"
          placeholder={'Doe'}
          required
          fullWidth
          margin="normal"
        />
        <FieldMuiText
          control={control}
          name={'title'}
          label="Title"
          placeholder={'Enter your title'}
          fullWidth
          margin="normal"
        />
      </Box>
      <Grid container direction="row" justifyContent="flex-end" alignItems="center">
        <Button
          type="submit"
          variant="contained"
          size={'large'}
          sx={{ mt: 2, mb: 2, width: 200 }}
          onClick={handleSubmit(handleSaveProfile)}
        >
          Save
        </Button>
      </Grid>
    </Grid>
  );
};
