import { useRef } from "react";
import { makeUuid } from "../utils";

export const useUniqueId = () => {
  const ref = useRef<string>();
  if (ref.current == null) {
    ref.current = makeUuid();
  }

  return ref.current;
};
