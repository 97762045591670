import { ReactNode } from 'react';
import { Path } from 'react-hook-form';

export type ArrayElement<ArrayType extends readonly unknown[]> = ArrayType[number];

export type MyRecursivePartial<T> = {
  [P in keyof T]?: T[P] extends (infer U)[]
    ? MyRecursivePartial<U>[]
    : T[P] extends object
    ? MyRecursivePartial<T[P]>
    : T[P];
};

export type WithOptChildren = { children?: ReactNode };
export type WithReqChildren = { children: ReactNode };

export type SelectedRow<T> = {
  allSelected: boolean;
  selectedCount: number;
  selectedRows: T[];
};

export const discriminate = <K extends PropertyKey, V extends string | number | boolean>(
  discriminantKey: K,
  discriminantValue: V
) => {
  return <T extends Record<K, any>>(obj: T & Record<K, V extends T[K] ? T[K] : V>): obj is Extract<T, Record<K, V>> =>
    obj[discriminantKey] === discriminantValue;
};

// based on https://stackoverflow.com/a/68572792

type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (k: infer I) => void ? I : never;

type Values<T> = T[keyof T];

/**
 * Generate all possible combinations of allowed arguments
 */
export type AllOverloads<Mappings, Keys extends string> = {
  [Prop in Keys]: Prop extends keyof Mappings ? (key: Prop, data: Mappings[Prop]) => any : (key: Prop) => any;
};

/**
 * Convert all allowed combinations to function overload
 */
export type KeyedOverload<Mappings, Keys extends string> = keyof Mappings extends Keys
  ? UnionToIntersection<Values<AllOverloads<Mappings, Keys>>>
  : never;

export type DeepRequired<T> = T extends Date | FileList | File | Blob
  ? T
  : {
      [K in keyof T]-?: NonNullable<DeepRequired<T[K]>>;
    };

export const deepKey = <T>(key: Path<T>) => key;

export class KeyGetter<T> {
  get = (key: Path<T>) => key;
}
