import { Box, Button, Grid } from '@mui/material';
import React from 'react';
import { apiProvider } from 'shared/api/apiProvider';
import { UpdateProfilePasswordPayload, User } from 'shared/api/clients';
import { FieldMuiText, useRooForm } from 'components/form';
import { PasswordConfirmationSchema } from 'shared/utils';
import { z } from 'zod';

type FormDefinition = z.infer<typeof PasswordConfirmationSchema>;

export const PasswordSection = ({ onUpdate }: { onUpdate: (updated: User) => void }) => {
  const { control, handleSubmit } = useRooForm(PasswordConfirmationSchema, {
    defaultValues: {
      confirmPassword: null,
      newPassword: null
    }
  });

  const handleSaveProfile = async (values: FormDefinition) => {
    try {
      const updated = await apiProvider.profileClient.updatePassword(
        new UpdateProfilePasswordPayload({
          newPassword: values.newPassword
        })
      );
      onUpdate(updated);
    } catch (e) {}
  };
  return (
    <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ mt: 0, p: 3, mb: 4 }}>
      <Box component="form" noValidate sx={{ p: 3, mb: 4 }}>
        <FieldMuiText
          control={control}
          type={'password'}
          name={'newPassword'}
          label="New password"
          placeholder={'Enter your new password'}
          required
          fullWidth
          margin="normal"
        />
        <FieldMuiText
          control={control}
          type={'password'}
          name={'confirmPassword'}
          label="Confirm new password"
          placeholder={'Confirm new password'}
          required
          fullWidth
          margin="normal"
        />
      </Box>
      <Grid container direction="row" justifyContent="flex-end" alignItems="center">
        <Button
          size={'large'}
          type="submit"
          sx={{ mt: 2, mb: 2, width: 200 }}
          onClick={handleSubmit(handleSaveProfile)}
        >
          Save
        </Button>
      </Grid>
    </Grid>
  );
};
