import { useMediaQuery, useTheme } from '@mui/material';
import { RooDialog } from '../../../../../components';
import { issueActionList } from '../../../shared/types';
import { MuiIcon } from '../../../../../shared/icons';
import { IssueAction } from '../../../../../shared/api/clients';
import React from 'react';
import { createModalStore } from '../../../../../shared/store';
import { ChangeRequestFormCoordinator } from './ChangeRequestFormCoordinator';

const useCreateChangeRequestStore = createModalStore();

const CreateChangeRequestModal = () => {
  const isVisible = useCreateChangeRequestStore((x) => x.visible);
  const hide = useCreateChangeRequestStore((x) => x.actions.hide);
  const theme = useTheme();
  const isLarge = useMediaQuery(theme.breakpoints.up('md'));
  return (
    <RooDialog
      scroll={'paper'}
      fullScreen={!isLarge}
      maxWidth={isLarge ? 'md' : undefined}
      fullWidth
      open={isVisible}
      onClose={hide}
    >
      <RooDialog.Title onClose={hide}>Create Change Request</RooDialog.Title>
      <RooDialog.Content>
        <ChangeRequestFormCoordinator onClose={hide} changeRequest={null} />
      </RooDialog.Content>
    </RooDialog>
  );
};

export const createChangeRequestAction = issueActionList.register({
  Icon: MuiIcon.Add,
  color: 'primary',
  isDisabled: () => false,
  key: 'add-change-request',
  label: 'Change Order Request',
  isVisible: (ctx) => ctx.canAct(IssueAction.CreateChangeRequest),
  onClick: () => {
    useCreateChangeRequestStore.getState().actions.show();
  },
  modalToRender: <CreateChangeRequestModal />
});
