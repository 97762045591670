import { useWatchGlobalModal } from './modalStore';
import { RooDialog } from '../RooDialog';
import { RooButton } from '../RooButton';
import React from 'react';
import { Stack, Typography } from '@mui/material';

export const MakeReadyModal = () => {
  const { isVisible, toggle } = useWatchGlobalModal('makeReady');

  return (
    <>
      <RooDialog maxWidth={'md'} fullWidth open={isVisible} onClose={toggle}>
        <RooDialog.Title>Make Ready Checklist</RooDialog.Title>
        <RooDialog.Content>
          <Stack>
            <ul>
              <li>HVAC - test both air conditioning and heat. Set a/c to 78 in summer; set heat to 60 in winter.</li>
              <li>
                Smoke alarms - check the date and replace the unit if 8 or more years old. Replace batteries in existing
                units. One in each bedroom; hallway and living area. Carbon monoxide if there are gas utilities and/or
                attached garage
              </li>
              <li>Pool/Spa - Check for locks on exterior gates (install combo locks)</li>
            </ul>
            <Typography variant={'h6'}>FRONT EXTERIOR:</Typography>
            <ul>
              <li>Check doorbell and remove any wifi enabled video doorbells. Replace with standard doorbell.</li>
              <li>Check all window screens (in place and free of holes)</li>
              <li>Replace burned out bulbs on exterior lights</li>
              <li>Replace weatherstripping on exterior doors if needed</li>
            </ul>
            <Typography variant={'h6'}>LIVING/FAMILY/DINING ROOMS:</Typography>
            <ul>
              <li>Replace burned out/missing bulbs and globes</li>
              <li>Check lights and ceiling fans for operation (interior and exterior); light fixtures and globes</li>
              <li>Ceiling fans</li>
              <li>Check all closet doors</li>
              <li>Make sure all doors close/latch properly Doorknobs.</li>
              <li>Doorstops</li>
              <li>Check AC filters. Initial and date if new one installed. Note size and location on the invoice.</li>
              <li>Check all blinds for operation</li>
              <li>Check for any missing wands on good blinds.</li>
              <li>Check for broken windows</li>
              <li>Window screens on windows that open</li>
              <li>Check locks on windows that open</li>
              <li>Remove nails/screws - fill holes caulk/fill/texture foundation cracks</li>
              <li>Touch up paint or full paint</li>
              <li>Broken/missing switchplates.</li>
              <li>Check for caulking around wet areas.</li>
              <li>Check weather stripping on all doors</li>
              <li>Broken drawers or drawer bottoms</li>
              <li>Check all interior doors for damage</li>
              <li>Check for locking bedroom door knobs switch to regular door knobs</li>
              <li>Check doors to ensure they close and latch properly</li>
              <li>Check all handrails</li>
            </ul>
            <Typography variant={'h6'}>KITCHEN:</Typography>
            <ul>
              <li>
                Check all appliances for functionality: fridge, microwave, range, vent-a-hood, garbage disposal,
                dishwasher, washer and dryer.
              </li>
              <li>Provide pictures of the model and serial number sticker for each appliance and the water heater.</li>
              <li>Provide new Drip pans as needed.</li>
              <li>Check underneath and inside kitchen cabinets for rotten wood</li>
              <li>Check for hot water on all connections.</li>
            </ul>
            <Typography variant={'h6'}>BEDROOMS:</Typography>
            <ul>
              <li>Closet rods are all present.</li>
              <li>Make sure windows open/close properly</li>
              <li>Window locks and screens on all windows that open, Check all closet doors</li>
            </ul>
            <Typography variant={'h6'}>BATHROOMS:</Typography>
            <ul>
              <li>Toilet paper/towel holders.</li>
              <li>
                Check all plumbing fixtures, make sure toilets are not running, flush all toilets and make sure there
                are no clogs.
              </li>
              <li>Check all toilet seats. If old or broken replace</li>
              <li>Check Caulking</li>
              <li>Check Tub - is resurfacing needed?</li>
              <li>Check all shower doors open and close properly</li>
              <li>Check Pop up assemblies for sinks/tub.</li>
              <li>
                Check all showerheads for proper functionality. Notify if extra cleaning is needed for mineral deposits.
              </li>
              <li>Check all faucets and sinks for leaks</li>
              <li>Check underneath bathroom cabinets for rotten wood</li>
              <li>Check for hot water on all connections</li>
            </ul>
            <Typography variant={'h6'}>EXTERIOR:</Typography>
            <ul>
              <li>Check garage door for proper functioning.</li>
              <li>Are remotes present, or needed?</li>
              <li>
                City inspections will require visible address numbers on the front and the back if there is an alley.
              </li>
            </ul>
          </Stack>
        </RooDialog.Content>
        <RooDialog.Actions>
          <RooButton variant={'secondary'} onClick={toggle}>
            Close
          </RooButton>
        </RooDialog.Actions>
      </RooDialog>
    </>
  );
};
