import { bidActionList, bidModalComponents } from './BidList/actions';
import { MuiIcon } from '../../../../../shared/icons';
import { BidAction, BidSubmissionType, BidUserView, IBidUserView } from '../../../../../shared/api/clients';
import { createModalStore } from '../../../../../shared/store';
import { RooDialog } from '../../../../../components';
import { BidForm } from './BidForm';
import React from 'react';

const editBidAction = bidActionList.register({
  key: 'edit',
  color: 'primary',
  label: 'Edit',
  Icon: MuiIcon.Edit,
  actionType: BidAction.Edit,
  onClick: (bidView) => {
    useBidFormModalStore.getState().actions.show({
      bidView: bidView,
      submissionType: bidView.bid.isSubmitted ? BidSubmissionType.Update : BidSubmissionType.Submission
    });
  }
});

const reviseBidAction = bidActionList.register({
  key: 'revise',
  actionType: BidAction.Revise,
  color: 'primary',
  label: 'Request Changes',
  Icon: MuiIcon.Edit,
  onClick: (bidView) =>
    useBidFormModalStore.getState().actions.show({
      bidView: bidView,
      submissionType: BidSubmissionType.Revision
    })
});

const requestChangesAction = bidActionList.register({
  key: 'request-changes',
  color: 'primary',
  label: 'Request Changes',
  Icon: MuiIcon.Edit,
  actionType: BidAction.RequestChanges,
  onClick: (bidView) =>
    useBidFormModalStore.getState().actions.show({
      bidView: bidView,
      submissionType: BidSubmissionType.ChangeRequest
    })
});

export const bidEditActionList = [reviseBidAction, requestChangesAction, editBidAction];

type ModalPayload = {
  bidView: IBidUserView;
  submissionType: BidSubmissionType;
};
const useBidFormModalStore = createModalStore<ModalPayload>();

const BidFormModal = () => {
  const {
    visible,
    actions: { hide }
  } = useBidFormModalStore();
  return (
    <RooDialog maxWidth={'lg'} fullWidth open={visible}>
      <RooDialog.Title onClose={hide}>Submit a Bid</RooDialog.Title>
      <BidFormModalBody />
    </RooDialog>
  );
};

const BidFormModalBody = () => {
  const {
    actions: { hide },
    payload
  } = useBidFormModalStore();
  return (
    <>
      <RooDialog.Content>
        <BidForm myBid={payload.bidView.bid} submissionType={payload.submissionType} onCancel={hide} />
      </RooDialog.Content>
    </>
  );
};

bidModalComponents.push(<BidFormModal />);
