import { Helmet } from 'react-helmet';
import * as React from 'react';
import { useParams } from 'react-router';
import {
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { truncateText } from 'shared/text';
import { createInspectionEditorStore, InsEditorStoreProvider, insService, useDisclosure, useInsEditor } from '@roo/lib';
import { SummaryHeading } from './SummaryHeading';
import { ReassignInspectionModal, useReassignInspectionAssignmentModal } from './Workflow/ReassignInspectionModal';
import {
  CreateWorkorderFromInspectionPayload,
  IInspectionDetails,
  IInspectionUserView,
  InspectionRole,
  InspectionStatus
} from '@roo/api';
import { MuiIcon } from 'shared/icons';
import { FileThumbnail } from '../../components/Files/FileThumbnail';
import { ConfirmationModal, ContentWrapper, MuiRouterButton, NavSlim } from '../../components';
import { useCurrentUser, useHasInspectionsAccess } from '../../shared/store';
import { useInspectionDetailsQuery } from './shared';
import { CenteredLoader } from '../../components/CenteredLoader';
import { apiProvider } from '../../shared/api/apiProvider';
import { redirect, Routes } from '../../shared/routing';

export const InspectionSummaryPage = () => {
  const hasAccess = useHasInspectionsAccess();
  return (
    <>
      <Helmet>
        <title>Inspections - Walkthroo</title>
      </Helmet>
      <NavSlim breadcrumbs={[{ text: 'Inspections' }]} />
      <ContentWrapper>{hasAccess && <Body />}</ContentWrapper>
    </>
  );
};

const Body = () => {
  const { id } = useParams<{ id: string }>();
  const { status, data } = useInspectionDetailsQuery(id);

  if (status === 'loading') {
    return <CenteredLoader />;
  }

  if (status === 'error' || data == null) {
    return null;
  }

  return <Content inspectionView={data} />;
};

const Content = ({ inspectionView }: { inspectionView: IInspectionUserView }) => {
  const { inspection, role } = inspectionView;

  return (
    <Container>
      <Stack spacing={4}>
        <Paper elevation={0} sx={{ p: 2 }}>
          <Stack spacing={2}>
            <Stack direction={'row'} justifyContent={'space-between'}>
              <Typography variant="h6" component="div">
                Inspection {inspection.friendlyId}
              </Typography>
              {(role === InspectionRole.Assignee || role === InspectionRole.SelfAssigned) && (
                <ActionsAssignee inspection={inspection} />
              )}
              {(role === InspectionRole.Requester || role === InspectionRole.SelfAssigned) && (
                <ActionsRequester inspection={inspection} />
              )}
            </Stack>
            <Divider />
            <SummaryHeading inspection={inspection} />
          </Stack>
        </Paper>
        {inspection.status === InspectionStatus.Done && inspection.contentJson != null && (
          <ReportSummaryContainer inspection={inspection} />
        )}
      </Stack>

      <ReassignInspectionModal />
    </Container>
  );
};

const ActionsAssignee = ({ inspection }: { inspection: IInspectionDetails }): any => {
  return null;
};

const ActionsRequester = ({ inspection }: { inspection: IInspectionDetails }) => {
  const reassignModal = useReassignInspectionAssignmentModal((x) => x.actions);
  return (
    <>
      {inspection.status !== InspectionStatus.Done && (
        <Button
          startIcon={<MuiIcon.SwapHoriz />}
          color={'warning'}
          onClick={() => reassignModal.show({ inspectionId: inspection.id })}
        >
          Reassign
        </Button>
      )}
      {inspection.status === InspectionStatus.Done && inspection.linkedIssueId == null && (
        <CreateWorkorderButton inspection={inspection} />
      )}
    </>
  );
};

const CreateWorkorderButton = ({ inspection }: { inspection: IInspectionDetails }) => {
  const qc = useQueryClient();
  const modal = useDisclosure(false);
  const { isLoading, data, mutate } = useMutation({
    mutationFn: () =>
      apiProvider.inspectionClient.createWorkorder(
        new CreateWorkorderFromInspectionPayload({
          inspectionId: inspection.id
        })
      ),
    onSuccess: () => {
      void qc.invalidateQueries(['inspections']);
      void qc.invalidateQueries(['issues']);
    }
  });

  return (
    <>
      <Button onClick={modal.open}>Create Workorder</Button>
      <ConfirmationModal
        visible={modal.isOpen}
        running={isLoading}
        title={'Confirmation'}
        question={
          data?.created === false
            ? 'A workorder already exists for this inspection or the inspection was completed with no issues.'
            : 'Do you want to create a workorder from this inspection?'
        }
        onClose={modal.close}
        onSave={() =>
          mutate(null, {
            onSuccess: (data) => {
              if (data.created) {
                modal.close();
                redirect({
                  pathname: Routes.IssueView,
                  params: { workorderId: data.createdIds.workorderId, issueId: data.createdIds.issueId }
                });
              }
            }
          })
        }
      />
    </>
  );
};

const ReportSummaryContainer = ({ inspection }: { inspection: IInspectionDetails }) => {
  const user = useCurrentUser();
  return (
    <InsEditorStoreProvider
      createStore={() =>
        createInspectionEditorStore({
          isReadonly: true,
          inspection: inspection,
          existingDocument: insService.hydrate(inspection.contentJson).document,
          template: null,
          userId: user.id
        })
      }
    >
      <ReportSummary />
    </InsEditorStoreProvider>
  );
};

const ReportSummary = () => {
  const insId = useInsEditor((x) => x.inspectionDetails.id);
  const problemLines = useInsEditor((x) => x.views.problemLineIds.map((lineId) => x.views.lines[lineId]));
  return (
    <Paper elevation={0} sx={{ p: 2 }}>
      <Stack spacing={2}>
        <Stack direction={'row'} justifyContent={'space-between'}>
          <Typography variant="h6" component="div">
            Report Summary
          </Typography>
          <MuiRouterButton to={{ pathname: Routes.InspectionReport, params: { id: insId } }} color={'primary'}>
            Full report
          </MuiRouterButton>
        </Stack>
        <Divider />
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Section</TableCell>
                <TableCell>Line</TableCell>
                <TableCell>Action</TableCell>
                <TableCell>Notes</TableCell>
                <TableCell>Media</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {problemLines.map((line) => (
                <TableRow key={line.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    <Stack>
                      <Typography fontWeight={600}>{line.parentName}</Typography>
                      <Typography variant={'caption'}>{line.parentPath}</Typography>
                    </Stack>
                  </TableCell>
                  <TableCell>{line.name}</TableCell>
                  <TableCell>{line.selectedAction?.label}</TableCell>
                  <TableCell>{truncateText(line.notes, 150)}</TableCell>
                  <TableCell>
                    {(line.pictures ?? []).map((x) => (
                      <FileThumbnail key={x.id} size={32} file={x} />
                    ))}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    </Paper>
  );
};
