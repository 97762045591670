import { CircularProgress } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import { IAttachmentFile } from 'shared/api/clients';
import { mimeToIcon } from 'components/Files/utils';
import { MuiIcon, RooIcon } from 'shared/icons';
import { RooDialog } from 'components';
import { apiProvider } from '../../shared/api/apiProvider';

export const FileThumbnail = ({ file, size = 64 }: { file: Omit<IAttachmentFile, 'urls'>; size?: number }) => {
  const [showPreview, setShowPreview] = useState(false);
  const sizeStr = `${size}px`;

  if (file == null) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: size,
          height: size,
          backgroundColor: '#cdd0d5'
        }}
      >
        <MuiIcon.Image sx={{ color: 'white', width: size / 3, height: size / 3 }} />
      </Box>
    );
  }
  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', verticalAlign: 'center' }}>
        {file.mimeType.startsWith('image') && file.mimeType !== 'image/heic' && file.mimeType !== 'image/heif' ? (
          <ThumbnailImage
            onClick={() => setShowPreview(true)}
            size={size}
            previewUrl={apiProvider.fileUrls.thumbnail(file.id)}
          />
        ) : (
          <a target={'_blank'} href={apiProvider.fileUrls.serve(file.id)}>
            <RooIcon
              className={'text-primary'}
              icon={mimeToIcon(file.mimeType)}
              style={{ width: sizeStr, height: sizeStr }}
            />
          </a>
        )}
      </div>
      <FilePreview
        previewUrl={apiProvider.fileUrls.serve(file.id)}
        show={showPreview}
        onHide={() => setShowPreview(false)}
      />
    </>
  );
};

export const ThumbnailImage = ({
  size,
  previewUrl,
  onClick
}: {
  size: number;
  previewUrl: string;
  onClick: () => void;
}) => {
  const sizeStr = `${size}px`;
  const [isLoading, setIsLoading] = useState(true);
  return (
    <Box
      sx={{
        display: 'flex',
        position: 'relative',
        justifyContent: 'center',
        alignItems: 'center',
        width: sizeStr,
        height: sizeStr
      }}
    >
      {isLoading && <CircularProgress sx={{ position: 'absolute', margin: 'auto' }} />}
      <img
        src={previewUrl}
        style={{
          width: sizeStr,
          height: sizeStr,
          cursor: 'pointer',
          objectFit: 'cover',
          boxShadow: '0 0 10px 0 rgba(#000, 0.1)'
        }}
        onLoad={() => setIsLoading(false)}
        onClick={() => onClick()}
      />
    </Box>
  );
};

export const FilePreview = ({
  previewUrl,
  show,
  onHide
}: {
  previewUrl: string;
  show: boolean;
  onHide: () => void;
}) => {
  const [isLoading, setIsLoading] = useState(true);
  return (
    <RooDialog open={show} fullWidth maxWidth={'lg'} onClose={onHide} sx={{ justifyContent: 'center' }}>
      <RooDialog.Title onClose={onHide}>File Preview</RooDialog.Title>
      <RooDialog.Content>
        <div
          style={{
            height: 'auto',
            width: 'auto',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          {isLoading && <CircularProgress sx={{ position: 'absolute', margin: 'auto' }} />}
          <img
            onLoad={() => setIsLoading(false)}
            src={previewUrl}
            alt="file"
            style={{ width: 'auto', height: 'auto', maxWidth: '100%', maxHeight: '85vh', minHeight: '400px' }}
          />
        </div>
      </RooDialog.Content>
    </RooDialog>
  );
};
