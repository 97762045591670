import { IAddress } from 'shared/api/clients';

type WithCoordinates = { latitude?: number; longitude?: number };
const calculateDistanceInMiles = (addr1: WithCoordinates, addr2: WithCoordinates) => {
  if (addr1?.latitude == null || addr1?.longitude == null || addr2?.latitude == null || addr2?.longitude == null) {
    return null;
  }

  const pi = 0.017453292519943295; // Math.PI / 180
  const cos = Math.cos;
  const a =
    0.5 -
    cos((addr2?.latitude - addr1.latitude) * pi) / 2 +
    (cos(addr1.latitude * pi) * cos(addr2?.latitude * pi) * (1 - cos((addr2?.longitude - addr1.longitude) * pi))) / 2;

  const distanceKm = 12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
  return distanceKm / 1.609;
};

const generateGoogleMapsDirUrl = (address: IAddress) => {
  let encodedDest: string;
  const parts = [];
  if (address.address1 != null && address.address1.trim() !== '') {
    parts.push(address.address1.trim());
  }

  if (address.city != null && address.city.trim() !== '') {
    parts.push(address.city.trim());
  }

  if (address.state != null && address.state.trim() !== '') {
    if (address.zipCode != null && address.zipCode.trim() !== '') {
      parts.push(`${address.state} ${address.zipCode}`);
    } else {
      parts.push(address.state.trim());
    }
  }

  encodedDest = encodeURIComponent(parts.join(', '));
  return `https://www.google.com/maps/dir/?api=1&travelmode=driving&destination=${encodedDest}`;
};

// TODO: refactor this
const getStateByZipCode = (zipStr: string) => {
  const zipToken = zipStr.split('-')[0];
  if (zipToken.length !== 5) {
    return null;
  }

  const zipCode = parseInt(zipToken, 10);

  let stateCode: string;

  if (zipCode >= 35000 && zipCode <= 36999) {
    stateCode = 'AL'; //Alabama
  } else if (zipCode >= 99500 && zipCode <= 99999) {
    stateCode = 'AK'; //Alaska
  } else if (zipCode >= 85000 && zipCode <= 86999) {
    stateCode = 'AZ'; //Arizona
  } else if (zipCode >= 71600 && zipCode <= 72999) {
    stateCode = 'AR'; //Arkansas
  } else if (zipCode >= 90000 && zipCode <= 96699) {
    stateCode = 'CA'; //California
  } else if (zipCode >= 80000 && zipCode <= 81999) {
    stateCode = 'CO'; //Colorado
  } else if ((zipCode >= 6000 && zipCode <= 6389) || (zipCode >= 6391 && zipCode <= 6999)) {
    stateCode = 'CT'; //Connecticut
  } else if (zipCode >= 19700 && zipCode <= 19999) {
    stateCode = 'DE'; //Delaware
  } else if (zipCode >= 32000 && zipCode <= 34999) {
    stateCode = 'FL'; //Florida
  } else if ((zipCode >= 30000 && zipCode <= 31999) || (zipCode >= 39800 && zipCode <= 39999)) {
    stateCode = 'GA'; //Georgia
  } else if (zipCode >= 96700 && zipCode <= 96999) {
    stateCode = 'HI'; //Hawaii
  } else if (zipCode >= 83200 && zipCode <= 83999) {
    stateCode = 'ID'; //Idaho
  } else if (zipCode >= 60000 && zipCode <= 62999) {
    stateCode = 'IL'; //Illinois
  } else if (zipCode >= 46000 && zipCode <= 47999) {
    stateCode = 'IN'; //Indiana
  } else if (zipCode >= 50000 && zipCode <= 52999) {
    stateCode = 'IA'; //Iowa
  } else if (zipCode >= 66000 && zipCode <= 67999) {
    stateCode = 'KS'; //Kansas
  } else if (zipCode >= 40000 && zipCode <= 42999) {
    stateCode = 'KY'; //Kentucky
  } else if (zipCode >= 70000 && zipCode <= 71599) {
    stateCode = 'LA'; //Louisiana
  } else if (zipCode >= 3900 && zipCode <= 4999) {
    stateCode = 'ME'; //Maine
  } else if (zipCode >= 20600 && zipCode <= 21999) {
    stateCode = 'MD'; //Maryland
  } else if ((zipCode >= 1000 && zipCode <= 2799) || zipCode === 5501 || zipCode === 5544) {
    stateCode = 'MA'; //Massachusetts
  } else if (zipCode >= 48000 && zipCode <= 49999) {
    stateCode = 'MI'; //Michigan
  } else if (zipCode >= 55000 && zipCode <= 56899) {
    stateCode = 'MN'; //Minnesota
  } else if (zipCode >= 38600 && zipCode <= 39999) {
    stateCode = 'MS'; //Mississippi
  } else if (zipCode >= 63000 && zipCode <= 65999) {
    stateCode = 'MO'; //Missouri
  } else if (zipCode >= 59000 && zipCode <= 59999) {
    stateCode = 'MT'; //Montana'
  } else if (zipCode >= 27000 && zipCode <= 28999) {
    stateCode = 'NC'; //North Carolina
  } else if (zipCode >= 58000 && zipCode <= 58999) {
    stateCode = 'ND'; //North Dakota
  } else if (zipCode >= 68000 && zipCode <= 69999) {
    stateCode = 'NE'; //Nebraska
  } else if (zipCode >= 88900 && zipCode <= 89999) {
    stateCode = 'NV'; //Nevada
  } else if (zipCode >= 3000 && zipCode <= 3899) {
    stateCode = 'NH'; //New Hampshire
  } else if (zipCode >= 7000 && zipCode <= 8999) {
    stateCode = 'NJ'; //New Jersey
  } else if (zipCode >= 87000 && zipCode <= 88499) {
    stateCode = 'NM'; //New Mexico
  } else if ((zipCode >= 10000 && zipCode <= 14999) || zipCode === 6390 || zipCode === 501 || zipCode === 544) {
    stateCode = 'NY'; //New York
  } else if (zipCode >= 43000 && zipCode <= 45999) {
    stateCode = 'OH'; //Ohio
  } else if ((zipCode >= 73000 && zipCode <= 73199) || (zipCode >= 73400 && zipCode <= 74999)) {
    stateCode = 'OK'; //Oklahoma
  } else if (zipCode >= 97000 && zipCode <= 97999) {
    stateCode = 'OR'; //Oregon
  } else if (zipCode >= 15000 && zipCode <= 19699) {
    stateCode = 'PA'; //Pennsylvania
  } else if (zipCode >= 300 && zipCode <= 999) {
    stateCode = 'PR'; //Puerto Rico
  } else if (zipCode >= 2800 && zipCode <= 2999) {
    stateCode = 'RI'; //Rhode Island
  } else if (zipCode >= 29000 && zipCode <= 29999) {
    stateCode = 'SC'; //South Carolina
  } else if (zipCode >= 57000 && zipCode <= 57999) {
    stateCode = 'SD'; //South Dakota
  } else if (zipCode >= 37000 && zipCode <= 38599) {
    stateCode = 'TN'; //Tennessee
  } else if (
    (zipCode >= 75000 && zipCode <= 79999) ||
    (zipCode >= 73301 && zipCode <= 73399) ||
    (zipCode >= 88500 && zipCode <= 88599)
  ) {
    stateCode = 'TX'; //Texas
  } else if (zipCode >= 84000 && zipCode <= 84999) {
    stateCode = 'UT'; //Utah
  } else if (zipCode >= 5000 && zipCode <= 5999) {
    stateCode = 'VT'; //Vermont
  } else if ((zipCode >= 20100 && zipCode <= 20199) || (zipCode >= 22000 && zipCode <= 24699) || zipCode === 20598) {
    stateCode = 'VA'; //Virgina
  } else if (
    (zipCode >= 20000 && zipCode <= 20099) ||
    (zipCode >= 20200 && zipCode <= 20599) ||
    (zipCode >= 56900 && zipCode <= 56999)
  ) {
    stateCode = 'DC'; //Washington DC
  } else if (zipCode >= 98000 && zipCode <= 99499) {
    stateCode = 'WA'; //Washington
  } else if (zipCode >= 24700 && zipCode <= 26999) {
    stateCode = 'WV'; //West Virginia
  } else if (zipCode >= 53000 && zipCode <= 54999) {
    stateCode = 'WI'; //Wisconsin
  } else if (zipCode >= 82000 && zipCode <= 83199) {
    stateCode = 'WY'; //Wyoming
  } else {
    stateCode = null;
  }

  return stateCode;
};

export const rooGeo = {
  calculateDistanceInMiles,
  getStateByZipCode,
  generateGoogleMapsDirUrl
};
