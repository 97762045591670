import {
  boolFilterTemplate,
  CompanyDropdown,
  FilterBuilder,
  ServerGridState,
  useServerGrid
} from 'components/Issues/utils';
import { IconLink } from 'components/Links';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { apiProvider } from 'shared/api/apiProvider';
import {
  CapabilityType,
  DashboardStatCategory,
  IssueForGrid,
  IssuesForUserPayload,
  IssuesSortField,
  ITasksForUsersFilter,
  SortDirection,
  TasksForUsersFilter
} from 'shared/api/clients';
import { Routes } from 'shared/routing';
import { issueStatusOptions, rooEnum, useHasCapability, useIsMultiCompany } from 'shared/store';
import { KeyGetter, rooFmt } from 'shared/utils';

export type IssuesExternalFilter = Partial<
  Pick<
    TasksForUsersFilter,
    | 'isActive'
    | 'propertyId'
    | 'companyIds'
    | 'vendorIds'
    | 'subVendorIds'
    | 'stateCodes'
    | 'city'
    | 'startDate'
    | 'endDate'
    | 'onlyTopLevelIssues'
    | 'accountManagerId'
  >
> & {
  forCategory?: DashboardStatCategory;
};

export const useIssuesGrid = ({
  forceExpanded,
  externalFilters,
  onInitialLoad,
  onLoad
}: {
  forceExpanded: boolean;
  externalFilters: IssuesExternalFilter;
  onInitialLoad?: () => void;
  onLoad?: () => void;
}) => {
  const keys = new KeyGetter<IssueForGrid>();
  const filterBuilder = new FilterBuilder<IssueForGrid>();
  filterBuilder.addString('issue.friendlyId');
  filterBuilder.addString('property.address.address1');
  filterBuilder.addString('issue.title');
  filterBuilder.addString('cosmeticStatus');
  filterBuilder.addBoolean('property.isOccupied');
  filterBuilder.addId('managementCompany.id');

  const { forCategory, companyIds, ...restFilters } = externalFilters;

  return useServerGrid<IssueForGrid, IssuesExternalFilter>({
    forceExpanded: forceExpanded,
    externalFilters: externalFilters,
    initialSortField: keys.get('issue.dateModified'),
    initialSortDirection: SortDirection.Descending,
    initialFilters: filterBuilder.get(),
    onInitialLoad: onInitialLoad,
    onLoad: onLoad,
    idGetter: (item) => item.issue.id,
    dataGetter: async ({ sortDirection, externalFilters, filterExtractor, sortField, take, skip }) => {
      const extractor = filterExtractor.makeTyped<IssueForGrid>();
      const fieldEnum = fieldToEnum(sortField);
      const tableCompanyId = extractor.getString('managementCompany.id');

      const filters = {
        friendlyId: extractor.getString('issue.friendlyId'),
        address1: extractor.getString('property.address.address1'),
        address2: extractor.getString('property.address.address2'),
        createdAt: extractor.getInstant('issue.dateCreated'),
        modifiedAt: extractor.getInstant('issue.dateModified'),
        isOccupied: extractor.getBoolean('property.isOccupied'),
        issueStatus: extractor.getNumber('cosmeticStatus'),
        issueTitle: extractor.getString('issue.title'),
        companyIds: tableCompanyId == null ? externalFilters.companyIds : [tableCompanyId],
        ...restFilters
      } satisfies Partial<ITasksForUsersFilter>;

      const items = await apiProvider.issues.main.listForUser(
        new IssuesForUserPayload({
          filters: new TasksForUsersFilter(filters as any),
          category: externalFilters.forCategory,
          take: take,
          skip: skip,
          sortField: fieldEnum,
          sortDirection: sortDirection
        })
      );
      return {
        totalItems: items.totalItems,
        items: items.issues
      };
    }
  });
};

const fieldToEnum = (sortField: string) => {
  let finalSortField = IssuesSortField.Default;
  const keys = new KeyGetter<IssueForGrid>();
  switch (sortField) {
    case keys.get('issue.id'):
      finalSortField = IssuesSortField.IssueId;
      break;
    case keys.get('property.address.address1'):
      finalSortField = IssuesSortField.Address1;
      break;
    case keys.get('issue.title'):
      finalSortField = IssuesSortField.Title;
      break;
    case keys.get('cosmeticStatus'):
      finalSortField = IssuesSortField.Status;
      break;
    case keys.get('property.isOccupied'):
      finalSortField = IssuesSortField.IsOccupied;
      break;
    case keys.get('managementCompany.name'):
      finalSortField = IssuesSortField.CompanyName;
      break;
    case keys.get('issue.dateCreated'):
      finalSortField = IssuesSortField.CreatedAt;
      break;
    case keys.get('issue.dateModified'):
      finalSortField = IssuesSortField.ModifiedAt;
      break;
  }

  return finalSortField;
};

export const IssuesServerGrid = ({
  state,
  forceLinksNewWindow,
  showStatAmount,
  showSubtotal,
  showCompany,
  fullWidth = false
}: {
  state: ServerGridState<IssueForGrid, IssuesExternalFilter>;
  forceLinksNewWindow: boolean;
  showStatAmount: boolean;
  showSubtotal: boolean;
  showCompany: boolean;
  fullWidth?: boolean;
}) => {
  const isMultiCompany = useIsMultiCompany();
  const isVendor = useHasCapability(CapabilityType.Vendor);
  const needsCompanyCol = showCompany && (isMultiCompany || isVendor);
  const keys = new KeyGetter<IssueForGrid>();
  return (
    <div className={'workorder-container'} style={{ width: fullWidth ? '100%' : undefined }}>
      <DataTable
        lazy
        paginator
        rowsPerPageOptions={[10, 25, 50, 100]}
        {...state.tableHandlers}
        emptyMessage={<p className={'text-center'}>No issues</p>}
        size={'small'}
        dataKey="id"
      >
        <Column
          field={keys.get('issue.friendlyId')}
          header={'Issue ID'}
          filter
          showFilterMatchModes={false}
          showFilterOperator={false}
          showAddButton={false}
          sortable
          body={(row: IssueForGrid) => (
            <IconLink
              target={forceLinksNewWindow ? '_blank' : undefined}
              style={{ whiteSpace: 'nowrap' }}
              to={{ pathname: Routes.IssueView, params: { issueId: row.issue.id, workorderId: row.issue.workorderId } }}
              text={row.issue.friendlyId}
            />
          )}
        />

        {state.externalFilters.propertyId == null && (
          <Column
            field={keys.get('property.address.address1')}
            filter
            showFilterMatchModes={false}
            showFilterOperator={false}
            showAddButton={false}
            sortable
            header={'Address 1'}
          />
        )}
        <Column
          field={keys.get('issue.title')}
          filter
          showFilterMatchModes={false}
          showFilterOperator={false}
          showAddButton={false}
          sortable
          header={'Title'}
        />
        {needsCompanyCol && (
          <Column
            field={keys.get('managementCompany.name')}
            sortable
            filter
            filterField={keys.get('managementCompany.id')}
            showFilterMatchModes={false}
            showFilterOperator={false}
            showAddButton={false}
            filterElement={(options) => <CompanyDropdown options={options} />}
            header={'Company'}
          />
        )}

        <Column
          field={keys.get('cosmeticStatus')}
          filter
          showFilterMatchModes={false}
          showFilterOperator={false}
          showAddButton={false}
          filterElement={(options) => (
            <Dropdown
              value={options.value}
              options={issueStatusOptions}
              onChange={(e) => options.filterCallback(e.value, options.index)}
              placeholder="Select a Status"
              className="p-column-filter"
              showClear
            />
          )}
          body={(row: IssueForGrid) => rooEnum.issueStatus.display(row.cosmeticStatus)}
          sortable
          header={'Status'}
        />
        <Column
          field={keys.get('issue.dateCreated')}
          sortable
          header={'Created'}
          dataType={'date'}
          body={(row: IssueForGrid) => rooFmt.date(row.issue.dateCreated)}
        />
        <Column
          field={keys.get('issue.dateModified')}
          sortable
          header={'Modified'}
          dataType={'date'}
          body={(row: IssueForGrid) => rooFmt.date(row.issue.dateModified)}
        />
        {!showStatAmount && state.externalFilters.propertyId == null && (
          <Column
            field={keys.get('property.isOccupied')}
            sortable
            header={'Occupied'}
            filter
            showFilterMatchModes={false}
            dataType={'boolean'}
            filterElement={boolFilterTemplate}
            body={(row: IssueForGrid) => (row.property.isOccupied ? 'Yes' : 'No')}
          />
        )}
        <Column
          hidden={!showSubtotal}
          field={keys.get('subtotal')}
          header={'Subtotal'}
          body={(row: IssueForGrid) => rooFmt.moneyMaybe(row.subtotal, 'N/A')}
        />
        <Column
          hidden={!showStatAmount}
          field={keys.get('statValue')}
          header={'Amount'}
          body={(row: IssueForGrid) => rooFmt.moneyMaybe(row.statValue, 'N/A')}
        />
        <Column
          hidden={
            !showStatAmount ||
            (state.externalFilters?.forCategory !== DashboardStatCategory.IssProfitLossIncome &&
              state.externalFilters?.forCategory !== DashboardStatCategory.IssProfitLossCost)
          }
          field={keys.get('secondaryValue')}
          header={
            state.externalFilters?.forCategory === DashboardStatCategory.IssProfitLossIncome
              ? 'Total COGS'
              : 'Total Income'
          }
          body={(row: IssueForGrid) => rooFmt.moneyMaybe(row.secondaryValue, 'N/A')}
        />
      </DataTable>
    </div>
  );
};
