import { WithReqChildren } from 'shared/utils';

export const PageContentSection = ({ children }: WithReqChildren) => {
  return (
    <section id="content" className="account">
      <div className="content-wrap">
        <div className="container clearfix">
          <div className="page-inner pd-0-force">{children}</div>
        </div>
      </div>
    </section>
  );
};
