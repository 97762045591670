import { Disclosure } from '@roo/lib';
import { RooDialog } from './RooDialog';
import { useAppStore, useCurrentUser } from '../shared/store';
import React, { useMemo, useState } from 'react';
import { DataTable as PrimeDataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { RouterLink } from './Links';
import { Routes } from '../shared/routing';
import { rooFmt } from '../shared/utils';
import { Button } from '@mui/material';
import { Property } from '@roo/api';
import { useQuery } from '@tanstack/react-query';
import { apiProvider } from '../shared/api/apiProvider';
import { CenteredLoader } from './CenteredLoader';

export const PropertySelectionModal = ({
  disclosure,
  onSelect,
  companyId
}: {
  disclosure: Disclosure;
  onSelect: (val: Property) => void;
  companyId?: string;
}) => {
  return (
    <RooDialog open={disclosure.isOpen} onClose={disclosure.close} maxWidth={'xl'} fullWidth>
      <RooDialog.Title onClose={disclosure.close}>Select Property</RooDialog.Title>
      <ModalBody disclosure={disclosure} onSelect={onSelect} companyId={companyId} />
    </RooDialog>
  );
};

export const ModalBody = ({
  disclosure,
  onSelect,
  companyId
}: {
  disclosure: Disclosure;
  companyId?: string;
  onSelect: (val: Property) => void;
}) => {
  const currentUser = useCurrentUser();
  const { isLoading, data } = useQuery(['properties', 'list'], () =>
    apiProvider.propertiesClient.getAll(currentUser.id)
  );

  const filtered = useMemo(() => {
    if (companyId == null) {
      return data;
    }

    return (data ?? []).filter((x) => x.managementCompany?.id === companyId);
  }, [data, companyId]);

  const [filters] = useState({
    name: { operator: 'AND', constraints: [{ value: '', matchMode: 'contains' }] },
    'address.address1': { operator: 'AND', constraints: [{ value: '', matchMode: 'contains' }] },
    'address.address2': { operator: 'AND', constraints: [{ value: '', matchMode: 'contains' }] },
    'address.city': { operator: 'AND', constraints: [{ value: '', matchMode: 'contains' }] },
    'address.state': { operator: 'AND', constraints: [{ value: '', matchMode: 'contains' }] },
    'manager.fullName': { operator: 'AND', constraints: [{ value: '', matchMode: 'contains' }] },
    'owner.fullName': { operator: 'AND', constraints: [{ value: '', matchMode: 'contains' }] },
    isOccupied: { value: null, matchMode: 'equals' },
    isActive: { value: true, matchMode: 'equals' }
  });

  if (isLoading) {
    return <CenteredLoader />;
  }

  return (
    <PrimeDataTable filters={filters as any} paginator rows={10} value={filtered} dataKey={'id'}>
      <Column
        sortable
        filter
        showFilterMatchModes={false}
        showFilterOperator={false}
        showAddButton={false}
        field={'name'}
        header={'Name'}
        body={(row: Property) => (
          <RouterLink to={{ pathname: Routes.PropertyView, params: { id: row.id } }}>{row.name}</RouterLink>
        )}
      />
      <Column
        filter
        showFilterMatchModes={false}
        showFilterOperator={false}
        showAddButton={false}
        sortable
        header={'Address 1'}
        field={'address.address1'}
      />
      <Column
        filter
        showFilterMatchModes={false}
        showFilterOperator={false}
        showAddButton={false}
        sortable
        header={'Address 2'}
        field={'address.address2'}
      />
      <Column
        filter
        showFilterMatchModes={false}
        showFilterOperator={false}
        showAddButton={false}
        sortable
        header={'City'}
        field={'address.city'}
        style={{ width: 200 }}
      />
      <Column
        filter
        showFilterMatchModes={false}
        showFilterOperator={false}
        showAddButton={false}
        sortable
        header={'State'}
        field={'address.state'}
        style={{ width: 150 }}
      />
      <Column
        filter
        showFilterMatchModes={false}
        showFilterOperator={false}
        showAddButton={false}
        sortable
        field={'manager.fullName'}
        header={'PM'}
        body={(row: Property) => row.manager?.fullName ?? row.managementCompany?.name}
      />
      <Column
        filter
        showFilterMatchModes={false}
        showFilterOperator={false}
        showAddButton={false}
        sortable
        field={'owner.fullName'}
        header={'Owner'}
        body={(row: Property) => row.owner?.fullName ?? 'N/A'}
      />
      <Column
        showFilterMatchModes={false}
        sortable
        header={'Maint. limit'}
        field={'maintenanceLimit'}
        dataType="numeric"
        body={(row: Property) => rooFmt.moneyMaybe(row.maintenanceLimit, 'N/A')}
      />
      <Column
        header={'Actions'}
        body={(row: Property) => <Button onClick={() => onSelect(row)}>Choose</Button>}
        style={{ width: 110 }}
      />
    </PrimeDataTable>
  );
};
