import { Alert, CssBaseline, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ConfirmationModal, RooDialog } from 'components';
import { useCurrentIssue } from 'pages/Workorders/shared/CurrentIssueContext';
import React, { useState } from 'react';
import { apiProvider } from 'shared/api/apiProvider';
import { IssueAction, MarkWorkCompletePayload } from 'shared/api/clients';
import { MuiIcon } from 'shared/icons';
import { showSuccess } from 'shared/utils';
import { createModalStore } from '../../../../../shared/store';
import { issueActionList } from '../../../shared/types';
import { CompleteWorkStepForm } from './CompleteWorkStepForm';
import { CompleteWorkStepInvoiceReview } from './CompleteWorkStepInvoiceReview';
import { CompleteWorkStepInvoiceEdit } from './CompleteWorkStepInvoiceEdit';
import { useMutation } from '@tanstack/react-query';

const useCompleteWorkStore = createModalStore();

const CompleteWorkModalManager = () => {
  const isVisible = useCompleteWorkStore((x) => x.visible);
  const hide = useCompleteWorkStore((x) => x.actions.hide);
  const { subissues } = useCurrentIssue();
  const canSkip = subissues.length > 0;
  if (canSkip) {
    return <SkipListDialog visible={isVisible} onClose={hide} />;
  }
  return <CreateChecklistDialog visible={isVisible} onClose={hide} />;
};

export const completeWorkAction = issueActionList.register({
  Icon: MuiIcon.Check,
  color: 'success',
  isDisabled: () => false,
  key: 'complete-work',
  label: 'Complete',
  isVisible: (ctx) => ctx.canAct(IssueAction.CompleteWork),
  onClick: () => {
    useCompleteWorkStore.getState().actions.show();
  },
  modalToRender: <CompleteWorkModalManager />
});

const SkipListDialog = ({ visible, onClose }: { visible: boolean; onClose: () => void }) => {
  const { issue, onIssueUpdate } = useCurrentIssue();
  const { mutate, isLoading } = useMutation({
    mutationFn: () =>
      apiProvider.issues.workflow.markWorkComplete(
        new MarkWorkCompletePayload({
          issueId: issue.id,
          oldStatus: issue.status,
          lines: []
        })
      ),
    onSuccess: (result) => {
      onIssueUpdate(result.issueView);
      showSuccess();
      onClose();
    }
  });

  return (
    <ConfirmationModal
      visible={visible}
      onClose={onClose}
      onSave={mutate}
      running={isLoading}
      question={
        <Stack spacing={2}>
          <Alert color={'success'}>
            You aren't required to provide any details because you hired a subcontractor for this job.
          </Alert>
          <Typography>Is the job complete?</Typography>
        </Stack>
      }
    />
  );
};

type DialogStep = 'form' | 'invoice-review' | 'invoice-edit';
type DialogState = { step: DialogStep; invoiceId: string };

const CreateChecklistDialog = ({ visible, onClose }: { visible: boolean; onClose: () => void }) => {
  const theme = useTheme();
  const isLarge = useMediaQuery(theme.breakpoints.up('md'));
  const [state, setState] = useState<DialogState>({ step: 'form', invoiceId: null });

  return (
    <RooDialog
      scroll={'paper'}
      fullScreen={!isLarge}
      maxWidth={isLarge ? 'md' : undefined}
      fullWidth
      open={visible}
      onClose={(e, reason) => {
        if (reason === 'backdropClick') {
          return;
        }
        onClose();
      }}
    >
      <CssBaseline />
      {state.step === 'form' && (
        <CompleteWorkStepForm
          onClose={onClose}
          onContinue={(invoiceId) => {
            if (invoiceId) {
              setState({ step: 'invoice-review', invoiceId });
            } else {
              onClose();
            }
          }}
        />
      )}
      {state.step === 'invoice-review' && state.invoiceId && (
        <CompleteWorkStepInvoiceReview
          onClose={onClose}
          requestEdit={() => {
            setState((x) => ({ ...x, step: 'invoice-edit' }));
          }}
          invoiceId={state.invoiceId}
        />
      )}
      {state.step === 'invoice-edit' && state.invoiceId && (
        <CompleteWorkStepInvoiceEdit onClose={onClose} onContinue={onClose} invoiceId={state.invoiceId} />
      )}
    </RooDialog>
  );
};
