import { createContext, useContext } from 'react';
import { IIssueUserView, IssueAction } from 'shared/api/clients';

export type CurrentIssueContextType = IIssueUserView & {
  onIssueUpdate?: (updatedIssue: Partial<IIssueUserView>) => void;
  canAct: (action: IssueAction) => boolean;
};

export const CurrentIssueContext = createContext<CurrentIssueContextType>(null);

export const useCurrentIssue = () => {
  const ctx = useContext<CurrentIssueContextType>(CurrentIssueContext);
  if (ctx == null || ctx.issue == null) {
    throw new Error('Tried to access empty IssueContext');
  }

  return ctx;
};
