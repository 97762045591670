import { Button, Grid } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { apiProvider } from 'shared/api/apiProvider';
import { Membership, RooPermission, SetUserPermissionsPayload, UserDetails } from 'shared/api/clients';
import { FieldMuiSwitch } from 'components/form';
import { RooDialog } from 'components';
import { rooEnum } from 'shared/store';
import { showSuccess } from 'shared/utils';
import { z } from 'zod';
import { UpdateUserHandler } from 'pages/UserProfile/ViewProfile/shared';

const PermissionsSchema = z.object({
  id: z.number(),
  enabled: z.boolean()
});

const EditUserPermissionsSchema = z.object({
  permissions: z.array(PermissionsSchema)
});

type EditUserPermissionsDefinition = z.infer<typeof EditUserPermissionsSchema>;

export const EditUserPermissionsModal = ({
  visible,
  userDetails,
  onUpdateUser,
  onCloseRequest,
  membership,
  potentialPermissions
}: {
  visible: boolean;
  userDetails: UserDetails;
  onUpdateUser: UpdateUserHandler;
  onCloseRequest: () => void;
  membership: Membership;
  potentialPermissions: RooPermission[];
}) => {
  const { handleSubmit, control, formState } = useForm<EditUserPermissionsDefinition>({
    defaultValues: {
      permissions: potentialPermissions.map((x) => ({
        id: x,
        enabled: membership.permissions.indexOf(x) > -1
      }))
    }
  });

  const { fields } = useFieldArray({
    control,
    name: 'permissions'
  });

  const submit = async (values: EditUserPermissionsDefinition) => {
    try {
      const perms = values.permissions.filter((x) => x.enabled).map((x) => x.id);
      await apiProvider.usersClient.setUserPermissions(
        new SetUserPermissionsPayload({
          permissions: perms,
          userId: userDetails.user.id,
          managementCompanyId: membership.managementCompanyId
        })
      );

      onUpdateUser((draft) => {
        const currMembership = draft.memberships.find((x) => x.managementCompanyId === membership.managementCompanyId);
        if (currMembership == null) {
          return;
        }

        currMembership.permissions = perms;
      });
      onCloseRequest();
      showSuccess();
    } catch (err) {
      console.log('err: ', err);
    }
  };
  return (
    <RooDialog maxWidth={'sm'} fullWidth open={visible} onClose={onCloseRequest}>
      <RooDialog.Title onClose={onCloseRequest}>Edit User Permissions</RooDialog.Title>
      <Box
        component="form"
        noValidate
        sx={{ p: 3, mb: 4 }}
        style={{ marginBottom: 'initial' }}
        onSubmit={handleSubmit(submit)}
      >
        <RooDialog.Content>
          <Grid container direction="column" justifyContent="center" alignItems="center">
            <table>
              <tbody>
                {fields.map((permission, index) => (
                  <tr>
                    <td>{rooEnum.permission.display(permission.id)}</td>
                    <td style={{ paddingLeft: '20px' }}>
                      <FieldMuiSwitch
                        control={control}
                        placeholder={rooEnum.permission.display(permission.id)}
                        name={`permissions.${index}.enabled`}
                        label={rooEnum.permission.display(permission.id)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Grid>
        </RooDialog.Content>
        <RooDialog.Actions>
          <Button disabled={formState.isSubmitting} type="submit" color="primary" size="small">
            {formState.isSubmitting && (
              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
            )}
            {formState.isSubmitting ? 'Saving...' : 'Save'}
          </Button>
          &nbsp;&nbsp;
          <Button
            color="muted"
            disabled={formState.isSubmitting || formState.isValidating}
            size="small"
            onClick={onCloseRequest}
          >
            Cancel
          </Button>
        </RooDialog.Actions>
      </Box>
    </RooDialog>
  );
};
