import { PaymentStatus } from 'shared/api/clients';
import { ColoredEnumDisplay, createMap, EnumMap } from 'shared/store/enums/shared';

const InternalMapPaymentStatus: EnumMap<typeof PaymentStatus, ColoredEnumDisplay> = {
  Unknown: {
    display: 'Unknown',
    color: 'dark'
  },
  PaymentSent: {
    display: 'Payment Sent',
    color: 'warning'
  },
  Paid: {
    display: 'Paid',
    color: 'success'
  },
  Failed: {
    display: 'Failed',
    color: 'danger'
  }
};

export const EnumMapPaymentStatus = createMap<PaymentStatus, ColoredEnumDisplay>(
  PaymentStatus,
  InternalMapPaymentStatus
);
