import { DeepRequired } from 'shared/utils';
import { z } from 'zod';

export const AttachmentFileSchema = z.object({
  id: z.string(),
  fileName: z.string(),
  size: z.number(),
  urls: z.object({
    thumbnailUrl: z.string(),
    downloadUrl: z.string(),
    serveUrl: z.string()
  }),
  createdAt: z.date().nullish(),
  mimeType: z.string()
});

export type AttachmentFileDefinition = DeepRequired<z.infer<typeof AttachmentFileSchema>>;

export const LineSchema = z.object({
  description: z.string(),
  notes: z.string().nullish(),
  value: z.number(),
  initialValue: z.number().nullish(),
  resaleValue: z.number().nullish(),
  type: z.number(),
  sourceEntityId: z.string().nullish(),
  beforeFiles: z.array(AttachmentFileSchema),
  afterFiles: z.array(AttachmentFileSchema),
  canEditDescription: z.boolean().nullish(),
  canEditValue: z.boolean().nullish()
});

export type LineDefinition = DeepRequired<z.infer<typeof LineSchema>>;

export const TaxableLinesSchema = z.object({
  taxPercent: z.number().nonnegative(),
  resaleTaxPercent: z.number().nonnegative().nullish(),
  discountPercent: z.number().nonnegative().nullish(),
  previousDiscount: z.number().nullish(),
  partialAmount: z.number().nullish(),
  didSetPartialAmount: z.boolean().nullish(),
  partialPercent: z.number().nullish(),
  didSetPartialPercent: z.boolean().nullish(),
  lines: z.array(LineSchema)
});

export type TaxableLinesDefinition = DeepRequired<z.infer<typeof TaxableLinesSchema>>;
export type WithLineCollection = { lineCollection: TaxableLinesDefinition };
