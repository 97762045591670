import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export * as MuiIcon from '@mui/icons-material';

export const loadIcons = () => {
  library.add(fab, fas);
};

export const RooIcon = FontAwesomeIcon;
