import { MutableRefObject, useLayoutEffect, useState } from 'react';

export const useContainerHeight = ({
  defaultHeight,
  itemRef
}: {
  defaultHeight: number;
  itemRef: MutableRefObject<HTMLElement>;
}) => {
  const [height, setHeight] = useState(defaultHeight);
  useLayoutEffect(() => {
    if (itemRef.current == null) {
      return;
    }

    const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
    const rect = itemRef.current.getBoundingClientRect();

    setHeight(vh - rect.top - 100);
  }, [itemRef]);

  return height;
};
