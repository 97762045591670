// pulled from the zustand repo, since they're deprecating it

import {
  ReactNode,
  createElement,
  useContext,
  useMemo,
  useRef,
  createContext,
} from "react";
import { StoreApi, useStore } from "zustand";

type UseContextStore<S extends StoreApi<unknown>> = {
  (): ExtractState<S>;
  <U>(
    selector: (state: ExtractState<S>) => U,
    equalityFn?: (a: U, b: U) => boolean,
  ): U;
};

type ExtractState<S> = S extends { getState: () => infer T } ? T : never;

type WithoutCallSignature<T> = { [K in keyof T]: T[K] };

function createStoreContext<S extends StoreApi<unknown>>() {
  const ZustandContext = createContext<S | undefined>(undefined);

  const Provider = ({
    createStore,
    children,
  }: {
    createStore: () => S;
    children: ReactNode;
  }) => {
    const storeRef = useRef<S>();

    if (!storeRef.current) {
      storeRef.current = createStore();
    }

    return createElement(
      ZustandContext.Provider,
      { value: storeRef.current },
      children,
    );
  };

  const useContextStore: UseContextStore<S> = <StateSlice = ExtractState<S>>(
    selector?: (state: ExtractState<S>) => StateSlice,
    equalityFn?: (a: StateSlice, b: StateSlice) => boolean,
  ) => {
    const store = useContext(ZustandContext);
    if (!store) {
      throw new Error(
        "Seems like you have not used zustand provider as an ancestor.",
      );
    }
    return useStore(
      store,
      selector as (state: ExtractState<S>) => StateSlice,
      equalityFn,
    );
  };

  const useStoreApi = () => {
    const store = useContext(ZustandContext);
    if (!store) {
      throw new Error(
        "Seems like you have not used zustand provider as an ancestor.",
      );
    }
    return useMemo<WithoutCallSignature<S>>(() => ({ ...store }), [store]);
  };

  return {
    Provider,
    useStore: useContextStore,
    useStoreApi,
  };
}

export { createStoreContext };
