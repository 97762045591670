import { CircularProgress } from '@mui/material';
import { ReactNode } from 'react';
import { Center } from './Center';

export const CenteredLoader = ({ children }: { children?: ReactNode }) => {
  return (
    <Center p={'xl'}>
      <CircularProgress size={64} />
    </Center>
  );
};
