import {
  Box,
  Card,
  CardContent,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { apiProvider } from 'shared/api/apiProvider';
import { CapabilityType, UserAccount } from 'shared/api/clients';
import { Routes } from 'shared/routing';
import { rooEnum } from 'shared/store';
import { ContentWrapper, MuiRouterLink, NavSlim, RouterIconButton, RouterLink } from 'components';
import { rooFmt } from 'shared/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { CompanyInfoMoreButton } from './CompanyInfoModal';
import { MuiIcon } from 'shared/icons';
import { UserActionsMenu } from './UserActionsMenu';

type YesNoFilter = 'yes' | 'no' | 'all';

const stringMatch = (needle: string, haystack: string) => {
  if (needle === '') {
    return true;
  }

  if (haystack == null || haystack === '') {
    return false;
  }

  return haystack.toUpperCase().indexOf(needle.toUpperCase()) >= 0;
};

const applyFilters = (
  filterVals: {
    firstName: string;
    lastName: string;
    email: string;
    capability: CapabilityType;
    companyName: string;
    vendorName: string;
    isEnabled: YesNoFilter;
    didLogIn: YesNoFilter;
  },
  accounts: UserAccount[]
) => {
  const { firstName, lastName, email, capability, companyName, vendorName, isEnabled, didLogIn } = filterVals;
  return accounts.filter((x) => {
    if (!stringMatch(firstName, x.firstName)) {
      return false;
    }
    if (!stringMatch(lastName, x.lastName)) {
      return false;
    }

    if (!stringMatch(email, x.email)) {
      return false;
    }

    if (capability != null && x.capabilities.find((x) => x.type === capability) == null) {
      return false;
    }

    if (Boolean(companyName) && x.companies.find((x) => stringMatch(companyName, x.companyName)) == null) {
      return false;
    }

    if (!stringMatch(vendorName, x.vendor?.companyName)) {
      return false;
    }

    if (isEnabled !== 'all') {
      return (x.enabled && isEnabled === 'yes') || (!x.enabled && isEnabled === 'no');
    }

    if (didLogIn !== 'all') {
      return (x.firstLogin != null && didLogIn === 'yes') || (x.firstLogin == null && didLogIn === 'no');
    }

    return true;
  });
};

export const AccountsList = () => {
  const [{ loading, accounts, filteredAccounts }, setState] = useState<{
    loading: boolean;
    accounts: UserAccount[];
    filteredAccounts: UserAccount[];
  }>({
    loading: true,
    accounts: [],
    filteredAccounts: []
  });

  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [capability, setCapability] = useState<CapabilityType>(null);
  const [companyName, setCompanyName] = useState<string>('');
  const [vendorName, setVendorName] = useState<string>('');
  const [isEnabled, setIsEnabled] = useState<YesNoFilter>('all');
  const [didLogIn, setDidLogIn] = useState<YesNoFilter>('all');

  useEffect(() => {
    if (accounts == null || accounts.length === 0) {
      return;
    }

    setState({
      loading,
      accounts,
      filteredAccounts: applyFilters(
        { firstName, lastName, email, capability, companyName, vendorName, isEnabled, didLogIn },
        accounts
      )
    });
  }, [firstName, lastName, email, capability, companyName, vendorName, isEnabled, didLogIn, accounts, loading]);

  const pull = async () => {
    try {
      const accounts = await apiProvider.adminClient.listAccounts(null, null);
      setState({
        loading: false,
        accounts: accounts,
        filteredAccounts: applyFilters(
          { firstName, lastName, email, capability, companyName, vendorName, isEnabled, didLogIn },
          accounts
        )
      });
    } catch (e) {
      setState({ loading: false, accounts: [], filteredAccounts: [] });
    }
  };

  useEffect(() => {
    void pull();
    //eslint-disable-next-line
  }, []);

  return (
    <>
      <Helmet>
        <title>Accounts - Admin - Walkthroo</title>
      </Helmet>
      <NavSlim breadcrumbs={[{ text: 'Accounts' }]} />
      <ContentWrapper>
        <Stack spacing={3}>
          <Card>
            <CardContent>
              <Typography variant={'h5'}>Filters</Typography>
              <Grid container spacing={2} sx={{ marginTop: '8px' }}>
                <Grid item>
                  <TextField
                    size={'small'}
                    label={'First Name'}
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    size={'small'}
                    label={'Last Name'}
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </Grid>
                <Grid item>
                  <TextField size={'small'} label={'Email'} value={email} onChange={(e) => setEmail(e.target.value)} />
                </Grid>
                <Grid item>
                  <Box sx={{ minWidth: 250 }}>
                    <FormControl fullWidth>
                      <InputLabel id="capability-label" size={'small'}>
                        Capability
                      </InputLabel>
                      <Select
                        size={'small'}
                        labelId="capability-label"
                        value={capability?.toString() as any}
                        label="Capability"
                        onChange={(ev) => setCapability(ev.target.value == null ? null : parseInt(ev.target.value))}
                      >
                        <MenuItem value={null}>Any</MenuItem>
                        <MenuItem value={CapabilityType.ManagementCompanyOwner}>Company Owner</MenuItem>
                        <MenuItem value={CapabilityType.PropertyManager}>Property Manager</MenuItem>
                        <MenuItem value={CapabilityType.PropertyOwner}>Property Owner</MenuItem>
                        <MenuItem value={CapabilityType.Vendor}>Vendor</MenuItem>
                        <MenuItem value={CapabilityType.Tenant}>Tenant</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item>
                  <TextField
                    size={'small'}
                    label={'Company Name'}
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    size={'small'}
                    label={'Vendor Name'}
                    value={vendorName}
                    onChange={(e) => setVendorName(e.target.value)}
                  />
                </Grid>
                <Grid item>
                  <ToggleButtonGroup
                    color="primary"
                    value={isEnabled}
                    exclusive
                    size={'small'}
                    onChange={(_, val) => setIsEnabled(val)}
                    aria-label="Enabled"
                  >
                    <ToggleButton value="all">All</ToggleButton>
                    <ToggleButton value="yes">Enabled</ToggleButton>
                    <ToggleButton value="no">Disabled</ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item>
                  <ToggleButtonGroup
                    color="primary"
                    value={didLogIn}
                    exclusive
                    size={'small'}
                    onChange={(_, val) => setDidLogIn(val)}
                    aria-label="Did Log In"
                  >
                    <ToggleButton value="all">All</ToggleButton>
                    <ToggleButton value="yes">Did Log In</ToggleButton>
                    <ToggleButton value="no">Never Logged In</ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <DataTable
            sortField={'createdDate'}
            sortOrder={-1}
            loading={loading}
            value={filteredAccounts}
            paginator
            rows={10}
          >
            <Column sortable field={'firstName'} header={'First Name'} />
            <Column sortable field={'lastName'} header={'Last Name'} />
            <Column sortable field={'email'} header={'Email'} />
            <Column
              sortable
              field={'enabled'}
              header={'Enabled'}
              body={(row: UserAccount) => (row.enabled ? 'Yes' : 'No')}
            />
            <Column
              header={'Companies'}
              headerClassName={'column-center'}
              body={(row: UserAccount) => <CompanyInfo row={row} />}
            />
            <Column header={'Vendor'} body={(row: UserAccount) => <VendorInfo row={row} />} />

            <Column
              sortable
              field={'createdDate'}
              header={'Created'}
              body={(row: UserAccount) => rooFmt.date(row.createdDate)}
            />
            <Column
              sortable
              field={'firstLogin'}
              header={'First Login'}
              body={(row: UserAccount) => rooFmt.dateMaybe(row.firstLogin, 'N/A')}
            />
            <Column
              sortable
              field={'lastLogin'}
              header={'Last Login'}
              body={(row: UserAccount) => rooFmt.dateMaybe(row.lastLogin, 'N/A')}
            />
            <Column
              body={(row: UserAccount) => (
                <Stack px={2} spacing={2} direction={'row'} flexWrap={'wrap'} justifyContent={'flex-end'}>
                  <RouterIconButton
                    color={'primary'}
                    target={'_blank'}
                    to={{ pathname: Routes.UserProfile, params: { id: row.userId } }}
                  >
                    <MuiIcon.Visibility />
                  </RouterIconButton>
                  <UserActionsMenu key={row.userId} row={row} requestRefresh={pull} />
                </Stack>
              )}
            />
          </DataTable>
        </Stack>
      </ContentWrapper>
    </>
  );
};

export const VendorInfo = ({ row }: { row: UserAccount }) => {
  if (row.vendor == null) {
    return null;
  }

  const tags = [];
  if (row.vendor.showInPool) {
    tags.push('Pool');
  }
  if (row.vendor.isPinned) {
    tags.push('Pinned');
  }
  if (row.vendor.isGeneralContractor) {
    tags.push('GC');
  }

  return (
    <Stack>
      <MuiRouterLink target={'blank'} to={{ pathname: Routes.VendorProfile, params: { vendorId: row.vendor.id } }}>
        {row.vendor.companyName}
      </MuiRouterLink>
      <Typography fontSize={'small'} color={'gray.400'}>
        {tags.join(', ')}
      </Typography>
    </Stack>
  );
};

export const CompanyInfo = ({ row }: { row: UserAccount }) => {
  if (row.companies.length === 0) {
    return null;
  }

  return (
    <Stack justifyContent={'center'} alignItems={'center'}>
      <MuiRouterLink
        target={'_blank'}
        to={{ pathname: Routes.CompanyProfile, params: { companyId: row.companies[0].managementCompanyId } }}
      >
        {row.companies[0].companyName}
      </MuiRouterLink>
      <Typography fontSize={'small'} color={'gray.400'}>
        {row.companies[0].capabilities.map((x) => rooEnum.capabilityType.display(x)).join(', ')}
      </Typography>
      {row.companies.length > 1 && (
        <div>
          <CompanyInfoMoreButton row={row} />
        </div>
      )}
    </Stack>
  );
};
