import { Avatar } from '@mui/material';

type KnownSize = 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl';
type AvatarProps = React.ComponentProps<typeof Avatar>;

const sizeMap: Record<KnownSize, { size: number; fontSize: number }> = {
  xs: { size: 22, fontSize: 10 },
  s: { size: 24, fontSize: 12 },
  m: { size: 32, fontSize: 14 },
  l: { size: 40, fontSize: 19 },
  xl: { size: 64, fontSize: 26 },
  xxl: { size: 130, fontSize: 50 }
};

const stringToColor = (string: string) => {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
};

const getInitials = (name: string) => {
  const tokens = name.split(' ').filter((x) => x !== '');
  if (tokens.length === 0) {
    return 'A';
  }

  if (tokens.length === 1) {
    if (tokens[0].length === 1) {
      return tokens[0];
    }

    return tokens[0].substring(0, 2);
  }

  return tokens[0][0] + tokens[1][0];
};

const getContrastFontColor = (backgroundColor: string) => {
  let hexColor = backgroundColor.replace('#', '');
  let r = parseInt(hexColor.substr(0, 2), 16);
  let g = parseInt(hexColor.substr(2, 2), 16);
  let b = parseInt(hexColor.substr(4, 2), 16);
  let yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? 'black' : 'white';
};

type RooAvatarProps = AvatarProps & { name: string; avatarUrl: string; size?: KnownSize };

export const RooAvatar = ({ name, avatarUrl, size = 'm', style, children, ...rest }: RooAvatarProps) => {
  const boxSize = sizeMap[size].size;
  const fontSize = sizeMap[size].fontSize;
  const bgColor = stringToColor(name);
  const fontColor = getContrastFontColor(bgColor);
  const body = children ?? getInitials(name);

  return (
    <Avatar
      {...rest}
      alt={name}
      src={avatarUrl}
      component={'span'}
      style={{
        ...{
          width: boxSize,
          height: boxSize,
          fontSize: fontSize,
          backgroundColor: stringToColor(name),
          display: 'inline-flex',
          color: fontColor
        },
        ...style
      }}
    >
      {body}
    </Avatar>
  );
};
