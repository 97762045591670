import { Button, Typography } from '@mui/material';
import { useState } from 'react';
import { apiProvider } from 'shared/api/apiProvider';
import { LegalLinks } from 'shared/routing';
import { useAppStore, useCurrentUser } from 'shared/store';
import theme from 'shared/theme';
import { RooDialog } from 'components';

export const EulaModal = () => {
  const user = useCurrentUser();
  const visible = useAppStore((x) => x.forceEulaModal);
  const onEulaAccepted = useAppStore((x) => x.actions.onEulaAccepted);
  const [loading, setLoading] = useState(false);
  const buttonText = 'I Accept';

  const accept = async () => {
    setLoading(true);
    try {
      await apiProvider.usersClient.acceptEula();
      onEulaAccepted();
    } catch (e) {}
    setLoading(false);
  };

  return (
    <RooDialog fullWidth maxWidth={'sm'} open={visible}>
      <RooDialog.Title>Welcome to Walkthroo!</RooDialog.Title>
      <RooDialog.Content>
        <Typography variant={'subtitle1'} sx={{ textAlign: 'center' }}>
          By clicking "{buttonText}", you agree to our{' '}
          <a href={LegalLinks.Privacy} target="_blank" style={{ color: theme.palette.primary.main }} rel="noreferrer">
            Privacy Policy
          </a>{' '}
          and{' '}
          <a
            href={LegalLinks.TermsOfUse}
            target="_blank"
            style={{ color: theme.palette.primary.main }}
            rel="noreferrer"
          >
            Terms of Use Agreement
          </a>
        </Typography>
      </RooDialog.Content>
      <RooDialog.Actions>
        <Button disabled={user == null || loading} onClick={() => void accept()}>
          {buttonText}
        </Button>
      </RooDialog.Actions>
    </RooDialog>
  );
};
