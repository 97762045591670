import { LoaderSmall } from 'components';
import { useEffect, useState } from 'react';
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { apiProvider } from 'shared/api/apiProvider';
import { useCurrentUser } from 'shared/store';

export const VendorsWithOpenIssues = ({
  companyId,
  vendorIds,
  stateCode,
  city
}: {
  companyId: string;
  vendorIds: string[];
  stateCode: string;
  city: string;
}) => {
  const currentUser = useCurrentUser();
  const [data, setData] = useState([]);
  const [drillDown, setDrillDown] = useState([]);
  const [vendor, setVendor] = useState('');
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const getData = async () => {
      const d = await apiProvider.dashboardClient.vendorsWithOpenIssues({
        city,
        companyId,
        stateCode,
        vendorIds,
        userId: currentUser.id
      } as any);
      setData(d);
      setLoading(false);
    };
    void getData();
  }, [currentUser.id, city, companyId, stateCode, vendorIds]);

  const tooltipFormatter = (value: any, name: any, props: any) => {
    if (name === 'count1') {
      name = '0-3 day';
    } else if (name === 'count2') {
      name = '3+ day';
    } else if (name === 'count3') {
      name = '5+ day';
    } else if (name === 'count4') {
      name = '7+ day';
    }
    return [value, name];
  };

  const handleClick = async (data: any, index: number) => {
    var vendorId = data.activePayload[0].payload.id;
    setLoading(true);
    setVendor(data.activePayload[0].payload.companyName);
    var d = await apiProvider.dashboardClient.vendorsWithOpenIssuesDrillDown(vendorId);
    setDrillDown(d);
    setLoading(false);
  };

  return (
    <div>
      {data.length > 0 && (
        <>
          {!vendor ? (
            <h3 style={{ fontWeight: 400 }}>Vendors with open issues</h3>
          ) : (
            <h3 style={{ fontWeight: 400 }}>
              {vendor}{' '}
              <button
                className="btn btn-secondary"
                style={{ float: 'right' }}
                onClick={() => {
                  setVendor('');
                  setLoading(false);
                }}
              >
                back
              </button>
            </h3>
          )}
          <div className="bg-white" style={{ paddingRight: 20, paddingBottom: 10, paddingTop: 10 }}>
            {loading ? (
              <div className="dashboard-loader-container">
                <LoaderSmall />
              </div>
            ) : !vendor ? (
              <ResponsiveContainer minHeight={Math.min(400, data.length * 80)}>
                <BarChart layout="vertical" data={data} style={{ margin: 0 }} onClick={handleClick}>
                  <XAxis type="number" orientation="top" />
                  <YAxis type="category" dataKey="companyName" width={120} tick={{ fontSize: 10 }} />
                  <Tooltip formatter={tooltipFormatter} />
                  <Bar dataKey="count1" stackId="a" fill="#00D0B5" />
                  <Bar dataKey="count2" stackId="a" fill="#D8D800" />
                  <Bar dataKey="count3" stackId="a" fill="#ED6C02" />
                  <Bar dataKey="count4" stackId="a" fill="#D32F2F" />
                </BarChart>
              </ResponsiveContainer>
            ) : (
              <ResponsiveContainer minHeight={Math.min(400, drillDown.length * 80)}>
                <BarChart layout="vertical" data={drillDown} style={{ margin: 0 }}>
                  <XAxis type="number" orientation="top" />
                  <YAxis type="category" dataKey="focusName" width={120} tick={{ fontSize: 10 }} />
                  <Tooltip formatter={tooltipFormatter} />
                  <Bar dataKey="count1" stackId="a" fill="#00D0B5" />
                  <Bar dataKey="count2" stackId="a" fill="#D8D800" />
                  <Bar dataKey="count3" stackId="a" fill="#ED6C02" />
                  <Bar dataKey="count4" stackId="a" fill="#D32F2F" />
                </BarChart>
              </ResponsiveContainer>
            )}
          </div>
        </>
      )}
    </div>
  );
};
