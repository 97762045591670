import { Button, Grid, IconButton, List, ListItem, ListItemText, ListSubheader, Stack } from '@mui/material';
import { ManagementCompany, UpdateCompanyMiscSettingsPayload } from '../../../shared/api/clients';
import { rooFmt } from '../../../shared/utils';
import { MuiIcon } from '../../../shared/icons';
import { RooDialog } from '../../../components';
import { FieldMuiSwitch, useRooForm } from '../../../components/form';
import { apiProvider } from '../../../shared/api/apiProvider';
import { FieldMuiNumber } from 'components/form/FieldMuiNumber';
import { z } from 'zod';
import { useCompanyMutation } from '../../../shared/api/queries';
import { Disclosure, useDisclosure } from '@roo/lib';

export const MiscSettingsWidget = ({ company }: { company: ManagementCompany }) => {
  const disclosure = useDisclosure(false);

  return (
    <List
      className={'profile'}
      subheader={
        <Stack direction={'row'}>
          <ListSubheader>
            <MuiIcon.MiscellaneousServices /> Manager Info
          </ListSubheader>
          <IconButton color={'primary'} onClick={() => disclosure.open()}>
            <MuiIcon.Edit />
          </IconButton>
        </Stack>
      }
    >
      <ListItem>
        <ListItemText
          primary={rooFmt.numberMaybe(company.doorsUnderManagement, '-', 0)}
          secondary={'Doors Under Management'}
        />
      </ListItem>
      <ListItem>
        <ListItemText primary={company.isSoftwareClient ? 'Yes' : 'No'} secondary={'Software client?'} />
      </ListItem>
      <ListItem>
        <ListItemText primary={rooFmt.moneyMaybe(company.dollarsPerDoor, '-')} secondary={'$ per Door'} />
      </ListItem>

      <UpdateMiscSettingsModal disclosure={disclosure} company={company} />
    </List>
  );
};

const UpdateMiscSettingsModal = ({ disclosure, company }: { disclosure: Disclosure; company: ManagementCompany }) => {
  return (
    <RooDialog onClose={disclosure.close} fullWidth maxWidth={'sm'} open={disclosure.isOpen}>
      <RooDialog.Title onClose={disclosure.close}>Update Manager Info</RooDialog.Title>
      <UpdateMiscSettingsForm disclosure={disclosure} company={company} />
    </RooDialog>
  );
};

const ManagerInfoSchema = z.object({
  doorsUnderManagement: z.number().nullish(),
  dollarsPerDoor: z.number().nullish(),
  isSoftwareClient: z.boolean()
});

type ManagerInfoDefinition = z.infer<typeof ManagerInfoSchema>;

const UpdateMiscSettingsForm = ({ disclosure, company }: { disclosure: Disclosure; company: ManagementCompany }) => {
  const { control, handleSubmit } = useRooForm(ManagerInfoSchema, {
    defaultValues: {
      doorsUnderManagement: company.doorsUnderManagement,
      dollarsPerDoor: company.dollarsPerDoor,
      isSoftwareClient: company.isSoftwareClient
    }
  });

  const { isLoading, mutateAsync } = useCompanyMutation({
    mutationFn: (values: ManagerInfoDefinition) =>
      apiProvider.companiesClient.updateMiscSettings(
        new UpdateCompanyMiscSettingsPayload({
          doorsUnderManagement: values.doorsUnderManagement,
          dollarsPerDoor: values.dollarsPerDoor,
          isSoftwareClient: values.isSoftwareClient,
          companyId: company.id
        })
      ),
    onSuccess: () => {
      disclosure.close();
    }
  });

  return (
    <>
      <form noValidate onSubmit={handleSubmit((vals) => mutateAsync(vals))}>
        <RooDialog.Content>
          <Grid item xs={12}>
            <FieldMuiNumber
              control={control}
              name={'doorsUnderManagement'}
              label="Doors Under Management"
              fullWidth
              margin="normal"
            />
            <Grid item xs={12} md={6}>
              <h6>Is Software Client?</h6>
              <FieldMuiSwitch control={control} name={'isSoftwareClient'} color="primary" />
            </Grid>
            <FieldMuiNumber control={control} name={'dollarsPerDoor'} label="$ per Door" fullWidth margin="normal" />
          </Grid>
        </RooDialog.Content>
        <RooDialog.Actions>
          <Stack direction={'row'} spacing={2}>
            <Button type="submit" disabled={isLoading}>
              Update
            </Button>
            <Button color={'muted'} disabled={isLoading} onClick={disclosure.close}>
              Close
            </Button>
          </Stack>
        </RooDialog.Actions>
      </form>
    </>
  );
};
