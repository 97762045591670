import { Autocomplete, AutocompleteProps, TextField } from '@mui/material';
import { DropdownOption } from '../shared/store';
import { ReactNode } from 'react';

export type RooMuiSingleSelectProps<TVal> = Omit<
  AutocompleteProps<DropdownOption<TVal>, false, any, false>,
  'options' | 'renderInput' | 'freeSolo' | 'multiple' | 'value' | 'onChange'
> & {
  options: DropdownOption<TVal>[];
  value: TVal;
  onChange: (val: TVal) => void;
  placeholder: ReactNode;
};

export const RooMuiSingleSelect = <TVal extends {}>({
  options,
  placeholder,
  value,
  onChange,
  ...props
}: RooMuiSingleSelectProps<TVal>) => {
  const finalOptions = options ?? [];
  return (
    <Autocomplete
      multiple={false}
      freeSolo={false}
      getOptionLabel={(option) => option.label}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.value?.toString() ?? option.label}>
            {option.label}
          </li>
        );
      }}
      renderInput={(params) => <TextField variant={'standard'} {...params} size={'small'} label={placeholder} />}
      options={finalOptions}
      // explicitly want null instead of undefined
      value={finalOptions.find((x) => x?.value === value) ?? null}
      onChange={(_, val) => onChange(val?.value)}
      isOptionEqualToValue={(a, b) => {
        return a?.value === b?.value && a != null && b != null;
      }}
      {...props}
    />
  );
};
