import React, { ReactNode, useCallback, useRef, useState } from 'react';
import { useFileArea } from './FilePicker.store';
import { Stack, Typography } from '@mui/material';
import { Center } from '../../Center';
import { MuiIcon } from 'shared/icons';

export const DragArea = ({ children }: { children: ReactNode }) => {
  const { canAddMore, canDrop } = useFileArea((x) => x.computed);
  const { onDrop } = useFileArea((x) => x.actions);
  const [isDragging, setIsDragging] = useState(false);
  const dragTimeoutRef = useRef<NodeJS.Timeout>(null);

  const handleDragOver = useCallback(
    (event: React.DragEvent) => {
      event.preventDefault();
      event.stopPropagation();
      setIsDragging(true);

      if (dragTimeoutRef.current != null) {
        clearTimeout(dragTimeoutRef.current);
        dragTimeoutRef.current = null;
      }

      const { types } = event.dataTransfer;
      if (!canDrop || !types.some((type) => type === 'Files') || !canAddMore) {
        event.dataTransfer.dropEffect = 'none';
      } else {
        event.dataTransfer.dropEffect = 'copy';
      }
    },
    [canAddMore, canDrop]
  );

  const handleDragLeave = useCallback((event: React.DragEvent) => {
    event.preventDefault();
    event.stopPropagation();

    if (dragTimeoutRef.current == null) {
      dragTimeoutRef.current = setTimeout(() => {
        setIsDragging(false);
      }, 50); // prevent flickering
    }
  }, []);

  const handleDrop = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      event.stopPropagation();
      if (dragTimeoutRef.current) {
        clearTimeout(dragTimeoutRef.current);
        dragTimeoutRef.current = null;
      }
      setIsDragging(false);

      const files = event.dataTransfer.files;
      onDrop(Array.from(files));
    },
    [onDrop]
  );

  return (
    <div onDragOver={handleDragOver} onDragLeave={handleDragLeave} onDrop={handleDrop} style={{ position: 'relative' }}>
      {isDragging && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 2,
            pointerEvents: 'none',
            backgroundColor: 'white'
          }}
        >
          <Stack>
            <Center>
              <MuiIcon.CloudUpload fontSize={'large'} color={'primary'} />
            </Center>
            <Typography>Drop your files here</Typography>
          </Stack>
        </div>
      )}
      {children}
    </div>
  );
};
