import { useState } from 'react';
import { apiProvider } from 'shared/api/apiProvider';
import { AssignIssuePayload } from 'shared/api/clients';
import { VendorSelectorModal } from 'components/VendorSelector/VendorSelectorModal';
import { RooButton } from 'components';
import { useCurrentIssue } from 'pages/Workorders/shared/CurrentIssueContext';

export const AssignVendorButton = () => {
  const [isVisible, setIsVisible] = useState(false);
  return (
    <>
      <RooButton icon={'street-view'} onClick={() => setIsVisible(true)}>
        Assign 1 Vendor
      </RooButton>
      <AssignVendorModal visible={isVisible} onCloseRequest={() => setIsVisible(false)} />
    </>
  );
};

export const AssignVendorModal = ({ visible, onCloseRequest }: { visible: boolean; onCloseRequest: () => void }) => {
  const { issue, onIssueUpdate, property } = useCurrentIssue();
  const save = async (selectedIds: string[], notes: string) => {
    if (selectedIds.length !== 1) {
      return;
    }

    const updated = await apiProvider.issues.assignments.assignIssue(
      new AssignIssuePayload({
        issueId: issue.id,
        vendorId: selectedIds[0],
        notes
      })
    );
    onIssueUpdate(updated);
  };

  return (
    <VendorSelectorModal
      title={'Assign Vendor'}
      saveButtonFormatter={(ids, isSaving) => {
        if (!isSaving && ids.length === 0) {
          return 'No vendor selected';
        }
        if (!isSaving && ids.length === 1) {
          return `Assign vendor`;
        }

        if (isSaving) {
          return 'Assigning...';
        }
      }}
      visible={visible}
      onCloseRequest={onCloseRequest}
      selectionMode={'single'}
      lockedVendors={[]}
      referenceAddress={property.address}
      save={save}
    />
  );
};
