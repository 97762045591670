import { UserAccount } from '../../../shared/api/clients';
import { ActionsMenu, ActionsMenuItem } from '../../../components/ActionsMenu';
import { IconButton } from '@mui/material';
import { MuiIcon } from '../../../shared/icons';
import { useCurrentUser } from '../../../shared/store';
import { ChangeEmailModal } from './ChangeEmailModal';
import { useRequestGlobalModal } from '../../../components/modals';
import React from 'react';
import { DisableUserModal, EnableUserModal } from './ToggleEnabledModal';
import { useDisclosure } from '@roo/lib';

export const UserActionsMenu = ({ row, requestRefresh }: { row: UserAccount; requestRefresh: () => void }) => {
  const changeEmailModal = useDisclosure(false);
  const disableUserModal = useDisclosure(false);
  const enableUserModal = useDisclosure(false);
  const currentUser = useCurrentUser();
  const [, toggleImpersonate] = useRequestGlobalModal('impersonateModal', {
    props: {
      account: row,
      sendToLanding: true
    }
  });
  return (
    <>
      <ActionsMenu
        moreButton={(onClick) => (
          <IconButton color={'default'} onClick={onClick}>
            <MuiIcon.MoreHoriz />
          </IconButton>
        )}
      >
        <ActionsMenuItem
          disabled={currentUser.id === row.userId}
          onClick={toggleImpersonate}
          Icon={MuiIcon.SwitchAccount}
          color={'warning'}
        >
          Impersonate
        </ActionsMenuItem>
        <ActionsMenuItem
          disabled={currentUser.id === row.userId}
          onClick={changeEmailModal.open}
          Icon={MuiIcon.AlternateEmail}
          color={'error'}
        >
          Change Email
        </ActionsMenuItem>
        <ActionsMenuItem
          show={!row.enabled}
          disabled={currentUser.id === row.userId}
          onClick={enableUserModal.open}
          Icon={MuiIcon.Replay}
          color={'success'}
        >
          Enable
        </ActionsMenuItem>
        <ActionsMenuItem
          show={row.enabled}
          disabled={currentUser.id === row.userId}
          onClick={disableUserModal.open}
          Icon={MuiIcon.Block}
          color={'error'}
        >
          Disable
        </ActionsMenuItem>
      </ActionsMenu>
      <ChangeEmailModal
        row={row}
        isVisible={changeEmailModal.isOpen}
        onClose={changeEmailModal.close}
        requestRefresh={requestRefresh}
      />
      <DisableUserModal row={row} disclosure={disableUserModal} requestRefresh={requestRefresh} />
      <EnableUserModal row={row} disclosure={enableUserModal} requestRefresh={requestRefresh} />
    </>
  );
};
