import { z } from 'zod';
import { AddressSchema, makeNullish, OptionalAddressSchema } from 'shared/utils';

export const FormSchema = z.object({
  company: z.string().nullish(),
  contactFirstName: z.string(),
  contactLastName: z.string().nullish(),
  contactEmail: z.string().email(),
  contactCellPhone: z.string().nullish(),
  contactWorkPhone: z.string().nullish(),
  address: OptionalAddressSchema,
  vendorFocuses: z.number().array()
});

export type FormDefinition = z.infer<typeof FormSchema>;
