import { ReactNode } from 'react';
import { SvgIconComponent } from '@mui/icons-material';
import { ActionColor } from '../../../../../../shared/actions';
import { BidAction, IBidUserView } from '../../../../../../shared/api/clients';
import { MuiIcon } from 'shared/icons';

export const bidActions: Record<string, BidActionListEntry> = {};

class BidActionList {
  private allActionsList: BidActionListEntry[] = [];
  public register(entry: BidActionListEntry): BidActionListEntry {
    this.allActionsList.push(entry);
    return entry;
  }

  public getActions(): BidActionListEntry[] {
    return this.allActionsList;
  }

  public isAvailable(action: BidActionListEntry, bidView: IBidUserView): boolean {
    return bidView.allowedActions.indexOf(action.actionType) > -1;
  }
}

export const bidActionList = new BidActionList();
export const bidModalComponents: JSX.Element[] = [];

type BidActionListEntry = {
  key: string;
  label: ReactNode;
  Icon: SvgIconComponent;
  color: ActionColor;
  actionType: BidAction;
  onClick: (bidView: IBidUserView) => void;
};
