import axios from 'axios';
import { toast } from 'react-toastify';
import { useAppStore } from 'shared/store';
import { AuthManager } from 'shared/store/authManager';

const instance = axios.create();
const enableProfiling = localStorage.getItem('enableProfiling');

instance.interceptors.request.use((config) => {
  const token = AuthManager.instance.getToken();
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  if (enableProfiling != null) {
    config.headers['X-ENABLE-PROFILING'] = `1`;
  }

  return config;
});

instance.interceptors.response.use(
  (resp) => {
    if (resp.headers['x-app-version']) {
      useAppStore.getState().actions.trackAppVersion(resp.headers['x-app-version']);
    }
    if (enableProfiling) {
      try {
        const mp = (window as any).MiniProfiler;
        if (mp != null) {
          const header = resp.headers['x-miniprofiler-ids'];
          const finalIds = [];
          if (header) {
            const allIds = JSON.parse(header) as string[];
            for (const id of allIds) {
              const match = mp.fetchStatus[id];
              if (match == null) {
                finalIds.push(id);
              }
            }
            if (finalIds.length > 0) {
              mp.fetchResults(finalIds);
            }
          }
        }
      } catch (e) {
        console.error(e);
      }
    }

    return resp;
  },
  (err) => {
    if (err.response?.status === 401) {
      AuthManager.instance.logOut(true);
      return;
    }

    if (!err.response) {
      toast.error('📶 Could not connect to the server. Try again later.', { toastId: 'no-connection' });
    } else {
      toast.error('🚫 Request failed. Try again later.');
    }
    return Promise.reject(err);
  }
);

export default instance;
