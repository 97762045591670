import { Box, Button, CircularProgress, ThemeProvider, Typography } from '@mui/material';
import React, { useState } from 'react';
import { apiProvider } from 'shared/api/apiProvider';
import { RequestPasswordResetPayload } from 'shared/api/clients';
import { FieldMuiText, useRooForm } from 'components/form';
import { darkTheme } from 'shared/theme';
import { z } from 'zod';
import { AuthPage } from 'pages/Auth/AuthPage';

const FormSchema = z.object({
  email: z.string().email()
});

type FormDefinition = z.infer<typeof FormSchema>;

export const RequestPasswordReset = () => {
  const [state, setState] = useState<'form' | 'confirmation'>('form');
  const showConfirm = () => setState('confirmation');
  return (
    <AuthPage title={'Reset Password - Walkthroo'}>
      <Box className="d-flex flex-column align-items-center">
        <Typography variant="h5" component="div" gutterBottom sx={{ fontWeight: 700 }}>
          Reset Password
        </Typography>
      </Box>
      {state === 'form' && <FormView onConfirmation={showConfirm} />}
      {state === 'confirmation' && <ConfirmationView />}
    </AuthPage>
  );
};

const FormView = ({ onConfirmation }: { onConfirmation: () => void }) => {
  const [state, setState] = useState<'idle' | 'loading' | 'error'>('idle');
  const { control, handleSubmit } = useRooForm(FormSchema);

  const requestReset = async (values: FormDefinition) => {
    setState('loading');
    try {
      await apiProvider.authClient.requestPasswordReset(
        new RequestPasswordResetPayload({
          email: values.email
        })
      );
      onConfirmation();
    } catch (e) {
      setState('error');
    }
  };

  return (
    <>
      <Typography variant="subtitle1" component="div" gutterBottom>
        If your e-mail matches an active account, we'll send you a reset link.
      </Typography>
      <form noValidate onSubmit={handleSubmit(requestReset)}>
        <ThemeProvider theme={darkTheme}>
          <FieldMuiText
            control={control}
            margin={'normal'}
            className={'Input-dark'}
            name={'email'}
            label={'E-mail'}
            fullWidth
            required
          />
          <Button
            size={'largest'}
            type="submit"
            fullWidth
            autoFocus
            sx={{ mt: 2, mb: 2 }}
            onClick={handleSubmit(requestReset)}
          >
            {state === 'loading' ? <CircularProgress style={{ width: 31, height: 31 }} color="inherit" /> : 'Reset'}
          </Button>

          {state === 'error' && (
            <div className="alert alert-danger mt-2" role="alert">
              Could not reset your password.
            </div>
          )}
        </ThemeProvider>
      </form>
    </>
  );
};

const ConfirmationView = () => {
  return (
    <>
      <Box sx={{ textAlign: 'center' }}>
        <Typography variant="subtitle1" gutterBottom>
          We're almost done, we just need you to click the link we sent to your e-mail address.
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Don't see it? Wait a few minutes and make sure to check your spam folder.
        </Typography>
      </Box>
    </>
  );
};
