import { createTheme } from '@mui/material/styles';
import { alpha } from '@mui/material';
import type {} from '@mui/lab/themeAugmentation';

const primary = '#00a49e';

const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor: string) => augmentColor({ color: { main: mainColor } });

const theme = createTheme({
  typography: {
    h6: {
      fontWeight: 700
    },
    fontFamily: ['Montserrat'].join(',')
  },

  palette: {
    primary: createColor(primary),
    secondary: createColor('#fff'),
    muted: createColor('#eee'),
    light: createColor('#fff')
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Montserrat';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
        }
      `
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained'
      },
      styleOverrides: {
        // bootstrap styles clashing with mui ones
        // we need to explicitly specify colors otherwise link buttons will be blue
        contained: ({ ownerState: { color }, theme: { palette } }) => {
          return {
            borderRadius: 0,
            boxShadow: 'none',
            // @ts-ignore-error
            color: color === 'inherit' ? undefined : palette[color].contrastText,
            '&:hover': {
              boxShadow: 'none',
              // @ts-ignore-error
              color: color === 'inherit' ? undefined : palette[color].contrastText
            }
          };
        },
        sizeLarge: {
          padding: '0.75em'
        }
      },
      variants: [
        {
          props: { size: 'largest' },
          style: {
            padding: '1em',
            fontWeight: 700,
            fontSize: 18
          }
        }
      ]
    },
    MuiLoadingButton: {
      defaultProps: {
        variant: 'contained'
      },
      styleOverrides: {},
      variants: [
        {
          props: { size: 'largest' },
          style: {
            padding: '1em',
            fontWeight: 700,
            fontSize: 18
          }
        }
      ]
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: '#F7F8FC',
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#EAECF6'
          }
        }
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: primary,
          textDecoration: 'none'
        }
      }
    },
    MuiStack: {
      defaultProps: {
        useFlexGap: true
      }
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
          '& .MuiTableCell-head': {
            fontWeight: 600,
            fontSize: '14px',
            textTransform: 'uppercase'
          }
        }
      }
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          backgroundColor: 'white'
        }
      }
    },
    MuiTableFooter: {
      styleOverrides: {
        root: {
          backgroundColor: 'white'
        }
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '& .MuiTableCell-root': {
            padding: '1.3em 1em'
          }
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          a: {
            textDecorationLine: 'underline'
          },
          '& .MuiSvgIcon-colorSuccess': {
            color: primary
          },
          '& .MuiSvgIcon-colorError': {
            color: '#ef4545'
          }
        }
      }
    },
    MuiPaper: {
      // Header layout
      styleOverrides: {
        root: {
          '&.MuiAppBar-root': {
            backgroundColor: 'transparent',
            boxShadow: 'none',
            color: 'black'
          }
        }
      }
    },
    MuiList: {
      styleOverrides: {
        root: {
          '& .MuiListSubheader-root': {
            fontWeight: 700
          },
          '&.profile .MuiListItemText-primary': {
            fontSize: '1.25rem'
          },
          '& .MuiButtonBase-root.Mui-selected': {
            // listItem selected
            borderLeft: `4px solid ${primary}`,
            backgroundColor: '#1A1A1A',
            boxSizing: 'border-box',
            '& .MuiAvatar-root': {
              color: 'white'
            },
            '& .MuiTypography-root': {
              fontWeight: 700
            }
          },
          '& .MuiListItem-root.MuiButtonBase-root:hover': {
            color: 'gray'
          },
          '&.menu-user-profile .Mui-selected': {
            // menu user profile
            backgroundColor: '#F7F8FC',
            color: 'black',
            fontWeight: 600
          },
          '& .MuiMenuItem-root.MuiMenuItem-gutters.Mui-selected': {
            // dropdown profile
            backgroundColor: alpha(primary, 0.08)
          }
        }
      }
    },
    MuiListItemAvatar: {
      // List items sidebar
      styleOverrides: {
        root: {
          '& .MuiAvatar-root': {
            backgroundColor: '#222',
            color: 'gray'
          }
        }
      }
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          backgroundColor: '#F7F8FC',
          minHeight: 74,
          '& .MuiButtonBase-root': {
            color: '#C5C7CD'
          },
          '& .MuiButtonBase-root, & .MuiButtonBase-root .MuiSvgIcon-root': {
            cursor: 'pointer'
          },
          '& .MuiButtonBase-root.MuiIconButton-root:hover': {
            backgroundColor: 'whitesmoke'
          }
        }
      }
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        root: {
          '& li p, & li a': {
            fontSize: 14
          }
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '1em'
        }
      }
    }
  }
});

export const darkTheme = createTheme({
  typography: theme.typography,
  palette: theme.palette,
  components: {
    MuiButton: theme.components.MuiButton,
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: 'white'
        }
      }
    }
  }
});

export default theme;
