import { Box, Container, CssBaseline, ThemeProvider, Typography } from '@mui/material';
import bgImage from 'images/bg-auth.png';
import rooBlue from 'images/roo-logo-blue.png';
import React, { CSSProperties, ReactNode } from 'react';
import { Helmet } from 'react-helmet';
import theme from 'shared/theme';
import { useFullScreen, WithReqChildren } from 'shared/utils';

export const AuthPage = ({
  title,
  children,
  minWidth,
  logo = null
}: WithReqChildren & { title: ReactNode; minWidth?: number; logo?: ReactNode }) => {
  useFullScreen();
  const finalLogo = logo ?? (
    <Box className={'brand'}>
      <img src={rooBlue} alt="" />
    </Box>
  );
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <ThemeProvider theme={theme}>
        <ContainerAuth>
          <Container component="main" className="login-container dark-content">
            <CssBaseline />
            <Box
              sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}
            >
              {finalLogo}
              <Box
                sx={(theme) => ({
                  marginTop: theme.spacing(1),
                  backgroundColor: '#303030',
                  padding: theme.spacing(3),
                  [theme.breakpoints.up('md')]: {
                    minWidth: minWidth
                  }
                })}
              >
                {children}
              </Box>
            </Box>
            <Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 8, mb: 4, color: 'white' }}>
              Copyright © 2021 Walkthroo LLC
            </Typography>
          </Container>
        </ContainerAuth>
      </ThemeProvider>
    </>
  );
};

type PropsContainerAuth = {
  children?: ReactNode;
  style?: CSSProperties;
};

export const ContainerAuth = ({ children, ...rest }: PropsContainerAuth) => {
  return (
    <div
      style={{
        width: '100%',
        backgroundColor: '#171717',
        backgroundImage: `url(${bgImage})`,
        backgroundPositionX: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100%',
        minHeight: '100vh',
        ...rest.style
      }}
    >
      {children}
    </div>
  );
};
