import { Helmet } from 'react-helmet';
import * as React from 'react';
import { useMemo, useState } from 'react';
import { ContentWrapper, IconLink, NavSlim, RooButton, RouterButton } from '../../components';
import { inspectionStatusOptions, rooEnum, useHasInspectionsAccess } from '../../shared/store';
import { useQuery } from '@tanstack/react-query';
import { apiProvider } from '../../shared/api/apiProvider';
import { CenteredLoader } from '../../components/CenteredLoader';
import { Box, Button, Card, CardContent, CardHeader, Stack } from '@mui/material';
import { StatCard } from '../../components/StatCard';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { KeyGetter, rooFmt } from '../../shared/utils';
import { InspectionStatus, InspectionSummary } from '../../shared/api/clients';
import { differenceInCalendarDays } from 'date-fns';
import { Dropdown } from 'primereact/dropdown';
import { MuiIcon } from 'shared/icons';
import { prepareRouterHrefAttrs, Routes } from '../../shared/routing';
import { useDisclosure } from '@roo/lib';
import { CreateInspectionModal } from './Workflow/CreateInspectionModal';

export const InspectionsDashboard = () => {
  const hasAccess = useHasInspectionsAccess();
  return (
    <>
      <Helmet>
        <title>Inspections - Walkthroo</title>
      </Helmet>
      <NavSlim breadcrumbs={[{ text: 'Inspections' }]} />
      <ContentWrapper>{hasAccess && <Body />}</ContentWrapper>
    </>
  );
};

const statKeys = [
  'total',
  'assigned',
  'unassigned',
  'needToAccept',
  'inProgress',
  'notStarted5d',
  'completedLast30d'
] as const;
type StatKey = (typeof statKeys)[number];

const statConfiguration: Record<StatKey, { filter: (summary: InspectionSummary) => boolean; name: string }> = {
  total: {
    name: 'Total',
    filter: () => true
  },
  assigned: {
    name: 'Assigned',
    filter: (ins) => ins.assignedUser != null || ins.assignedVendor != null || ins.assignedCompany != null
  },
  unassigned: {
    name: 'Unassigned',
    filter: (ins) => ins.assignedUser != null && ins.assignedVendor != null && ins.assignedCompany != null
  },
  needToAccept: { name: 'Need to Accept', filter: (ins) => ins.status === InspectionStatus.Invited },
  inProgress: { name: 'In Progress', filter: (ins) => ins.status === InspectionStatus.Started },
  notStarted5d: {
    name: 'Not started after 5d',
    filter: (ins) =>
      ins.status === InspectionStatus.Accepted && differenceInCalendarDays(new Date(), ins.modifiedAt) >= 5
  },
  completedLast30d: {
    name: 'Completed last 30d',
    filter: (ins) => ins.status === InspectionStatus.Done && differenceInCalendarDays(new Date(), ins.modifiedAt) <= 30
  }
} as const;

const Body = () => {
  const { isLoading, data } = useQuery(['inspections', 'list'], () => apiProvider.inspectionClient.listInspections());
  const [currentStat, setCurrentStat] = useState<StatKey>(null);

  if (isLoading) {
    return <CenteredLoader />;
  }

  return (
    <Stack gap={4}>
      <Stack>
        <h3 style={{ fontWeight: 400, marginTop: '24px', marginBottom: '10px' }}>Summary</h3>
        <Box sx={{ backgroundColor: 'white' }}>
          <Box
            sx={{
              display: 'grid',
              gridAutoRows: '1fr',
              gridTemplateColumns: 'repeat(auto-fit, minmax(180px, 1fr));'
            }}
          >
            {statKeys.map((k) => (
              <StatCard
                stat={{
                  displayName: statConfiguration[k].name,
                  value: data.filter(statConfiguration[k].filter).length,
                  delayedCount: 0,
                  assigneeBadge: null,
                  hasDetails: true,
                  displayDecimals: 0,
                  emphasized: false
                }}
                isLoading={false}
                isSelected={currentStat === k}
                onClick={() => setCurrentStat(k)}
              />
            ))}
          </Box>
        </Box>
      </Stack>
      <InspectionList allInspections={data} currentStat={currentStat} setCurrentStat={setCurrentStat} />
    </Stack>
  );
};

const InspectionList = ({
  allInspections,
  currentStat,
  setCurrentStat
}: {
  allInspections: InspectionSummary[];
  currentStat: StatKey;
  setCurrentStat: (k: StatKey) => void;
}) => {
  const filtered = useMemo(
    () =>
      (currentStat != null ? allInspections.filter(statConfiguration[currentStat].filter) : allInspections).map(
        (x) => ({
          ...x,
          assigneeName:
            x.assignedUser != null
              ? x.assignedUser.fullName
              : x.assignedVendor != null
              ? x.assignedVendor.companyName
              : null
        })
      ),
    [allInspections, currentStat]
  );
  const keys = new KeyGetter<InspectionSummary & { assigneeName: string }>();

  return (
    <Card>
      <CardHeader
        title={currentStat == null ? 'Inspections' : `Showing only: ${statConfiguration[currentStat].name}`}
        action={
          currentStat == null ? (
            <CreateInspectionButton />
          ) : (
            <RooButton onClick={() => setCurrentStat(null)} variant={'link'} icon={'undo'}>
              View All
            </RooButton>
          )
        }
      />
      <CardContent>
        <div className={'workorder-container'} style={{ width: '100%' }}>
          <DataTable
            value={filtered}
            sortField={keys.get('createdAt')}
            sortOrder={-1}
            paginator
            rows={10}
            rowsPerPageOptions={[10, 25, 50, 100]}
            emptyMessage={<p className={'text-center'}>No Inspections</p>}
            size={'small'}
            dataKey={'id'}
          >
            <Column
              header={'Inspection ID'}
              sortable
              field={keys.get('friendlyId')}
              body={(row: InspectionSummary) => (
                <IconLink
                  style={{ whiteSpace: 'nowrap' }}
                  to={{ pathname: Routes.InspectionView, params: { id: row.id } }}
                  text={row.friendlyId}
                />
              )}
            />
            <Column
              field={keys.get('property.address.address1')}
              filter
              showFilterMatchModes={false}
              showFilterOperator={false}
              showAddButton={false}
              sortable
              header={'Address 1'}
            />
            <Column
              field={keys.get('property.address.address2')}
              filter
              showFilterMatchModes={false}
              showFilterOperator={false}
              showAddButton={false}
              sortable
              header={'Address 2'}
            />
            <Column
              field={keys.get('status')}
              filter
              showFilterMatchModes={false}
              showFilterOperator={false}
              showAddButton={false}
              filterElement={(options) => (
                <Dropdown
                  value={options.value}
                  options={inspectionStatusOptions}
                  onChange={(e) => options.filterCallback(e.value, options.index)}
                  placeholder="Select a Status"
                  className="p-column-filter"
                  showClear
                />
              )}
              body={(row: InspectionSummary) => rooEnum.inspectionStatus.display(row.status)}
              sortable
              header={'Status'}
            />
            <Column
              field={keys.get('assigneeName')}
              filter
              showFilterMatchModes={false}
              showFilterOperator={false}
              showAddButton={false}
              sortable
              header={'Assignee'}
            />
            <Column
              field={keys.get('createdAt')}
              sortable
              header={'Created'}
              dataType={'date'}
              body={(row: InspectionSummary) => rooFmt.date(row.createdAt)}
            />
            <Column
              field={keys.get('modifiedAt')}
              sortable
              header={'Modified'}
              dataType={'date'}
              body={(row: InspectionSummary) => rooFmt.date(row.modifiedAt)}
            />
            <Column
              body={(row: InspectionSummary) => (
                <Stack direction={'row'} flexWrap={'nowrap'} spacing={1}>
                  {row.status === InspectionStatus.Done && row.lastRenderedPdf && (
                    <RooButton
                      style={{ width: '80px' }}
                      as={'a'}
                      href={apiProvider.fileUrls.download(row.lastRenderedPdf.id)}
                      size={'sm'}
                      icon={'download'}
                    >
                      PDF
                    </RooButton>
                  )}
                  {row.status === InspectionStatus.Done && (
                    <RooButton
                      style={{ width: '80px' }}
                      as={'a'}
                      {...prepareRouterHrefAttrs({ pathname: Routes.InspectionReport, params: { id: row.id } }, null)}
                      size={'sm'}
                      icon={'eye'}
                    >
                      Report
                    </RooButton>
                  )}
                </Stack>
              )}
            />
          </DataTable>
        </div>
      </CardContent>
    </Card>
  );
};

const CreateInspectionButton = () => {
  const disclosure = useDisclosure(false);
  return (
    <>
      <Button size={'large'} color="primary" onClick={disclosure.open} startIcon={<MuiIcon.Add />}>
        Create Inspection
      </Button>
      <CreateInspectionModal visible={disclosure.isOpen} onCloseRequest={disclosure.close} />
    </>
  );
};
