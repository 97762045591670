import { UpdateVendorServicesPayload, Vendor } from '../../shared/api/clients';
import { Alert, Box, Button, Chip, IconButton, List, ListItem, ListSubheader, Stack, styled } from '@mui/material';
import { MuiIcon } from '../../shared/icons';
import { RooDialog } from '../../components';
import { z } from 'zod';
import { FieldMultiSelect, useRooForm } from '../../components/form';
import { useFocusOptions } from '../../shared/store';
import { apiProvider } from '../../shared/api/apiProvider';
import React from 'react';
import { useVendorMutation } from './shared';
import { ReactSelectCommonStyles } from '../../components/RooSelect';
import { Disclosure, useDisclosure } from '@roo/lib';

export const ServicesWidget = ({ vendor, canEdit }: { vendor: Vendor; canEdit: boolean }) => {
  const modal = useDisclosure(false);
  return (
    <>
      <List
        subheader={
          <Stack direction={'row'}>
            <ListSubheader>
              <MuiIcon.Construction /> Services
            </ListSubheader>
            {canEdit && (
              <IconButton color={'primary'} onClick={modal.open}>
                <MuiIcon.Edit />
              </IconButton>
            )}
          </Stack>
        }
      >
        {vendor.focuses.length > 0 && (
          <ListItem>
            <Box
              component={'ul'}
              sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', listStyle: 'none', p: 0.5, m: 0 }}
            >
              {vendor.focuses.map((focus) => (
                <SpacedListItem key={focus.id}>
                  <Chip label={focus.name} />
                </SpacedListItem>
              ))}
            </Box>
          </ListItem>
        )}
        {vendor.focuses.length === 0 && (
          <ListItem>
            <Alert severity={'info'}>This vendor has no services set.</Alert>{' '}
          </ListItem>
        )}
      </List>
      <UpdateServicesModal disclosure={modal} vendor={vendor} />
    </>
  );
};

export const UpdateServicesModal = ({ disclosure, vendor }: { disclosure: Disclosure; vendor: Vendor }) => {
  return (
    <RooDialog onClose={disclosure.close} fullWidth maxWidth={'sm'} open={disclosure.isOpen}>
      <RooDialog.Title onClose={disclosure.close}>Update Services</RooDialog.Title>
      <UpdateServicesForm disclosure={disclosure} vendor={vendor} />
    </RooDialog>
  );
};

const ServicesSchema = z.object({
  focusIds: z.number().array().min(1, 'You must specify at least 1 focus')
});

type ServicesForm = z.infer<typeof ServicesSchema>;

const UpdateServicesForm = ({ disclosure, vendor }: { disclosure: Disclosure; vendor: Vendor }) => {
  const { control, handleSubmit } = useRooForm(ServicesSchema, {
    defaultValues: {
      focusIds: vendor?.focuses.map((x) => x.id) ?? []
    }
  });

  const options = useFocusOptions();

  const { isLoading, mutateAsync } = useVendorMutation(vendor.id, {
    mutationFn: (values: ServicesForm) =>
      apiProvider.vendorClient.updateServices(
        new UpdateVendorServicesPayload({
          vendorId: vendor.id,
          focusIds: values.focusIds
        })
      ),
    onSuccess: () => {
      disclosure.close();
    }
  });

  return (
    <>
      <form noValidate onSubmit={handleSubmit((vals) => mutateAsync(vals))}>
        <RooDialog.Content>
          <FieldMultiSelect
            control={control}
            options={options}
            name={'focusIds'}
            placeholder="Services..."
            required
            label={'Vendor Services'}
            className="Input"
            styles={ReactSelectCommonStyles.MuiLookAlike}
          />
        </RooDialog.Content>
        <RooDialog.Actions>
          <Stack direction={'row'} spacing={2}>
            <Button type="submit" disabled={isLoading}>
              Update
            </Button>
            <Button color={'muted'} disabled={isLoading} onClick={disclosure.close}>
              Close
            </Button>
          </Stack>
        </RooDialog.Actions>
      </form>
    </>
  );
};

const SpacedListItem = styled('li')(({ theme }) => ({
  margin: theme.spacing(0.5)
}));
