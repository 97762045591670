import { ApiClientType } from "@roo/api";

type PlatformUtils = {
  generateUuid: () => string;
  logException: (e: unknown) => void;
  storage: PlatformStorage;
  api: ApiClientType;
};

export type PlatformStorage = {
  set: (key: string, value: string) => Promise<void>;
  get: (key: string) => Promise<string>;
};

class PlatformUtilsManager {
  private utils: PlatformUtils;

  get = () => this.utils;
  set = (utils: PlatformUtils) => (this.utils = utils);
}

export const platformUtils = new PlatformUtilsManager();
