import { useState } from 'react';
import { apiProvider } from 'shared/api/apiProvider';
import { CandidacyStatus, InviteVendorsPayload } from 'shared/api/clients';
import { LockedVendor } from 'components/VendorSelector/VendorSelector';
import { VendorSelectorModal } from 'components/VendorSelector/VendorSelectorModal';
import { RooButton } from 'components';
import { rooFmt } from 'shared/utils';
import { useCurrentIssue } from 'pages/Workorders/shared/CurrentIssueContext';

export const InviteVendorsButton = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  return (
    <>
      <RooButton onClick={() => setIsModalVisible(true)} icon={'users'}>
        Assign Multiple Vendors
      </RooButton>
      <InviteVendorsModal isVisible={isModalVisible} setIsModalVisible={setIsModalVisible} />
    </>
  );
};

export const InviteVendorsModal = ({
  isVisible,
  setIsModalVisible
}: {
  isVisible: boolean;
  setIsModalVisible: (requesting: boolean) => void;
}) => {
  const { issue, onIssueUpdate, property } = useCurrentIssue();
  const locked: LockedVendor[] =
    issue.vendorCandidates?.map((x) => ({
      color: x.status === CandidacyStatus.Declined ? 'error' : 'success',
      reason: x.status === CandidacyStatus.Declined ? 'Rejected Invite' : 'Invited',
      id: x.vendor.id
    })) ?? [];
  const closeModal = () => setIsModalVisible(false);

  const save = async (selectedIds: string[], notes: string) => {
    if (selectedIds.length === 0) {
      return;
    }
    const updated = await apiProvider.issues.assignments.inviteCandidates(
      new InviteVendorsPayload({
        issueId: issue.id,
        vendorIds: selectedIds,
        notes
      })
    );

    onIssueUpdate(updated);
  };

  return (
    <VendorSelectorModal
      title={'Assign Multiple Vendors'}
      saveButtonFormatter={(ids, isSaving) => {
        if (isSaving) {
          return 'Sending invitations...';
        }
        if (ids.length === 0) {
          return 'No vendors selected';
        }

        return `Invite ${rooFmt.counted(ids.length, 'vendor', 'vendors')}`;
      }}
      visible={isVisible}
      onCloseRequest={closeModal}
      selectionMode={'multiple'}
      lockedVendors={locked}
      referenceAddress={property.address}
      save={save}
    />
  );
};
