import { Helmet } from 'react-helmet';
import { ContentWrapper, NavSlim, RouterLink } from '../../../components';
import { Stack } from '@mui/material';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { rooFmt } from '../../../shared/utils';
import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { apiProvider } from '../../../shared/api/apiProvider';
import { SoftwareClientDetails } from '@roo/api';
import { Routes } from '../../../shared/routing';

export const SoftwareClientList = () => {
  const { isLoading, data: companies } = useQuery(['companies', 'admin-list-software-clients'], () =>
    apiProvider.adminClient.listSoftwareClients()
  );

  return (
    <>
      <Helmet>
        <title>Software Client List - Walkthroo</title>
      </Helmet>
      <NavSlim breadcrumbs={[{ text: 'Software Client List' }]} />
      <ContentWrapper>
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
          p={4}
          sx={{ backgroundColor: 'white' }}
        >
          <h5 className="m-0">Software Client List</h5>
        </Stack>
        <DataTable
          tableStyle={{ width: '100%' }}
          value={companies}
          loading={isLoading}
          paginator
          rows={12}
          sortField={'createdAt'}
          sortOrder={-1}
        >
          <Column
            field={'name'}
            header={'Name'}
            sortable
            filter
            body={(row: SoftwareClientDetails) => (
              <RouterLink
                target={'_blank'}
                to={{ pathname: Routes.CompanyProfile, params: { companyId: row.companyId } }}
              >
                {row.name}
              </RouterLink>
            )}
          />
          <Column
            field={'admin.fullName'}
            header={'Admin'}
            sortable
            body={(row: SoftwareClientDetails) =>
              row.admin != null ? (
                <RouterLink target={'_blank'} to={{ pathname: Routes.UserProfile, params: { id: row.admin.id } }}>
                  {row.admin.fullName}
                </RouterLink>
              ) : (
                'N/A'
              )
            }
          />
          <Column
            field={'admin.contactInfo.phoneNumber'}
            header={'Phone'}
            sortable
            body={(row: SoftwareClientDetails) => row.admin.contactInfo.phoneNumber}
          />
          <Column
            field={'createdAt'}
            header={'Created'}
            sortable
            body={(row: SoftwareClientDetails) => rooFmt.date(row.createdAt)}
          />
          <Column
            field={'adminLastLogin'}
            header={'Last Login'}
            sortable
            body={(row: SoftwareClientDetails) => rooFmt.dateMaybe(row.adminLastLogin, 'N/A')}
          />

          <Column field={'userCount'} header={'# Employees'} sortable />
          <Column field={'propertyCount'} header={'# of doors'} sortable />
          <Column
            field={'dollarsPerDoor'}
            header={'$ per Door'}
            sortable
            body={(row: SoftwareClientDetails) => rooFmt.moneyMaybe(row.dollarsPerDoor, 'N/A')}
          />
        </DataTable>
      </ContentWrapper>
    </>
  );
};
