import { ConfirmationModal } from '../../../components';
import { useMutation } from '@tanstack/react-query';
import { apiProvider } from '../../../shared/api/apiProvider';
import { ToggleUserEnabledPayload, UserAccount } from '../../../shared/api/clients';
import { Disclosure } from '@roo/lib';

export const EnableUserModal = ({
  row,
  disclosure,
  requestRefresh
}: {
  row: UserAccount;
  disclosure: Disclosure;
  requestRefresh: () => void;
}) => {
  return (
    <ToggleEnabledModal
      row={row}
      disclosure={disclosure}
      requestRefresh={requestRefresh}
      value={true}
      question={'Are you sure you want to enable this user?'}
    />
  );
};

export const DisableUserModal = ({
  row,
  disclosure,
  requestRefresh
}: {
  row: UserAccount;
  disclosure: Disclosure;
  requestRefresh: () => void;
}) => {
  return (
    <ToggleEnabledModal
      row={row}
      disclosure={disclosure}
      requestRefresh={requestRefresh}
      value={false}
      question={'Are you sure you want to disable this user?'}
    />
  );
};

export const ToggleEnabledModal = ({
  row,
  disclosure,
  requestRefresh,
  value,
  question
}: {
  row: UserAccount;
  disclosure: Disclosure;
  requestRefresh: () => void;
  value: boolean;
  question: string;
}) => {
  const { mutate, isLoading } = useMutation({
    mutationFn: () =>
      apiProvider.adminClient.toggleUserEnabled(
        new ToggleUserEnabledPayload({
          userId: row.userId,
          value
        })
      ),
    onSuccess: () => {
      requestRefresh();
      disclosure.close();
    }
  });
  return (
    <ConfirmationModal
      question={question}
      visible={disclosure.isOpen}
      onClose={disclosure.close}
      onSave={mutate}
      running={isLoading}
    />
  );
};
