import { useCurrentIssue } from 'pages/Workorders/shared/CurrentIssueContext';
import { BidForm } from 'pages/Workorders/ViewIssue/Documents/Bids/BidForm';
import { BidList } from 'pages/Workorders/ViewIssue/Documents/Bids/BidList/BidList';
import { DeclineBidButton } from 'pages/Workorders/ViewIssue/Documents/Bids/DeclineBidButton';
import { ScheduleProposalForm } from 'pages/Workorders/ViewIssue/Documents/Bids/ScheduleProposalForm';
import React, { ReactNode, useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import {
  BidStatus,
  BidSubmissionType,
  IBid,
  IBidUserView,
  IIssue,
  IssueAction,
  IssueRole,
  ProposalType
} from 'shared/api/clients';
import { vendorSkipBiddingAction } from '../../Workflow/SkipBiddingModal';
import { RooButton, RouterLink } from '../../../../../components';
import { Stack } from '@mui/material';
import { assertNever } from '@roo/lib';
import { Routes } from '../../../../../shared/routing';
import { RooIcon } from '../../../../../shared/icons';

type ManagerState =
  | { step: 'list' | 'declined' | 'uninvited' }
  | { step: 'edit'; bid: IBid; canCancel: boolean; submissionType: BidSubmissionType }
  | { step: 'schedule'; bid: IBid };

const calculateState = (userRole: IssueRole, visibleBids: IBidUserView[], issue: IIssue) => {
  let state: ManagerState = { step: 'list' };
  if (userRole === IssueRole.Bidder && visibleBids.length === 1) {
    const myBid = visibleBids[0].bid;
    if (myBid.status === BidStatus.Requested) {
      if (issue.requireScheduleBeforeBid) {
        state = { step: 'schedule', bid: myBid };
      } else {
        state = { step: 'edit', bid: myBid, submissionType: BidSubmissionType.Submission, canCancel: false };
      }
    }

    if (myBid.status === BidStatus.ProposedSchedule) {
      state = { step: 'schedule', bid: myBid };
    }

    if (myBid.status === BidStatus.Scheduled) {
      state = { step: 'edit', submissionType: BidSubmissionType.Submission, bid: myBid, canCancel: false };
    }
  }
  return state;
};

export const BidManager = () => {
  const { visibleBids, userRole, issue, canAct } = useCurrentIssue();
  const defaultState = calculateState(userRole, visibleBids, issue);
  const [state, setState] = useState<ManagerState>(defaultState);

  useEffect(() => {
    setState(calculateState(userRole, visibleBids, issue));
  }, [visibleBids, userRole, issue]);

  if (visibleBids.length === 0 && !canAct(IssueAction.RequestBid)) {
    return null;
  }

  switch (state.step) {
    case 'list':
      return (
        <BidList
          requestEdit={(bid, submissionType) =>
            setState({
              step: 'edit',
              bid: bid,
              canCancel: true,
              submissionType: submissionType
            })
          }
        />
      );
    case 'edit':
      return (
        <StepEditForm
          submissionType={state.submissionType}
          canCancel={state.canCancel}
          bid={state.bid}
          setState={setState}
        />
      );
    case 'declined':
      return <StepDeclined />;
    case 'uninvited':
      return <StepUninvited />;
    case 'schedule':
      return <StepProposalForm bid={state.bid} />;
    default:
      return assertNever(state);
  }
};

const StepEditForm = ({
  bid,
  setState,
  canCancel,
  submissionType
}: {
  bid: IBid;
  canCancel: boolean;
  submissionType: BidSubmissionType;
  setState: (state: ManagerState) => void;
}) => {
  const ctx = useCurrentIssue();
  const canSkip = vendorSkipBiddingAction.isVisible(ctx);

  return (
    <CardTemplate
      header={'Submit a Bid'}
      actions={
        <>
          {canSkip && (
            <RooButton onClick={vendorSkipBiddingAction.onClick} size={'sm'} icon={'fast-forward'} variant={'warning'}>
              Under Limit
            </RooButton>
          )}

          <DeclineBidButton />
        </>
      }
    >
      <BidForm
        myBid={bid}
        submissionType={submissionType}
        onCancel={canCancel ? () => setState({ step: 'list' }) : null}
      />
    </CardTemplate>
  );
};

const StepProposalForm = ({ bid }: { bid: IBid }) => {
  const { property } = useCurrentIssue();
  const proposalType =
    bid.status === BidStatus.ProposedSchedule
      ? ProposalType.Final
      : property.isOccupied
        ? ProposalType.Tentative
        : ProposalType.Final;
  return (
    <CardTemplate header={'Schedule your visit'} actions={<DeclineBidButton />}>
      <ScheduleProposalForm myBid={bid} proposalType={proposalType} />
    </CardTemplate>
  );
};

const StepUninvited = () => {
  return (
    <CardTemplate header={'You have been uninvited'} actions={null}>
      <h3>You have been uninvited from bidding on this issue</h3>
    </CardTemplate>
  );
};

const StepDeclined = () => {
  return (
    <CardTemplate header={'You have declined'} actions={null}>
      <h3>You have declined to submit a bid</h3>
    </CardTemplate>
  );
};

const CardTemplate = ({
  header,
  children,
  actions
}: {
  header: ReactNode;
  children: ReactNode;
  actions: ReactNode;
}) => {
  return (
    <Card>
      <Card.Header>
        <div className={'d-flex justify-content-between'}>
          <div>{header}</div>
          <Stack spacing={1} direction={'row'}>
            {actions}
          </Stack>
        </div>
      </Card.Header>
      <Card.Body>{children}</Card.Body>
    </Card>
  );
};
