import { useCurrentIssue } from '../../../shared/CurrentIssueContext';
import React, { useEffect, useState } from 'react';
import { apiProvider } from '../../../../../shared/api/apiProvider';
import { ChangeRequest, ChangeRequestIncludeCandidate } from '../../../../../shared/api/clients';
import { ChangeRequestForm } from './ChangeRequestForm';
import { CenteredLoader } from '../../../../../components/CenteredLoader';
import { InlineError } from '../../../../../components/InlineError';
import { Stack } from '@mui/material';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { rooFmt } from '../../../../../shared/utils';
import { RooButton } from '../../../../../components';

type State = {
  stage: 'loading' | 'selection' | 'form' | 'error';
  selectedItems: ChangeRequestIncludeCandidate[];
  candidates: ChangeRequestIncludeCandidate[];
};

export const ChangeRequestFormCoordinator = ({
  onClose,
  changeRequest
}: {
  onClose: () => void;
  changeRequest: ChangeRequest;
}) => {
  const { issue } = useCurrentIssue();
  const [state, setState] = useState<State>({ stage: 'loading', selectedItems: [], candidates: [] });
  useEffect(() => {
    const getCandidates = async () => {
      try {
        if (changeRequest != null) {
          return setState({
            stage: 'form',
            selectedItems: [],
            candidates: []
          });
        }

        const candidates = await apiProvider.issues.changeRequests.getIncludeCandidates(issue.id);
        setState({
          stage: candidates.length === 0 ? 'form' : 'selection',
          selectedItems: [],
          candidates: candidates
        });
      } catch (e) {
        setState({
          stage: 'error',
          selectedItems: [],
          candidates: []
        });
      }
    };

    void getCandidates();
  }, [issue, changeRequest]);

  if (state.stage === 'loading') {
    return <CenteredLoader />;
  }

  if (state.stage === 'error') {
    return <InlineError />;
  }

  if (state.stage === 'form') {
    return (
      <ChangeRequestForm onComplete={onClose} changeRequest={changeRequest} includedDocuments={state.selectedItems} />
    );
  }

  if (state.stage === 'selection') {
    return (
      <Selector
        onSetSelection={(items) => setState((curr) => ({ ...curr, stage: 'form', selectedItems: items }))}
        candidates={state.candidates}
      />
    );
  }

  throw new Error(`Unknown stage ${state.stage}`);
};

const Selector = ({
  onSetSelection,
  candidates
}: {
  onSetSelection: (items: ChangeRequestIncludeCandidate[]) => void;
  candidates: ChangeRequestIncludeCandidate[];
}) => {
  const [selected, setSelected] = useState<ChangeRequestIncludeCandidate[]>(null);
  return (
    <>
      <Stack direction={'column'} sx={{ mb: 4 }} alignItems={'center'}>
        <h5>Select the lines you want to include</h5>
        <p>You will be able to edit them before submitting</p>
      </Stack>
      <DataTable
        dataKey={'documentId'}
        value={candidates}
        selection={selected}
        onSelectionChange={(e) => {
          setSelected(e.value);
        }}
        selectionMode={'multiple'}
      >
        <Column selectionMode="multiple" headerStyle={{ width: '3em' }} />
        <Column bodyClassName={'text-col'} field={'issueFriendlyId'} header={'Subissue'} />
        <Column bodyClassName={'text-col'} field={'documentFriendlyId'} header={'Document'} />
        <Column field={'lineCount'} header={'Lines'} dataType={'numeric'} />
        <Column
          field={'amount'}
          header={'Subtotal'}
          dataType={'numeric'}
          body={(e: ChangeRequestIncludeCandidate) => rooFmt.moneyMaybe(e.totals.subtotal, 'N/A')}
        />
        <Column
          field={'tax'}
          header={'Tax'}
          dataType={'numeric'}
          body={(e: ChangeRequestIncludeCandidate) => rooFmt.moneyMaybe(e.totals.tax, 'N/A')}
        />
        <Column
          field={'grandTotal'}
          header={'Grand Total'}
          body={(e: ChangeRequestIncludeCandidate) => rooFmt.moneyMaybe(e.totals.grandTotal, 'N/A')}
        />
      </DataTable>
      <Stack alignItems={'center'} justifyContent={'center'} sx={{ mt: 4 }}>
        <RooButton onClick={() => onSetSelection(selected)} className={'me-4'}>
          Continue
        </RooButton>
      </Stack>
    </>
  );
};
