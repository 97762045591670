import { Box, BoxProps } from '@mui/material';

export const Center = ({ sx, ...other }: BoxProps) => {
  return (
    <Box
      sx={[
        {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        },
        ...(Array.isArray(sx) ? sx : [sx])
      ]}
      {...other}
    />
  );
};
