import { MutationOptions, useMutation, useQueryClient } from '@tanstack/react-query';
import { Vendor } from '../../shared/api/clients';
import { useAppStore } from '../../shared/store';

export const useVendorMutation = <TVariables>(
  vendorId: string,
  options: MutationOptions<Vendor, unknown, TVariables>
) => {
  const qc = useQueryClient();
  const onVendorUpdated = useAppStore((x) => x.actions.onVendorUpdated);
  return useMutation({
    ...options,
    onSuccess: async (result, variables, context) => {
      if (options.onSuccess != null) {
        await options.onSuccess(result, variables, context);
      }
      await qc.resetQueries(['vendor', vendorId]);
      onVendorUpdated(result);
    }
  });
};
