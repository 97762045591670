import { IconButton, Tooltip } from '@mui/material';
import { RooIcon } from 'shared/icons';

export default function NoteTooltip({ notes }: { notes?: string }) {
  if (!notes) return null;

  return (
    <Tooltip title={'Notes: ' + notes} placement="top">
      <IconButton>
        <RooIcon size="sm" icon={'comments'} color="#0ab7b7" />
      </IconButton>
    </Tooltip>
  );
}
