import React, { useEffect, useMemo, useState } from 'react';
import { Card } from 'react-bootstrap';
import { apiProvider } from 'shared/api/apiProvider';
import { IWorkorder, WorkorderActivityLogEntry, WorkorderActivityType } from 'shared/api/clients';
import { LoaderSmall, ActivityTimeline, TimelineEntry, UserLink } from 'components';
import { rooEnum } from 'shared/store';
import { MuiIcon } from '../../../shared/icons';

const formatActivity = ({ log, workorder }: { log: WorkorderActivityLogEntry; workorder: IWorkorder }) => {
  const entry: TimelineEntry = {
    id: log.id,
    date: log.modifiedAt,
    color: 'primary',
    Icon: MuiIcon.QuestionMark,
    text: <>Unknown event</>,
    notes: null
  };

  const userCreated = <UserLink user={log.modifiedBy} role={null} />;

  switch (log.activityType) {
    case WorkorderActivityType.Created:
      entry.Icon = MuiIcon.Add;
      entry.text = (
        <>
          {userCreated} created Workorder <strong>{workorder.friendlyId}</strong>
        </>
      );
      break;
    case WorkorderActivityType.ChangedStatus:
      entry.Icon = MuiIcon.CompareArrows;
      entry.text = (
        <>
          {userCreated} changed the status from <strong>{rooEnum.workorderStatus.display(log.oldStatus)}</strong> to{' '}
          <strong>{rooEnum.workorderStatus.display(log.newStatus)}</strong>
        </>
      );
      break;
    case WorkorderActivityType.Updated:
      entry.Icon = MuiIcon.Edit;
      entry.text = <>{userCreated} updated the Workorder</>;
      break;
  }

  return entry;
};

export const WorkorderActivityLog = ({ workorder }: { workorder: IWorkorder }) => {
  const [{ logs, loading }, setstate] = useState({ logs: new Array<WorkorderActivityLogEntry>(), loading: true });
  useEffect(() => {
    const load = async () => {
      const items = await apiProvider.workOrdersClient.getActivityLog(workorder.id);
      await setstate({
        loading: false,
        logs: items
      });
    };
    void load();
  }, [workorder.id]);

  let body = <LoaderSmall />;

  if (!loading) {
    if (!logs.length) {
      body = (
        <div style={{ textAlign: 'center' }}>
          <h4>No entries</h4>
        </div>
      );
    } else {
      body = <FormattedTimeline workorder={workorder} logs={logs} />;
    }
  }

  return (
    <Card>
      <Card.Header>Activity Log</Card.Header>
      <Card.Body>{body}</Card.Body>
    </Card>
  );
};

const FormattedTimeline = ({ logs, workorder }: { logs: WorkorderActivityLogEntry[]; workorder: IWorkorder }) => {
  const entries = useMemo(() => logs.map((x) => formatActivity({ log: x, workorder })), [logs, workorder]);
  return <ActivityTimeline entries={entries} showNotificationBtn={false} />;
};
