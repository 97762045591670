import { LoaderBig, PropertyCard } from 'components';
import { useEffect, useState } from 'react';
import { Badge, Card } from 'react-bootstrap';
import { apiProvider } from 'shared/api/apiProvider';
import { DetailedAccountInfo, UserDetails } from 'shared/api/clients';
import { RooIcon } from 'shared/icons';
import { rooEnum, useIsAdmin } from 'shared/store';
import { cls } from 'shared/utils';

export const AccountInfo = ({ userDetails }: { userDetails: UserDetails }) => {
  const isAdmin = useIsAdmin();
  if (!isAdmin) {
    return null;
  }

  return (
    <Card className={'mt-4'}>
      <Card.Header>
        <strong>Account Info</strong>
      </Card.Header>
      <Card.Body>
        <AccountInfoBody userDetails={userDetails} />
      </Card.Body>
    </Card>
  );
};

const AccountInfoBody = ({ userDetails }: { userDetails: UserDetails }) => {
  const [{ data, isLoading }, setState] = useState<{ data: DetailedAccountInfo; isLoading: boolean }>({
    data: null,
    isLoading: true
  });

  useEffect(() => {
    const load = async () => {
      try {
        const info = await apiProvider.usersClient.getAccountInfo(userDetails.user.id);
        setState({ isLoading: false, data: info });
      } catch (e) {}
    };
    void load();
  }, [userDetails?.user?.id]);

  if (isLoading) {
    return <LoaderBig />;
  }

  return (
    <ul className="list-group">
      {data.companies.map((companyInfo) => (
        <li key={companyInfo.managementCompany.id} className="list-group-item">
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h4>
              <RooIcon icon={'building'} /> &nbsp;
              {companyInfo.managementCompany.name}
            </h4>
            <div>
              {companyInfo.capabilities.map((x) => (
                <Badge bg={rooEnum.capabilityType.color(x)} className={'me-2'}>
                  {rooEnum.capabilityType.display(x)}
                </Badge>
              ))}
            </div>
          </div>
          <br />
          <div className={'d-flex flex-wrap'}>
            {companyInfo.properties.map((prop) => (
              <PropertyCard className={'me-2'} property={prop} />
            ))}
          </div>
        </li>
      ))}
    </ul>
  );
};
