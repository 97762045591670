import { Alert, Box, Button, CircularProgress, Grid, ThemeProvider, Typography } from '@mui/material';
import oneEightLarge from 'images/1836/1836_large.png';
import rooBlue from 'images/roo-logo-blue.png';
import { useEffect, useMemo, useState } from 'react';
import { apiProvider } from 'shared/api/apiProvider';
import { LogInPayload, LogInSsoPayload } from 'shared/api/clients';
import { FieldMuiCheckbox, FieldMuiText, useRooForm } from 'components/form';
import { redirect, RouteDestination, Routes } from 'shared/routing';
import { AuthManager, useAppStore, useThemeName } from 'shared/store';
import theme, { darkTheme } from 'shared/theme';
import { RouterLink } from 'components';
import { useQueryParams } from 'shared/utils';
import { z } from 'zod';
import { AuthPage } from 'pages/Auth/AuthPage';

export const Login = () => {
  const { returnUrl, sso } = useQueryParams<{ returnUrl: string; sso: string }>();
  const finalReturnUrl = returnUrl ?? Routes.Dashboard;
  const hasSso = sso != null && sso !== '';

  return (
    <>
      {!hasSso && <LoginPassword returnUrl={finalReturnUrl} />}
      {hasSso && <LoginSso sso={sso} returnUrl={finalReturnUrl} />}
    </>
  );
};

const FormSchemaPassword = z.object({
  email: z.string().email(),
  password: z.string(),
  rememberMe: z.boolean()
});

type FormDefinitionPassword = z.infer<typeof FormSchemaPassword>;

export const LoginPassword = ({ returnUrl }: { returnUrl: string }) => {
  const [state, setState] = useState<'idle' | 'loading' | 'error'>('idle');
  const lastLogin = useMemo(() => AuthManager.instance.getLastLogin(), []);
  const { control, handleSubmit, setValue } = useRooForm(FormSchemaPassword, {
    defaultValues: {
      rememberMe: false,
      email: lastLogin
    }
  });

  const logIn = async (values: FormDefinitionPassword) => {
    setState('loading');
    try {
      const result = await apiProvider.authClient.logIn(
        new LogInPayload({
          email: values.email,
          password: values.password
        })
      );
      if (result.success) {
        if (values.email !== lastLogin) {
          AuthManager.instance.setLastLogin(values.rememberMe ? values.email : null);
        }

        if (result.needsTwoFactor) {
          redirect(Routes.TwoFactor);
        } else {
          AuthManager.instance.logIn(result.token, values.rememberMe);
          redirect(returnUrl as RouteDestination);
        }
      } else {
        setValue('password', null, { shouldValidate: false });
        setState('error');
      }
    } catch (e) {
      setState('error');
    }
  };

  return (
    <AuthPage title={'Log In - Walkthroo'}>
      <form noValidate onSubmit={handleSubmit(logIn)}>
        <Box className="d-flex flex-column align-items-center">
          <Typography variant="h5" component="div" gutterBottom sx={{ fontWeight: 700 }}>
            Login
          </Typography>
          <Typography variant="subtitle1" component="div" gutterBottom>
            Enter your details below to access your account
          </Typography>
        </Box>
        <ThemeProvider theme={darkTheme}>
          <FieldMuiText
            control={control}
            className={'Input-dark'}
            name={'email'}
            label={'Email'}
            type={'email'}
            autoComplete={'email'}
            placeholder={'Enter your Email'}
            margin={'none'}
            fullWidth
            required
          />
          <FieldMuiText
            type={'password'}
            control={control}
            className={'Input-dark'}
            margin="normal"
            name={'password'}
            label={'Password'}
            autoComplete="current-password"
            fullWidth
            required
          />
        </ThemeProvider>

        <Grid container sx={{ alignItems: 'center ' }}>
          <Grid item xs>
            <FieldMuiCheckbox control={control} label={'Remember me'} name={'rememberMe'} color={'primary'} />
          </Grid>
          <Grid item>
            <RouterLink to={Routes.RequestPasswordReset} style={{ color: 'white', textDecorationLine: 'underline' }}>
              Forgot Password
            </RouterLink>
          </Grid>
        </Grid>

        <Button
          type="submit"
          fullWidth
          variant="contained"
          size={'largest'}
          sx={{ mt: 2, mb: 2 }}
          onClick={handleSubmit(logIn)}
        >
          {state === 'loading' ? <CircularProgress style={{ width: 31, height: 31 }} color="inherit" /> : 'Log In'}
        </Button>
        <Box sx={{ mt: 3 }}>
          {state === 'error' && (
            <Alert severity="error">Could not log you in. Please check your e-mail and password.</Alert>
          )}
        </Box>
        <Grid container className="d-flex justify-content-center mt-3">
          <Grid item>
            Don't have an account?&nbsp;
            <RouterLink to={Routes.Register} style={{ color: theme.palette.primary.main }}>
              Register
            </RouterLink>
          </Grid>
        </Grid>
      </form>
    </AuthPage>
  );
};

const FormSchemaSso = z.object({
  email: z.string().email()
});

type FormDefinitionSso = z.infer<typeof FormSchemaSso>;

export const LoginSso = ({ sso, returnUrl }: { sso: string; returnUrl: string }) => {
  const [state, setState] = useState<'preparing' | 'idle' | 'loading' | 'error'>('preparing');
  const { control, handleSubmit } = useRooForm(FormSchemaSso);
  const theme = useThemeName();
  const setTheme = useAppStore((x) => x.actions.setTheme);

  useEffect(() => {
    const run = async () => {
      setState('preparing');
      try {
        const theme = await apiProvider.authClient.getThemeFromSso(sso);
        setTheme(theme as any);
      } catch (e) {}
      setState('idle');
    };

    void run();
  }, [sso, setTheme]);

  const logIn = async (values: FormDefinitionSso) => {
    setState('loading');
    try {
      const result = await apiProvider.authClient.logInSso(
        new LogInSsoPayload({
          email: values.email,
          ssoToken: sso
        })
      );

      if (result.success) {
        if (result.needsTwoFactor) {
          redirect(Routes.TwoFactor);
        } else {
          AuthManager.instance.logIn(result.token, false);
          redirect(returnUrl as RouteDestination);
        }
      } else {
        setState('error');
      }
    } catch (e) {
      setState('error');
    }
  };

  let logo = <CircularProgress />;
  if (state !== 'preparing') {
    if (theme === 'default') {
      logo = (
        <Box className={'brand'}>
          <img src={rooBlue} alt="" />
        </Box>
      );
    }

    if (theme === '1836') {
      logo = (
        <Box className={'brand'}>
          <img style={{ backgroundColor: 'white' }} src={oneEightLarge} alt="" />
        </Box>
      );
    }
  }

  return (
    <AuthPage title={'Log In - Walkthroo'} logo={logo}>
      <form noValidate onSubmit={handleSubmit(logIn)}>
        <Box className="d-flex flex-column align-items-center">
          <Typography variant="h5" component="div" gutterBottom sx={{ fontWeight: 700 }}>
            Login
          </Typography>
          <Typography variant="subtitle1" component="div" gutterBottom>
            Use your Buildium e-mail to log in to Walkthroo
          </Typography>
        </Box>
        <ThemeProvider theme={darkTheme}>
          <FieldMuiText
            control={control}
            className={'Input-dark'}
            name={'email'}
            label={'Email'}
            type={'email'}
            autoComplete={'email'}
            placeholder={'Enter your Email'}
            margin={sso ? 'normal' : 'none'}
            fullWidth
            required
          />
        </ThemeProvider>

        <Button
          type="submit"
          disabled={state === 'preparing'}
          size={'largest'}
          fullWidth
          sx={{ mt: 2, mb: 2 }}
          onClick={handleSubmit(logIn)}
        >
          {state === 'loading' ? <CircularProgress style={{ width: 31, height: 31 }} color="inherit" /> : 'Log In'}
        </Button>
        <Box sx={{ mt: 3 }}>
          {state === 'error' && (
            <Alert severity="error">Could not log you in. Please check your e-mail and password.</Alert>
          )}
        </Box>
      </form>
    </AuthPage>
  );
};
