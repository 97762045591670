import { CreateInspectionPayload, Property } from 'shared/api/clients';
import { z } from 'zod';
import { DeepRequired } from '../../../shared/utils';
import { RooDialog, SaveButtonMui } from '../../../components';
import Button from '@mui/material/Button';
import React from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRequiredRooForm } from 'components/form/utils';
import { apiProvider } from '../../../shared/api/apiProvider';
import { InspectorField, InspectorSelection } from './InspectorField';
import { PropertyField } from './PropertyField';
import { Box, Stack } from '@mui/material';
import { FieldDatePicker, FieldMuiText } from '../../../components/form';

export const InspectionSchema = z.object({
  property: z.custom<Property>((x) => x != null, { message: 'Required' }),
  notes: z.string().optional(),
  dueDate: z.date().nullish(),
  inspector: z.custom<InspectorSelection>((x) => x != null, {
    message: 'Required'
  })
});
export type InspectionDefinition = DeepRequired<z.infer<typeof InspectionSchema>>;

export const CreateInspectionModal = ({
  visible,
  onCloseRequest
}: {
  visible: boolean;
  onCloseRequest: () => void;
}) => {
  return (
    <RooDialog open={visible} onClose={onCloseRequest} maxWidth={'sm'} fullWidth>
      <RooDialog.Title onClose={onCloseRequest}>Create Inspection</RooDialog.Title>
      <ModalBody visible={visible} onCloseRequest={onCloseRequest} />
    </RooDialog>
  );
};

const ModalBody = ({ onCloseRequest }: { visible: boolean; onCloseRequest: () => void }) => {
  const qc = useQueryClient();
  const { handleSubmit, control, formState } = useRequiredRooForm(InspectionSchema);

  const { mutateAsync } = useMutation({
    mutationFn: (values: InspectionDefinition) =>
      apiProvider.inspectionClient.createInspection(
        new CreateInspectionPayload({
          propertyId: values.property.id,
          notes: values.notes,
          dueDate: values.dueDate,
          assignedCompanyId: values.inspector.company?.id,
          assignedUserId: values.inspector.user?.id,
          assignedVendorId: values.inspector.vendor?.id
        })
      ),
    onSuccess: (data) => {
      void qc.invalidateQueries(['inspections']);
      onCloseRequest();
    }
  });

  return (
    <form noValidate onSubmit={handleSubmit((vals) => mutateAsync(vals))}>
      <RooDialog.Content>
        <Stack gap={3}>
          {/*@ts-ignore*/}
          <PropertyField control={control} />
          {/*@ts-ignore*/}
          <InspectorField control={control} />
          <FieldDatePicker control={control} name={'dueDate'} label={'Due Date'} style={{ width: 200 }} />

          <FieldMuiText control={control} name={'notes'} label={'Notes'} multiline fullWidth minRows={3} />
        </Stack>
      </RooDialog.Content>
      <RooDialog.Actions>
        <SaveButtonMui control={control}>Save</SaveButtonMui>
        <Button
          color="muted"
          disabled={formState.isSubmitting || formState.isValidating}
          size="small"
          onClick={onCloseRequest}
        >
          Cancel
        </Button>
      </RooDialog.Actions>
    </form>
  );
};
