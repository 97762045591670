import { useEffect, useState } from "react";

export const useIntervalRerender = (period: number) => {
  const [, setTimestamp] = useState(new Date().getTime());
  useEffect(() => {
    const interval = setInterval(
      () => setTimestamp(new Date().getTime()),
      period,
    );
    return () => {
      clearInterval(interval);
    };
  }, [period]);
};
