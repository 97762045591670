import { Grid, Stack, Typography } from '@mui/material';
import { FileThumbnail } from 'components/Files/FileThumbnail';
import { ControlProps } from 'components/form/types';
import React, { ReactNode, useEffect, useRef } from 'react';
import { Button } from 'react-bootstrap';
import { FieldError, FieldValues, useController } from 'react-hook-form';
import { AttachmentEntityType, AttachmentFile, IAttachmentFile } from 'shared/api/clients';
import { RooIcon } from 'shared/icons';
import { FilePickerModal } from '../../../../components/Files/FilePicker/FilePicker';
import { useCurrentIssue } from '../../shared/CurrentIssueContext';
import { useDisclosure, useListState } from '@roo/lib';

export const FileAreaField = <TForm extends FieldValues>({
  control,
  name,
  label,
  entityType
}: { entityType: AttachmentEntityType } & ControlProps<TForm>) => {
  const {
    field,
    fieldState: { error }
  } = useController({
    control: control,
    name: name
  });
  return (
    <FileArea
      label={label}
      entityType={entityType}
      // @ts-ignore
      onChange={field.onChange}
      error={error}
      initialValues={field.value as IAttachmentFile[]}
    />
  );
};

const MAX_FILES = 1;

const FileArea = ({
  label,
  initialValues,
  onChange,
  entityType,
  error
}: {
  label: ReactNode;
  initialValues: IAttachmentFile[];
  error: FieldError;
  onChange: (files: IAttachmentFile[]) => void;
  entityType: AttachmentEntityType;
}) => {
  const [files, { append, remove }] = useListState<IAttachmentFile>(initialValues ?? []);
  const hasFiles = files.length > 0;
  const canUpload = files.length < MAX_FILES;
  const onChangeRef = useRef<(files: IAttachmentFile[]) => void>();
  onChangeRef.current = onChange;
  useEffect(() => {
    onChangeRef.current?.(files);
  }, [files]);
  return (
    <Stack direction={hasFiles ? 'column-reverse' : 'column'}>
      {canUpload && (
        <div style={{ flexGrow: 0, paddingTop: hasFiles ? '12px' : null }}>
          <FilePickerButton entityType={entityType} append={append} label={label} />
        </div>
      )}

      {files.length > 0 && (
        <Stack spacing={1}>
          <Typography>{label}</Typography>
          <Grid container spacing={2}>
            {files.map((file, idx) => (
              <Grid item key={file.id}>
                <div style={{ position: 'relative' }}>
                  <FileThumbnail size={128} file={file} />
                  <Button
                    style={{ top: 6, right: 6, position: 'absolute' }}
                    size={'sm'}
                    variant={'danger'}
                    onClick={() => {
                      remove(idx);
                    }}
                  >
                    <RooIcon icon={['fas', 'trash']} />
                  </Button>
                </div>
              </Grid>
            ))}
          </Grid>
        </Stack>
      )}
      {error && (
        <Typography variant={'caption'} sx={(theme) => ({ color: theme.palette.error.main })}>
          {error.message}
        </Typography>
      )}
    </Stack>
  );
};

const FilePickerButton = ({
  label,
  append,
  entityType
}: {
  label: ReactNode;
  append: (...items: AttachmentFile[]) => void;
  entityType: AttachmentEntityType;
}) => {
  const newModal = useDisclosure(false);
  const { issue } = useCurrentIssue();
  return (
    <>
      <Button onClick={newModal.open} className={'pt-0 pe-0'} variant={'link'}>
        <RooIcon icon={['fas', 'upload']} /> Add Picture {label}
      </Button>
      <FilePickerModal
        modal={newModal}
        onAppend={append}
        allowedFileTypes={['image']}
        maxFiles={MAX_FILES}
        meta={{
          entityType: entityType,
          galleryEntityId: issue?.id,
          galleryEntityType: AttachmentEntityType.Issue
        }}
      />
    </>
  );
};
