import { useCallback, useEffect, useLayoutEffect, useRef } from "react";

export function usePreparedTimeout(
  callback: (...params: any) => void,
  delay: number | null,
) {
  const id = useRef(null);

  const savedCallback = useRef(callback);

  useLayoutEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  const timeout = useCallback(
    (...args: any[]) => {
      if (id.current) clearTimeout(id.current);
      id.current = setTimeout(() => savedCallback.current(...args), delay);
    },
    [delay],
  );

  useEffect(() => {
    return () => {
      if (id.current) clearTimeout(id.current);
    };
  }, []);

  return timeout;
}
