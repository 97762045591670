import { z, ZodObject, ZodRawShape, ZodString, ZodTypeAny } from 'zod';
import { regex } from './patterns';

export const RequiredMessage = { message: 'This field is required' };
export const required = (str: ZodString) => str.min(1, RequiredMessage);

export const makeNullish = <T extends ZodRawShape>(
  schema: ZodObject<T>
): ZodObject<{ [k in keyof T]: ReturnType<T[k]['nullish']> }, 'strip', ZodTypeAny> => {
  const newShape: any = {};
  for (const key in schema.shape) {
    const fieldSchema = schema.shape[key];
    newShape[key] = fieldSchema.isOptional() && fieldSchema.isNullable() ? fieldSchema : fieldSchema.nullish();
  }
  return new ZodObject({
    ...schema._def,
    shape: () => newShape
  }) as any;
};

export const PersonSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  email: z.string().email(),
  phone: z.string(),
  userId: z.string().nullish()
});

export const PasswordConfirmationSchema = z
  .object({
    newPassword: z.string().nullish(),
    confirmPassword: z.string().nullish()
  })
  .superRefine((val, ctx) => {
    if (val.newPassword == null && val.confirmPassword == null) {
      return;
    }

    if (!/^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@#$!%^*?&+~|{}:;<>/])[A-Za-z\d@#$!%^*?&+~|{}:;<>/]{12,}/.test(val.newPassword)) {
      ctx.addIssue({
        message:
          'The passsword has to be minimum 12 character long, and has to contain at least one letter, one number and one special character!',
        code: 'custom',
        path: ['newPassword']
      });
      return;
    }

    if (val.newPassword === val.confirmPassword) {
      return;
    }

    ctx.addIssue({
      message: "Passwords don't match",
      code: 'custom',
      path: ['confirmPassword']
    });
  });

export const AddressSchema = z.object({
  address1: z.string(),
  address2: z.string().nullish(),
  city: z.string(),
  state: z.string(),
  zipCode: z.string().regex(regex.zipCode, { message: 'Not a valid ZIP code' })
});

export const OptionalAddressSchema = makeNullish(AddressSchema);

export const ContactSchema = z.object({
  notes: z.string().nullish(),
  propertiesFile: z.any().nullish()
});

export type AddressForm = z.infer<typeof AddressSchema>;
export type OptionalAddressForm = z.infer<typeof OptionalAddressSchema>;
export type PersonForm = z.infer<typeof PersonSchema>;
export type ContactForm = z.infer<typeof ContactSchema>;

export const customizeErrors = () => {
  const errorMap: z.ZodErrorMap = (error, ctx) => {
    switch (error.code) {
      case z.ZodIssueCode.invalid_type:
        return { message: `Required` };
    }

    return { message: ctx.defaultError };
  };

  z.setErrorMap(errorMap);
};

export const TimeBlockSchema = z.object({
  startHour: z.number(),
  startMinutes: z.number(),
  durationMinutes: z.number()
});
