import { Stack } from '@mui/material';
import { Col, Row } from 'react-bootstrap';
import { Bid, IBid } from 'shared/api/clients';
import { LineTable } from 'pages/Workorders/ViewIssue/Documents/LineTable';
import { rooFmt } from 'shared/utils';
import { useCurrentVendor } from '../../../../../shared/store';
import { DocumentViewTotals } from '../DocumentViewTotals';

export const BidInfo = ({ bid }: { bid: IBid }) => {
  const currVendor = useCurrentVendor();
  return (
    <>
      {!!bid.reviewerNotes && (
        <Row>
          <Col>
            <dl>
              <dt>Reviewer Notes</dt>
              <dd>{bid.reviewerNotes}</dd>
            </dl>
          </Col>
        </Row>
      )}
      {!!bid.revisionNotes && (
        <Row>
          <Col>
            <dl>
              <dt>Reviewer Notes</dt>
              <dd>{bid.revisionNotes}</dd>
            </dl>
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 1, md: 4 }}>
            <dl>
              <dt>Start Date</dt>
              <dd>{rooFmt.instantDate(bid.startDate)}</dd>
            </dl>
            <dl>
              <dt>End Date</dt>
              <dd>{rooFmt.instantDate(bid.endDate)}</dd>
            </dl>
          </Stack>
        </Col>
      </Row>

      <Row>
        <Col>
          <LineTable lines={bid.lines} />
        </Col>
      </Row>

      <Row>
        <Col>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'flex-end',
              flexGrow: 0,
              marginTop: '30px'
            }}
          >
            <DocumentViewTotals totals={bid.totals} isAuthor={currVendor?.id === bid.vendor.id} />
          </div>
        </Col>
      </Row>
    </>
  );
};
