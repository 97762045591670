import { Column } from 'primereact/column';
import { DataTable as PrimeDataTable } from 'primereact/datatable';
import { IIssue, Issue } from 'shared/api/clients';
import { Routes } from 'shared/routing';
import { IconLink } from 'components/Links';
import { rooEnum } from 'shared/store';
import { rooFmt } from 'shared/utils';

export const IssuesClientGrid = ({ issues }: { issues: Issue[] }) => {
  return (
    <PrimeDataTable
      value={issues}
      responsiveLayout="stack"
      cellClassName={(_, options: { rowData: IIssue }) => {
        return options.rowData?.isLegacy ?? false ? 'legacy-cell' : null;
      }}
    >
      <Column
        field={'friendlyId'}
        sortable
        header={'Issue ID'}
        body={(row: IIssue) => (
          <IconLink
            style={{ whiteSpace: 'nowrap' }}
            to={{ pathname: Routes.IssueView, params: { issueId: row.id, workorderId: row.workorderId } }}
            text={row.friendlyId}
          />
        )}
      />
      <Column field="title" header="Title" sortable />
      <Column field="status" header="Status" sortable body={(row: IIssue) => rooEnum.issueStatus.display(row.status)} />
      <Column field="vendor.companyName" header="Vendor" sortable />
      <Column field={'dateCreated'} sortable header={'Created'} body={(row: IIssue) => rooFmt.date(row.dateCreated)} />
      <Column
        field={'dateModified'}
        sortable
        header={'Modified'}
        body={(row: IIssue) => rooFmt.date(row.dateModified)}
      />
    </PrimeDataTable>
  );
};
