import { AddNotePayload, NoteEntityType, NoteSource } from '../../shared/api/clients';
import React, { useState } from 'react';
import { useAddNote } from '../../shared/api/queries';
import { showSuccess } from '../../shared/utils';
import { Stack, TextField } from '@mui/material';
import { RooButton } from '../RooButton';

export const AddNoteForm = ({
  entityType,
  entityId,
  noteSource
}: {
  entityType: NoteEntityType;
  entityId: string;
  noteSource: NoteSource;
}) => {
  const [noteText, setNoteText] = useState<string>('');
  const hasText = noteText.trim() !== '';
  const mutation = useAddNote(() => {
    setNoteText('');
    showSuccess();
  });
  return (
    <>
      <Stack spacing={2}>
        <TextField
          label={'Notes'}
          multiline
          value={noteText}
          onChange={(e) => setNoteText(e.target.value)}
          minRows={3}
          sx={{ width: '100%' }}
        />
        <Stack direction={'row'} justifyContent={'end'}>
          <RooButton
            onClick={() =>
              mutation.mutate(
                new AddNotePayload({
                  entityId,
                  entityType,
                  note: noteText,
                  noteSource: noteSource
                })
              )
            }
            disabled={!hasText || mutation.isLoading}
            loading={mutation.isLoading}
            variant={'success'}
            icon={'plus'}
          >
            Add
          </RooButton>
        </Stack>
      </Stack>
    </>
  );
};
