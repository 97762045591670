import { Grid, ScopedCssBaseline, TextField, Typography } from '@mui/material';
import React, { ReactNode, useState } from 'react';
import { IAddress } from 'shared/api/clients';
import { RooButton, RooDialog } from 'components';
import { rooFmt, showSuccess } from 'shared/utils';
import { useCurrentIssue } from 'pages/Workorders/shared/CurrentIssueContext';
import { LockedVendor, useVendorSelector, VendorSelector } from 'components/VendorSelector/VendorSelector';

export const VendorSelectorModal = ({
  referenceAddress,
  selectionMode,
  lockedVendors,
  save,
  visible,
  onCloseRequest,
  saveButtonFormatter,
  title
}: {
  referenceAddress: IAddress;
  lockedVendors: LockedVendor[];
  selectionMode: 'single' | 'multiple';
  save: (selectedIds: string[], notes: string) => Promise<void>;
  visible: boolean;
  onCloseRequest: () => void;
  saveButtonFormatter: (selectedIds: string[], isSaving: boolean) => string;
  title: ReactNode;
}) => {
  const { issue } = useCurrentIssue();
  const [view, setView] = useState<'selector' | 'comments'>('selector');
  const [notes, setNotes] = useState('');
  const { selectedIds, ...selectorRest } = useVendorSelector({
    lockedVendors: lockedVendors,
    selectionMode: selectionMode,
    referenceAddress: referenceAddress,
    excludedVendors: [issue.managingVendorId]
  });

  const [isSaving, setIsSaving] = useState(false);

  const onSave = async () => {
    if (view === 'selector') {
      setView('comments');
      return;
    }

    setIsSaving(true);
    try {
      await save(selectedIds, notes);
      showSuccess();
      onCloseRequest();
    } catch (e) {}
    setIsSaving(false);
  };

  const selectedCount = selectedIds.length;
  return (
    <ScopedCssBaseline>
      <RooDialog
        scroll={'body'}
        fullWidth={view === 'selector'}
        maxWidth={view === 'selector' ? 'lg' : false}
        onClose={onCloseRequest}
        aria-labelledby="customized-dialog-title"
        open={visible}
      >
        <RooDialog.Title id="customized-dialog-title" onClose={onCloseRequest}>
          {title}
        </RooDialog.Title>
        <RooDialog.Content dividers>
          {view === 'selector' && <VendorSelector selectedIds={selectedIds} {...selectorRest} />}
          {view === 'comments' && (
            <>
              <Grid sx={{ padding: '0 80px' }} container justifyContent={'center'} alignItems={'center'}>
                <Grid item>
                  <Typography sx={{ marginBottom: '16px' }} variant={'subtitle1'}>
                    Leave a note for the {rooFmt.counted(selectedIds.length, 'vendor', 'vendors', true)} (optional)
                  </Typography>
                  <TextField
                    label={'Notes'}
                    multiline
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                    minRows={3}
                    sx={{ width: '100%' }}
                  />
                </Grid>
              </Grid>
            </>
          )}
        </RooDialog.Content>
        <RooDialog.Actions>
          {view === 'comments' && (
            <RooButton variant={'secondary'} onClick={() => setView('selector')}>
              Return to Selection
            </RooButton>
          )}
          <RooButton disabled={selectedCount === 0} loading={isSaving} onClick={() => void onSave()}>
            {saveButtonFormatter(selectedIds, isSaving)}
          </RooButton>
        </RooDialog.Actions>
      </RooDialog>
    </ScopedCssBaseline>
  );
};
