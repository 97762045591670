import { RooIcon } from 'shared/icons';
import { ComponentProps, ReactNode } from 'react';
import { Card } from 'react-bootstrap';
import { IUserSummary } from 'shared/api/clients';
import { ImpersonateButton } from 'components/ImpersonateButton';
import { useIsAdmin } from 'shared/store';
import { classNames } from 'shared/utils';
import { RooAvatar } from 'components/RooAvatar';

export type UserCardProps = Omit<ComponentProps<typeof Card>, 'children'> & {
  user: IUserSummary;
  role: string;
  children?: ReactNode;
};

export const UserCard = ({ user, role, className, children, ...rest }: UserCardProps) => {
  const isAdmin = useIsAdmin();
  return (
    <Card {...rest} className={classNames('user-card', className)}>
      <Card.Body>
        <div className={'d-flex user-header mb-1'}>
          <div className={'avatar-container'}>
            <RooAvatar name={user.fullName} avatarUrl={user.avatarUrl} />
          </div>
          <div className={'user-name'}>
            <strong>{user.fullName}</strong>
            {role && <div className={'user-role'}>{role}</div>}
          </div>
        </div>

        {Boolean(user.contactInfo.email) && (
          <div className={'user-email mb-1'}>
            <RooIcon icon={['fas', 'envelope']} className={'text-primary'} /> &nbsp;
            <a href={`mailto:${user.contactInfo.email}`}>{user.contactInfo.email}</a>
          </div>
        )}

        {user.contactInfo.phoneNumber && (
          <div className={'user-phone'}>
            <RooIcon icon={['fas', 'phone']} className={'text-primary'} /> &nbsp;
            <a href={`tel:${user.contactInfo.phoneNumber}`}>{user.contactInfo.phoneNumber}</a>
          </div>
        )}
        {isAdmin && (
          <ImpersonateButton
            account={{ userId: user.id, firstName: user.firstName, lastName: user.lastName }}
            sendToLanding={false}
          />
        )}
        {children}

        <span className={'type-indicator'}>
          <RooIcon icon={['fas', 'user']} className={'type-indicator'} /> &nbsp;
        </span>
      </Card.Body>
    </Card>
  );
};
