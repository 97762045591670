import { useCurrentIssue } from 'pages/Workorders/shared/CurrentIssueContext';
import { CancelIssueButton } from 'pages/Workorders/ViewIssue/CancelIssueButton';
import { RequestBidsButton } from 'pages/Workorders/ViewIssue/Documents/Bids/RequestBids';
import { skipEstimationAction } from 'pages/Workorders/ViewIssue/Documents/Estimates/SkipEstimationModal';
import { completeWorkAction } from 'pages/Workorders/ViewIssue/Documents/Checklist/CompleteWorkModal';
import { InviteVendorsButton } from 'pages/Workorders/ViewIssue/InviteVendors/InviteVendorsModal';
import { ReviewInvitationButton } from 'pages/Workorders/ViewIssue/InviteVendors/ReviewInvitationButtons';
import { MarkPaidButton } from 'pages/Workorders/ViewIssue/MarkPaidButton';
import { acceptAssignmentAction, declineAssignmentAction } from 'pages/Workorders/ViewIssue/ReviewAssignmentModal';
import { ReviewIssueScheduleButton } from 'pages/Workorders/ViewIssue/ReviewIssueScheduleButton';
import { AssignVendorButton } from 'pages/Workorders/ViewIssue/Workflow/AssignVendorButton';
import { setFinalScheduleAction, setTentativeScheduleAction } from 'pages/Workorders/ViewIssue/Workflow/ScheduleModal';
import { skipSchedulingAction } from 'pages/Workorders/ViewIssue/Workflow/SkipSchedulingModal';
import { TenantCancelButton } from 'pages/Workorders/ViewIssue/Workflow/TenantCancelButton';
import { TenantRescheduleButton } from 'pages/Workorders/ViewIssue/Workflow/TenantRescheduleButton';
import { UnassignVendorButton } from 'pages/Workorders/ViewIssue/Workflow/UnassignVendorButton';
import { vendorCancelAction } from 'pages/Workorders/ViewIssue/Workflow/VendorCancelModal';
import { vendorRescheduleAction } from 'pages/Workorders/ViewIssue/Workflow/VendorRescheduleModal';
import {
  IssueAction,
  IssueRole,
  IssueStatus,
  ReviewInvitationAction,
  ReviewIssueScheduleAction,
  ScheduleStatus
} from 'shared/api/clients';
import React, { Fragment } from 'react';
import { Button, Stack } from '@mui/material';
import { IssueActionListEntry, getActionColor, issueActionList } from '../shared/types';
import { ActionsMenu, ActionsMenuItem } from '../../../components/ActionsMenu';
import { MuiIcon } from '../../../shared/icons';
import { enumIsNot, useIsSmallScreen } from '../../../shared/utils';
import { scrollToChatAction } from './ScrollToChatAction';
import { createInvoiceAction, createPartialInvoiceAction } from './Documents/Invoices/CreateInvoiceModal';
import { scrollToBidsAction } from './ScrollToBidsAction';
import { createChangeRequestAction } from './Documents/ChangeRequests/CreateChangeRequestModal';
import { EstimateSentToggle } from './Documents/Estimates/EstimateSentToggle';
import { vendorSkipBiddingAction } from './Workflow/SkipBiddingModal';
import { AuthManager, useCurrentUser } from '../../../shared/store';

export const IssueActivityBar = () => {
  const { userRole } = useCurrentIssue();
  return (
    <div className={'activityBar'}>
      {(userRole === IssueRole.MainPropertyManager || userRole === IssueRole.AltPropertyManager) && (
        <ManagerActivityBar />
      )}
      {userRole === IssueRole.PropertyOwner && <OwnerActivityBar />}
      {userRole === IssueRole.Vendor && <VendorActivityBar />}
      {userRole === IssueRole.Tenant && <TenantActivityBar />}
      {userRole === IssueRole.Bidder && <BidderActivityBar />}
      {userRole === IssueRole.VendorCandidate && <CandidateActivityBar />}
    </div>
  );
};

const ManagerActivityBar = () => {
  const { issue } = useCurrentIssue();
  const isImpersonating = AuthManager.instance.isImpersonating();

  const canCancel = enumIsNot(issue.status, [
    IssueStatus.Closed,
    IssueStatus.CanceledOwnerDeclined,
    IssueStatus.Paid,
    IssueStatus.CanceledLostBid,
    IssueStatus.CanceledOther
  ]);

  return (
    <>
      {issue.status === IssueStatus.New && (
        <>
          {(issue.parentId != null || isImpersonating) && (
            <>
              <AssignVendorButton />
              <InviteVendorsButton />
            </>
          )}

          <RequestBidsButton />
        </>
      )}

      {issue.status !== IssueStatus.New && <UnassignVendorButton />}

      {canCancel && <CancelIssueButton />}

      {(issue.status === IssueStatus.NeedsInvoice ||
        issue.status === IssueStatus.PaymentPending ||
        issue.status === IssueStatus.InvoiceReview) && <MarkPaidButton />}
    </>
  );
};

const OwnerActivityBar = (): JSX.Element => {
  return null;
};

const VendorActivityBar = () => {
  const { canAct } = useCurrentIssue();
  return (
    <Stack direction={'row'} alignContent={'center'}>
      {canAct(IssueAction.ToggleEstimateSent) && <EstimateSentToggle />}
      <ActionList
        actions={[
          acceptAssignmentAction,
          declineAssignmentAction,
          setTentativeScheduleAction,
          setFinalScheduleAction,
          skipSchedulingAction,
          createChangeRequestAction,
          completeWorkAction,
          vendorRescheduleAction,
          skipEstimationAction,
          createPartialInvoiceAction,
          createInvoiceAction,
          vendorCancelAction,
          scrollToChatAction
        ]}
      />
    </Stack>
  );
};

const BidderActivityBar = () => {
  return <ActionList actions={[vendorSkipBiddingAction, scrollToBidsAction, scrollToChatAction]} />;
};

const TenantActivityBar = () => {
  const { issue } = useCurrentIssue();
  const isWorkComplete = [IssueStatus.NeedsInvoice, IssueStatus.Paid, IssueStatus.Closed].indexOf(issue.status) >= 0;
  const canCancel =
    !isWorkComplete &&
    issue.status !== IssueStatus.TenantCanceled &&
    issue.status !== IssueStatus.Bidding &&
    issue.status !== IssueStatus.CanceledOwnerDeclined &&
    issue.status !== IssueStatus.CanceledLostBid &&
    issue.status !== IssueStatus.CanceledOther;
  return (
    <>
      {issue.status === IssueStatus.WorkInProgress && issue.schedule != null && <TenantRescheduleButton />}
      {issue.status === IssueStatus.VendorScheduling &&
        issue.schedule != null &&
        issue.schedule.status === ScheduleStatus.PendingReview && (
          <>
            <ReviewIssueScheduleButton action={ReviewIssueScheduleAction.Accept} />
            <ReviewIssueScheduleButton action={ReviewIssueScheduleAction.Reject} />
          </>
        )}
      {canCancel && <TenantCancelButton />}
    </>
  );
};

const CandidateActivityBar = () => {
  const { canAct, myCandidacy } = useCurrentIssue();

  if (!canAct(IssueAction.AcceptMultiAssignment) && !canAct(IssueAction.DeclineMultiAssignment)) {
    return null;
  }

  return (
    <>
      <ReviewInvitationButton candidacy={myCandidacy} action={ReviewInvitationAction.Accept} />
      <ReviewInvitationButton candidacy={myCandidacy} action={ReviewInvitationAction.Declined} />
    </>
  );
};

export const ActionModalsRenderer = () => {
  const actions = issueActionList.getActions();
  const modalActions = actions.filter((x) => x.modalToRender != null);

  return (
    <>
      {modalActions.map((act) => (
        <Fragment key={`${act.key}-modal`}>{act.modalToRender}</Fragment>
      ))}
    </>
  );
};

const ActionList = ({ actions }: { actions: IssueActionListEntry[] }) => {
  const currentIssue = useCurrentIssue();
  const shownActions = actions.filter((x) => x.isVisible(currentIssue));
  const isSmallScreen = useIsSmallScreen();
  if (shownActions.length === 0) {
    return null;
  }

  const limit = isSmallScreen ? 2 : 3;

  const buttonActions: IssueActionListEntry[] = [];
  const menuActions: IssueActionListEntry[] = [];

  for (let i = 0; i < shownActions.length; i++) {
    if (buttonActions.length < limit && !shownActions[i].isDeprioritized?.(currentIssue)) {
      buttonActions.push(shownActions[i]);
    } else {
      menuActions.push(shownActions[i]);
    }
  }

  return (
    <Stack direction={'row'}>
      {buttonActions.map(
        (act) =>
          act.isVisible(currentIssue) && (
            <Button
              variant={'contained'}
              key={`${act.key}-button`}
              disabled={act.isDisabled(currentIssue)}
              onClick={act.onClick}
              color={getActionColor(act.color, currentIssue)}
              startIcon={<act.Icon />}
            >
              {act.label}
            </Button>
          )
      )}
      {menuActions.length > 0 && (
        <ActionsMenu
          moreButton={(onClick) => (
            <Button
              className={'Button-Basic'}
              variant={'contained'}
              color={'info'}
              onClick={onClick}
              endIcon={<MuiIcon.MoreHoriz />}
            >
              More
            </Button>
          )}
        >
          {menuActions.map((act) => (
            <ActionsMenuItem
              key={`${act.key}-menu`}
              onClick={act.onClick}
              Icon={act.Icon}
              color={getActionColor(act.color, currentIssue)}
            >
              {act.label}
            </ActionsMenuItem>
          ))}
        </ActionsMenu>
      )}
    </Stack>
  );
};
