import { Alert, Badge, Card } from 'react-bootstrap';
import { IInvoiceSplitProjection, InvoiceStatus, IssueRole, SplitDocumentParty } from 'shared/api/clients';
import { RooButton } from 'components';
import { useCurrentIssue } from 'pages/Workorders/shared/CurrentIssueContext';
import { createInvoiceAction, createPartialInvoiceAction } from './CreateInvoiceModal';
import { Box, Button, Card as MuiCard, CardContent, IconButton, Stack, Typography } from '@mui/material';
import { MuiIcon, RooIcon } from '../../../../../shared/icons';
import { rooEnum } from '../../../../../shared/store';
import { rooFmt } from '../../../../../shared/utils';
import { ActionsContainer } from '../ActionsContainer';
import { InvoiceMenu } from './InvoiceListActions';
import { InvoiceInfoButton } from './InvoiceInfoButton';
import React from 'react';
import { apiProvider } from '../../../../../shared/api/apiProvider';
import { orderBy } from 'lodash';
import { SplitInvoiceModal } from './SplitInvoiceModal';
import { useDisclosure } from '@roo/lib';

export const InvoiceList = () => {
  const currentIssue = useCurrentIssue();
  const { userRole, visibleInvoices } = currentIssue;
  const showDraftWarning =
    userRole === IssueRole.Vendor && visibleInvoices.find((x) => x.status === InvoiceStatus.Draft) != null;
  const canCreateFull = createInvoiceAction.isVisible(currentIssue);
  const canCreatePartial = createPartialInvoiceAction.isVisible(currentIssue);

  return (
    <Card>
      <Card.Header>
        <Stack direction={'row'} justifyContent={'space-between'}>
          Invoices{' '}
          <Stack direction={'row'} spacing={1}>
            {canCreateFull && (
              <RooButton
                color={'primary'}
                size={'sm'}
                className={'float-end slim'}
                icon={'plus'}
                onClick={createInvoiceAction.onClick}
              >
                Add
              </RooButton>
            )}
            {canCreatePartial && (
              <RooButton
                color={'primary'}
                size={'sm'}
                className={'float-end slim'}
                icon={'plus'}
                onClick={createPartialInvoiceAction.onClick}
              >
                Add Partial
              </RooButton>
            )}
          </Stack>
        </Stack>
      </Card.Header>
      <Card.Body>
        {showDraftWarning && (
          <Alert className={'text-center'} variant={'warning'}>
            You have Draft invoices pending. <strong>Send</strong> them to the client when you're ready.
          </Alert>
        )}
        {<InvoiceGrid onCreate={createInvoiceAction.onClick} canCreate={canCreateFull} />}
      </Card.Body>
    </Card>
  );
};

export const InvoiceGrid = ({ onCreate, canCreate }: { onCreate: () => void; canCreate: boolean }) => {
  const { userRole, parentIssue, visibleInvoices } = useCurrentIssue();
  const splitInvoiceModal = useDisclosure(false);

  if (visibleInvoices.length === 0) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Box sx={{ p: 4 }}>
          <p>There are no Invoices</p>
          {canCreate && (
            <div className={'text-center'}>
              <RooButton color={'primary'} icon={'plus'} onClick={onCreate}>
                Create one
              </RooButton>
            </div>
          )}
        </Box>
      </Box>
    );
  }

  const sorted = orderBy(visibleInvoices, (x) => x.friendlyId, 'desc');

  return (
    <Stack gap={3}>
      {sorted.map((x) => (
        <MuiCard variant={'outlined'} sx={{ width: '100%' }} key={x.id}>
          <CardContent sx={{ width: '100%' }}>
            <Stack sx={{ width: '100%' }} direction={'row'} gap={2} justifyContent={'space-between'} flexWrap={'wrap'}>
              <Stack sx={{ width: '100%' }} direction={'row'} gap={2} flex={1} alignItems={'center'} flexWrap={'wrap'}>
                <Stack>
                  {x.pdfFile == null && x.friendlyId}
                  {x.pdfFile != null && (
                    <a href={apiProvider.fileUrls.download(x.pdfFile.id)} rel="noreferrer">
                      <RooIcon icon={'download'} /> {x.friendlyId}
                    </a>
                  )}
                  <div className={'mb-0'}>
                    <Badge bg={rooEnum.invoiceStatus.color(x.status)}>{rooEnum.invoiceStatus.display(x.status)}</Badge>{' '}
                  </div>
                </Stack>
                <Stack sx={{ minWidth: 85 }}>
                  <Typography variant={'caption'}>Date</Typography>
                  <Typography>{rooFmt.instantDateMaybe(x.referenceDate, '-')}</Typography>
                </Stack>

                {parentIssue == null && (
                  <Stack sx={{ minWidth: 80 }}>
                    <Typography variant={'caption'}>Subtotal</Typography>
                    <Typography>{rooFmt.moneyMaybe(x.totals.subtotal, 'N/A')}</Typography>
                  </Stack>
                )}
                {parentIssue == null && (
                  <Stack sx={{ minWidth: 80 }}>
                    <Typography variant={'caption'}>Tax</Typography>{' '}
                    <Typography>{rooFmt.moneyMaybe(x.totals.tax, 'N/A')}</Typography>
                  </Stack>
                )}
                <Stack sx={{ minWidth: 80 }}>
                  <Typography variant={'caption'}>Grand Total</Typography>
                  <Typography>{rooFmt.moneyMaybe(x.totals.finalTotal, 'N/A')}</Typography>
                </Stack>

                <Stack sx={{ minWidth: 80 }}>
                  <Typography variant={'caption'}>Paid</Typography>
                  <Typography>{rooFmt.moneyMaybe(x.totals.finalTotal - x.outstanding, 'N/A')}</Typography>
                </Stack>
                <Stack sx={{ minWidth: 80 }}>
                  <Typography variant={'caption'}>Outstanding</Typography>
                  <Typography>{rooFmt.moneyMaybe(x.outstanding, 'N/A')}</Typography>
                </Stack>
              </Stack>

              <Box>
                <ActionsContainer>
                  <InvoiceMenu invoice={x} />
                  <InvoiceInfoButton invoice={x} />
                </ActionsContainer>
              </Box>
            </Stack>
            {(userRole === IssueRole.MainPropertyManager || userRole === IssueRole.AltPropertyManager) &&
              x.split != null && (
                <Stack p={2} gap={1}>
                  <Stack direction={'row'} alignItems={'center'} gap={1}>
                    <Typography fontWeight={600} fontSize={'large'}>
                      Split
                    </Typography>
                    <IconButton onClick={splitInvoiceModal.open} size={'small'} color={'primary'}>
                      <MuiIcon.Edit fontSize={'small'} />
                    </IconButton>
                  </Stack>
                  <Stack direction={'row'} gap={2}>
                    {x.split.ownerProjection != null && <SplitInvoiceCard projection={x.split.ownerProjection} />}
                    {x.split.tenantProjection != null && <SplitInvoiceCard projection={x.split.tenantProjection} />}
                  </Stack>
                  <SplitInvoiceModal invoiceId={x.id} modal={splitInvoiceModal} />
                </Stack>
              )}
          </CardContent>
        </MuiCard>
      ))}
    </Stack>
  );
};

const SplitInvoiceCard = ({ projection }: { projection: IInvoiceSplitProjection }) => {
  return (
    <MuiCard variant={'outlined'} sx={{ maxWidth: 300, flex: 1 }}>
      <CardContent>
        <Stack direction={'column'} gap={2}>
          <Stack gap={1}>
            <Typography fontSize={'large'} fontWeight={600}>
              {projection.friendlyId}
            </Typography>
            <Stack direction={'row'} gap={2}>
              <Stack>
                <Typography variant={'caption'}>Party</Typography>
                <Typography>{projection.party === SplitDocumentParty.Tenant ? 'Tenant' : 'Owner'}</Typography>
              </Stack>
              <Stack>
                <Typography variant={'caption'}>Amount</Typography>
                <Typography>{rooFmt.money(projection.amount)}</Typography>
              </Stack>
            </Stack>
          </Stack>

          <Stack gap={1}>
            <Typography variant={'caption'}>PDF</Typography>
            <Stack direction={'row'} gap={1}>
              <Button
                size={'small'}
                href={`/api/issues/invoices/render-split-pdf?splitId=${projection.splitId}&party=${projection.party}`}
                target={'_blank'}
                startIcon={<MuiIcon.PictureAsPdf />}
              >
                View
              </Button>
              <Button
                size={'small'}
                href={`/api/issues/invoices/download-split-pdf?splitId=${projection.splitId}&party=${projection.party}`}
                target={'_blank'}
                startIcon={<MuiIcon.Download />}
              >
                Download
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </CardContent>
    </MuiCard>
  );
};
