import { Stack } from '@mui/material';
import React from 'react';
import { FormSettingsWidget } from './FormSettings';
import {
  CapabilityType,
  CompanyFeatureFlag,
  ManagementCompany,
  NoteEntityType,
  NoteSource
} from '../../../shared/api/clients';
import { ContactInfoWidget } from '../../../components/Profiles/ContactInfoWidget';
import {
  useContractorCanManageCompany,
  useHasCapability,
  useHasFeatureFlag,
  useIsAdmin,
  useIsGeneralContractor
} from '../../../shared/store';
import { AddressWidget } from '../../../components/Profiles/AddressWidget';
import { NotesWidget } from '../../../components/Profiles/NotesWidget';
import { TenantCarbonConfigurationWidget } from './TenantCopy';
import { UpdateCompanyAddressModal } from './UpdateCompanyAddressModal';
import { UpdateCompanyContactModal } from './UpdateCompanyContactModal';
import { MiscSettingsWidget } from './MiscSettings';
import Grid2 from '@mui/material/Unstable_Grid2';
import { useDisclosure } from '@roo/lib';

export const SectionBasics = ({ company }: { company: ManagementCompany }) => {
  const gcCanManage = useContractorCanManageCompany(company.id);
  const isAdmin = useIsAdmin();
  const isManager = useHasCapability(CapabilityType.PropertyManager, company.id);

  const addressDisclosure = useDisclosure(false);
  const contactDisclosure = useDisclosure(false);

  const canEdit = gcCanManage || isAdmin || isManager;
  const hasSoftwareClients = useHasFeatureFlag(CompanyFeatureFlag.SoftwareClients);

  return (
    <Stack alignItems={'center'} justifyContent={'center'} p={3} py={7}>
      <Stack alignItems={'center'} justifyContent={'center'} spacing={4}>
        <h3 style={{ margin: 0 }}>{company.name}</h3>
        <Grid2 container columnSpacing={20}>
          <Grid2>
            <ContactInfoWidget
              nameLabel={'Company Name'}
              name={company.name}
              canEdit={canEdit}
              contactInfo={company.contactInfo}
              onEdit={() => contactDisclosure.open()}
            />
          </Grid2>
          <Grid2>
            <AddressWidget canEdit={canEdit} address={company.address} onEdit={() => addressDisclosure.open()} />
          </Grid2>
        </Grid2>
        <Grid2 container direction={'row'} spacing={4} flex={1} width={'100%'}>
          {canEdit && (
            <Grid2>
              <TenantCarbonConfigurationWidget canEdit={canEdit} company={company} />
            </Grid2>
          )}
          {gcCanManage && (
            <Grid2>
              <FormSettingsWidget companyId={company.id} />
            </Grid2>
          )}
          {(isAdmin || gcCanManage) && hasSoftwareClients && (
            <Grid2>
              <MiscSettingsWidget company={company} />
            </Grid2>
          )}
        </Grid2>
        {gcCanManage && (
          <Grid2 xs={12}>
            <NotesWidget
              noteSource={NoteSource.CompanyProfile}
              entityType={NoteEntityType.ManagementCompany}
              entityId={company.id}
            />
          </Grid2>
        )}
      </Stack>
      <UpdateCompanyAddressModal disclosure={addressDisclosure} company={company} />
      <UpdateCompanyContactModal disclosure={contactDisclosure} company={company} />
    </Stack>
  );
};
