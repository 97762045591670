import { Column } from 'primereact/column';
import { DataTable as PrimeDataTable } from 'primereact/datatable';
import React from 'react';
import { Alert, Badge, Card } from 'react-bootstrap';
import {
  ChangeRequestAction,
  ChangeRequestStatus,
  ChangeRequestUserView,
  IssueRole,
  ReviewChangeRequestAction
} from 'shared/api/clients';
import { RooButton } from 'components';
import { rooFmt } from 'shared/utils';
import { useCurrentIssue } from 'pages/Workorders/shared/CurrentIssueContext';
import { ActionsContainer } from 'pages/Workorders/ViewIssue/Documents/ActionsContainer';
import { ActionsMenu, ActionsMenuItem } from 'components/ActionsMenu';
import { DeleteChangeRequestModal } from 'pages/Workorders/ViewIssue/Documents/ChangeRequests/DeleteChangeRequestModal';
import { ReviewChangeRequestModal } from 'pages/Workorders/ViewIssue/Documents/ChangeRequests/ReviewChangeRequestModal';
import { MuiIcon, RooIcon } from '../../../../../shared/icons';
import { EditChangeRequestModal } from './EditChangeRequestModal';
import { ChangeRequestInfoButton } from './ChangeRequestInfoButton';
import { rooEnum } from '../../../../../shared/store';
import { PublishChangeRequestModal } from './PublishChangeRequestModal';
import { createChangeRequestAction } from './CreateChangeRequestModal';
import { useDisclosure } from '@roo/lib';

export const ChangeRequestList = () => {
  const currentIssue = useCurrentIssue();
  const { userRole, visibleChangeRequests } = currentIssue;
  const showDraftWarning =
    userRole === IssueRole.Vendor &&
    visibleChangeRequests.find((x) => x.changeRequest.status === ChangeRequestStatus.Draft) != null;
  const canCreate = createChangeRequestAction.isVisible(currentIssue);
  return (
    <Card>
      <Card.Header>
        Change Order Requests
        {canCreate && (
          <RooButton
            color={'primary'}
            size={'sm'}
            className={'float-end slim'}
            icon={'plus'}
            onClick={createChangeRequestAction.onClick}
          >
            Add
          </RooButton>
        )}
      </Card.Header>
      <Card.Body>
        {showDraftWarning && (
          <Alert className={'text-center'} variant={'warning'}>
            You have Draft change requests pending. <strong>Send</strong> them to the client when you're ready.
          </Alert>
        )}
        <PrimeDataTable value={visibleChangeRequests}>
          <Column
            sortable
            field={'changeRequest.friendlyId'}
            header={'Number'}
            bodyClassName={'text-col'}
            body={(row: ChangeRequestUserView) => (
              <>
                {row.changeRequest.pdfUrls == null && row.changeRequest.friendlyId}
                {row.changeRequest.pdfUrls != null && (
                  <a href={row.changeRequest.pdfUrls.downloadUrl} rel="noreferrer">
                    <RooIcon icon={'download'} /> {row.changeRequest.friendlyId}
                  </a>
                )}
              </>
            )}
          />
          <Column
            field={'changeRequest.status'}
            header={'Status'}
            body={(row: ChangeRequestUserView) => (
              <Badge bg={rooEnum.changeRequest.color(row.changeRequest.status)}>
                {rooEnum.changeRequest.display(row.changeRequest.status)}
              </Badge>
            )}
          />
          <Column
            field={'changeRequest.cost'}
            header={'Cost'}
            body={(row: ChangeRequestUserView) => rooFmt.money(row.changeRequest.totals.grandTotal)}
          />
          <Column
            field={'changeRequest.lastModified'}
            header={'Modified'}
            body={(row: ChangeRequestUserView) => rooFmt.date(row.changeRequest.lastModified)}
          />
          <Column
            body={(row: ChangeRequestUserView) => (
              <ActionsContainer>
                <ChangeRequestMenu changeRequestView={row} />
                <ChangeRequestInfoButton changeRequest={row.changeRequest} />
              </ActionsContainer>
            )}
          />
        </PrimeDataTable>
      </Card.Body>
    </Card>
  );
};

const ChangeRequestMenu = ({ changeRequestView }: { changeRequestView: ChangeRequestUserView }) => {
  const editModal = useDisclosure(false);
  const deleteModal = useDisclosure(false);
  const acceptModal = useDisclosure(false);
  const rejectModal = useDisclosure(false);
  const elevateModal = useDisclosure(false);
  const publishModal = useDisclosure(false);
  const forceAcceptModal = useDisclosure(false);

  const { actions, changeRequest } = changeRequestView;
  if (actions.length === 0) {
    return null;
  }

  return (
    <>
      <ActionsMenu moreButton={(onClick) => <RooButton icon={'ellipsis-h'} onClick={onClick} />}>
        <ActionsMenuItem
          show={actions.includes(ChangeRequestAction.Publish)}
          onClick={publishModal.open}
          Icon={MuiIcon.Publish}
          color={'primary'}
        >
          Send to Client
        </ActionsMenuItem>
        <ActionsMenuItem
          show={actions.includes(ChangeRequestAction.Edit)}
          onClick={editModal.open}
          Icon={MuiIcon.Edit}
          color={'primary'}
        >
          Edit
        </ActionsMenuItem>
        <ActionsMenuItem
          show={actions.includes(ChangeRequestAction.Delete)}
          onClick={deleteModal.open}
          Icon={MuiIcon.Delete}
          color={'error'}
        >
          Delete
        </ActionsMenuItem>
        <ActionsMenuItem
          show={actions.includes(ChangeRequestAction.Accept)}
          onClick={acceptModal.open}
          Icon={MuiIcon.CheckCircle}
          color={'success'}
        >
          Accept
        </ActionsMenuItem>
        <ActionsMenuItem
          show={actions.includes(ChangeRequestAction.ForceAccept)}
          onClick={forceAcceptModal.open}
          Icon={MuiIcon.Hardware}
          color={'warning'}
        >
          Force Accept
        </ActionsMenuItem>
        <ActionsMenuItem
          show={actions.includes(ChangeRequestAction.Reject)}
          onClick={rejectModal.open}
          Icon={MuiIcon.Cancel}
          color={'error'}
        >
          Reject
        </ActionsMenuItem>
        <ActionsMenuItem
          show={actions.includes(ChangeRequestAction.Elevate)}
          onClick={elevateModal.open}
          Icon={MuiIcon.Elevator}
          color={'warning'}
        >
          Send to Owner
        </ActionsMenuItem>
      </ActionsMenu>
      <DeleteChangeRequestModal
        changeRequest={changeRequest}
        visible={deleteModal.isOpen}
        onClose={deleteModal.close}
      />
      <EditChangeRequestModal changeRequest={changeRequest} modal={editModal} />
      <ReviewChangeRequestModal
        changeRequest={changeRequest}
        actionType={ReviewChangeRequestAction.Accept}
        visible={acceptModal.isOpen}
        onClose={acceptModal.close}
      />
      <ReviewChangeRequestModal
        changeRequest={changeRequest}
        actionType={ReviewChangeRequestAction.ForceAccept}
        visible={forceAcceptModal.isOpen}
        onClose={forceAcceptModal.close}
      />
      <ReviewChangeRequestModal
        changeRequest={changeRequest}
        actionType={ReviewChangeRequestAction.Reject}
        visible={rejectModal.isOpen}
        onClose={rejectModal.close}
      />
      <ReviewChangeRequestModal
        changeRequest={changeRequest}
        actionType={ReviewChangeRequestAction.Elevate}
        visible={elevateModal.isOpen}
        onClose={elevateModal.close}
      />
      <PublishChangeRequestModal changeRequest={changeRequest} modal={publishModal} />
    </>
  );
};
