import { useMutation, useQuery } from '@tanstack/react-query';
import { apiProvider } from '../../shared/api/apiProvider';
import { rooEnum, useAppStore } from '../../shared/store';
import { Helmet } from 'react-helmet';
import { ContentWrapper, IconLink, LoaderSmall, MuiRouterLink, NavSlim, RooButton } from '../../components';
import { PaymentsNav } from './PaymentsNav';
import {
  ExportPayeesCsvPayload,
  ExportPayeesPdfPayload,
  ListForPayeePagePayload,
  NoteEntityType,
  NoteSource,
  PayeeInfo,
  PayeePayment
} from '../../shared/api/clients';
import React, { useRef, useState } from 'react';
import { useContainerHeight } from '../../shared/utils/useContainerHeight';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { Column } from 'primereact/column';
import { rooDate, rooFmt } from '../../shared/utils';
import { DataTable as PrimeDataTable } from 'primereact/datatable';
import { Box, Paper, Stack, Typography } from '@mui/material';
import { sumBy } from 'lodash';
import { Routes } from '../../shared/routing';
import { NotesButton } from '../../components/Notes/NotesButton';
import { DateRangeChips, makeDefaultChipInterval, SelectedDateRange } from '../../components/DateRangeChips';
import { downloadFiles } from '../../components/Files/utils';
import { Badge } from 'react-bootstrap';

export const Payees = () => {
  const [dateSelection, setDateSelection] = useState<SelectedDateRange>(makeDefaultChipInterval('mtd'));
  const { isLoading, data } = useQuery(['reporting', 'payees', dateSelection.startDate, dateSelection.endDate], () =>
    apiProvider.issues.reports.listForPayees(
      new ListForPayeePagePayload({
        vendorId: null,
        startDate: rooDate.makeInstant(dateSelection.startDate),
        endDate: rooDate.makeInstant(dateSelection.endDate)
      })
    )
  );

  const hideReports = useAppStore((x) => x.hideReports);

  return (
    <>
      <Helmet>
        <title>Accounts Receivable - Walkthroo</title>
      </Helmet>
      <NavSlim breadcrumbs={[{ text: 'Payees' }]} />
      <ContentWrapper className="container-property-list no-transparent">
        <PaymentsNav value={2} />
        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} p={4}>
          <Box></Box>
          <DateRangeChips value={dateSelection} setValue={setDateSelection} />
          <Stack direction={'row'} gap={1}>
            <DownloadPdfButton payee={null} dateRange={dateSelection} />
            <DownloadCsvButton dateRange={dateSelection} />
          </Stack>
        </Stack>
        {isLoading && (
          <div className={'text-center p-4'}>
            <LoaderSmall />
          </div>
        )}
        {!isLoading && !hideReports && <PayeesTable data={data} dateRange={dateSelection} />}
      </ContentWrapper>
    </>
  );
};

const rowExpansionTemplate = (row: PayeeInfo) => {
  if (row.payments.length === 0) {
    return (
      <Stack sx={{ width: '100%' }} justifyContent={'center'} alignItems={'center'}>
        <Typography color={'grey.500'}>No matching payments</Typography>
      </Stack>
    );
  }

  return (
    <Box sx={{ width: { xs: '100%' }, px: { md: 4 }, display: 'block' }}>
      <Paper elevation={1} sx={{ p: 2, pb: { xs: 2, md: 1 } }}>
        <PrimeDataTable value={row.payments} sortOrder={-1} sortField={'date'}>
          <Column
            header="Issue #"
            sortable
            field={'issue.friendlyId'}
            body={(row: PayeePayment) => (
              <Stack direction={'row'}>
                <Stack>
                  <IconLink
                    style={{ whiteSpace: 'nowrap', alignSelf: 'center' }}
                    to={{
                      pathname: Routes.IssueView,
                      params: { issueId: row.issue.id, workorderId: row.issue.workorderId }
                    }}
                    text={row.issue.friendlyId}
                  />
                  <Badge style={{ marginRight: '5px' }} bg={rooEnum.issueStatus.color(row.issue.status)}>
                    {rooEnum.issueStatus.display(row.issue.status)}
                  </Badge>
                </Stack>
                <NotesButton
                  entityId={row.issue.id}
                  entityType={NoteEntityType.Issue}
                  title={'Issue Notes'}
                  noteSource={NoteSource.Reporting}
                />
              </Stack>
            )}
          />
          <Column header={'Issue Title'} field={'issue.title'} />

          <Column sortable header={'Property'} field={'issue.property.displayAddress'} />
          <Column
            field={'date'}
            dataType={'date'}
            header={'Date'}
            sortable
            body={(row: PayeePayment) => rooFmt.date(row.date)}
          />
          <Column field={'notes'} dataType={'text'} header={'Notes'} />
          <Column
            field={'method'}
            sortable
            header={'Method'}
            body={(row: PayeePayment) => rooEnum.paymentMethod.display(row.method)}
          />
          <Column
            field={'amount'}
            header={'Amount'}
            dataType={'numeric'}
            sortable
            className={'column-money'}
            bodyClassName={'column-money'}
            body={(row: PayeePayment) => rooFmt.money(row.amount)}
          />
        </PrimeDataTable>
      </Paper>
    </Box>
  );
};

export const PayeesTable = ({ data, dateRange }: { data: PayeeInfo[]; dateRange: SelectedDateRange }) => {
  const containerRef = useRef<HTMLDivElement>();
  const height = useContainerHeight({ itemRef: containerRef, defaultHeight: 500 });
  const [filteredData, setFilteredData] = useState(data);
  const [expandedRows, setExpandedRows] = useState<any>({});

  const [filters] = useState({
    'user.firstName': { operator: 'AND', constraints: [{ value: '', matchMode: 'contains' }] },
    'user.lastName': { operator: 'AND', constraints: [{ value: '', matchMode: 'contains' }] },
    'vendor.address.city': { operator: 'AND', constraints: [{ value: '', matchMode: 'contains' }] },
    'vendor.address.state': { operator: 'AND', constraints: [{ value: '', matchMode: 'contains' }] },
    'vendor.companyName': { operator: 'AND', constraints: [{ value: '', matchMode: 'contains' }] },
    'vendor.contactInfo.phoneNumber': { operator: 'AND', constraints: [{ value: '', matchMode: 'contains' }] },
    'vendor.contactInfo.email': { operator: 'AND', constraints: [{ value: '', matchMode: 'contains' }] }
  });

  const footerGroup = (
    <ColumnGroup>
      <Row>
        <Column footer="Totals:" footerStyle={{ fontWeight: 'bold' }} />
        <Column
          className={'column-money'}
          footer={() => {
            const total = sumBy(filteredData, (x) => x.totalFees ?? 0);
            return rooFmt.money(total);
          }}
        />
        <Column
          className={'column-money'}
          footer={() => {
            const total = sumBy(filteredData, (x) => x.totalPayments ?? 0);
            return rooFmt.money(total);
          }}
        />
      </Row>
    </ColumnGroup>
  );
  return (
    <div ref={containerRef}>
      <PrimeDataTable
        onValueChange={(data: any[]) => setFilteredData(data)}
        filters={filters as any}
        scrollHeight={`${height}px`}
        scrollable
        value={data}
        rowExpansionTemplate={rowExpansionTemplate}
        onRowToggle={(e) => {
          setExpandedRows(e.data);
        }}
        expandedRows={expandedRows}
        footerColumnGroup={footerGroup}
        sortOrder={-1}
        sortField={'totalPayments'}
        dataKey="vendor.id"
      >
        <Column style={{ maxWidth: '80px' }} expander />
        <Column
          field={'user.firstName'}
          sortable
          filter
          showFilterMatchModes={false}
          showFilterOperator={false}
          showAddButton={false}
          header={'First Name'}
          style={{ minWidth: '150px' }}
        />
        <Column
          field={'user.lastName'}
          sortable
          filter
          showFilterMatchModes={false}
          showFilterOperator={false}
          showAddButton={false}
          header={'Last Name'}
          style={{ minWidth: '150px' }}
        />
        <Column
          field={'vendor.address.city'}
          sortable
          filter
          showFilterMatchModes={false}
          showFilterOperator={false}
          showAddButton={false}
          header={'City'}
          style={{ minWidth: '150px' }}
        />
        <Column
          field={'vendor.address.state'}
          sortable
          filter
          showFilterMatchModes={false}
          showFilterOperator={false}
          showAddButton={false}
          header={'State'}
          style={{ minWidth: '120px' }}
        />
        <Column
          field={'vendor.companyName'}
          sortable
          filter
          showFilterMatchModes={false}
          showFilterOperator={false}
          showAddButton={false}
          header={'Company Name'}
          style={{ minWidth: '500px' }}
          body={(row: PayeeInfo) => (
            <Stack gap={'6px'}>
              <MuiRouterLink
                target={'_blank'}
                to={{ pathname: Routes.VendorProfile, params: { vendorId: row.vendor.id } }}
              >
                {row.vendor.companyName}
              </MuiRouterLink>
              <DownloadPdfButton payee={row} dateRange={dateRange} />
            </Stack>
          )}
        />
        <Column
          field={'vendor.contactInfo.phoneNumber'}
          sortable
          filter
          showFilterMatchModes={false}
          showFilterOperator={false}
          showAddButton={false}
          header={'Phone'}
          style={{ minWidth: '150px' }}
        />
        <Column
          field={'vendor.contactInfo.email'}
          sortable
          filter
          showFilterMatchModes={false}
          showFilterOperator={false}
          showAddButton={false}
          style={{ minWidth: '300px' }}
          body={(val: PayeeInfo) => (
            <Typography style={{ width: '280px', wordWrap: 'break-word' }}>{val.vendor.contactInfo.email}</Typography>
          )}
          header={'E-mail'}
        />
        <Column
          field={'jobCount'}
          sortable
          header={'Jobs'}
          dataType={'numeric'}
          className={'column-money'}
          bodyClassName={'column-money'}
          style={{ minWidth: '100px' }}
        />
        <Column
          field={'totalPayments'}
          sortable
          header={'Total Payments'}
          dataType={'numeric'}
          className={'column-money'}
          bodyClassName={'column-money'}
          style={{ minWidth: '200px' }}
          body={(row: PayeeInfo) => rooFmt.money(row.totalPayments)}
        />
      </PrimeDataTable>
    </div>
  );
};

const DownloadPdfButton = ({ payee, dateRange }: { payee: PayeeInfo; dateRange: SelectedDateRange }) => {
  const { isLoading, mutate } = useMutation({
    mutationFn: () =>
      apiProvider.issues.reports.exportPayeesPdf(
        new ExportPayeesPdfPayload({
          vendorId: payee?.vendor?.id,
          startDate: rooDate.makeInstant(dateRange?.startDate),
          endDate: rooDate.makeInstant(dateRange?.endDate)
        })
      ),
    onSuccess: (data) => {
      downloadFiles([data]);
    }
  });

  return (
    <RooButton
      style={{ width: '150px' }}
      size={'sm'}
      loading={isLoading}
      disabled={isLoading}
      onClick={() => mutate()}
      icon={'download'}
    >
      Download PDF
    </RooButton>
  );
};

const DownloadCsvButton = ({ dateRange }: { dateRange: SelectedDateRange }) => {
  const { isLoading, mutate } = useMutation({
    mutationFn: () =>
      apiProvider.issues.reports.exportPayeesCsv(
        new ExportPayeesCsvPayload({
          startDate: rooDate.makeInstant(dateRange?.startDate),
          endDate: rooDate.makeInstant(dateRange?.endDate)
        })
      ),
    onSuccess: (data) => {
      downloadFiles([data]);
    }
  });

  return (
    <RooButton
      style={{ width: '150px' }}
      size={'sm'}
      variant={'success'}
      loading={isLoading}
      disabled={isLoading}
      onClick={() => mutate()}
      icon={'download'}
    >
      Download CSV
    </RooButton>
  );
};
