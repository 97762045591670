const logPerf = false;

export const logTime = <T>(name: string, timed: () => T): T => {
  if (!logPerf) {
    return timed();
  }

  const start = performance.now();
  const res = timed();
  const end = performance.now();

  console.log(`Timed ${name} in ${end - start}ms`);
  return res;
};
