import { ManagementCompany } from '../../shared/api/clients';
import { Stack } from '@mui/material';
import { IssuesServerGrid, useIssuesGrid } from '../../components/Issues/IssuesServerGrid';
import * as React from 'react';
import { useCurrentVendor } from '../../shared/store';

export const SectionIssues = ({ company }: { company: ManagementCompany }) => {
  const grid = useIssuesGrid({
    forceExpanded: true,
    externalFilters: {
      companyIds: [company.id],
      onlyTopLevelIssues: true
    }
  });
  return (
    <>
      <Stack alignItems={'center'} justifyContent={'center'} p={3} py={7}>
        <IssuesServerGrid
          fullWidth={true}
          state={grid}
          forceLinksNewWindow={true}
          showStatAmount={false}
          showCompany={false}
          showSubtotal={false}
        />
      </Stack>
    </>
  );
};
