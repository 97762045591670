export const LoaderBalls = () => {
  return (
    <>
      <div className="spinner-grow text-secondary" role="status" />
      <div className="spinner-grow text-success" role="status" />
      <div className="spinner-grow text-danger" role="status" />
      <div className="spinner-grow text-warning" role="status" />
      <div className="spinner-grow text-info" role="status" />
      <div className="spinner-grow text-light" role="status" />
      <div className="spinner-grow text-dark" role="status" />
    </>
  );
};
