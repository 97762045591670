import { IconName } from '@fortawesome/free-regular-svg-icons';
import { RooIcon } from 'shared/icons';
import { Button, ButtonProps } from 'react-bootstrap';

export type RooButtonProps = ButtonProps & {
  loading?: boolean;
  icon?: IconName;
};
export const RooButton = ({ loading, children, icon, ...rest }: RooButtonProps) => {
  const showIcon = icon || loading;
  return (
    <Button {...rest}>
      {showIcon && <RooIcon icon={['fas', !loading ? icon : 'circle-notch']} spin={loading} />}
      {showIcon && ' '}
      {children}
    </Button>
  );
};
