import { Stack } from '@mui/material';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import {
  BidStatus,
  DocumentTotals,
  EstimateStatus,
  IDocumentLineItem,
  IFormDefaults,
  IIssue,
  IIssueUserView,
  IInstant,
  LineSource
} from 'shared/api/clients';
import { RooButton, RooDialog } from 'components';
import { rooFmt } from 'shared/utils';
import { useCurrentIssue } from 'pages/Workorders/shared/CurrentIssueContext';
import { EstimateRequestViewChange } from 'pages/Workorders/ViewIssue/Documents/Estimates/types';
import { LineTable } from 'pages/Workorders/ViewIssue/Documents/LineTable';
import { DocumentViewTotals } from '../DocumentViewTotals';
import { useToggle } from '@roo/lib';

type LineGroup = {
  key: string;
  lineCount: number;
  subtotal?: number;
  tax?: number;
  grandTotal?: number;
  sourceType: LineSource;
  taxPercent: number;
  discountPercent: number;
  description: string;
  startDate?: IInstant;
  endDate?: IInstant;
  lines: IDocumentLineItem[];
};

const getLineGroups = (
  issue: IIssue,
  taskList: string[],
  subissues: IIssueUserView[],
  formDefaults: IFormDefaults
): LineGroup[] => {
  const groups: LineGroup[] = [];
  if (taskList.length > 0) {
    groups.push({
      key: 'tasklist',
      sourceType: LineSource.TaskList,
      lineCount: taskList.length,
      subtotal: null,
      tax: null,
      grandTotal: null,
      discountPercent: formDefaults.discountPercent,
      taxPercent: formDefaults.taxPercent,
      description: 'Items from issue description',
      lines: taskList.map((x, i) => ({
        id: x,
        order: i,
        description: x,
        notes: null,
        initialValue: null,
        sourceEntityId: null,
        sourceEntityType: LineSource.TaskList,
        value: null,
        resaleValue: null,
        beforeFiles: [],
        afterFiles: [],
        canEdit: issue.parentId == null
      }))
    });
  } else {
    groups.push({
      key: 'main',
      sourceType: LineSource.MainTask,
      lineCount: 1,
      subtotal: null,
      tax: formDefaults.taxPercent,
      grandTotal: null,
      taxPercent: null,
      discountPercent: formDefaults.discountPercent,
      description: 'Issue title',
      lines: [
        {
          id: issue.title,
          order: 0,
          description: issue.title,
          sourceEntityType: LineSource.MainTask,
          notes: null,
          initialValue: null,
          resaleValue: null,
          sourceEntityId: null,
          value: null,
          beforeFiles: [],
          afterFiles: []
        }
      ]
    });
  }

  for (const subissue of subissues) {
    const bids =
      subissue.visibleBids
        .map((x) => x.bid)
        .filter(
          (x) =>
            x.status === BidStatus.PendingReviewPM ||
            x.status === BidStatus.Accepted ||
            x.status === BidStatus.PendingReviewOwner
        ) ?? [];

    for (const bid of bids) {
      groups.push({
        key: `bid-${bid.id}`,
        sourceType: LineSource.SubissueBidLine,
        lineCount: bid.lines.length,
        subtotal: bid.totals.subtotal,
        tax: bid.totals.tax,
        // this could theoretically pass discounts from a different vendor on
        discountPercent: formDefaults.discountPercent,
        grandTotal: bid.totals.grandTotal,
        taxPercent: formDefaults.taxPercent,
        description: `Items from bid by ${bid.vendor.companyName} in ${subissue.issue.friendlyId}`,
        startDate: bid.startDate,
        endDate: bid.endDate,
        lines: bid.lines.map((x) => ({ ...x, initialValue: x.value, sourceEntityType: LineSource.SubissueBidLine }))
      });
    }

    const estimates =
      subissue.estimates?.filter(
        (x) => x.status === EstimateStatus.Accepted || x.status === EstimateStatus.PendingReview
      ) ?? [];

    for (const estimate of estimates) {
      groups.push({
        key: `estimate-${estimate.id}`,
        sourceType: LineSource.SubissueEstimateLine,
        lineCount: estimate.lines.length,
        subtotal: estimate.totals.subtotal,
        tax: estimate.totals.tax,
        grandTotal: estimate.totals.grandTotal,
        taxPercent: estimate.totals.taxPercent ?? formDefaults.taxPercent,
        discountPercent: estimate.totals.discountPercent ?? formDefaults.discountPercent,
        startDate: estimate.startDate,
        endDate: estimate.endDate,
        description: `Items from estimate by ${estimate.vendor.companyName} in ${subissue.issue.friendlyId}`,
        lines: estimate.lines.map((x) => ({
          ...x,
          initialValue: x.value,
          sourceEntityType: LineSource.SubissueEstimateLine
        }))
      });
    }
  }

  return groups;
};

export const EstimateConfirmation = ({ requestViewChange }: { requestViewChange: EstimateRequestViewChange }) => {
  const { issue, taskList, subissues, formDefaults } = useCurrentIssue();
  const lineGroups = getLineGroups(issue, taskList, subissues, formDefaults);
  const [selected, setSelected] = useState<LineGroup[]>(null);
  const confirm = () => {
    const allLines = selected.reduce<IDocumentLineItem[]>((prev, curr) => [...prev, ...curr.lines], []);
    const mainTax = selected.map((x) => x.taxPercent).find((x) => x != null);
    const mainDiscount = selected.map((x) => x.discountPercent).find((x) => x != null);
    const mainDatesSource = selected.find((x) => x.startDate != null && x.endDate != null);
    const mainStart = mainDatesSource?.startDate;
    const mainEnd = mainDatesSource?.endDate;
    requestViewChange({
      view: 'create',
      lines: {
        startDate: mainStart,
        endDate: mainEnd,
        taxPercent: mainTax,
        resaleTaxPercent: null,
        discountPercent: mainDiscount ?? formDefaults.discountPercent,
        lines: allLines
      },
      force: true
    });
  };

  return (
    <>
      <Stack direction={'column'} sx={{ mb: 4 }} alignItems={'center'}>
        <h5>Select the lines you want to include</h5>
        <p>You will be able to edit them before submitting</p>
      </Stack>
      <DataTable
        dataKey={'key'}
        value={lineGroups}
        selection={selected}
        onSelectionChange={(e) => {
          setSelected(e.value);
        }}
        selectionMode={'multiple'}
      >
        <Column selectionMode="multiple" headerStyle={{ width: '3em' }} />
        <Column bodyClassName={'text-col'} field={'description'} header={'Description'} />
        <Column field={'lineCount'} header={'Lines'} dataType={'numeric'} />
        <Column
          field={'amount'}
          header={'Subtotal'}
          dataType={'numeric'}
          body={(e: LineGroup) => rooFmt.moneyMaybe(e.subtotal, 'N/A')}
        />
        <Column
          field={'tax'}
          header={'Tax'}
          dataType={'numeric'}
          body={(e: LineGroup) => rooFmt.moneyMaybe(e.tax, 'N/A')}
        />
        <Column
          field={'grandTotal'}
          header={'Grand Total'}
          body={(e: LineGroup) => rooFmt.moneyMaybe(e.grandTotal, 'N/A')}
        />
        <Column body={(e: LineGroup) => <PreviewLinesButton lineGroup={e} />} />
      </DataTable>
      <Stack alignItems={'center'} justifyContent={'center'} sx={{ mt: 4 }}>
        <RooButton onClick={() => confirm()} className={'me-4'}>
          Continue
        </RooButton>
      </Stack>
    </>
  );
};

const PreviewLinesButton = ({ lineGroup }: { lineGroup: LineGroup }) => {
  const [show, toggleShow] = useToggle();
  return (
    <>
      <RooButton
        icon={'eye'}
        variant={'primary'}
        onClick={(e) => {
          toggleShow();
          e.stopPropagation();
        }}
      />
      <RooDialog open={show} onClose={toggleShow} maxWidth={'md'} fullWidth>
        <RooDialog.Title onClose={toggleShow}>Lines Preview</RooDialog.Title>
        <RooDialog.Content>
          <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 1, md: 4 }}>
            <dl style={{ marginBottom: 0 }}>
              <dt>Source</dt>
              <dd>{lineGroup.description}</dd>
            </dl>
          </Stack>
          <Row>
            <Col xl={12}>
              <Row>
                <Col>
                  <LineTable lines={lineGroup.lines} />
                </Col>
              </Row>
            </Col>
          </Row>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'flex-end',
              flexGrow: 0,
              marginTop: '30px'
            }}
          >
            <DocumentViewTotals
              isAuthor={false}
              totals={
                new DocumentTotals({
                  tax: lineGroup.tax,
                  grandTotal: lineGroup.grandTotal,
                  finalTotal: lineGroup.grandTotal,
                  subtotal: lineGroup.subtotal,
                  discount: 0,
                  discountPercent: 0,
                  taxPercent: lineGroup.taxPercent,
                  previousDiscount: null,
                  partialAmount: null,
                  partialPercent: null,
                  partialTotal: null
                })
              }
            />
          </div>
        </RooDialog.Content>
        <RooDialog.Actions>
          <Stack direction={'row'} spacing={2}>
            <Button variant={'outline-primary'} onClick={toggleShow}>
              Close
            </Button>
          </Stack>
        </RooDialog.Actions>
      </RooDialog>
    </>
  );
};
