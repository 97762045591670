export const truncateText = (str: string, maxLength: number) => {
  if (str == null) {
    return null;
  }
  const termination = '...';
  const separator = ' ';
  if (str.length < maxLength + termination.length) {
    return str;
  }

  let truncated = str.substring(0, str.lastIndexOf(separator, maxLength)) + termination;
  if (truncated.length <= maxLength + termination.length) {
    return truncated;
  }

  truncated = str.substring(0, maxLength) + termination;
  return truncated;
};
