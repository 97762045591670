import { Stack } from '@mui/material';
import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { AttachmentFile, BidAction, IBidUserView } from 'shared/api/clients';
import { downloadFiles } from 'components/Files/utils';
import { RooButton, RooDialog } from 'components';
import { RooIcon } from 'shared/icons';
import { rooFmt } from 'shared/utils';
import { BidInfo } from 'pages/Workorders/ViewIssue/Documents/Bids/BidInfo';
import { ReviewButtonGroup } from 'pages/Workorders/ViewIssue/Documents/Bids/BidList/BidReview';
import { apiProvider } from '../../../../../../shared/api/apiProvider';
import { useToggle } from '@roo/lib';

export const BidInfoButton = ({ bidView }: { bidView: IBidUserView }) => {
  const [show, toggleShow] = useToggle();
  if (bidView.allowedActions.indexOf(BidAction.ViewDetails) < 0) {
    return null;
  }

  const bid = bidView.bid;

  const allFiles = bid.lines.reduce(
    (curr, next) => [...curr, ...(next.beforeFiles ?? [])],
    new Array<AttachmentFile>()
  );
  return (
    <>
      <RooButton icon={'eye'} variant={'primary'} onClick={toggleShow} />
      <RooDialog open={show} onClose={toggleShow} maxWidth={'md'} fullWidth>
        <RooDialog.Title onClose={toggleShow}>Bid Info</RooDialog.Title>
        <RooDialog.Content>
          <Row>
            <Col xl={12}>
              <BidInfo bid={bid} />
            </Col>
          </Row>
        </RooDialog.Content>
        <RooDialog.Actions>
          <Stack direction={'row'} spacing={1}>
            <ReviewButtonGroup onButtonClick={toggleShow} bidView={bidView} />
            {allFiles.length > 0 && (
              <Button onClick={() => downloadFiles(allFiles)}>
                Download {rooFmt.counted(allFiles.length, 'picture', 'pictures')}
              </Button>
            )}
            {bid.pdfFile != null && (
              <a href={apiProvider.fileUrls.download(bid.pdfFile.id)} className={'btn btn-primary'} rel="noreferrer">
                <RooIcon icon={'file-pdf'} /> Download PDF
              </a>
            )}
            <Button variant={'outline-primary'} onClick={toggleShow}>
              Close
            </Button>
          </Stack>
        </RooDialog.Actions>
      </RooDialog>
    </>
  );
};
