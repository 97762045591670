import { apiProvider } from 'shared/api/apiProvider';
import {
  BidComparison,
  CreateOrUpdateEstimatePayload,
  DocumentLineItem,
  FormSubmissionLineItem,
  Estimate,
  LineSource
} from 'shared/api/clients';
import { redirect, Routes } from 'shared/routing';
import { rooDate } from 'shared/utils';
import { Divider, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useRooForm } from 'components/form';
import { z } from 'zod';
import { useCallback, useState } from 'react';
import { TasksList } from './TaskList';
import { EstimateForm } from './EstimateForm';
import { BidColumn } from './BidColumn';
import { PreviousEstimate } from './PreviousEstimate';
import { PrevEstimateColumn } from './PrevEstimateColumn';

export type BidColumnSelection = {
  line: DocumentLineItem;
  taskIndex: number;
};

type EstimateSelection = {
  value: number;
  originalValue: number;
  lineId: string;
  task: string;
};

export const schema = z.object({
  startDate: z.date(),
  endDate: z.date(),
  taxtRate: z.number(),
  markup: z.number(),
  discount: z.number().optional()
});

export const CompareBidsContent = ({
  comparisonData,
  workorderId,
  issueId
}: {
  comparisonData: BidComparison;
  workorderId: string;
  issueId: string;
}) => {
  const [selected, setSelected] = useState<BidColumnSelection[]>([]);
  const [estimates, setEstimates] = useState<{ [key: string]: EstimateSelection }>({});
  const [isLoading, setIsLoading] = useState(false);
  const [prevEstimates, setPrevEstimates] = useState<Estimate[]>([]);
  const [skipped, setSkipped] = useState<string[]>([]);

  const onClick = (taskIndex: number, line: DocumentLineItem) => {
    const exists = selected.find((s) => s.line.id === line.id);

    setSelected((state) => {
      const newState = state.filter((s) => s.line.id !== line.id && s.taskIndex !== taskIndex);
      return exists ? newState : [...newState, { line, taskIndex }];
    });
  };

  const onChangeEstimate = useCallback(
    (index: number, lineId: string, value: number, originalValue: number, task: string) => {
      setEstimates((prev) => ({ ...prev, [index]: { value, lineId, originalValue, task } }));
    },
    [setEstimates]
  );

  const onSelectAll = (lines: DocumentLineItem[]) => {
    setSelected((state) => {
      const newState = state.filter((s) => !lines.find((l) => l.description === s.line.description));
      return [...newState, ...lines.map((line, index) => ({ line, taskIndex: index }))];
    });
  };

  const selectedLines = () => selected.map((selection) => selection.line.id);

  const { control, handleSubmit } = useRooForm(schema, {
    defaultValues: {
      markup: 15,
      taxtRate: comparisonData.taxPercent,
      discount: comparisonData.discountPercent,
      startDate: new Date(),
      endDate: new Date()
    }
  });

  const selectedBidLines = () => [...skipped, ...selected.map((s) => s.line.description)];
  const getMissingLines = () => {
    const lines = selectedBidLines();

    return comparisonData.lines.filter((l) => !lines.includes(l));
  };

  const onSubmit = async (data: z.infer<typeof schema>) => {
    const missingLines = getMissingLines();

    if (missingLines.length > 0) return;

    const selectedEstimates = Object.keys(estimates).map((key) => estimates[key]);

    setIsLoading(true);

    try {
      await apiProvider.issues.estimates.createOrUpdateEstimate(
        new CreateOrUpdateEstimatePayload({
          issueId,
          estimateId: null,
          discountPercent: +data.discount,
          startDate: rooDate.makeInstant(new Date(data.startDate)),
          endDate: rooDate.makeInstant(new Date(data.endDate)),
          taxPercent: +data.taxtRate,
          lines: selectedBidLines().map((task, index) => {
            const isSkipped = skipped.includes(task);

            const line = selected.find((s) => s.line.description === task)?.line;

            const value = isSkipped ? 0 : selectedEstimates.find((e) => e.task === task)?.value ?? line?.value;

            return new FormSubmissionLineItem({
              beforeFileIds: isSkipped ? [] : line.beforeFiles.map((f) => f.id),
              afterFileIds: [],
              description: task,
              initialValue: isSkipped ? 0 : line.initialValue,
              notes: isSkipped ? null : line.notes,
              order: index,
              sourceEntityId: isSkipped ? null : line.sourceEntityId,
              sourceEntityType: isSkipped ? LineSource.TaskList : line.sourceEntityType,
              value: isSkipped ? 0 : value,
              resaleValue: null
            });
          })
        })
      );
      redirect({ pathname: Routes.IssueView, params: { workorderId, issueId } });
    } catch (e) {
      setIsLoading(false);
    }
  };

  const onSkip = useCallback((task: string, skip: boolean) => {
    setSkipped((state) => {
      if (!skip) return state.filter((s) => s !== task);

      return state.includes(task) ? state : [...state, task];
    });
  }, []);

  const summaryLines = Object.keys(estimates)
    .map((key) => estimates[key])
    .map((x) => ({ initialValue: x.originalValue, value: x.value }));

  const enabledButton = getMissingLines().length === 0;

  return (
    <Box display="flex" flexDirection="column" p="1.5rem">
      <Divider />
      <Grid container padding="1rem 0" justifyContent={'space-between'}>
        <Grid item>
          <Typography>Compare the offers from your vendors</Typography>
        </Grid>
        <Grid>
          <PreviousEstimate issueId={issueId} handlePrevEstimatesCallback={setPrevEstimates} />
        </Grid>
      </Grid>
      <Grid
        container
        className="custom-scrollbar"
        sx={{
          maxHeight: '600px',
          overflowY: 'auto'
        }}
      >
        <Grid item container xs={12} sm={6} direction={'column'}>
          <TasksList
            control={control}
            tasks={comparisonData.lines}
            lines={selected}
            onChangeEstimate={onChangeEstimate}
            onSkip={onSkip}
          />
        </Grid>
        <Grid item container xs={12} sm={6} wrap={'nowrap'} className="custom-scrollbar" sx={{ overflowX: 'auto' }}>
          {prevEstimates.map((entry) => (
            <PrevEstimateColumn key={entry.id} entry={entry} tasks={comparisonData.lines} />
          ))}
          {comparisonData.comparables.map((entry, index) => (
            <BidColumn
              key={index}
              onSelectAll={onSelectAll}
              entry={entry}
              tasks={comparisonData.lines}
              onClick={onClick}
              selectedLines={selectedLines()}
            />
          ))}
        </Grid>
      </Grid>
      <EstimateForm
        disabled={isLoading}
        disableSend={!enabledButton}
        workorderId={workorderId}
        issueId={issueId}
        control={control}
        onSubmit={handleSubmit(onSubmit)}
        lines={summaryLines}
      />
    </Box>
  );
};
