import { Autocomplete, AutocompleteProps, TextField } from '@mui/material';
import { DropdownOption } from '../shared/store';
import { ReactNode } from 'react';

export type RooMuiMultipleSelectProps<TVal> = Omit<
  AutocompleteProps<DropdownOption<TVal>, true, any, false>,
  'options' | 'renderInput' | 'freeSolo' | 'multiple' | 'value' | 'onChange'
> & {
  options: DropdownOption<TVal>[];
  value: TVal[];
  onChange: (val: TVal[]) => void;
  placeholder: ReactNode;
};

export const RooMuiMultipleSelect = <TVal extends {}>({
  options,
  placeholder,
  value,
  onChange,
  ...props
}: RooMuiMultipleSelectProps<TVal>) => {
  const finalOptions = options ?? [];
  return (
    <Autocomplete
      multiple={true}
      freeSolo={false}
      getOptionLabel={(option) => option.label}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.value?.toString() ?? option.label}>
            {option.label}
          </li>
        );
      }}
      renderInput={(params) => <TextField variant={'standard'} {...params} size={'small'} label={placeholder} />}
      options={finalOptions}
      value={finalOptions.filter((x) => (value ?? []).includes(x.value))}
      onChange={(_, val) => {
        onChange((val ?? []).map((x) => x.value));
      }}
      isOptionEqualToValue={(a, b) => {
        return a?.value === b?.value && a != null && b != null;
      }}
      {...props}
    />
  );
};
