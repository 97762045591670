import { IssuesExternalFilter, IssuesServerGrid, useIssuesGrid } from './IssuesServerGrid';
import { DashboardStatCategory, Instant } from '../../shared/api/clients';
import { RooDialog } from '../RooDialog';
import * as React from 'react';
import { ReactNode } from 'react';

export const IssuesServerModal = ({
  open,
  onClose,
  title,
  ...props
}: {
  open: boolean;
  onClose: () => void;
  title: ReactNode;
  filters: IssuesExternalFilter;
}) => {
  return (
    <RooDialog onClose={onClose} open={open} fullWidth maxWidth={'xl'}>
      <RooDialog.Title onClose={onClose}>{title}</RooDialog.Title>
      <RooDialog.Content>
        <ModalBody {...props} />
      </RooDialog.Content>
    </RooDialog>
  );
};

const ModalBody = ({ filters }: { filters: IssuesExternalFilter }) => {
  const grid = useIssuesGrid({
    forceExpanded: true,
    externalFilters: filters
  });

  return (
    <IssuesServerGrid
      state={grid}
      forceLinksNewWindow={true}
      showStatAmount={true}
      showSubtotal={true}
      showCompany={true}
    />
  );
};
