/**
 * @function isMobile - Checks if the user is on a mobile device
 */
export function isMobile(): boolean {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

export const getDeviceType = (): 'mobile-android' | 'mobile-ios' | 'other' => {
  const ua = navigator.userAgent;
  if (/iPad|iPhone|iPod/.test(ua) && !(window as any).MSStream) {
    return 'mobile-ios';
  }

  if (/android/i.test(ua)) {
    return 'mobile-android';
  }

  return 'other';
};
