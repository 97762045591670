import { ReactNode } from 'react';
import { Button, Form, FormGroup } from 'react-bootstrap';
import { apiProvider } from 'shared/api/apiProvider';
import {
  CreateIssuePayload,
  IIssue,
  IIssueUserView,
  IssueUserView,
  LocationType,
  UpdateIssuePayload
} from 'shared/api/clients';
import { FieldRadio, FieldSingleSelect, FieldText, FieldTextArea, Required, useRooForm } from 'components/form';
import { useExteriorLocationOptions, useFocusOptions, useInteriorLocationOptions } from 'shared/store';
import { RooDialog, SaveButton } from 'components';
import { z } from 'zod';
import { Alert, Stack } from '@mui/material';

const FormSchema = z.object({
  description: z.string(),
  title: z.string(),
  locationType: z.nativeEnum(LocationType),
  interiorLocationId: z.number().nullish(),
  exteriorLocationId: z.number().nullish(),
  workTypeId: z.number().nullish()
});

type FormDefinition = z.infer<typeof FormSchema>;

export const AddEditIssueModal = ({
  issue,
  visible,
  onCloseRequest,
  onIssueSaved,
  parentIssue,
  title,
  managementCompanyId,
  propertyId,
  workorderId
}: {
  issue?: IIssue;
  parentIssue?: IIssue;
  visible: boolean;
  workorderId: string;
  propertyId: string;
  managementCompanyId: string;
  onCloseRequest: () => void;
  onIssueSaved: (issue: IIssueUserView) => void;
  title: ReactNode;
}) => {
  const interiorOptions = useInteriorLocationOptions();
  const exteriorOptions = useExteriorLocationOptions();
  const workTypes = useFocusOptions();

  const { handleSubmit, control, formState, watch } = useRooForm(FormSchema, {
    defaultValues: {
      description: issue?.description ?? parentIssue?.description,
      title: issue?.title ?? parentIssue?.title,
      locationType: issue?.locationType ?? parentIssue?.locationType ?? LocationType.Interior,
      workTypeId: issue?.workType?.id ?? parentIssue?.workType?.id
    }
  });
  const selectedLocation = watch('locationType');
  const submit = async (values: FormDefinition) => {
    try {
      let newIssue: IssueUserView;
      if (issue == null) {
        newIssue = await apiProvider.issues.main.create(
          new CreateIssuePayload({
            description: values.description,
            title: values.title,
            managementCompanyId: managementCompanyId,
            propertyId: propertyId,
            workorderId: workorderId,
            locationType: values.locationType,
            interiorLocationId: values.locationType === LocationType.Interior ? values.interiorLocationId : null,
            exteriorLocationId: values.locationType === LocationType.Exterior ? values.exteriorLocationId : null,
            workTypeId: values.workTypeId,
            parentId: parentIssue?.id,
            inspectionId: null
          })
        );
      } else {
        newIssue = await apiProvider.issues.main.update(
          new UpdateIssuePayload({
            id: issue.id,
            description: values.description,
            title: values.title,
            locationType: values.locationType,
            interiorLocationId: values.locationType === LocationType.Interior ? values.interiorLocationId : null,
            exteriorLocationId: values.locationType === LocationType.Exterior ? values.exteriorLocationId : null,
            workTypeId: values.workTypeId
          })
        );
      }

      onCloseRequest();
      onIssueSaved(newIssue);
    } catch (err) {}
  };

  return (
    <RooDialog maxWidth={'sm'} fullWidth open={visible} onClose={onCloseRequest}>
      <Form noValidate style={{ marginBottom: 'initial' }} onSubmit={handleSubmit(submit)}>
        <RooDialog.Title onClose={onCloseRequest}>{title}</RooDialog.Title>
        <RooDialog.Content>
          <Stack spacing={2}>
            <FieldRadio
              control={control}
              options={[
                { value: LocationType.Interior, label: 'Interior' },
                { value: LocationType.Exterior, label: 'Exterior' }
              ]}
              name={'locationType'}
            />
            {selectedLocation === LocationType.Interior && (
              <FieldSingleSelect
                control={control}
                options={interiorOptions}
                name={'interiorLocationId'}
                label={'Location'}
              />
            )}
            {selectedLocation === LocationType.Exterior && (
              <FieldSingleSelect
                control={control}
                options={exteriorOptions}
                name={'exteriorLocationId'}
                label={'Location'}
              />
            )}

            <FormGroup>
              <FieldSingleSelect control={control} options={workTypes} name={'workTypeId'} label={'Work Type'} />
            </FormGroup>

            <FieldText control={control} name={'title'} required label={'Title'} />

            <Stack gap={1}>
              <FieldTextArea
                control={control}
                name={'description'}
                placeholder={'Example:\n- Line 1\n- Line 2'}
                rows={3}
                required
                label={'Description'}
              />
              <Alert severity={'info'}>Must start each line with a hyphen</Alert>
            </Stack>
          </Stack>
        </RooDialog.Content>
        <RooDialog.Actions>
          <SaveButton control={control}>Save</SaveButton>
          <Button
            disabled={formState.isSubmitting || formState.isValidating}
            variant={'secondary'}
            onClick={onCloseRequest}
          >
            Cancel
          </Button>
        </RooDialog.Actions>
      </Form>
    </RooDialog>
  );
};
