import { Grid, IconButton, Typography } from '@mui/material';
import { RooAvatar } from 'components';
import { FilePreview } from 'components/Files/FileThumbnail';
import { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { ComparisonEntry, DocumentLineItem } from 'shared/api/clients';
import { MuiIcon } from 'shared/icons';
import NoteTooltip from './NoteTooltip';
import { apiProvider } from '../../../../../shared/api/apiProvider';

type BidColumnProps = {
  entry: ComparisonEntry;
  tasks: string[];
  onClick?: (task: number, line: DocumentLineItem) => void;
  selectedLines?: string[];
  onSelectAll: (line: DocumentLineItem[]) => void;
};

export function BidColumn({ entry, tasks, onSelectAll, onClick, selectedLines }: BidColumnProps) {
  const selectAll = () => {
    onSelectAll(entry.lines);
  };

  return (
    <Grid marginLeft="0.2rem" container direction="column" minWidth={'180px'}>
      <Grid
        container
        alignItems={'center'}
        py={'1rem'}
        px={'0.5rem'}
        sx={{
          backgroundColor: '#2ccccc7d',
          borderTopLeftRadius: '10px',
          borderTopRightRadius: '10px'
        }}
        height={'calc(50px + 1rem)'}
        wrap="nowrap"
        position="relative"
        onClick={selectAll}
      >
        <Grid item width="32px">
          <RooAvatar name={entry.vendor.companyName} avatarUrl={null} size={'m'} />
        </Grid>
        <Grid width="calc(100% - 32px)" item>
          <Typography px="0.5rem" sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
            {entry.vendor.companyName}
          </Typography>
        </Grid>
      </Grid>
      {tasks.map((task, index) => {
        const line = entry.lines.find((l) => l.description === task);
        const isSelected = selectedLines?.includes(line?.id ?? '') ?? false;

        return <BidTaskItem isSelected={isSelected} line={line} onClick={onClick} taskIndex={index} key={task} />;
      })}

      <Grid
        item
        container
        alignItems={'center'}
        justifyContent={'center'}
        width={'100%'}
        direction={'column'}
        sx={{
          backgroundColor: '#303030',
          color: 'white',
          borderBottomLeftRadius: '10px',
          borderBottomRightRadius: '10px'
        }}
        height={'calc(50px + 1rem)'}
      >
        <Typography align="center">Total bid</Typography>
        <Typography align="center" fontWeight={600} fontSize={'1.2rem'}>
          ${entry.grandTotal}
        </Typography>
      </Grid>
    </Grid>
  );
}

type BidTaskItemProps = {
  isSelected: boolean;
  taskIndex: number;
  line: DocumentLineItem;
  onClick: (taskIndex: number, line: DocumentLineItem) => void;
};

function useResizeObserver(taskIndex: number) {
  const [elementHeight, setHeight] = useState<string | number>('calc(100px + 1rem)');
  const resizeObserverRef = useRef(null);

  useEffect(() => {
    const element = document.getElementById(`task-${taskIndex}`);

    resizeObserverRef.current = new ResizeObserver((entries) => {
      const height = entries[0].target.clientHeight;
      setHeight(height);
    });

    resizeObserverRef.current.observe(element);

    return () => {
      resizeObserverRef.current.unobserve(element);
      resizeObserverRef.current.disconnect();
    };
  }, [taskIndex]);

  return elementHeight;
}

function BidTaskItem({ isSelected, taskIndex, line, onClick }: BidTaskItemProps) {
  const [showPreview, setShowPreview] = useState(false);

  const height = useResizeObserver(taskIndex);

  const onClickItem = (e: SyntheticEvent<HTMLDivElement>) => {
    if (!line) return;
    onClick(taskIndex, line);
  };

  const onClickPicture = (e: SyntheticEvent) => {
    // prevents selecting the cell
    e.stopPropagation();

    setShowPreview(true);
  };

  return (
    <>
      <Grid
        height={height}
        py={'1rem'}
        px={'0.5rem'}
        item
        container
        direction={'column'}
        alignItems={'center'}
        justifyContent={'center'}
        onClick={onClickItem}
        sx={{
          position: 'relative',
          backgroundColor: taskIndex % 2 === 0 ? '#e3f2f2' : '#f5fbfb',
          border: (isSelected && '5px solid #0ab7b7') || 'unset',
          ':hover': {
            border: !isSelected && '2px solid #0ab7b7'
          }
        }}
      >
        {line && (
          <Grid container justifyContent={'center'} alignItems={'center'} height={'100%'}>
            <Typography align={'center'} fontWeight="600" sx={{ flex: '1 0 0' }}>
              ${line.value}
            </Typography>
            <IconButton
              onClick={onClickPicture}
              disabled={line.beforeFiles.length === 0}
              size={'large'}
              color={'primary'}
            >
              <MuiIcon.Image fontSize={'inherit'} />
            </IconButton>
            <NoteTooltip notes={line?.notes} />
          </Grid>
        )}
      </Grid>
      {
        // placed outsite of Grid to prevent onClickItem to get triggered when clicking on modal
        line && line.beforeFiles.length > 0 && (
          <FilePreview
            previewUrl={apiProvider.fileUrls.serve(line.beforeFiles[0].id)}
            show={showPreview}
            onHide={() => setShowPreview(false)}
          />
        )
      }
    </>
  );
}
