import { Alert, AlertTitle, Box, Button, CircularProgress, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { apiProvider } from 'shared/api/apiProvider';
import {
  CanForceChangeEmailPayload,
  CapabilityType,
  ForceChangeEmailPayload,
  ForceChangeEmailResultType,
  UserDetails
} from 'shared/api/clients';
import { FieldMuiText, useRooForm } from 'components/form';
import { useHasCapability } from 'shared/store';
import { RooDialog, SaveButtonMui } from 'components';
import { showError, showSuccess } from 'shared/utils';
import { z } from 'zod';
import { UpdateUserHandler } from 'pages/UserProfile/ViewProfile/shared';
import { useToggle } from '@roo/lib';

export const ForceChangeEmailButton = ({
  targetUser,
  onUpdateUser
}: {
  targetUser: UserDetails;
  onUpdateUser: UpdateUserHandler;
}) => {
  const [show, toggleShow] = useToggle();
  const canEdit = useHasCapability(CapabilityType.PropertyManager, targetUser.user.managementCompany?.id);
  if (!canEdit) {
    return null;
  }

  return (
    <>
      <Button type="submit" sx={{ mt: 2, mb: 2, width: 200 }} onClick={toggleShow}>
        Change E-mail
      </Button>
      <RooDialog open={show} onClose={toggleShow} maxWidth={'sm'} fullWidth>
        <RooDialog.Title>Change user e-mail</RooDialog.Title>
        {show && <ForceChangeBody toggleModal={toggleShow} targetUser={targetUser} onUpdateUser={onUpdateUser} />}
      </RooDialog>
    </>
  );
};

type CheckState = 'checking' | 'allowed' | 'denied' | 'error';

export const ForceChangeBody = ({
  targetUser,
  toggleModal,
  onUpdateUser
}: {
  toggleModal: () => void;
  targetUser: UserDetails;
  onUpdateUser: UpdateUserHandler;
}) => {
  const [state, setState] = useState<CheckState>('checking');
  useEffect(() => {
    const run = async () => {
      setState('checking');
      try {
        const isAllowed = await apiProvider.profileClient.canForceChangeEmail(
          new CanForceChangeEmailPayload({
            managementCompanyId: targetUser.user.managementCompany.id,
            targetUserId: targetUser.user.id
          })
        );
        setState(isAllowed ? 'allowed' : 'denied');
      } catch (e) {
        setState('error');
      }
    };

    void run();
  }, [targetUser.user.id, targetUser.user.managementCompany.id]);

  if (state === 'allowed') {
    return <ForceChangeForm targetUser={targetUser} toggleModal={toggleModal} onUpdateUser={onUpdateUser} />;
  }

  return (
    <>
      <RooDialog.Content>
        {state === 'checking' && (
          <>
            <Box sx={{ textAlign: 'center' }}>
              <CircularProgress />
              <Typography>Checking if you can change the user's e-mail... </Typography>
            </Box>
          </>
        )}
        {state === 'error' && (
          <Alert severity={'error'}>
            <>We couldn't determine whether or not you are able to change this user's email. Please try again later</>
          </Alert>
        )}
        {state === 'denied' && (
          <Alert severity={'error'}>
            <AlertTitle>This user's e-mail can't be changed</AlertTitle>
            Possible reasons may include:
            <ul>
              <li>The user is a part of multiple companies</li>
              <li>The user is imported from an external source</li>
              <li>The user has already logged in</li>
            </ul>
          </Alert>
        )}
      </RooDialog.Content>
      <RooDialog.Actions>
        <RooDialog.Actions>
          <Button color={'muted'} onClick={toggleModal}>
            Close
          </Button>
        </RooDialog.Actions>
      </RooDialog.Actions>
    </>
  );
};

const FormSchema = z
  .object({
    oldEmail: z.string(),
    newEmail: z.string().email()
  })
  .superRefine((val, ctx) => {
    if (val.oldEmail === val.newEmail) {
      ctx.addIssue({
        message: 'New e-mail is the same as old e-mail',
        code: 'custom',
        path: ['newEmail']
      });
    }
  });
type FormDefinition = z.infer<typeof FormSchema>;

const ForceChangeForm = ({
  targetUser,
  toggleModal,
  onUpdateUser
}: {
  targetUser: UserDetails;
  toggleModal: () => void;
  onUpdateUser: UpdateUserHandler;
}) => {
  const { control, handleSubmit, setError } = useRooForm(FormSchema, {
    defaultValues: {
      oldEmail: targetUser.user.contactInfo.email,
      newEmail: targetUser.user.contactInfo.email
    }
  });

  const save = async (values: FormDefinition) => {
    try {
      const captured = values.newEmail;
      const result = await apiProvider.profileClient.forceChangeEmail(
        new ForceChangeEmailPayload({
          oldEmail: values.oldEmail,
          newEmail: captured,
          userId: targetUser.user.id
        })
      );

      if (result === ForceChangeEmailResultType.EmailUnavailable) {
        const err = 'This e-mail is unavailable. Please choose a different one';
        setError('newEmail', { message: err, type: 'server' });
        showError(err);
      }

      if (result === ForceChangeEmailResultType.Success) {
        onUpdateUser((userDetails) => {
          userDetails.user.contactInfo.email = captured;
        });
        toggleModal();
        showSuccess("The user's e-mail has been changed");
      }
    } catch (e) {}
  };

  return (
    <form noValidate onSubmit={handleSubmit(save)}>
      <RooDialog.Content>
        <FieldMuiText control={control} name={'newEmail'} label={'New Email'} required fullWidth />
      </RooDialog.Content>
      <RooDialog.Actions>
        <SaveButtonMui control={control}>Save</SaveButtonMui>
        <Button color={'muted'} onClick={toggleModal}>
          Close
        </Button>
      </RooDialog.Actions>
    </form>
  );
};
