// https://github.com/mtblc/image-collage

import { round } from 'lodash';
import { findShortestPath } from './dijkstra';

interface PlacementImage {
  uri: string;
  width: number;
  height: number;
}

type Row = PlacementImage[];

export const getRowLayout = (
  photos: PlacementImage[],
  containerWidth: number,
  targetRowHeight: number = 300
): Row[] => {
  let limitNodeSearch = 2;

  if (containerWidth >= 450) {
    limitNodeSearch = findIdealNodeSearch({ containerWidth, targetRowHeight });
  }

  const thumbs = computeRowLayout({
    containerWidth,
    limitNodeSearch,
    targetRowHeight,
    margin: 0,
    photos
  });

  const rows: Row[] = [];
  let currentRow: Row = [];
  let width = 0;

  thumbs.forEach((thumb) => {
    if (Math.round(width + thumb.width) > containerWidth) {
      rows.push(currentRow);
      currentRow = [];
      width = thumb.width;
    } else {
      width += thumb.width;
    }
    currentRow.push(thumb);
  });

  if (currentRow.length > 0) rows.push(currentRow);

  return rows;
};

export const getCanvasWidth = (rows: Row[]) => {
  return rows[0].reduce((width, element) => width + element.width, 0);
};

export const getCanvasHeight = (rows: Row[]) => {
  return rows.reduce((height, row) => height + row[0].height, 0);
};

export function getPositions(rows: Row[]) {
  let y = 0;

  return rows.map((row) => {
    let x = 0;
    const position = row.map((thumb) => {
      const thumbX = x;
      x += thumb.width;
      return { x: thumbX, y };
    });
    y += row[0].height;
    return position;
  });
}

export const findIdealNodeSearch = ({
  targetRowHeight,
  containerWidth
}: {
  targetRowHeight: number;
  containerWidth: number;
}): number => {
  const rowAR = containerWidth / targetRowHeight;
  return round(rowAR / 1.5, 0) + 8;
};

type NeighborResults = { [key: string]: number };
type GetNeighbors = (start: string) => NeighborResults;

const getCommonHeight = (row: PlacementImage[], containerWidth: number, margin: number): number => {
  const rowWidth = containerWidth - row.length * (margin * 2);
  const totalAspectRatio = row.reduce((acc, photo) => acc + ratio(photo), 0);
  return rowWidth / totalAspectRatio;
};

const cost = (
  photos: PlacementImage[],
  i: number,
  j: number,
  width: number,
  targetHeight: number,
  margin: number
): number => {
  const row = photos.slice(i, j);
  const commonHeight = getCommonHeight(row, width, margin);
  return Math.pow(Math.abs(commonHeight - targetHeight), 2);
};

const makeGetNeighbors =
  (
    targetHeight: number,
    containerWidth: number,
    photos: PlacementImage[],
    limitNodeSearch: number,
    margin: number
  ): GetNeighbors =>
  (start) => {
    const results: NeighborResults = {};
    const startNum = +start;
    results[start] = 0;
    for (let i = startNum + 1; i < photos.length + 1; ++i) {
      if (i - startNum > limitNodeSearch) break;
      results[i.toString()] = cost(photos, startNum, i, containerWidth, targetHeight, margin);
    }
    return results;
  };

export const computeRowLayout = ({
  containerWidth,
  limitNodeSearch,
  targetRowHeight,
  margin,
  photos
}: {
  containerWidth: number;
  limitNodeSearch: number;
  targetRowHeight: number;
  margin: number;
  photos: PlacementImage[];
}): PlacementImage[] => {
  const getNeighbors = makeGetNeighbors(targetRowHeight, containerWidth, photos, limitNodeSearch, margin);
  let path = findShortestPath(getNeighbors, '0', photos.length.toString());
  const numericalPath = path.map((node) => +node);
  for (let i = 1; i < path.length; ++i) {
    const row = photos.slice(numericalPath[i - 1], numericalPath[i]);
    const height = getCommonHeight(row, containerWidth, margin);
    for (let j = numericalPath[i - 1]; j < numericalPath[i]; ++j) {
      photos[j].width = round(height * ratio(photos[j]), 1);
      photos[j].height = height;
    }
  }
  return photos;
};

export const ratio = ({ width, height }: { width: number; height: number }) => round(width / height, 2);
