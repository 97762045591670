import { Stack, Typography } from '@mui/material';
import { addDays, differenceInCalendarDays, endOfWeek, format, isSameDay, startOfWeek } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { ListGroup, ListGroupItem } from 'react-bootstrap';
import { apiProvider } from 'shared/api/apiProvider';
import { Issue, ProposalType, SearchScheduledIssueCriteria } from 'shared/api/clients';
import { prepareRouterHrefAttrs, Routes } from 'shared/routing';
import { LoaderSmall } from 'components';
import WeekPicker from 'components/WeekPicker';

export type WeeklyScheduleCardProps = {
  companyId: string;
  vendorIds: string[];
  stateCode: string;
  city: string;
};

export const WeeklyScheduleCard = (props: WeeklyScheduleCardProps) => {
  interface IState {
    startDate: Date;
    endDate: Date;
    daysToRender: Date[];
    issues?: Issue[];
    loading: boolean;
  }

  const getDaysToRender = (startDate: Date, endDate: Date): Date[] => {
    var daysToRender = [];
    for (let i = 0; i <= differenceInCalendarDays(endDate, startDate); i++) {
      daysToRender.push(addDays(startDate, i));
    }
    return daysToRender;
  };

  const initialDate = new Date();

  const [state, setState] = useState<IState>({
    loading: true,
    startDate: startOfWeek(initialDate),
    endDate: endOfWeek(initialDate),
    daysToRender: getDaysToRender(startOfWeek(initialDate), endOfWeek(initialDate))
  });

  useEffect(() => {
    const fetch = async () => {
      let issues = await apiProvider.dashboardClient.searchScheduledIssues(
        new SearchScheduledIssueCriteria({
          managementCompanyId: props.companyId,
          scheduleStartDate: state.startDate,
          scheduleEndDate: state.endDate,
          currentOffset: new Date().getTimezoneOffset(),
          scheduleStatus: null,
          proposalType: null,
          vendorIds: props.vendorIds,
          stateCode: props.stateCode,
          city: props.city
        })
      );

      setState((x) => ({
        ...x,
        loading: false,
        issues: issues.sort(
          (a, b) => b.schedule.scheduledInstant.dateTime.getTime() - a.schedule.scheduledInstant.dateTime.getTime()
        )
      }));
    };

    setState((x) => ({ ...x, loading: true, issues: undefined }));

    void fetch();
  }, [props.companyId, props.vendorIds, props.stateCode, props.city, state.startDate, state.endDate]);

  const onChangeWeek = (start: Date, end: Date) => {
    setState((x: IState) => ({ ...x, startDate: start, endDate: end, daysToRender: getDaysToRender(start, end) }));
  };

  return (
    <>
      <Stack>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          alignItems={{ xs: 'center', sm: 'space-between' }}
          justifyContent={{ sm: 'space-between' }}
        >
          <h3 style={{ fontWeight: 400 }}>Schedule</h3>
          {!state.loading && <WeekPicker value={state.startDate} onChange={onChangeWeek}></WeekPicker>}
        </Stack>
        {state.loading && <LoaderSmall />}
        {!state.loading && (
          <Stack gap={4}>
            <ListGroup className="schedule-weekly list-group-horizontal-md">
              {state.daysToRender.map((day) => {
                const issuesInDay = state.issues.filter((i) => isSameDay(day, i.schedule.scheduledInstant.dateTime));
                return (
                  <ListGroupItem key={format(day, 'EEEE')} className="flex align-items-center day">
                    <h6 className="border-bottom text-center px-1">{format(day, 'd - EEEE')}</h6>

                    <ListGroup className="day-events">
                      {issuesInDay.length === 0 && (
                        <Stack sx={{ height: '100%' }} alignItems={'center'} justifyContent={'center'}>
                          <Typography variant={'caption'} color={'grey.400'}>
                            All clear!
                          </Typography>
                        </Stack>
                      )}
                      {issuesInDay.length > 0 &&
                        issuesInDay.map((issue, i) => (
                          <ListGroupItem
                            key={i}
                            action
                            {...prepareRouterHrefAttrs({
                              pathname: Routes.IssueView,
                              params: { workorderId: issue.workorderId, issueId: issue.id }
                            })}
                            className="event-card bg-light"
                          >
                            <Stack direction={'row'} justifyContent={'space-between'}>
                              <span>
                                {format(issue.schedule.scheduledInstant.dateTime, 'EEE')} &nbsp;{' '}
                                {issue.schedule.timeBlocks[0].display}
                              </span>
                              {issue.schedule.proposalType === ProposalType.Tentative && <span>PROPOSED</span>}
                            </Stack>
                            <div className="fw-bold">{issue.property.name}</div>
                            {issue.title}
                          </ListGroupItem>
                        ))}
                    </ListGroup>
                  </ListGroupItem>
                );
              })}
            </ListGroup>
          </Stack>
        )}
      </Stack>
    </>
  );
};
