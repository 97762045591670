import { EntityNote } from '../../shared/api/clients';
import { Card, Grid, Stack, Typography, useTheme } from '@mui/material';
import React from 'react';
import { rooFmt } from '../../shared/utils';

export const InternalNote = ({ note }: { note: EntityNote }) => {
  const theme = useTheme();

  return (
    <Grid container justifyContent={'flex-start'}>
      <Grid item>
        <Card
          style={{
            padding: 10,
            margin: 10,
            minWidth: 150,
            borderRadius: 20,
            backgroundColor: theme.palette.grey.A100
          }}
        >
          <Grid container alignItems="center">
            <Grid item style={{ marginLeft: 10 }}>
              <Typography variant="body1">{note.text}</Typography>
              <Stack mt={1} direction="row" spacing={1} alignItems="center">
                <Typography variant="caption">{note.author.fullName}</Typography>
                <Typography variant="caption" color="textSecondary">
                  {rooFmt.chatDate(note.createdAt)}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};

export const InternalNoteList = ({ notes }: { notes: EntityNote[] }) => {
  return (
    <Stack spacing={1}>
      {notes.map((x) => (
        <InternalNote key={x.id} note={x} />
      ))}
    </Stack>
  );
};
