import { LoaderBalls } from 'components/LoaderBalls';

export const LoaderBig = () => {
  return (
    <>
      <div className="d-flex justify-content-center mb-2" style={{ marginTop: '200px' }}>
        <h2>Loading...</h2>
      </div>
      <div className="d-flex justify-content-center" style={{ marginBottom: '200px' }}>
        <div className="spinner-grow text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
        <LoaderBalls />
      </div>
    </>
  );
};
