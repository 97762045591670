import { ReactNode } from 'react';
import { RooIcon } from 'shared/icons';
import { RooDialog } from 'components/RooDialog';
import { Button } from '@mui/material';

export const ConfirmationModal = ({
  visible,
  onClose,
  onSave,
  running,
  question,
  title,
  size,
  confirmDisabled
}: {
  visible: boolean;
  onClose: () => void;
  onSave: () => void;
  running: boolean;
  question?: ReactNode;
  title?: ReactNode;
  size?: 'sm' | 'lg' | 'md' | 'xl';
  confirmDisabled?: boolean;
}) => {
  const finalQuestion = question ?? 'Do you want to proceed?';
  const finalTitle = title ?? 'Confirmation';
  return (
    <RooDialog maxWidth={size} fullWidth open={visible} onClose={onClose}>
      <RooDialog.Title onClose={onClose}>{finalTitle}</RooDialog.Title>
      <RooDialog.Content>{finalQuestion}</RooDialog.Content>
      <RooDialog.Actions>
        <Button color="primary" onClick={onSave} disabled={running || confirmDisabled} sx={{ mr: 1 }}>
          {running ? <RooIcon icon={['fas', 'circle-notch']} spin={running} style={{ fontSize: 23 }} /> : 'Yes'}
        </Button>
        <Button color="muted" onClick={onClose} disabled={running}>
          Cancel
        </Button>
      </RooDialog.Actions>
    </RooDialog>
  );
};
