type PendingQuery<T> = {
  queryArgs: T;
  resolve: (value: any) => void;
  reject: (reason?: any) => void;
};

type FlattenOnce<T> = T extends (infer R)[] ? R : T;

type Batch<T> = {
  state: 'collecting' | 'running' | 'complete';
  timeoutId: number | null;
  queries: Array<PendingQuery<T>>;
};

type FetchFunction<TParams, TResult> = (paramsList: TParams[]) => Promise<FlattenOnce<TResult>[]>;
type SplitterFunction<TParams, TResult> = (
  params: TParams,
  allResults: FlattenOnce<TResult>[]
) => FlattenOnce<TResult>[];

export class QueryBatcher<TParams, TResult> {
  private pendingBatches: Array<Batch<TParams>> = [];

  constructor(
    private readonly batchWaitTime: number,
    private readonly fetchFunction: FetchFunction<TParams, TResult>,
    private readonly splitterFunction: SplitterFunction<TParams, TResult>
  ) {
    this.batchWaitTime = batchWaitTime;
    this.fetchFunction = fetchFunction;
  }

  batchQuery(queryArgs: TParams): Promise<TResult> {
    return new Promise((resolve, reject) => {
      const collectingBatch = this.findOrCreateCollectingBatch();
      collectingBatch.queries.push({ queryArgs, resolve, reject });
    });
  }

  private findOrCreateCollectingBatch(): Batch<TParams> {
    let collectingBatch = this.pendingBatches.find((batch) => batch.state === 'collecting');

    if (!collectingBatch) {
      collectingBatch = {
        state: 'collecting',
        timeoutId: null,
        queries: []
      };
      this.pendingBatches.push(collectingBatch);

      collectingBatch.timeoutId = window.setTimeout(() => {
        void this.processBatch(collectingBatch);
      }, this.batchWaitTime);
    }

    return collectingBatch;
  }

  private async processBatch(batch: Batch<TParams>): Promise<void> {
    batch.state = 'running';
    clearTimeout(batch.timeoutId);
    batch.timeoutId = null;

    try {
      const queryArgsArray = batch.queries.map((pendingQuery) => pendingQuery.queryArgs);
      const combinedResults = await this.fetchFunction(queryArgsArray);

      batch.queries.forEach(({ resolve, queryArgs }) => {
        resolve(this.splitterFunction(queryArgs, combinedResults));
      });
    } catch (error) {
      batch.queries.forEach(({ reject }) => {
        reject(error);
      });
    } finally {
      batch.state = 'complete';
      this.clearCompletedBatches();
    }
  }

  private clearCompletedBatches(): void {
    this.pendingBatches = this.pendingBatches.filter((batch) => batch.state !== 'complete');
  }
}
