import { Typography } from '@mui/material';
import { RenderCount } from 'pages/Dev/RenderCount';
import { Control, FieldValues, useWatch } from 'react-hook-form';

export const DebugFormResults = <TFieldValues extends FieldValues = FieldValues>({
  control
}: {
  control: Control<TFieldValues>;
}) => {
  const val = useWatch({
    control: control
  });
  return (
    <>
      <Typography variant={'h4'}>
        <h4>Results</h4>
      </Typography>
      <div style={{ position: 'relative' }}>
        <RenderCount />
        <pre>{JSON.stringify(val, null, 2)}</pre>
      </div>
    </>
  );
};
