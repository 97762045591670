import { Alert, Box, Button, CircularProgress, ThemeProvider, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { apiProvider } from 'shared/api/apiProvider';
import { InvoiceStatus, PayInvoiceInfo, ResetPasswordPayload } from 'shared/api/clients';
import { FieldMuiText, useRooForm } from 'components/form';
import { Routes } from 'shared/routing';
import theme, { darkTheme } from 'shared/theme';
import { LoaderBig, RouterLink } from 'components';
import { useQueryParams } from 'shared/utils';
import { z } from 'zod';
import { AuthPage } from 'pages/Auth/AuthPage';
import { Center } from 'components/Center';
import { rooFmt } from 'shared/utils';
import { useParams } from 'react-router';
import { EnumMapInvoiceStatus } from 'shared/store/enums/invoiceStatus';

export const PayInvoice = () => {
  const { id, userId } = useParams<{ id: string; userId: string }>();
  const [isLoading, setIsLoading] = useState(true);
  const [invoiceInfo, setInvoiceInfo] = useState<PayInvoiceInfo>();
  useEffect(() => {
    apiProvider.stripeClient
      .getPayInvoiceInfo(id)
      .then((r) => {
        setInvoiceInfo(r);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [id]);

  const isCancel = userId === 'cancel';
  const isSuccess = userId === 'success';

  const onPayInvoice = (method: string) => {
    setIsLoading(true);
    apiProvider.stripeClient.getPayInvoiceUrl(method, id, userId).then((url) => {
      window.location.href = url;
    });
  };

  return (
    <AuthPage title={'Pay Invoice - Walkthroo'}>
      <Typography variant="h5" component="div" gutterBottom sx={{ fontWeight: 700 }}>
        <Center>
          Pay Invoice
          {invoiceInfo &&
            ' - ' + invoiceInfo.friendlyId + ' (' + EnumMapInvoiceStatus.get(invoiceInfo.status).display + ')'}
        </Center>
      </Typography>
      {isLoading && <LoaderBig></LoaderBig>}
      {!isLoading &&
        invoiceInfo &&
        !isSuccess &&
        !isCancel &&
        (invoiceInfo.status === InvoiceStatus.Accepted || invoiceInfo.status === InvoiceStatus.PendingReview) && (
          <Box>
            {invoiceInfo.paymentOptions.map((i) => (
              <Button
                key={i.method}
                size={'largest'}
                type="submit"
                fullWidth
                sx={{ mt: 2, mb: 2 }}
                disabled={isLoading}
                onClick={() => {
                  onPayInvoice(i.method);
                }}
              >
                {isLoading ? (
                  <CircularProgress style={{ width: 31, height: 31 }} color="inherit" />
                ) : (
                  'Pay by ' + i.methodName + ': ' + rooFmt.money(i.chargeAmount)
                )}
              </Button>
            ))}
          </Box>
        )}
      {!isLoading &&
        invoiceInfo &&
        !isSuccess &&
        !isCancel &&
        invoiceInfo.status !== InvoiceStatus.Accepted &&
        invoiceInfo.status !== InvoiceStatus.PendingReview && (
          <Center>
            <Alert severity={'error'} title={"Can't pay the invoice"}>
              Can't pay the invoice because the invoice status is {EnumMapInvoiceStatus.get(invoiceInfo.status).display}
            </Alert>
          </Center>
        )}
      {!isLoading && invoiceInfo && (isSuccess || isCancel) && (
        <Center>
          <Alert severity={isSuccess ? 'info' : 'warning'} title={isSuccess ? 'Success' : 'Canceled'}>
            The payment was {isSuccess ? 'Successful' : 'Canceled'}!
          </Alert>
        </Center>
      )}
    </AuthPage>
  );
};
