import { useState } from 'react';
import { apiProvider } from 'shared/api/apiProvider';
import { IssueAction, VendorCancelIssuePayload } from 'shared/api/clients';
import { redirect, Routes } from 'shared/routing';
import { ConfirmationModal } from 'components';
import { showSuccess } from 'shared/utils';
import { useCurrentIssue } from 'pages/Workorders/shared/CurrentIssueContext';
import { issueActionList } from '../../shared/types';
import { createModalStore } from '../../../../shared/store';
import { MuiIcon } from '../../../../shared/icons';

const useVendorCancelStore = createModalStore();

export const VendorCancelModal = () => {
  const { issue, onIssueUpdate } = useCurrentIssue();
  const [comments, setComments] = useState<string>('');
  const [isSaving, setIsSaving] = useState(false);
  const isVisible = useVendorCancelStore((x) => x.visible);
  const hide = useVendorCancelStore((x) => x.actions.hide);

  const save = async () => {
    setIsSaving(true);
    try {
      const result = await apiProvider.issues.main.vendorCancelIssue(
        new VendorCancelIssuePayload({
          issueId: issue.id,
          comments: comments?.trim() === '' ? null : comments
        })
      );
      onIssueUpdate(result);
      showSuccess();
    } catch (e) {}

    setIsSaving(false);
    hide();
    redirect(Routes.Dashboard);
  };

  return (
    <ConfirmationModal
      visible={isVisible}
      running={isSaving}
      title={'Confirm Issue Cancellation'}
      question={
        <>
          Are you sure you want to cancel this issue?
          <div className={'mt-4 form-group'}>
            <label htmlFor={'notes-input'}>Comments</label>
            <textarea
              value={comments}
              onChange={(e) => setComments(e.target.value)}
              className={'form-control'}
              id={'notes-input'}
              rows={3}
              placeholder={'Your comments'}
            />
          </div>
        </>
      }
      onClose={hide}
      onSave={() => {
        void save();
      }}
    />
  );
};

export const vendorCancelAction = issueActionList.register({
  Icon: MuiIcon.Cancel,
  color: 'error',
  isDisabled: () => false,
  key: 'vendor-cancel',
  label: 'Cancel Issue',
  isVisible: (ctx) => ctx.canAct(IssueAction.VendorCancel),
  onClick: () => {
    useVendorCancelStore.getState().actions.show();
  },
  modalToRender: <VendorCancelModal />
});
