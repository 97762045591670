import { useState } from 'react';
import { apiProvider } from 'shared/api/apiProvider';
import { IssueAction, SkipSchedulingPayload } from 'shared/api/clients';
import { ConfirmationModal } from 'components';
import { showSuccess } from 'shared/utils';
import { useCurrentIssue } from 'pages/Workorders/shared/CurrentIssueContext';
import { issueActionList } from '../../shared/types';
import { createModalStore } from '../../../../shared/store';
import { MuiIcon } from '../../../../shared/icons';

const useSkipSchedulingStore = createModalStore();

export const SkipSchedulingModal = () => {
  const { issue, onIssueUpdate } = useCurrentIssue();
  const [comments, setComments] = useState<string>('');
  const [isSaving, setIsSaving] = useState(false);
  const isVisible = useSkipSchedulingStore((x) => x.visible);
  const hide = useSkipSchedulingStore((x) => x.actions.hide);

  const save = async () => {
    setIsSaving(true);
    try {
      const result = await apiProvider.issues.scheduling.skipScheduling(
        new SkipSchedulingPayload({
          issueId: issue.id,
          notes: comments?.trim() === '' ? null : comments
        })
      );
      onIssueUpdate(result);
      showSuccess();
    } catch (e) {}

    setIsSaving(false);
    hide();
  };

  return (
    <ConfirmationModal
      visible={isVisible}
      running={isSaving}
      title={'Confirm Skip Scheduling'}
      question={
        <>
          Are you sure you want to skip setting a schedule?
          <div className={'mt-4 form-group'}>
            <label htmlFor={'notes-input'}>Comments</label>
            <textarea
              value={comments}
              onChange={(e) => setComments(e.target.value)}
              className={'form-control'}
              id={'notes-input'}
              rows={3}
              placeholder={'Your comments'}
            />
          </div>
        </>
      }
      onClose={hide}
      onSave={() => {
        void save();
      }}
    />
  );
};

export const skipSchedulingAction = issueActionList.register({
  Icon: MuiIcon.FastForward,
  color: 'warning',
  isDisabled: () => false,
  key: 'skip-scheduling',
  label: 'Skip Scheduling',
  isVisible: (ctx) => ctx.canAct(IssueAction.SkipScheduling),
  onClick: () => {
    useSkipSchedulingStore.getState().actions.show();
  },
  modalToRender: <SkipSchedulingModal />
});
