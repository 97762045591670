import Button from '@mui/material/Button';
import React, { useState } from 'react';
import { Alert, Col, Form, FormGroup, Row } from 'react-bootstrap';
import { apiProvider } from 'shared/api/apiProvider';
import { CapabilityType, InviteUserPayload, InviteUserStatus } from 'shared/api/clients';
import { Required, FieldText, useRooForm } from 'components/form';
import { useAppStore } from 'shared/store';
import { RooDialog, SaveButtonMui } from 'components';
import { PersonForm, PersonSchema, showSuccess } from 'shared/utils';
import { useWatchGlobalModal } from './modalStore';
import Grid2 from '@mui/material/Unstable_Grid2';

export const InviteUserModal = () => {
  const [error, setError] = useState(null);
  const user = useAppStore((x) => x.userDetails);
  const { isVisible, toggle, props, callbacks } = useWatchGlobalModal('inviteUser');
  const { handleSubmit, formState, control, reset } = useRooForm(PersonSchema);

  let titleFragment = 'Person';
  let successFragment = 'The person';
  switch (props.capability) {
    case CapabilityType.PropertyManager:
      titleFragment = 'Property Manager';
      successFragment = 'Your coworker';
      break;
    case CapabilityType.Tenant:
      titleFragment = 'Tenant';
      successFragment = 'The tenant';
      break;
    case CapabilityType.PropertyOwner:
      titleFragment = 'Property Owner';
      successFragment = 'The owner';
      break;
  }

  const submit = async (values: PersonForm) => {
    try {
      const result = await apiProvider.usersClient.inviteUser(
        new InviteUserPayload({
          email: values.email,
          managementCompanyId: user.managementCompany.id,
          firstName: values.firstName,
          lastName: values.lastName,
          phone: values.phone,
          capabilityType: props.capability
        })
      );

      switch (result.status) {
        case InviteUserStatus.Success:
          setError(null);
          reset();
          toggle();
          callbacks?.onSuccess?.();
          showSuccess(`✨ ${successFragment} has been invited!`);
          break;
        case InviteUserStatus.DuplicateEmail:
          setError('A user with that e-mail already exists');
          break;
        default:
          setError('An unknown error has occurred');
          break;
      }
    } catch (err) {}
  };

  return (
    <RooDialog open={isVisible} onClose={toggle}>
      <Form noValidate style={{ marginBottom: 'initial' }} onSubmit={handleSubmit(submit)}>
        <RooDialog.Title onClose={toggle}>Invite {titleFragment}</RooDialog.Title>
        <RooDialog.Content>
          <Grid2 spacing={2} container>
            <Grid2 xl={6} xs={12}>
              <FieldText
                control={control}
                name={'firstName'}
                required
                label={'First Name'}
                placeholder={'First Name'}
                className="Input-field"
              />
            </Grid2>
            <Grid2 xl={6} xs={12}>
              <FieldText
                control={control}
                name={'lastName'}
                required
                label={'Last Name'}
                placeholder={'Last Name'}
                className="Input-field"
              />
            </Grid2>
            <Grid2 xl={6} xs={12}>
              <FieldText
                control={control}
                name={'email'}
                required
                label={'Email'}
                placeholder={'Email'}
                className="Input-field"
              />
            </Grid2>
            <Grid2 xl={6} xs={12}>
              <FieldText
                control={control}
                name={'phone'}
                required
                label={'Phone'}
                placeholder={'Phone'}
                className="Input-field"
              />
            </Grid2>
          </Grid2>
          {error && <Alert variant={'danger'}>{error}</Alert>}
        </RooDialog.Content>
        <RooDialog.Actions>
          <SaveButtonMui control={control}>Save</SaveButtonMui>
          &nbsp;&nbsp;
          <Button
            color="muted"
            disabled={formState.isSubmitting || formState.isValidating}
            type="button"
            onClick={toggle}
          >
            Cancel
          </Button>
        </RooDialog.Actions>
      </Form>
    </RooDialog>
  );
};
