import { BidComparison, DeclineBidPayload, DocumentLineItem, IssueRole, IssueUserView } from 'shared/api/clients';
import { Divider, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { z } from 'zod';
import React, { useState } from 'react';
import { TasksList } from './TaskList';
import { BidColumn } from './BidColumn';
import { useCurrentIssue } from '../../../shared/CurrentIssueContext';
import { apiProvider } from '../../../../../shared/api/apiProvider';
import { redirect, Routes } from '../../../../../shared/routing';
import { ConfirmationModal, RooButton } from '../../../../../components';
import { showSuccess } from '../../../../../shared/utils';

export type BidColumnSelection = {
  line: DocumentLineItem;
  taskIndex: number;
};

export const schema = z.object({
  startDate: z.date(),
  endDate: z.date(),
  taxtRate: z.number(),
  markup: z.number(),
  discount: z.number().optional()
});

export const CompareBidsContent = ({
  comparisonData,
  issueView,
  issueId
}: {
  comparisonData: BidComparison;
  workorderId: string;
  issueId: string;
  issueView: IssueUserView;
}) => {
  const [selected, setSelected] = useState<BidColumnSelection[]>([]);

  const onClick = (taskIndex: number, line: DocumentLineItem) => {};

  const onSelectAll = (lines: DocumentLineItem[]) => {};

  const selectedLines = () => selected.map((selection) => selection.line.id);

  return (
    <Box display="flex" flexDirection="column" p="1.5rem">
      <Divider />
      <Grid container padding="1rem 0" justifyContent={'space-between'}>
        <Grid item>
          <Typography>Compare the offers from your vendors</Typography>
        </Grid>
        {(issueView.userRole === IssueRole.MainPropertyManager ||
          issueView.userRole === IssueRole.AltPropertyManager) &&
          issueView.property?.owner != null && <SendEmailButton issueId={issueId} />}
      </Grid>
      <Grid
        container
        className="custom-scrollbar"
        sx={{
          maxHeight: '600px',
          overflowY: 'auto'
        }}
      >
        <Grid item container xs={12} sm={4} direction={'column'}>
          <TasksList tasks={comparisonData.lines} lines={selected} />
        </Grid>
        <Grid item container xs={12} sm={8} wrap={'nowrap'} className="custom-scrollbar" sx={{ overflowX: 'auto' }}>
          {comparisonData.comparables.map((entry, index) => (
            <BidColumn
              key={index}
              onSelectAll={onSelectAll}
              entry={entry}
              tasks={comparisonData.lines}
              onClick={onClick}
              selectedLines={selectedLines()}
            />
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};

const SendEmailButton = ({ issueId }: { issueId: string }) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const save = async () => {
    setIsSaving(true);
    try {
      await apiProvider.issues.bids.sendComparisonEmail(issueId);
      setShowConfirm(false);
      showSuccess('Link sent!');
      setIsSaving(false);
    } catch (e) {
      setIsSaving(false);
    }
  };
  return (
    <>
      <RooButton onClick={() => setShowConfirm(true)} variant={'primary'}>
        Send to Owner
      </RooButton>
      <ConfirmationModal
        visible={showConfirm}
        question={'Are you sure you want to send this comparison to the Property Owner?'}
        onClose={() => setShowConfirm(false)}
        onSave={() => void save()}
        running={isSaving}
      />
    </>
  );
};
