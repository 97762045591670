import { Box, Unstable_Grid2 as Grid, ListItemText, MenuItem, MenuList, Paper, Stack, styled } from '@mui/material';
import { Helmet } from 'react-helmet';
import { RouteComponentProps } from 'react-router';
import { NoteEntityType, NoteSource, UserDetails } from 'shared/api/clients';
import { prepareRouterHrefAttrs, Routes } from 'shared/routing';
import { useCompanyContext, useCurrentCanManageOther, useIsAdmin } from 'shared/store';
import { LoaderBig, MuiRouterLink, NavSlim, RooAvatar } from 'components';
import { rooFmt } from 'shared/utils';
import { AccountInfo } from 'pages/UserProfile/ViewProfile/AccountInfo';
import { AddressWidget } from 'components/Profiles/AddressWidget';
import { ContactInfoWidget } from 'components/Profiles/ContactInfoWidget';
import { ForceChangeEmailButton } from 'pages/UserProfile/ViewProfile/ForceChangeEmailButton';
import { UpdateUserHandler } from 'pages/UserProfile/ViewProfile/shared';
import { UserPermissionsInfo } from 'pages/UserProfile/ViewProfile/UserPermissionsInfo';
import { useEffect, useState } from 'react';
import { apiProvider } from '../../../shared/api/apiProvider';
import { produce } from 'immer';
import { NotesWidget } from '../../../components/Profiles/NotesWidget';
import { WhiteBox } from '../../../components/WhiteBox';
import { ImpersonateButton } from '../../../components/ImpersonateButton';

type ProfileSections = 'profile' | 'permissions' | 'account-info';

export const ViewProfile = ({
  match: {
    params: { id: userId, tab = 'profile' }
  }
}: RouteComponentProps<{ id: string; tab: ProfileSections }>) => {
  const { isLoading, userDetails, updateUser } = useGetUser(userId);
  const isAdmin = useIsAdmin();

  const { canManage, companyId } = useCurrentCanManageOther(userDetails);

  const makeTab = (newTab: ProfileSections) => {
    return {
      selected: tab === newTab,
      ...prepareRouterHrefAttrs({ pathname: Routes.UserProfile, params: { id: userId, tab: newTab } })
    };
  };

  if (isLoading) {
    return <LoaderBig />;
  }

  return (
    <>
      <Helmet>
        <title> {rooFmt.fullName(userDetails.user)} - User Profile - Walkthroo</title>
      </Helmet>
      <NavSlim breadcrumbs={[{ text: `${rooFmt.fullName(userDetails.user)} - User Profile` }]} />
      <Grid container p={1} spacing={1}>
        <Grid xs={12} md={4} lg={3}>
          <WhiteBox>
            <MenuList sx={{ pr: 1 }} className="menu-user-profile">
              <MenuItem sx={{ pt: 1, pb: 1 }} {...makeTab('profile')}>
                <ListItemText>Profile</ListItemText>
              </MenuItem>
              {canManage && (
                <MenuItem sx={{ pt: 1, pb: 1 }} {...makeTab('permissions')}>
                  <ListItemText>Permissions</ListItemText>
                </MenuItem>
              )}

              {isAdmin && (
                <MenuItem sx={{ pt: 1, pb: 1 }} {...makeTab('account-info')}>
                  <ListItemText>Account Info</ListItemText>
                </MenuItem>
              )}
            </MenuList>
          </WhiteBox>
        </Grid>
        <Grid xs={12} md={8} lg={9}>
          <WhiteBox sx={{ px: 4 }}>
            {tab === 'profile' && <ProfileSection userDetails={userDetails} onUpdateUser={updateUser} />}
            {tab === 'permissions' && canManage && (
              <UserPermissionsInfo userDetails={userDetails} companyId={companyId} onUpdateUser={updateUser} />
            )}
            {tab === 'account-info' && isAdmin && <AccountInfo userDetails={userDetails} />}
          </WhiteBox>
        </Grid>
      </Grid>
    </>
  );
};

const ProfileSection = ({
  userDetails,
  onUpdateUser
}: {
  userDetails: UserDetails;
  onUpdateUser: UpdateUserHandler;
}) => {
  const isAdmin = useIsAdmin();
  const companyCtx = useCompanyContext();

  return (
    <Stack alignItems={'center'} justifyContent={'center'} p={3} py={7}>
      <Stack sx={{ maxWidth: '1000px', width: '100%' }} alignItems={'center'} justifyContent={'center'} spacing={4}>
        <Stack direction={'row'}>
          <RooAvatar name={rooFmt.fullName(userDetails.user)} avatarUrl={userDetails.user.avatarUrl} size={'xl'} />
          <Box sx={{ ml: 3 }}>
            {userDetails.user.title && <h6>{userDetails.user.title}</h6>}
            <h5 style={{ margin: 0 }}>{rooFmt.fullName(userDetails.user)}</h5>
            {userDetails.vendorInfo != null && (
              <>
                Vendor in{' '}
                <MuiRouterLink
                  target={'_blank'}
                  to={{ pathname: Routes.VendorProfile, params: { vendorId: userDetails.vendorInfo.id } }}
                >
                  {userDetails.vendorInfo.companyName}
                </MuiRouterLink>
              </>
            )}
          </Box>
        </Stack>
        {isAdmin && (
          <ImpersonateButton
            account={{
              userId: userDetails.user.id,
              firstName: userDetails.user.firstName,
              lastName: userDetails.user.lastName
            }}
            sendToLanding={true}
          />
        )}

        <Stack direction={'row'} justifyContent={'space-between'}>
          <ContactInfoWidget canEdit={false} name={null} nameLabel={null} contactInfo={userDetails.user.contactInfo} />
          <AddressWidget canEdit={false} address={userDetails.user.address} />
        </Stack>

        {companyCtx != null && (
          <NotesWidget
            entityId={userDetails.user.id}
            entityType={NoteEntityType.CompanyMember}
            noteSource={NoteSource.UserProfile}
          />
        )}

        <Grid container direction="row" justifyContent="flex-end" alignItems="center">
          <ForceChangeEmailButton targetUser={userDetails} onUpdateUser={onUpdateUser} />
        </Grid>
      </Stack>
    </Stack>
  );
};

export const useGetUser = (
  userId: string
): {
  isLoading: boolean;
  userDetails: UserDetails;
  updateUser: UpdateUserHandler;
} => {
  const [{ isLoading, user }, setState] = useState<{ user: UserDetails; isLoading: boolean }>({
    isLoading: true,
    user: null
  });
  useEffect(() => {
    const load = async () => {
      try {
        const user = await apiProvider.usersClient.getDetails(userId);
        setState({ user: user, isLoading: false });
      } catch (err) {
        setState({ user: null, isLoading: false });
      }
    };

    void load();
  }, [userId]);

  return {
    isLoading,
    userDetails: user,
    updateUser: (fn: (user: UserDetails) => void) => {
      setState({
        isLoading,
        user: produce(fn)(user)
      });
    }
  };
};
