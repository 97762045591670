import { BidStatus } from 'shared/api/clients';
import { BasicEnumDisplay, ColoredEnumDisplay, createMap, EnumMap } from 'shared/store/enums/shared';

const InternalMapBidStatus: EnumMap<typeof BidStatus, ColoredEnumDisplay> = {
  Accepted: {
    display: 'Accepted',
    color: 'success'
  },
  PendingReviewPM: {
    display: 'Pending Review - PM',
    color: 'warning'
  },
  PendingReviewOwner: {
    display: 'Pending Review - Owner',
    color: 'warning'
  },
  Rejected: {
    display: 'Rejected',
    color: 'danger'
  },
  Requested: {
    display: 'Requested',
    color: 'primary'
  },
  Scheduled: {
    display: 'Scheduled',
    color: 'primary'
  },
  ProposedSchedule: {
    display: 'Proposed Schedule',
    color: 'warning'
  },
  Declined: {
    display: 'Declined',
    color: 'danger'
  },
  Uninvited: {
    display: 'Uninvited',
    color: 'danger'
  },
  RevisionPendingVendorReview: {
    display: 'Pending Review - Vendor',
    color: 'warning'
  },
  Revised: {
    display: 'Revised',
    color: 'secondary'
  },
  ChangeRequestPendingVendorReview: {
    display: 'Pending Review - Vendor',
    color: 'warning'
  },
  ChangesRequested: {
    display: 'Changes Requested',
    color: 'warning'
  },
  ChangeRequestDeclined: {
    display: 'Changes Declined',
    color: 'danger'
  },
  Edited: {
    display: 'Edited',
    color: 'secondary'
  },
  SkippedBidding: {
    display: 'Skipped Bidding',
    color: 'secondary'
  }
};

export const EnumMapBidStatus = createMap<BidStatus, ColoredEnumDisplay>(BidStatus, InternalMapBidStatus);
