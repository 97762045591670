import React, { useState } from 'react';
import {
  Card,
  Checkbox,
  Chip,
  Divider,
  Grid,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Popover,
  Stack,
  TextField
} from '@mui/material';
import { ManagementCompany } from '../../shared/api/clients';
import { useMyCompanies } from '../../shared/api/queries';
import { useStateOptions } from '../../shared/store';
import { rooFmt } from '../../shared/utils';
import { MuiIcon } from '../../shared/icons';

export const StateCompanyFilter = ({
  value,
  setValue
}: {
  value: ManagementCompany[];
  setValue: (val: ManagementCompany[]) => void;
}) => {
  const { isLoading, data } = useMyCompanies();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const valueCount = (value ?? []).length;
  const label = valueCount === 0 ? 'Select Companies...' : rooFmt.counted(valueCount, 'company', 'companies');

  return (
    <div>
      <Chip
        label={label}
        clickable
        onClick={(ev: any) => setAnchorEl(ev.currentTarget)}
        disabled={isLoading}
        size={'medium'}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <SelectorBody companies={data} selectedCompanies={value} setSelectedCompanies={setValue} />
      </Popover>
    </div>
  );
};

const SelectorBody = ({
  selectedCompanies,
  companies,
  setSelectedCompanies
}: {
  selectedCompanies: ManagementCompany[];
  setSelectedCompanies: (val: ManagementCompany[]) => void;
  companies: ManagementCompany[];
}) => {
  const [visibleState, setVisibleState] = useState(null);

  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      <Grid item>
        <StatesList
          selectedCompanies={selectedCompanies}
          setSelectedCompanies={setSelectedCompanies}
          companies={companies}
          visibleState={visibleState}
          setVisibleState={setVisibleState}
        />
      </Grid>
      <Grid item>
        <CompanyList
          selectedCompanies={selectedCompanies}
          setSelectedCompanies={setSelectedCompanies}
          companies={companies}
          visibleState={visibleState}
        />
      </Grid>
    </Grid>
  );
};

const StatesList = ({
  selectedCompanies,
  companies,
  setSelectedCompanies,
  visibleState,
  setVisibleState
}: {
  selectedCompanies: ManagementCompany[];
  setSelectedCompanies: (val: ManagementCompany[]) => void;
  companies: ManagementCompany[];
  visibleState: string;
  setVisibleState: (code: string) => void;
}) => {
  const states = useStateOptions();
  const stateOptions = [{ value: null, label: 'All States' }, ...states];

  const selectedByState = (stateCode: string) => {
    return selectedCompanies.filter((x) => x.address.state === stateCode || stateCode == null).length;
  };

  return (
    <Card>
      <List
        sx={{
          width: 250,
          height: 350,
          bgcolor: 'background.paper',
          overflow: 'auto'
        }}
        dense
        component="div"
        role="list"
      >
        {stateOptions.map((state) => {
          const selectedCount = selectedByState(state.value);
          const availableCompanies = companies.filter((x) => x.address.state === state.value || state.value == null);
          const availableCount = availableCompanies.length;
          if (availableCount === 0 && state.value != null) {
            return null;
          }

          const isChecked = selectedCount === availableCount;
          const isIndeterminate = selectedCount > 0 && selectedCount < availableCount;

          const onSelect = () => {
            const baseValues = selectedCompanies.filter((x) => availableCompanies.find((y) => x.id === y.id) == null);
            // unchecked or indeterminate, go to full
            if (selectedCount < availableCount) {
              setSelectedCompanies([...baseValues, ...availableCompanies]);
              return;
            }

            // checked, go to unchecked
            setSelectedCompanies(baseValues);
          };

          const isActive = visibleState === state.value;

          return (
            <ListItem
              sx={{
                backgroundColor: isActive ? '#eee' : undefined
              }}
              key={state.value}
              role="listitem"
              button
              onClick={() => setVisibleState(state.value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={isChecked}
                  indeterminate={isIndeterminate}
                  disabled={availableCount === 0}
                  tabIndex={-1}
                  onClick={(e) => {
                    e.stopPropagation();
                    onSelect();
                  }}
                  disableRipple
                />
              </ListItemIcon>
              <ListItemText primary={state.label} />
            </ListItem>
          );
        })}
      </List>
    </Card>
  );
};

const CompanyList = ({
  selectedCompanies,
  setSelectedCompanies,
  companies,
  visibleState
}: {
  selectedCompanies: ManagementCompany[];
  setSelectedCompanies: (val: ManagementCompany[]) => void;
  companies: ManagementCompany[];
  visibleState: string;
}) => {
  const [searchState, setSearchState] = useState('');
  const visibleCompanies = companies
    .filter((x) => x.address.state === visibleState || visibleState == null)
    .filter((x) => {
      if (searchState === '') {
        return true;
      }

      const normalizedSearch = searchState.toLowerCase();
      const strings = [x.name?.toLowerCase()];
      for (const str of strings) {
        if (str.indexOf(normalizedSearch) > -1) {
          return true;
        }
      }

      return false;
    });

  return (
    <Card>
      <Stack>
        <TextField
          size={'small'}
          sx={{ p: 1 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position={'start'}>
                <MuiIcon.Search />
              </InputAdornment>
            )
          }}
          value={searchState}
          onChange={(e) => setSearchState(e.target.value)}
        />
      </Stack>
      <Divider />
      <List
        sx={{
          width: 350,
          height: 350 - 56,
          bgcolor: 'background.paper',
          overflow: 'auto'
        }}
        dense
        component="div"
        role="list"
      >
        {visibleCompanies.map((company) => {
          const isSelected = selectedCompanies.find((x) => x.id === company.id) != null;
          return (
            <ListItem
              key={company.id}
              role="listitem"
              button
              onClick={() => {
                const copy = [...selectedCompanies];
                if (isSelected) {
                  const idx = copy.findIndex((x) => x.id === company.id);
                  if (idx > -1) {
                    copy.splice(idx, 1);
                  }
                } else {
                  copy.push(company);
                }

                setSelectedCompanies(copy);
              }}
            >
              <ListItemIcon>
                <Checkbox checked={isSelected} tabIndex={-1} disableRipple />
              </ListItemIcon>
              <ListItemText primary={company.name} />
            </ListItem>
          );
        })}
      </List>
    </Card>
  );
};
