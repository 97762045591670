import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { apiProvider } from '../../../shared/api/apiProvider';
import { Helmet } from 'react-helmet';
import { ConfirmationModal, ContentWrapper, NavSlim, RouterLink } from '../../../components';
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from '@mui/material';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Routes } from '../../../shared/routing';
import React, { useMemo, useState } from 'react';
import { AdminVendorView, CapabilityType, ToggleVendorIsGcPayload, UserAccount } from '@roo/api';
import { Disclosure, useDisclosure } from '@roo/lib';

type YesNoFilter = 'yes' | 'no' | 'all';

const stringMatch = (needle: string, haystack: string) => {
  if (needle === '') {
    return true;
  }

  if (haystack == null || haystack === '') {
    return false;
  }

  return haystack.toUpperCase().indexOf(needle.toUpperCase()) >= 0;
};

const applyFilters = (
  filterVals: {
    companyName: string;
    isGc: YesNoFilter;
  },
  vendors: AdminVendorView[]
) => {
  const { companyName, isGc } = filterVals;
  return vendors.filter((x) => {
    if (!stringMatch(companyName, x.companyName)) {
      return false;
    }

    if (isGc !== 'all') {
      return (x.isGeneralContractor && isGc === 'yes') || (!x.isGeneralContractor && isGc === 'no');
    }

    return true;
  });
};

export const AdminVendorList = () => {
  const { isLoading, data: companies } = useQuery(['companies', 'vendor-list'], () =>
    apiProvider.adminClient.listVendors()
  );

  const [companyName, setCompanyName] = useState<string>('');
  const [isGc, setIsGc] = useState<YesNoFilter>('all');

  const filteredData = useMemo(() => {
    if (companies == null || companies.length === 0) {
      return [];
    }

    return applyFilters({ isGc, companyName }, companies);
  }, [companies, isGc, companyName]);

  return (
    <>
      <Helmet>
        <title>Vendor List - Walkthroo</title>
      </Helmet>
      <NavSlim breadcrumbs={[{ text: 'Vendor List' }]} />
      <ContentWrapper>
        <Stack spacing={3}>
          <Card>
            <CardContent>
              <Typography variant={'h5'}>Filters</Typography>
              <Grid container spacing={2} sx={{ marginTop: '8px' }}>
                <Grid item>
                  <TextField
                    size={'small'}
                    label={'Name'}
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                  />
                </Grid>

                <Grid item>
                  <ToggleButtonGroup
                    color="primary"
                    value={isGc}
                    exclusive
                    size={'small'}
                    onChange={(_, val) => setIsGc(val)}
                    aria-label="Is GC?"
                  >
                    <ToggleButton value="all">All</ToggleButton>
                    <ToggleButton value="yes">GC</ToggleButton>
                    <ToggleButton value="no">Not GC</ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <DataTable
            tableStyle={{ width: '100%' }}
            value={filteredData}
            loading={isLoading}
            paginator
            rows={12}
            sortField={'createdAt'}
            sortOrder={-1}
          >
            <Column
              field={'name'}
              header={'Name'}
              sortable
              body={(row: AdminVendorView) => (
                <RouterLink target={'_blank'} to={{ pathname: Routes.VendorProfile, params: { vendorId: row.id } }}>
                  {row.companyName}
                </RouterLink>
              )}
            />
            <Column
              field={'isGeneralContractor'}
              header={'Is GC'}
              sortable
              body={(row: AdminVendorView) => (row.isGeneralContractor ? 'Yes' : 'No')}
            />
            <Column
              field={'managedClients'}
              header={'Clients'}
              sortable
              body={(row: AdminVendorView) => row.managedClients}
            />
            <Column
              field={'managedVendors'}
              header={'Subcontractors'}
              sortable
              body={(row: AdminVendorView) => row.managedVendors}
            />
            <Column header={'Actions'} body={(row: AdminVendorView) => <ToggleGcButton vendor={row} />} />
          </DataTable>
        </Stack>
      </ContentWrapper>
    </>
  );
};

const ToggleGcButton = ({ vendor }: { vendor: AdminVendorView }) => {
  const enableModal = useDisclosure(false);
  const disableModal = useDisclosure(false);

  return (
    <>
      <Button color={'warning'} onClick={vendor.isGeneralContractor ? disableModal.open : enableModal.open}>
        Toggle GC
      </Button>
      <EnableGcModal row={vendor} disclosure={enableModal} />
      <DisableGcModal row={vendor} disclosure={disableModal} />
    </>
  );
};

export const EnableGcModal = ({ row, disclosure }: { row: AdminVendorView; disclosure: Disclosure }) => {
  return (
    <ToggleGcModal
      row={row}
      disclosure={disclosure}
      value={true}
      question={'Are you sure you want to turn this vendor into a General Contractor?'}
    />
  );
};

export const DisableGcModal = ({ row, disclosure }: { row: AdminVendorView; disclosure: Disclosure }) => {
  return (
    <ToggleGcModal
      row={row}
      disclosure={disclosure}
      value={false}
      question={'Are you sure you want to turn this vendor back into a Vendor?'}
    />
  );
};

export const ToggleGcModal = ({
  row,
  disclosure,
  value,
  question
}: {
  row: AdminVendorView;
  disclosure: Disclosure;
  value: boolean;
  question: string;
}) => {
  const qc = useQueryClient();
  const { mutate, isLoading } = useMutation({
    mutationFn: () =>
      apiProvider.adminClient.toggleVendorIsGc(
        new ToggleVendorIsGcPayload({
          vendorId: row.id,
          value
        })
      ),
    onSuccess: async () => {
      await qc.invalidateQueries();
      disclosure.close();
    }
  });
  return (
    <ConfirmationModal
      question={question}
      visible={disclosure.isOpen}
      onClose={disclosure.close}
      onSave={mutate}
      running={isLoading}
    />
  );
};
