import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

export interface ModalStore<TPayload = never> {
  visible: boolean;
  payload: TPayload;
  actions: {
    show: (...payload: [TPayload] extends [never] ? [] : [TPayload]) => void;
    hide: () => void;
  };
}

export const createModalStore = <TPayload = never>() => {
  return create<ModalStore<TPayload>>()(
    immer((set, get) => ({
      visible: false,
      payload: null as TPayload,
      actions: {
        show: (...payload: [TPayload] extends [never] ? [] : [TPayload]) => {
          set((curr) => {
            curr.visible = true;
            if (payload != null) {
              // @ts-ignore
              curr.payload = payload[0];
            }
          });
        },
        hide: () => {
          set((curr) => {
            curr.visible = false;
          });
        }
      }
    }))
  );
};
