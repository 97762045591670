import { Button, CircularProgress, Divider, Paper, Stack } from '@mui/material';
import { Box } from '@mui/system';
import { RooIcon } from 'shared/icons';
import { ReactNode, useEffect, useState } from 'react';
import { Col, Container, FormGroup, Row } from 'react-bootstrap';
import { Control, useFieldArray } from 'react-hook-form';
import { FormState, UseFormSetValue, UseFormWatch } from 'react-hook-form/dist/types/form';
import { RooPermission, User } from 'shared/api/clients';
import { RouteDestination } from 'shared/routing';
import {
  DropdownOption,
  useCurrentUser,
  useDropdownValues,
  useHasPermission,
  useIsGeneralContractor,
  useStateOptions
} from 'shared/store';
import { RouterButton, SaveButtonMui } from 'components';
import { FormDefinition } from 'pages/Properties/forms';
import { FieldDatePicker, FieldNumber, FieldSwitch, FieldText, FieldSingleSelect } from 'components/form';
import { apiProvider } from '../../shared/api/apiProvider';
import { ReactSelectCommonStyles } from '../../components/RooSelect';
import Grid2 from '@mui/material/Unstable_Grid2';
import { useQuery } from '@tanstack/react-query';
import { usePrevious } from '@roo/lib';

type WithControl = { control: Control<FormDefinition>; isEditing: boolean };
type WithFormState = { formState: FormState<FormDefinition> };
type WithSetValue = { setValue: UseFormSetValue<FormDefinition>; watch: UseFormWatch<FormDefinition> };

export const PropertyFormDefaultButtons = ({
  control,
  returnUrl
}: {
  returnUrl: RouteDestination;
  control: Control<FormDefinition>;
}) => {
  return (
    <Stack
      spacing={{
        xs: 3,
        md: 5
      }}
      mt={3}
      direction={{
        xs: 'column-reverse',
        md: 'row'
      }}
      justifyContent={{
        xs: 'center',
        md: 'flex-end'
      }}
    >
      <RouterButton size={'large'} color="muted" sx={{ width: { xs: '100%', lg: 200 } }} to={returnUrl}>
        Cancel
      </RouterButton>
      <SaveButtonMui size={'large'} control={control} sx={{ width: { xs: '100%', lg: 200 } }}>
        Save
      </SaveButtonMui>
    </Stack>
  );
};

export const PropertyForm = ({
  control,
  formState,
  isEditing,
  setValue,
  watch,
  renderButtons
}: WithControl &
  WithFormState &
  WithSetValue & { renderButtons: ({ control }: { control: Control<FormDefinition> }) => ReactNode }) => {
  return (
    <>
      <Paper elevation={0} sx={{ p: 3 }}>
        <h6 className="text-black">
          <strong>General</strong>
        </h6>
        <Divider sx={{ mb: 2 }} />
        <GeneralSection control={control} isEditing={isEditing} setValue={setValue} watch={watch} />

        <h6 className="text-black mt-2">
          <strong>Owner</strong>
        </h6>
        <Divider sx={{ mb: 2 }} />
        <OwnerSection control={control} isEditing={isEditing} setValue={setValue} watch={watch} />

        <h6 className="text-black mt-2">
          <strong>Address</strong>
        </h6>
        <Divider sx={{ mb: 2 }} />
        <AddressSection control={control} isEditing={isEditing} />

        <h6 className="text-black mt-2">
          <strong>Details</strong>
        </h6>
        <Divider sx={{ mb: 2 }} />
        <DetailsSection control={control} isEditing={isEditing} />

        <h6 className="text-black mt-2">
          <strong>Tenants</strong>
        </h6>
        <Divider sx={{ mb: 2 }} />
        <TenantsList control={control} isEditing={isEditing} />
        {renderButtons({ control })}
      </Paper>
    </>
  );
};

const GeneralSection = ({ control, isEditing, watch, setValue }: WithControl & WithSetValue) => {
  const isGc = useIsGeneralContractor();
  return (
    <Grid2 container spacing={2}>
      <Grid2 xl={9} xs={12}>
        <FieldText
          control={control}
          name={'name'}
          label={'Name'}
          placeholder={'Property Name'}
          className="Input-field"
        />
      </Grid2>
      <Grid2 xl={2} sm={12}>
        <FieldSwitch
          groupProps={{ style: { paddingTop: '35px' } }}
          control={control}
          name={'isOccupied'}
          label={'Occupied'}
        />
      </Grid2>
      <Grid2 xl={3} xs={12}>
        <FieldNumber
          control={control}
          name={'maintenanceLimit'}
          required
          label={'Maintenance Limit'}
          placeholder={'Maintenance Limit ($)'}
          className="Input-field"
        />
      </Grid2>
      {isGc && (
        <Grid2 xl={3} xs={12}>
          <ManagementCompanyField control={control} isEditing={isEditing} setValue={setValue} watch={watch} />
        </Grid2>
      )}

      <Grid2 xl={3} xs={12}>
        <ManagerField control={control} isEditing={isEditing} setValue={setValue} watch={watch} />
      </Grid2>
    </Grid2>
  );
};

const ManagementCompanyField = ({ control, setValue, watch, isEditing }: WithControl & WithSetValue) => {
  const isGc = useIsGeneralContractor();
  const { data, isLoading } = useQuery(
    ['user', 'managedCompanies'],
    () => apiProvider.usersClient.getManagedCompanies(),
    {
      enabled: isGc,
      select: (data) => (data ?? []).map((x) => ({ label: x.name, value: x.id }))
    }
  );

  if (!isGc) {
    return null;
  }

  return (
    <FieldSingleSelect
      control={control}
      name={'managementCompanyId'}
      options={data}
      isDisabled={isLoading || isEditing}
      isClearable
      label={'Management Company'}
      styles={ReactSelectCommonStyles.MuiLookAlike}
    />
  );
};

const ManagerField = ({ control, setValue, watch }: WithControl & WithSetValue) => {
  const companyId = watch('managementCompanyId');
  const prevCompanyId = usePrevious(companyId);
  const { data, isLoading } = useQuery(
    ['users', 'getManagers', companyId],
    () => apiProvider.usersClient.getManagers(companyId),
    {
      enabled: companyId != null,
      select: (data) => (data ?? []).map((x) => ({ label: x.fullName, value: x.id }) satisfies DropdownOption<string>)
    }
  );

  useEffect(() => {
    if (prevCompanyId != null && companyId !== prevCompanyId) {
      setValue('managerId', null);
    }
  }, [companyId, prevCompanyId]);

  return (
    <FieldSingleSelect
      control={control}
      name={'managerId'}
      options={data}
      isDisabled={isLoading || companyId == null}
      isClearable
      label={'Property Manager'}
      styles={ReactSelectCommonStyles.MuiLookAlike}
    />
  );
};

const OwnerSection = ({ control, isEditing, setValue, watch }: WithControl & WithSetValue) => {
  const userId = watch('owner.userId');
  const owner = watch('owner');
  const [dummy, setDummy] = useState(1);
  const hasOwner = owner != null;
  const hasSavedOwner = !!userId;
  const currentUser = useCurrentUser();

  return (
    <>
      {hasOwner && (
        <fieldset disabled={hasSavedOwner}>
          <Stack
            style={{ backgroundColor: '#F7F8FC' }}
            direction={{ default: 'column', md: 'row' }}
            alignItems={'center'}
            justifyContent={'center'}
            padding={2}
            spacing={4}
          >
            <Grid2 container spacing={2} flexGrow={1}>
              <Grid2 md={6} xs={12}>
                <FieldText
                  control={control}
                  name={'owner.firstName'}
                  required
                  label={'First Name'}
                  placeholder={'Owner First Name'}
                  className="Input-field Input-field-white"
                />
              </Grid2>
              <Grid2 md={6} xs={12}>
                <FieldText
                  control={control}
                  name={'owner.lastName'}
                  required
                  label={'Last Name'}
                  placeholder={'Owner Last Name'}
                  className="Input-field Input-field-white"
                />
              </Grid2>
              <Grid2 md={6} xs={12}>
                <FieldText
                  control={control}
                  name={'owner.email'}
                  required
                  label={'Email'}
                  placeholder={'Owner Email'}
                  className="Input-field Input-field-white"
                />
              </Grid2>
              <Grid2 md={6} xs={12}>
                <FieldText
                  control={control}
                  name={'owner.phone'}
                  required
                  label={'Phone'}
                  placeholder={'Owner Phone'}
                  className="Input-field Input-field-white"
                />
              </Grid2>
            </Grid2>
            <Button
              variant="contained"
              color="error"
              className="Button"
              onClick={() => {
                setValue('owner', null, { shouldValidate: true });
                setDummy(dummy + 1);
              }}
            >
              <RooIcon icon={['fas', 'times']} />
              <Box sx={{ display: { xs: 'inline-block', md: 'none' } }}>
                &nbsp;<span>Remove owner</span>
              </Box>
            </Button>
          </Stack>
        </fieldset>
      )}
      {!hasOwner && (
        <div className="mb-3">
          <Button
            size={'large'}
            onClick={() => {
              setValue(
                'owner',
                { firstName: null, lastName: null, phone: null, email: null, userId: null },
                { shouldValidate: true }
              );
              setDummy(dummy + 1);
            }}
          >
            <RooIcon icon={['fas', 'plus']} />
            &nbsp;&nbsp; Add Owner
          </Button>
        </div>
      )}
    </>
  );
};

const AddressSection = ({ control }: WithControl) => {
  const stateOptions = useStateOptions();
  return (
    <Grid2 container spacing={2}>
      <Grid2 xl={6} xs={12}>
        <FieldText
          control={control}
          name={'address.address1'}
          required
          label={'Address 1'}
          placeholder={'Address 1'}
          className="Input-field"
        />
      </Grid2>
      <Grid2 xl={6} xs={12}>
        <FieldText
          control={control}
          name={'address.address2'}
          label={'Address 2'}
          placeholder={'Address 2'}
          className="Input-field"
        />
      </Grid2>
      <Grid2 xl={4} xs={12}>
        <FieldText
          control={control}
          name={'address.city'}
          required
          label={'City'}
          placeholder={'City'}
          className="Input-field"
        />
      </Grid2>
      <Grid2 xl={4} xs={12}>
        <FieldSingleSelect
          control={control}
          options={stateOptions}
          name={'address.state'}
          required
          label={'State'}
          styles={ReactSelectCommonStyles.MuiLookAlike}
        />
      </Grid2>
      <Grid2 xl={4} xs={12}>
        <FieldText
          control={control}
          name={'address.zipCode'}
          required
          label={'Zip Code'}
          placeholder={'Zip Code'}
          className="Input-field"
        />
      </Grid2>
    </Grid2>
  );
};

const DetailsSection = ({ control }: WithControl) => {
  return (
    <Grid2 container spacing={2}>
      <Grid2 xl={4} xs={12}>
        <FieldText
          control={control}
          name={'gateCode'}
          label={'Gate Code'}
          placeholder={'Gate Code'}
          className="Input-field"
        />
      </Grid2>
      <Grid2 xl={4} xs={12}>
        <FieldNumber
          control={control}
          step={1.0}
          name={'bedroomNo'}
          label={'Bedroom Number'}
          placeholder={'How many bedrooms?'}
          className="Input-field"
        />
      </Grid2>
      <Grid2 xl={4} xs={12}>
        <FieldNumber
          control={control}
          step={1.0}
          name={'bathroomNo'}
          label={'Bathroom Number'}
          placeholder={'How many bathrooms?'}
          className="Input-field"
        />
      </Grid2>
      <Grid2 xl={4} xs={12}>
        <FieldNumber
          control={control}
          name={'propertyArea'}
          label={'Property Area'}
          placeholder={'Property Area (sq ft)'}
          className="Input-field"
        />
      </Grid2>
      <Grid2 xl={4} xs={12}>
        <FieldNumber
          control={control}
          name={'lotSize'}
          label={'Lot Size'}
          placeholder={'Lot Size (sq ft)'}
          className="Input-field"
        />
      </Grid2>
      <Grid2 xl={4} xs={12}>
        <FieldSwitch
          groupProps={{ style: { paddingTop: '35px' } }}
          control={control}
          name={'hasPool'}
          label={'Has Pool'}
        />
      </Grid2>
    </Grid2>
  );
};

const TenantsList = ({ control }: WithControl) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'tenants'
  });

  const addTenant = () => {
    append(
      {
        email: null,
        phone: null,
        userId: null,
        lastName: null,
        firstName: null,
        moveInDate: null,
        moveOutDate: null
      },
      {
        shouldFocus: false
      }
    );
  };

  return (
    <Stack spacing={4} alignItems={'center'}>
      {fields.map((tenant, index) => (
        <Stack spacing={4} direction={{ default: 'column', md: 'row' }} alignItems={'center'}>
          <Grid2 container spacing={2} style={{ backgroundColor: '#F7F8FC' }} padding={2}>
            <Grid2 xl={6} xs={12}>
              <fieldset disabled={tenant.userId != null}>
                <FieldText
                  control={control}
                  name={`tenants.${index}.firstName` as const}
                  required
                  label={'First Name'}
                  placeholder={'Tenant First Name'}
                  className="Input-field Input-field-white"
                />
              </fieldset>
            </Grid2>
            <Grid2 xl={6} xs={12}>
              <fieldset disabled={tenant.userId != null}>
                <FieldText
                  control={control}
                  name={`tenants.${index}.lastName` as const}
                  required
                  label={'Last Name'}
                  placeholder={'Tenant Last Name'}
                  className="Input-field Input-field-white"
                />
              </fieldset>
            </Grid2>
            <Grid2 xl={3} xs={12}>
              <fieldset disabled={tenant.userId != null}>
                <FieldText
                  control={control}
                  name={`tenants.${index}.email` as const}
                  label={'Email'}
                  placeholder={'Tenant Email'}
                  className="Input-field Input-field-white"
                />
              </fieldset>
            </Grid2>
            <Grid2 xl={3} xs={12}>
              <fieldset disabled={tenant.userId != null}>
                <FieldText
                  control={control}
                  name={`tenants.${index}.phone` as const}
                  required
                  label={'Phone'}
                  placeholder={'Tenant Phone'}
                  className="Input-field Input-field-white"
                />
              </fieldset>
            </Grid2>
            <Grid2 xl={3} xs={12}>
              <FieldDatePicker
                control={control}
                name={`tenants.${index}.moveInDate` as const}
                label={'Move In Date'}
                placeholderText={'Move In Date'}
                className="Input-field Input-field-white"
              />
            </Grid2>
            <Grid2 xl={3} xs={12}>
              <FieldDatePicker
                control={control}
                name={`tenants.${index}.moveOutDate` as const}
                label={'Move Out Date'}
                placeholderText={'Move Out Date'}
                className="Input-field Input-field-white"
              />
            </Grid2>
          </Grid2>
          <Button
            size="large"
            color="error"
            onClick={() => {
              remove(index);
            }}
          >
            <RooIcon icon={['fas', 'times']} />
            <Box sx={{ display: { xs: 'inline-block', lg: 'none' } }}>
              &nbsp;<span>Remove tenant {index + 1}</span>
            </Box>
          </Button>
        </Stack>
      ))}
      <Button size={'large'} onClick={() => addTenant()}>
        <RooIcon icon={['fas', 'plus']} />
        &nbsp;&nbsp; Add Tenant
      </Button>
    </Stack>
  );
};
