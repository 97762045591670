import { Alert } from 'react-bootstrap';

export const LegacyNotice = ({
  item: { isLegacy },
  type
}: {
  item: { isLegacy?: boolean };
  type: 'workorder' | 'issue';
}) => {
  if (!(isLegacy ?? false)) {
    return null;
  }

  return (
    <Alert variant={'warning'}>
      <Alert.Heading>This is a legacy {type}</Alert.Heading>
      <p>It may be of lesser importance or no longer relevant.</p>
    </Alert>
  );
};
