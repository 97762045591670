import { Typography } from '@mui/material';
import { RenderCount } from 'pages/Dev/RenderCount';
import { Control, FieldValues, useFormState } from 'react-hook-form';

export const DebugFormStatus = <TFieldValues extends FieldValues = FieldValues>({
  control
}: {
  control: Control<TFieldValues>;
}) => {
  const {
    errors,
    isDirty,
    isSubmitSuccessful,
    isSubmitted,
    isSubmitting,
    isValid,
    isValidating,
    submitCount,
    touchedFields,
    dirtyFields
  } = useFormState({
    control: control
  });

  const copy = {
    errors,
    isDirty,
    isSubmitSuccessful,
    isSubmitted,
    isSubmitting,
    isValid,
    isValidating,
    submitCount,
    touchedFields,
    dirtyFields
  };
  return (
    <>
      <Typography variant={'h4'}>
        <h4>Status</h4>
      </Typography>
      <div style={{ position: 'relative' }}>
        <RenderCount />
        <pre>{JSON.stringify(copy, null, 2)}</pre>
      </div>
    </>
  );
};
