import React, { useEffect, useState } from 'react';
import { AddNotePayload, NoteEntityType, NoteSource } from 'shared/api/clients';
import { RooButton, RooDialog } from 'components';
import { showSuccess } from '../../../shared/utils';
import { useAddNote } from '../../../shared/api/queries';

export const AddNoteModal = ({
  visible,
  entityId,
  entityType,
  onCloseRequest
}: {
  visible: boolean;
  entityId: string;
  entityType: NoteEntityType;
  onCloseRequest: () => void;
}) => {
  const [noteText, setNoteText] = useState<string>('');
  const hasText = noteText.trim() !== '';
  const mutation = useAddNote(() => {
    onCloseRequest();
    showSuccess();
  });

  useEffect(() => {
    setNoteText('');
  }, [visible]);

  return (
    <RooDialog open={visible} onClose={onCloseRequest} maxWidth={'sm'} fullWidth>
      <RooDialog.Title onClose={onCloseRequest}>{'New note'}</RooDialog.Title>
      <RooDialog.Content>
        <div className={'mt-4 form-group'}>
          <textarea
            value={noteText}
            onChange={(e) => setNoteText(e.target.value)}
            className={'form-control'}
            id={'notes-input'}
            rows={3}
          />
        </div>
      </RooDialog.Content>
      <RooDialog.Actions>
        <RooButton
          disabled={mutation.isLoading || !hasText}
          onClick={() =>
            mutation.mutate(
              new AddNotePayload({
                entityId,
                entityType,
                note: noteText,
                noteSource: NoteSource.MainPage
              })
            )
          }
          variant={'success'}
        >
          Add
        </RooButton>
      </RooDialog.Actions>
    </RooDialog>
  );
};
