import { Variant } from 'react-bootstrap/types';

export type BasicEnumDisplay = { display: string };
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export type ColoredEnumDisplay = BasicEnumDisplay & { color: Variant };

export type EnumMap<T, TVal extends BasicEnumDisplay> = { [key in keyof T]: TVal };

export const createMap = <TEnum extends any, TVal extends BasicEnumDisplay>(enumVal: any, internalMap: any) => {
  const map = new Map<TEnum, TVal>();
  for (const key of Object.keys(internalMap)) {
    //@ts-ignore
    map.set(enumVal[key], internalMap[key]);
  }
  return map;
};

export class BasicEnumAccessor<T> {
  constructor(private map: Map<T, BasicEnumDisplay>) {}

  private get = (val: T) => {
    const data = this.map.get(val);
    if (data == null) {
      throw new Error(`Can't find map for ${val}`);
    }
    return data;
  };

  display = (val: T) => {
    const data = this.get(val);
    return data.display;
  };
}

export class ColoredEnumAccessor<T> {
  constructor(private map: Map<T, ColoredEnumDisplay>) {}

  private get = (val: T) => {
    const data = this.map.get(val);
    if (data == null) {
      throw new Error(`Can't find map for ${val}`);
    }
    return data;
  };

  display = (val: T) => {
    const data = this.get(val);
    return data.display;
  };

  color = (val: T) => {
    const data = this.get(val);
    return data.color;
  };
}
