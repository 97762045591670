import { Button, Container, Grid, Stack, Typography } from '@mui/material';

export const DevButtonsDemoPage = () => {
  const variants = ['contained', 'outlined', 'text', 'outlined'] as const;
  const colors = ['inherit', 'primary', 'secondary', 'muted', 'success', 'error', 'info', 'warning'] as const;
  const sizes = ['small', 'medium', 'large', 'largest'] as const;

  return (
    <Container maxWidth="lg" sx={{ backgroundColor: 'white', p: 4 }}>
      <Stack spacing={4}>
        {variants.map((variant) => (
          <div key={variant}>
            <Typography variant="h4" gutterBottom>
              Variant: {variant}
            </Typography>
            <Stack spacing={2}>
              {sizes.map((size) => (
                <div key={`${variant}-${size}`}>
                  <Typography variant="h6" gutterBottom>
                    Size: {size}
                  </Typography>
                  <Grid container spacing={2}>
                    {colors.map((color) => (
                      <Grid item key={`${variant}-${color}-${size}`}>
                        <Button variant={variant} color={color} size={size}>
                          {color}
                        </Button>
                      </Grid>
                    ))}
                  </Grid>
                </div>
              ))}
            </Stack>
          </div>
        ))}
      </Stack>
    </Container>
  );
};
