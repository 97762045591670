import { Chip, Divider, Paper, Stack, Typography } from '@mui/material';
import * as React from 'react';
import { rooEnum } from '../../shared/store';
import { apiProvider } from '../../shared/api/apiProvider';
import { RooIcon } from '../../shared/icons';
import { IconLink, RouterLink, UserCard } from '../../components';
import { Routes } from '../../shared/routing';
import { VendorCard } from '../../components/VendorCard';
import { FileThumbnail } from '../../components/Files/FileThumbnail';
import { IInspectionDetails, IPropertySummary } from '@roo/api';
import { MapButton } from '../../components/MapButton';
import { rooFmt } from '../../shared/utils';
import Grid2 from '@mui/material/Unstable_Grid2';

export const SummaryHeading = ({ inspection }: { inspection: IInspectionDetails }) => {
  return (
    <Stack px={3} spacing={2}>
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={3} alignItems={'center'}>
        <Stack direction={'row'} spacing={2} alignItems={'center'}>
          <Typography fontWeight={600}>Status</Typography>
          <Chip
            label={rooEnum.inspectionStatus.display(inspection.status)}
            // @ts-ignore
            color={rooEnum.inspectionStatus.color(inspection.status)}
          />
        </Stack>
        <Stack direction={'row'} spacing={2}>
          <Typography fontWeight={600}>Due Date</Typography>
          <Typography>{rooFmt.dateMaybe(inspection.dueDate, 'N/A')}</Typography>
        </Stack>
        {inspection.lastRenderedPdf != null && (
          <Stack direction={'row'} spacing={2}>
            <Typography fontWeight={600}>PDF</Typography>
            <a target={'_blank'} href={apiProvider.fileUrls.download(inspection.lastRenderedPdf.id)} rel="noreferrer">
              <RooIcon icon={'download'} /> Download
            </a>
          </Stack>
        )}

        {inspection.linkedIssueId != null && inspection.linkedWorkorderId != null && (
          <Stack direction={'row'} spacing={2}>
            <Typography fontWeight={600}>Created Issue</Typography>
            <RouterLink
              to={{
                pathname: Routes.IssueView,
                params: { issueId: inspection.linkedIssueId, workorderId: inspection.linkedWorkorderId }
              }}
            >
              View
            </RouterLink>
          </Stack>
        )}
      </Stack>
      <Grid2 container sx={{ p: 2 }} spacing={3}>
        <Grid2 md={6} xs={12}>
          <Stack spacing={1}>
            <Typography fontWeight={600}>Property</Typography>
            <PropertyInfo property={inspection.property} />
          </Stack>
        </Grid2>
        <Grid2 md={6} xs={12} display={'flex'} justifyContent={{ xs: 'start', md: 'center' }} alignItems={'center'}>
          <Stack spacing={1}>
            <Typography fontWeight={600}>Assignee</Typography>
            {inspection.assignedVendor != null && <VendorCard vendor={inspection.assignedVendor} />}
            {inspection.assignedUser != null && <UserCard user={inspection.assignedUser} role={null} />}
          </Stack>
        </Grid2>
      </Grid2>
    </Stack>
  );
};

const PropertyInfo = ({ property }: { property: IPropertySummary }) => {
  return (
    <Stack direction={{ xs: 'column', md: 'row' }} spacing={3}>
      <Stack direction={'row'} spacing={3}>
        <FileThumbnail file={property.coverPhoto} size={96} />
        <Stack>
          <IconLink to={{ pathname: Routes.PropertyView, params: { id: property.id } }} text={property.name} />
          <Typography>{property.address.line1Formatted}</Typography>
          <Typography>{property.address.line2Formatted}</Typography>
          <MapButton propAddress={property.address} />
        </Stack>
      </Stack>

      {/*<Divider sx={{ sm: { display: 'none' } }} flexItem orientation={'vertical'} />*/}
      <Stack direction={'row'} spacing={2}>
        <Stack>
          <Typography>Area</Typography>
          <Typography>Bedrooms</Typography>
          <Typography>Bathrooms</Typography>
        </Stack>
        <Stack>
          <Typography>{property.propertyArea == null ? 'N/A' : `${property.propertyArea} sq ft`}</Typography>
          <Typography>{property.bedroomNumber ?? 'N/A'}</Typography>
          <Typography>{property.bathroomNumber ?? 'N/A'}</Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};
