import { Instant } from 'shared/api/clients';

export const rooDate = {
  makeInstant: (date: Date): Instant => {
    if (date == null) {
      return null;
    }

    return new Instant({
      dateTime: date,
      utcOffset: date.getTimezoneOffset()
    });
  }
};
