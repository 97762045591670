import { Box, Button, Grid, Stack } from '@mui/material';
import { Column, ColumnFilterElementTemplateOptions } from 'primereact/column';
import { DataTable as PrimeDataTable } from 'primereact/datatable';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { apiProvider } from 'shared/api/apiProvider';
import { CapabilityType, DeletePropertyPayload, Property, RooPermission } from 'shared/api/clients';
import { Routes } from 'shared/routing';
import { useAppStore, useCurrentUser, useHasCapability, useHasPermission, useIsGeneralContractor } from 'shared/store';
import { ConfirmationModal, ContentWrapper, LoaderSmall, NavSlim, PermissionCheckPage, RouterLink } from 'components';
import { rooFmt, showSuccess } from 'shared/utils';
import 'pages/Properties/properties.scss';
import { MuiIcon } from '../../shared/icons';
import { useRequestGlobalModal } from '../../components/modals';
import { YesNoIcon } from '../../components/YesNoIcon';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { CenteredLoader } from '../../components/CenteredLoader';

export const PropertyList = () => {
  const currentUser = useCurrentUser();
  const { isLoading, data } = useQuery(['properties', 'list'], () =>
    apiProvider.propertiesClient.getAll(currentUser.id)
  );
  const isGc = useIsGeneralContractor();
  const [, toggleHelp] = useRequestGlobalModal('propertyHelp');
  const [, toggleBulk] = useRequestGlobalModal('propertyBulkImport');

  const canAddProperty = useHasPermission(RooPermission.AddEditProperties, currentUser.managementCompany?.id) || isGc;

  const [filters] = useState({
    'managementCompany.name': { operator: 'AND', constraints: [{ value: '', matchMode: 'contains' }] },
    name: { operator: 'AND', constraints: [{ value: '', matchMode: 'contains' }] },
    'address.address1': { operator: 'AND', constraints: [{ value: '', matchMode: 'contains' }] },
    'address.address2': { operator: 'AND', constraints: [{ value: '', matchMode: 'contains' }] },
    'address.city': { operator: 'AND', constraints: [{ value: '', matchMode: 'contains' }] },
    'address.state': { operator: 'AND', constraints: [{ value: '', matchMode: 'contains' }] },
    'manager.fullName': { operator: 'AND', constraints: [{ value: '', matchMode: 'contains' }] },
    'owner.fullName': { operator: 'AND', constraints: [{ value: '', matchMode: 'contains' }] },
    isOccupied: { value: null, matchMode: 'equals' },
    isActive: { value: true, matchMode: 'equals' }
  });

  const [state] = useState();

  return (
    <>
      <Helmet>
        <title>Property list - Walkthroo</title>
      </Helmet>
      <NavSlim breadcrumbs={[{ text: 'Property List' }]} />
      <ContentWrapper className="container-property-list">
        <div
          className="d-flex justify-content-between align-items-center pt-3 pb-3 ps-4 pe-4"
          style={{ backgroundColor: 'white' }}
        >
          <Grid container className="align-items-center">
            <Grid item xs={12} md={3}>
              <h5 className="m-0">Property List</h5>
            </Grid>
            <Grid item xs={12} md={9}>
              {canAddProperty && (
                <Stack direction={'row'} justifyContent={'flex-end'} gap={2}>
                  {!isGc && (
                    <>
                      <Button size={'large'} color="muted" startIcon={<MuiIcon.UploadFile />} onClick={toggleHelp}>
                        Send us your File
                      </Button>
                      <Button size={'large'} color="muted" onClick={toggleBulk} startIcon={<MuiIcon.FileUpload />}>
                        Upload Property
                      </Button>
                    </>
                  )}

                  <Button
                    size={'large'}
                    color="primary"
                    startIcon={<MuiIcon.Add />}
                    component={RouterLink}
                    to={{ pathname: Routes.PropertyCreate }}
                  >
                    Add Property
                  </Button>
                </Stack>
              )}
            </Grid>
          </Grid>
        </div>
        {!isLoading && data != null && (
          <PrimeDataTable filters={filters as any} paginator rows={10} value={data} dataKey={'id'}>
            {isGc && (
              <Column
                sortable
                filter
                showFilterMatchModes={false}
                showFilterOperator={false}
                showAddButton={false}
                field={'managementCompany.name'}
                header={'Company'}
                body={(row: Property) =>
                  row.managementCompany != null && (
                    <RouterLink
                      to={{ pathname: Routes.CompanyProfile, params: { companyId: row.managementCompany?.id } }}
                    >
                      {row.managementCompany.name}
                    </RouterLink>
                  )
                }
              />
            )}

            <Column
              sortable
              filter
              showFilterMatchModes={false}
              showFilterOperator={false}
              showAddButton={false}
              field={'name'}
              header={'Name'}
              body={(row: Property) => (
                <RouterLink to={{ pathname: Routes.PropertyView, params: { id: row.id } }}>{row.name}</RouterLink>
              )}
            />
            <Column
              filter
              showFilterMatchModes={false}
              showFilterOperator={false}
              showAddButton={false}
              sortable
              header={'Address 1'}
              field={'address.address1'}
            />
            <Column
              filter
              showFilterMatchModes={false}
              showFilterOperator={false}
              showAddButton={false}
              sortable
              header={'Address 2'}
              field={'address.address2'}
            />
            <Column
              filter
              showFilterMatchModes={false}
              showFilterOperator={false}
              showAddButton={false}
              sortable
              header={'City'}
              field={'address.city'}
              style={{ width: 200 }}
            />
            <Column
              filter
              showFilterMatchModes={false}
              showFilterOperator={false}
              showAddButton={false}
              sortable
              header={'State'}
              field={'address.state'}
              style={{ width: 150 }}
            />
            <Column
              filter
              showFilterMatchModes={false}
              showFilterOperator={false}
              showAddButton={false}
              sortable
              field={'manager.fullName'}
              header={'PM'}
              body={(row: Property) => row.manager?.fullName ?? row.managementCompany?.name}
            />
            <Column
              filter
              showFilterMatchModes={false}
              showFilterOperator={false}
              showAddButton={false}
              sortable
              field={'owner.fullName'}
              header={'Owner'}
              body={(row: Property) => row.owner?.fullName ?? 'N/A'}
            />
            <Column
              showFilterMatchModes={false}
              sortable
              header={'Maint. limit'}
              field={'maintenanceLimit'}
              dataType="numeric"
              body={(row: Property) => rooFmt.moneyMaybe(row.maintenanceLimit, 'N/A')}
            />
            <Column
              filter
              showFilterMatchModes={false}
              filterElement={occupiedFilterTemplate}
              sortable
              header={'Occupied'}
              field={'isOccupied'}
              dataType="boolean"
              body={(row: Property) => <YesNoIcon value={row.isOccupied} />}
              style={{ width: 150, textAlign: 'center' }}
            />
            <Column
              filter
              showFilterMatchModes={false}
              filterElement={activeFilterTemplate}
              sortable
              header={'Active'}
              field={'isActive'}
              dataType="boolean"
              body={(row: Property) => <YesNoIcon value={row.isActive} />}
              style={{ width: 150, textAlign: 'center' }}
            />
            <Column header={'Actions'} body={(row: Property) => <ActionButtons row={row} />} style={{ width: 110 }} />
          </PrimeDataTable>
        )}
        {isLoading && <CenteredLoader />}
      </ContentWrapper>
      {state === 'pending' && (
        <div className={'text-center'}>
          <LoaderSmall />
        </div>
      )}
    </>
  );
};

const occupiedFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
  return (
    <>
      <div className="p-field-checkbox p-m-0">
        <TriStateCheckbox value={options.value} onChange={(e) => options.filterCallback(e.value)} />
        <label style={{ marginLeft: '0.5rem', lineHeight: 1, marginBottom: 0 }}>Is Occupied</label>
      </div>
    </>
  );
};

const activeFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
  return (
    <>
      <div className="p-field-checkbox p-m-0">
        <TriStateCheckbox value={options.value} onChange={(e) => options.filterCallback(e.value)} />
        <label style={{ marginLeft: '0.5rem', lineHeight: 1, marginBottom: 0 }}>Is Active</label>
      </div>
    </>
  );
};

const ActionButtons = ({ row }: { row: Property }) => {
  const isManager = useHasCapability(CapabilityType.PropertyManager);
  if (!isManager) {
    return null;
  }
  return (
    <Stack direction={'row'} spacing={2}>
      <DeletePropertyButton targetId={row.id} />
    </Stack>
  );
};

const DeletePropertyButton = ({ targetId }: { targetId: string }) => {
  const qc = useQueryClient();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isRunning, setIsRunning] = useState(false);

  const deleteProperty = async () => {
    setIsRunning(true);
    try {
      await apiProvider.propertiesClient.delete(
        new DeletePropertyPayload({
          id: targetId
        })
      );
      await qc.invalidateQueries();
      showSuccess();
    } catch (err) {}
    setIsRunning(false);
  };

  return (
    <>
      <Button color="error" onClick={() => setShowConfirmation(true)}>
        Delete
      </Button>
      <ConfirmationModal
        visible={showConfirmation}
        onClose={() => setShowConfirmation(false)}
        onSave={() => void deleteProperty()}
        question={'Are you sure you want to delete this property? All its Workorders and Issues will also be deleted.'}
        running={isRunning}
      />
    </>
  );
};
