import { IUser } from 'shared/api/clients';
import { ReactNode } from 'react';
import { CurrentIssueContextType } from './CurrentIssueContext';
import { SvgIconComponent } from '@mui/icons-material';
import { ActionColor } from '../../../shared/actions';

export type Contact = IUser & {
  role: string;
};

class IssueActionList {
  private allActionsList: IssueActionListEntry[] = [];
  public register(entry: IssueActionListEntry): IssueActionListEntry {
    this.allActionsList.push(entry);
    return entry;
  }

  public getActions(): IssueActionListEntry[] {
    return this.allActionsList;
  }
}

export const issueActionList = new IssueActionList();

export type IssueActionListEntry = {
  key: string;
  isVisible: (ctx: CurrentIssueContextType) => boolean;
  isDisabled: (ctx: CurrentIssueContextType) => boolean;
  isDeprioritized?: (ctx: CurrentIssueContextType) => boolean;
  onClick: () => void;
  label: ReactNode;
  Icon?: SvgIconComponent;
  color: ActionColor | ((ctx: CurrentIssueContextType) => ActionColor);
  modalToRender?: ReactNode;
};

export const getActionColor = (
  colorOrGetter: ActionColor | ((ctx: CurrentIssueContextType) => ActionColor),
  ctx: CurrentIssueContextType
): ActionColor => {
  if (colorOrGetter == null) {
    return null;
  }

  if (typeof colorOrGetter === 'string') {
    return colorOrGetter;
  }

  return colorOrGetter(ctx);
};
