import { z } from 'zod';
import { LineDefinition, TaxableLinesSchema, WithLineCollection } from '../types';
import {
  AttachmentEntityType,
  BidStatus,
  ChangeRequest,
  ChangeRequestDefaultsResponse,
  ChangeRequestIncludeCandidate,
  ChangeRequestStatus,
  CreateOrUpdateChangeRequestPayload,
  FormSubmissionLineItem,
  GetChangeRequestDefaultsPayload,
  GetInvoiceDefaultsPayload,
  IncludedDocument,
  Invoice,
  IssueStatus,
  LineSource,
  ModifyChangeRequestAction
} from '../../../../../shared/api/clients';
import { useCurrentIssue } from '../../../shared/CurrentIssueContext';
import { useRequiredRooForm } from '../../../../../components/form/utils';
import { Control, useFieldArray } from 'react-hook-form';
import { apiProvider } from '../../../../../shared/api/apiProvider';
import { classNames, DeepRequired, rooDate, rooFmt, showSuccess } from '../../../../../shared/utils';
import { Alert, Stack } from '@mui/material';
import { Button, Col, FormGroup, Row } from 'react-bootstrap';
import {
  FieldDatePicker,
  FieldMuiCheckbox,
  FieldNumber,
  FieldTextArea,
  Required
} from '../../../../../components/form';
import { FormLineItem } from '../FormLineItem';
import { UseFormGetValues, UseFormSetValue } from 'react-hook-form/dist/types/form';
import { RooIcon } from '../../../../../shared/icons';
import { DocumentFormTotals } from '../DocumentFormTotals';
import { RooButton, SaveButton } from '../../../../../components';
import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { CenteredLoader } from '../../../../../components/CenteredLoader';
import { InlineError } from '../../../../../components/InlineError';
import { calculateMarkup, DEFAULT_MARKUP_PERCENT } from '../utils';
import { useUniqueId } from '@roo/lib';

const ChangeRequestSchema = z.object({
  changeRequestId: z.string().nullish(),
  notes: z.string().nullish(),
  saveAsDraft: z.boolean(),
  lineCollection: TaxableLinesSchema,
  completionDate: z.date()
});

type FormDefinition = DeepRequired<z.infer<typeof ChangeRequestSchema>>;
const makeEmptyLine = (): LineDefinition => {
  return {
    value: null,
    description: null,
    type: LineSource.Custom,
    sourceEntityId: null,
    beforeFiles: [],
    afterFiles: [],
    notes: null,
    initialValue: null,
    resaleValue: null,
    canEditDescription: true,
    canEditValue: true
  };
};

export const ChangeRequestForm = ({
  onComplete,
  changeRequest,
  includedDocuments
}: {
  onComplete: () => void;
  changeRequest: ChangeRequest;
  includedDocuments: ChangeRequestIncludeCandidate[];
}) => {
  const { issue } = useCurrentIssue();
  const uid = useUniqueId();

  const { data, status } = useQuery(['issues', issue.id, 'changeRequest', changeRequest?.id, 'form-defaults', uid], {
    queryFn: () =>
      apiProvider.issues.changeRequests.getFormDefaults(
        new GetChangeRequestDefaultsPayload({
          issueId: issue.id,
          existingChangeRequestId: changeRequest?.id,
          includedDocuments: includedDocuments.map(
            (x) =>
              new IncludedDocument({
                documentId: x.documentId,
                documentType: x.type
              })
          )
        })
      ),
    cacheTime: Infinity,
    staleTime: Infinity
  });

  if (status === 'loading') {
    return <CenteredLoader />;
  }

  if (status === 'error') {
    return <InlineError />;
  }

  return <ChangeRequestFormInner defaults={data} changeRequest={changeRequest} onComplete={onComplete} />;
};

export const ChangeRequestFormInner = ({
  onComplete,
  changeRequest,
  defaults
}: {
  onComplete: () => void;
  changeRequest: ChangeRequest;
  defaults: ChangeRequestDefaultsResponse;
}) => {
  const { issue, onIssueUpdate } = useCurrentIssue();

  const { handleSubmit, control, getValues, setValue } = useRequiredRooForm(ChangeRequestSchema, {
    defaultValues: {
      changeRequestId: defaults.changeRequestId,
      notes: defaults.notes,
      saveAsDraft: false,
      completionDate: rooFmt.dateFromInstant(defaults.completionDate),
      lineCollection: {
        taxPercent: defaults.lineCollection.taxPercent,
        discountPercent: defaults.lineCollection.discountPercent,
        lines: defaults.lineCollection.lineItems.map((x) => ({
          value: x.value,
          initialValue: x.initialValue,
          resaleValue: x.resaleValue,
          notes: x.notes,
          type: x.sourceEntityType,
          sourceEntityId: x.sourceEntityId,
          beforeFiles: x.beforeFiles,
          afterFiles: x.afterFiles,
          description: x.description,
          canEditDescription: x.canEditDescription,
          canEditValue: x.canEditValue
        }))
      }
    }
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'lineCollection.lines'
  });

  const addLine = () => {
    append(makeEmptyLine(), {
      shouldFocus: false
    });
  };

  const save = async (values: FormDefinition) => {
    try {
      const updated = await apiProvider.issues.changeRequests.createOrUpdateChangeRequest(
        new CreateOrUpdateChangeRequestPayload({
          changeRequestId: changeRequest?.id,
          issueId: issue.id,
          notes: values.notes,
          taxPercent: values.lineCollection.taxPercent,
          discountPercent: values.lineCollection.discountPercent,
          completionDate: rooDate.makeInstant(values.completionDate),
          action: determineAction(values.saveAsDraft, changeRequest),
          lines: values.lineCollection.lines.map(
            (x, i) =>
              new FormSubmissionLineItem({
                description: x.description,
                value: x.value,
                initialValue: x.initialValue,
                beforeFileIds: x.beforeFiles.map((x) => x.id),
                notes: x.notes,
                afterFileIds: [],
                sourceEntityId: x.sourceEntityId,
                resaleValue: null,
                sourceEntityType: x.type,
                order: i
              })
          )
        })
      );

      onIssueUpdate(updated);
      showSuccess();
      onComplete();
    } catch (e) {}
  };

  return (
    <form noValidate onSubmit={handleSubmit(save)}>
      <Stack alignItems={'center'} justifyContent={'center'} mb={2}>
        {(issue.status === IssueStatus.VendorScheduling || issue.status === IssueStatus.TenantScheduling) && (
          <Alert sx={{ mb: 2 }} severity={'warning'}>
            Creating this Change Order Request will skip scheduling.
          </Alert>
        )}
        <Stack direction={'row'} spacing={2} flexWrap={'wrap'} gap={2}>
          {defaults.meta.withInitial && (
            <Stack alignItems={'center'}>
              <MarkupField
                getValues={getValues}
                setValue={setValue}
                initialMarkup={defaults.meta.appliedMarkupPercent}
              />
            </Stack>
          )}
          {defaults.meta.withTax && (
            <FieldNumber
              control={control}
              name={`lineCollection.taxPercent`}
              required
              label={'Tax Rate %'}
              placeholder={'Tax Rate %'}
            />
          )}
          {defaults.meta.withDiscount && (
            <FieldNumber
              control={control}
              name={`lineCollection.discountPercent`}
              required
              label={'Discount %'}
              placeholder={'Discount %'}
            />
          )}
          <FieldDatePicker
            groupProps={{ className: 'ms-0' }}
            control={control}
            name={'completionDate'}
            minDate={new Date()}
            dateFormat={'MMMM d, yyyy'}
            placeholderText={'Estimated Completion'}
            required
            label={'Estimated Completion Date'}
          />
        </Stack>
      </Stack>
      {fields.map((line, index) => {
        return (
          <FormLineItem
            key={index}
            line={line}
            index={index}
            remove={remove}
            control={control as unknown as Control<WithLineCollection>}
            getValues={getValues as unknown as UseFormGetValues<WithLineCollection>}
            entityType={AttachmentEntityType.ChangeRequestLineItem}
            canEditDescription={line.canEditDescription}
            canEditValue={true}
            showInitial={defaults.meta.withInitial}
            hasResale={false}
            hasNotes={true}
            hasBeforeFiles={true}
            hasAfterFiles={false}
          />
        );
      })}
      <div style={{ textAlign: 'center', marginTop: '15px' }}>
        <Button color={'primary'} onClick={() => addLine()}>
          <RooIcon icon={['fas', 'plus']} /> Add
        </Button>
      </div>

      <div style={{ marginTop: '30px' }}>
        <DocumentFormTotals
          withTax={defaults.meta.withTax}
          withResale={defaults.meta.withResale}
          withDiscount={defaults.meta.withDiscount}
          withPartial={false}
          previousDiscount={null}
          control={control as unknown as Control<WithLineCollection>}
        />
      </div>

      <Row>
        <Col className={'mb-2'}>
          <FieldTextArea control={control} name={'notes'} label={'Notes'} />
        </Col>
      </Row>

      <hr className="hr-text" />
      {(changeRequest == null || changeRequest.status === ChangeRequestStatus.Draft) && (
        <Stack justifyContent={'center'} direction={'row'}>
          <FieldMuiCheckbox control={control} name={'saveAsDraft'} label={'Save as Draft'} />
        </Stack>
      )}

      <Stack alignItems={'center'} justifyContent={'center'} direction={'row'} spacing={2}>
        <SaveButton className={'btn me-2'} control={control}>
          Save
        </SaveButton>
        <RooButton onClick={onComplete} variant={'secondary'}>
          Cancel
        </RooButton>
      </Stack>
    </form>
  );
};

const determineAction = (saveAsDraft: boolean, changeRequest: ChangeRequest) => {
  return saveAsDraft
    ? changeRequest == null || changeRequest.status === ChangeRequestStatus.Draft
      ? ModifyChangeRequestAction.SaveDraft
      : ModifyChangeRequestAction.SavePublished
    : ModifyChangeRequestAction.SavePublished;
};

const MarkupField = ({
  getValues,
  setValue,
  initialMarkup
}: {
  getValues: UseFormGetValues<FormDefinition>;
  setValue: UseFormSetValue<FormDefinition>;
  initialMarkup: number;
}) => {
  return (
    <div>
      <label htmlFor={'markup'}>Markup %</label>
      <input
        type="number"
        className={'form-control'}
        step="0.01"
        defaultValue={initialMarkup}
        onChange={(e) => {
          const textValue = e.target.value;
          const number = parseFloat(textValue);
          if (isNaN(number)) {
            return;
          }

          const lines = getValues('lineCollection.lines');
          let idx = 0;
          for (const line of lines) {
            if (line.initialValue != null) {
              const newVal = calculateMarkup(line.initialValue, number);
              setValue(`lineCollection.lines.${idx}.value`, newVal);
            }
            idx += 1;
          }
        }}
        id={'markup'}
      />
    </div>
  );
};
