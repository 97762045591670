import * as React from 'react';
import { styled } from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import { addWeeks, endOfWeek, format, getDay, isSameWeek, startOfWeek } from 'date-fns';
import {
  BaseSingleInputFieldProps,
  DatePicker,
  DateValidationError,
  FieldSection,
  UseDateFieldProps
} from '@mui/x-date-pickers';
import { Box, Button, IconButton } from '@mui/material';
import { MuiIcon } from '../shared/icons';

interface CustomPickerDayProps extends PickersDayProps<Date> {
  isSelected: boolean;
  isHovered: boolean;
}

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) => prop !== 'isSelected' && prop !== 'isHovered'
})<CustomPickerDayProps>(({ theme, isSelected, isHovered, day }) => ({
  borderRadius: 0,
  ...(isSelected && {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.main
    }
  }),
  ...(isHovered && {
    backgroundColor: theme.palette.primary[theme.palette.mode],
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary[theme.palette.mode]
    }
  }),
  ...(getDay(day) === 0 && {
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%'
  }),
  ...(getDay(day) === 6 && {
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%'
  })
})) as React.ComponentType<CustomPickerDayProps>;

const isInSameWeek = (dayA: Date, dayB: Date | null | undefined) => {
  if (dayB == null) {
    return false;
  }

  return isSameWeek(dayA, dayB);
};

function Day(
  props: PickersDayProps<Date> & {
    selectedDay?: Date | null;
    hoveredDay?: Date | null;
  }
) {
  const { day, selectedDay, hoveredDay, ...other } = props;

  return (
    <CustomPickersDay
      {...other}
      day={day}
      sx={{ px: 2.5 }}
      disableMargin
      selected={false}
      isSelected={isInSameWeek(day, selectedDay)}
      isHovered={isInSameWeek(day, hoveredDay)}
    />
  );
}

interface ButtonFieldProps
  extends UseDateFieldProps<Date, false>,
    BaseSingleInputFieldProps<Date | null, Date, FieldSection, false, DateValidationError> {
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  value: Date;
}

function ButtonField(props: ButtonFieldProps) {
  const { setOpen, value, id, InputProps: { ref } = {} } = props;

  return (
    <Button
      id={id}
      ref={ref}
      variant="text"
      startIcon={<MuiIcon.CalendarMonth />}
      onClick={() => setOpen?.((prev) => !prev)}
    >
      {value == null
        ? 'Pick a date'
        : `${format(startOfWeek(value), 'dd. MMM.')} - ${format(endOfWeek(value), 'dd. MMM.')}`}
    </Button>
  );
}

export default function WeekPicker(props: { value: Date; onChange?: (start: Date, end: Date) => any }) {
  const [value, setValue] = React.useState<Date | null>(props.value);
  const [open, setOpen] = React.useState(false);
  const [hoveredDay, setHoveredDay] = React.useState<Date | null>(null);

  const handleChange = (newValue: Date) => {
    setValue(newValue);
    if (props.onChange) {
      props.onChange(startOfWeek(newValue), endOfWeek(newValue));
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
        <IconButton
          aria-label="previous"
          size="large"
          onClick={() => {
            handleChange(addWeeks(value, -1));
          }}
        >
          <MuiIcon.ChevronLeft />
        </IconButton>
        <DatePicker
          open={open}
          showDaysOutsideCurrentMonth
          displayWeekNumber
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          label="Week picker"
          value={value}
          onChange={handleChange}
          slots={{ day: Day, field: ButtonField }}
          slotProps={{
            field: { setOpen } as any,
            day: (ownerState) =>
              ({
                selectedDay: value,
                hoveredDay,
                onPointerEnter: () => setHoveredDay(ownerState.day),
                onPointerLeave: () => setHoveredDay(null)
              } as any),
            actionBar: {
              actions: ['today']
            }
          }}
        />
        <IconButton
          aria-label="next"
          size="large"
          onClick={() => {
            handleChange(addWeeks(value, 1));
          }}
        >
          <MuiIcon.ChevronRight />
        </IconButton>
      </Box>
    </LocalizationProvider>
  );
}
