import { Grid, ListItemIcon, ListItemText, MenuItem, MenuList } from '@mui/material';
import { RouteComponentProps } from 'react-router';
import { useContractorCanManageVendor, useCurrentVendor, useIsAdmin, useIsGeneralContractor } from '../../shared/store';
import { LoaderBig, NavSlim } from '../../components';
import { prepareRouterHrefAttrs, redirect, Routes } from '../../shared/routing';
import { Helmet } from 'react-helmet';
import { useQuery } from '@tanstack/react-query';
import { apiProvider } from '../../shared/api/apiProvider';
import { SectionBasics } from './SectionBasics';
import { SectionDwolla } from './SectionDwolla';
import { WhiteBox } from '../../components/WhiteBox';
import { MuiIcon } from '../../shared/icons';
import { SectionMembers } from './SectionMembers';
import Grid2 from '@mui/material/Unstable_Grid2';

type ProfileSections = 'basics' | 'dwolla' | 'members';

export const VendorProfilePage = ({
  match: {
    params: { vendorId, tab = 'basics' }
  }
}: RouteComponentProps<{ vendorId: string; tab: ProfileSections }>) => {
  const gcCanManager = useContractorCanManageVendor(vendorId);
  const isAdmin = useIsAdmin();
  const isVendorMember = useCurrentVendor()?.id === vendorId;
  const canEditProfile = gcCanManager || isAdmin || isVendorMember;
  const { isLoading, data: vendor } = useQuery(['vendor', vendorId], () => apiProvider.vendorClient.get(vendorId));

  if (isLoading) {
    return <LoaderBig />;
  }

  const makeTab = (newTab: ProfileSections) => {
    return {
      selected: tab === newTab,
      ...prepareRouterHrefAttrs({ pathname: Routes.VendorProfile, params: { vendorId: vendorId, tab: newTab } })
    };
  };

  return (
    <>
      <Helmet>
        <title>{vendor.companyName} - Profile - Walkthroo</title>
      </Helmet>
      <NavSlim breadcrumbs={[{ text: vendor.companyName }]} />

      <Grid2 container p={1} spacing={1}>
        <Grid2 xs={12} md={4} lg={3}>
          <WhiteBox>
            <MenuList sx={{ pr: 1 }} className="menu-user-profile">
              <MenuItem sx={{ pt: 1, pb: 1 }} {...makeTab('basics')}>
                <ListItemText>Profile</ListItemText>
              </MenuItem>
              <MenuItem sx={{ pt: 1, pb: 1 }} {...makeTab('members')}>
                <ListItemText>Members</ListItemText>
              </MenuItem>
              {canEditProfile && (
                <MenuItem sx={{ pt: 1, pb: 1 }} {...makeTab('dwolla')}>
                  <ListItemIcon>
                    <MuiIcon.Lock fontSize={'small'} />
                  </ListItemIcon>
                  <ListItemText>Bank Info</ListItemText>
                </MenuItem>
              )}
            </MenuList>
          </WhiteBox>
        </Grid2>
        <Grid2 xs={12} md={8} lg={9}>
          {tab === 'basics' && (
            <WhiteBox sx={{ px: 4 }}>
              <SectionBasics vendor={vendor} />
            </WhiteBox>
          )}
          {tab === 'members' && <SectionMembers vendor={vendor} />}
          {canEditProfile && tab === 'dwolla' && (
            <WhiteBox sx={{ px: 4 }}>
              <SectionDwolla
                vendor={vendor}
                onCancel={() => {
                  redirect({ pathname: Routes.VendorProfile, params: { vendorId: vendorId, tab: 'basics' } });
                }}
              />
            </WhiteBox>
          )}
        </Grid2>
      </Grid2>
    </>
  );
};
