import { Property } from '../../../shared/api/clients';
import { PropertyCard } from '../../../components';
import React from 'react';
import { Control, useController } from 'react-hook-form';
import { Box, Button, Stack, Typography } from '@mui/material';
import { MuiIcon } from 'shared/icons';
import { useDisclosure } from '@roo/lib';
import { PropertySelectionModal } from '../../../components/PropertySelectionModal';

export const PropertyField = ({ control }: { control: Control<{ property: Property }> }) => {
  const {
    field,
    fieldState: { error }
  } = useController({
    control,
    name: 'property'
  });

  const disclosure = useDisclosure(false);

  let display = (
    <Button sx={{ minWidth: 200 }} onClick={disclosure.open} size={'small'} startIcon={<MuiIcon.Search />}>
      Select Property
    </Button>
  );
  if (field.value != null) {
    display = <PropertyCard property={field.value} />;
  }

  return (
    <>
      <Stack gap={1}>
        <Stack direction={'row'} justifyContent={'space-between'}>
          <Typography variant={'body2'} fontWeight={600}>
            Property
          </Typography>
          {field.value != null && (
            <Button size={'small'} variant={'text'} onClick={() => field.onChange(null)}>
              Change
            </Button>
          )}
        </Stack>

        <Box px={1}>{display}</Box>
        {error != null && <Typography color={'error'}>{error.message}</Typography>}
      </Stack>
      <PropertySelectionModal
        disclosure={disclosure}
        onSelect={(val) => {
          // @ts-ignore
          field.onChange(val);
          disclosure.close();
        }}
      />
    </>
  );
};
