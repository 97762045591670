import { Button } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { RooDialog } from 'components';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useState } from 'react';
import { Badge, Form } from 'react-bootstrap';
import { apiProvider } from 'shared/api/apiProvider';
import { Estimate } from 'shared/api/clients';
import { EnumMapEstimateStatus } from 'shared/store/enums/estimateStatus';
import { rooFmt } from 'shared/utils';

function useQueryIssue(issueId: string) {
  return useQuery([`previousEstimates-${issueId}`], async () => apiProvider.issues.main.getForUser(issueId));
}

export const PreviousEstimate = ({
  issueId,
  handlePrevEstimatesCallback
}: {
  issueId: string;
  handlePrevEstimatesCallback: (prevEstimates: Estimate[]) => void;
}) => {
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [selectedEstimates, setSelectedEstimates] = useState<Estimate[]>(null);
  const query = useQueryIssue(issueId);

  const handleButtonClick = () => {
    handlePrevEstimatesCallback(selectedEstimates);
    setModalVisible(false);
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        sx={{ padding: '0.5rem 1rem' }}
        onClick={() => setModalVisible(!modalVisible)}
      >
        Previous Estimate
      </Button>

      <RooDialog maxWidth={'md'} fullWidth open={modalVisible} onClose={() => setModalVisible(false)}>
        <Form noValidate style={{ marginBottom: 'initial' }}>
          <RooDialog.Title onClose={() => setModalVisible(false)}>Select Previous Estimates</RooDialog.Title>
          <RooDialog.Content>
            <DataTable
              value={query.data?.estimates}
              selectionMode={'multiple'}
              selection={selectedEstimates}
              onSelectionChange={(e) => {
                setSelectedEstimates(e.value);
              }}
            >
              <Column selectionMode="multiple" headerStyle={{ width: '3em' }} />
              <Column
                header={'Number'}
                bodyClassName={'text-col'}
                body={(row: Estimate) => (
                  <>
                    {row.friendlyId}
                    <div className={'mb-0'}>
                      <Badge bg={EnumMapEstimateStatus.get(row.status).color}>
                        {EnumMapEstimateStatus.get(row.status).display}
                      </Badge>
                    </div>
                  </>
                )}
              />
              <Column
                sortable
                field={'referenceDate'}
                header={'Date'}
                body={(row: Estimate) => rooFmt.instantDateMaybe(row.referenceDate, '-')}
              />
              <Column
                sortable
                field={'totals.subtotal'}
                header={'Subtotal'}
                body={(row: Estimate) => rooFmt.moneyMaybe(row.totals.subtotal, 'N/A')}
              />
              <Column
                sortable
                field={'totals.tax'}
                header={'Tax'}
                body={(row: Estimate) => rooFmt.moneyMaybe(row.totals.tax, 'N/A')}
              />
              <Column
                sortable
                field={'grandTotal'}
                header={'Grand Total'}
                body={(row: Estimate) => rooFmt.moneyMaybe(row.totals.grandTotal, 'N/A')}
              />
            </DataTable>
          </RooDialog.Content>
          <RooDialog.Actions>
            <Button color="primary" onClick={handleButtonClick}>
              Save
            </Button>
          </RooDialog.Actions>
        </Form>
      </RooDialog>
    </>
  );
};
