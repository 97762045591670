import { fileUtils, UploaderFile, useFileArea } from './FilePicker.store';
import { RooDialog } from '../../RooDialog';
import { Button, Card, CircularProgress, IconButton, Stack, Typography } from '@mui/material';
import { Center } from '../../Center';
import React, { useMemo } from 'react';
import { rooFmt } from '../../../shared/utils';
import { prettierBytes, truncateMiddle, useBasicFileDrop } from './utils';
import { getIconByMime } from './getIconByMime';
import { MuiIcon } from 'shared/icons';

export const ViewFileList = () => {
  useBasicFileDrop();
  const files = useFileArea((x) => x.files);
  const { canAddMore } = useFileArea((x) => x.computed);
  const { requestView } = useFileArea((x) => x.actions);
  return (
    <>
      <RooDialog.Content>
        <Stack spacing={1} sx={{ overflow: 'auto' }}>
          {files.map((x) => (
            <FileEntry key={x.assetId} file={x} />
          ))}
          {canAddMore && (
            <Center>
              <Button color={'info'} startIcon={<MuiIcon.Add />} onClick={() => requestView('source-selector')}>
                Add More
              </Button>
            </Center>
          )}
        </Stack>
      </RooDialog.Content>
      <RooDialog.Actions>
        <ViewListAction />
      </RooDialog.Actions>
    </>
  );
};

const ViewListAction = () => {
  const { isUploading, pendingCount, successCount, canClose } = useFileArea((x) => x.computed);
  const { startUpload, requestClose } = useFileArea((x) => x.actions);
  return (
    <Center px={4} sx={{ height: 50, width: '100%' }}>
      {(isUploading || pendingCount > 0) && (
        <Button
          disabled={isUploading || pendingCount === 0}
          startIcon={!isUploading ? <MuiIcon.CloudUpload /> : <CircularProgress size={22} />}
          onClick={() => startUpload()}
          fullWidth
          size={'large'}
        >
          {isUploading
            ? 'Uploading...'
            : pendingCount === 0
            ? 'Waiting for Files'
            : `Upload ${rooFmt.counted(pendingCount, 'File', 'Files')}`}
        </Button>
      )}
      {!isUploading && pendingCount === 0 && successCount > 0 && canClose && (
        <Button fullWidth size={'large'} onClick={requestClose}>
          Done
        </Button>
      )}
    </Center>
  );
};

const FileEntry = ({ file }: { file: UploaderFile }) => {
  const { removeFile } = useFileArea((x) => x.actions);
  let size = prettierBytes(file.rawFile.size);
  if (file.compressedFile != null) {
    size += ` (${prettierBytes(file.compressedFile.size)} compressed)`;
  }
  return (
    <Card variant={'outlined'} sx={{ p: 1 }}>
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
        <Stack direction={'row'} alignItems={'center'} spacing={2}>
          {file.thumbnailUrl != null && <FilePreviewImage file={file} />}
          {file.thumbnailUrl == null && <FilePreviewIcon file={file} />}

          <Stack>
            <Typography title={file.fileName}>{truncateMiddle(file.fileName, 30)}</Typography>
            <Typography variant={'caption'}>{size}</Typography>
            {file.state === 'pending' && <Typography variant={'caption'}>Pending upload</Typography>}
            {file.state === 'success' && (
              <Typography color={'success.main'} variant={'caption'}>
                Uploaded successfully!
              </Typography>
            )}
            {(file.state === 'uploading' || file.state === 'compressing') && (
              <Typography color={'info.main'} variant={'caption'}>
                Uploading...
              </Typography>
            )}
            {file.state === 'error' && (
              <Typography color={'error.main'} variant={'caption'}>
                {file.errorMessage ?? 'Failed to upload file'}
              </Typography>
            )}
          </Stack>
        </Stack>
        <Stack>
          {fileUtils.canRemove(file) && (
            <IconButton color={'error'} onClick={() => removeFile(file.assetId)}>
              <MuiIcon.Delete />
            </IconButton>
          )}
        </Stack>
      </Stack>
    </Card>
  );
};

const FilePreviewImage = ({ file }: { file: UploaderFile }) => {
  return <img alt={''} width={64} height={64} src={file.thumbnailUrl} />;
};

const FilePreviewIcon = ({ file }: { file: UploaderFile }) => {
  const { icon, color } = useMemo(() => getIconByMime(file.rawFile.type), [file.rawFile.type]);
  return (
    <Center width={64} height={64} bgcolor={color} borderRadius={1}>
      <Center sx={{ width: '55%', height: '76%' }} bgcolor={'white'}>
        {icon}
      </Center>
    </Center>
  );
};
