import { useQuery } from '@tanstack/react-query';
import { apiProvider } from '../../shared/api/apiProvider';

export const useInspectionDetailsQuery = (inspectionId: string) => {
  return useQuery(
    ['inspections', 'details-user-view', inspectionId],
    () => apiProvider.inspectionClient.getUserView(inspectionId),
    {
      enabled: inspectionId != null,
      staleTime: 5 * 60 * 1000,
      cacheTime: Infinity
    }
  );
};
