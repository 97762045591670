import { Stack } from '@mui/material';
import { MuiIcon } from '../../shared/icons';
import { NotesThread } from '../Notes/NotesThread';
import { NoteEntityType, NoteSource } from '../../shared/api/clients';

export const NotesWidget = ({
  entityId,
  entityType,
  noteSource
}: {
  entityId: string;
  entityType: NoteEntityType;
  noteSource: NoteSource;
}) => {
  return (
    <Stack spacing={2} width={'100%'}>
      <Stack direction={'row'} spacing={0.5}>
        <MuiIcon.Chat fontSize={'small'} />
        <h5 style={{ marginBottom: 0 }}>Notes</h5>
      </Stack>

      <NotesThread entityType={entityType} entityId={entityId} noteSource={noteSource} />
    </Stack>
  );
};
