import { createModalStore } from '../../../../shared/store';
import { useCurrentIssue } from '../../shared/CurrentIssueContext';
import React, { useState } from 'react';
import { apiProvider } from '../../../../shared/api/apiProvider';
import { IssueAction, SkipBiddingPayload } from '../../../../shared/api/clients';
import { showSuccess } from '../../../../shared/utils';
import { ConfirmationModal } from '../../../../components';
import { issueActionList } from '../../shared/types';
import { MuiIcon } from '../../../../shared/icons';

const useSkipBiddingStore = createModalStore();

export const SkipBiddingModal = () => {
  const { issue, onIssueUpdate } = useCurrentIssue();
  const [comments, setComments] = useState<string>('');
  const [isSaving, setIsSaving] = useState(false);
  const isVisible = useSkipBiddingStore((x) => x.visible);
  const hide = useSkipBiddingStore((x) => x.actions.hide);

  const save = async () => {
    setIsSaving(true);
    try {
      const result = await apiProvider.issues.bids.skipBidding(
        new SkipBiddingPayload({
          issueId: issue.id,
          notes: comments?.trim() === '' ? null : comments
        })
      );
      onIssueUpdate(result);
      showSuccess();
    } catch (e) {}

    setIsSaving(false);
    hide();
  };

  return (
    <ConfirmationModal
      visible={isVisible}
      running={isSaving}
      title={'Confirm Bid Skip'}
      question={
        <>
          Are you sure you want want to skip the bidding process?
          <div className={'mt-4 form-group'}>
            <label htmlFor={'notes-input'}>Comments</label>
            <textarea
              value={comments}
              onChange={(e) => setComments(e.target.value)}
              className={'form-control'}
              id={'notes-input'}
              rows={3}
              placeholder={'Your comments'}
            />
          </div>
        </>
      }
      onClose={hide}
      onSave={() => {
        void save();
      }}
    />
  );
};

export const vendorSkipBiddingAction = issueActionList.register({
  Icon: MuiIcon.FastForward,
  color: 'warning',
  isDisabled: () => false,
  key: 'vendor-skip-bidding',
  label: 'Under Limit',
  isVisible: (ctx) => ctx.canAct(IssueAction.SkipBidding),
  onClick: () => {
    useSkipBiddingStore.getState().actions.show();
  },
  modalToRender: <SkipBiddingModal />
});
