import { Switch, SwitchProps } from '@mui/material';
import React from 'react';
import { FieldValues, useController } from 'react-hook-form';
import { ControlProps } from 'components/form/types';

type BaseProps = Omit<SwitchProps, 'id' | 'type' | 'name' | 'onChange' | 'value' | 'onBlur'>;
type FinalProps<TForm extends FieldValues> = ControlProps<TForm> & BaseProps;
export type FieldMuiSwitchProps<TForm extends FieldValues> = FinalProps<TForm>;

export const FieldMuiSwitch = <T extends FieldValues>({ control, name, ...rest }: FinalProps<T>) => {
  const { field } = useController({
    control: control,
    name: name
  });
  return <Switch name={name} checked={field.value} onChange={(val) => field.onChange(val)} onBlur={field.onBlur} />;
};
