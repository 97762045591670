import { useNotesQuery } from '../../shared/api/queries';
import React, { useState } from 'react';
import { NoteEntityType, NoteSource } from '../../shared/api/clients';
import { Alert, Box, Stack } from '@mui/material';
import { LoaderSmall } from '../LoaderSmall';
import { InternalNoteList } from './InternalNote';
import { AddNoteModal } from '../../pages/Workorders/shared/AddNoteModal';
import { Center } from '../Center';
import { AddNoteForm } from './AddNoteForm';

export const NotesThread = ({
  entityType,
  entityId,
  noteSource
}: {
  entityType: NoteEntityType;
  entityId: string;
  noteSource: NoteSource;
}) => {
  const { isLoading, data } = useNotesQuery({
    entityId: entityId,
    entityType: entityType
  });
  const [openNoteModal, setOpenNoteModal] = useState(false);

  return (
    <Box style={{ width: '100%' }}>
      {isLoading && <LoaderSmall />}
      {!isLoading && data.length === 0 && (
        <Stack spacing={2}>
          <Center>
            <Alert color={'info'}>No existing notes yet. Add your own!</Alert>
          </Center>
          <AddNoteForm entityId={entityId} entityType={entityType} noteSource={noteSource} />
        </Stack>
      )}
      {!isLoading && data.length > 0 && (
        <Stack spacing={2}>
          <AddNoteForm entityId={entityId} entityType={entityType} noteSource={noteSource} />
          <InternalNoteList notes={data} />
        </Stack>
      )}
      <AddNoteModal
        entityId={entityId}
        entityType={entityType}
        visible={openNoteModal}
        onCloseRequest={() => setOpenNoteModal(false)}
      />
    </Box>
  );
};
