import { AuthManager } from '../../shared/store';
import { RooDialog } from '../RooDialog';
import React, { useState } from 'react';
import { Button } from '@mui/material';
import { apiProvider } from '../../shared/api/apiProvider';
import { StartImpersonationPayload } from '../../shared/api/clients';
import { useWatchGlobalModal } from './modalStore';

export const ImpersonateModal = () => {
  const { isVisible, toggle, props } = useWatchGlobalModal('impersonateModal');
  const [loading, setIsLoading] = useState(false);

  const requestImpersonation = async () => {
    try {
      setIsLoading(true);
      const result = await apiProvider.adminClient.startImpersonation(
        new StartImpersonationPayload({
          userId: props.account.userId
        })
      );
      AuthManager.instance.startImpersonation(result.token, props.sendToLanding);
    } catch (e) {
      setIsLoading(false);
    }
  };
  return (
    <>
      <RooDialog maxWidth={'sm'} fullWidth open={isVisible} onClose={toggle}>
        <RooDialog.Title>Confirmation</RooDialog.Title>
        <RooDialog.Content>
          Are you sure you want to impersonate {props.account.firstName} {props.account.lastName}?
        </RooDialog.Content>
        <RooDialog.Actions>
          <Button disabled={loading} onClick={() => requestImpersonation()}>
            Impersonate
          </Button>
          <Button color="muted" size="small" onClick={toggle}>
            Cancel
          </Button>
        </RooDialog.Actions>
      </RooDialog>
    </>
  );
};
