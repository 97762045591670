import { Alert, Box, Button, Grid } from '@mui/material';
import React, { useState } from 'react';
import { apiProvider } from 'shared/api/apiProvider';
import { CreateCompanyWithOwnerPayload, CreatePersonModel, StatusType } from 'shared/api/clients';
import { FieldMuiCheckbox, FieldMuiText, useRooForm } from 'components/form';
import { RooDialog } from 'components';
import { PersonSchema } from 'shared/utils';
import { z } from 'zod';
import { MuiIcon } from '../../../shared/icons';
import { useCompanyMutation } from '../../../shared/api/queries';

const FormSchema = z.object({
  person: PersonSchema,
  companyName: z.string(),
  autoConfirmOwnerEmail: z.boolean(),
  muteCompanyNotifications: z.boolean(),
  muteOwnerNotifications: z.boolean()
});
type FormDefinition = z.infer<typeof FormSchema>;

export const CreateCompanyButton = () => {
  const [show, setShow] = useState(false);
  const toggleShow = () => setShow(!show);
  return (
    <>
      <Button color="primary" size={'large'} startIcon={<MuiIcon.Add />} onClick={toggleShow}>
        Create
      </Button>
      <RooDialog scroll={'body'} open={show} onClose={toggleShow}>
        <RooDialog.Title onClose={toggleShow}>New Company</RooDialog.Title>
        <CreateCompanyModalContent toggleShow={toggleShow} />
      </RooDialog>
    </>
  );
};

export const CreateCompanyModalContent = ({ toggleShow }: { toggleShow: () => void }) => {
  const { control, handleSubmit } = useRooForm(FormSchema, {
    defaultValues: {
      muteCompanyNotifications: false,
      autoConfirmOwnerEmail: true,
      muteOwnerNotifications: false
    }
  });

  const { status, mutateAsync, data } = useCompanyMutation({
    mutationFn: (values: FormDefinition) =>
      apiProvider.adminClient.createCompany(
        new CreateCompanyWithOwnerPayload({
          companyName: values.companyName,
          person: new CreatePersonModel({
            email: values.person.email,
            firstName: values.person.firstName,
            lastName: values.person.lastName,
            phone: values.person.phone,
            userId: null
          }),
          muteCompanyNotifications: values.muteCompanyNotifications,
          muteOwnerNotifications: values.muteOwnerNotifications,
          autoConfirmOwnerEmail: values.autoConfirmOwnerEmail,
          password: null
        })
      ),
    onSuccess: () => {
      toggleShow();
    }
  });

  return (
    <RooDialog.Content>
      <form noValidate onSubmit={handleSubmit((vals) => mutateAsync(vals))}>
        <Grid container spacing={[0, 0, 2]}>
          <Grid item xs={12}>
            <FieldMuiCheckbox
              control={control}
              label={"Automatically confirm owner's e-mail"}
              name={'autoConfirmOwnerEmail'}
              color={'primary'}
            />
          </Grid>
          <Grid item xs={12}>
            <FieldMuiCheckbox
              control={control}
              label={'Mute all notifications sent in this company'}
              name={'muteCompanyNotifications'}
              color={'primary'}
            />
          </Grid>
          <Grid item xs={12}>
            <FieldMuiCheckbox
              control={control}
              label={'Mute all notifications sent to the owner'}
              name={'muteOwnerNotifications'}
              color={'primary'}
            />
          </Grid>
          <Grid item xs={12}>
            <FieldMuiText
              control={control}
              name={'companyName'}
              margin="normal"
              label="Company Name"
              type="text"
              placeholder="Company Name"
              fullWidth
              required
              autoFocus
            />
          </Grid>
        </Grid>
        <Grid container spacing={[0, 0, 2]}>
          <Grid item xs={12} md={6}>
            <FieldMuiText
              control={control}
              name={'person.firstName'}
              margin="normal"
              label="First Name"
              type="text"
              placeholder="First Name"
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FieldMuiText
              control={control}
              name={'person.lastName'}
              margin="normal"
              label="Last Name"
              type="text"
              placeholder="Last Name"
              required
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container spacing={[0, 0, 2]}>
          <Grid item xs={12} md={6}>
            <FieldMuiText
              control={control}
              name={'person.email'}
              margin="normal"
              label="Email"
              type="email"
              placeholder="Email"
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FieldMuiText
              control={control}
              name={'person.phone'}
              margin="normal"
              label="Phone"
              type="text"
              placeholder="Phone"
              required
              fullWidth
            />
          </Grid>
        </Grid>

        <Button size={'large'} type="submit" disabled={status === 'loading'} sx={{ my: 2 }}>
          Create
        </Button>
        <Box sx={{ mt: 3 }}>
          {(status === 'success' || status === 'error') && (
            <Alert severity="error">
              {data?.status === StatusType.EmailAlreadyTaken ? 'This e-mail is already in use' : 'Error'}
            </Alert>
          )}
        </Box>
      </form>
    </RooDialog.Content>
  );
};
