import { ManagementCompany, NoteSource } from '../../shared/api/clients';
import { Stack } from '@mui/material';
import React from 'react';
import { ContactsTable } from '../../components/Clients/ContactsTable';

export const SectionContacts = ({ company }: { company: ManagementCompany }) => {
  return (
    <Stack alignItems={'center'} justifyContent={'center'} px={3}>
      <ContactsTable companyId={company.id} noteSource={NoteSource.CompanyProfile} />
    </Stack>
  );
};
