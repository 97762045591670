import {
  CompanyType,
  DashboardStatCategory,
  NoteEntityType,
  NoteSource,
  PayableInvoiceInfo,
  PaymentGroup,
  PaymentGroupEntry
} from '../../shared/api/clients';
import { Box, Button, Paper, Stack, Typography } from '@mui/material';
import { DataTable as PrimeDataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { IconLink } from '../../components';
import { Routes } from '../../shared/routing';
import { rooEnum } from '../../shared/store';
import { MuiIcon } from '../../shared/icons';
import { rooDate, rooFmt } from '../../shared/utils';
import React, { useRef, useState } from 'react';
import { useContainerHeight } from '../../shared/utils/useContainerHeight';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { sumBy } from 'lodash';
import { IssueListButton } from './IssueListButton';
import { ReportingPayInvoiceButton } from './ReportingPayInvoiceModal';
import { NotesButton } from '../../components/Notes/NotesButton';
import { InvoiceInfoModal } from '../Workorders/ViewIssue/Documents/Invoices/InvoiceInfoButton';
import { Badge } from 'react-bootstrap';
import { useToggle } from '@roo/lib';

const rowExpansionTemplate = (row: PaymentGroupEntry) => {
  if (row.invoices.length === 0) {
    return (
      <Stack sx={{ width: '100%' }} justifyContent={'center'} alignItems={'center'}>
        <Typography color={'grey.500'}>No pending invoices</Typography>
      </Stack>
    );
  }

  return (
    <Box sx={{ width: { xs: '100%' }, px: { md: 4 }, display: 'block' }}>
      <Paper elevation={1} sx={{ p: 2, pb: { xs: 2, md: 1 } }}>
        <PrimeDataTable value={row.invoices} sortOrder={-1} sortField={'invoice.referenceDate.dateTime'}>
          <Column
            header="Issue #"
            sortable
            field={'issue.friendlyId'}
            body={(row: PayableInvoiceInfo) => (
              <>
                <Stack>
                  <IconLink
                    style={{ whiteSpace: 'nowrap', alignSelf: 'center' }}
                    to={{
                      pathname: Routes.IssueView,
                      params: { issueId: row.issue.id, workorderId: row.issue.workorderId }
                    }}
                    text={row.issue.friendlyId}
                  />
                  <Badge style={{ marginRight: '5px' }} bg={rooEnum.issueStatus.color(row.issue.status)}>
                    {rooEnum.issueStatus.display(row.issue.status)}
                  </Badge>
                </Stack>
              </>
            )}
          />
          <Column header={'Issue Title'} field={'issue.title'} />
          <Column sortable header={'Property'} field={'issue.property.displayAddress'} />
          <Column header={'Manager'} body={(row: PayableInvoiceInfo) => <ManagerDisplay row={row} />} />
          <Column
            header={'Invoice #'}
            sortable
            field={'invoice.friendlyId'}
            body={(row: PayableInvoiceInfo) => (
              <Stack>
                <InvoiceInfoButton row={row} />
                <Badge style={{ marginRight: '5px' }} bg={rooEnum.invoiceStatus.color(row.invoice.status)}>
                  {rooEnum.invoiceStatus.display(row.invoice.status)}
                </Badge>
              </Stack>
            )}
          />
          <Column
            field={'invoice.referenceDate.dateTime'}
            dataType={'date'}
            header={'Date'}
            sortable
            body={(row: PayableInvoiceInfo) => rooFmt.instantDateMaybe(row.invoice.referenceDate, '-')}
          />
          <Column
            field={'age'}
            dataType={'numeric'}
            header={'Age'}
            sortable
            body={(row: PayableInvoiceInfo) => rooFmt.numberMaybe(row.age, '-', 0)}
          />
          <Column
            body={(row: PayableInvoiceInfo) => (
              <Stack direction={'row'} spacing={1}>
                <ReportingPayInvoiceButton row={row} />
                <NotesButton
                  entityId={row.issue.id}
                  entityType={NoteEntityType.Issue}
                  title={'Issue Notes'}
                  noteSource={NoteSource.Reporting}
                />
              </Stack>
            )}
          />
          <Column
            field={'invoice.outstanding'}
            header={'Subtotal'}
            dataType={'numeric'}
            sortable
            className={'column-money'}
            bodyClassName={'column-money'}
            body={(row: PayableInvoiceInfo) => rooFmt.money(row.invoice.totals.subtotal)}
          />
          <Column
            field={'invoice.outstanding'}
            header={'Outstanding'}
            dataType={'numeric'}
            sortable
            className={'column-money'}
            bodyClassName={'column-money'}
            body={(row: PayableInvoiceInfo) => rooFmt.money(row.invoice.outstanding)}
          />
        </PrimeDataTable>
      </Paper>
    </Box>
  );
};

const InvoiceInfoButton = ({ row }: { row: PayableInvoiceInfo }) => {
  const [show, toggleShow] = useToggle();
  return (
    <>
      <Button variant={'text'} startIcon={<MuiIcon.Visibility />} size={'small'} onClick={toggleShow}>
        {row.invoice.friendlyId}
      </Button>
      <InvoiceInfoModal invoice={row.invoice} show={show} toggleShow={toggleShow} />
    </>
  );
};

const ManagerDisplay = ({ row }: { row: PayableInvoiceInfo }) => {
  if (row.issue.manager == null) {
    return null;
  }

  return (
    <Stack>
      <Typography>{row.issue.manager.fullName}</Typography>
      <Typography>{row.issue.manager.contactInfo.phoneNumber}</Typography>
    </Stack>
  );
};

export const PaymentsTable = ({
  data,
  CompanyNameExtras,
  companyType
}: {
  data: PaymentGroup;
  CompanyNameExtras: React.ComponentType<{ groupEntry: PaymentGroupEntry }>;
  companyType: CompanyType;
}) => {
  const containerRef = useRef<HTMLDivElement>();
  const height = useContainerHeight({ itemRef: containerRef, defaultHeight: 500 });
  const [filteredData, setFilteredData] = useState(data.entries);
  const [expandedRows, setExpandedRows] = useState<any>({});

  const [filters] = useState({
    companyName: { operator: 'AND', constraints: [{ value: '', matchMode: 'contains' }] }
  });

  const footerGroup = (
    <ColumnGroup>
      <Row>
        <Column footer="Totals:" footerStyle={{ fontWeight: 'bold' }} />
        <Column
          className={'column-money'}
          footer={() => {
            const total = sumBy(filteredData, (x) => x.age0 ?? 0);
            return rooFmt.money(total);
          }}
        />
        <Column
          className={'column-money'}
          footer={() => {
            const total = sumBy(filteredData, (x) => x.age15 ?? 0);
            return rooFmt.money(total);
          }}
        />
        <Column
          className={'column-money'}
          footer={() => {
            const total = sumBy(filteredData, (x) => x.age30 ?? 0);
            return rooFmt.money(total);
          }}
        />
        <Column
          className={'column-money'}
          footer={() => {
            const total = sumBy(filteredData, (x) => x.age60 ?? 0);
            return rooFmt.money(total);
          }}
        />
        <Column
          className={'column-money'}
          footer={() => {
            const total = sumBy(filteredData, (x) => x.ageMax ?? 0);
            return rooFmt.money(total);
          }}
        />
        <Column
          className={'column-money'}
          footer={() => {
            const total = sumBy(filteredData, (x) => x.total ?? 0);
            return rooFmt.money(total);
          }}
        />
      </Row>
      <Row>
        <Column
          footer={
            companyType === CompanyType.ManagementCompany
              ? 'Invoices that need to be sent:'
              : 'Outstanding owed needed invoices:'
          }
          footerStyle={{ fontWeight: 'bold' }}
        />
        <Column
          className={'column-money'}
          colSpan={5}
          footer={() => {
            return (
              <IssueListButton
                modalTitle={
                  companyType === CompanyType.ManagementCompany
                    ? 'Invoices that need to be sent'
                    : 'Outstanding owed needed invoices'
                }
                filters={{
                  forCategory:
                    companyType === CompanyType.ManagementCompany
                      ? DashboardStatCategory.IssProfitLossPendingIncome
                      : DashboardStatCategory.IssProfitLossPendingCost,
                  startDate: rooDate.makeInstant(new Date(2022, 11, 1))
                }}
              >
                {rooFmt.money(data.potentialInvoices)}
              </IssueListButton>
            );
          }}
        />
      </Row>
    </ColumnGroup>
  );
  return (
    <div ref={containerRef}>
      <PrimeDataTable
        onValueChange={(data: any[]) => setFilteredData(data)}
        filters={filters as any}
        scrollHeight={`${height}px`}
        scrollable
        value={data.entries}
        rowExpansionTemplate={rowExpansionTemplate}
        onRowToggle={(e) => {
          setExpandedRows(e.data);
        }}
        expandedRows={expandedRows}
        footerColumnGroup={footerGroup}
        sortOrder={-1}
        sortField={'total'}
        dataKey="companyId"
      >
        <Column style={{ maxWidth: '80px' }} expander />
        <Column
          field={'companyName'}
          sortable
          filter
          showFilterMatchModes={false}
          showFilterOperator={false}
          showAddButton={false}
          header={companyType === CompanyType.ManagementCompany ? 'Company' : 'Vendor'}
          body={(row: PaymentGroupEntry) => (
            <Stack width={'100%'} direction={'row'} spacing={1} justifyContent={'space-between'}>
              <Typography>{row.companyName}</Typography>
              {CompanyNameExtras != null && <CompanyNameExtras groupEntry={row} />}
            </Stack>
          )}
        />
        <Column
          field={'age0'}
          sortable
          header={'Current'}
          dataType={'numeric'}
          className={'column-money'}
          bodyClassName={'column-money'}
          body={(row: PaymentGroupEntry) => rooFmt.money(row.age0)}
        />
        <Column
          field={'age15'}
          sortable
          header={'1 - 15'}
          dataType={'numeric'}
          className={'column-money'}
          bodyClassName={'column-money'}
          body={(row: PaymentGroupEntry) => rooFmt.money(row.age15)}
        />
        <Column
          field={'age30'}
          sortable
          header={'16 - 30'}
          dataType={'numeric'}
          className={'column-money'}
          bodyClassName={'column-money'}
          body={(row: PaymentGroupEntry) => rooFmt.money(row.age30)}
        />
        <Column
          field={'age60'}
          sortable
          header={'31 - 60'}
          dataType={'numeric'}
          className={'column-money'}
          bodyClassName={'column-money'}
          body={(row: PaymentGroupEntry) => rooFmt.money(row.age60)}
        />
        <Column
          field={'ageMax'}
          sortable
          header={'61+'}
          dataType={'numeric'}
          className={'column-money'}
          bodyClassName={'column-money'}
          body={(row: PaymentGroupEntry) => rooFmt.money(row.ageMax)}
        />
        <Column
          field={'total'}
          sortable
          header={'Total'}
          dataType={'numeric'}
          className={'column-money'}
          bodyClassName={'column-money'}
          body={(row: PaymentGroupEntry) => rooFmt.money(row.total)}
        />
      </PrimeDataTable>
    </div>
  );
};
