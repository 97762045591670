import { useFileArea } from './FilePicker.store';
import { useBasicFileDrop } from './utils';
import { RooDialog } from '../../RooDialog';
import { Center } from '../../Center';
import { Button, Stack, styled, Typography } from '@mui/material';
import React, { ReactNode } from 'react';
import { MuiIcon } from 'shared/icons';
import { assertNever, makeUuid } from '@roo/lib';

export const ViewSelector = () => {
  const canGoBack = useFileArea((x) => x.files.length > 0);
  const { requestView } = useFileArea((x) => x.actions);
  const { hasGallery } = useFileArea((x) => x.computed);
  useBasicFileDrop();
  return (
    <RooDialog.Content>
      <Center sx={{ minHeight: 350 }}>
        <Stack spacing={2}>
          <Typography variant={'h6'} align={'center'}>
            Drop files here or...
          </Typography>
          <FileInputButton label={'Browse Files'} icon={<MuiIcon.Folder />} />
          <FileInputButton label={'Take Picture'} icon={<MuiIcon.CameraAlt />} />
          {hasGallery && (
            <Button
              sx={{ minWidth: 250 }}
              size={'large'}
              onClick={() => requestView('gallery')}
              startIcon={<MuiIcon.Collections />}
            >
              Use Existing
            </Button>
          )}

          <Button
            sx={{ minWidth: 250 }}
            size={'large'}
            onClick={() => requestView('collage')}
            startIcon={<MuiIcon.Compare />}
          >
            Create Collage
          </Button>

          {canGoBack && (
            <Button color={'secondary'} onClick={() => requestView('file-list')}>
              Cancel
            </Button>
          )}
        </Stack>
      </Center>
    </RooDialog.Content>
  );
};

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
});

const FileInputButton = ({ label, icon }: { label: string; icon: ReactNode }) => {
  const { addFiles, requestView } = useFileArea((x) => x.actions);
  const allowedTypes = useFileArea((x) => x.allowedFileTypes);
  const maxFiles = useFileArea((x) => x.maxFiles);
  const filesCount = useFileArea((x) => x.files.length);
  const mappedTypes = allowedTypes.map((x) => {
    switch (x) {
      case 'image':
        return 'image/*';
      case 'document':
        return '*.*';
      default:
        assertNever(x);
    }
  });

  const accept = mappedTypes.includes('*.*') ? '*.*' : mappedTypes.join(',');
  return (
    <Button sx={{ minWidth: 250 }} size={'large'} component="label" variant="contained" startIcon={icon}>
      {label}
      <VisuallyHiddenInput
        type="file"
        accept={accept}
        multiple={maxFiles - filesCount > 1}
        onChange={(ev) => {
          const files = ev.target.files;
          if (files == null) {
            return;
          }

          addFiles(
            Array.from(files).map((x) => ({
              fileName: x.name,
              rawFile: x,
              assetId: makeUuid(),
              state: 'pending'
            }))
          );
          requestView('file-list');
          ev.target.value = null;
        }}
      />
    </Button>
  );
};
