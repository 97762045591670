import { rooFmt } from 'shared/utils';
import { IDocumentTotals } from '../../../../shared/api/clients';

export const DocumentViewTotals = ({
  totals,
  isAuthor,
  outstanding
}: {
  totals: IDocumentTotals;
  isAuthor: boolean;
  outstanding?: number;
}) => {
  if (totals == null) {
    return null;
  }

  const hasTax = (totals.tax ?? 0) !== 0;
  const hasDiscount = (totals.discount ?? 0) !== 0;
  const hasPaidAmount = (outstanding ?? totals.grandTotal) !== totals.grandTotal;
  const hasPrevDiscount = (totals.previousDiscount ?? 0) !== 0;
  const hasPartial = (totals.partialAmount ?? 0) !== 0;

  return (
    <table>
      <tbody>
        {hasTax && (
          <>
            <tr style={{ paddingBottom: '18px' }}>
              <td style={{ textAlign: 'right', paddingRight: '50px' }}>
                <strong>Subtotal</strong>
              </td>
              <td>{rooFmt.moneyMaybe(totals.subtotal, 'N/A')}</td>
            </tr>
          </>
        )}
        {hasTax && (
          <tr style={{ paddingBottom: '18px' }}>
            <td style={{ textAlign: 'right', paddingRight: '50px' }}>
              <strong>Tax</strong>
            </td>
            <td>{rooFmt.moneyMaybe(totals.tax, 'N/A')}</td>
          </tr>
        )}
        {hasPrevDiscount && (
          <tr style={{ paddingBottom: '18px' }}>
            <td style={{ textAlign: 'right', paddingRight: '50px' }}>
              <strong>Previous Payments</strong>
            </td>
            <td>{rooFmt.moneyMaybe(totals.previousDiscount, 'N/A')}</td>
          </tr>
        )}
        <tr style={{ fontSize: '1.25rem' }}>
          <td style={{ textAlign: 'right', paddingRight: '50px' }}>
            <strong>Grand Total</strong>
          </td>
          <td>{rooFmt.moneyMaybe(totals.grandTotal, 'N/A')}</td>
        </tr>

        {hasDiscount && isAuthor && (
          <tr style={{ paddingBottom: '18px' }}>
            <td style={{ textAlign: 'right', paddingRight: '50px' }}>
              <strong>Discount ({rooFmt.percent(totals.discountPercent)})</strong>
            </td>
            <td>{rooFmt.moneyMaybe(-1 * totals.discount, 'N/A')}</td>
          </tr>
        )}

        {hasPartial && (
          <>
            <tr style={{ paddingBottom: '18px' }}>
              <td style={{ textAlign: 'right', paddingRight: '50px' }}>
                <strong>% of Total</strong>
              </td>
              <td>{rooFmt.percent(totals.partialPercent)}</td>
            </tr>
            <tr style={{ paddingBottom: '18px' }}>
              <td style={{ textAlign: 'right', paddingRight: '50px' }}>
                <strong>Total Due</strong>
              </td>
              <td>{rooFmt.moneyMaybe(totals.partialTotal, 'N/A')}</td>
            </tr>
          </>
        )}

        {hasDiscount && isAuthor && (
          <tr style={{ paddingBottom: '18px' }}>
            <td style={{ textAlign: 'right', paddingRight: '50px' }}>
              <strong>Final Total</strong>
            </td>
            <td>{rooFmt.moneyMaybe(totals.finalTotal, 'N/A')}</td>
          </tr>
        )}

        {hasPaidAmount && (
          <>
            <tr style={{ paddingBottom: '18px' }}>
              <td style={{ textAlign: 'right', paddingRight: '50px' }}>
                <strong>Paid</strong>
              </td>
              <td>{rooFmt.moneyMaybe(-(totals.finalTotal - outstanding), 'N/A')}</td>
            </tr>
            <tr style={{ fontSize: '1.25rem' }}>
              <td style={{ textAlign: 'right', paddingRight: '50px' }}>
                <strong>Outstanding</strong>
              </td>
              <td>{rooFmt.moneyMaybe(outstanding, 'N/A')}</td>
            </tr>
          </>
        )}
      </tbody>
    </table>
  );
};
