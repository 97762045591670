import { CapabilityType } from 'shared/api/clients';
import { ColoredEnumDisplay, createMap, EnumMap } from 'shared/store/enums/shared';

const InternalMapCapabilityType: EnumMap<typeof CapabilityType, ColoredEnumDisplay> = {
  Tenant: {
    display: 'Tenant',
    color: 'primary'
  },
  Vendor: {
    display: 'Vendor',
    color: 'success'
  },
  ManagementCompanyOwner: {
    display: 'Management Company Owner',
    color: 'danger'
  },
  PropertyManager: {
    display: 'Property Manager',
    color: 'warning'
  },
  PropertyOwner: {
    display: 'Property Owner',
    color: 'secondary'
  },
  Tech: {
    display: 'Tech',
    color: 'Success'
  },
  GeneralContractor: {
    display: 'General Contractor',
    color: 'success'
  },
  Unknown: {
    display: 'Unknown',
    color: 'dark'
  }
};

export const EnumMapCapabilityType = createMap<CapabilityType, ColoredEnumDisplay>(
  CapabilityType,
  InternalMapCapabilityType
);
