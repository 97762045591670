import {
  Alert,
  Box,
  Button,
  Chip,
  Grid,
  List,
  ListItem,
  ListItemText,
  styled,
  Typography,
  useMediaQuery
} from '@mui/material';
import { uniq } from 'lodash';
import React, { useState } from 'react';
import { CapabilityType, Membership, RooPermission, UserDetails } from 'shared/api/clients';
import { rooEnum, useAppStore } from 'shared/store';
import theme from 'shared/theme';
import { rooFmt } from 'shared/utils';
import { EditUserPermissionsModal } from 'pages/UserProfile/ViewProfile/EditUserPermissionsModal';
import { UpdateUserHandler } from 'pages/UserProfile/ViewProfile/shared';
import { MuiIcon } from '../../../shared/icons';
import { YesNoIcon } from '../../../components/YesNoIcon';

export const UserPermissionsInfo = ({
  userDetails,
  companyId,
  onUpdateUser
}: {
  userDetails: UserDetails;
  companyId: string;
  onUpdateUser: UpdateUserHandler;
}) => {
  const [showEditUserPermissions, setShowEditUserPermissions] = useState(false);

  const membership = userDetails.memberships.find((x) => x.managementCompanyId === companyId);
  const permissionMap = useAppStore((x) => x.lookups.capabilityPermissionsMap);
  const permissions = uniq(membership?.capabilities.flatMap((x) => permissionMap.find((y) => y.key === x).value) ?? []);

  const capabilities = [
    CapabilityType.PropertyManager,
    CapabilityType.PropertyOwner,
    CapabilityType.Tenant,
    CapabilityType.Vendor
  ];

  return (
    <>
      <Grid container direction="row" justifyContent="center" alignItems="center">
        <Box sx={{ mt: 4, p: 3, mb: 4 }}>
          <Box>
            <h4>Roles</h4>
            <Typography>
              Each user can have one or more <strong>roles</strong> in your company, depending on how you invited them.
            </Typography>
            <Typography>
              The enabled roles for <strong>{rooFmt.fullName(userDetails.user)}</strong> are highlighted below.
            </Typography>
            <Box
              component={'ul'}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                flexWrap: 'wrap',
                listStyle: 'none',
                p: 0.5,
                m: 0,
                mt: 2
              }}
            >
              {capabilities.map((cap) => (
                <SpacedListItem key={cap}>
                  <Chip
                    label={rooEnum.capabilityType.display(cap)}
                    color={membership.capabilities.indexOf(cap) > -1 ? 'primary' : 'default'}
                  />
                </SpacedListItem>
              ))}
            </Box>
            <Typography sx={{ mt: 2 }} variant={'caption'}>
              These are managed automatically by Walkthroo, you can't change them directly.
            </Typography>
          </Box>
          <Box sx={{ mt: 6 }}>
            <h4>Permissions</h4>
            <Typography>
              Each <strong>role</strong> comes with a predetermined set of <strong>permissions</strong> which you can
              toggle on or off.
            </Typography>
            <Typography>
              Use them to customize how <strong>{rooFmt.fullName(userDetails.user)}</strong> can interact with your
              company.
            </Typography>

            <PermissionLayout membership={membership} permissions={permissions} />
          </Box>
        </Box>
        <Grid container direction="row" justifyContent="flex-end" alignItems="center">
          <Button
            type="submit"
            variant="contained"
            size={'large'}
            disabled={permissions.length === 0}
            sx={{ mt: 2, mb: 2, width: 200 }}
            onClick={() => setShowEditUserPermissions(true)}
          >
            Update Permissions
          </Button>
        </Grid>
      </Grid>
      <EditUserPermissionsModal
        visible={showEditUserPermissions}
        userDetails={userDetails}
        membership={membership}
        potentialPermissions={permissions}
        onUpdateUser={onUpdateUser}
        onCloseRequest={() => setShowEditUserPermissions(false)}
      />
    </>
  );
};

const PermissionLayout = ({ membership, permissions }: { membership: Membership; permissions: RooPermission[] }) => {
  const isDesktop = useMediaQuery(theme.breakpoints.up('xl'));
  const shouldSplit = isDesktop && permissions.length > 4;
  const chunks = rooFmt.chunkArray(permissions, 2);

  if (permissions.length === 0) {
    return (
      <Alert sx={{ mt: 2 }} severity="info">
        No permissions are available for the user's enabled roles.
      </Alert>
    );
  }

  return (
    <Grid container justifyContent={'center'} alignItems={'center'}>
      {shouldSplit ? (
        [
          <PermissionList key={0} membership={membership} permissions={chunks[0]} />,
          <PermissionList key={1} membership={membership} permissions={chunks[1]} margin={4} />
        ]
      ) : (
        <PermissionList membership={membership} permissions={permissions} />
      )}
    </Grid>
  );
};

const PermissionList = ({
  membership,
  permissions,
  margin
}: {
  membership: Membership;
  permissions: RooPermission[];
  margin?: number;
}) => {
  return (
    <List sx={{ ml: margin }}>
      {permissions.map((perm) => (
        <ListItem>
          <ListItemText sx={{ textAlign: 'right' }} primary={rooEnum.permission.display(perm)} />
          <Box sx={{ ml: 4 }}>
            <YesNoIcon value={membership.permissions.find((x) => x === perm) != null} />
          </Box>
        </ListItem>
      ))}
    </List>
  );
};

const SpacedListItem = styled('li')(({ theme }) => ({
  margin: theme.spacing(0.5)
}));
