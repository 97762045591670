import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { apiProvider } from '../../shared/api/apiProvider';
import { CenteredLoader } from '../CenteredLoader';
import { ClientContact, NoteEntityType, NoteSource, SetContactDesignationPayload } from '../../shared/api/clients';
import React, { useMemo, useState } from 'react';
import MaterialReactTable, { MRT_ColumnDef } from 'material-react-table';
import { Autocomplete, Button, IconButton, Link, Stack, TextField, Typography } from '@mui/material';
import { RooAvatar } from '../RooAvatar';
import { RouterIconButton } from '../Links';
import { Routes } from '../../shared/routing';
import { MuiIcon } from '../../shared/icons';
import { NotesButton } from '../Notes/NotesButton';
import { AddContactModal } from './AddContactModal';
import { useDisclosure } from '@roo/lib';

export const ContactsTable = ({ companyId, noteSource }: { companyId: string; noteSource: NoteSource }) => {
  const { isLoading, data } = useQuery(['contacts', companyId], () =>
    apiProvider.generalContractorClient.getClientContacts(companyId)
  );

  if (isLoading) {
    return <CenteredLoader />;
  }

  return <ContactsTableImpl contacts={data} companyId={companyId} noteSource={noteSource} />;
};

const ContactsTableImpl = ({
  contacts,
  noteSource,
  companyId
}: {
  contacts: ClientContact[];
  companyId: string;
  noteSource: NoteSource;
}) => {
  const addContactDisclosure = useDisclosure(false);
  const columns = useMemo<MRT_ColumnDef<ClientContact>[]>(
    () => [
      {
        header: 'Name',
        accessorKey: 'user.fullName',
        Cell: ({ row }) => (
          <Stack direction={'row'} alignItems={'center'} spacing={0.5}>
            <RooAvatar name={row.original.user.fullName} avatarUrl={row.original.user.avatarUrl} size={'s'} />
            <Typography fontWeight={'bold'}>{row.original.user.fullName}</Typography>
          </Stack>
        )
      },
      {
        header: 'Title',
        accessorKey: 'user.title'
      },
      {
        header: 'Designation',
        accessorKey: 'designation',
        Cell: ({ row }) => <DesignationCell contact={row.original} companyId={companyId} />,
        size: 300
      },
      {
        header: 'Role',
        accessorKey: 'isOwner',
        Cell: ({ cell }) => (cell.getValue<boolean>() ? 'Owner' : 'Manager'),
        size: 100
      },
      {
        header: 'Phone',
        accessorKey: 'user.contactInfo.phoneNumber',
        Cell: ({ cell }) => {
          const val = cell.getValue<string>();
          return <Link href={`tel:${val}`}>{val}</Link>;
        },
        size: 150
      },
      {
        header: 'E-mail',
        accessorKey: 'user.contactInfo.email',
        Cell: ({ cell }) => {
          const val = cell.getValue<string>();
          return <Link href={`mailto:${val}`}>{val}</Link>;
        }
      },
      {
        id: 'actions',
        header: null,
        columnDefType: 'display',
        size: 100,
        Cell: ({ row }) => (
          <Stack direction={'row'} spacing={2}>
            <NotesButton
              entityId={row.original.user.id}
              entityType={NoteEntityType.CompanyMember}
              noteSource={noteSource}
              title={'Contact Notes'}
            />
            <RouterIconButton
              color={'primary'}
              target={'_blank'}
              to={{ pathname: Routes.UserProfile, params: { id: row.original.user.id } }}
            >
              <MuiIcon.Visibility />
            </RouterIconButton>
          </Stack>
        )
      }
    ],
    [companyId, noteSource]
  );

  return (
    <>
      <MaterialReactTable
        columns={columns}
        data={contacts}
        state={{ density: 'spacious' }}
        muiTablePaperProps={{
          sx: {
            width: '100%'
          }
        }}
        initialState={{
          sorting: [
            {
              id: 'isOwner',
              desc: true
            }
          ]
        }}
        renderTopToolbarCustomActions={() => (
          <Button
            startIcon={<MuiIcon.Add />}
            className={'Button-Basic'}
            variant={'contained'}
            sx={{
              color: 'white !important'
            }}
            onClick={addContactDisclosure.open}
          >
            Add
          </Button>
        )}
        enableFullScreenToggle={false}
        enableDensityToggle={false}
        enablePagination={false}
        enableStickyHeader={true}
        enableTableFooter={false}
        enableHiding={false}
        enableColumnDragging={false}
        enableBottomToolbar={false}
      />
      <AddContactModal disclosure={addContactDisclosure} companyId={companyId} />
    </>
  );
};

const DesignationCell = ({ contact, companyId }: { contact: ClientContact; companyId: string }) => {
  const [mode, setMode] = useState<'view' | 'edit'>('view');
  if (mode === 'edit') {
    return <DesignationCellEdit contact={contact} companyId={companyId} requestView={() => setMode('view')} />;
  }
  if (contact.designation == null) {
    return (
      <IconButton onClick={() => setMode('edit')}>
        <MuiIcon.Add />
      </IconButton>
    );
  }

  return (
    <Stack direction={'row'} alignItems={'center'} spacing={1}>
      <Typography>{contact.designation}</Typography>
      <IconButton onClick={() => setMode('edit')}>
        <MuiIcon.Edit />
      </IconButton>
    </Stack>
  );
};

const DesignationCellEdit = ({
  contact,
  requestView,
  companyId
}: {
  contact: ClientContact;
  companyId: string;
  requestView: () => void;
}) => {
  const { isLoading, data } = useQuery(['vendor', 'all-designations'], () =>
    apiProvider.generalContractorClient.getAvailableDesignations()
  );
  const qc = useQueryClient();

  const { isLoading: isSaving, mutate } = useMutation({
    mutationFn: (designation: string) =>
      apiProvider.generalContractorClient.setContactDesignation(
        new SetContactDesignationPayload({
          designation: designation,
          managementCompanyId: companyId,
          userId: contact.user.id
        })
      ),
    onSuccess: async () => {
      await qc.invalidateQueries(['contacts', companyId]);
      void qc.invalidateQueries(['vendor', 'all-designations']);
      requestView();
    }
  });

  const [designation, setDesignation] = useState(contact.designation);

  return (
    <Stack direction={'row'} spacing={1}>
      <Autocomplete
        size={'small'}
        loading={isLoading}
        options={data ?? []}
        value={designation}
        onInputChange={(_, val) => {
          setDesignation(val);
        }}
        freeSolo
        sx={{ width: 150 }}
        renderInput={(params) => <TextField {...params} label="Designation" />}
      />
      <IconButton disabled={isSaving} color={'success'} size={'small'} onClick={() => mutate(designation)}>
        <MuiIcon.Check />
      </IconButton>
      <IconButton disabled={isSaving} color={'error'} size={'small'} onClick={requestView}>
        <MuiIcon.Close />
      </IconButton>
    </Stack>
  );
};
