import React, { useState } from 'react';
import { Alert, Button, Col, Modal, ModalFooter, Row } from 'react-bootstrap';
import { apiProvider } from 'shared/api/apiProvider';
import { Instant, IssueAction, ProposalType, SetIssueSchedulePayload, TimeBlock } from 'shared/api/clients';
import { FieldDatePicker, FieldSingleSelect, FieldTextArea, Required, useRooForm } from 'components/form';
import { createModalStore, useTimeBlockOptions } from 'shared/store';
import { SaveButton } from 'components';
import { rooFmt, showSuccess } from 'shared/utils';
import { useCurrentIssue } from 'pages/Workorders/shared/CurrentIssueContext';
import { z } from 'zod';
import { issueActionList } from '../../shared/types';
import { CalendarMonth } from '@mui/icons-material';
import { MuiIcon } from '../../../../shared/icons';

const TimeBlockSchema = z.object({
  startHour: z.number(),
  startMinutes: z.number(),
  durationMinutes: z.number()
});

const FormSchema = z.object({
  date: z.date(),
  timeBlocks: TimeBlockSchema,
  notes: z.string().nullish()
});

type FormDefinition = z.infer<typeof FormSchema>;

const useSetTentativeScheduleStore = createModalStore();
export const SetTentativeScheduleModal = () => {
  const isVisible = useSetTentativeScheduleStore((x) => x.visible);
  const hide = useSetTentativeScheduleStore((x) => x.actions.hide);
  return (
    <Modal show={isVisible} onHide={hide} className={'modal-dialog-centered'}>
      {isVisible && <ScheduleModalContent proposalType={ProposalType.Tentative} closeModal={hide} />}
    </Modal>
  );
};

export const setTentativeScheduleAction = issueActionList.register({
  Icon: CalendarMonth,
  color: 'primary',
  isDisabled: () => false,
  key: 'set-tentative-schedule',
  label: 'Propose',
  isVisible: (ctx) => ctx.canAct(IssueAction.SetTentativeSchedule),
  onClick: () => {
    useSetTentativeScheduleStore.getState().actions.show();
  },
  modalToRender: <SetTentativeScheduleModal />
});

const useSetFinalScheduleStore = createModalStore();
export const SetFinalScheduleModal = () => {
  const isVisible = useSetFinalScheduleStore((x) => x.visible);
  const hide = useSetFinalScheduleStore((x) => x.actions.hide);
  return (
    <Modal show={isVisible} onHide={hide} className={'modal-dialog-centered'}>
      {isVisible && <ScheduleModalContent proposalType={ProposalType.Final} closeModal={hide} />}
    </Modal>
  );
};
export const setFinalScheduleAction = issueActionList.register({
  Icon: MuiIcon.CalendarMonth,
  color: (ctx) => (ctx.property.isOccupied ? 'warning' : 'primary'),
  isDisabled: (ctx) => ctx.property.isOccupied && !ctx.issue.schedule,
  key: 'set-final-schedule',
  label: 'Schedule',
  isVisible: (ctx) => ctx.canAct(IssueAction.SetFinalSchedule),
  onClick: () => {
    useSetFinalScheduleStore.getState().actions.show();
  },
  modalToRender: <SetFinalScheduleModal />
});

export const ScheduleModalContent = ({
  proposalType,
  closeModal
}: {
  proposalType: ProposalType;
  closeModal: () => void;
}) => {
  //TODO: convert this to RooDialog once we fix the issue with react-datepicker in mui modals
  const { issue, onIssueUpdate, property } = useCurrentIssue();
  const blockOptions = useTimeBlockOptions();
  const selectedBlock = blockOptions.find(
    (x) => x.value.startHour === issue.schedule?.timeBlocks?.[0]?.startHour
  )?.value;
  const { control, handleSubmit } = useRooForm(FormSchema, {
    defaultValues: {
      timeBlocks: selectedBlock,
      date: rooFmt.dateFromInstant(issue.schedule?.scheduledInstant),
      // if he already had some notes in the proposal, no point in having them in the final schedule again
      notes: null
    }
  });

  const [isSaving, setIsSaving] = useState(false);

  const save = async (values: FormDefinition) => {
    setIsSaving(true);
    try {
      const result = await apiProvider.issues.scheduling.setIssueSchedule(
        new SetIssueSchedulePayload({
          issueId: issue.id,
          scheduledInstant: new Instant({
            dateTime: values.date,
            utcOffset: values.date.getTimezoneOffset()
          }),
          timeBlocks: [
            new TimeBlock({
              startMinutes: values.timeBlocks.startMinutes,
              startHour: values.timeBlocks.startHour,
              durationMinutes: values.timeBlocks.durationMinutes,
              display: null
            })
          ],
          proposalType: proposalType,
          comments: values.notes
        })
      );
      onIssueUpdate(result);
      showSuccess();
    } catch (e) {}

    setIsSaving(false);
    closeModal();
  };
  return (
    <form noValidate onSubmit={handleSubmit(save)}>
      <Modal.Header closeButton>Schedule</Modal.Header>
      <Modal.Body>
        {proposalType === ProposalType.Final && property.isOccupied && issue.schedule == null && (
          <Alert variant={'warning'}>
            This property is occupied. You should <strong>Propose</strong> a schedule first so tenants can agree to it.
          </Alert>
        )}
        {proposalType === ProposalType.Final && property.isOccupied && issue.schedule != null && (
          <Alert variant={'warning'}>
            This property is occupied. Make sure the tenants have agreed to your <strong>Proposal</strong>.
          </Alert>
        )}
        <Row>
          <Col xs={12} className={'mb-2'}>
            <FieldDatePicker
              control={control}
              name={'date'}
              minDate={new Date()}
              dateFormat={'MMMM d, yyyy'}
              placeholderText={'Date'}
              required
              label={'Date'}
            />
          </Col>
          <Col xs={12} className={'mb-2'}>
            <FieldSingleSelect
              equalityFn={(a: TimeBlock, b: TimeBlock) => a.startHour === b.startHour}
              control={control}
              name={'timeBlocks'}
              options={blockOptions}
              required
              label={'Time'}
            />
          </Col>
          <Col xl={12}>
            <FieldTextArea control={control} name={'notes'} label={'Notes'} />
          </Col>
        </Row>
      </Modal.Body>
      <ModalFooter>
        <SaveButton control={control}>{proposalType === ProposalType.Tentative ? 'Propose' : 'Schedule'}</SaveButton>
        <Button disabled={isSaving} variant={'secondary'} onClick={closeModal}>
          Cancel
        </Button>
      </ModalFooter>
    </form>
  );
};
