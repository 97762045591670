import { RooDialog } from '../../RooDialog';
import {
  Button as MuiButton,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  Grid,
  Stack,
  Typography
} from '@mui/material';
import React, { useState } from 'react';
import { UploaderFile, useFileArea } from './FilePicker.store';
import { useQuery } from '@tanstack/react-query';
import { apiProvider } from '../../../shared/api/apiProvider';
import { CenteredLoader } from '../../CenteredLoader';
import { GalleryFile } from '../../../shared/api/clients';
import { FilePreview } from '../FileThumbnail';
import { makeUuid } from '@roo/lib';

export const ViewGallery = () => {
  const { requestView } = useFileArea((x) => x.actions);
  const meta = useFileArea((x) => x.meta);
  const { isLoading, data } = useQuery(['gallery', meta.galleryEntityType, meta.galleryEntityId], () =>
    apiProvider.filesClient.getForGallery(meta.galleryEntityType, meta.galleryEntityId)
  );
  return (
    <>
      <RooDialog.Content>
        {isLoading && <CenteredLoader />}

        {!isLoading && data.length === 0 && (
          <Typography sx={{ p: 4 }} align={'center'} variant={'h6'}>
            No files available
          </Typography>
        )}
        {!isLoading && data.length > 0 && <GalleryEntries files={data} />}
      </RooDialog.Content>
      <RooDialog.Actions>
        <Button color={'secondary'} onClick={() => requestView('source-selector')}>
          Cancel
        </Button>
      </RooDialog.Actions>
    </>
  );
};

const GalleryEntries = ({ files }: { files: GalleryFile[] }) => {
  return (
    <Grid container spacing={2} justifyContent={{ xs: 'center', md: 'flex-start' }}>
      {files.map((file) => (
        <GalleryCard key={file.file.id} file={file} />
      ))}
    </Grid>
  );
};

const GalleryCard = ({ file }: { file: GalleryFile }) => {
  const { addFiles } = useFileArea((x) => x.actions);
  const [showPreview, setShowPreview] = useState(false);
  const chips: string[] = file.chips;
  if (file.isFromSubissue) {
    chips.push('Sub');
  }
  if (file.tag != null) {
    chips.push(file.tag);
  }

  return (
    <Grid item sx={{ display: 'flex' }}>
      <Card sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', width: 256 }}>
        <CardActionArea
          onClick={(e) => {
            setShowPreview(true);
            e.preventDefault();
          }}
        >
          <CardMedia height={256} width={256} component={'img'} image={apiProvider.fileUrls.thumbnail(file.file.id)} />
          <CardContent>
            <Stack spacing={1}>
              <Stack direction={'row'} spacing={1}>
                {chips.map((x) => (
                  <Chip key={x} label={x} />
                ))}
              </Stack>
              {file.description && (
                <Typography align={'center'} variant={'body2'} color={'text.secondary'}>
                  {file.description}
                </Typography>
              )}
            </Stack>
          </CardContent>
        </CardActionArea>
        <CardActions>
          <MuiButton
            color="primary"
            onClick={() => {
              addFiles([
                {
                  fileName: file.file.fileName,
                  state: 'success',
                  assetId: makeUuid(),
                  rawFile: null,
                  uploadResult: file.file
                } satisfies UploaderFile
              ]);
            }}
          >
            Use
          </MuiButton>
        </CardActions>
      </Card>
      <FilePreview
        previewUrl={apiProvider.fileUrls.serve(file.file.id)}
        show={showPreview}
        onHide={() => setShowPreview(false)}
      />
    </Grid>
  );
};
