import { CompanyType, ManagementCompany, UserSummary, VendorSummary } from '../../../shared/api/clients';
import { RooButton, RooDialog, UserCard } from '../../../components';
import { VendorCard } from '../../../components/VendorCard';
import React from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { useAppStore, useCurrentUser, useCurrentVendor } from '../../../shared/store';
import { Center } from '../../../components/Center';
import { Control, useController } from 'react-hook-form';
import { MuiIcon } from 'shared/icons';
import { useVendorSelector, VendorSelector } from '../../../components/VendorSelector/VendorSelector';
import { useQuery } from '@tanstack/react-query';
import { apiProvider } from '../../../shared/api/apiProvider';
import { CenteredLoader } from '../../../components/CenteredLoader';
import { Disclosure, useDisclosure } from '@roo/lib';

export const InspectorField = ({ control }: { control: Control<{ inspector: InspectorSelection }> }) => {
  const {
    field,
    fieldState: { error }
  } = useController({
    control,
    name: 'inspector'
  });

  const disclosure = useDisclosure(false);

  let display = (
    <Button sx={{ minWidth: 200 }} onClick={disclosure.open} size={'small'} startIcon={<MuiIcon.Search />}>
      Choose Inspector
    </Button>
  );
  if (field.value != null) {
    if (field.value.user != null) {
      // @ts-ignore
      display = <UserCard user={field.value.user} role={null} />;
    } else if (field.value.vendor != null) {
      // @ts-ignore
      display = <VendorCard vendor={field.value.vendor} />;
    }
  }

  return (
    <>
      <Stack gap={1}>
        <Stack direction={'row'} justifyContent={'space-between'}>
          <Typography variant={'body2'} fontWeight={600}>
            Inspector
          </Typography>
          {field.value != null && (
            <Button size={'small'} variant={'text'} onClick={() => field.onChange(null)}>
              Change
            </Button>
          )}
        </Stack>

        <Box px={1}>{display}</Box>
        {error != null && <Typography color={'error'}>{error.message}</Typography>}
      </Stack>
      <InspectorSelectionModal
        disclosure={disclosure}
        onSelect={(val) => {
          // @ts-ignore
          field.onChange(val);
          disclosure.close();
        }}
      />
    </>
  );
};

export type InspectorSelection = {
  user: UserSummary;
  company: ManagementCompany;
  vendor: VendorSummary;
};

type InspectorSelectionStage = 'root' | 'coworkers' | 'vendors';

export const InspectorSelectionModal = ({
  disclosure,
  onSelect
}: {
  disclosure: Disclosure;
  onSelect: (val: InspectorSelection) => void;
}) => {
  return (
    <RooDialog open={disclosure.isOpen} onClose={disclosure.close} maxWidth={'xl'} fullWidth>
      <RooDialog.Title onClose={disclosure.close}>Select Inspector</RooDialog.Title>
      <ModalBody disclosure={disclosure} onSelect={onSelect} />
    </RooDialog>
  );
};

const ModalBody = ({
  disclosure,
  onSelect
}: {
  disclosure: Disclosure;
  onSelect: (val: InspectorSelection) => void;
}) => {
  const [view, setView] = React.useState<InspectorSelectionStage>('root');
  return (
    <Stack>
      {view === 'root' && <StageRoot disclosure={disclosure} onSelect={onSelect} setView={setView} />}
      {view === 'coworkers' && <StageCoworkers disclosure={disclosure} onSelect={onSelect} setView={setView} />}
      {view === 'vendors' && <StageVendor disclosure={disclosure} onSelect={onSelect} setView={setView} />}
    </Stack>
  );
};

const StageRoot = ({
  disclosure,
  onSelect,
  setView
}: {
  disclosure: Disclosure;
  onSelect: (val: InspectorSelection) => void;
  setView: (val: InspectorSelectionStage) => void;
}) => {
  const currentUser = useCurrentUser();
  const currentCompanyCtx = useAppStore((x) => x.companyContext);
  const currentVendor = useCurrentVendor();
  return (
    <RooDialog.Content>
      <Stack justifyContent={'center'} alignItems={'center'} style={{ flex: 1 }}>
        <Stack gap={3} style={{ width: 600 }}>
          <Center>
            <Typography variant={'h6'}>Choose Inspector</Typography>
          </Center>

          <Stack gap={2} mt={1} px={3}>
            <Button
              size={'large'}
              onClick={() => {
                onSelect({
                  user: currentUser,
                  company:
                    currentCompanyCtx.companyType === CompanyType.ManagementCompany
                      ? currentUser.managementCompany
                      : null,
                  vendor: currentCompanyCtx.companyType === CompanyType.Vendor ? currentVendor : null
                });
                disclosure.close();
              }}
            >
              Assign Myself
            </Button>
            <Button size={'large'} onClick={() => setView('coworkers')}>
              Assign Coworker
            </Button>
            <Button size={'large'} onClick={() => setView('vendors')}>
              Search Vendors
            </Button>
          </Stack>

          <Button sx={{ mx: 3 }} color={'muted'} size={'large'} onClick={() => disclosure.close()}>
            Cancel
          </Button>
        </Stack>
      </Stack>
    </RooDialog.Content>
  );
};

const StageCoworkers = ({
  disclosure,
  onSelect,
  setView
}: {
  disclosure: Disclosure;
  onSelect: (val: InspectorSelection) => void;
  setView: (val: InspectorSelectionStage) => void;
}) => {
  const currentCompanyCtx = useAppStore((x) => x.companyContext);
  return (
    <RooDialog.Content>
      <Stack alignItems={'center'} justifyContent={'center'} sx={{ flex: 1 }}>
        <Stack sx={{ flex: 1, maxWidth: 600 }}>
          <Stack gap={4}>
            <Center>
              <Typography variant={'h6'}>Choose a coworker</Typography>
            </Center>

            <Stack gap={2} alignItems={'center'} style={{ flex: 1 }}>
              {currentCompanyCtx.coworkers.map((coworker) => (
                <Stack direction={'row'} alignItems={'center'} gap={4}>
                  <UserCard user={coworker} role={null} />
                  <Button
                    onClick={() => {
                      onSelect({
                        user: coworker,
                        company: currentCompanyCtx.managementCompany,
                        vendor: currentCompanyCtx.vendor
                      });
                    }}
                  >
                    Choose
                  </Button>
                </Stack>
              ))}
            </Stack>
            <Button sx={{ mx: 3 }} color={'muted'} size={'large'} onClick={() => disclosure.close()}>
              Cancel
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </RooDialog.Content>
  );
};

const StageVendor = ({
  disclosure,
  onSelect,
  setView
}: {
  disclosure: Disclosure;
  onSelect: (val: InspectorSelection) => void;
  setView: (val: InspectorSelectionStage) => void;
}) => {
  const currentUser = useCurrentUser();
  const { status, data } = useQuery(['all-vendors'], {
    queryFn: async () => {
      const [forUser, fromPool] = await Promise.all([
        apiProvider.vendorClient.vendorsForUser(currentUser.id),
        apiProvider.vendorClient.vendorPoolForUser(currentUser.id)
      ]);

      return [...forUser, ...fromPool];
    }
  });

  const { selectedIds, ...selectorRest } = useVendorSelector({
    lockedVendors: [],
    selectionMode: 'single',
    referenceAddress: null,
    excludedVendors: []
  });

  return (
    <>
      <RooDialog.Content>
        {status === 'loading' && <CenteredLoader />}
        {status === 'success' && <VendorSelector selectedIds={selectedIds} {...selectorRest} />}
      </RooDialog.Content>
      <RooDialog.Actions>
        <RooButton variant={'secondary'} onClick={() => setView('root')}>
          Cancel
        </RooButton>
        {status === 'success' && (
          <RooButton
            disabled={selectedIds.length === 0}
            onClick={() => {
              const match = data.find((x) => x.id === selectedIds[0]);
              if (match != null) {
                onSelect({
                  user: null,
                  company: null,
                  vendor: match
                });
              }
            }}
          >
            Choose
          </RooButton>
        )}
      </RooDialog.Actions>
    </>
  );
};
