import { RooDialog, SaveButtonMui } from '../../../../../components';
import { Button, Divider, Grid, Paper, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Control, useFieldArray } from 'react-hook-form';
import { apiProvider } from '../../../../../shared/api/apiProvider';
import {
  AttachmentEntityType,
  ChecklistDefaultsPayload,
  ChecklistDefaultsResponse,
  ChecklistLinePayload,
  LineSource,
  MarkWorkCompletePayload
} from '../../../../../shared/api/clients';
import { DeepRequired, showSuccess } from '../../../../../shared/utils';
import { useRequiredRooForm } from '../../../../../components/form/utils';
import { useCurrentIssue } from '../../../shared/CurrentIssueContext';
import { z } from 'zod';
import { AttachmentFileSchema } from '../types';
import { UseFormTrigger, UseFormWatch } from 'react-hook-form/dist/types/form';
import { FieldMuiText, Required } from '../../../../../components/form';
import { FieldYesNoButtonGroup } from '../../../../../components/form/FieldYesNoButtonGroup';
import { FieldMuiNumber } from '../../../../../components/form/FieldMuiNumber';
import { FileAreaField } from '../FileAreaField';
import { useQuery } from '@tanstack/react-query';
import { CenteredLoader } from '../../../../../components/CenteredLoader';
import { InlineError } from '../../../../../components/InlineError';
import { Form } from 'react-bootstrap';
import { useUniqueId } from '@roo/lib';

const ChecklistLineSchema = z
  .object({
    description: z.string(),
    isComplete: z.enum(['yes', 'no']),
    notes: z.string().nullish(),
    value: z.number().nullish(),
    initialValue: z.number().nullish(),
    entityType: z.nativeEnum(LineSource),
    entityId: z.string().nullish(),
    beforeFiles: z.array(AttachmentFileSchema),
    afterFiles: z.array(AttachmentFileSchema)
  })
  .superRefine((val, ctx) => {
    if (val.isComplete == null) {
      return;
    }

    if (val.isComplete === 'yes') {
      if (val.value == null) {
        ctx.addIssue({
          message: 'Required',
          code: 'custom',
          path: ['value']
        });
      }
      if (val.afterFiles == null || val.afterFiles.length === 0) {
        ctx.addIssue({
          message: 'Required',
          code: 'custom',
          path: ['afterFiles']
        });
      }
    } else {
      if ((val.notes?.trim() ?? '') === '') {
        ctx.addIssue({
          message: 'Required',
          code: 'custom',
          path: ['notes']
        });
      }
    }
  });

type ChecklistLineDefinition = DeepRequired<z.infer<typeof ChecklistLineSchema>>;

const ChecklistSchema = z.object({
  lines: z.array(ChecklistLineSchema)
});

type ChecklistDefinition = DeepRequired<z.infer<typeof ChecklistSchema>>;

export const CompleteWorkStepForm = ({
  onClose,
  onContinue
}: {
  onClose: () => void;
  onContinue: (invoiceId: string) => void;
}) => {
  const { issue } = useCurrentIssue();
  const uid = useUniqueId();
  const { data, status } = useQuery(['issues', issue.id, 'checklist', 'form-defaults', uid], {
    queryFn: () =>
      apiProvider.issues.workflow.getChecklistDefaults(
        new ChecklistDefaultsPayload({
          issueId: issue.id
        })
      ),
    cacheTime: Infinity,
    staleTime: Infinity
  });

  if (status === 'loading') {
    return (
      <>
        <RooDialog.Title onClose={onClose}>Let us know how the job went</RooDialog.Title>;
        <RooDialog.Content>
          <CenteredLoader />
        </RooDialog.Content>
      </>
    );
  }

  if (status === 'error') {
    return (
      <>
        <RooDialog.Title onClose={onClose}>Let us know how the job went</RooDialog.Title>;
        <RooDialog.Content>
          <InlineError />
        </RooDialog.Content>
      </>
    );
  }

  return <CompleteWorkStepFormInner onClose={onClose} onContinue={onContinue} defaults={data} />;
};

const CompleteWorkStepFormInner = ({
  onClose,
  onContinue,
  defaults
}: {
  onClose: () => void;
  onContinue: (invoiceId: string) => void;
  defaults: ChecklistDefaultsResponse;
}) => {
  const { issue, onIssueUpdate } = useCurrentIssue();
  const [isSaving, setIsSaving] = useState(false);

  const { handleSubmit, control, watch, trigger } = useRequiredRooForm(ChecklistSchema, {
    defaultValues: {
      lines: defaults.lines.map((x) => ({
        description: x.description,
        value: x.value,
        initialValue: x.initialValue,
        entityType: x.sourceEntityType,
        entityId: x.sourceEntityId,
        isComplete: x.isComplete == null ? null : x.isComplete ? ('yes' as const) : ('no' as const),
        notes: x.notes,
        beforeFiles: x.beforeFiles,
        afterFiles: x.afterFiles
      }))
    }
  });
  const { fields } = useFieldArray({
    control,
    name: 'lines'
  });

  const save = async (values: ChecklistDefinition) => {
    setIsSaving(true);
    try {
      const result = await apiProvider.issues.workflow.markWorkComplete(
        new MarkWorkCompletePayload({
          issueId: issue.id,
          oldStatus: issue.status,
          lines: values.lines.map(
            (line) =>
              new ChecklistLinePayload({
                description: line.description,
                value: line.value,
                initialValue: line.initialValue,
                isComplete: line.isComplete === 'yes',
                notes: line.notes,
                beforeFileIds: line.beforeFiles.map((f) => f.id),
                afterFileIds: line.isComplete === 'no' ? [] : line.afterFiles.map((f) => f.id),
                sourceEntityId: line.entityId,
                sourceEntityType: line.entityType
              })
          )
        })
      );
      onIssueUpdate(result.issueView);
      showSuccess();
      onContinue(result.createdInvoiceId);
    } catch (e) {}
    setIsSaving(false);
  };
  return (
    <Form noValidate style={{ marginBottom: 'initial' }} onSubmit={handleSubmit(save)}>
      <RooDialog.Title onClose={onClose}>Let us know how the job went</RooDialog.Title>
      <RooDialog.Content>
        <Stack spacing={2} sx={{ pt: 2 }}>
          {fields.map((line, index) => (
            <ChecklistEntry
              key={index}
              control={control}
              index={index}
              watch={watch}
              totalLines={fields.length}
              trigger={trigger}
            />
          ))}
        </Stack>
      </RooDialog.Content>
      <RooDialog.Actions>
        <SaveButtonMui control={control}>Continue</SaveButtonMui>
        <Button color="muted" onClick={onClose} disabled={isSaving}>
          Cancel
        </Button>
      </RooDialog.Actions>
    </Form>
  );
};

const ChecklistEntry = ({
  control,
  index,
  totalLines,
  watch,
  trigger
}: {
  control: Control<ChecklistDefinition>;
  index: number;
  totalLines: number;
  watch: UseFormWatch<ChecklistDefinition>;
  trigger: UseFormTrigger<ChecklistDefinition>;
}) => {
  const isComplete = watch(`lines.${index}.isComplete` as const);
  return (
    <Paper sx={{ p: 2 }}>
      <Stack spacing={2}>
        <Typography variant={'caption'}>
          Line {index + 1}/{totalLines}
        </Typography>
        <FieldMuiText
          fullWidth
          InputProps={{
            readOnly: true
          }}
          control={control}
          name={`lines.${index}.description` as const}
          label={'Description'}
          placeholder={'Description'}
        />

        <Stack spacing={2} direction={{ xs: 'column', md: 'row' }} alignItems={'center'} justifyContent={'center'}>
          <Typography>Is this task complete?</Typography>
          <FieldYesNoButtonGroup
            control={control}
            name={`lines.${index}.isComplete` as const}
            // if we don't trigger validation manually the other option's field errors will be preserved...
            onChange={() => trigger(`lines.${index}`)}
          />
        </Stack>
        {isComplete === 'no' && (
          <>
            <FieldMuiText
              fullWidth
              multiline
              control={control}
              name={`lines.${index}.notes` as const}
              required
              label={'Why not?'}
              placeholder={'Explain why this task was not completed'}
            />
          </>
        )}
        {isComplete === 'yes' && (
          <>
            <Stack spacing={1}>
              <Typography align={'center'}>How much did it cost?</Typography>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <FieldMuiNumber
                    fullWidth
                    control={control}
                    disabled
                    name={`lines.${index}.initialValue` as const}
                    label={'Estimated'}
                    placeholder={'N/A'}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FieldMuiNumber fullWidth control={control} name={`lines.${index}.value` as const} label={'Actual'} />
                </Grid>
              </Grid>
            </Stack>
            <Stack spacing={1}>
              <Typography align={'center'}>Pictures</Typography>
              <Grid container>
                <Grid item xs>
                  <Stack>
                    <Typography fontSize={'small'}>Before job</Typography>
                    <FileAreaField
                      entityType={AttachmentEntityType.EstimateLineItem}
                      control={control}
                      name={`lines.${index}.beforeFiles` as const}
                    />
                  </Stack>
                </Grid>
                <Divider sx={{ mx: 2 }} orientation="vertical" flexItem />
                <Grid item xs>
                  <Stack sx={{ height: '100%' }}>
                    <Typography fontSize={'small'}>After job</Typography>
                    <FileAreaField
                      entityType={AttachmentEntityType.EstimateLineItem}
                      control={control}
                      name={`lines.${index}.afterFiles` as const}
                    />
                  </Stack>
                </Grid>
              </Grid>
            </Stack>

            <FieldMuiText
              fullWidth
              multiline
              control={control}
              name={`lines.${index}.notes` as const}
              label={'Notes'}
              placeholder={'Notes'}
            />
          </>
        )}
      </Stack>
    </Paper>
  );
};
