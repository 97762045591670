import { createModalStore } from '../../../../../shared/store';
import React, { useState } from 'react';
import { issueActionList } from '../../../shared/types';
import { MuiIcon } from '../../../../../shared/icons';
import { InvoiceStatus, IssueAction } from '../../../../../shared/api/clients';
import { Button, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { RooDialog } from '../../../../../components';
import { InvoiceForm } from './InvoiceForm';
import { useCurrentIssue } from '../../../shared/CurrentIssueContext';

const useCreateInvoiceStore = createModalStore<{ isPartial: boolean }>();

export const CreateInvoiceModal = () => {
  const isVisible = useCreateInvoiceStore((x) => x.visible);
  const hide = useCreateInvoiceStore((x) => x.actions.hide);
  const payload = useCreateInvoiceStore((x) => x.payload);
  const theme = useTheme();
  const isLarge = useMediaQuery(theme.breakpoints.up('md'));
  const isPartial = payload?.isPartial ?? false;
  return (
    <RooDialog
      scroll={'paper'}
      fullScreen={!isLarge}
      maxWidth={isLarge ? 'md' : undefined}
      fullWidth
      open={isVisible}
      onClose={hide}
    >
      {isPartial && <RooDialog.Title onClose={hide}>Create Partial Invoice</RooDialog.Title>}
      {!isPartial && <RooDialog.Title onClose={hide}>Create Invoice</RooDialog.Title>}
      <RooDialog.Content>
        <InvoiceModalBody onHide={hide} isPartial={payload?.isPartial} />
      </RooDialog.Content>
      <RooDialog.Actions></RooDialog.Actions>
    </RooDialog>
  );
};

export const createInvoiceAction = issueActionList.register({
  Icon: MuiIcon.Add,
  color: 'primary',
  isDisabled: () => false,
  key: 'create-invoice',
  label: 'Invoice',
  isVisible: (ctx) => ctx.canAct(IssueAction.CreateInvoice),
  isDeprioritized: (ctx) =>
    ctx.visibleInvoices.filter((x) => x.status === InvoiceStatus.Draft || x.status === InvoiceStatus.PendingReview)
      .length > 0,
  onClick: () => {
    useCreateInvoiceStore.getState().actions.show({ isPartial: false });
  },
  modalToRender: <CreateInvoiceModal />
});

export const createPartialInvoiceAction = issueActionList.register({
  Icon: MuiIcon.Add,
  color: 'primary',
  isDisabled: () => false,
  key: 'create-partial-invoice',
  label: 'Partial Invoice',
  isVisible: (ctx) => ctx.canAct(IssueAction.CreatePartialInvoice),
  isDeprioritized: (ctx) =>
    ctx.visibleInvoices.filter((x) => x.status === InvoiceStatus.Draft || x.status === InvoiceStatus.PendingReview)
      .length > 0,
  onClick: () => {
    useCreateInvoiceStore.getState().actions.show({ isPartial: true });
  },
  modalToRender: <CreateInvoiceModal />
});

const InvoiceModalBody = ({ onHide, isPartial }: { onHide: () => void; isPartial: boolean }) => {
  const { visibleInvoices } = useCurrentIssue();
  const [{ view, includeImplicit }, setState] = useState<{ view: 'confirm' | 'form'; includeImplicit: boolean }>({
    view: visibleInvoices.length > 0 && !isPartial ? 'confirm' : 'form',
    includeImplicit: visibleInvoices.length > 0 && !isPartial ? null : true
  });

  return (
    <>
      {view === 'confirm' && (
        <InvoiceConfirmation setAnswer={(include) => setState({ view: 'form', includeImplicit: include })} />
      )}
      {view === 'form' && (
        <InvoiceForm
          onComplete={onHide}
          allowImplicitLines={includeImplicit}
          invoice={null}
          preferMatchDraft={true}
          isPartial={isPartial}
        />
      )}
    </>
  );
};

export const InvoiceConfirmation = ({ setAnswer }: { setAnswer: (include: boolean) => void }) => {
  return (
    <Stack alignItems={'center'} justifyContent={'center'} gap={2}>
      <Typography variant={'h6'}>Do you want to add all items in your existing invoice?</Typography>
      <Stack gap={2} direction={'row'}>
        <Button className={'Button-Basic'} onClick={() => setAnswer(true)} color={'primary'} variant={'contained'}>
          Yes
        </Button>
        <Button className={'Button-Basic'} onClick={() => setAnswer(false)} color={'warning'} variant={'contained'}>
          No
        </Button>
      </Stack>
    </Stack>
  );
};
