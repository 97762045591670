import { TextField } from '@mui/material';
import { OutlinedTextFieldProps } from '@mui/material/TextField/TextField';
import { useEffect, useState } from 'react';
import { FieldValues, useController } from 'react-hook-form';
import { getMuiHelperText } from 'components/form/ErrorMessage';
import { ControlProps } from 'components/form/types';

type BaseProps = Omit<OutlinedTextFieldProps, 'id' | 'type' | 'name' | 'onChange' | 'value' | 'onBlur' | 'variant'>;
type FinalProps<TForm extends FieldValues> = ControlProps<TForm> & BaseProps & { type?: 'text' | 'password' | 'email' };
export type FieldMuiNumberProps<TForm extends FieldValues> = FinalProps<TForm>;

const transform = {
  input: (value: number) => {
    return isNaN(value) || value === null ? '' : value.toString();
  },
  output: (str: string) => {
    const output = parseFloat(str);
    return isNaN(output) ? null : output;
  }
};

export const FieldMuiNumber = <T extends FieldValues>({ name, control, helperText, label, ...rest }: FinalProps<T>) => {
  const {
    field,
    fieldState: { error }
  } = useController({
    control: control,
    name: name
  });
  const [userInput, setUserInput] = useState('');

  useEffect(() => {
    const parsedUserVal = transform.output(userInput);
    const isBadState = isNaN(parsedUserVal);
    if (parsedUserVal !== field.value && !isBadState) {
      setUserInput(transform.input(field.value));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.value]);
  const finalHelperText = getMuiHelperText(helperText, error);
  return (
    <TextField
      label={label}
      name={name}
      type={'number'}
      inputProps={{
        step: '0.01'
      }}
      error={error != null}
      helperText={finalHelperText}
      value={userInput}
      onChange={(e) => {
        const textValue = e.target.value;
        setUserInput(textValue);
        // @ts-ignore
        field.onChange(transform.output(textValue));
      }}
      onBlur={field.onBlur}
      {...rest}
    />
  );
};
