import { Box, Breadcrumbs, Typography } from '@mui/material';
import { useEffect, useRef } from 'react';
import { useAppStore } from 'shared/store';
import theme from 'shared/theme';
import { RouteDestination, Routes } from 'shared/routing';
import { RouterLink } from 'components/Links';

export type Breadcrumb = {
  text: string;
  destination?: RouteDestination;
};

export const NavSlim = ({ breadcrumbs }: { breadcrumbs: Breadcrumb[] }) => {
  const setBreadcrumbTitle = useAppStore((x) => x.actions.setBreadCrumbTitle);
  const breadcrumbRef = useRef(breadcrumbs);

  useEffect(() => {
    const lastBreadcrumb = breadcrumbRef.current[breadcrumbRef.current.length - 1];
    // set breadcrumb title
    setBreadcrumbTitle(lastBreadcrumb.text);
  }, [setBreadcrumbTitle]);

  return (
    <Box pl={3}>
      <Breadcrumbs aria-label="breadcrumb" separator="·">
        <RouterLink to={Routes.Dashboard} style={{ color: 'gray' }}>
          Overview
        </RouterLink>
        {breadcrumbs
          .filter((x) => x != null)
          .map((crumb, index) => {
            const last = index === breadcrumbs.length - 1;
            return last ? (
              <Typography style={{ color: theme.palette.primary.main }} key={index}>
                {crumb.text}
              </Typography>
            ) : (
              <RouterLink style={{ color: 'inherit' }} to={crumb.destination} key={index}>
                {crumb.text}
              </RouterLink>
            );
          })}
      </Breadcrumbs>
    </Box>
  );
};
