import Cookies from 'js-cookie';
import { Routes } from 'shared/routing';
import { queryClient } from '../queryClient';
import { makeUuid } from '@roo/lib';

const META_KEY = 'auth_meta';
const SESSION_COOKIE_KEY = 'auth_meta_session';
const LAST_LOGIN_KEY = 'auth_last_login';

type AuthToken = {
  token: string;
  boundToSession: string;
};

type AuthMeta = {
  mainToken: AuthToken;
  impersonationToken: AuthToken;
};

export class AuthManager {
  _sessionId: string;
  _isImpersonating: boolean;
  _cachedMeta: string;

  constructor() {
    this._sessionId = Cookies.get(SESSION_COOKIE_KEY);
    if (this._sessionId == null) {
      this._sessionId = makeUuid();
      Cookies.set(SESSION_COOKIE_KEY, this._sessionId);
    }

    const meta = AuthManager.getMeta();
    if (meta != null) {
      let needsUpdate = false;
      if (meta.impersonationToken && meta.impersonationToken.boundToSession !== this._sessionId) {
        meta.impersonationToken = null;
        needsUpdate = true;
      }

      if (meta.mainToken?.boundToSession != null && meta.mainToken.boundToSession !== this._sessionId) {
        meta.mainToken = null;
        needsUpdate = true;
      }

      if (needsUpdate) {
        this.setMeta(meta);
      }
    }

    this._cachedMeta = localStorage.getItem(META_KEY);
    this._isImpersonating = meta?.impersonationToken != null;

    window.addEventListener('storage', (ev) => {
      if (ev.storageArea !== localStorage || ev.key !== META_KEY) {
        return;
      }

      this.onMetaChange();
    });
  }

  public logOut(preserveUrl: boolean) {
    const meta = AuthManager.getMeta();
    queryClient.removeQueries();
    if (meta?.impersonationToken) {
      meta.impersonationToken = null;
      this.setMeta(meta);
      AuthManager.redirectToLanding();
    } else {
      this.setMeta(null);
      AuthManager.redirectToLogIn(preserveUrl);
    }
  }

  public logIn(token: string, rememberMe: boolean) {
    this.setMeta({
      mainToken: {
        boundToSession: rememberMe ? null : this._sessionId,
        token: token
      },
      impersonationToken: null
    });
  }

  public isImpersonating() {
    return this._isImpersonating;
  }

  public onMetaChange() {
    const metaStr = localStorage.getItem(META_KEY);
    if (this._cachedMeta === metaStr) {
      return;
    }

    // if we're here, it means localstorage got updated underneath us
    // if the user was logged out and now is logged in, we need to redirect to landing
    // if the user was logged in and is now logged out, we need to redirect to login
    // if the impersonation status changed, we need to redirect to landing
    // since all the states transitions we're interested in end up with a hard reload, we don't need to update the cache

    const oldMeta = JSON.parse(this._cachedMeta) as AuthMeta;
    const newMeta = JSON.parse(metaStr) as AuthMeta;

    if (oldMeta?.mainToken == null && newMeta?.mainToken != null) {
      AuthManager.redirectToLanding();
    }

    if (oldMeta?.mainToken != null && newMeta?.mainToken == null) {
      AuthManager.redirectToLogIn(true);
    }

    if (oldMeta?.impersonationToken?.token !== newMeta?.impersonationToken?.token) {
      AuthManager.redirectToLanding();
    }
  }

  public startImpersonation(token: string, sendToLanding: boolean) {
    const meta = AuthManager.getMeta();
    meta.impersonationToken = {
      token: token,
      boundToSession: this._sessionId
    };

    this.setMeta(meta);
    if (sendToLanding) {
      AuthManager.redirectToLanding();
    } else {
      window.location.reload();
    }
  }

  public stopImpersonation() {
    const meta = AuthManager.getMeta();
    queryClient.removeQueries();
    if (meta?.impersonationToken) {
      meta.impersonationToken = null;
      this.setMeta(meta);
      AuthManager.redirectToLanding();
    }
  }

  public getToken() {
    const meta = AuthManager.getMeta();
    return meta?.impersonationToken?.token ?? meta?.mainToken?.token;
  }

  public getLastLogin() {
    return localStorage.getItem(LAST_LOGIN_KEY);
  }

  public setLastLogin(lastLogin: string) {
    if (lastLogin == null || lastLogin === '') {
      localStorage.removeItem(LAST_LOGIN_KEY);
    } else {
      localStorage.setItem(LAST_LOGIN_KEY, lastLogin);
    }
  }

  static curr: AuthManager;

  static get instance() {
    if (this.curr == null) {
      this.curr = new AuthManager();
    }

    return this.curr;
  }

  private static getMeta() {
    const metaStr = localStorage.getItem(META_KEY);
    return JSON.parse(metaStr) as AuthMeta;
  }

  private setMeta(meta: AuthMeta) {
    const tokenStr = JSON.stringify(meta);
    this._cachedMeta = tokenStr;
    localStorage.setItem(META_KEY, tokenStr);
  }

  private static redirectToLanding() {
    window.location.href = '/';
  }

  private static redirectToLogIn(preserveUrl: boolean) {
    if (window.location.pathname === Routes.LogIn) {
      throw Error('already at login');
    }

    let href = Routes.LogIn;
    if (preserveUrl && (window.location.pathname !== '/' || !!window.location.search)) {
      href += `?returnUrl=${encodeURIComponent(window.location.pathname + window.location.search)}`;
    }

    window.location.href = href;
  }
}
