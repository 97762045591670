import { FieldTextArea, useRooForm } from 'components/form';
import { RooIcon } from 'shared/icons';
import { useRef, useState } from 'react';
import { Button, Col, Form, FormGroup, Modal, ModalFooter, Row } from 'react-bootstrap';
import { apiProvider } from 'shared/api/apiProvider';
import { useAppStore } from 'shared/store';
import { SaveButton } from 'components';
import { ContactForm, ContactSchema, showSuccess } from 'shared/utils';
import { useWatchGlobalModal } from './modalStore';
import Grid2 from '@mui/material/Unstable_Grid2';
import { Stack } from '@mui/material';

export const PropertyContactModal = () => {
  //TODO: move this into the props folder and convert to RooDialog whenever Ricky is done with it
  const user = useAppStore((x) => x.userDetails);
  const { isVisible, toggle } = useWatchGlobalModal('propertyHelp');
  const { handleSubmit, formState, control, reset } = useRooForm(ContactSchema);

  const [selectedFile, setSelectedFile] = useState(null);

  const submit = async (values: ContactForm) => {
    try {
      await apiProvider.usersClient.helpPropertyManager(user.managementCompany.id, values.notes, selectedFile);
      reset();
      toggle();
      showSuccess('✨ Your file has been sent to our team. We will help you on the property upload process!');
    } catch (err) {}
  };

  const myRefname = useRef(null);

  const handleClick = () => {
    myRefname.current.click();
  };

  return (
    <Modal show={isVisible} onHide={toggle} className={'modal-dialog-centered'}>
      <Form style={{ marginBottom: 'initial' }} onSubmit={handleSubmit(submit)}>
        <Modal.Header closeButton>
          <>Send Us Your File</>
        </Modal.Header>
        <Modal.Body>
          <Stack spacing={2}>
            <FieldTextArea
              control={control}
              name={'notes'}
              label={'Notes'}
              placeholder={'We are glad to help you! Please give us a brief description of your file.'}
            />
            <Stack direction={'row'}>
              <input
                ref={myRefname}
                style={{ position: 'absolute', cursor: 'pointer', marginLeft: '28px', width: '434px' }}
                type={'file'}
                onChange={(e) => setSelectedFile(e.target.files[0])}
                name={'propertiesFile'}
              />
              <Button className="btn-custom" style={{ position: 'relative', zIndex: 1000 }} onClick={handleClick}>
                <RooIcon icon={['fas', 'file-import']} /> <b>Upload file</b>
              </Button>
            </Stack>
          </Stack>
        </Modal.Body>
        <ModalFooter>
          <SaveButton className="btn-custom" control={control} style={{ fontWeight: 'bold' }}>
            Submit
          </SaveButton>
          <Button disabled={formState.isSubmitting || formState.isValidating} variant={'secondary'} onClick={toggle}>
            <b>Cancel</b>
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};
