import { Box, Button, Grid } from '@mui/material';
import { ElementRef, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { CapabilityType } from 'shared/api/clients';
import { ContentWrapper, NavSlim } from 'components';
import { UserList } from 'pages/UserLists/UserList';
import { MuiIcon } from '../../shared/icons';
import { useRequestGlobalModal } from '../../components/modals';
import { useIsSpecialUser } from '../../shared/store';

export const TenantList = () => {
  const listRef = useRef<ElementRef<typeof UserList>>(null);
  const [, toggleModal] = useRequestGlobalModal('inviteUser', {
    props: { capability: CapabilityType.Tenant },
    onSuccess: () => listRef.current?.refresh()
  });

  const isSpecialUser = useIsSpecialUser();

  return (
    <>
      <Helmet>
        <title>Tenants - Walkthroo</title>
      </Helmet>
      <NavSlim breadcrumbs={[{ text: 'Tenants List' }]} />
      <ContentWrapper className="container-property-list">
        <div
          className="d-flex justify-content-between align-items-center pt-3 pb-3 ps-4 pe-4"
          style={{ backgroundColor: 'white' }}
        >
          <Grid container className="align-items-center">
            <Grid item xs={5} md={3}>
              <h5 className="m-0">Tenants</h5>
            </Grid>
            <Grid item xs={7} md={9}>
              <Box className="d-flex align-items-center justify-content-end">
                {isSpecialUser && (
                  <Button size={'large'} color="primary" startIcon={<MuiIcon.Add />} onClick={toggleModal}>
                    Add Tenant
                  </Button>
                )}
              </Box>
            </Grid>
          </Grid>
        </div>
        <UserList ref={listRef} capability={CapabilityType.Tenant} />
      </ContentWrapper>
    </>
  );
};
