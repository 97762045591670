import React, { ReactNode, useState } from 'react';
import { DashboardStatCategory, Instant } from '../../shared/api/clients';
import { Button } from '@mui/material';
import { IssuesServerModal } from '../../components/Issues/IssuesServerModal';
import { MuiIcon } from '../../shared/icons';
import { IssuesExternalFilter } from '../../components/Issues/IssuesServerGrid';

export const IssueListButton = ({
  children,
  modalTitle,
  filters
}: {
  children: ReactNode;
  modalTitle: ReactNode;
  filters: IssuesExternalFilter;
}) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button onClick={() => setOpen(true)} variant={'text'} size={'medium'} startIcon={<MuiIcon.List />}>
        {children}
      </Button>
      <IssuesServerModal open={open} title={modalTitle} onClose={() => setOpen(false)} filters={filters} />
    </>
  );
};
