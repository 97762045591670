import { createModalStore } from 'shared/store';
import { RooDialog, SaveButtonMui } from '../../../components';
import React from 'react';
import { Stack } from '@mui/material';
import Button from '@mui/material/Button';
import { z } from 'zod';
import { ReassignInspectionPayload } from '../../../shared/api/clients';
import { DeepRequired } from '../../../shared/utils';
import { useRequiredRooForm } from '../../../components/form/utils';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { apiProvider } from '../../../shared/api/apiProvider';
import { InspectorField, InspectorSelection } from './InspectorField';

export const useReassignInspectionAssignmentModal = createModalStore<{ inspectionId: string }>();

export const ReassignInspectionModal = () => {
  const {
    visible,
    actions: { hide }
  } = useReassignInspectionAssignmentModal();
  return (
    <RooDialog open={visible} onClose={hide} maxWidth={'sm'} fullWidth>
      <RooDialog.Title onClose={hide}>Reassign Inspection</RooDialog.Title>
      <ModalBody />
    </RooDialog>
  );
};

export const ReassignInspectionSchema = z.object({
  inspector: z.custom<InspectorSelection>((x) => x != null, {
    message: 'Required'
  })
});
export type ReassignInspectionDefinition = DeepRequired<z.infer<typeof ReassignInspectionSchema>>;

const ModalBody = () => {
  const qc = useQueryClient();
  const {
    payload,
    actions: { hide }
  } = useReassignInspectionAssignmentModal();
  const { handleSubmit, control, formState } = useRequiredRooForm(ReassignInspectionSchema);

  const { mutateAsync } = useMutation({
    mutationFn: (values: ReassignInspectionDefinition) =>
      apiProvider.inspectionClient.reassignInspection(
        new ReassignInspectionPayload({
          inspectionId: payload.inspectionId,
          assignedCompanyId: values.inspector.company?.id,
          assignedUserId: values.inspector.user?.id,
          assignedVendorId: values.inspector.vendor?.id
        })
      ),
    onSuccess: (data) => {
      void qc.invalidateQueries(['inspections']);
      hide();
    }
  });

  return (
    <form noValidate onSubmit={handleSubmit((vals) => mutateAsync(vals))}>
      <RooDialog.Content>
        <Stack gap={3}>
          {/*@ts-ignore*/}
          <InspectorField control={control} />
        </Stack>
      </RooDialog.Content>
      <RooDialog.Actions>
        <SaveButtonMui control={control}>Save</SaveButtonMui>
        <Button color="muted" disabled={formState.isSubmitting || formState.isValidating} size="small" onClick={hide}>
          Cancel
        </Button>
      </RooDialog.Actions>
    </form>
  );
};
