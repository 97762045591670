import { ColoredEnumDisplay, createMap, EnumMap } from './shared';
import { InspectionStatus } from '../../api/clients';

const InternalMapInspectionStatus: EnumMap<typeof InspectionStatus, ColoredEnumDisplay> = {
  Invited: {
    display: 'Invited',
    color: 'warning'
  },
  Declined: {
    display: 'Declined',
    color: 'danger'
  },
  Accepted: {
    display: 'Accepted',
    color: 'primary'
  },
  Done: {
    display: 'Done',
    color: 'success'
  },
  Started: {
    display: 'Started',
    color: 'primary'
  },
  Created: {
    display: 'Created',
    color: 'secondary'
  },
  Unknown: {
    display: 'Unknown',
    color: 'secondary'
  }
};

export const EnumMapInspectionStatus = createMap<InspectionStatus, ColoredEnumDisplay>(
  InspectionStatus,
  InternalMapInspectionStatus
);
