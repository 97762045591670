import { Button, Chip, Popover, Stack } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { StaticDatePicker } from '@mui/x-date-pickers';
import { addDays, startOfDay, startOfMonth, startOfYear } from 'date-fns';
import { useEffect, useRef, useState } from 'react';
import { Disclosure, useDisclosure } from '@roo/lib';

const now = () => new Date();
const today = () => startOfDay(now());

type IntervalKey = '30d' | 'mtd' | 'ytd' | 'custom' | 'all-time';
type IntervalType = 'static' | 'dynamic';
type DateRange = { startDate: Date; endDate: Date };
export type SelectedDateRange = { id: IntervalKey } & DateRange;

type IntervalDefinition = { id: IntervalKey; type: IntervalType; label: string } & (
  | { type: 'static'; makeInterval: () => DateRange }
  | { type: 'dynamic'; makeInterval: (start: Date, end: Date) => DateRange }
);

const intervals: IntervalDefinition[] = [
  {
    id: 'mtd',
    label: 'Current Month',
    type: 'static',
    makeInterval: () => ({ startDate: startOfMonth(now()), endDate: today() })
  },
  {
    id: '30d',
    label: 'Last 30d',
    type: 'static',
    makeInterval: () => ({ startDate: addDays(today(), -30), endDate: today() })
  },
  {
    id: 'ytd',
    label: 'Current Year',
    type: 'static',
    makeInterval: () => ({ startDate: startOfYear(now()), endDate: today() })
  },
  {
    id: 'all-time',
    label: 'All Time',
    type: 'static',
    makeInterval: () => ({ startDate: null, endDate: null })
  },
  { id: 'custom', label: 'Custom', type: 'dynamic', makeInterval: (start, end) => ({ startDate: start, endDate: end }) }
];

export const makeDefaultChipInterval = (key: IntervalKey): SelectedDateRange => {
  const match = intervals.find((x) => x.id === key);
  return { id: match.id, ...match.makeInterval(null, null) };
};

export const DateRangeChips = ({
  allowedTypes = null,
  value,
  setValue
}: {
  value: SelectedDateRange;
  setValue: (val: SelectedDateRange) => void;
  allowedTypes?: IntervalKey[];
}) => {
  return (
    <>
      <Stack gap={2} direction={'row'} justifyContent={'center'} alignItems={'center'}>
        {intervals
          .filter((x) => allowedTypes == null || allowedTypes.includes(x.id))
          .map((interval) => {
            if (interval.type === 'static') {
              return (
                <Chip
                  key={interval.id}
                  color={value?.id === interval.id ? 'primary' : 'default'}
                  label={interval.label}
                  onClick={() => {
                    setValue({ id: interval.id, ...interval.makeInterval() });
                  }}
                />
              );
            }

            if (interval.type === 'dynamic') {
              return (
                <DynamicRangeChip key={interval.id} selection={value} definition={interval} setSelection={setValue} />
              );
            }

            return undefined;
          })}
      </Stack>
    </>
  );
};

const DynamicRangeChip = ({
  definition,
  selection,
  setSelection
}: {
  selection: SelectedDateRange;
  setSelection: (val: SelectedDateRange) => void;
  definition: IntervalDefinition & { type: 'dynamic' };
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const disclosure = useDisclosure(false);
  return (
    <>
      <Chip
        key={definition.id}
        ref={ref}
        color={selection?.id === definition.id ? 'primary' : 'default'}
        label={definition.label}
        onClick={() => {
          disclosure.open();
        }}
      />
      <DateRangePopover
        anchorEl={ref?.current}
        selection={selection}
        setSelection={setSelection}
        definition={definition}
        disclosure={disclosure}
      />
    </>
  );
};

const DateRangePopover = ({
  disclosure,
  anchorEl,
  definition,
  selection,
  setSelection
}: {
  anchorEl: HTMLDivElement;
  selection: SelectedDateRange;
  disclosure: Disclosure;
  setSelection: (val: SelectedDateRange) => void;
  definition: IntervalDefinition & { type: 'dynamic' };
}) => {
  const [start, setStart] = useState<Date>(selection.id === definition.id ? selection.startDate : null);
  const [end, setEnd] = useState<Date>(selection.id === definition.id ? selection.endDate : null);

  useEffect(() => {
    if (selection.id === definition.id) {
      setStart(selection.startDate);
      setEnd(selection.endDate);
    } else {
      setStart(null);
      setEnd(null);
    }
  }, [disclosure.isOpen, selection.id, definition.id, selection.startDate, selection.endDate]);

  const canSave = start != null && end != null && start <= end;

  return (
    <Popover open={disclosure.isOpen} onClose={disclosure.close} anchorEl={anchorEl}>
      <Stack>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Stack gap={2} justifyContent={'center'} alignItems={'center'} direction={'row'}>
            <StaticDatePicker
              displayStaticWrapperAs="desktop"
              onChange={(val) => setStart(startOfDay(val))}
              value={start}
            />
            <StaticDatePicker
              displayStaticWrapperAs="desktop"
              onChange={(val) => setEnd(startOfDay(val))}
              value={end}
            />
          </Stack>
        </LocalizationProvider>
        <Stack px={2} py={1} flexDirection={'row'} justifyContent={'flex-end'}>
          <Button
            disabled={!canSave}
            color="primary"
            onClick={() => {
              setSelection({
                id: definition.id,
                startDate: start,
                endDate: end
              });
              disclosure.close();
            }}
          >
            Apply
          </Button>
        </Stack>
      </Stack>
    </Popover>
  );
};
