import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { apiProvider } from 'shared/api/apiProvider';
import { AuthTokenValidationResult, ValidateAuthTokenPayload } from 'shared/api/clients';
import { Routes } from 'shared/routing';
import { AuthManager } from 'shared/store';
import { useQueryParams } from 'shared/utils';
import { AuthPage } from 'pages/Auth/AuthPage';

type LandingQuery = {
  originalUrl: string;
  authToken: string;
  userId: string;
};

type Status = 'loading' | 'different-user' | 'token-expired' | 'error';

const redirect = (url: string) => {
  window.location.href = url;
};

export const LandingPage = () => {
  const { originalUrl, authToken, userId } = useQueryParams<LandingQuery>();
  const [jwt, setJwt] = useState<string>();
  const [state, setState] = useState<Status>('loading');

  useEffect(() => {
    const load = async () => {
      try {
        const result = await apiProvider.authClient.validateAuthToken(
          new ValidateAuthTokenPayload({
            authToken: authToken,
            userId: userId
          })
        );
        setJwt(result.jwt);

        switch (result.result) {
          case AuthTokenValidationResult.AlreadyLoggedIn:
            redirect(originalUrl);
            break;
          case AuthTokenValidationResult.CanApply:
            AuthManager.instance.logIn(result.jwt, true);
            redirect(originalUrl);
            break;
          case AuthTokenValidationResult.TokenExpired:
          case AuthTokenValidationResult.BadToken:
            setState('token-expired');
            break;
          case AuthTokenValidationResult.DifferentUser:
            setState('different-user');
            break;
        }
      } catch (e) {
        setState('error');
      }
    };

    void load();
  }, [authToken, originalUrl, userId]);

  const switchSession = () => {
    if (jwt == null) {
      return;
    }

    AuthManager.instance.logIn(jwt, true);
    window.location.href = originalUrl;
  };

  return (
    <AuthPage title={'Redirecting...'}>
      <>
        {state === 'loading' && <p>Redirecting you...</p>}
        {state === 'error' && <p>An error occurred while trying to redirect you</p>}
        {state === 'token-expired' && (
          <>
            <p>This link has expired. You'll have to log in again</p>
            <Button
              fullWidth
              component={'a'}
              size={'largest'}
              sx={{ mt: 2, mb: 2 }}
              href={`${Routes.LogIn}?returnUrl=${encodeURIComponent(originalUrl)}`}
            >
              Proceed
            </Button>
          </>
        )}
        {state === 'different-user' && (
          <>
            <p>This link is intended for a different user. If you proceed, you will be logged in as that user.</p>
            <Button fullWidth size={'largest'} sx={{ mt: 2, mb: 2 }} onClick={() => switchSession()}>
              Proceed
            </Button>
          </>
        )}
      </>
    </AuthPage>
  );
};
