import { RooPermission } from 'shared/api/clients';
import { BasicEnumDisplay, createMap, EnumMap } from 'shared/store/enums/shared';

const InternalMapRooPermissions: EnumMap<typeof RooPermission, BasicEnumDisplay> = {
  Unknown: {
    display: 'Unknown'
  },
  ViewPropertyList: {
    display: 'View Property List'
  },
  ViewAllProperties: {
    display: 'View All Properties'
  },
  ViewAllWorkorders: {
    display: 'View All Workorders'
  },
  AddEditProperties: {
    display: 'Add/Edit Properties'
  },
  TakeActionWorkorder: {
    display: 'Take action on any WO - Override'
  },
  AcceptWorkorders: {
    display: 'Accept Workorders'
  },
  CreateUsers: {
    display: 'Create Users'
  },
  UploadLogo: {
    display: 'Upload Logo'
  },
  AddEditVendors: {
    display: 'Add/Edit Vendors'
  },
  AddEditOwners: {
    display: 'Add/Edit Owners'
  },
  AddEditTenants: {
    display: 'Add/Edit Tenants'
  }
};

export const EnumMapRooPermission = createMap<RooPermission, BasicEnumDisplay>(
  RooPermission,
  InternalMapRooPermissions
);
