import { Grid, Paper, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { flatMap } from 'lodash';
import { Column } from 'primereact/column';
import { DataTable as PrimeDataTable } from 'primereact/datatable';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { DocumentLineItem, IDocumentLineItem } from 'shared/api/clients';
import { FileThumbnail } from 'components/Files/FileThumbnail';
import { RooIcon } from 'shared/icons';
import { rooFmt } from 'shared/utils';
import { lineFiles } from 'pages/Workorders/ViewIssue/Documents/utils';

const rowExpansionTemplate = (data: DocumentLineItem) => {
  const files = lineFiles(data);
  return (
    <Box sx={{ width: { xs: '100%' }, px: { md: 4 }, display: 'block' }}>
      <Paper elevation={1} sx={{ p: 2, pb: { xs: 2, md: 1 } }}>
        <Grid container spacing={2}>
          {files.map((file, idx) => (
            <Grid item key={file.id}>
              <FileThumbnail
                size={128}
                file={{
                  id: file.id,
                  fileName: file.fileName,
                  size: file.size,
                  createdAt: file.createdAt,
                  mimeType: file.mimeType
                }}
              />
            </Grid>
          ))}
        </Grid>
      </Paper>
    </Box>
  );
};

type ExpansionState = Record<string, boolean>;

export const LineTable = ({ lines }: { lines: IDocumentLineItem[] }) => {
  const [expandedRows, setExpandedRows] = useState<ExpansionState>({});
  const toggleItem = (id: string) => {
    setExpandedRows((oldState) => {
      const keys = Object.keys(oldState);
      const newState = {} as ExpansionState;
      let newVal = true;
      for (const key of keys) {
        if (key === id) {
          newVal = false;
          continue;
        }

        newState[key] = oldState[key];
      }

      if (newVal) {
        newState[id] = true;
      }

      return newState;
    });
  };

  const allFiles = flatMap(lines, (x) => lineFiles(x));

  return (
    <PrimeDataTable
      style={{ width: '100%' }}
      value={lines}
      dataKey={'id'}
      expandedRows={expandedRows}
      rowExpansionTemplate={rowExpansionTemplate}
    >
      <Column
        bodyClassName={'text-col'}
        field={'description'}
        header={'Description'}
        body={(line: DocumentLineItem) => (
          <Stack>
            <Typography>{line.description}</Typography>
            {!!line.notes && (
              <Typography variant={'caption'}>
                <strong>Notes:</strong> {line.notes}
              </Typography>
            )}
          </Stack>
        )}
      />
      <Column
        field={'value'}
        header={'Amount'}
        style={{ minWidth: '120px' }}
        body={(row: DocumentLineItem) => rooFmt.moneyMaybe(row.value, 'N/A')}
      />
      {allFiles.length > 0 && (
        <Column
          style={{ width: '90px' }}
          body={(row: DocumentLineItem) => {
            const rowFiles = lineFiles(row);
            if (rowFiles.length === 0) {
              return null;
            }

            return (
              <Button size={'sm'} onClick={() => toggleItem(row.id)}>
                <RooIcon icon={['fas', 'image']} /> {rowFiles.length}
              </Button>
            );
          }}
        />
      )}
    </PrimeDataTable>
  );
};
