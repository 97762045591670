import { RooButton, RooDialog } from 'components';
import { useState } from 'react';
import { FormGroup } from 'react-bootstrap';
import { apiProvider } from 'shared/api/apiProvider';
import { SetIssueNoticePayload } from '@roo/api';
import { useCompanyMutation } from '../../shared/api/queries';

export const AddIssueNoticeButton = ({ companyId, currentNotice }: { companyId: string; currentNotice: string }) => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  return (
    <>
      <RooButton icon={Boolean(currentNotice) ? 'edit' : 'plus'} size={'sm'} onClick={() => setIsPopupVisible(true)}>
        Note
      </RooButton>
      <SetIssueNoticeModal
        visible={isPopupVisible}
        companyId={companyId}
        onCloseRequest={() => setIsPopupVisible(false)}
        currentNotice={currentNotice}
      />
    </>
  );
};

export const SetIssueNoticeModal = ({
  visible,
  companyId,
  onCloseRequest,
  currentNotice
}: {
  visible: boolean;
  companyId: string;
  onCloseRequest: () => void;
  currentNotice: string;
}) => {
  const [notice, setNotice] = useState<string>(currentNotice ?? null);
  const { isLoading, mutate } = useCompanyMutation({
    mutationFn: (notice: string) =>
      apiProvider.generalContractorClient.setClientIssueNotice(
        new SetIssueNoticePayload({
          companyId: companyId,
          note: notice
        })
      ),
    onSuccess: async () => {
      onCloseRequest();
    }
  });

  return (
    <RooDialog open={visible} onClose={onCloseRequest} maxWidth={'sm'} fullWidth>
      <RooDialog.Title onClose={onCloseRequest}>{currentNotice ? 'Edit Note' : 'Add note'}</RooDialog.Title>
      <RooDialog.Content>
        <FormGroup>
          <textarea value={notice} onChange={(e) => setNotice(e.target.value)} className={'form-control'} rows={3} />
        </FormGroup>
      </RooDialog.Content>
      <RooDialog.Actions>
        <RooButton variant={'success'} disabled={isLoading} loading={isLoading} onClick={() => mutate(notice)}>
          Save
        </RooButton>
        {currentNotice && (
          <RooButton variant={'danger'} disabled={isLoading} loading={isLoading} onClick={() => mutate(null)}>
            Delete
          </RooButton>
        )}
      </RooDialog.Actions>
    </RooDialog>
  );
};
