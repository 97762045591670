import { Button, Grid, Stack } from '@mui/material';
import { RooDialog } from 'components';
import React from 'react';
import { useCompanyMutation } from 'shared/api/queries';
import { z } from 'zod';
import { apiProvider } from '../../../shared/api/apiProvider';
import { ManagementCompany, UpdateCompanyContactInfoPayload } from '../../../shared/api/clients';
import { FieldMuiText, useRooForm } from '../../../components/form';
import { Disclosure } from '@roo/lib';

export const UpdateCompanyContactModal = ({
  disclosure,
  company
}: {
  disclosure: Disclosure;
  company: ManagementCompany;
}) => {
  return (
    <RooDialog onClose={disclosure.close} fullWidth maxWidth={'sm'} open={disclosure.isOpen}>
      <RooDialog.Title onClose={disclosure.close}>Update Contact Info</RooDialog.Title>
      <UpdateCompanyContactForm disclosure={disclosure} company={company} />
    </RooDialog>
  );
};

const ContactSchema = z.object({
  name: z.string(),
  email: z.string().email().nullish(),
  phone: z.string().nullish()
});

type ContactForm = z.infer<typeof ContactSchema>;

const UpdateCompanyContactForm = ({ disclosure, company }: { disclosure: Disclosure; company: ManagementCompany }) => {
  const { control, handleSubmit } = useRooForm(ContactSchema, {
    defaultValues: {
      name: company.name,
      phone: company.contactInfo.phoneNumber,
      email: company.contactInfo.email
    }
  });

  const { isLoading, mutateAsync } = useCompanyMutation({
    mutationFn: (values: ContactForm) =>
      apiProvider.companiesClient.updateContactInfo(
        new UpdateCompanyContactInfoPayload({
          companyId: company.id,
          email: values.email,
          phone: values.phone,
          name: values.name
        })
      ),
    onSuccess: () => {
      disclosure.close();
    }
  });

  return (
    <>
      <form noValidate onSubmit={handleSubmit((vals) => mutateAsync(vals))}>
        <RooDialog.Content>
          <Grid item xs={12}>
            <FieldMuiText control={control} name={'name'} label="Company Name" fullWidth margin="normal" required />
            <FieldMuiText control={control} name={'email'} label="Email" fullWidth margin="normal" />
            <FieldMuiText control={control} name={'phone'} label="Phone" fullWidth margin="normal" />
          </Grid>
        </RooDialog.Content>
        <RooDialog.Actions>
          <Stack direction={'row'} spacing={2}>
            <Button type="submit" disabled={isLoading}>
              Update
            </Button>
            <Button color={'muted'} disabled={isLoading} onClick={disclosure.close}>
              Close
            </Button>
          </Stack>
        </RooDialog.Actions>
      </form>
    </>
  );
};
