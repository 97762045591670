import { z } from 'zod';
import { PersonSchema } from '../../shared/utils';
import React, { useState } from 'react';
import { Alert, Box, Button, Grid, Typography } from '@mui/material';
import { RooDialog } from '../../components';
import { FieldMuiCheckbox, FieldMuiText, useRooForm } from '../../components/form';
import { useCompanyMutation } from '../../shared/api/queries';
import { apiProvider } from '../../shared/api/apiProvider';
import { AttachContractorClientPayload, CreateContractorClientPayload, CreatePersonModel, StatusType } from '@roo/api';
import { MuiIcon } from 'shared/icons';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useCurrentVendor } from '../../shared/store';

const FormSchema = z.object({
  person: PersonSchema,
  companyName: z.string().nullish(),
  autoConfirmOwnerEmail: z.boolean(),
  muteCompanyNotifications: z.boolean(),
  muteOwnerNotifications: z.boolean()
});
type FormDefinition = z.infer<typeof FormSchema>;

export const CreateClientButton = () => {
  const [show, setShow] = useState(false);
  const toggleShow = () => setShow(!show);
  return (
    <>
      <Button color="primary" size={'large'} startIcon={<MuiIcon.Add />} onClick={toggleShow}>
        Create
      </Button>
      <RooDialog scroll={'body'} open={show} onClose={toggleShow}>
        <CreateClientModalContent toggleShow={toggleShow} onResult={() => {}} />
      </RooDialog>
    </>
  );
};

type ModalState = {
  view: 'create' | 'attach';
  data?: { companyId: string; companyName: string };
};

export const CreateClientModalContent = ({
  toggleShow,
  onResult
}: {
  toggleShow: () => void;
  onResult: (companyId: string) => void;
}) => {
  const [state, setState] = useState<ModalState>({ view: 'create' });
  if (state.view === 'create') {
    return <ViewCreate toggleShow={toggleShow} changeView={setState} onResult={onResult} />;
  }

  return <ViewAttach toggleShow={toggleShow} state={state} onResult={onResult} />;
};

const ViewCreate = ({
  toggleShow,
  changeView,
  onResult
}: {
  toggleShow: () => void;
  changeView: (to: ModalState) => void;
  onResult: (companyId: string) => void;
}) => {
  const { control, handleSubmit } = useRooForm(FormSchema, {
    defaultValues: {
      muteCompanyNotifications: false,
      autoConfirmOwnerEmail: true,
      muteOwnerNotifications: false
    }
  });

  const qc = useQueryClient();

  const { status, mutateAsync, data } = useCompanyMutation({
    mutationFn: (values: FormDefinition) =>
      apiProvider.generalContractorClient.createClient(
        new CreateContractorClientPayload({
          companyName: values.companyName,
          autoConfirmOwnerEmail: values.autoConfirmOwnerEmail,
          muteCompanyNotifications: values.muteCompanyNotifications,
          muteOwnerNotifications: values.muteOwnerNotifications,
          person: new CreatePersonModel({
            email: values.person.email,
            firstName: values.person.firstName,
            lastName: values.person.lastName,
            phone: values.person.phone,
            userId: null
          })
        })
      ),
    onSuccess: async (data) => {
      if (data.status === StatusType.Success) {
        await qc.invalidateQueries(['vendor', 'clients']);
        await qc.invalidateQueries(['user', 'managedCompanies']);
        onResult(data.companyId);
        toggleShow();
      } else if (data.status === StatusType.EmailAlreadyTaken) {
        changeView({ view: 'attach', data: { companyId: data.companyId, companyName: data.companyName } });
      }
    }
  });

  return (
    <form noValidate onSubmit={handleSubmit((vals) => mutateAsync(vals))}>
      <RooDialog.Title onClose={toggleShow}>New Client</RooDialog.Title>
      <RooDialog.Content>
        <Grid container spacing={[0, 0, 2]}>
          <Grid item xs={12}>
            <FieldMuiCheckbox
              control={control}
              label={"Automatically confirm owner's e-mail"}
              name={'autoConfirmOwnerEmail'}
              color={'primary'}
            />
          </Grid>
          <Grid item xs={12}>
            <FieldMuiCheckbox
              control={control}
              label={'Mute all notifications sent in this company'}
              name={'muteCompanyNotifications'}
              color={'primary'}
            />
          </Grid>
          <Grid item xs={12}>
            <FieldMuiCheckbox
              control={control}
              label={'Mute all notifications sent to the owner'}
              name={'muteOwnerNotifications'}
              color={'primary'}
            />
          </Grid>
          <Grid item xs={12}>
            <FieldMuiText
              control={control}
              name={'companyName'}
              margin="normal"
              label="Company Name"
              type="text"
              placeholder="Company Name"
              fullWidth
              autoFocus
            />
          </Grid>
        </Grid>
        <Grid container spacing={[0, 0, 2]}>
          <Grid item xs={12} md={6}>
            <FieldMuiText
              control={control}
              name={'person.firstName'}
              margin="normal"
              label="First Name"
              type="text"
              placeholder="First Name"
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FieldMuiText
              control={control}
              name={'person.lastName'}
              margin="normal"
              label="Last Name"
              type="text"
              placeholder="Last Name"
              required
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container spacing={[0, 0, 2]}>
          <Grid item xs={12} md={6}>
            <FieldMuiText
              control={control}
              name={'person.email'}
              margin="normal"
              label="Email"
              type="email"
              placeholder="Email"
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FieldMuiText
              control={control}
              name={'person.phone'}
              margin="normal"
              label="Phone"
              type="text"
              placeholder="Phone"
              required
              fullWidth
            />
          </Grid>
        </Grid>
      </RooDialog.Content>
      <RooDialog.Actions>
        <Button size={'large'} type="submit" disabled={status === 'loading'} sx={{ my: 2 }}>
          Create
        </Button>
      </RooDialog.Actions>
    </form>
  );
};

const ViewAttach = ({
  toggleShow,
  state,
  onResult
}: {
  toggleShow: () => void;
  state: ModalState;
  onResult: (companyId: string) => void;
}) => {
  const qc = useQueryClient();
  const vendor = useCurrentVendor();
  const { status, mutate } = useCompanyMutation({
    mutationFn: () =>
      apiProvider.generalContractorClient.attachClient(
        new AttachContractorClientPayload({
          companyId: state.data?.companyId,
          vendorId: vendor.id
        })
      ),
    onSuccess: async () => {
      await qc.invalidateQueries(['vendor', 'clients']);
      await qc.invalidateQueries(['user', 'managedCompanies']);
      onResult(state.data.companyId);
      toggleShow();
    }
  });
  return (
    <>
      <RooDialog.Title onClose={toggleShow}>Manage Client</RooDialog.Title>
      <RooDialog.Content>
        <Typography>
          This e-mail address belongs to a company already in our system: <strong>{state.data?.companyName}</strong>. Do
          you want to manage this company?
        </Typography>
      </RooDialog.Content>
      <RooDialog.Actions>
        <Button disabled={status === 'loading'} size={'large'} sx={{ my: 2 }} onClick={() => mutate()}>
          Manage
        </Button>
      </RooDialog.Actions>
    </>
  );
};
