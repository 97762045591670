import { useAppStore } from '../../shared/store';
import { useLocalStorage } from '../../shared/utils/useLocalStorage';
import { getDeviceType } from '../../shared/utils/device';
import { RooDialog } from 'components/RooDialog';
import { useMemo } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { ReactComponent as AppleLogo } from 'images/apple-store-black.svg';
import googleLogo from 'images/google-play-badge.png';
import { Center } from 'components/Center';

const PLAY_STORE_URL = '';
const APPLE_STORE_URL = 'https://apps.apple.com/us/app/walkthroo/id1661562341?itsct=apps_box_badge&amp;itscg=30200';
const HIDE_FOR_MS = 7 * 24 * 60 * 60 * 1000; // a week

export const AppNudgeModal = () => {
  const eulaVisible = useAppStore((x) => x.forceEulaModal);
  const isLoaded = useAppStore((x) => x.dataLoaded);
  const [dismissedUntil, setDismissedUntil] = useLocalStorage<number>({
    key: 'appNudgeDismissedUntil',
    defaultValue: null
  });
  const deviceType = useMemo(() => getDeviceType(), []);
  const isVisible =
    !eulaVisible &&
    isLoaded &&
    (dismissedUntil == null || dismissedUntil < Date.now()) &&
    (deviceType === 'mobile-android' || deviceType === 'mobile-ios');

  return (
    <RooDialog fullWidth maxWidth={'sm'} open={isVisible}>
      <RooDialog.Content sx={{ display: 'flex' }}>
        <Stack sx={{ width: '100%' }}>
          <Stack spacing={2}>
            <Typography variant={'subtitle1'} align={'center'}>
              Try the Walkthroo app!
            </Typography>
            <Center>
              <Stack spacing={1}>
                <Typography>✨ Seamless & Convenient</Typography>
                <Typography>🚀 Quick & Easy Access</Typography>
                <Typography>📲 Enhanced User Experience</Typography>
              </Stack>
            </Center>
          </Stack>

          {deviceType === 'mobile-ios' && (
            <Center my={2}>
              <a
                href={APPLE_STORE_URL}
                style={{
                  display: 'inline-block',
                  overflow: 'hidden',
                  borderRadius: '13px',
                  width: '250px',
                  height: '83px'
                }}
              >
                <AppleLogo
                  style={{
                    borderRadius: '13px',
                    width: '250px',
                    height: '83px'
                  }}
                />
              </a>
            </Center>
          )}

          {deviceType === 'mobile-android' && (
            <Center my={2}>
              <a href={PLAY_STORE_URL}>
                <img alt={''} src={googleLogo} width={250} height={97} />
              </a>
            </Center>
          )}

          <Center>
            <Button onClick={() => setDismissedUntil(Date.now() + HIDE_FOR_MS)} variant={'text'}>
              Maybe Later
            </Button>
          </Center>
          {deviceType === 'mobile-android' && (
            <Typography mt={2} fontSize={8}>
              Google Play and the Google Play logo are trademarks of Google LLC.
            </Typography>
          )}
        </Stack>
      </RooDialog.Content>
    </RooDialog>
  );
};
