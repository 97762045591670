import { FieldValues, useController } from 'react-hook-form';
import { fieldClassNames } from 'components/form/utils';
import { ControlProps } from 'components/form/types';
import React, { InputHTMLAttributes } from 'react';
import { ErrorMessage } from 'components/form/ErrorMessage';
import { FormGroup } from 'react-bootstrap';
import { FormGroupProps } from 'react-bootstrap/FormGroup';
import { RooFormLabel } from './RooFormLabel';

type BaseProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'id' | 'type' | 'name' | 'onChange' | 'value' | 'onBlur'>;
type FinalProps<TForm extends FieldValues> = ControlProps<TForm> & BaseProps & { groupProps?: FormGroupProps };
export type FieldSwitchProps<TForm extends FieldValues> = FinalProps<TForm>;

export const FieldSwitch = <TForm extends FieldValues>({
  control,
  name,
  label,
  className,
  groupProps,
  required,
  ...rest
}: FinalProps<TForm>) => {
  const {
    field,
    fieldState: { error }
  } = useController({
    control: control,
    name: name
  });
  return (
    <FormGroup {...(groupProps ?? {})}>
      <RooFormLabel className={'inline-block'} label={label} name={name} required={required} />
      <div style={{ display: 'inline-block', marginLeft: '10px' }} className="material-switch">
        <input
          type="checkbox"
          className={fieldClassNames(className, error)}
          name={name}
          id={name}
          checked={field.value}
          // @ts-ignore
          onChange={(ev) => field.onChange(ev.target.checked)}
          {...rest}
        />
        <label htmlFor={name} className="bg-primary" />
      </div>
      <ErrorMessage error={error} />
    </FormGroup>
  );
};
