import { ChangeRequest } from '../../../../../shared/api/clients';
import { useMediaQuery, useTheme } from '@mui/material';
import { RooDialog } from '../../../../../components';
import React from 'react';
import { ChangeRequestFormCoordinator } from './ChangeRequestFormCoordinator';
import { Disclosure } from '@roo/lib';

export const EditChangeRequestModal = ({
  modal,
  changeRequest
}: {
  modal: Disclosure;
  changeRequest: ChangeRequest;
}) => {
  const theme = useTheme();
  const isLarge = useMediaQuery(theme.breakpoints.up('md'));
  return (
    <RooDialog
      scroll={'paper'}
      fullScreen={!isLarge}
      maxWidth={isLarge ? 'md' : undefined}
      fullWidth
      open={modal.isOpen}
      onClose={modal.close}
    >
      <RooDialog.Title onClose={modal.close}>Edit Change Request</RooDialog.Title>
      <RooDialog.Content>
        <ChangeRequestFormCoordinator onClose={modal.close} changeRequest={changeRequest} />
      </RooDialog.Content>
    </RooDialog>
  );
};
