import React, { ComponentProps, ReactNode } from 'react';
import { Card } from 'react-bootstrap';
import { IVendorSummary } from '../shared/api/clients';
import { classNames } from '../shared/utils';
import { RooAvatar } from './RooAvatar';
import { MuiIcon, RooIcon } from '../shared/icons';

export type VendorCardProps = Omit<ComponentProps<typeof Card>, 'children'> & {
  vendor: IVendorSummary;
  children?: ReactNode;
};

export const VendorCard = ({ vendor, className, children, ...rest }: VendorCardProps) => {
  return (
    <Card {...rest} className={classNames('user-card', className)}>
      <Card.Body>
        <div className={'d-flex user-header mb-1'}>
          <div className={'avatar-container'}>
            <RooAvatar size={'l'} name={vendor.companyName} avatarUrl={null}>
              <MuiIcon.Build />
            </RooAvatar>
          </div>
          <div className={'user-name'}>
            <strong>{vendor.companyName}</strong>
          </div>
        </div>
        {children}

        <span className={'type-indicator'}>
          <RooIcon icon={['fas', 'hammer']} className={'type-indicator'} /> &nbsp;
        </span>
      </Card.Body>
    </Card>
  );
};
