import { Divider, Grid, Paper } from '@mui/material';
import { Col, Form, FormGroup, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { FieldText, useRooForm } from 'components/form';
import { ContentWrapper, SaveButton } from 'components';
import { z } from 'zod';
import { DebugFormResults } from './DebugFormResults';
import { DebugFormStatus } from './DebugFormStatus';

const FormSchema = z.object({
  stringRequired: z.string(),
  stringOptional: z.string().nullish()
});

type FormDefinition = z.infer<typeof FormSchema>;

export const FormTestPage = () => {
  const { control, handleSubmit } = useRooForm(FormSchema);
  const save = (values: FormDefinition) => {
    console.log(values);
  };

  return (
    <>
      {' '}
      <Helmet>
        <title>Create Vendor - Walkthroo</title>
      </Helmet>
      <ContentWrapper>
        <Form onSubmit={handleSubmit(save)}>
          <Paper elevation={0} sx={{ p: { xs: 2, md: 3 } }}>
            <h6 className="text-black">
              <strong>Vendor Information</strong>
            </h6>
            <Divider sx={{ mb: 2 }} />
            <Row>
              <Col xl={6}>
                <FieldText
                  control={control}
                  name={'stringRequired'}
                  label={'String Required'}
                  placeholder={'String Required'}
                  className="Input-field"
                />
              </Col>
              <Col xl={6}>
                <FieldText
                  control={control}
                  name={'stringOptional'}
                  label={'String Optional'}
                  placeholder={'String Optional'}
                  className="Input-field"
                />
              </Col>
            </Row>
            <div className="mt-3" style={{ textAlign: 'right' }}>
              <SaveButton className={'btn-lg'} control={control}>
                Save
              </SaveButton>
            </div>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <DebugFormResults control={control} />
              </Grid>
              <Grid item xs={6}>
                <DebugFormStatus control={control} />
              </Grid>
            </Grid>
          </Paper>
        </Form>
      </ContentWrapper>
    </>
  );
};
