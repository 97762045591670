import { ReactNode, useState } from 'react';
import { UnassignIssuePayload } from 'shared/api/clients';
import { RooButton } from 'components';
import { apiProvider } from 'shared/api/apiProvider';
import { showSuccess } from 'shared/utils';
import { ConfirmationModal } from 'components';
import { useCurrentIssue } from 'pages/Workorders/shared/CurrentIssueContext';
import { redirect, Routes } from '../../../../shared/routing';

export const UnassignVendorPencil = ({ text }: { text: ReactNode }) => {
  const [isVisible, setIsVisible] = useState(false);
  return (
    <>
      <RooButton style={{ paddingLeft: 0 }} variant={'link'} icon={'pencil-alt'} onClick={() => setIsVisible(true)}>
        {text}
      </RooButton>
      <UnassignVendorModal visible={isVisible} onCloseRequest={() => setIsVisible(false)} />
    </>
  );
};

export const UnassignVendorButton = () => {
  const [isVisible, setIsVisible] = useState(false);
  return (
    <>
      <RooButton icon={'random'} onClick={() => setIsVisible(true)}>
        Reassign
      </RooButton>
      <UnassignVendorModal visible={isVisible} onCloseRequest={() => setIsVisible(false)} />
    </>
  );
};

export const UnassignVendorModal = ({ visible, onCloseRequest }: { visible: boolean; onCloseRequest: () => void }) => {
  const { issue } = useCurrentIssue();
  const [isSaving, setIsSaving] = useState(false);
  const save = async () => {
    setIsSaving(true);
    try {
      const result = await apiProvider.issues.assignments.unassignIssue(
        new UnassignIssuePayload({
          issueId: issue?.id
        })
      );
      redirect({ pathname: Routes.IssueView, params: { workorderId: result.workorderId, issueId: result.newIssueId } });
      onCloseRequest();
      showSuccess();
    } catch (err) {}
    setIsSaving(false);
  };

  return (
    <ConfirmationModal
      question={'Are you sure you want to unassign the current vendor and start over?'}
      visible={visible}
      onClose={onCloseRequest}
      onSave={() => void save()}
      running={isSaving}
    />
  );
};
