import { Box, Grid, Typography } from '@mui/material';
import { round } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { DocumentLineItem, Estimate } from 'shared/api/clients';

type PrevEstimateProps = {
  entry: Estimate;
  tasks: string[];
};

export function PrevEstimateColumn({ entry, tasks }: PrevEstimateProps) {
  const total = entry.lines.reduce((acc, line) => round(acc + line.value, 2), 0);

  return (
    <Grid marginLeft="0.2rem" container direction="column" minWidth={'180px'}>
      <Grid
        container
        alignItems={'center'}
        py={'1rem'}
        px={'0.5rem'}
        sx={{
          backgroundColor: '#2ccccc7d',
          borderTopLeftRadius: '10px',
          borderTopRightRadius: '10px'
        }}
        height={'calc(50px + 1rem)'}
        wrap="nowrap"
        position="relative"
      >
        <Grid width="100%" item>
          <Typography
            px="0.5rem"
            sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
            align={'center'}
          >
            {entry.friendlyId}
          </Typography>
        </Grid>
      </Grid>
      {tasks.map((task, index) => {
        const line = entry.lines.find((l) => l.description === task);

        return <EstimateTaskItem line={line} taskIndex={index} key={task} />;
      })}

      <Grid
        item
        container
        alignItems={'center'}
        justifyContent={'center'}
        width={'100%'}
        direction={'column'}
        sx={{
          backgroundColor: '#303030',
          color: 'white',
          borderBottomLeftRadius: '10px',
          borderBottomRightRadius: '10px'
        }}
        height={'calc(50px + 1rem)'}
      >
        <Typography align="center">Total bid</Typography>
        <Typography align="center" fontWeight={600} fontSize={'1.2rem'}>
          ${total}
        </Typography>
      </Grid>
    </Grid>
  );
}

type EstimateTaskItemProps = {
  taskIndex: number;
  line: DocumentLineItem;
};

function EstimateTaskItem({ taskIndex, line }: EstimateTaskItemProps) {
  const [height, setHeight] = useState<number | string>('calc(100px + 1rem)');

  const updateHeight = useCallback(() => {
    setHeight(document.getElementById(`task-${taskIndex}`).clientHeight);
  }, [taskIndex]);

  useEffect(() => {
    updateHeight();

    window.addEventListener('resize', updateHeight);
    return () => window.removeEventListener('resize', updateHeight);
  }, [updateHeight]);

  return (
    <>
      <Grid
        height={height}
        py={'1rem'}
        px={'0.5rem'}
        item
        container
        direction={'column'}
        alignItems={'center'}
        justifyContent={'center'}
        sx={{
          position: 'relative',
          backgroundColor: '#e3f2f2'
        }}
      >
        {line && (
          <Box>
            <Typography align={'center'} fontWeight="600">
              ${line.value}
            </Typography>
          </Box>
        )}
      </Grid>
    </>
  );
}
