import { IssueStatus } from 'shared/api/clients';
import { ColoredEnumDisplay, createMap, EnumMap } from 'shared/store/enums/shared';

const InternalMapIssueStatus: EnumMap<typeof IssueStatus, ColoredEnumDisplay> = {
  Unknown: {
    display: 'Unknown',
    color: 'secondary'
  },
  New: {
    display: 'New',
    color: 'primary'
  },
  AssignedToVendor: {
    display: 'Assigned to Vendor',
    color: 'primary'
  },
  VendorScheduling: {
    display: 'Vendor Scheduling',
    color: 'warning'
  },
  TenantScheduling: {
    display: 'Tenant Scheduling',
    color: 'warning'
  },
  VendorDeclined: {
    display: 'Vendor Declined',
    color: 'danger'
  },
  WorkInProgress: {
    display: 'Work in Progress',
    color: 'primary'
  },
  NeedsInvoice: {
    display: 'Needs Invoice',
    color: 'warning'
  },
  Closed: {
    display: 'Closed',
    color: 'success'
  },
  Bidding: {
    display: 'Request Vendor Bids',
    color: 'primary'
  },
  AssignedToMultipleVendors: {
    display: 'Assigned to Multiple Vendors',
    color: 'primary'
  },
  TenantCanceled: {
    display: 'Tenant Canceled',
    color: 'danger'
  },
  VendorUnresponsive: {
    display: 'Vendor Unresponsive',
    color: 'danger'
  },
  Estimating: {
    display: 'Estimating',
    color: 'primary'
  },
  DispatchingCrew: {
    display: 'Dispatching Crew',
    color: 'primary'
  },
  CanceledOwnerDeclined: {
    display: 'Canceled (Owner Declined)',
    color: 'danger'
  },
  CanceledLostBid: {
    display: 'Canceled (Lost Bid)',
    color: 'danger'
  },
  CanceledOther: {
    display: 'Canceled (Other)',
    color: 'danger'
  },
  InvoiceReview: {
    display: 'Invoice Review',
    color: 'warning'
  },
  PaymentPending: {
    display: 'Payment Pending',
    color: 'warning'
  },
  Paid: {
    display: 'Paid',
    color: 'success'
  },
  BidReview: {
    display: 'Bid Review',
    color: 'warning'
  },
  CanceledUnassigned: {
    display: 'Canceled (Unassigned)',
    color: 'danger'
  },
  NeedToEstimate: {
    display: 'Need to Estimate',
    color: 'warning'
  },
  Obsolete_10: {
    display: '',
    color: ''
  },
  Obsolete_11: {
    display: '',
    color: ''
  }
};

export const EnumMapIssueStatus = createMap<IssueStatus, ColoredEnumDisplay>(IssueStatus, InternalMapIssueStatus);
