type Classable = string | number | Record<string, boolean> | Classable[];

export const classNames = (...args: Classable[]): string => {
  const classes: string[] = [];
  for (const arg of args) {
    if (arg == null) {
      continue;
    }

    if (typeof arg === 'string') {
      classes.push(arg);
      continue;
    }

    if (typeof arg === 'number') {
      classes.push(arg.toString());
      continue;
    }

    if (Array.isArray(arg)) {
      const res = classNames.apply(null, arg);
      if (res) {
        classes.push(res);
      }
      continue;
    }

    if (typeof arg === 'object') {
      if (arg.toString === Object.prototype.toString) {
        for (let key in arg) {
          if (arg.hasOwnProperty(key) && arg[key]) {
            classes.push(key);
          }
        }
      } else {
        classes.push(arg.toString());
      }
      continue;
    }
  }

  return classes.join(' ');
};

export const cls = classNames;
