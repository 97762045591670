import { useState } from 'react';
import { Alert, Card } from 'react-bootstrap';
import { EstimateStatus, IssueRole } from 'shared/api/clients';
import { RooButton, RouterLink } from 'components';
import { useCurrentIssue } from 'pages/Workorders/shared/CurrentIssueContext';
import { EstimateConfirmation } from 'pages/Workorders/ViewIssue/Documents/Estimates/EstimateConfirmation';
import { EstimateForm } from 'pages/Workorders/ViewIssue/Documents/Estimates/EstimateForm';
import { EstimateList } from 'pages/Workorders/ViewIssue/Documents/Estimates/EstimateList';
import { EstimateManagerState, EstimateRequestViewChange } from 'pages/Workorders/ViewIssue/Documents/Estimates/types';
import { RooIcon } from 'shared/icons';
import { Routes } from 'shared/routing';

export const EstimateManager = () => {
  const { userRole, estimates, issue } = useCurrentIssue();
  const [{ view, estimate, lines }, setManagerState] = useState<EstimateManagerState>({
    view: 'list',
    estimate: null,
    lines: null
  });
  const showDraftWarning =
    userRole === IssueRole.Vendor && estimates.find((x) => x.status === EstimateStatus.Draft) != null;
  const canCreate = userRole === IssueRole.Vendor || userRole === IssueRole.Tech;

  const requestViewChange: EstimateRequestViewChange = (payload) => {
    switch (payload.view) {
      case 'list':
        setManagerState({ view: 'list', estimate: null, lines: null });
        break;
      case 'edit':
        setManagerState({ view: 'edit', estimate: payload.estimate, lines: null });
        break;
      case 'confirm':
        setManagerState({ view: 'confirm', estimate: null, lines: null });
        break;
      case 'create':
        if (payload.force ?? false) {
          setManagerState({ view: 'create', estimate: null, lines: payload.lines });
        } else {
          if (estimates.length > 0) {
            setManagerState({ view: 'confirm', estimate: null, lines: null });
          } else {
            setManagerState({ view: 'create', estimate: null, lines: payload.lines });
          }
        }
        break;
    }
  };

  return (
    <Card>
      <Card.Header>
        {view === 'list' && 'Estimates'}
        {view === 'create' && 'Create New Estimate'}
        {view === 'edit' && 'Edit Estimate'}
        {view === 'confirm' && 'Confirm Creation'}
        {canCreate && view === 'list' && (
          <RooButton
            color={'primary'}
            size={'sm'}
            className={'float-end slim'}
            icon={'plus'}
            onClick={() => requestViewChange({ view: 'confirm' })}
          >
            Add
          </RooButton>
        )}
        {
          <RouterLink
            to={{ pathname: Routes.IssueBidCompare, params: { issueId: issue.id, workorderId: issue.workorderId } }}
            className={'btn btn-primary btn-sm slim float-end me-1'}
          >
            <RooIcon icon={['fas', 'eye']} /> Compare
          </RouterLink>
        }
      </Card.Header>
      <Card.Body>
        {view === 'list' && showDraftWarning && (
          <Alert className={'text-center'} variant={'warning'}>
            You have Draft estimates pending. <strong>Send</strong> them to the client when you're ready.
          </Alert>
        )}
        {view === 'confirm' && <EstimateConfirmation requestViewChange={requestViewChange} />}
        {view === 'list' && <EstimateList canEdit={true} requestViewChange={requestViewChange} canCreate={canCreate} />}
        {(view === 'create' || view === 'edit') && (
          <EstimateForm
            requestViewChange={requestViewChange}
            implicitLines={lines}
            estimate={view === 'create' ? null : estimate}
          />
        )}
      </Card.Body>
    </Card>
  );
};
