import { MuiIcon } from 'shared/icons';
import { rooFmt } from 'shared/utils';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  timelineOppositeContentClasses,
  TimelineSeparator
} from '@mui/lab';
import React from 'react';
import { SvgIconComponent } from '@mui/icons-material';
import { Box, Stack, Typography } from '@mui/material';
import { NotificationListButton } from './NotificationList';
import { useIntervalRerender } from '@roo/lib';

export type TimelineEntry = {
  id: string;
  date: Date;
  Icon: SvgIconComponent;
  text: JSX.Element;
  color: 'primary' | 'secondary' | 'success' | 'warning' | 'error';
  notes: string;
};

export const ActivityTimeline = ({
  entries,
  showNotificationBtn
}: {
  entries: TimelineEntry[];
  showNotificationBtn: boolean;
}) => {
  useIntervalRerender(15 * 1000);
  return (
    <Timeline
      sx={{
        [`& .${timelineOppositeContentClasses.root}`]: {
          flex: 0.3
        }
      }}
    >
      {entries.map((entry) => (
        <TimelineItem key={entry.id}>
          <TimelineOppositeContent sx={{ m: 'auto 0' }} align="right" variant="body2" color="text.secondary">
            {rooFmt.dateTime(entry.date)} {showNotificationBtn && <NotificationListButton activityId={entry.id} />}
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot color={entry.color}>
              <entry.Icon fontSize={'small'} />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ m: 'auto 0' }}>
            <Stack spacing={1}>
              <Box>{entry.text}</Box>
              {entry.notes && (
                <Stack direction={'row'} spacing={1} pl={2}>
                  <MuiIcon.SpeakerNotes color={'info'} />
                  <Typography variant={'body2'} sx={{ fontStyle: 'italic' }}>
                    {entry.notes}
                  </Typography>
                </Stack>
              )}
            </Stack>
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
};
