import { Stack } from '@mui/material';
import { RooButton, SaveButton } from 'components';
import { FieldMuiCheckbox, FieldNumber, FieldTextArea, Required } from 'components/form';
import { useRequiredRooForm } from 'components/form/utils';
import { useCurrentIssue } from 'pages/Workorders/shared/CurrentIssueContext';
import { FormLineItem } from 'pages/Workorders/ViewIssue/Documents/FormLineItem';
import { calculateDocumentSummary, DocumentFormTotals } from 'pages/Workorders/ViewIssue/Documents/DocumentFormTotals';
import { TaxableLinesSchema, WithLineCollection } from 'pages/Workorders/ViewIssue/Documents/types';
import { Button, Col, Row } from 'react-bootstrap';
import { Control, useFieldArray, useWatch } from 'react-hook-form';
import { UseFormGetValues, UseFormSetValue } from 'react-hook-form/dist/types/form';
import { apiProvider } from 'shared/api/apiProvider';
import {
  AttachmentEntityType,
  ChangeInvoiceAction,
  CreateOrUpdateInvoicePayload,
  DocumentFormMeta,
  FormSubmissionLineItem,
  GetInvoiceDefaultsPayload,
  IInvoice,
  InvoiceDefaultsResponse,
  InvoiceStatus,
  IssueRole,
  LineSource
} from 'shared/api/clients';
import { RooIcon } from 'shared/icons';
import { DeepRequired, showSuccess } from 'shared/utils';
import { z } from 'zod';
import { useQuery } from '@tanstack/react-query';
import { CenteredLoader } from '../../../../../components/CenteredLoader';
import { InlineError } from '../../../../../components/InlineError';
import React, { useEffect } from 'react';
import { round } from 'lodash';
import { useUniqueId } from '@roo/lib';

const InvoiceSchema = z.object({
  invoiceId: z.string().nullish(),
  notes: z.string().nullish(),
  saveAsDraft: z.boolean(),

  lineCollection: TaxableLinesSchema
});

type FormDefinition = DeepRequired<z.infer<typeof InvoiceSchema>>;

export const InvoiceForm = (params: {
  onComplete: () => void;
  invoice: IInvoice;
  allowImplicitLines: boolean;
  preferMatchDraft: boolean;
  isPartial: boolean;
}) => {
  const { issue } = useCurrentIssue();
  const { invoice, allowImplicitLines, isPartial } = params;
  const uid = useUniqueId();

  const { data, status } = useQuery(['issues', issue.id, 'invoices', invoice?.id, 'form-defaults', uid], {
    queryFn: () =>
      apiProvider.issues.invoices.getFormDefaults(
        new GetInvoiceDefaultsPayload({
          isPartial: isPartial,
          issueId: issue.id,
          existingInvoiceId: invoice?.id,
          allowImplicitLines: allowImplicitLines
        })
      ),
    cacheTime: Infinity,
    staleTime: Infinity
  });

  if (status === 'loading') {
    return <CenteredLoader />;
  }

  if (status === 'error') {
    return <InlineError />;
  }

  return <InvoiceFormInner defaults={data} {...params} />;
};

const InvoiceFormInner = ({
  onComplete,
  invoice,
  preferMatchDraft,
  defaults,
  isPartial
}: {
  onComplete: () => void;
  invoice: IInvoice;
  preferMatchDraft: boolean;
  defaults: InvoiceDefaultsResponse;
  isPartial: boolean;
}) => {
  const { issue, onIssueUpdate } = useCurrentIssue();
  const { handleSubmit, control, getValues, setValue, watch } = useRequiredRooForm(InvoiceSchema, {
    defaultValues: {
      invoiceId: defaults.invoiceId,
      notes: defaults.notes,
      saveAsDraft: invoice == null || (invoice.status === InvoiceStatus.Draft && preferMatchDraft),
      lineCollection: {
        taxPercent: defaults.lineCollection.taxPercent,
        discountPercent: defaults.lineCollection.discountPercent,
        resaleTaxPercent: null,
        previousDiscount: defaults.meta.previousDiscount,
        partialAmount: defaults.lineCollection.partialAmount,
        partialPercent: defaults.lineCollection.partialPercent,
        didSetPartialAmount: true,
        didSetPartialPercent: false,
        lines: defaults.lineCollection.lineItems.map((x) => ({
          value: x.value,
          type: x.sourceEntityType,
          sourceEntityId: x.sourceEntityId,
          beforeFiles: x.beforeFiles,
          afterFiles: x.afterFiles,
          notes: x.notes,
          initialValue: x.initialValue,
          resaleValue: x.resaleValue,
          canEditDescription: x.canEditDescription,
          canEditValue: x.canEditValue,
          description: x.description
        }))
      }
    }
  });

  const saveAsDraft = watch('saveAsDraft');

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'lineCollection.lines'
  });

  const addLine = () => {
    append(
      {
        value: null,
        description: null,
        type: LineSource.Custom,
        sourceEntityId: null,
        beforeFiles: [],
        afterFiles: [],
        notes: null,
        initialValue: null,
        canEditDescription: true,
        canEditValue: true,
        resaleValue: null
      },
      {
        shouldFocus: false
      }
    );
  };

  const save = async (values: FormDefinition) => {
    try {
      const updated = await apiProvider.issues.invoices.createOrUpdateInvoice(
        new CreateOrUpdateInvoicePayload({
          isPartial: isPartial,
          invoiceId: invoice?.id,
          issueId: issue.id,
          notes: values.notes,
          taxPercent: values.lineCollection.taxPercent,
          previousDiscount: values.lineCollection.previousDiscount,
          discountPercent: values.lineCollection.discountPercent,
          partialAmount: values.lineCollection.partialAmount,
          partialPercent: values.lineCollection.partialPercent,
          action: determineAction(values.saveAsDraft, invoice),
          lines: values.lineCollection.lines.map(
            (x, i) =>
              new FormSubmissionLineItem({
                description: x.description,
                value: x.value,
                initialValue: x.initialValue,
                sourceEntityId: x.sourceEntityId,
                sourceEntityType: x.type,
                beforeFileIds: x.beforeFiles.map((x) => x.id),
                afterFileIds: x.afterFiles.map((x) => x.id),
                notes: x.notes,
                resaleValue: null,
                order: i
              })
          )
        })
      );

      onIssueUpdate(updated.issueView);
      showSuccess();
      onComplete();
    } catch (e) {}
  };

  return (
    <form noValidate onSubmit={handleSubmit(save)}>
      <Stack alignItems={'center'} justifyContent={'center'} direction={'row'} spacing={3} mb={4}>
        {defaults.meta.withTax && (
          <FieldNumber
            control={control}
            name={`lineCollection.taxPercent`}
            required
            label={'Tax Rate %'}
            placeholder={'Tax Rate %'}
          />
        )}
        {defaults.meta.withDiscount && (
          <FieldNumber
            control={control}
            name={`lineCollection.discountPercent`}
            required
            label={'Discount %'}
            placeholder={'Discount %'}
          />
        )}
        {defaults.meta.withPartial && (
          <>
            <FieldNumber
              control={control}
              name={`lineCollection.partialPercent`}
              required
              label={'% of Total'}
              placeholder={'% of Total'}
              onAfterChange={() => {
                setValue(`lineCollection.didSetPartialPercent`, true);
                setValue(`lineCollection.didSetPartialAmount`, false);
              }}
            />
            <FieldNumber
              control={control}
              name={`lineCollection.partialAmount`}
              required
              label={'Amount $'}
              placeholder={'Amount $'}
              onAfterChange={() => {
                setValue(`lineCollection.didSetPartialAmount`, true);
                setValue(`lineCollection.didSetPartialPercent`, false);
              }}
            />
            <PartialValuesFormWatcher control={control} setValue={setValue} meta={defaults.meta} />
          </>
        )}
      </Stack>
      {fields.map((line, index) => {
        return (
          <FormLineItem
            key={index}
            line={line}
            index={index}
            remove={remove}
            control={control as unknown as Control<WithLineCollection>}
            getValues={getValues as unknown as UseFormGetValues<WithLineCollection>}
            entityType={AttachmentEntityType.InvoiceLineItem}
            canEditDescription={line.canEditDescription}
            canEditValue={line.canEditValue}
            showInitial={false}
            hasResale={false}
            hasNotes={true}
            hasBeforeFiles={true}
            hasAfterFiles={true}
          />
        );
      })}
      {defaults.lineCollection.canAddLines && (
        <div style={{ textAlign: 'center', marginTop: '15px' }}>
          <Button color={'primary'} onClick={() => addLine()}>
            <RooIcon icon={['fas', 'plus']} /> Add
          </Button>
        </div>
      )}

      <div style={{ marginTop: '30px' }}>
        <DocumentFormTotals
          withTax={defaults.meta.withTax}
          withResale={false}
          withDiscount={defaults.meta.withDiscount}
          previousDiscount={defaults.meta.previousDiscount}
          withPartial={defaults.meta.withPartial}
          control={control as unknown as Control<WithLineCollection>}
        />
      </div>

      <Row>
        <Col className={'mb-2'}>
          <FieldTextArea control={control} name={'notes'} label={'Invoice Notes'} />
        </Col>
      </Row>

      <hr className="hr-text" />
      {(invoice == null || invoice.status === InvoiceStatus.Draft) && (
        <Stack justifyContent={'center'} direction={'row'}>
          <FieldMuiCheckbox control={control} name={'saveAsDraft'} label={'Save as Draft'} />
        </Stack>
      )}

      <Stack alignItems={'center'} justifyContent={'center'} direction={'row'} spacing={2}>
        <SaveButton className={'btn me-2'} control={control}>
          {determineAction(saveAsDraft, invoice) === ChangeInvoiceAction.SavePublished ? 'Save & Send' : 'Save Draft'}
        </SaveButton>
        <RooButton onClick={onComplete} variant={'secondary'}>
          Cancel
        </RooButton>
      </Stack>
    </form>
  );
};

const PartialValuesFormWatcher = ({
  control,
  setValue,
  meta
}: {
  control: Control<FormDefinition>;
  setValue: UseFormSetValue<FormDefinition>;
  meta: DocumentFormMeta;
}): JSX.Element => {
  const vals = useWatch({ control: control });
  const { userRole, subissues } = useCurrentIssue();
  const isReselling = userRole === IssueRole.Vendor && subissues.length > 0;

  const getRawValue = () => {
    const summary = calculateDocumentSummary({
      withFutureResale: meta.withResale,
      withDiscount: meta.withDiscount,
      previousDiscount: meta.previousDiscount,
      discountPercent: vals.lineCollection.discountPercent,
      lines: vals.lineCollection.lines,
      isReselling: isReselling,
      withTax: meta.withTax,
      taxPercent: vals.lineCollection.taxPercent,
      resaleTaxPercent: vals.lineCollection.resaleTaxPercent,
      withPartial: false,
      partialAmount: vals.lineCollection.partialAmount,
      partialPercent: vals.lineCollection.partialPercent
    });

    const finalTotal = summary.find((x) => x.flag === 'final_total')?.numericValue;
    const grandTotal = summary.find((x) => x.flag === 'grand_total')?.numericValue;

    return finalTotal ?? grandTotal;
  };

  useEffect(() => {
    const totalValue = getRawValue();
    if (totalValue == null) {
      return;
    }
    if (vals.lineCollection.didSetPartialPercent) {
      setValue('lineCollection.partialAmount', round(totalValue * (vals.lineCollection.partialPercent / 100), 2));
    }
  }, [vals.lineCollection.partialPercent]);

  useEffect(() => {
    const totalValue = getRawValue();
    if (totalValue == null) {
      return;
    }
    if (vals.lineCollection.didSetPartialAmount) {
      setValue('lineCollection.partialPercent', round((vals.lineCollection.partialAmount / totalValue) * 100, 2));
    }
  }, [vals.lineCollection.partialAmount]);

  useEffect(() => {
    const totalValue = getRawValue();
    if (totalValue == null) {
      return;
    }

    if (vals.lineCollection.didSetPartialAmount) {
      setValue('lineCollection.partialPercent', round((vals.lineCollection.partialAmount / totalValue) * 100, 2));
      return;
    }

    // if neither (on edit), defer to percent
    setValue('lineCollection.partialAmount', round(totalValue * (vals.lineCollection.partialPercent / 100), 2));
  }, [vals.lineCollection.lines.reduce((prev, curr) => prev + '|' + curr.value, '')]);

  return null;
};

const determineAction = (saveAsDraft: boolean, invoice: IInvoice) => {
  return saveAsDraft
    ? invoice == null || invoice.status === InvoiceStatus.Draft
      ? ChangeInvoiceAction.SaveDraft
      : ChangeInvoiceAction.SavePublished
    : ChangeInvoiceAction.SavePublished;
};
