import React, { useState } from 'react';
import { apiProvider } from 'shared/api/apiProvider';
import { TenantCancelIssuePayload } from 'shared/api/clients';
import { ConfirmationModal, RooButton } from 'components';
import { showSuccess } from 'shared/utils';
import { useCurrentIssue } from 'pages/Workorders/shared/CurrentIssueContext';

export const TenantCancelButton = () => {
  const { issue, onIssueUpdate } = useCurrentIssue();
  const [comments, setComments] = useState<string>('');
  const [isSaving, setIsSaving] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);

  const save = async () => {
    setIsSaving(true);
    try {
      const result = await apiProvider.issues.main.tenantCancelIssue(
        new TenantCancelIssuePayload({
          issueId: issue.id,
          comments: comments?.trim() === '' ? null : comments
        })
      );
      onIssueUpdate(result);
      showSuccess();
    } catch (e) {}

    setIsSaving(false);
    setIsConfirming(false);
  };

  return (
    <>
      <RooButton onClick={() => setIsConfirming(true)} variant={'danger'} icon={'times'}>
        Cancel Issue
      </RooButton>
      <ConfirmationModal
        visible={isConfirming}
        running={isSaving}
        title={'Confirm Issue Cancellation'}
        question={
          <>
            Are you sure you want to cancel this issue?
            <div className={'mt-4 form-group'}>
              <label htmlFor={'notes-input'}>Comments</label>
              <textarea
                value={comments}
                onChange={(e) => setComments(e.target.value)}
                className={'form-control'}
                id={'notes-input'}
                rows={3}
                placeholder={'Your comments'}
              />
            </div>
          </>
        }
        onClose={() => setIsConfirming(false)}
        onSave={() => {
          void save();
        }}
      />
    </>
  );
};
