import { EstimateStatus } from 'shared/api/clients';
import { ColoredEnumDisplay, createMap, EnumMap } from 'shared/store/enums/shared';

const InternalMapEstimateStatus: EnumMap<typeof EstimateStatus, ColoredEnumDisplay> = {
  Draft: {
    display: 'Draft',
    color: 'dark'
  },
  PendingReview: {
    display: 'Pending Review',
    color: 'warning'
  },
  Rejected: {
    display: 'Rejected',
    color: 'danger'
  },
  Accepted: {
    display: 'Accepted',
    color: 'success'
  },
  Edited: {
    display: 'Edited',
    color: 'secondary'
  },
  Canceled: {
    display: 'Canceled',
    color: 'secondary'
  }
};

export const EnumMapEstimateStatus = createMap<EstimateStatus, ColoredEnumDisplay>(
  EstimateStatus,
  InternalMapEstimateStatus
);
