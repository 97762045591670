import { MuiIcon } from '../../shared/icons';
import { Alert, Box, CircularProgress, IconButton, Stack, Typography } from '@mui/material';
import { rooFmt } from '../../shared/utils';
import { RooDialog } from '../RooDialog';
import { useQuery } from '@tanstack/react-query';
import { apiProvider } from '../../shared/api/apiProvider';
import { Center } from 'components/Center';
import { Button } from 'react-bootstrap';
import React, { useMemo, useState } from 'react';
import MaterialReactTable, { MRT_ColumnDef } from 'material-react-table';
import { CommunicationMethod, SentNotificationSummary } from '../../shared/api/clients';
import { UserLink } from '../UserLink';
import { FileThumbnail } from '../Files/FileThumbnail';
import { Disclosure, useDisclosure } from '@roo/lib';

export const NotificationListButton = ({ activityId }: { activityId: string }) => {
  const disclosure = useDisclosure(false);
  return (
    <>
      <IconButton onClick={disclosure.open}>
        <MuiIcon.Notifications />
      </IconButton>
      <NotificationListModal activityId={activityId} disclosure={disclosure} />
    </>
  );
};

const NotificationListModal = ({ disclosure, activityId }: { activityId: string; disclosure: Disclosure }) => {
  return (
    <RooDialog onClose={disclosure.close} maxWidth={'md'} fullWidth open={disclosure.isOpen}>
      <RooDialog.Title onClose={disclosure.close}>Notifications</RooDialog.Title>
      <NotificationListBody activityId={activityId} disclosure={disclosure} />
    </RooDialog>
  );
};

type BodyState = { view: 'list' | 'detail'; notificationId?: string };

const NotificationListBody = ({ activityId, disclosure }: { activityId: string; disclosure: Disclosure }) => {
  const [state, setState] = useState<BodyState>({ view: 'list' });

  return (
    <>
      <RooDialog.Content>
        {state.view === 'list' && <NotificationList activityId={activityId} changeView={setState} />}
        {state.view === 'detail' && <NotificationDetail notificationId={state.notificationId} changeView={setState} />}
      </RooDialog.Content>
      <RooDialog.Actions>
        {state.view === 'detail' && (
          <Button variant={'info'} onClick={() => setState({ view: 'list' })}>
            Back to List
          </Button>
        )}

        <Button variant={'secondary'} onClick={disclosure.close}>
          Close
        </Button>
      </RooDialog.Actions>
    </>
  );
};

const NotificationList = ({
  activityId,
  changeView
}: {
  activityId: string;
  changeView: (state: BodyState) => void;
}) => {
  const { isLoading, data } = useQuery(['activity-notifications', activityId], () =>
    apiProvider.issues.main.getNotifications(activityId)
  );

  const columns = useMemo<MRT_ColumnDef<SentNotificationSummary>[]>(
    () => [
      {
        header: 'Destination',
        accessorKey: 'theirAddress',
        Cell: ({ cell, row }) => (
          <Stack direction={'row'} spacing={1}>
            <Box>
              {row.original.method === CommunicationMethod.Sms && <MuiIcon.Phone />}
              {row.original.method === CommunicationMethod.Email && <MuiIcon.Email />}
            </Box>
            <Typography>{cell.getValue<string>()}</Typography>
          </Stack>
        )
      },
      {
        header: 'Successful',
        Cell: ({ row }) => (
          <>
            {row.original.sentSuccess && <MuiIcon.Check color={'success'} />}
            {!row.original.sentSuccess && <MuiIcon.Cancel color={'error'} />}
          </>
        )
      },
      {
        header: 'User',
        Cell: ({ row }) => <UserLink user={row.original.user} role={null} />
      },
      {
        header: 'Content',
        Cell: ({ row }) => (
          <IconButton color={'primary'} onClick={() => changeView({ notificationId: row.original.id, view: 'detail' })}>
            <MuiIcon.Visibility />
          </IconButton>
        )
      }
    ],
    [changeView]
  );

  if (isLoading) {
    return (
      <Center>
        <CircularProgress />
      </Center>
    );
  }

  if (!isLoading && data && data.length === 0) {
    return (
      <Center>
        <Alert color={'info'}>There are no notifications for this activity.</Alert>
      </Center>
    );
  }

  return (
    <MaterialReactTable
      columns={columns}
      enablePagination={false}
      data={data ?? []}
      state={{ isLoading, density: 'compact' }}
      enableGlobalFilter={false}
      enableHiding={false}
      enableSorting={false}
      enableFilters={false}
      enableColumnDragging={false}
      enableFullScreenToggle={false}
      enableDensityToggle={false}
      enableTopToolbar={false}
      enableBottomToolbar={false}
      enableColumnActions={false}
    />
  );
};

const NotificationDetail = ({ notificationId }: { notificationId: string; changeView: (state: BodyState) => void }) => {
  const { isLoading, isError, data } = useQuery(['activity-notification-details', notificationId], () =>
    apiProvider.issues.main.getNotificationDetails(notificationId)
  );

  if (isLoading) {
    return (
      <Center>
        <CircularProgress />
      </Center>
    );
  }

  if (!isLoading && (!data || isError)) {
    return (
      <Center>
        <Alert color={'error'}>Could not load this notification.</Alert>
      </Center>
    );
  }

  return (
    <Stack spacing={2} px={4}>
      <Stack direction={'row'} justifyContent={'space-between'} spacing={2}>
        <Stack>
          <Typography fontWeight={'bold'}>Destination</Typography>
          <Stack flexWrap={'nowrap'} direction={'row'} spacing={1}>
            <Box>
              {data.method === CommunicationMethod.Sms && <MuiIcon.Phone />}
              {data.method === CommunicationMethod.Email && <MuiIcon.Email />}
            </Box>
            <Typography>{data.theirAddress}</Typography>
          </Stack>
        </Stack>
        <Stack>
          <Typography fontWeight={'bold'}>Successful</Typography>
          {data.sentSuccess && <MuiIcon.Check color={'success'} />}
          {!data.sentSuccess && <MuiIcon.Cancel color={'error'} />}
        </Stack>
        <Stack>
          <Typography fontWeight={'bold'}>Date</Typography>
          <Typography>{rooFmt.dateTime(data.sentAt)}</Typography>
        </Stack>
        <Stack>
          <Typography fontWeight={'bold'}>User</Typography>
          <UserLink user={data.user} role={null} />
        </Stack>
      </Stack>
      {data.attachments.length > 0 && (
        <Stack spacing={1}>
          <Typography fontWeight={'bold'}>Attachments</Typography>
          <Stack direction={'row'} spacing={2}>
            {data.attachments.map((x) => (
              <FileThumbnail key={x.id} size={64} file={x} />
            ))}
          </Stack>
        </Stack>
      )}
      {data.method === CommunicationMethod.Sms && (
        <>
          {data.plaintextBody && (
            <Stack spacing={1}>
              <Typography fontWeight={'bold'}>Message</Typography>
              <pre style={{ marginBottom: 0 }}>{data.plaintextBody}</pre>
            </Stack>
          )}
          {!data.plaintextBody && (
            <Center>
              <Alert color={'info'}>This message is not available.</Alert>
            </Center>
          )}
        </>
      )}
      {data.method === CommunicationMethod.Email && (
        <>
          {data.htmlBody && (
            <>
              <Stack>
                <Typography fontWeight={'bold'}>Subject</Typography>
                <Typography>{data.subject}</Typography>
              </Stack>
              <Stack>
                <Typography fontWeight={'bold'}>Message</Typography>
                <iframe title={data.subject} height={300} style={{ border: 0 }} srcDoc={data.htmlBody} />
              </Stack>
            </>
          )}
          {!data.htmlBody && (
            <Center>
              <Alert color={'info'}>This message is not available.</Alert>
            </Center>
          )}
        </>
      )}
    </Stack>
  );
};
