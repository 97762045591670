import { EnumMapBidStatus } from 'shared/store/enums/bidStatus';
import { EnumMapCandidacyStatus } from 'shared/store/enums/candidacyStatus';
import { EnumMapCapabilityType } from 'shared/store/enums/capabilityType';
import { EnumMapInvoiceStatus } from 'shared/store/enums/invoiceStatus';
import { EnumMapIssueStatus } from 'shared/store/enums/issueStatus';
import { EnumMapRooPermission } from 'shared/store/enums/permissions';
import { EnumMapPriority } from 'shared/store/enums/priority';
import { BasicEnumAccessor, ColoredEnumAccessor } from 'shared/store/enums/shared';
import { EnumMapWorkorderStatus } from 'shared/store/enums/workorderStatus';
import { EnumMapChangeRequestStatus } from './changeRequestStatus';
import { EnumMapPaymentStatus } from './paymentStatus';
import { EnumMapPaymentMethod } from './paymentMethod';
import { EnumMapInspectionStatus } from './inspectionStatus';

export const rooEnum = {
  bidStatus: new ColoredEnumAccessor(EnumMapBidStatus),
  candidacyStatus: new BasicEnumAccessor(EnumMapCandidacyStatus),
  capabilityType: new ColoredEnumAccessor(EnumMapCapabilityType),
  invoiceStatus: new ColoredEnumAccessor(EnumMapInvoiceStatus),
  issueStatus: new ColoredEnumAccessor(EnumMapIssueStatus),
  permission: new BasicEnumAccessor(EnumMapRooPermission),
  workorderStatus: new ColoredEnumAccessor(EnumMapWorkorderStatus),
  priority: new ColoredEnumAccessor(EnumMapPriority),
  changeRequest: new ColoredEnumAccessor(EnumMapChangeRequestStatus),
  paymentStatus: new ColoredEnumAccessor(EnumMapPaymentStatus),
  paymentMethod: new BasicEnumAccessor(EnumMapPaymentMethod),
  inspectionStatus: new ColoredEnumAccessor(EnumMapInspectionStatus)
};
