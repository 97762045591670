import { Button, Grid, Stack } from '@mui/material';
import { RooDialog } from 'components';
import React from 'react';
import { z } from 'zod';
import { UpdateVendorContactInfoPayload, Vendor } from '../../shared/api/clients';
import { FieldMuiText, useRooForm } from '../../components/form';
import { apiProvider } from '../../shared/api/apiProvider';
import { useVendorMutation } from './shared';
import { Disclosure } from '@roo/lib';

export const UpdateVendorContactModal = ({ disclosure, vendor }: { disclosure: Disclosure; vendor: Vendor }) => {
  return (
    <RooDialog onClose={disclosure.close} fullWidth maxWidth={'sm'} open={disclosure.isOpen}>
      <RooDialog.Title onClose={disclosure.close}>Update Contact Info</RooDialog.Title>
      <UpdateVendorContactForm disclosure={disclosure} vendor={vendor} />
    </RooDialog>
  );
};

const ContactSchema = z.object({
  name: z.string(),
  email: z.string().email().nullish(),
  phone: z.string().nullish()
});

type ContactForm = z.infer<typeof ContactSchema>;

const UpdateVendorContactForm = ({ disclosure, vendor }: { disclosure: Disclosure; vendor: Vendor }) => {
  const { control, handleSubmit } = useRooForm(ContactSchema, {
    defaultValues: {
      name: vendor.companyName,
      phone: vendor.contactInfo.phoneNumber,
      email: vendor.contactInfo.email
    }
  });

  const { isLoading, mutateAsync } = useVendorMutation(vendor.id, {
    mutationFn: (values: ContactForm) =>
      apiProvider.vendorClient.updateContactInfo(
        new UpdateVendorContactInfoPayload({
          vendorId: vendor.id,
          email: values.email,
          phone: values.phone,
          name: values.name
        })
      ),
    onSuccess: () => {
      disclosure.close();
    }
  });

  return (
    <>
      <form noValidate onSubmit={handleSubmit((vals) => mutateAsync(vals))}>
        <RooDialog.Content>
          <Grid item xs={12}>
            <FieldMuiText control={control} name={'name'} label="Vendor Name" fullWidth margin="normal" required />
            <FieldMuiText control={control} name={'email'} label="Email" fullWidth margin="normal" />
            <FieldMuiText control={control} name={'phone'} label="Phone" fullWidth margin="normal" />
          </Grid>
        </RooDialog.Content>
        <RooDialog.Actions>
          <Stack direction={'row'} spacing={2}>
            <Button type="submit" disabled={isLoading}>
              Update
            </Button>
            <Button color={'muted'} disabled={isLoading} onClick={disclosure.close}>
              Close
            </Button>
          </Stack>
        </RooDialog.Actions>
      </form>
    </>
  );
};
