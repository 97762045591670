import { Box, Paper } from '@mui/material';
import { IconLink } from 'components';
import { IssuesClientGrid } from 'components/Issues/IssuesClientGrid';
import {
  boolFilterTemplate,
  CompanyDropdown,
  FilterBuilder,
  ServerGridState,
  useServerGrid
} from 'components/Issues/utils';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { forwardRef } from 'react';
import { apiProvider } from 'shared/api/apiProvider';
import {
  CapabilityType,
  DashboardStatCategory,
  IWorkorder,
  SortDirection,
  TasksForUsersFilter,
  WorkordersForUserPayload,
  WorkorderSortField,
  WorkorderWithIssues
} from 'shared/api/clients';
import { Routes } from 'shared/routing';
import { rooEnum, useHasCapability, useIsMultiCompany, workorderStatusOptions } from 'shared/store';
import { KeyGetter, rooFmt } from 'shared/utils';

type WorkordersExternalFilter = {
  forActive?: boolean;
  forCategory?: DashboardStatCategory;
  forPropertyId?: string;
  forCompanyId?: string;
  forVendors?: string[];
  forState?: string;
  forCity?: string;
  forAccountManager?: string;
};

const rowExpansionTemplate = (row: WorkorderWithIssues) => {
  return (
    <Box sx={{ width: { xs: '100%' }, px: { md: 4 }, display: 'block' }}>
      <Paper elevation={1} sx={{ p: 2, pb: { xs: 2, md: 1 } }}>
        <IssuesClientGrid issues={row.issues} />
      </Paper>
    </Box>
  );
};

export const useWorkordersGrid = ({
  forceExpanded,
  externalFilters,
  onInitialLoad,
  onLoad
}: {
  forceExpanded: boolean;
  externalFilters: WorkordersExternalFilter;
  onInitialLoad?: () => void;
  onLoad?: () => void;
}) => {
  const keys = new KeyGetter<WorkorderWithIssues>();
  const filterBuilder = new FilterBuilder<WorkorderWithIssues>();
  filterBuilder.addString('friendlyId');
  filterBuilder.addString('property.address.address1');
  filterBuilder.addString('property.address.address2');
  filterBuilder.addString('status');
  filterBuilder.addBoolean('property.isOccupied');
  filterBuilder.addId('managementCompany.id');

  return useServerGrid<WorkorderWithIssues, WorkordersExternalFilter>({
    forceExpanded: forceExpanded,
    externalFilters: externalFilters,
    initialSortField: keys.get('dateModified'),
    initialSortDirection: SortDirection.Descending,
    initialFilters: filterBuilder.get(),
    onInitialLoad: onInitialLoad,
    onLoad: onLoad,
    idGetter: (item) => item.id,
    dataGetter: async ({ sortDirection, externalFilters, filterExtractor, sortField, take, skip }) => {
      const extractor = filterExtractor.makeTyped<WorkorderWithIssues>();
      const fieldEnum = fieldToEnum(sortField);
      const tableCompanyId = extractor.getString('managementCompany.id');
      const items = await apiProvider.workOrdersClient.getForUser(
        new WorkordersForUserPayload({
          filters: new TasksForUsersFilter({
            friendlyId: extractor.getString('friendlyId'),
            address1: extractor.getString('property.address.address1'),
            address2: extractor.getString('property.address.address2'),
            primaryPm: extractor.getString('property.manager.fullName'),
            createdAt: extractor.getInstant('dateCreated'),
            modifiedAt: extractor.getInstant('dateModified'),
            isOccupied: extractor.getBoolean('property.isOccupied'),
            workorderStatus: extractor.getNumber('status'),
            companyIds:
              tableCompanyId == null
                ? externalFilters.forCompanyId == null
                  ? null
                  : [externalFilters.forCompanyId]
                : [tableCompanyId],
            isActive: externalFilters.forActive,
            propertyId: externalFilters.forPropertyId,
            issueTitle: null,
            issueStatus: null,
            quickSearchQuery: null,
            workorderId: null,
            startDate: null,
            endDate: null,
            vendorIds: externalFilters.forVendors,
            subVendorIds: null,
            stateCodes: externalFilters.forState == null ? null : [externalFilters.forState],
            city: externalFilters.forCity,
            onlyTopLevelIssues: null,
            accountManagerId: externalFilters.forAccountManager
          }),
          category: externalFilters.forCategory,
          take: take,
          skip: skip,
          sortField: fieldEnum,
          sortDirection: sortDirection
        })
      );
      return {
        totalItems: items.totalItems,
        items: items.workorders
      };
    }
  });
};

const fieldToEnum = (sortField: string) => {
  let finalSortField = WorkorderSortField.Default;
  const keys = new KeyGetter<WorkorderWithIssues>();
  switch (sortField) {
    case keys.get('friendlyId'):
      finalSortField = WorkorderSortField.WorkorderId;
      break;
    case keys.get('property.address.address1'):
      finalSortField = WorkorderSortField.Address1;
      break;
    case keys.get('property.address.address2'):
      finalSortField = WorkorderSortField.Address2;
      break;
    case keys.get('property.manager.fullName'):
      finalSortField = WorkorderSortField.PrimaryPm;
      break;
    case keys.get('status'):
      finalSortField = WorkorderSortField.Status;
      break;
    case keys.get('dateCreated'):
      finalSortField = WorkorderSortField.CreatedAt;
      break;
    case keys.get('dateModified'):
      finalSortField = WorkorderSortField.ModifiedAt;
      break;
    case keys.get('property.isOccupied'):
      finalSortField = WorkorderSortField.IsOccupied;
      break;
    case keys.get('managementCompany.name'):
      finalSortField = WorkorderSortField.CompanyName;
  }
  return finalSortField;
};

export const WorkordersGrid = forwardRef<
  HTMLDivElement,
  {
    state: ServerGridState<WorkorderWithIssues, WorkordersExternalFilter>;
  }
>(({ state }, ref) => {
  const isMultiCompany = useIsMultiCompany();
  const isVendor = useHasCapability(CapabilityType.Vendor);
  const needsCompanyCol = isMultiCompany || isVendor;
  return (
    <div ref={ref} className={'workorder-container'}>
      <DataTable
        lazy
        paginator
        {...state.tableHandlers}
        rowExpansionTemplate={rowExpansionTemplate}
        cellClassName={(_, options: { rowData: IWorkorder }) => {
          return options.rowData?.isLegacy ?? false ? 'legacy-cell' : null;
        }}
        emptyMessage={<p className={'text-center'}>No workorders</p>}
        size={'small'}
        dataKey="id"
      >
        <Column expander />
        <Column
          field={'friendlyId'}
          header={'Workorder ID'}
          filter
          showFilterMatchModes={false}
          showFilterOperator={false}
          showAddButton={false}
          sortable
          body={(row: IWorkorder) => {
            return (
              <IconLink
                style={{ whiteSpace: 'nowrap' }}
                to={{ pathname: Routes.WorkorderView, params: { id: row.id } }}
                text={row.friendlyId}
              />
            );
          }}
        />
        {state.externalFilters.forPropertyId == null && (
          <Column
            field={'property.address.address1'}
            filter
            showFilterMatchModes={false}
            showFilterOperator={false}
            showAddButton={false}
            sortable
            header={'Address 1'}
          />
        )}
        {state.externalFilters.forPropertyId == null && (
          <Column
            field={'property.address.address2'}
            filter
            showFilterMatchModes={false}
            showFilterOperator={false}
            showAddButton={false}
            sortable
            header={'Address 2'}
          />
        )}
        {needsCompanyCol && (
          <Column
            field={'managementCompany.name'}
            sortable
            filter
            filterField={'managementCompany.id'}
            showFilterMatchModes={false}
            showFilterOperator={false}
            showAddButton={false}
            filterElement={(options) => <CompanyDropdown options={options} />}
            header={'Company'}
          />
        )}

        <Column
          field={'property.manager.fullName'}
          filter
          showFilterMatchModes={false}
          showFilterOperator={false}
          showAddButton={false}
          sortable
          header={'Primary PM'}
        />
        <Column
          field={'status'}
          filter
          showFilterMatchModes={false}
          showFilterOperator={false}
          showAddButton={false}
          filterElement={(options) => (
            <Dropdown
              value={options.value}
              options={workorderStatusOptions}
              onChange={(e) => options.filterCallback(e.value, options.index)}
              placeholder="Select a Status"
              className="p-column-filter"
              showClear
            />
          )}
          body={(row: IWorkorder) => rooEnum.workorderStatus.display(row.status)}
          sortable
          header={'Status'}
        />
        <Column
          field={'dateCreated'}
          sortable
          header={'Created'}
          dataType={'date'}
          body={(row: IWorkorder) => rooFmt.date(row.dateCreated)}
        />
        <Column
          field={'dateModified'}
          sortable
          header={'Modified'}
          dataType={'date'}
          body={(row: IWorkorder) => rooFmt.date(row.dateModified)}
        />
        {state.externalFilters.forPropertyId == null && (
          <Column
            field={'property.isOccupied'}
            sortable
            header={'Occupied'}
            filter
            showFilterMatchModes={false}
            dataType={'boolean'}
            filterElement={boolFilterTemplate}
            body={(row: IWorkorder) => (row.property.isOccupied ? 'Yes' : 'No')}
          />
        )}
      </DataTable>
    </div>
  );
});
