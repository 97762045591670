import {
  RemoveVendorMemberPayload,
  UpdateVendorMemberSettingsPayload,
  UserRole,
  Vendor,
  VendorMember
} from '../../shared/api/clients';
import { Button, Grid, IconButton, Link, Stack, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { apiProvider } from '../../shared/api/apiProvider';
import { CenteredLoader } from '../../components/CenteredLoader';
import MaterialReactTable, { MRT_ColumnDef } from 'material-react-table';
import { ConfirmationModal, RooAvatar, RooDialog, RouterIconButton } from '../../components';
import { Routes } from '../../shared/routing';
import { MuiIcon } from '../../shared/icons';
import { YesNoIcon } from '../../components/YesNoIcon';
import { useRequestGlobalModal } from '../../components/modals';
import { useCurrentUser, useCurrentVendor } from '../../shared/store';
import { z } from 'zod';
import { FieldMuiSwitch, useRooForm } from '../../components/form';
import { Disclosure, useDisclosure } from '@roo/lib';

export const SectionMembers = ({ vendor }: { vendor: Vendor }) => {
  const { isLoading, data } = useQuery(['vendor', vendor.id, 'members'], () =>
    apiProvider.vendorClient.getMembers(vendor.id)
  );

  if (isLoading) {
    return <CenteredLoader />;
  }

  return (
    <Stack alignItems={'center'} justifyContent={'center'} px={3}>
      <MembersTable members={data} vendor={vendor} />
    </Stack>
  );
};

const MembersTable = ({ members, vendor }: { members: VendorMember[]; vendor: Vendor }) => {
  const [, toggleAddVendorMember] = useRequestGlobalModal('addVendorMember');
  const isMember = useCurrentVendor()?.id === vendor.id;
  const columns = useMemo<MRT_ColumnDef<VendorMember>[]>(
    () => [
      {
        header: 'Name',
        accessorKey: 'user.fullName',
        Cell: ({ row }) => (
          <Stack direction={'row'} alignItems={'center'} spacing={0.5}>
            <RooAvatar name={row.original.user.fullName} avatarUrl={row.original.user.avatarUrl} size={'s'} />
            <Typography fontWeight={'bold'}>{row.original.user.fullName}</Typography>
          </Stack>
        )
      },

      {
        header: 'Phone',
        accessorKey: 'user.contactInfo.phoneNumber',
        Cell: ({ cell }) => {
          const val = cell.getValue<string>();
          return <Link href={`tel:${val}`}>{val}</Link>;
        },
        size: 150
      },
      {
        header: 'E-mail',
        accessorKey: 'user.contactInfo.email',
        Cell: ({ cell }) => {
          const val = cell.getValue<string>();
          return <Link href={`mailto:${val}`}>{val}</Link>;
        }
      },
      {
        header: 'Backoffice',
        accessorKey: 'isBackoffice',
        Cell: ({ row }) => <YesNoIcon value={row.original.isBackoffice} />
      },
      {
        id: 'actions',
        header: null,
        columnDefType: 'display',
        size: 150,
        Cell: ({ row }) => (
          <Stack direction={'row'} justifyContent={'flex-end'} spacing={2}>
            <DeleteMemberButton member={row.original} vendor={vendor} />
            <UpdateSettingsButton member={row.original} />
            <RouterIconButton
              color={'primary'}
              target={'_blank'}
              to={{ pathname: Routes.UserProfile, params: { id: row.original.user.id } }}
            >
              <MuiIcon.Visibility />
            </RouterIconButton>
          </Stack>
        )
      }
    ],
    []
  );

  return (
    <>
      <MaterialReactTable
        columns={columns}
        data={members}
        state={{ density: 'spacious' }}
        muiTablePaperProps={{
          sx: {
            width: '100%'
          }
        }}
        initialState={{
          sorting: [
            {
              id: 'isOwner',
              desc: true
            }
          ]
        }}
        renderTopToolbarCustomActions={() =>
          isMember ? (
            <Button
              startIcon={<MuiIcon.Add />}
              className={'Button-Basic'}
              variant={'contained'}
              sx={{
                color: 'white !important'
              }}
              onClick={toggleAddVendorMember}
            >
              Add
            </Button>
          ) : null
        }
        enableFullScreenToggle={false}
        enableDensityToggle={false}
        enablePagination={false}
        enableStickyHeader={true}
        enableTableFooter={false}
        enableHiding={false}
        enableColumnDragging={false}
        enableBottomToolbar={false}
      />
    </>
  );
};

const DeleteMemberButton = ({ member, vendor }: { member: VendorMember; vendor: Vendor }) => {
  const disclosure = useDisclosure();
  const qc = useQueryClient();
  const currentUser = useCurrentUser();
  const currentVendor = useCurrentVendor();

  const { mutate, isLoading } = useMutation({
    mutationFn: () =>
      apiProvider.vendorClient.removeMember(
        new RemoveVendorMemberPayload({
          userId: member.user.id,
          vendorId: member.vendorId
        })
      ),
    onSuccess: () => {
      void qc.invalidateQueries(['vendor', member.vendorId, 'members']);
      disclosure.close();
    }
  });

  const targetIsOwner = member.isOwner;
  const currentIsAdmin = currentUser.accountRole === UserRole.Admin || currentUser.accountRole === UserRole.Dev;
  const currentIsOwner = currentVendor != null && currentVendor.userId === currentUser.id;
  const currentIsTarget = currentUser.id === member.user.id;
  const isAllowed = !targetIsOwner && (currentIsAdmin || currentIsOwner || currentIsTarget);
  if (!isAllowed) {
    return null;
  }

  return (
    <>
      <IconButton color={'error'} onClick={disclosure.open}>
        <MuiIcon.Delete />
      </IconButton>
      <ConfirmationModal
        question={`Are you sure you want to remove ${member.user.fullName} from ${vendor.companyName}?`}
        visible={disclosure.isOpen}
        onClose={disclosure.close}
        onSave={mutate}
        running={isLoading}
      />
    </>
  );
};

const UpdateSettingsButton = ({ member }: { member: VendorMember }) => {
  const disclosure = useDisclosure(false);
  return (
    <>
      <IconButton color={'primary'} onClick={disclosure.open}>
        <MuiIcon.Edit />
      </IconButton>
      <UpdateSettingsModal member={member} disclosure={disclosure} />
    </>
  );
};

const UpdateSettingsModal = ({ disclosure, member }: { disclosure: Disclosure; member: VendorMember }) => {
  return (
    <RooDialog onClose={disclosure.close} fullWidth maxWidth={'sm'} open={disclosure.isOpen}>
      <RooDialog.Title onClose={disclosure.close}>Update Settings</RooDialog.Title>
      <UpdateSettingsForm disclosure={disclosure} member={member} />
    </RooDialog>
  );
};

const MemberSettingsSchema = z.object({
  isBackoffice: z.boolean()
});

type MemberSettingsForm = z.infer<typeof MemberSettingsSchema>;

const UpdateSettingsForm = ({ disclosure, member }: { disclosure: Disclosure; member: VendorMember }) => {
  const { control, handleSubmit } = useRooForm(MemberSettingsSchema, {
    defaultValues: {
      isBackoffice: member.isBackoffice
    }
  });
  const qc = useQueryClient();
  const { isLoading, mutateAsync } = useMutation({
    mutationFn: (values: MemberSettingsForm) =>
      apiProvider.vendorClient.updateMemberSettings(
        new UpdateVendorMemberSettingsPayload({
          vendorId: member.vendorId,
          userId: member.user.id,
          isBackoffice: values.isBackoffice
        })
      ),
    onSuccess: async () => {
      await qc.invalidateQueries(['vendor', member.vendorId, 'members']);
      disclosure.close();
    }
  });

  return (
    <>
      <form noValidate onSubmit={handleSubmit((vals) => mutateAsync(vals))}>
        <RooDialog.Content>
          <Grid item xs={12} md={12}>
            <h6>Is Backoffice User</h6>
            <FieldMuiSwitch control={control} name={'isBackoffice'} color="primary" />
          </Grid>
        </RooDialog.Content>
        <RooDialog.Actions>
          <Stack direction={'row'} spacing={2}>
            <Button type="submit" disabled={isLoading}>
              Update
            </Button>
            <Button color={'muted'} disabled={isLoading} onClick={disclosure.close}>
              Close
            </Button>
          </Stack>
        </RooDialog.Actions>
      </form>
    </>
  );
};
