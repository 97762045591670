import { useIsSmallScreen } from 'shared/utils';
import { ReactNode, useRef } from 'react';
import Drawer from 'rc-drawer';
import { RooButton } from 'components/RooButton';

export const RooDrawer = ({
  visible,
  onClose,
  children,
  title,
  noPadding = false,
  className
}: {
  visible: boolean;
  onClose: () => void;
  children: ReactNode;
  title: ReactNode;
  noPadding?: boolean;
  className?: string;
}) => {
  const isSmallScreen = useIsSmallScreen();
  const container = useRef<HTMLDivElement>(document.getElementById('drawerContainer') as HTMLDivElement);
  return (
    <>
      <Drawer
        handler={false}
        onClose={onClose}
        getContainer={() => container.current}
        open={visible}
        placement={'right'}
        width={isSmallScreen ? '100vw' : '25vw'}
        className={className}
      >
        <div style={{ padding: noPadding ? null : isSmallScreen ? '40px' : '20px', width: '100%' }}>
          {title && (
            <div style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center' }}>
              <strong>{title}</strong>

              <RooButton onClick={() => onClose()} variant={'link'} icon={'times'}>
                Close
              </RooButton>
            </div>
          )}
          {children}
        </div>
      </Drawer>
    </>
  );
};
