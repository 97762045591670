import { useEffect } from 'react';
import { matchPath, useLocation } from 'react-router';
import { useChatStore } from 'components/Chat/chatStore';
import { redirect, Routes } from 'shared/routing';
import { useAppStore } from 'shared/store';
import { LoaderBig } from 'components';
import { WithReqChildren } from 'shared/utils';
import { toast } from 'react-toastify';

export const PageInitializer = ({ children }: WithReqChildren) => {
  const location = useLocation();
  const {
    actions: { initialize: appInitialize },
    isLoading: appIsLoading,
    userDetails
  } = useAppStore();
  const { initialize: chatInitialize } = useChatStore();
  const implicitMatch = matchPath<{ id: string }>(location.pathname, {
    path: Routes.NewUserProfile,
    exact: true
  });

  const confirmMatch = matchPath(location.pathname, {
    path: Routes.ConfirmEmail
  });

  const showReload = useAppStore((x) => x.showUpgradeToast);
  useEffect(() => {
    if (!showReload || process.env.NODE_ENV === 'development') {
      return;
    }

    toast.info(
      <>
        <strong>New version available!</strong>
        <p style={{ marginBottom: 0 }}>Please save your work and refresh the page as soon as possible.</p>
      </>,
      {
        autoClose: false,
        onClick: (e) => e.preventDefault(),
        closeOnClick: false,
        closeButton: false,
        toastId: 'refresh-notice'
      }
    );
  }, [showReload]);

  useEffect(() => {
    const init = async () => {
      const currentUser = await appInitialize(implicitMatch?.params?.id);
      await chatInitialize(currentUser);
    };

    void init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (appIsLoading) {
    return <LoaderBig />;
  }

  if (confirmMatch == null && !userDetails.emailConfirmed) {
    redirect(Routes.ConfirmEmail);
  }

  return <>{children}</>;
};
