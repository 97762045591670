export const firstNotEmpty = (...strings: string[]) => {
  for (const str of strings) {
    if (str != null && str.trim() !== '') {
      return str;
    }
  }

  return null;
};

export const isDevelopment = () => {
  return process.env.NODE_ENV === 'development';
};

export const isNullOrEmpty = (str: string) => {
  return str == null || str === '';
};

export const toSnakeCase = (str = '') => {
  const strArr = str.split(' ');
  const snakeArr = strArr.reduce((acc, val) => {
    return acc.concat(val.toLowerCase());
  }, []);
  return snakeArr.join('_');
};

//https://stackoverflow.com/a/68227965
export const debouncePromise = <T extends {}>(task: (...args: any[]) => Promise<T>, delay: number) => {
  let t = { promise: null as any, cancel: (_?: any): any => void 0 };
  return async (...args: any[]) => {
    try {
      t.cancel();
      t = deferred(delay);
      await t.promise;
      return await task(...args);
    } catch (_) {
      /* prevent memory leak */
    }
  };
};

//https://stackoverflow.com/a/68227965
const deferred = (delay: number) => {
  let cancel,
    promise = new Promise((resolve, reject) => {
      cancel = reject;
      setTimeout(resolve, delay);
    });
  return { promise, cancel };
};

export const enumIsNot = <T extends {}>(needle: T, haystack: T[]) => {
  return haystack.find((x) => x === needle) == null;
};
