import { ChangeRequestStatus } from 'shared/api/clients';
import { ColoredEnumDisplay, createMap, EnumMap } from 'shared/store/enums/shared';

const InternalMapChangeRequestStatus: EnumMap<typeof ChangeRequestStatus, ColoredEnumDisplay> = {
  Draft: {
    display: 'Draft',
    color: 'secondary'
  },
  Accepted: {
    display: 'Accepted',
    color: 'success'
  },
  Declined: {
    display: 'Declined',
    color: 'danger'
  },
  Deleted: {
    display: 'Deleted',
    color: 'danger'
  },
  Edited: {
    display: 'Edited',
    color: 'secondary'
  },
  PendingReviewOwner: {
    display: 'Pending Review (Owner)',
    color: 'warning'
  },
  PendingReviewPM: {
    display: 'Pending Review (PM)',
    color: 'warning'
  },
  Obsolete: {
    display: '',
    color: 'secondary'
  }
};

export const EnumMapChangeRequestStatus = createMap<ChangeRequestStatus, ColoredEnumDisplay>(
  ChangeRequestStatus,
  InternalMapChangeRequestStatus
);
