import { Button } from '@mui/material';
import { sortBy } from 'lodash';
import { Column } from 'primereact/column';
import { DataTable as PrimeDataTable } from 'primereact/datatable';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { apiProvider } from 'shared/api/apiProvider';
import { CapabilityType, CompanyUserAccount } from 'shared/api/clients';
import { Routes } from 'shared/routing';
import { useCurrentUser } from 'shared/store';
import { RouterLink } from 'components';
import { rooFmt } from 'shared/utils';

type Props = {
  capability: CapabilityType;
};

type Handle = {
  refresh: () => void;
};

export const UserList = forwardRef<Handle, Props>(({ capability }, fwRef) => {
  const [{ loading, accounts }, setState] = useState({
    loading: true as boolean,
    accounts: [] as CompanyUserAccount[]
  });

  useImperativeHandle(fwRef, () => ({
    refresh: () => {
      void pull();
    }
  }));

  const currentUser = useCurrentUser();

  const pull = useCallback(async () => {
    try {
      const accounts = await apiProvider.usersClient.listAccounts(currentUser.managementCompany.id);
      setState({
        loading: false,
        accounts: accounts
      });
    } catch (e) {
      setState({ loading: false, accounts: [] });
    }
  }, [currentUser.managementCompany.id]);

  useEffect(() => {
    void pull();
  }, [pull]);
  const myAccounts = sortBy(accounts.filter((x) => x.capabilities.find((x) => x.type === capability)));

  const [filters] = useState({
    firstName: { operator: 'AND', constraints: [{ value: '', matchMode: 'contains' }] },
    lastName: { operator: 'AND', constraints: [{ value: '', matchMode: 'contains' }] },
    email: { operator: 'AND', constraints: [{ value: '', matchMode: 'contains' }] },
    createdDate: { operator: 'AND', constraints: [{ value: '', matchMode: 'contains' }] },
    lastLogin: { operator: 'AND', constraints: [{ value: '', matchMode: 'contains' }] }
  });

  return (
    <>
      <PrimeDataTable loading={loading} filters={filters as any} paginator rows={10} value={myAccounts} dataKey={'id'}>
        <Column
          sortable
          filter
          showFilterMatchModes={false}
          showFilterOperator={false}
          showAddButton={false}
          header={'First Name'}
          field={'firstName'}
        />
        <Column
          sortable
          filter
          showFilterMatchModes={false}
          showFilterOperator={false}
          showAddButton={false}
          header={'Last Name'}
          field={'lastName'}
        />
        <Column
          sortable
          filter
          showFilterMatchModes={false}
          showFilterOperator={false}
          showAddButton={false}
          header={'Email'}
          field={'email'}
        />
        <Column
          style={{ width: '140px' }}
          sortable
          filter
          showFilterMatchModes={false}
          showFilterOperator={false}
          showAddButton={false}
          header={'Created'}
          field={'createdDate'}
          body={(row: CompanyUserAccount) => rooFmt.date(row.createdDate)}
        />
        <Column
          style={{ width: '180px' }}
          sortable
          filter
          showFilterMatchModes={false}
          showFilterOperator={false}
          showAddButton={false}
          header={'Last Login'}
          field={'lastLogin'}
          body={(row: CompanyUserAccount) => rooFmt.dateMaybe(row.lastLogin, 'N/A')}
        />
        <Column
          header={'Actions'}
          style={{ width: '100px' }}
          body={(row: CompanyUserAccount) => (
            <>
              <Button
                component={RouterLink}
                color="primary"
                size="small"
                to={{ pathname: Routes.UserProfile, params: { id: row.userId, tab: 'profile' } }}
              >
                Profile
              </Button>
            </>
          )}
        />
      </PrimeDataTable>
    </>
  );
});
