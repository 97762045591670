import { Checkbox, FormControl, FormControlLabel, FormHelperText } from '@mui/material';
import { CheckboxProps } from '@mui/material/Checkbox/Checkbox';
import React from 'react';
import { FieldValues, useController } from 'react-hook-form';
import { getMuiHelperText } from 'components/form/ErrorMessage';
import { ControlProps } from 'components/form/types';

type BaseProps = Omit<CheckboxProps, 'id' | 'type' | 'name' | 'onChange' | 'value' | 'onBlur'>;
type FinalProps<TForm extends FieldValues> = ControlProps<TForm> & BaseProps & { helperText?: React.ReactNode };
export type FieldMuiCheckboxProps<TForm extends FieldValues> = FinalProps<TForm>;

export const FieldMuiCheckbox = <T extends FieldValues>({
  control,
  name,
  label,
  helperText,
  ...rest
}: FinalProps<T>) => {
  const {
    field,
    fieldState: { error }
  } = useController({
    control: control,
    name: name
  });
  const finalHelperText = getMuiHelperText(helperText, error);
  return (
    <FormControl>
      <FormControlLabel
        control={<Checkbox checked={field.value} onChange={field.onChange} onBlur={field.onBlur} {...rest} />}
        label={label}
      />
      {finalHelperText && <FormHelperText>{finalHelperText}</FormHelperText>}
    </FormControl>
  );
};
