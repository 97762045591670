import { Stack } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { apiProvider } from 'shared/api/apiProvider';
import { AttachmentEntityType, EntityAttachment, FileVisibility, IAttachmentFile } from 'shared/api/clients';
import { FileThumbnail } from 'components/Files/FileThumbnail';
import { downloadFiles } from 'components/Files/utils';
import { RooIcon } from 'shared/icons';
import { useCurrentUser } from 'shared/store';
import { ConfirmationModal, RooButton, UserLink } from 'components';
import { LoaderSmall } from 'components/LoaderSmall';
import { rooFmt } from 'shared/utils';
import { FilePickerModal } from './FilePicker/FilePicker';
import { useDisclosure } from '@roo/lib';

const MAX_FILES = 25;

const useFiles = (scope: UploadFileScope) => {
  const [iterator, setIterator] = useState(0);
  const refresh = useCallback(() => setIterator((x) => x + 1), []);
  const [{ files, loading }, setState] = useState<{ files: EntityAttachment[]; loading: boolean }>({
    files: null,
    loading: true
  });

  useEffect(() => {
    const load = async (entityType: AttachmentEntityType, entityId: string) => {
      try {
        const files = await apiProvider.filesClient.getForEntity(entityType, entityId);
        setState({ files: files, loading: false });
      } catch {}
    };

    setState({ files: null, loading: true });
    void load(scope.entityType, scope.entityId);
  }, [scope.entityId, scope.entityType, iterator]);

  return {
    refresh,
    files,
    loading
  };
};

export type UploadFileScope = {
  entityType: AttachmentEntityType;
  entityId: string;
  secondaryEntityType?: AttachmentEntityType;
  secondaryEntityId?: string;
  visibility: FileVisibility;
  allowUpload: boolean;
  forceAllowDelete: boolean;
  grow?: boolean;
};
export const FileList = (scope: UploadFileScope) => {
  const { refresh, files, loading } = useFiles(scope);
  const modal = useDisclosure(false, {
    onClose() {
      refresh();
    }
  });

  let body = <LoaderSmall />;
  if (!loading) {
    body = <FileListBody requestRefresh={refresh} attachments={files} forceAllowDelete={scope.forceAllowDelete} />;
  }

  const canUpload = !loading && scope.allowUpload && files.length < MAX_FILES;

  if (!loading && files.length === 0) {
    body = (
      <div className={'text-center'}>
        <p>There are no files yet</p>
        {canUpload && (
          <RooButton onClick={modal.open} icon={'upload'}>
            Upload
          </RooButton>
        )}
      </div>
    );
  }

  return (
    <Card style={{ flexGrow: scope.grow ? 1 : undefined }}>
      <Card.Header>
        <Stack direction={'row'} justifyContent={'space-between'}>
          Files
          <Stack direction={'row'} spacing={2}>
            {!loading && <DownloadAllButton files={files.map((x) => x.file)} />}
            {canUpload && (
              <button onClick={modal.open} className={'btn btn-primary btn-sm float-end slim'}>
                <RooIcon icon={['fas', 'upload']} /> Upload
              </button>
            )}
          </Stack>
        </Stack>
      </Card.Header>
      <Card.Body>{body}</Card.Body>
      {!loading && (
        <FilePickerModal
          maxFiles={MAX_FILES - (files?.length ?? 0)}
          modal={modal}
          meta={{
            entityId: scope.entityId,
            entityType: scope.entityType,
            secondaryEntityId: scope.secondaryEntityId,
            secondaryEntityType: scope.secondaryEntityType,
            visibility: scope.visibility
          }}
        />
      )}
    </Card>
  );
};

const DownloadAllButton = ({ files }: { files: IAttachmentFile[] }) => {
  if (files.length === 0) {
    return null;
  }

  return (
    <>
      <button onClick={() => downloadFiles(files)} className={'btn btn-primary btn-sm float-end slim'}>
        <RooIcon icon={['fas', 'file-archive']} /> Download All
      </button>
    </>
  );
};

const File = ({
  attachment,
  forceAllowDelete,
  requestRefresh
}: {
  attachment: EntityAttachment;
  requestRefresh: () => void;
  forceAllowDelete: boolean;
}) => {
  const currentUser = useCurrentUser();
  const canDelete = currentUser.id === attachment.createdBy?.id || forceAllowDelete;
  return (
    <>
      <li className={'list-group-item d-flex justify-content-between align-items-center'}>
        <div className={'d-flex'} style={{ minWidth: 0 }}>
          <div style={{ paddingRight: '16px' }}>
            <FileThumbnail file={attachment.file} />
          </div>

          <div style={{ display: 'inline-block', minWidth: 0 }}>
            <p className={'mb-1 wrap-text'} style={{ maxWidth: '100%' }}>
              {attachment.file.fileName}
            </p>
            <p className={'mb-0'}>
              <span className={'d-none d-md-inline'}>Uploaded by&nbsp;</span>
              {attachment.createdBy && <UserLink short user={attachment.createdBy} role={''} />}
              <span className={'d-block d-md-inline'}>&nbsp;{rooFmt.elapsed(new Date(attachment.createdAt))}</span>
            </p>
            {canDelete && <DeleteFileButton onDeleted={requestRefresh} attachment={attachment} />}
            <a
              className={'d-block d-md-none'}
              target={'_blank'}
              href={apiProvider.fileUrls.download(attachment.file.id)}
              rel="noreferrer"
            >
              <RooIcon icon={'download'} /> Download
            </a>
          </div>
        </div>
        <a
          className={'d-none d-md-block'}
          target={'_blank'}
          href={apiProvider.fileUrls.download(attachment.file.id)}
          rel="noreferrer"
        >
          <RooIcon icon={'download'} size={'2x'} />
        </a>
      </li>
    </>
  );
};

const DeleteFileButton = ({ attachment, onDeleted }: { attachment: EntityAttachment; onDeleted: () => void }) => {
  const [isConfirming, setIsConfirming] = useState(false);
  const [loading, setIsLoading] = useState(false);

  const requestImpersonation = async () => {
    try {
      setIsLoading(true);
      await apiProvider.filesClient.deleteFile(attachment.file.id);
      onDeleted();
    } catch (e) {
      setIsLoading(false);
    }
  };

  return (
    <>
      <RooButton
        onClick={() => setIsConfirming(true)}
        style={{ padding: '0px', color: '#c82333' }}
        variant={'link'}
        icon={'trash'}
      >
        Delete
      </RooButton>
      <ConfirmationModal
        visible={isConfirming}
        onClose={() => setIsConfirming(false)}
        onSave={() => void requestImpersonation()}
        question={`Are you sure you want to delete this file?`}
        running={loading}
      />
    </>
  );
};

const FileListBody = ({
  attachments,
  forceAllowDelete,
  requestRefresh
}: {
  attachments: EntityAttachment[];
  forceAllowDelete: boolean;
  requestRefresh: () => void;
}) => {
  return (
    <ul className={'list-group'}>
      {attachments.map((x) => (
        <File requestRefresh={requestRefresh} key={x.file.id} attachment={x} forceAllowDelete={forceAllowDelete} />
      ))}
    </ul>
  );
};
