import { useState } from 'react';
import { apiProvider } from 'shared/api/apiProvider';
import { IVendorCandidate, ReviewCandidateInvitationPayload, ReviewInvitationAction } from 'shared/api/clients';
import { ConfirmationModal, RooButton } from 'components';
import { useCurrentIssue } from 'pages/Workorders/shared/CurrentIssueContext';

export const ReviewInvitationButton = ({
  candidacy,
  action
}: {
  candidacy: IVendorCandidate;
  action: ReviewInvitationAction;
}) => {
  const { issue, onIssueUpdate } = useCurrentIssue();
  const [showModal, setShowModal] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const run = async () => {
    try {
      setIsSaving(true);
      const updatedIssue = await apiProvider.issues.assignments.reviewCandidateInvitation(
        new ReviewCandidateInvitationPayload({
          issueId: issue.id,
          action: action,
          candidacyId: candidacy.id
        })
      );
      setIsSaving(false);
      onIssueUpdate(updatedIssue);
      setShowModal(false);
    } catch (e) {
      setIsSaving(false);
    }
  };

  return (
    <>
      <RooButton
        onClick={() => setShowModal(true)}
        icon={action === ReviewInvitationAction.Accept ? 'check' : 'times'}
        variant={action === ReviewInvitationAction.Accept ? 'success' : 'danger'}
      >
        {action === ReviewInvitationAction.Accept ? 'Accept' : 'Reject'}
      </RooButton>
      <ConfirmationModal
        question={
          action === ReviewInvitationAction.Accept
            ? 'Do you want to accept this Issue?'
            : 'Do you want to reject this Issue?'
        }
        visible={showModal}
        onClose={() => setShowModal(false)}
        onSave={() => void run()}
        running={isSaving}
      />
    </>
  );
};
