import { Stack, Switch } from '@mui/material';
import {
  ErrorScreen,
  LoaderBig,
  MessageBig,
  MuiRouterLink,
  NavSlim,
  PageContentSection,
  PropertyCard,
  RooButton,
  UserLink,
  WithPlaceholder
} from 'components';
import { IssueChat } from 'components/Chat/Chat';
import { useChatStore } from 'components/Chat/chatStore';
import { FileList } from 'components/Files/FileList';
import { AddEditIssueModal } from 'pages/Workorders/AddEditIssueModal';
import { CurrentIssueContext, useCurrentIssue } from 'pages/Workorders/shared/CurrentIssueContext';
import { LegacyNotice } from 'pages/Workorders/shared/LegacyNotice';
import { BulletinBoardView } from 'pages/Workorders/ViewIssue/BulletinBoard/BulletinBoardView';
import { BidManager } from 'pages/Workorders/ViewIssue/Documents/Bids/BidManager';
import { ChangeRequestList } from 'pages/Workorders/ViewIssue/Documents/ChangeRequests/ChangeList';
import { EstimateListStandalone } from 'pages/Workorders/ViewIssue/Documents/Estimates/EstimateList';
import { EstimateManager } from 'pages/Workorders/ViewIssue/Documents/Estimates/EstimateManager';
import { InvoiceList } from 'pages/Workorders/ViewIssue/Documents/Invoices/InvoiceList';
import { InvitationList } from 'pages/Workorders/ViewIssue/InviteVendors/InvitationList';
import { ActionModalsRenderer, IssueActivityBar } from 'pages/Workorders/ViewIssue/IssueActivityBar';
import { IssueActivityLog } from 'pages/Workorders/ViewIssue/IssueActivityLog';
import { IssueCallToAction } from 'pages/Workorders/ViewIssue/IssueCallToAction';
import { IssueContacts } from 'pages/Workorders/ViewIssue/IssueContacts';
import { ScheduledVisitListCard } from 'pages/Workorders/ViewIssue/ScheduledVisitListCard';
import { SubissueList } from 'pages/Workorders/ViewIssue/SubissueList';
import { JumpStatusModal } from 'pages/Workorders/ViewIssue/Workflow/JumpStatusModal';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { Badge, Card, Col, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router';
import { apiProvider } from 'shared/api/apiProvider';
import {
  AttachmentEntityType,
  CandidacyStatus,
  FileVisibility,
  FlaggedEntityType,
  FlagType,
  GetFlagPayload,
  IIssueUserView,
  IssueAction,
  IssueRole,
  IssueStatus,
  IssueUserView,
  LocationType,
  NoteEntityType,
  NotificationStatusEntityType,
  SetFlagPayload,
  SetIssueNotificationStatusPayload
} from 'shared/api/clients';
import { RooIcon } from 'shared/icons';
import { Routes } from 'shared/routing';
import { rooEnum, useAppStore, useCompanyContext, useContractorCanManageCompany } from 'shared/store';
import { rooFmt, showSuccess, useSearchParams } from 'shared/utils';
import { Payments } from './Documents/Payments/Payments';
import { InternalNotesCard } from '../shared/InternalNotesCard';
import { ErrorBoundary } from '../../../components/ErrorBoundary';
import { bidModalComponents } from './Documents/Bids/BidList/actions';
import { ChecklistCard } from './Documents/Checklist/ChecklistCard';
import { useMutation, useQuery } from '@tanstack/react-query';
import { ColorSwitch } from '../../../components/ColorSwitch';
import { makeUuid } from '@roo/lib';
import { SplitEstimateModal } from './Documents/Estimates/SplitEstimateModal';
import { SplitInvoiceModal } from './Documents/Invoices/SplitInvoiceModal';
import { SplitBidModal } from './Documents/Bids/BidList/SplitBidModal';
import { ClientIssueNotice } from '../shared/ClientIssueNotice';

type ViewIssueState = { issueView: IssueUserView; isLoading: boolean };

export const ViewIssue = () => {
  const [logKey, setLogKey] = useState(makeUuid());
  const { workorderId, issueId } = useParams<{ workorderId: string; issueId: string }>();
  const [{ issueView, isLoading }, setState] = useState<ViewIssueState>({
    issueView: null,
    isLoading: true
  });

  const currentUser = useAppStore((x) => x.userDetails);
  const refreshChat = useChatStore((x) => x.refreshStore);
  const refreshLog = useCallback(() => setLogKey(makeUuid()), []);

  const updateIssue = useCallback(
    (userIssue: Partial<IIssueUserView>) => {
      refreshChat();
      refreshLog();
      if (userIssue != null) {
        setState((old) => ({
          isLoading: old.isLoading,
          issueView: new IssueUserView({
            ...old.issueView,
            ...userIssue
          })
        }));
      }
    },
    [refreshChat, refreshLog]
  );

  useEffect(() => {
    const load = async () => {
      setState({ issueView: null, isLoading: true });
      try {
        const issueForUser = await apiProvider.issues.main.getForUser(issueId);
        setState({
          issueView: issueForUser,
          isLoading: false
        });
      } catch (err) {
        setState({
          issueView: null,
          isLoading: false
        });
      }
    };

    void load();
  }, [issueId, workorderId, currentUser]);

  if (isLoading) {
    return <LoaderBig />;
  }

  if (issueView?.issue == null) {
    return <MessageBig message={"This issue does not exist or you're not allowed to view it"} />;
  }

  return (
    <CurrentIssueContext.Provider
      value={{
        ...issueView,
        onIssueUpdate: updateIssue,
        canAct: (action: IssueAction) => issueView.allowedActions.includes(action)
      }}
    >
      <IssuePage logKey={logKey} />
    </CurrentIssueContext.Provider>
  );
};

const IssuePage = ({ logKey }: { logKey: string }) => {
  const { issue, workorder, userRole, myCandidacy, parentIssue } = useCurrentIssue();

  if (userRole === IssueRole.None) {
    return <MessageBig message={"You're not allowed to view this issue"} />;
  }

  if (userRole === IssueRole.Bidder && issue.status !== IssueStatus.Bidding) {
    return <MessageBig message={'Your bid has been rejected'} />;
  }

  if (userRole === IssueRole.VendorCandidate) {
    if (myCandidacy == null) {
      return <MessageBig message={'This issue is no longer available.'} />;
    }

    if (myCandidacy.status === CandidacyStatus.Expired) {
      return <MessageBig message={'This issue assignment has expired.'} />;
    }

    if (myCandidacy.status === CandidacyStatus.NoLongerAvailable) {
      return <MessageBig message={'Another vendor has accepted this issue before you.'} />;
    }

    if (myCandidacy.status === CandidacyStatus.Declined) {
      return <MessageBig message={'You have declined the invitation to work on this issue.'} />;
    }
  }

  return (
    <>
      <Helmet>
        <title>{issue.friendlyId} - Issue Details - Walkthroo</title>
      </Helmet>
      <NavSlim
        breadcrumbs={[
          { text: workorder.friendlyId, destination: { pathname: Routes.WorkorderView, params: { id: workorder.id } } },
          parentIssue != null &&
          (userRole === IssueRole.MainPropertyManager ||
            userRole === IssueRole.AltPropertyManager ||
            userRole === IssueRole.AdminViewer)
            ? {
                text: parentIssue.friendlyId,
                destination: {
                  pathname: Routes.IssueView,
                  params: { workorderId: workorder.id, issueId: parentIssue.id }
                }
              }
            : null,
          {
            text: issue.friendlyId,
            destination: { pathname: Routes.IssueView, params: { workorderId: workorder.id, issueId: issue.id } }
          }
        ]}
      />

      <PageContentSection>
        <Row>
          <Col sm={12} className={'d-block d-none-md'}>
            <IssueActivityBar />
          </Col>
          <Col xl={3}>
            <IssueSidebar />
          </Col>
          <Col xl={9}>
            <IssueBody logKey={logKey} />
          </Col>
        </Row>
      </PageContentSection>
      <ActionModalsRenderer />
    </>
  );
};

const IssueSidebar = () => {
  const { issue, workorder, userRole, property, managementCompany, formDefaults } = useCurrentIssue();
  const gcCanManage = useContractorCanManageCompany(managementCompany.id);
  const canViewMeta =
    userRole === IssueRole.AdminViewer ||
    userRole === IssueRole.AltPropertyManager ||
    userRole === IssueRole.MainPropertyManager ||
    userRole === IssueRole.PropertyOwner ||
    gcCanManage;
  return (
    <Card>
      <Card.Header>
        <strong>Issue</strong>
        <EditIssueButton />
      </Card.Header>
      <Card.Body>
        <h4 className={'mb-0'}>Issue #{issue.friendlyId}</h4>
        <div className={'d-flex'}>
          <IssueStatusBadge />
        </div>
        {userRole !== IssueRole.Bidder &&
          userRole !== IssueRole.VendorCandidate &&
          userRole !== IssueRole.AdminViewer && (
            <div className={'mt-2 mb-2'}>
              <NotificationStatus />
            </div>
          )}
        {(userRole === IssueRole.MainPropertyManager ||
          userRole === IssueRole.AltPropertyManager ||
          (userRole === IssueRole.Vendor && gcCanManage)) && (
          <div className={'mb-2'}>
            <NeedsAttentionFlag />
          </div>
        )}

        <dl>
          <dt>Workorder #{workorder.friendlyId}</dt>
        </dl>
        <div>
          <strong>Property</strong>
        </div>

        <PropertyCard className={'mb-2'} property={property} showZillow={true} />
        <dl>
          <dt>Ref#</dt>
          <dd>
            <WithPlaceholder value={workorder.externalReference} />
          </dd>
          <dt>Gate Code</dt>
          <dd>
            <WithPlaceholder value={workorder.gateCode} />
          </dd>
          <dt>Lock Box</dt>
          <dd>
            <WithPlaceholder value={workorder.lockBox} />
          </dd>
          <dt>Notes</dt>
          <dd>
            <WithPlaceholder value={workorder.notes} />
          </dd>
        </dl>
        {canViewMeta && (
          <dl>
            <dt>Company</dt>
            <dd>
              <MuiRouterLink
                target={'_blank'}
                to={{ pathname: Routes.CompanyProfile, params: { companyId: managementCompany.id } }}
              >
                {managementCompany.name}
              </MuiRouterLink>
            </dd>
            {formDefaults.discountPercent != null && (
              <>
                <dt>Discount %</dt>
                <dd>{rooFmt.percentMaybe(formDefaults.discountPercent, 'N/A')}</dd>
              </>
            )}
            {formDefaults.taxPercent != null && (
              <>
                <dt>Tax %</dt>
                <dd>{rooFmt.percentMaybe(formDefaults.taxPercent, 'N/A')}</dd>
              </>
            )}

            <dt>Created</dt>
            <dd>
              {rooFmt.date(issue.dateCreated)} by <UserLink user={issue.createdBy} role={null} />
            </dd>
          </dl>
        )}

        <Stack spacing={1}>
          <strong>Key Contacts</strong>
          <IssueContacts />
        </Stack>
      </Card.Body>
    </Card>
  );
};

function VendorInfo() {
  const { issue } = useCurrentIssue();
  return (
    <>
      <dt>Vendor</dt>
      <dd>
        <WithPlaceholder value={issue.vendor?.companyName} />
      </dd>
    </>
  );
}

const IssueBody = ({ logKey }: { logKey: string }) => {
  const { issue } = useCurrentIssue();
  return (
    <>
      <LegacyNotice item={issue} type={'issue'} />
      <ClientIssueNotice companyId={issue.managementCompanyId} />
      <NotesSection />
      <div className={'d-none d-block-md'}>
        <IssueActivityBar />
      </div>

      <IssueCallToAction />
      <Row>
        <IssueDescriptionSection />
        <SubissueListSection />
        <EstimateManagerSection />
        <EstimateListSection />
        <InvoiceManagerSection />
        <PaymentsSection />
        <TaskLinesSection />
        <ChangeRequestSection />
        <BidManagerSection />
        <ScheduledVisitListCard />
        <InvitationListSection />
        <MessagesSection />
        <BulletinBoardSection />

        <FilesSection />
        <ActivityLogSection logKey={logKey} />
        <SplitEstimateFromUrlModal />
        <SplitBidFromUrlModal />
        <SplitInvoiceFromUrlModal />
      </Row>
    </>
  );
};

const IssueDescriptionSection = () => {
  const { issue } = useCurrentIssue();
  return (
    <Col xl={12}>
      <Card className={'mb-4'}>
        <Card.Body>
          <Row>
            <Col sm={6} xl={3}>
              <dl>
                <dt>Title</dt>
                <dd>{issue.title}</dd>
              </dl>
            </Col>
            <Col sm={6} xl={3}>
              <VendorInfo />
            </Col>

            <Col sm={6} xl={3}>
              <dt>Location</dt>
              <dd>
                <WithPlaceholder
                  value={
                    issue.locationType === LocationType.Interior
                      ? issue.interiorLocation?.name
                      : issue.exteriorLocation?.name
                  }
                />
              </dd>
            </Col>
            <Col sm={6} xl={3}>
              <dt>Work Type</dt>
              <dd>
                <WithPlaceholder value={issue.workType?.name} />
              </dd>
            </Col>
            {!!issue.description && (
              <Col sm={12} xl={12}>
                <dt>Description</dt>
                <dd style={{ whiteSpace: 'pre-wrap' }}>{issue.description}</dd>
              </Col>
            )}
          </Row>
        </Card.Body>
      </Card>
    </Col>
  );
};

const InvoiceManagerSection = () => {
  const { issue, userRole } = useCurrentIssue();
  const canHaveInvoices = issue.vendor != null;
  const canViewInvoices =
    userRole === IssueRole.MainPropertyManager ||
    userRole === IssueRole.AltPropertyManager ||
    userRole === IssueRole.Vendor ||
    userRole === IssueRole.AdminViewer;

  if (!canHaveInvoices || !canViewInvoices) {
    return null;
  }

  return (
    <Col xl={12} className={'mb-4'}>
      <InvoiceList />
    </Col>
  );
};

const PaymentsSection = () => {
  const { issue, userRole } = useCurrentIssue();
  if (issue.vendor == null) {
    return null;
  }

  const allowedRoles = [
    IssueRole.AdminViewer,
    IssueRole.MainPropertyManager,
    IssueRole.AltPropertyManager,
    IssueRole.Vendor
  ];

  if (!allowedRoles.includes(userRole)) {
    return null;
  }

  return (
    <Col xl={12} className={'mb-4'}>
      <Payments />
    </Col>
  );
};

const EstimateManagerSection = () => {
  const { issue, userRole } = useCurrentIssue();
  const canHaveEstimates = issue.status === IssueStatus.Estimating;
  const canViewEstimates =
    userRole === IssueRole.MainPropertyManager ||
    userRole === IssueRole.AltPropertyManager ||
    userRole === IssueRole.Vendor;

  if (!canHaveEstimates || !canViewEstimates) {
    return null;
  }

  return (
    <Col xl={12} className={'mb-4'}>
      <EstimateManager />
    </Col>
  );
};

const EstimateListSection = () => {
  const { issue, userRole, estimates } = useCurrentIssue();
  const excludedStatuses = [
    IssueStatus.New,
    IssueStatus.AssignedToVendor,
    IssueStatus.Estimating,
    IssueStatus.Bidding,
    IssueStatus.AssignedToMultipleVendors,
    IssueStatus.VendorUnresponsive
  ];
  const canViewEstimates =
    userRole === IssueRole.MainPropertyManager ||
    userRole === IssueRole.AltPropertyManager ||
    userRole === IssueRole.Vendor;
  if (!canViewEstimates || excludedStatuses.indexOf(issue.status) >= 0) {
    return null;
  }

  const hasExisting = estimates.length > 0;
  if (!hasExisting) {
    return null;
  }

  return (
    <Col xl={12} className={'mb-4'}>
      <EstimateListStandalone />
    </Col>
  );
};

const TaskLinesSection = () => {
  const { issue, userRole, checklist } = useCurrentIssue();
  const canView =
    userRole === IssueRole.MainPropertyManager ||
    userRole === IssueRole.AltPropertyManager ||
    userRole === IssueRole.Vendor ||
    userRole === IssueRole.Tech;

  const allowedStatuses = [
    IssueStatus.Closed,
    IssueStatus.Paid,
    IssueStatus.PaymentPending,
    IssueStatus.InvoiceReview,
    IssueStatus.NeedsInvoice
  ];

  const hasLines = checklist.length > 0;

  if (!canView || !hasLines || !allowedStatuses.includes(issue.status)) {
    return null;
  }

  return (
    <Col xl={12} className={'mb-4'}>
      <ChecklistCard />
    </Col>
  );
};

const SplitEstimateFromUrlModal = () => {
  const {
    searchParams: { splitEstimateId },
    setSearchParam
  } = useSearchParams<{ splitEstimateId: string }>();

  const open = () => {
    throw new Error('attempt to programmatically open url-controlled modal ');
  };
  const close = () => setSearchParam('splitEstimateId', null);

  return (
    <SplitEstimateModal
      estimateId={splitEstimateId}
      modal={{
        open: open,
        isOpen: splitEstimateId != null,
        close: close,
        toggle: () => {
          if (splitEstimateId) {
            close();
          } else {
            throw new Error('attempt to programmatically open url-controlled modal ');
          }
        }
      }}
    />
  );
};

const SplitInvoiceFromUrlModal = () => {
  const {
    searchParams: { splitInvoiceId },
    setSearchParam
  } = useSearchParams<{ splitInvoiceId: string }>();

  const open = () => {
    throw new Error('attempt to programmatically open url-controlled modal ');
  };
  const close = () => setSearchParam('splitInvoiceId', null);

  return (
    <SplitInvoiceModal
      invoiceId={splitInvoiceId}
      modal={{
        open: open,
        isOpen: splitInvoiceId != null,
        close: close,
        toggle: () => {
          if (splitInvoiceId) {
            close();
          } else {
            throw new Error('attempt to programmatically open url-controlled modal ');
          }
        }
      }}
    />
  );
};

const SplitBidFromUrlModal = () => {
  const {
    searchParams: { splitBidId },
    setSearchParam
  } = useSearchParams<{ splitBidId: string }>();

  const open = () => {
    throw new Error('attempt to programmatically open url-controlled modal ');
  };
  const close = () => setSearchParam('splitBidId', null);

  return (
    <SplitBidModal
      bidId={splitBidId}
      modal={{
        open: open,
        isOpen: splitBidId != null,
        close: close,
        toggle: () => {
          if (splitBidId) {
            close();
          } else {
            throw new Error('attempt to programmatically open url-controlled modal ');
          }
        }
      }}
    />
  );
};

const ChangeRequestSection = () => {
  const { visibleChangeRequests } = useCurrentIssue();
  if (visibleChangeRequests.length <= 0) {
    return null;
  }

  return (
    <Col xl={12} className={'mb-4'}>
      <ChangeRequestList />
    </Col>
  );
};

const BidManagerSection = () => {
  const { visibleBids, canAct } = useCurrentIssue();
  if (!canAct(IssueAction.RequestBid) && visibleBids.length === 0) {
    return null;
  }

  return (
    <Col xl={12} className={'mb-4'} id={'bids-anchor'}>
      <BidManager />
      {bidModalComponents.map((x, i) => (
        <Fragment key={i}>{x}</Fragment>
      ))}
    </Col>
  );
};

const SubissueListSection = () => {
  const { canAct } = useCurrentIssue();
  if (!canAct(IssueAction.ManageSubissues)) {
    return null;
  }

  return (
    <Col xl={12} className={'mb-4'}>
      <SubissueList />
    </Col>
  );
};

const InvitationListSection = () => {
  const { userRole, issue } = useCurrentIssue();
  const canView =
    userRole === IssueRole.PropertyOwner ||
    userRole === IssueRole.MainPropertyManager ||
    userRole === IssueRole.AltPropertyManager;
  const hasCandidates = issue.vendorCandidates.length > 0;
  if (!canView || !hasCandidates) {
    return null;
  }
  return (
    <Col xl={12} className={'mb-4'}>
      <InvitationList />
    </Col>
  );
};

const MessagesSection = () => {
  const { userRole } = useCurrentIssue();
  if (userRole === IssueRole.AdminViewer) {
    return null;
  }

  return (
    <Col xl={12} className={'mb-4'} id={'chat-anchor'}>
      <Card>
        <Card.Header>Messages</Card.Header>
        <Card.Body>
          <ErrorBoundary fallback={ErrorScreen}>
            <IssueChat />
          </ErrorBoundary>
        </Card.Body>
      </Card>
    </Col>
  );
};

const BulletinBoardSection = () => {
  const { userRole } = useCurrentIssue();
  const isExcluded = userRole === IssueRole.Bidder || userRole === IssueRole.VendorCandidate;
  if (isExcluded) {
    return null;
  }

  return (
    <Col xl={12} className={'mb-4'}>
      <BulletinBoardView />
    </Col>
  );
};

const NotesSection = () => {
  const { issue, parentIssue, userRole } = useCurrentIssue();
  const companyCtx = useCompanyContext();
  const canView = companyCtx != null;
  if (userRole === IssueRole.AdminViewer) {
    return null;
  }
  if (!canView) {
    return null;
  }
  return (
    <Row>
      <Col xl={12} className={'mb-4'}>
        <InternalNotesCard entityType={NoteEntityType.Issue} entityId={parentIssue?.id ?? issue.id} />
      </Col>
    </Row>
  );
};

const FilesSection = () => {
  const { issue, userRole } = useCurrentIssue();
  return (
    <Col xl={12} className={'mb-4'}>
      <FileList
        entityType={AttachmentEntityType.Issue}
        entityId={issue.id}
        secondaryEntityType={issue.parentId != null ? AttachmentEntityType.Issue : null}
        secondaryEntityId={issue.parentId}
        allowUpload={userRole !== IssueRole.AdminViewer}
        forceAllowDelete={
          userRole === IssueRole.MainPropertyManager ||
          userRole === IssueRole.AltPropertyManager ||
          userRole === IssueRole.AdminViewer
        }
        visibility={
          userRole === IssueRole.Bidder || userRole === IssueRole.VendorCandidate
            ? FileVisibility.Private
            : FileVisibility.Public
        }
      />
    </Col>
  );
};

const ActivityLogSection = ({ logKey }: { logKey: string }) => {
  const { userRole } = useCurrentIssue();
  const isExcluded = userRole === IssueRole.Bidder || userRole === IssueRole.VendorCandidate;
  if (isExcluded) {
    return null;
  }
  return (
    <Col xl={12}>
      <IssueActivityLog key={logKey} />
    </Col>
  );
};

const EditIssueButton = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { workorder, issue, onIssueUpdate, allowedActions } = useCurrentIssue();
  if (!allowedActions.includes(IssueAction.EditIssue)) {
    return null;
  }

  return (
    <>
      <button onClick={() => setModalIsOpen(true)} className={'btn btn-primary btn-sm float-end slim'}>
        <RooIcon icon={['fas', 'pencil-alt']} /> Edit
      </button>
      <AddEditIssueModal
        title={'Edit Issue'}
        workorderId={workorder.id}
        propertyId={issue.propertyId}
        managementCompanyId={issue.managementCompanyId}
        issue={issue}
        visible={modalIsOpen}
        onCloseRequest={() => setModalIsOpen(false)}
        onIssueSaved={(newIssue) => {
          onIssueUpdate(newIssue);
          showSuccess();
        }}
      />
    </>
  );
};

const IssueStatusBadge = () => {
  const { cosmeticStatus, allowedActions } = useCurrentIssue();
  const [isVisible, setIsVisible] = useState(false);

  const badge = (
    <Badge style={{ marginRight: '5px' }} bg={rooEnum.issueStatus.color(cosmeticStatus)}>
      {rooEnum.issueStatus.display(cosmeticStatus)}
    </Badge>
  );

  if (!allowedActions.includes(IssueAction.JumpStatus)) {
    return <p style={{ marginBottom: 0 }}>{badge}</p>;
  }

  return (
    <>
      <RooButton
        style={{ paddingLeft: 0, textDecoration: 'none' }}
        variant={'link'}
        icon={'pencil-alt'}
        onClick={() => setIsVisible(true)}
      >
        {badge}
      </RooButton>
      <JumpStatusModal visible={isVisible} onCloseRequest={() => setIsVisible(false)} />
    </>
  );
};

const NotificationStatus = () => {
  const { issue } = useCurrentIssue();
  const allStatuses = useAppStore((x) => x.notificationStatuses);
  const setStoreStatus = useAppStore((x) => x.actions.setNotificationStatus);
  const storeEnabled =
    allStatuses.find((x) => x.entityId === issue.id && x.entityType === NotificationStatusEntityType.Issue)?.enabled ??
    true;
  const [enabled, setEnabled] = useState(storeEnabled);

  const updateSettings = async (value: boolean) => {
    try {
      setEnabled(value);
      await apiProvider.issues.main.setNotificationStatus(
        new SetIssueNotificationStatusPayload({
          enabled: value,
          issueId: issue.id
        })
      );
      setStoreStatus(issue.id, NotificationStatusEntityType.Issue, enabled);
    } catch (err) {
      setEnabled(enabled);
    }
  };

  return (
    <Stack direction={'row'} spacing={2} alignItems={'center'} justifyContent={'space-between'}>
      <label className={'mb-0'} style={{ display: 'inline-block' }}>
        Notifications
      </label>
      <Switch checked={enabled} onChange={(e) => void updateSettings(e.target.checked)} />
    </Stack>
  );
};

const NeedsAttentionFlag = () => {
  const { issue } = useCurrentIssue();
  const targetId = issue.parentId ?? issue.id;
  const { isLoading, isError, data } = useQuery(
    ['flag', FlagType.NeedsAttention, targetId, FlaggedEntityType.Issue],
    () =>
      apiProvider.flagsClient.getForEntity(
        new GetFlagPayload({
          flag: FlagType.NeedsAttention,
          entityId: targetId,
          entityType: FlaggedEntityType.Issue
        })
      )
  );

  if (isLoading || isError) {
    return null;
  }

  return <NeedsAttentionSwitch targetId={targetId} currentValue={data} />;
};

const NeedsAttentionSwitch = ({ currentValue, targetId }: { targetId: string; currentValue: boolean }) => {
  const [value, setValue] = useState(currentValue);
  const { mutate } = useMutation((value: boolean) =>
    apiProvider.flagsClient.set(
      new SetFlagPayload({
        value,
        entityId: targetId,
        entityType: FlaggedEntityType.Issue,
        flag: FlagType.NeedsAttention
      })
    )
  );
  const onUpdate = (val: boolean) => {
    mutate(val);
    setValue(val);
  };
  return (
    <Stack direction={'row'} spacing={2} alignItems={'center'} justifyContent={'space-between'}>
      <label className={'mb-0'} style={{ display: 'inline-block' }}>
        Needs Attention
      </label>
      <ColorSwitch
        checkedColor={'success'}
        uncheckedColor={'error'}
        checked={value}
        onChange={(e) => onUpdate(e.target.checked)}
      />
    </Stack>
  );
};
