import { Rating, SvgIcon } from '@mui/material';
import { ReactComponent as RooKangarooOutline } from 'images/roo-logo-kangaroo-outline.svg';
import { ReactComponent as RooKangaroo } from 'images/roo-logo-kangaroo.svg';
import { ComponentProps } from 'react';

type RooRatingProps = {
  value: number;
  size: 'small' | 'medium' | 'large';
  onChange?: (value: number) => void;
} & Omit<ComponentProps<typeof Rating>, 'value' | 'onChange' | 'icon' | 'emptyIcon'>;

export const RooRating = ({ value, onChange, size, ...props }: RooRatingProps) => {
  let sizePx = '48px';
  let strokeWidth = '7';

  if (size === 'small') {
    sizePx = '24px';
    strokeWidth = '20';
  }

  if (size === 'medium') {
    sizePx = '32px';
    strokeWidth = '12';
  }

  return (
    <Rating
      size={size}
      sx={(theme) => ({
        '& .MuiRating-icon .MuiSvgIcon-root path': {
          strokeWidth: strokeWidth
        },
        '& .MuiRating-icon': {
          color: theme.palette.primary.main
        },
        '&.Mui-disabled .MuiRating-icon': {
          color: theme.palette.grey.A400
        }
      })}
      icon={
        <SvgIcon sx={{ width: sizePx, height: sizePx }}>
          <RooKangaroo />
        </SvgIcon>
      }
      emptyIcon={
        <SvgIcon sx={{ width: sizePx, height: sizePx }}>
          <RooKangarooOutline />
        </SvgIcon>
      }
      value={value}
      onChange={(event, newValue) => {
        onChange?.(newValue);
      }}
      {...props}
    />
  );
};
