import { Box, Button, Chip, CircularProgress, Grid, IconButton, Stack, Typography } from '@mui/material';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Address, CompanyFeatureFlag, DeletePropertyPayload, IVendor, RooPermission, Vendor } from 'shared/api/clients';
import {
  makeLocationColumn,
  makeReviewsColumn,
  makeServicesColumn,
  useTableVendors,
  useVendorRadius,
  VendorRadiusSelector,
  vendorTableIcons
} from 'components/VendorSelector/VendorSelector';
import { Routes } from 'shared/routing';
import {
  AuthManager,
  useCurrentUser,
  useCurrentVendor,
  useFocusOptions,
  useHasFeatureFlag,
  useHasPermission,
  useIsGeneralContractor
} from 'shared/store';
import { ConfirmationModal, ContentWrapper, NavSlim, RooAvatar, RouterLink } from 'components';
import { makeBasicFilter, showSuccess } from 'shared/utils';
import { apiProvider } from '../../../shared/api/apiProvider';
import { MuiIcon } from 'shared/icons';
import { useRequestGlobalModal } from '../../../components/modals';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

type TableVendor = IVendor & {
  index: number;
  distance: number;
};

export const VendorList = () => {
  const [, toggleBulk] = useRequestGlobalModal('vendorBulkImport');
  const currentUser = useCurrentUser();
  const isGc = useIsGeneralContractor();
  const canAddEditVendors = useHasPermission(RooPermission.AddEditVendors, currentUser.managementCompany?.id) || isGc;
  const referenceAddress = currentUser.address;
  const hasPoolFlag = useHasFeatureFlag(CompanyFeatureFlag.VendorPool);
  const hasPool = AuthManager.instance.isImpersonating() || hasPoolFlag;

  const { data, isLoading } = useQuery(['vendors', 'for-user'], {
    queryFn: async () =>
      await Promise.all([
        apiProvider.vendorClient.vendorsForUser(currentUser.id),
        apiProvider.vendorClient.vendorPoolForUser(currentUser.id)
      ])
  });

  if (isLoading) {
    return (
      <>
        <Stack p={4} justifyContent={'center'} alignItems={'center'}>
          <CircularProgress size={64} />
        </Stack>
      </>
    );
  }

  const [knownVendors, poolVendors] = data;

  return (
    <>
      <Helmet>
        <title>Vendors - Walkthroo</title>
      </Helmet>
      <NavSlim breadcrumbs={[{ text: 'Vendor List' }]} />
      <ContentWrapper className="container-vendor-list">
        <div
          className="d-flex justify-content-between align-items-center pt-3 pb-3 ps-4 pe-4"
          style={{ backgroundColor: 'white' }}
        >
          <Grid container className="align-items-center">
            <Grid item xs={5} md={3}>
              <h5 className="m-0">Your Vendors</h5>
            </Grid>
            <Grid item xs={7} md={9}>
              <Box className="d-flex align-items-center justify-content-end" style={{ gap: '8px' }}>
                {canAddEditVendors && (
                  <Button size={'large'} color="muted" onClick={toggleBulk} startIcon={<MuiIcon.FileUpload />}>
                    Upload Vendors
                  </Button>
                )}
                {canAddEditVendors && (
                  <Button
                    color="primary"
                    size={'large'}
                    startIcon={<MuiIcon.Add />}
                    component={RouterLink}
                    to={{ pathname: Routes.VendorCreate }}
                  >
                    Add Vendor
                  </Button>
                )}
              </Box>
            </Grid>
          </Grid>
        </div>
        <div className="container-vendor-list">
          <VendorTable
            vendors={knownVendors}
            referenceAddress={referenceAddress}
            source={'contacts'}
            withDelete={true}
          />
        </div>
      </ContentWrapper>
      {hasPool && (
        <ContentWrapper className="container-vendor-list">
          <div
            className="d-flex justify-content-between align-items-center pt-3 pb-3 ps-4 pe-4"
            style={{ backgroundColor: 'white' }}
          >
            <Grid container className="align-items-center">
              <Grid item xs={12} md={3}>
                <h5 className="m-0">Vendor Pool</h5>
              </Grid>
            </Grid>
          </div>
          <div className="container-vendor-list">
            <VendorTable vendors={poolVendors} referenceAddress={referenceAddress} source={'pool'} withDelete={false} />
          </div>
        </ContentWrapper>
      )}
    </>
  );
};

const VendorTable = ({
  vendors,
  referenceAddress,
  source,
  withDelete
}: {
  vendors: Vendor[];
  referenceAddress: Address;
  source: 'contacts' | 'pool';
  withDelete: boolean;
}) => {
  const isGc = useIsGeneralContractor();
  const { radius, radiusState } = useVendorRadius({ defaultRadius: '25' });
  const services = useFocusOptions();
  const tableVendors = useTableVendors({ vendors, referenceAddress, searchRadius: radius });
  const [filters] = useState({
    companyName: { operator: 'AND', constraints: [{ value: '', matchMode: 'contains' }] },
    'user.fullName': { operator: 'AND', constraints: [{ value: '', matchMode: 'contains' }] },
    serviceIds: { value: null, matchMode: 'arrayIntersect' }
  });

  return (
    <>
      <Box sx={{ my: 1, pl: 3 }}>
        <VendorRadiusSelector state={radiusState} />
      </Box>
      <Box sx={{ width: '100%' }}>
        <DataTable
          filters={filters as any}
          responsiveLayout={'scroll'}
          dataKey={'id'}
          value={tableVendors}
          paginator
          rows={5}
        >
          <Column
            {...makeBasicFilter()}
            header={'Company'}
            field={'companyName'}
            sortable
            body={(data: TableVendor) => {
              return (
                <Box sx={{ display: 'flex' }}>
                  <RooAvatar size={'l'} name={data.companyName} avatarUrl={data.avatarUrl}>
                    {vendorTableIcons[data.index % vendorTableIcons.length]}
                  </RooAvatar>
                  <Box sx={{ ml: 2 }}>
                    <Typography gutterBottom sx={{ fontWeight: 'bold' }}>
                      <RouterLink to={{ pathname: Routes.VendorProfile, params: { vendorId: data.id } }}>
                        {data.companyName}
                      </RouterLink>
                    </Typography>
                    <Stack direction="row" spacing={1}>
                      {!data.users[0].isActive && <Chip label="Inactive" size={'small'} />}
                    </Stack>
                  </Box>
                </Box>
              );
            }}
          />
          {source === 'contacts' && (
            <Column
              header={'Contact'}
              field={'user.fullName'}
              {...makeBasicFilter()}
              sortable
              body={(data: TableVendor) => {
                return (
                  <Box sx={{ display: 'flex' }}>
                    <RooAvatar name={data.users[0].fullName} size={'l'} avatarUrl={data.avatarUrl} />
                    <Box sx={{ ml: 2 }}>
                      <Typography gutterBottom sx={{ fontWeight: 'bold' }}>
                        {data.users[0].fullName} {!data.users[0].isActive && <Chip label="Inactive" size={'small'} />}
                      </Typography>
                      {!data.users[0].contactInfo.email && <Typography variant={'caption'}>No e-mail</Typography>}
                      {data.users[0].contactInfo.email && (
                        <a href={`mailto:${data.users[0].contactInfo.email}`}>
                          {' '}
                          <MuiIcon.Email /> {data.users[0].contactInfo.email}{' '}
                        </a>
                      )}
                    </Box>
                  </Box>
                );
              }}
            />
          )}

          {makeServicesColumn(services)}
          {makeLocationColumn()}
          {makeReviewsColumn()}
          {isGc && withDelete && (
            <Column header={'Actions'} sortable body={(data: TableVendor) => <DeleteVendorButton vendor={data} />} />
          )}
        </DataTable>
      </Box>
    </>
  );
};

const DeleteVendorButton = ({ vendor }: { vendor: TableVendor }) => {
  const qc = useQueryClient();
  const currVendor = useCurrentVendor();
  const { mutate, isLoading } = useMutation({
    mutationFn: () => apiProvider.vendorClient.unlink(currVendor.id, vendor.id),
    onSuccess: async () => {
      await qc.invalidateQueries(['vendors']);
    }
  });

  const [showConfirmation, setShowConfirmation] = useState(false);

  return (
    <>
      <IconButton color={'error'} onClick={() => setShowConfirmation(true)}>
        <MuiIcon.Delete />
      </IconButton>
      <ConfirmationModal
        visible={showConfirmation}
        onClose={() => setShowConfirmation(false)}
        onSave={() => void mutate()}
        question={'Are you sure you want to delete this vendor?'}
        running={isLoading}
      />
    </>
  );
};
