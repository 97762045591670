import React, { useState } from 'react';
import { apiProvider } from 'shared/api/apiProvider';
import { IssueAction, ReviewDirectAssignmentAction, ReviewDirectAssignmentPayload } from 'shared/api/clients';
import { ConfirmationModal } from 'components';
import { showSuccess } from 'shared/utils';
import { useCurrentIssue } from 'pages/Workorders/shared/CurrentIssueContext';
import { createModalStore } from '../../../shared/store';
import { issueActionList } from '../shared/types';
import { MuiIcon } from '../../../shared/icons';

const useAcceptAssignmentStore = createModalStore();

export const AcceptAssignmentModal = () => {
  const isVisible = useAcceptAssignmentStore((x) => x.visible);
  const hide = useAcceptAssignmentStore((x) => x.actions.hide);
  return <ReviewAssignmentModal visible={isVisible} onClose={hide} action={ReviewDirectAssignmentAction.Accept} />;
};

export const acceptAssignmentAction = issueActionList.register({
  Icon: MuiIcon.Check,
  color: 'success',
  isDisabled: () => false,
  key: 'accept-assignment',
  label: 'Accept',
  isVisible: (ctx) => ctx.canAct(IssueAction.AcceptAssignment),
  onClick: () => {
    useAcceptAssignmentStore.getState().actions.show();
  },
  modalToRender: <AcceptAssignmentModal />
});

const useDeclineAssignmentStore = createModalStore();

export const DeclineAssignmentModal = () => {
  const isVisible = useDeclineAssignmentStore((x) => x.visible);
  const hide = useDeclineAssignmentStore((x) => x.actions.hide);
  return <ReviewAssignmentModal visible={isVisible} onClose={hide} action={ReviewDirectAssignmentAction.Reject} />;
};

export const declineAssignmentAction = issueActionList.register({
  Icon: MuiIcon.Cancel,
  color: 'error',
  isDisabled: () => false,
  key: 'decline-assignment',
  label: 'Decline',
  isVisible: (ctx) => ctx.canAct(IssueAction.DeclineAssignment),
  onClick: () => {
    useDeclineAssignmentStore.getState().actions.show();
  },
  modalToRender: <DeclineAssignmentModal />
});

export const ReviewAssignmentModal = ({
  action,
  visible,
  onClose
}: {
  action: ReviewDirectAssignmentAction;
  visible: boolean;
  onClose: () => void;
}) => {
  const { issue, onIssueUpdate } = useCurrentIssue();
  const [isSaving, setIsSaving] = useState(false);
  const [notes, setNotes] = useState<string>('');

  let question: string = null;
  switch (action) {
    case ReviewDirectAssignmentAction.Accept:
      question = 'Do you want to accept this Issue?';
      break;
    case ReviewDirectAssignmentAction.Reject:
      question = 'Do you want to decline this Issue?';
      break;
  }

  const save = async () => {
    setIsSaving(true);
    try {
      const result = await apiProvider.issues.assignments.reviewDirectAssignment(
        new ReviewDirectAssignmentPayload({
          issueId: issue.id,
          notes: notes?.trim() === '' ? null : notes,
          action: action
        })
      );
      onIssueUpdate(result);
      showSuccess();
    } catch (e) {}

    setIsSaving(false);
    onClose();
  };
  return (
    <ConfirmationModal
      visible={visible}
      running={isSaving}
      question={
        <>
          {question}
          <div className={'mt-4 form-group'}>
            <label htmlFor={'notes-input'}>Notes</label>
            <textarea
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              className={'form-control'}
              id={'notes-input'}
              rows={3}
              placeholder={'Your comments'}
            />
          </div>
        </>
      }
      onClose={onClose}
      onSave={() => {
        void save();
      }}
    />
  );
};
