import {
  Checkbox,
  CircularProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ScopedCssBaseline,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { apiProvider } from 'shared/api/apiProvider';
import {
  GetAvailableJumpStatusesPayload,
  IssueStatus,
  SetIssueStatusPayload,
  StatusAvailabilityMeta
} from 'shared/api/clients';
import { RooButton, RooDialog } from 'components';
import { useCurrentIssue } from 'pages/Workorders/shared/CurrentIssueContext';
import { rooEnum } from 'shared/store';
import { MuiIcon } from 'shared/icons';

export const JumpStatusModal = ({ visible, onCloseRequest }: { visible: boolean; onCloseRequest: () => void }) => {
  const { issue, onIssueUpdate } = useCurrentIssue();
  const [{ status, data }, setState] = useState<{
    status: 'loading' | 'idle' | 'saving' | 'error';
    data?: StatusAvailabilityMeta[];
  }>({
    status: 'idle'
  });

  const [selection, setSelection] = useState<IssueStatus>();
  const availableStatuses = (data ?? []).filter((x) => x.status !== issue.status);

  const onSave = async () => {
    try {
      setState((x) => ({
        ...x,
        status: 'saving'
      }));
      const newIssue = await apiProvider.issues.workflow.setStatus(
        new SetIssueStatusPayload({
          issueId: issue.id,
          oldStatus: issue.status,
          newStatus: selection,
          notes: null
        })
      );
      onIssueUpdate(newIssue);
      onCloseRequest();
    } catch (e) {
      setState((x) => ({
        ...x,
        status: 'idle'
      }));
    }
  };

  useEffect(() => {
    const pull = async () => {
      setState({
        status: 'loading'
      });
      try {
        const results = await apiProvider.issues.workflow.getJumpStatuses(
          new GetAvailableJumpStatusesPayload({
            issueId: issue.id
          })
        );
        setState({
          status: 'idle',
          data: results
        });
        setSelection(null);
      } catch (e) {
        setState({
          status: 'idle'
        });
      }
    };
    void pull();
  }, [issue]);

  return (
    <ScopedCssBaseline>
      <RooDialog scroll={'body'} onClose={onCloseRequest} aria-labelledby="customized-dialog-title" open={visible}>
        <RooDialog.Title id="customized-dialog-title" onClose={onCloseRequest}>
          Change Issue Status
        </RooDialog.Title>
        <RooDialog.Content dividers>
          {status === 'error' && (
            <Typography variant={'subtitle1'}>Failed to load available statuses. Try again later.</Typography>
          )}
          {status === 'loading' && <CircularProgress />}
          {(status === 'idle' || status === 'saving') && (
            <>
              <Typography variant={'subtitle1'}>Move the issue into one of the available statuses:</Typography>
              <List>
                {availableStatuses.map((meta) => (
                  <ListItem disablePadding key={meta.status}>
                    <ListItemButton
                      disabled={!meta.canEnter}
                      role={undefined}
                      onClick={() => setSelection(meta.status)}
                      dense
                    >
                      <ListItemIcon>
                        <Checkbox
                          checkedIcon={<MuiIcon.RadioButtonChecked />}
                          icon={<MuiIcon.RadioButtonUnchecked />}
                          edge="start"
                          checked={selection === meta.status}
                          tabIndex={-1}
                          disableRipple
                        />
                      </ListItemIcon>
                      <ListItemText primary={rooEnum.issueStatus.display(meta.status)} secondary={meta.reason} />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </>
          )}
        </RooDialog.Content>
        <RooDialog.Actions>
          <RooButton disabled={selection == null} loading={status === 'saving'} onClick={() => onSave()}>
            Save
          </RooButton>
          <RooButton variant={'secondary'} onClick={() => onCloseRequest()}>
            Cancel
          </RooButton>
        </RooDialog.Actions>
      </RooDialog>
    </ScopedCssBaseline>
  );
};
