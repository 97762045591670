import { DatePicker } from '@mui/x-date-pickers';
import { LoaderSmall } from 'components';
import { useEffect, useState } from 'react';
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { apiProvider } from 'shared/api/apiProvider';
import { useCurrentUser } from 'shared/store';
import { addMonths, startOfDay, startOfMonth } from 'date-fns';
import { Stack, TextField } from '@mui/material';

export const PMChart = ({
  companyId,
  vendorIds,
  stateCode,
  city
}: {
  companyId: string;
  vendorIds: string[];
  stateCode: string;
  city: string;
}) => {
  const currentUser = useCurrentUser();
  const [data, setData] = useState([]);
  const [drillDown, setDrillDown] = useState([]);
  const [pm, setPM] = useState('');
  const [range, setRange] = useState<{ from: Date; to: Date }>({
    from: addMonths(startOfMonth(new Date()), -2),
    to: new Date()
  });
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const getData = async () => {
      const d = await apiProvider.dashboardClient.getPMChart({
        from: range.from,
        to: range.to,
        id: undefined,
        city,
        companyId,
        stateCode,
        vendorIds
      } as any);
      setData(d);
      setPM('');
      setLoading(false);
    };
    void getData();
  }, [currentUser.id, city, companyId, stateCode, vendorIds, range.from, range.to]);

  const tooltipFormatter = (value: any, name: any, props: any) => {
    if (name === 'count1') {
      name = 'Approved';
    } else if (name === 'count2') {
      name = 'Not approved';
    }
    return [value, name];
  };

  const handleClick = async (data: any, index: number) => {
    var pmId = data.activePayload[0].payload.id;
    setLoading(true);
    setPM(data.activePayload[0].payload.name);
    var d = await apiProvider.dashboardClient.getPMChartDrillDown({
      from: range.from,
      to: range.to,
      id: pmId,
      city,
      companyId,
      stateCode,
      vendorIds
    } as any);
    setDrillDown(d);
    setLoading(false);
  };

  return (
    <div>
      <h3 style={{ fontWeight: 400 }}>
        {!pm ? 'Approved Issues' : pm}{' '}
        <span style={{ float: 'right' }}>
          {pm && (
            <button
              className="btn btn-secondary"
              onClick={() => {
                setPM('');
                setLoading(false);
              }}
            >
              back
            </button>
          )}
        </span>
      </h3>
      <div className="bg-white" style={{ paddingRight: 20, paddingBottom: 10, paddingTop: 10 }}>
        {!loading && (
          <Stack direction={'row'} gap={2} justifyContent={'center'}>
            <DatePicker
              label="From"
              onChange={(val) => setRange({ ...range, from: startOfDay(val) })}
              value={range.from}
              slotProps={{
                textField: {
                  style: { width: 125 },
                  variant: 'standard',
                  size: 'small'
                }
              }}
            />
            <DatePicker
              label="To"
              onChange={(val) => setRange({ ...range, to: startOfDay(val) })}
              value={range.to}
              slotProps={{
                textField: {
                  style: { width: 125 },
                  variant: 'standard',
                  size: 'small'
                }
              }}
            />
          </Stack>
        )}
        {loading ? (
          <div className="dashboard-loader-container">
            <LoaderSmall />
          </div>
        ) : !pm ? (
          data.length > 0 && (
            <ResponsiveContainer minHeight={Math.min(354, data.length * 80)}>
              <BarChart layout="vertical" data={data} style={{ margin: 0 }} onClick={handleClick}>
                <XAxis type="number" orientation="top" />
                <YAxis type="category" dataKey="name" width={120} tick={{ fontSize: 10 }} />
                <Tooltip formatter={tooltipFormatter} />
                <Bar dataKey="count1" stackId="a" fill="blue" />
                <Bar dataKey="count2" stackId="a" fill="red" />
              </BarChart>
            </ResponsiveContainer>
          )
        ) : (
          drillDown.length > 0 && (
            <ResponsiveContainer minHeight={Math.min(354, drillDown.length * 80)}>
              <BarChart layout="vertical" data={drillDown} style={{ margin: 0 }}>
                <XAxis type="number" orientation="top" />
                <YAxis type="category" dataKey="name" width={120} tick={{ fontSize: 10 }} />
                <Tooltip formatter={tooltipFormatter} />
                <Bar dataKey="count1" stackId="a" fill="blue" />
                <Bar dataKey="count2" stackId="a" fill="red" />
              </BarChart>
            </ResponsiveContainer>
          )
        )}
      </div>
    </div>
  );
};
