import { useParams } from 'react-router';
import { useInspectionDetailsQuery } from './shared';
import { CenteredLoader } from '../../components/CenteredLoader';
import * as React from 'react';
import { ReactNode, useContext, useEffect, useRef, useState } from 'react';
import {
  IInspectionDetails,
  IInspectionUserView,
  InsActionType,
  InsConditionType,
  InsFieldType,
  InspectionStatus
} from '@roo/api';
import { Alert, Button, Container, Divider, Paper, Stack, styled, Typography } from '@mui/material';
import { SummaryHeading } from './SummaryHeading';
import { useCurrentUser } from '../../shared/store';
import { Button as ButtonUnstyled } from '@mui/base';
import { assertNever, createInspectionEditorStore, InsEditorStoreProvider, insService, useInsEditor } from '@roo/lib';
import { MuiIcon } from 'shared/icons';
import { Transition } from 'react-transition-group';
import { rooFmt } from '../../shared/utils';
import { FileThumbnail } from '../../components/Files/FileThumbnail';

export const InspectionReportPage = () => {
  const { id } = useParams<{ id: string }>();
  const { status, data } = useInspectionDetailsQuery(id);

  if (status === 'loading') {
    return <CenteredLoader />;
  }

  if (status === 'error' || data == null) {
    return null;
  }

  return <Content inspectionView={data} />;
};

const Content = ({ inspectionView }: { inspectionView: IInspectionUserView }) => {
  const { inspection, role } = inspectionView;

  return (
    <Container>
      <Stack spacing={4}>
        <Paper elevation={0} sx={{ p: 2 }}>
          <Stack spacing={2}>
            <Stack direction={'row'} justifyContent={'space-between'}>
              <Typography variant="h6" component="div">
                Inspection {inspection.friendlyId}
              </Typography>
            </Stack>
            <Divider />
            <SummaryHeading inspection={inspection} />
          </Stack>
        </Paper>
        {inspection.status === InspectionStatus.Done && <ReportContainer inspection={inspection} />}
        {inspection.status !== InspectionStatus.Done && (
          <Stack justifyContent={'center'} alignItems={'center'}>
            <Alert color={'warning'}>This inspection is not finished yet</Alert>
          </Stack>
        )}
      </Stack>
    </Container>
  );
};

type ViewType = 'root' | 'sectionList';
type ViewData = { view: ViewType; sectionId?: string };

const ViewCtx = React.createContext<{ viewData: ViewData; update: (vd: ViewData) => void }>(null);
const useViewData = () => {
  const ctx = useContext(ViewCtx);
  return ctx?.viewData;
};

const useSetViewData = () => {
  const ctx = useContext(ViewCtx);
  return ctx?.update;
};

const ReportContainer = ({ inspection }: { inspection: IInspectionDetails }) => {
  const [viewData, setViewData] = useState<ViewData>({ view: 'root', sectionId: null });
  const user = useCurrentUser();
  return (
    <ViewCtx.Provider value={{ viewData, update: setViewData }}>
      <InsEditorStoreProvider
        createStore={() =>
          createInspectionEditorStore({
            isReadonly: true,
            inspection: inspection,
            existingDocument: insService.hydrate(inspection.contentJson).document,
            template: null,
            userId: user.id
          })
        }
      >
        <Report />
      </InsEditorStoreProvider>
    </ViewCtx.Provider>
  );
};

const Report = () => {
  const vd = useViewData();

  switch (vd.view) {
    case 'root':
      return <RootView />;
    case 'sectionList':
      return <SectionListView />;
  }
};

const WhiteButton = styled(ButtonUnstyled)(({ theme }) => ({
  backgroundColor: 'white',
  border: 'none',
  textAlign: 'unset'
}));

const RootView = () => {
  const rootSectionIds = useInsEditor((x) => x.views.sections[x.rootNodeId].sectionIds);
  const rootSections = useInsEditor((x) => rootSectionIds.map((r) => x.views.sections[r]));
  const setVd = useSetViewData();

  return (
    <Paper elevation={0} sx={{ p: 2 }}>
      <Stack spacing={4} alignItems={'center'} justifyContent={'center'}>
        {rootSections.map((s) => (
          <Stack key={s.id} spacing={2} sx={{ width: '100%', maxWidth: 800 }}>
            <WhiteButton onClick={() => setVd({ view: 'sectionList', sectionId: s.id })}>
              <Paper elevation={1} sx={{ p: 2 }}>
                <SectionStats sectionId={s.id} />
              </Paper>
            </WhiteButton>
          </Stack>
        ))}
      </Stack>
    </Paper>
  );
};

const SectionListView = () => {
  const vd = useViewData();
  const section = useInsEditor((x) => x.views.sections[vd.sectionId]);
  const sectionIds = section.descendantSectionIds;
  const setVd = useSetViewData();

  return (
    <Paper elevation={0} sx={{ p: 2 }}>
      <Stack spacing={4} sx={{ width: '100%' }} justifyContent={'center'}>
        <Stack direction={'row'} justifyContent={'space-between'} sx={{ width: '100%' }}>
          <Typography variant="h6" component="div">
            {section.name}
          </Typography>
          <Button variant={'text'} startIcon={<MuiIcon.ArrowBack />} onClick={() => setVd({ view: 'root' })}>
            Back to start
          </Button>
        </Stack>
        <Stack spacing={2} sx={{ width: '100%' }}>
          {sectionIds.map((x) => (
            <SectionWithLines key={x} sectionId={x} />
          ))}
        </Stack>
      </Stack>
    </Paper>
  );
};

const SectionWithLines = ({ sectionId }: { sectionId: string }) => {
  const section = useInsEditor((x) => x.views.sections[sectionId]);
  const lineIds = useInsEditor((x) => x.views.sections[sectionId].lineIds);
  return (
    <Paper elevation={0} sx={{ p: 2 }}>
      <Stack spacing={2}>
        <Stack direction={'row'} justifyContent={'space-between'}>
          <Typography variant={'subtitle1'} fontWeight={600}>
            {section.name}
          </Typography>
          {Boolean(section.parentPath) && (
            <Typography variant={'caption'} fontWeight={600}>
              {section.parentPath}
            </Typography>
          )}
        </Stack>
        <Divider />
        <Stack spacing={2}>
          {lineIds.map((x) => (
            <LineCard key={x} lineId={x} />
          ))}
        </Stack>
      </Stack>
    </Paper>
  );
};

const LineCard = ({ lineId }: { lineId: string }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [height, setHeight] = useState<string | number>(0);
  const contentRef = useRef<HTMLDivElement>(null);

  const togglePanel = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (contentRef.current) {
      setHeight(isOpen ? contentRef.current.scrollHeight : 0);
    }
  }, [isOpen]);

  const line = useInsEditor((x) => x.views.lines[lineId]);

  return (
    <Paper elevation={1}>
      <Stack>
        <WhiteButton onClick={togglePanel} sx={{ p: 2 }}>
          <Stack spacing={2}>
            <Stack direction={'row'} justifyContent={'space-between'} spacing={4}>
              <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} flex={1}>
                <Typography variant={'subtitle1'} fontWeight={600}>
                  {line.name}
                </Typography>
                <Stack direction={'row'} spacing={1}>
                  {line.selectedCondition.type === InsConditionType.Acceptable ? (
                    <MuiIcon.Check fontSize={'small'} color={'success'} />
                  ) : (
                    <MuiIcon.Warning fontSize={'small'} color={'warning'} />
                  )}
                  <MuiIcon.ChatBubble fontSize={'small'} color={Boolean(line.notes) ? 'info' : 'disabled'} />
                  <MuiIcon.PhotoCamera fontSize={'small'} color={line.pictures.length > 0 ? 'info' : 'disabled'} />
                  <MuiIcon.AttachMoney
                    fontSize={'small'}
                    color={line.price != null && line.price > 0 ? 'info' : 'disabled'}
                  />
                </Stack>
              </Stack>

              <MuiIcon.ExpandMore
                style={{
                  transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                  transition: 'transform 0.3s ease-in-out'
                }}
              />
            </Stack>
          </Stack>
        </WhiteButton>

        <Transition in={isOpen} timeout={duration}>
          {(state) => (
            <div
              ref={contentRef}
              style={{
                transition: `max-height ${duration}ms ease-in-out`,
                maxHeight: state === 'entered' || state === 'entering' ? height : 0,
                overflow: 'hidden'
              }}
            >
              <Stack>
                <Divider />
                <Stack spacing={2} sx={{ px: 3, py: 2 }}>
                  <Stack direction={'row'} spacing={4}>
                    <Stack direction={'row'} spacing={1}>
                      <Typography>Condition:</Typography>
                      <Typography
                        fontWeight={600}
                        color={
                          line.selectedCondition.type === InsConditionType.Acceptable ? 'success.main' : 'warning.main'
                        }
                      >
                        {line.selectedCondition.label}
                      </Typography>
                    </Stack>
                    {line.selectedAction != null && (
                      <Stack direction={'row'} spacing={1}>
                        <Typography>Action:</Typography>
                        <Typography
                          color={line.selectedAction.type === InsActionType.Actionable ? 'warning.main' : 'gray'}
                          fontWeight={600}
                        >
                          {line.selectedAction?.label}
                        </Typography>
                      </Stack>
                    )}
                  </Stack>
                  {line.visibleFieldKeys.length > 0 && (
                    <Stack direction={'row'} spacing={4}>
                      {line.visibleFieldKeys.map((f, idx) => (
                        <RenderedField key={idx} fieldKey={f} lineKey={line.id} />
                      ))}
                    </Stack>
                  )}

                  {line.pictures.length > 0 && (
                    <Stack direction={'row'} spacing={4}>
                      {line.pictures.map((picture, index) => (
                        <FileThumbnail key={index} size={64} file={picture} />
                      ))}
                    </Stack>
                  )}
                </Stack>
              </Stack>
            </div>
          )}
        </Transition>
      </Stack>
    </Paper>
  );
};

const RenderedField = ({ fieldKey, lineKey }: { fieldKey: string; lineKey: string }) => {
  const fieldVal = useInsEditor((x) => x.views.fields[`${lineKey}:${fieldKey}`]);
  const fieldTemplate = useInsEditor((x) => x.document.sharedStore.fields[fieldKey]);

  if (fieldVal.type === InsFieldType.Pictures || fieldVal.value == null || fieldVal.value === '') {
    return null;
  }

  let content: ReactNode = null;
  switch (fieldVal.type) {
    case InsFieldType.TextLine:
      content = <Typography>{fieldVal.value}</Typography>;
      break;
    case InsFieldType.TextArea:
      content = <Typography>{fieldVal.value}</Typography>;
      break;
    case InsFieldType.Numeric:
      {
        const num = parseFloat(fieldVal.value);
        content = <Typography>{rooFmt.numberMaybe(num, 'N/A')}</Typography>;
      }
      break;
    case InsFieldType.Money:
      {
        const num = parseFloat(fieldVal.value);
        content = <Typography>{rooFmt.moneyMaybe(num, 'N/A')}</Typography>;
      }
      break;
    case InsFieldType.SingleSelect:
      content = <Typography>{fieldVal.value}</Typography>;
      break;
    case InsFieldType.MultiCheckbox:
      {
        const values = JSON.parse(fieldVal.value ?? '[]') as string[];
        content = <Typography>{values.length > 0 ? values.join(', ') : 'N/A'}</Typography>;
      }
      break;
    default:
      assertNever(fieldVal.type);
  }

  return (
    <Stack direction={'row'} spacing={1}>
      <Typography>{fieldTemplate.label}:</Typography>
      {content}
    </Stack>
  );
};

const duration = 300;

const SectionStats = ({ sectionId }: { sectionId: string }) => {
  const section = useInsEditor((x) => x.views.sections[sectionId]);

  return (
    <Stack spacing={2}>
      <Stack direction={'row'} justifyContent={'space-between'}>
        <Typography variant={'subtitle1'} fontWeight={600}>
          {section.name}
        </Typography>
        {Boolean(section.parentPath) && (
          <Typography variant={'caption'} fontWeight={600}>
            {section.parentPath}
          </Typography>
        )}
      </Stack>

      <Stack direction={'row'} spacing={4}>
        <Typography>{section.totalLines} lines</Typography>
        {section.issueLines > 0 && (
          <Typography fontWeight={600} color={'warning.main'}>
            {section.issueLines} issues
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};
