import React, { ReactNode, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { apiProvider } from 'shared/api/apiProvider';
import { BidAction, BidUserView, IBidUserView, ReviewBidAction, ReviewBidPayload } from 'shared/api/clients';
import { createModalStore } from 'shared/store';
import { RooDialog } from 'components';
import { showSuccess } from 'shared/utils';
import { useCurrentIssue } from 'pages/Workorders/shared/CurrentIssueContext';
import { BidInfo } from 'pages/Workorders/ViewIssue/Documents/Bids/BidInfo';
import { bidActionList, bidModalComponents } from './actions';
import { MuiIcon, RooIcon } from '../../../../../../shared/icons';
import { Button } from '@mui/material';
import { useMutation } from '@tanstack/react-query';

const acceptBidAction = bidActionList.register({
  key: 'accept',
  actionType: BidAction.Accept,
  color: 'success',
  label: 'Accept',
  Icon: MuiIcon.CheckCircle,
  onClick: (bidView) =>
    useReviewModalStore.getState().actions.show({
      action: ReviewBidAction.Accept,
      modalQuestion: 'Do you want to accept this Bid?',
      hasNotes: true,
      bidView
    })
});

const rejectBidAction = bidActionList.register({
  key: 'reject',
  actionType: BidAction.Reject,
  color: 'error',
  label: 'Reject',
  Icon: MuiIcon.Cancel,
  onClick: (bidView) =>
    useReviewModalStore.getState().actions.show({
      action: ReviewBidAction.Reject,
      modalQuestion: 'Do you want to reject this Bid?',
      hasNotes: true,
      bidView: bidView
    })
});

const elevateBidAction = bidActionList.register({
  key: 'elevate',
  actionType: BidAction.Elevate,
  color: 'warning',
  label: 'Send to Owner',
  Icon: MuiIcon.ArrowCircleUp,
  onClick: (bidView) =>
    useReviewModalStore.getState().actions.show({
      action: ReviewBidAction.Elevate,
      modalQuestion: 'Do you want to forward this Bid to the Property Owner?',
      hasNotes: false,
      bidView: bidView
    })
});

const acceptRevisionAction = bidActionList.register({
  key: 'accept-revision',
  actionType: BidAction.AcceptRevision,
  color: 'success',
  label: 'Accept Revision',
  Icon: MuiIcon.CheckCircle,
  onClick: (bidView) =>
    useReviewModalStore.getState().actions.show({
      bidView,
      action: ReviewBidAction.AcceptRevision,
      modalQuestion: 'Do you want to accept the changes?',
      hasNotes: true
    })
});

const forceAcceptRevisionAction = bidActionList.register({
  key: 'forceAcceptRevision',
  actionType: BidAction.ForceAcceptRevision,
  color: 'warning',
  label: 'Force Accept',
  Icon: MuiIcon.Hardware,
  onClick: (bidView) =>
    useReviewModalStore.getState().actions.show({
      bidView,
      action: ReviewBidAction.ForceAcceptRevision,
      modalQuestion: 'Do you want to accept these changes without waiting for vendor approval?',
      hasNotes: true
    })
});

const acceptChangesAction = bidActionList.register({
  key: 'accept-changes',
  actionType: BidAction.AcceptChanges,
  color: 'success',
  label: 'Accept',
  Icon: MuiIcon.CheckCircle,
  onClick: (bidView) =>
    useReviewModalStore.getState().actions.show({
      bidView,
      action: ReviewBidAction.AcceptChanges,
      modalQuestion: 'Do you want to accept the changes?',
      hasNotes: true
    })
});

const forceAcceptChangesAction = bidActionList.register({
  key: 'force-accept-changes',
  actionType: BidAction.ForceAcceptChanges,
  color: 'warning',
  label: 'Force Accept',
  Icon: MuiIcon.Hardware,
  onClick: (bidView) =>
    useReviewModalStore.getState().actions.show({
      bidView,
      action: ReviewBidAction.ForceAcceptChanges,
      modalQuestion: 'Do you want to accept these changes without waiting for vendor approval?',
      hasNotes: true
    })
});

const declineChangesAction = bidActionList.register({
  key: 'decline-changes',
  actionType: BidAction.DeclineChanges,
  color: 'error',
  label: 'Decline',
  Icon: MuiIcon.Cancel,
  onClick: (bidView) =>
    useReviewModalStore.getState().actions.show({
      bidView,
      action: ReviewBidAction.DeclineChanges,
      modalQuestion: 'Do you want to decline the changes?',
      hasNotes: true
    })
});

export const bidReviewActionList = [
  acceptRevisionAction,
  forceAcceptRevisionAction,
  acceptChangesAction,
  declineChangesAction,
  forceAcceptChangesAction,
  acceptBidAction,
  rejectBidAction,
  elevateBidAction
];

export const ReviewButtonGroup = ({
  bidView,
  onButtonClick = () => {}
}: {
  bidView: IBidUserView;
  onButtonClick?: () => void;
}) => {
  const availableActions = bidReviewActionList.filter((x) => bidActionList.isAvailable(x, bidView));
  if (availableActions.length === 0) {
    return null;
  }

  return (
    <>
      {availableActions.map((act) => (
        <Button
          key={act.key}
          color={act.color}
          size={'small'}
          onClick={() => {
            onButtonClick();
            act.onClick(bidView);
          }}
        >
          {act.label}
        </Button>
      ))}
    </>
  );
};

type ReviewModalPayload = {
  action: ReviewBidAction;
  bidView: IBidUserView;
  hasNotes: boolean;
  modalQuestion: ReactNode;
};
export const useReviewModalStore = createModalStore<ReviewModalPayload>();

export const ReviewBidModal = () => {
  const {
    visible,
    actions: { hide },
    payload
  } = useReviewModalStore();

  return (
    <RooDialog maxWidth={'md'} fullWidth open={visible} onClose={hide}>
      <RooDialog.Title onClose={hide}>Confirmation</RooDialog.Title>
      <ReviewBidModalBody payload={payload} />
    </RooDialog>
  );
};

const ReviewBidModalBody = ({ payload }: { payload: ReviewModalPayload }) => {
  const { onIssueUpdate } = useCurrentIssue();
  const [notes, setNotes] = useState<string>('');
  const {
    actions: { hide }
  } = useReviewModalStore();
  const { isLoading, mutate } = useMutation({
    mutationFn: () =>
      apiProvider.issues.bids.reviewBid(
        new ReviewBidPayload({
          action: payload.action,
          issueId: payload.bidView.bid.issueId,
          bidId: payload.bidView.bid.id,
          notes: notes.trim() === '' ? null : notes
        })
      ),
    onSuccess: (res) => {
      showSuccess();
      onIssueUpdate(res);
      hide();
    }
  });

  return (
    <>
      <RooDialog.Content>
        <Row>
          <Col xl={12} className={'mb-3'}>
            {payload.modalQuestion}
          </Col>
          {payload.hasNotes && (
            <Col xl={12} className={'mb-3'}>
              <div className={'mt-4 form-group'}>
                <label htmlFor={'review-notes'}>Notes</label>
                <textarea
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                  className={'form-control'}
                  id={'review-notes'}
                  rows={3}
                  placeholder={'Your comments'}
                />
              </div>
            </Col>
          )}
          <Col xl={12}>
            <BidInfo bid={payload.bidView.bid} />
          </Col>
        </Row>
      </RooDialog.Content>
      <RooDialog.Actions>
        <Button color="primary" onClick={() => void mutate()} disabled={isLoading} sx={{ mr: 1 }}>
          {isLoading ? <RooIcon icon={['fas', 'circle-notch']} spin={isLoading} style={{ fontSize: 23 }} /> : 'Yes'}
        </Button>
        <Button color="muted" onClick={hide} disabled={isLoading}>
          Cancel
        </Button>
      </RooDialog.Actions>
    </>
  );
};

bidModalComponents.push(<ReviewBidModal />);
