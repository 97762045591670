import React, { InputHTMLAttributes, useState } from 'react';
import { FieldValues, useController } from 'react-hook-form';
import { ControlProps } from './types';
import { FormGroupProps } from 'react-bootstrap/FormGroup';
import { FormGroup } from 'react-bootstrap';
import { RooFormLabel } from './RooFormLabel';
import { fieldClassNames, stringTransform } from './utils';
import { ErrorMessage } from './ErrorMessage';
import { Button, Stack, Typography } from '@mui/material';
import { MuiIcon } from 'shared/icons';

type BaseProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'id' | 'type' | 'name' | 'onChange' | 'value' | 'onBlur'>;
type FinalProps<TForm extends FieldValues> = ControlProps<TForm> & BaseProps & { groupProps?: FormGroupProps };
export type FieldToggleEditTextProps<TForm extends FieldValues> = FinalProps<TForm>;

export const FieldToggleEditText = <TForm extends FieldValues>({
  name,
  control,
  label,
  className,
  groupProps,
  required,
  disabled,
  ...rest
}: FinalProps<TForm>) => {
  const {
    field,
    fieldState: { error }
  } = useController({
    control: control,
    name: name
  });

  const [isEditing, setIsEditing] = useState(!Boolean(field.value) && !disabled);

  return (
    <FormGroup {...(groupProps ?? {})}>
      <RooFormLabel label={label} name={name} required={required} />
      {!isEditing && (
        <Stack>
          <Typography>{stringTransform.input(field.value)}</Typography>
          {!disabled && (
            <Button
              onClick={() => setIsEditing(true)}
              variant={'text'}
              size={'small'}
              startIcon={<MuiIcon.Edit />}
              sx={{ alignSelf: 'flex-start' }}
            >
              Edit
            </Button>
          )}
        </Stack>
      )}
      {isEditing && (
        <Stack>
          <input
            type={'text'}
            className={fieldClassNames(className, error)}
            name={name}
            id={name}
            onChange={(e) => field.onChange(stringTransform.output(e))}
            value={stringTransform.input(field.value)}
            onBlur={field.onBlur}
            {...rest}
          />
          <Stack direction={'row'}>
            <Button
              onClick={() => setIsEditing(false)}
              variant={'text'}
              size={'small'}
              startIcon={<MuiIcon.Check />}
              sx={{ alignSelf: 'flex-start' }}
            >
              Done
            </Button>
          </Stack>
        </Stack>
      )}
      <ErrorMessage error={error} />
    </FormGroup>
  );
};
