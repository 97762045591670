import Button from '@mui/material/Button';
import React, { useState } from 'react';
import { Alert, Col, Form, FormGroup, Row } from 'react-bootstrap';
import { apiProvider } from 'shared/api/apiProvider';
import { AddVendorMemberPayload } from 'shared/api/clients';
import { FieldText, Required, useRooForm } from 'components/form';
import { useCurrentVendor } from 'shared/store';
import { RooDialog, SaveButtonMui } from 'components';
import { PersonForm, PersonSchema } from 'shared/utils';
import { useWatchGlobalModal } from './modalStore';
import Grid2 from '@mui/material/Unstable_Grid2';

export const AddVendorMemberModal = () => {
  const [error, setError] = useState(null);
  const currentVendor = useCurrentVendor();
  const { isVisible, toggle, callbacks } = useWatchGlobalModal('addVendorMember');
  const { handleSubmit, formState, control, reset } = useRooForm(PersonSchema);

  const submit = async (values: PersonForm) => {
    try {
      await apiProvider.vendorClient.addMember(
        new AddVendorMemberPayload({
          email: values.email,
          contactFirstName: values.firstName,
          contactLastName: values.lastName,
          cellPhone: values.phone,
          vendorId: currentVendor.id,
          address: null,
          workPhone: null
        })
      );

      setError(null);
      reset();
      toggle();
      callbacks?.onSuccess?.();
    } catch (err) {}
  };

  return (
    <RooDialog open={isVisible} onClose={toggle}>
      <Form noValidate style={{ marginBottom: 'initial' }} onSubmit={handleSubmit(submit)}>
        <RooDialog.Title onClose={toggle}>Invite a coworker</RooDialog.Title>
        <RooDialog.Content>
          <Grid2 container spacing={2}>
            <Grid2 xl={6} xs={12}>
              <FieldText
                control={control}
                name={'firstName'}
                required
                label={'First Name'}
                placeholder={'First Name'}
                className="Input-field"
              />
            </Grid2>
            <Grid2 xl={6} xs={12}>
              <FieldText
                control={control}
                name={'lastName'}
                required
                label={'Last Name'}
                placeholder={'Last Name'}
                className="Input-field"
              />
            </Grid2>
            <Grid2 xl={6} xs={12}>
              <FieldText
                control={control}
                name={'email'}
                required
                label={'Email'}
                placeholder={'Email'}
                className="Input-field"
              />
            </Grid2>
            <Grid2 xl={6} xs={12}>
              <FieldText
                control={control}
                name={'phone'}
                required
                label={'Phone'}
                placeholder={'Phone'}
                className="Input-field"
              />
            </Grid2>
          </Grid2>
          {error && <Alert variant={'danger'}>{error}</Alert>}
        </RooDialog.Content>
        <RooDialog.Actions>
          <SaveButtonMui control={control}>Save</SaveButtonMui>
          &nbsp;&nbsp;
          <Button
            color="muted"
            disabled={formState.isSubmitting || formState.isValidating}
            size="small"
            onClick={toggle}
          >
            Cancel
          </Button>
        </RooDialog.Actions>
      </Form>
    </RooDialog>
  );
};
