import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
};

export const useIsSmallScreen = () => {
  const { width } = useWindowDimensions();
  return width <= 1000;
};

export function useSearchParams<T extends Record<string, string>>(): {
  searchParams: Partial<T>;
  setSearchParam: (key: keyof T, value: string | null) => void;
} {
  const { search } = useLocation();
  const history = useHistory();

  const searchParams = useMemo(() => {
    const params = new URLSearchParams(search);
    return Object.fromEntries(params.entries()) as Partial<T>;
  }, [search]);

  const setSearchParam = useCallback(
    (key: keyof T, value: string | null) => {
      const params = new URLSearchParams(search);
      if (value == null) {
        params.delete(key as string);
      } else {
        params.set(key as string, value);
      }
      history.push({ search: params.toString() });
    },
    [search, history]
  );

  return { searchParams, setSearchParam };
}

export const useQueryParams = <T extends any>() => {
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const result: any = {};
  for (const [key, value] of searchParams) {
    result[key] = value;
  }

  return result as T;
};

export const useFullScreen = () => {
  useEffect(() => {
    document.getElementsByTagName('html')[0].classList.add('h-100');
    document.body.classList.add('h-100');

    return () => {
      document.getElementsByTagName('html')[0].classList.remove('h-100');
      document.body.classList.remove('h-100');
    };
  });
};
