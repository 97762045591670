import { styled } from '@mui/material';
import { sum, sumBy } from 'lodash';
import React from 'react';
import { IssueRole } from 'shared/api/clients';
import { rooFmt } from 'shared/utils';
import { useCurrentIssue } from 'pages/Workorders/shared/CurrentIssueContext';
import { WithLineCollection } from 'pages/Workorders/ViewIssue/Documents/types';
import { Control, useWatch } from 'react-hook-form';

const CellDefinition = styled('td')(
  ({ theme }) => `
padding-right: 24px;
font-weight: 700
`
);

type DocumentSummaryEntry = {
  category: 'basic' | 'resale';
  label: string;
  value: string;
  numericValue: number;
  flag?: 'grand_total' | 'final_total';
};

export const calculateDocumentSummary = (formInfo: {
  withTax: boolean;
  taxPercent: number;
  withDiscount: boolean;
  discountPercent: number;
  withPartial: boolean;
  partialPercent: number;
  partialAmount: number;
  previousDiscount: number;

  resaleTaxPercent: number;
  withFutureResale: boolean;
  isReselling: boolean;
  lines: { initialValue?: number; value?: number; resaleValue?: number }[];
}) => {
  const taxPercent = formInfo.taxPercent == null || isNaN(formInfo.taxPercent) ? 0 : formInfo.taxPercent;
  const resaleTaxPercent =
    formInfo.resaleTaxPercent == null || isNaN(formInfo.resaleTaxPercent) ? 0 : formInfo.resaleTaxPercent;
  const discountPercent =
    formInfo.discountPercent == null || isNaN(formInfo.discountPercent) || !formInfo.withDiscount
      ? 0
      : formInfo.discountPercent;

  const partialPercent =
    formInfo.partialPercent == null || isNaN(formInfo.partialPercent) ? 0 : formInfo.partialPercent;
  const partialAmount = formInfo.partialAmount == null || isNaN(formInfo.partialAmount) ? 0 : formInfo.partialAmount;

  const hasInitial = formInfo.lines.find((x) => x.initialValue != null) != null;

  // if the amt is 0, it means they're not selling this, so it shouldn't factor in
  const initial = sumBy(
    formInfo.lines.filter((x) => x.value !== 0),
    (x) => x.initialValue ?? 0
  );
  const subtotal = sumBy(formInfo.lines, (x) => x.value ?? 0);

  const tax = subtotal * (taxPercent / 100);
  const grandTotal = subtotal + tax - (formInfo.previousDiscount ?? 0);
  const discount = grandTotal * (discountPercent / 100);
  const discountedTotal = grandTotal - discount;

  const resaleSubtotal = !formInfo.withFutureResale ? 0 : sumBy(formInfo.lines, (x) => x.resaleValue ?? 0);
  const resaleTax = !formInfo.withFutureResale ? 0 : resaleSubtotal * (resaleTaxPercent / 100);
  const resaleMarkup = !formInfo.withFutureResale ? 0 : resaleSubtotal - subtotal;

  const showPrevDiscount = formInfo.previousDiscount != null && formInfo.previousDiscount != 0;

  const showDiscount = formInfo.withDiscount && discountPercent != null && discount !== 0;
  const showTax = formInfo.withTax && tax !== 0;

  const initialMarkup = subtotal - initial;
  const initialMarkupPercent = ((subtotal - initial) / initial) * 100;

  const finalMarkup = discountedTotal - initial;
  const finalMarkupPercent = ((discountedTotal - initial) / initial) * 100;

  const isReselling = formInfo.isReselling && hasInitial && initial > 0;

  const results: DocumentSummaryEntry[] = [];
  const addBasic = (label: string, num: number, value: string, flag?: 'grand_total' | 'final_total') => {
    results.push({
      category: 'basic',
      label: label,
      value: value,
      numericValue: num,
      flag: flag
    });
  };

  if (isReselling) {
    addBasic('Contractor Subtotal', initial, rooFmt.money(initial));
    addBasic(
      'Initial Markup',
      initialMarkup,
      `${rooFmt.money(initialMarkup)} (${rooFmt.percent(initialMarkupPercent)})`
    );
  }

  if (showPrevDiscount || showDiscount || showTax) {
    addBasic('Subtotal', subtotal, rooFmt.money(subtotal));
    if (showTax) {
      addBasic(`Tax (${rooFmt.percent(taxPercent)})`, tax, rooFmt.money(tax));
    }
  }

  if (showPrevDiscount) {
    addBasic('Previous Payments', formInfo.previousDiscount, rooFmt.money(formInfo.previousDiscount));
  }

  addBasic('Grand Total', grandTotal, rooFmt.money(grandTotal), 'grand_total');

  if (showDiscount) {
    addBasic(`Discount (${rooFmt.percent(discountPercent)})`, discount, rooFmt.money(-1 * discount));
  }

  if (formInfo.withPartial) {
    addBasic(`Partial Invoice %`, partialPercent, rooFmt.percent(partialPercent));
    addBasic(`Partial Invoice Amount`, partialAmount, rooFmt.money(partialAmount));
    addBasic(`Remaining Balance`, discountedTotal - partialAmount, rooFmt.money(discountedTotal - partialAmount));
  }

  if (showDiscount && !formInfo.withPartial) {
    addBasic(`Final Total`, discountedTotal, rooFmt.money(discountedTotal), 'final_total');
  }

  if (isReselling) {
    addBasic('Final Markup', finalMarkup, `${rooFmt.money(finalMarkup)} (${rooFmt.percent(finalMarkupPercent)})`);
  }

  if (formInfo.withFutureResale) {
    results.push({
      category: 'resale',
      label: 'Resale Subtotal',
      value: `${rooFmt.money(resaleSubtotal)}`,
      numericValue: resaleSubtotal
    });
    results.push({
      category: 'resale',
      label: 'Resale Markup',
      value: `${rooFmt.money(resaleMarkup)}`,
      numericValue: resaleMarkup
    });
    results.push({
      category: 'resale',
      label: 'Resale Tax',
      value: `${rooFmt.money(resaleTax)}`,
      numericValue: resaleTax
    });
  }

  return results;
};

export const DocumentFormTotals = ({
  control,
  withTax,
  withResale,
  withDiscount,
  withPartial,
  previousDiscount
}: {
  control: Control<WithLineCollection>;
  withTax: boolean;
  withResale: boolean;
  withDiscount: boolean;
  withPartial: boolean;
  previousDiscount: number;
}) => {
  const { userRole, subissues } = useCurrentIssue();

  const lines = useWatch({
    control,
    name: 'lineCollection.lines'
  });
  const taxPercentVal = useWatch({
    control,
    name: 'lineCollection.taxPercent'
  });
  const resaleTaxPercentVal = useWatch({ control, name: 'lineCollection.resaleTaxPercent' });
  const discountPercentVal = useWatch({ control, name: 'lineCollection.discountPercent' });
  const previousDiscountVal = useWatch({ control, name: 'lineCollection.previousDiscount' });
  const partialAmount = useWatch({ control, name: 'lineCollection.partialAmount' });
  const partialPercent = useWatch({ control, name: 'lineCollection.partialPercent' });
  const isReselling = userRole === IssueRole.Vendor && subissues.length > 0;

  const summary = calculateDocumentSummary({
    withFutureResale: withResale,
    isReselling: isReselling,
    withTax: withTax,
    withDiscount: withDiscount,
    discountPercent: discountPercentVal,
    taxPercent: taxPercentVal,
    resaleTaxPercent: resaleTaxPercentVal,
    previousDiscount: previousDiscount,
    lines: lines,
    withPartial: withPartial,
    partialPercent: partialPercent,
    partialAmount: partialAmount
  });

  const basicEntries = summary.filter((x) => x.category === 'basic');
  const resaleEntries = summary.filter((x) => x.category === 'resale');

  return (
    <div style={{ display: 'flex', justifyContent: 'end', flexGrow: 0 }}>
      <table>
        <tbody>
          {basicEntries.map((x, i) => (
            <tr key={`${i}-${x.label}`}>
              <CellDefinition>{x.label}</CellDefinition>
              <td>{x.value}</td>
            </tr>
          ))}

          {resaleEntries.length > 0 && (
            <>
              <tr>
                <td colSpan={2}>
                  <hr />
                </td>
              </tr>
              {resaleEntries.map((x, i) => (
                <tr key={`${i}-${x.label}`}>
                  <CellDefinition>{x.label}</CellDefinition>
                  <td>{x.value}</td>
                </tr>
              ))}
            </>
          )}
        </tbody>
      </table>
    </div>
  );
};
