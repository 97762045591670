import { Grid, Stack } from '@mui/material';
import React from 'react';
import { Col, FormGroup, Row } from 'react-bootstrap';
import { Control, UseFieldArrayRemove } from 'react-hook-form';
import { FieldArrayWithId } from 'react-hook-form/dist/types/fieldArray';
import { UseFormGetValues } from 'react-hook-form/dist/types/form';
import { FieldNumber, FieldText, FieldTextArea, Required } from 'components/form';
import { FileAreaField } from 'pages/Workorders/ViewIssue/Documents/FileAreaField';
import { LineContainer, LineItemHeader, LineRemoveButton } from 'pages/Workorders/ViewIssue/Documents/LineContainer';
import { WithLineCollection } from 'pages/Workorders/ViewIssue/Documents/types';
import { AttachmentEntityType } from 'shared/api/clients';
import Grid2 from '@mui/material/Unstable_Grid2';
import { FieldToggleEditText } from '../../../../components/form/FieldToggleEditText';

export const FormLineItem = ({
  line,
  index,
  remove,
  control,
  getValues,
  entityType,
  canEditDescription,
  canEditValue,
  showInitial,
  hasNotes,
  hasResale,
  hasBeforeFiles,
  hasAfterFiles
}: {
  line: FieldArrayWithId<WithLineCollection, 'lineCollection.lines'>;
  index: number;
  remove: UseFieldArrayRemove;
  control: Control<WithLineCollection>;
  getValues: UseFormGetValues<WithLineCollection>;
  entityType: AttachmentEntityType;
  canEditDescription: boolean;
  canEditValue: boolean;
  showInitial: boolean;
  hasResale: boolean;
  hasNotes: boolean;
  hasBeforeFiles: boolean;
  hasAfterFiles: boolean;
}) => {
  return (
    <div>
      <LineContainer index={index} line={line}>
        <LineItemHeader remove={remove} index={index} canEdit={canEditDescription} />
        <Stack spacing={2} flexShrink={1} sx={{ marginBottom: 2 }}>
          <Grid2 container spacing={2}>
            <Grid2 xs={12} md>
              <FieldToggleEditText
                control={control}
                disabled={!canEditDescription}
                name={`lineCollection.lines.${index}.description` as const}
                required
                label={'Description'}
                placeholder={'Description'}
              />
            </Grid2>
            {showInitial && (
              <Grid2 xs={4} md={2}>
                <FieldNumber
                  control={control}
                  disabled
                  name={`lineCollection.lines.${index}.initialValue` as const}
                  label={'Initial'}
                  placeholder={'N/A'}
                />
              </Grid2>
            )}
            <Grid2 xs={4} md={2}>
              <FieldNumber
                control={control}
                disabled={!canEditValue}
                name={`lineCollection.lines.${index}.value` as const}
                required
                label={'Amount'}
                placeholder={'Amount'}
              />
            </Grid2>
            {hasResale && (
              <Grid2 xs={4} md={2}>
                <FieldNumber
                  control={control}
                  disabled={!canEditValue}
                  name={`lineCollection.lines.${index}.resaleValue` as const}
                  label={'Resale'}
                  placeholder={'N/A'}
                />
              </Grid2>
            )}
          </Grid2>
          {hasNotes && (
            <FieldTextArea
              control={control}
              name={`lineCollection.lines.${index}.notes` as const}
              label={'Notes'}
              placeholder={'Notes'}
            />
          )}
          {hasBeforeFiles && (
            <FileAreaField
              entityType={entityType}
              control={control}
              name={`lineCollection.lines.${index}.beforeFiles` as const}
              label={'Before Job'}
            />
          )}
          {hasAfterFiles && (
            <FileAreaField
              entityType={entityType}
              control={control}
              name={`lineCollection.lines.${index}.afterFiles` as const}
              label={'After Job'}
            />
          )}
        </Stack>
        <LineRemoveButton index={index} remove={remove} canEdit={canEditDescription} />
      </LineContainer>
    </div>
  );
};
