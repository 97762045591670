import React from 'react';
import { CapabilityType } from 'shared/api/clients';
import { useAppStore, useCurrentMemberships } from 'shared/store';
import { WithReqChildren } from 'shared/utils';

export type WhenCapableProps = {
  capability: CapabilityType;
  and?: boolean;
  companyId?: string;
} & WithReqChildren;

export const WhenCapable = ({ capability, children, and = true, companyId }: WhenCapableProps) => {
  let user = useAppStore((x) => x.userDetails);
  const memberships = useCurrentMemberships();
  let capabilities: CapabilityType[] = [];
  if (user != null) {
    capabilities =
      companyId != null
        ? memberships.find((x) => x.managementCompanyId === companyId)?.capabilities ?? []
        : memberships.flatMap((x) => x.capabilities);
  }

  if (!(user?.emailConfirmed ?? false)) {
    return null;
  }

  if (!capabilities.find((x) => x === capability)) {
    return null;
  }

  if (!and) {
    return null;
  }

  return <>{children}</>;
};
