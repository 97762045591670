import { issueActionList } from '../shared/types';
import { MuiIcon } from '../../../shared/icons';

export const scrollToBidsAction = issueActionList.register({
  Icon: MuiIcon.Gavel,
  color: 'info',
  isDisabled: () => false,
  key: 'scroll-to-bids',
  label: 'Bids',
  isVisible: (ctx) => true,
  onClick: () => {
    // something is interfering with the scroll, possibly the popup closing
    setTimeout(() => {
      const mainAnchor = document.querySelector('#main-anchor') as any;
      const bidsAnchor = document.querySelector('#bids-anchor') as any;
      const headerAnchor = document.querySelector('#header-anchor') as any;
      if (mainAnchor == null || bidsAnchor == null) {
        return;
      }

      const headerHeight = headerAnchor?.getBoundingClientRect().height ?? 0;

      const offset = bidsAnchor.getBoundingClientRect().y + mainAnchor.scrollTop - headerHeight;

      mainAnchor.scroll({ top: offset });
    }, 1);
  }
});
