import { IconButton, List, ListItem, ListItemText, ListSubheader, Stack } from '@mui/material';
import { Address } from 'shared/api/clients';
import { rooFmt } from 'shared/utils';
import { MuiIcon } from '../../shared/icons';

export const AddressWidget = ({
  address,
  canEdit,
  onEdit
}: {
  address: Address;
  canEdit: boolean;
  onEdit?: () => void;
}) => {
  return (
    <List
      className={'profile'}
      sx={{ ml: { md: 10 } }}
      subheader={
        <Stack direction={'row'}>
          <ListSubheader>
            <MuiIcon.Map /> Address
          </ListSubheader>
          {canEdit && (
            <IconButton color={'primary'} onClick={() => onEdit?.()}>
              <MuiIcon.Edit />
            </IconButton>
          )}
        </Stack>
      }
    >
      <ListItem>
        <ListItemText primary={rooFmt.textMaybe(address.address1, '-')} secondary={'Address 1'} />
        {address.address2 && (
          <ListItemText sx={{ ml: 6 }} primary={rooFmt.textMaybe(address.address2, '-')} secondary={'Address 2'} />
        )}
      </ListItem>
      <ListItem>
        <ListItemText primary={rooFmt.textMaybe(address.city, '-')} secondary={'City'} />
      </ListItem>
      <ListItem>
        <ListItemText
          sx={{ minWidth: '100px' }}
          primary={rooFmt.textMaybe(address.zipCode, '-')}
          secondary={'Zip Code'}
        />
        <ListItemText primary={rooFmt.textMaybe(address.state, '-')} secondary={'State'} />
      </ListItem>
    </List>
  );
};
