import { WorkorderStatus } from 'shared/api/clients';
import { ColoredEnumDisplay, createMap, EnumMap } from 'shared/store/enums/shared';

const InternalMapWorkorderStatus: EnumMap<typeof WorkorderStatus, ColoredEnumDisplay> = {
  New: {
    display: 'New',
    color: 'primary'
  },
  Closed: {
    display: 'Closed',
    color: 'secondary'
  },
  InProgress: {
    display: 'In Progress',
    color: 'success'
  },
  Unknown: {
    display: 'Unknown',
    color: 'secondary'
  }
};

export const EnumMapWorkorderStatus = createMap<WorkorderStatus, ColoredEnumDisplay>(
  WorkorderStatus,
  InternalMapWorkorderStatus
);
