import { AdminChangeEmailPayload, AdminChangeEmailResultType, UserAccount } from '../../../shared/api/clients';
import { z } from 'zod';
import React, { useState } from 'react';
import { apiProvider } from '../../../shared/api/apiProvider';
import { RooDialog, SaveButtonMui } from '../../../components';
import { Alert, Form } from 'react-bootstrap';
import { Button, Stack, Typography } from '@mui/material';
import { FieldMuiText, useRooForm } from '../../../components/form';

export const ChangeEmailModal = ({
  row,
  isVisible,
  onClose,
  requestRefresh
}: {
  row: UserAccount;
  isVisible: boolean;
  onClose: () => void;
  requestRefresh: () => void;
}) => {
  const ChangeEmailSchema = z
    .object({
      email: z.string().email()
    })
    .superRefine((val, ctx) => {
      if (val.email?.trim() === row.email?.trim()) {
        ctx.addIssue({
          message: 'Choose a new e-mail',
          code: 'custom',
          path: ['email']
        });
      }
    });
  type ChangeEmailForm = z.infer<typeof ChangeEmailSchema>;
  const [error, setError] = useState(null);
  const { handleSubmit, formState, control, reset } = useRooForm(ChangeEmailSchema);

  const submit = async (values: ChangeEmailForm) => {
    try {
      const result = await apiProvider.adminClient.changeAccountEmail(
        new AdminChangeEmailPayload({
          userId: row.userId,
          oldEmail: row.email,
          newEmail: values.email
        })
      );

      switch (result) {
        case AdminChangeEmailResultType.Success:
          setError(null);
          reset({
            email: values.email
          });
          onClose();
          requestRefresh();
          break;
        case AdminChangeEmailResultType.EmailUnavailable:
          setError('A user with that e-mail already exists');
          break;
        default:
          setError('An unknown error has occurred');
          break;
      }
    } catch (err) {}
  };

  return (
    <RooDialog open={isVisible} onClose={onClose} maxWidth={'sm'} fullWidth>
      <Form noValidate style={{ marginBottom: 'initial' }} onSubmit={handleSubmit(submit)}>
        <RooDialog.Title onClose={onClose}>Change user e-mail</RooDialog.Title>
        <RooDialog.Content>
          <Typography>
            Current user e-mail is <code>{row.email}</code>
          </Typography>
          <FieldMuiText
            control={control}
            name={'email'}
            label={'New E-mail'}
            sx={{ mt: 2 }}
            margin={'normal'}
            className={'Input-field'}
            fullWidth
          />
          {error && <Alert variant={'danger'}>{error}</Alert>}
        </RooDialog.Content>
        <RooDialog.Actions>
          <Stack spacing={2} direction={'row'} justifyContent={'right'}>
            <SaveButtonMui control={control}>Save</SaveButtonMui>
            <Button
              color="muted"
              disabled={formState.isSubmitting || formState.isValidating}
              size="small"
              onClick={onClose}
            >
              Cancel
            </Button>
          </Stack>
        </RooDialog.Actions>
      </Form>
    </RooDialog>
  );
};
