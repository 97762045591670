import { ListItemIcon, ListItemText, Menu, MenuItem, SvgIconProps } from '@mui/material';
import React, { createContext, ReactNode, useCallback, useContext } from 'react';
import { SvgIconComponent } from '@mui/icons-material';

const MenuContext = createContext<{ closeMenu: () => void }>(null);
const useMenuContext = () => {
  const ctx = useContext(MenuContext);
  if (ctx == null) {
    throw new Error(
      'Could not find context MenuContext in component tree. Make sure ActionMenuItem is rendered as a descendant of ActionsMenu'
    );
  }
  return ctx;
};

export const ActionsMenu = ({
  children,
  moreButton
}: {
  children: ReactNode;
  moreButton: (onClick: (event: React.MouseEvent<HTMLButtonElement>) => void) => ReactNode;
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);
  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <>
      {moreButton(handleClick)}
      <Menu anchorEl={anchorEl} open={anchorEl != null} onClose={handleClose}>
        <MenuContext.Provider
          value={{
            closeMenu: handleClose
          }}
        >
          {children}
        </MenuContext.Provider>
      </Menu>
    </>
  );
};

export const ActionsMenuItem = ({
  color,
  Icon,
  onClick,
  disabled,
  children,
  show = true
}: {
  show?: boolean;
  children: ReactNode;
  onClick: () => void;
  Icon: SvgIconComponent;
  color: SvgIconProps['color'];
  disabled?: boolean;
}) => {
  const { closeMenu } = useMenuContext();
  if (!show) {
    return null;
  }

  return (
    <MenuItem
      disabled={disabled}
      onClick={() => {
        onClick?.();
        closeMenu();
      }}
    >
      {Icon && (
        <ListItemIcon>
          <Icon color={color} />
        </ListItemIcon>
      )}
      <ListItemText>{children}</ListItemText>
    </MenuItem>
  );
};
