import { Button, Grid, InputAdornment, Typography } from '@mui/material';
import { z } from 'zod';
import { Control, useWatch } from 'react-hook-form';
import { schema } from './CompareBidsContent';
import { Routes, redirect } from 'shared/routing';
import { round } from 'lodash';
import { FieldMuiNumber } from 'components/form/FieldMuiNumber';
import { FieldMuiDatePicker } from 'components/form/FieldMuiDatePicker';
import { calculateDocumentSummary } from '../DocumentFormTotals';

export function EstimateForm({
  disabled,
  control,
  onSubmit,
  workorderId,
  issueId,
  disableSend,
  lines
}: {
  disabled: boolean;
  control: Control<z.infer<typeof schema>>;
  onSubmit: any;
  workorderId: string;
  issueId: string;
  disableSend: boolean;
  lines: { initialValue: number; value: number }[];
}) {
  const discount = useWatch({ control, name: 'discount' });
  const taxtRate = useWatch({ control, name: 'taxtRate' });
  const markup = useWatch({ control, name: 'markup' });

  const summary = calculateDocumentSummary({
    withFutureResale: false,
    isReselling: true,
    withTax: true,
    withDiscount: true,
    discountPercent: discount,
    taxPercent: taxtRate,
    resaleTaxPercent: null,
    previousDiscount: null,
    lines: lines,
    withPartial: false,
    partialPercent: null,
    partialAmount: null
  });

  return (
    <Grid component="form" onSubmit={onSubmit} container py="1rem">
      <EstimateFields control={control} />
      <Grid xs={12} pt="1rem" sm={4} item container alignItems="center" direction="column" gap="2" maxWidth={'400px'}>
        {summary.map((x, i) => (
          <Grid key={`${x.label}-${i}`} item container gap="2">
            <Grid sm={8} item>
              <Typography fontWeight={600}>{x.label}</Typography>
            </Grid>
            <Grid sm={4} item>
              <Typography>{x.value}</Typography>
            </Grid>
          </Grid>
        ))}
      </Grid>
      <EstimateButtons disabled={disabled} disabledSend={disableSend} workorderId={workorderId} issueId={issueId} />
    </Grid>
  );
}

function EstimateButtons({
  workorderId,
  issueId,
  disabled,
  disabledSend
}: {
  disabledSend: boolean;
  workorderId: string;
  issueId: string;
  disabled: boolean;
}) {
  const goBack = () => {
    redirect({
      pathname: Routes.IssueView,
      params: { workorderId: workorderId, issueId: issueId }
    });
  };

  return (
    <Grid container item gap={1} sm={2} pt="1rem" direction="column">
      <Grid item sm={2}>
        <Button disabled={disabled} fullWidth onClick={goBack} color="muted" sx={{ padding: '1rem' }}>
          Cancel
        </Button>
      </Grid>

      <Grid item sm={2}>
        <Button
          disabled={disabled || disabledSend}
          fullWidth
          variant="contained"
          type="submit"
          color="primary"
          sx={{ padding: '1rem' }}
        >
          Save
        </Button>
      </Grid>
    </Grid>
  );
}

function EstimateFields({ control }: { control: Control<z.infer<typeof schema>> }) {
  return (
    <Grid container item gap={1} sm={6} p="1rem">
      <Grid sm={12} gap={1} container item wrap="nowrap">
        <Grid item sm={6}>
          <FieldMuiDatePicker control={control} name="startDate" label="Start Date" />
        </Grid>
        <Grid item sm={6}>
          <FieldMuiDatePicker control={control} name="endDate" label="End Date" />
        </Grid>
      </Grid>
      <Grid sm={12} item container wrap="nowrap" gap={1}>
        <Grid item sm={4}>
          <FieldMuiNumber
            control={control}
            name="taxtRate"
            label="Tax Rate"
            fullWidth
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>
            }}
          />
        </Grid>
        <Grid item sm={4}>
          <FieldMuiNumber
            control={control}
            name="markup"
            label="Markup"
            fullWidth
            defaultValue={15}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>
            }}
          />
        </Grid>
        <Grid item sm={4}>
          <FieldMuiNumber
            control={control}
            name="discount"
            label="Discount"
            fullWidth
            InputProps={{
              type: 'number',
              endAdornment: <InputAdornment position="end">%</InputAdornment>
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
