import { IconName } from '@fortawesome/free-regular-svg-icons';
import { useState } from 'react';
import { ButtonProps } from 'react-bootstrap';
import { apiProvider } from 'shared/api/apiProvider';
import { ReviewIssueScheduleAction, ReviewIssueSchedulePayload } from 'shared/api/clients';
import { ReqStar } from 'components/form/ReqStar';
import { ConfirmationModal, RooButton } from 'components';
import { rooFmt, showSuccess } from 'shared/utils';
import { useCurrentIssue } from 'pages/Workorders/shared/CurrentIssueContext';

export const ReviewIssueScheduleButton = ({ action }: { action: ReviewIssueScheduleAction }) => {
  const { issue, onIssueUpdate } = useCurrentIssue();
  const [isConfirming, setIsConfirming] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [notes, setNotes] = useState<string>('');
  let notesRequired = false;

  let question: string = null;
  let buttonText: string = null;
  let notesPlaceholder: string = null;
  let buttonVariant: ButtonProps['variant'] = null;
  let buttonIcon: IconName = null;
  switch (action) {
    case ReviewIssueScheduleAction.Accept:
      question = 'Do you want to accept this schedule?';
      buttonText = 'Accept Schedule';
      buttonVariant = 'success';
      buttonIcon = 'check';
      notesPlaceholder = 'Your comments';
      break;
    case ReviewIssueScheduleAction.Reject:
      question = 'Do you want to decline this schedule?';
      buttonText = 'Decline Schedule';
      buttonVariant = 'danger';
      buttonIcon = 'times';
      notesPlaceholder = 'Please write a couple dates and times that work best for you.';
      notesRequired = true;
      break;
  }

  const save = async () => {
    setIsSaving(true);
    try {
      const result = await apiProvider.issues.scheduling.reviewIssueSchedule(
        new ReviewIssueSchedulePayload({
          issueId: issue.id,
          notes: notes?.trim() === '' ? null : notes,
          action: action
        })
      );
      onIssueUpdate(result);
      showSuccess();
    } catch (e) {}

    setIsSaving(false);
    setIsConfirming(false);
  };
  return (
    <>
      <RooButton onClick={() => setIsConfirming(true)} variant={buttonVariant} icon={buttonIcon}>
        {buttonText}
      </RooButton>
      <ConfirmationModal
        visible={isConfirming}
        running={isSaving}
        confirmDisabled={notesRequired && notes === ''}
        question={
          <>
            {question}
            <div className={'mt-2'}>
              <dl>
                <dt>Date and Time</dt>
                <dd>
                  {rooFmt.instantDate(issue.schedule.scheduledInstant)}, {issue.schedule.timeBlocks[0].display}
                </dd>
                {issue.schedule.notes && (
                  <>
                    <dt>Vendor's notes</dt>
                    <dd>{issue.schedule.notes}</dd>
                  </>
                )}
              </dl>
            </div>

            <div className={'mt-4 form-group'}>
              <label htmlFor={'notes-input'}>Notes {notesRequired && <ReqStar />}</label>
              <textarea
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                className={'form-control'}
                id={'notes-input'}
                rows={3}
                placeholder={notesPlaceholder}
              />
            </div>
          </>
        }
        onClose={() => setIsConfirming(false)}
        onSave={() => {
          void save();
        }}
      />
    </>
  );
};
