import { Box, Button, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Control, useController } from 'react-hook-form';
import { UseFormSetValue, UseFormWatch } from 'react-hook-form/dist/types/form';
import { FieldSingleSelect, FieldText, FieldTextArea } from 'components/form';
import { priorityOptions, useIsGeneralContractor } from 'shared/store';
import { PropertyCardLoader, RooDialog, RouterButton, SaveButtonMui } from 'components';
import { z } from 'zod';
import Grid2 from '@mui/material/Unstable_Grid2';
import { useQuery } from '@tanstack/react-query';
import { apiProvider } from '../../../shared/api/apiProvider';
import { useDisclosure, usePrevious } from '@roo/lib';
import { PropertySelectionModal } from '../../../components/PropertySelectionModal';
import { MuiIcon } from 'shared/icons';
import { CreateClientModalContent } from '../../VendorClients/CreateClientButton';
import { PropertyCreateForm } from '../../Properties/PropertyCreate';

export const WorkorderSchema = z.object({
  id: z.string().nullish(),
  priority: z.number(),
  notes: z.string().nullish(),
  gateCode: z.string().max(255).nullish(),
  lockBox: z.string().max(255).nullish(),
  externalReference: z.string().max(255).nullish(),
  isEditing: z.boolean(),
  managementCompanyId: z.string(),
  propertyId: z.string()
});

export type WorkorderDefinition = z.infer<typeof WorkorderSchema>;

export const WorkorderForm = ({
  isEditing,
  control,
  watch,
  setValue
}: {
  isEditing: boolean;
  control: Control<WorkorderDefinition>;
  watch: UseFormWatch<WorkorderDefinition>;
  setValue: UseFormSetValue<WorkorderDefinition>;
}) => {
  const isGc = useIsGeneralContractor();

  return (
    <Grid2 container spacing={2}>
      <Grid2 xl={6} xs={12}>
        <FieldSingleSelect control={control} options={priorityOptions} required name={'priority'} label={'Priority'} />
      </Grid2>
      {isGc && !isEditing && (
        <Grid2 xl={12} xs={12}>
          <ManagementCompanyField control={control} isEditing={isEditing} watch={watch} setValue={setValue} />
        </Grid2>
      )}

      <Grid2 xl={12} xs={12}>
        <PropertyField control={control as any} isEditing={isEditing} watch={watch} />
      </Grid2>
      <Grid2 xl={4} xs={12}>
        <FieldText control={control} name={'gateCode'} label={'Gate Code'} />
      </Grid2>
      <Grid2 xl={4} xs={12}>
        <FieldText control={control} name={'lockBox'} label={'Lock Box'} />
      </Grid2>
      <Grid2 xl={4} xs={12}>
        <FieldText control={control} name={'externalReference'} label={'Ref #'} />
      </Grid2>
      <Grid2 xl={12} xs={12}>
        <FieldTextArea control={control} name={'notes'} label={'Entry Notes'} />
      </Grid2>
    </Grid2>
  );
};

const ManagementCompanyField = ({
  control,
  isEditing,
  setValue
}: {
  isEditing: boolean;
  control: Control<WorkorderDefinition>;
  watch: UseFormWatch<WorkorderDefinition>;
  setValue: UseFormSetValue<WorkorderDefinition>;
}) => {
  const isGc = useIsGeneralContractor();
  const { data, isLoading } = useQuery(
    ['user', 'managedCompanies'],
    () => apiProvider.usersClient.getManagedCompanies(),
    {
      enabled: isGc,
      select: (data) => (data ?? []).map((x) => ({ label: x.name, value: x.id }))
    }
  );

  if (!isGc) {
    return null;
  }

  return (
    <Stack direction={'row'} alignItems={'center'} gap={2}>
      <FieldSingleSelect
        control={control}
        name={'managementCompanyId'}
        options={data}
        isDisabled={isLoading || isEditing}
        isClearable
        label={'Client'}
        groupProps={{ style: { flexGrow: 1 } }}
      />
      <Typography mt={'26px'} variant={'caption'}>
        or
      </Typography>
      {!isEditing && <CreateClientButton onResult={(cid) => setValue('managementCompanyId', cid)} />}
    </Stack>
  );
};

export const CreateClientButton = ({ onResult }: { onResult: (companyId: string) => void }) => {
  const [show, setShow] = useState(false);
  const toggleShow = () => setShow(!show);
  return (
    <>
      <Button sx={{ mt: '26px' }} size={'small'} color={'primary'} startIcon={<MuiIcon.Add />} onClick={toggleShow}>
        Create
      </Button>
      <RooDialog scroll={'body'} open={show} onClose={toggleShow}>
        <CreateClientModalContent toggleShow={toggleShow} onResult={onResult} />
      </RooDialog>
    </>
  );
};

export const PropertyField = ({
  control,
  isEditing,
  watch
}: {
  control: Control<WorkorderDefinition>;
  isEditing: boolean;
  watch: UseFormWatch<WorkorderDefinition>;
}) => {
  const {
    field,
    fieldState: { error }
  } = useController({
    control,
    name: 'propertyId'
  });

  const companyId = watch('managementCompanyId');
  const prevCompanyId = usePrevious(companyId);

  useEffect(() => {
    if (prevCompanyId != null && companyId !== prevCompanyId) {
      // @ts-ignore
      field.onChange(null);
    }
  }, [companyId, prevCompanyId]);

  const disclosure = useDisclosure(false);

  let display = (
    <Stack direction={'row'} alignItems={'center'} gap={2}>
      <Button disabled={companyId == null} onClick={disclosure.open} size={'small'} startIcon={<MuiIcon.Search />}>
        Select
      </Button>
      <Typography variant={'caption'}>or</Typography>
      {!isEditing && (
        <CreatePropertyButton
          companyId={companyId}
          onResult={(propertyId) => {
            field.onChange(propertyId);
          }}
        />
      )}
    </Stack>
  );

  if (field.value != null) {
    display = <PropertyCardLoader propertyId={field.value} />;
  }

  return (
    <>
      <Stack gap={1}>
        <Stack direction={'row'} justifyContent={'space-between'}>
          <Typography variant={'body2'} fontWeight={600}>
            Property
          </Typography>
          {field.value != null && (
            <Button size={'small'} variant={'text'} onClick={() => field.onChange(null)}>
              Change
            </Button>
          )}
        </Stack>

        <Box px={1}>{display}</Box>
        {error != null && <Typography color={'error'}>{error.message}</Typography>}
      </Stack>
      <PropertySelectionModal
        disclosure={disclosure}
        companyId={companyId}
        onSelect={(val) => {
          // @ts-ignore
          field.onChange(val.id);
          disclosure.close();
        }}
      />
    </>
  );
};

const CreatePropertyButton = ({
  companyId,
  onResult
}: {
  companyId: string;
  onResult: (propertyId: string) => void;
}) => {
  const [show, setShow] = useState(false);
  const toggleShow = () => setShow(!show);
  return (
    <>
      <Button
        disabled={companyId == null}
        size={'small'}
        color={'primary'}
        onClick={toggleShow}
        startIcon={<MuiIcon.Add />}
      >
        Create
      </Button>

      <RooDialog fullWidth={true} maxWidth={'xl'} scroll={'body'} open={show} onClose={toggleShow}>
        <RooDialog.Content>
          <PropertyCreateForm
            companyIdOverride={companyId}
            onResult={onResult}
            renderButtons={({ control }) => (
              <Stack
                spacing={{
                  xs: 3,
                  md: 5
                }}
                mt={3}
                direction={{
                  xs: 'column-reverse',
                  md: 'row'
                }}
                justifyContent={{
                  xs: 'center',
                  md: 'flex-end'
                }}
              >
                <Button size={'large'} color="muted" sx={{ width: { xs: '100%', lg: 200 } }} onClick={toggleShow}>
                  Cancel
                </Button>
                <SaveButtonMui size={'large'} control={control} sx={{ width: { xs: '100%', lg: 200 } }}>
                  Save
                </SaveButtonMui>
              </Stack>
            )}
          />
        </RooDialog.Content>
      </RooDialog>
    </>
  );
};
